
import { computed, defineComponent, PropType, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import {HamburgerMenuItem, LinkItem} from "@/types";

export default defineComponent({
  name: "HamburgerMenu",
  props: {
    color: {
      type: String,
      default: "#198FD9",
    },
    backgroundColor: {
      type: String,
      default: "#198FD9",
    },
    width: {
      type: Number,
      default: 24,
    },
    links: {
      type: Array as () => HamburgerMenuItem[],
      default: () => [],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: false,
    });
    const styles = computed(() => {
      return {
        "--width": props.width,
        "--color": props.color,
        "--background-color": props.backgroundColor,
      };
    });
    const router = useRouter();
    const moveToLink = (menuItem: HamburgerMenuItem) => {
      if (menuItem.isTitle) return;
      state.show = false;
      if (menuItem.isHref) {
        window.location.href = menuItem.link.name;
      } else {
        router.push(menuItem.link);
      }
    };
    return { ...toRefs(state), styles, moveToLink };
  },
});
