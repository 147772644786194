<template>
  <router-view />
</template>

<style lang="scss">
@import "./assets/styles/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
#app {
  font-family: "Noto Sans JP", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* DOM */
::-webkit-scrollbar {
  display: none;
}
h1 {
  font-size: 24px;
  font-weight: 700;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 20px;
  }
  @include mq(lg) {
    font-size: 24px;
  }
}
h2 {
  font-size: 20px;
  font-weight: 600;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 18px;
  }
  @include mq(lg) {
    font-size: 20px;
  }
}
h3 {
  font-size: 18px;
  font-weight: 600;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 14px;
  }
  @include mq(lg) {
    font-size: 18px;
  }
}
h4 {
  font-size: 14px;
  font-weight: 500;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 12px;
  }
  @include mq(lg) {
    font-size: 14px;
  }
}
h5 {
  font-size: 12px;
  font-weight: 500;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 10px;
  }
  @include mq(lg) {
    font-size: 12px;
  }
}
h6 {
  font-size: 10px;
  font-weight: 500;
  color: map-get($font-colors, "default");
  margin: 0;
  @include mq(xs) {
    font-size: 9px;
  }
  @include mq(lg) {
    font-size: 10px;
  }
}

input,
textarea {
  font-family: inherit;
}

/*汎用クラス*/
.d-none {
  display: none !important;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.text {
  &-center {
    text-align: center !important;
  }
  &-left {
    text-align: left !important;
  }
  &-right {
    text-align: right !important;
  }
}
.align {
  &-content {
    &-center {
      align-content: center !important;
    }
    &-top {
      align-content: start !important;
    }
    &-end {
      align-content: end !important;
    }
  }
  &-items {
    &-center {
      align-items: center !important;
    }
    &-start {
      align-items: start !important;
    }
    &-end {
      align-items: end !important;
    }
  }
  &-self {
    &-center {
      align-self: center !important;
    }
    &-start {
      align-self: start !important;
    }
    &-end {
      align-self: end !important;
    }
  }
}
.justify-content {
  &-center {
    justify-content: center !important;
  }
  &-left {
    justify-content: left !important;
  }
  &-right {
    justify-content: right !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
  &-space-between {
    justify-content: space-between !important;
  }
}
.m {
  &x-auto {
    margin: auto;
  }
  @each $name, $pixel in $pixels {
    &-#{$name} {
      margin: $pixel;
    }
  }
  &t {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-top: $pixel;
      }
    }
  }
  &b {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-bottom: $pixel;
      }
    }
  }
  &l {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-left: $pixel;
      }
    }
  }
  &r {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-right: $pixel;
      }
    }
  }
}
.p {
  @each $name, $pixel in $pixels {
    &-#{$name} {
      margin: $pixel;
    }
  }
  &t {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-top: $pixel;
      }
    }
  }
  &b {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-bottom: $pixel;
      }
    }
  }
  &l {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-left: $pixel;
      }
    }
  }
  &r {
    @each $name, $pixel in $pixels {
      &-#{$name} {
        margin-right: $pixel;
      }
    }
  }
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.base-container {
  background-color: map-get($bg-colors, "default");
  padding: 24px;
  min-height: calc(100vh - 48px);
  @include mq(xs) {
    padding: 10px;
  }
  @include mq(lg) {
    padding: 24px;
  }
}
.base-container__white {
  height: fit-content;
  background-color: white;
  padding: 24px;
  min-height: calc(100vh - 48px);
}
.base-container__gray {
  height: fit-content;
  background-color: map-get($bg-colors, "gray");
  padding: 24px;
  min-height: calc(100vh - 48px);
}
.main {
  margin-left: 104px;
  width: calc(100% - 104px);
  @include mq(xs) {
    width: 100%;
    margin: auto;
  }
  @include mq(lg) {
    margin-left: 104px;
    width: calc(100% - 104px);
  }
}

.margin-left-auto {
  margin-left: auto;
}

.w-fit-content {
  width: fit-content;
}

.expired {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  margin-top: 24px;
  padding: 80px 40px;
  &__title {
    color: map-get($colors, "blue300");
    font-weight: 900;
    font-size: 24px;
  }
  &__description {
    color: map-get($colors, "gray300");
    font-weight: 700;
    font-size: 16px;
  }

}
</style>
