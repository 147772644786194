
import { defineComponent } from "vue";
export default defineComponent({
  name: "InfrectLogo",
  props: {
    fillColor: {
      type: String,
      default: "#fff",
    },
  },
});
