<template>
  <div class="invoice-table">
    <div class="invoice-table__header d-flex">
      <div class="date">
        <h4>日時</h4>
      </div>
      <div class="number">
        <h4>請求書番号</h4>
      </div>
      <div class="plan d-flex align-items-center">
        <h4>プラン</h4>
      </div>
      <div class="amount">
        <h4>請求額</h4>
      </div>
      <div class="blank"></div>
    </div>
    <div :class="['invoice-table__body', item.number ? 'd-flex' : 'd-none', 'align-items-center']" v-for="(item, index) in items" :key="index">
      <div class="date">
        <h4>{{ formatDate(new Date(item.created * 1000)) }}</h4>
      </div>
      <div class="number">
        <h4>{{ item.number }}</h4>
      </div>
      <div class="plan">
        <h4>{{ getPlanName(item) }}</h4>
      </div>
      <div class="amount">
        <h4>¥{{ item.amountPaid.toLocaleString() }}</h4>
      </div>
      <div class="link">
        <a :href="item.hostedInvoiceUrl"><base-button size="small" color="success"> ダウンロード </base-button></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { Invoice, InvoiceData } from "@/types";
import { formatDate } from "@/plugins/formatter";

export default defineComponent({
  name: "InvoiceTable",
  components: { BaseButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: {
        add: true,
        trash: false,
      },
    });
    const sortByKey = (arr: [], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const getPlanName = (invoice: Invoice): string => {
      let temp = "";
      invoice.lines.data.map((x: InvoiceData) => {
        temp += x.plan?.nickname ? x.plan?.nickname : "" + " ";
      });
      return temp;
    };
    return { ...toRefs(state), sortByKey, getPlanName, formatDate };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.invoice-table {
  width: 100%;
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
  }
  &__body {
    height: fit-content;
    padding: 20px 20px;
    background-color: white;
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    &__icons {
      margin-left: auto;
    }
  }
  & blank {
    width: 92px;
    margin-left: auto;
  }
  & .date {
    width: 200px;
  }
  & .number {
    width: 200px;
  }
  & .plan {
    width: 180px;
  }
  & .amount {
    width: 120px;
    text-align: right;
  }
  & .link {
    width: 400px;
    text-align: right;
  }
}
</style>
