
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MemberCreateParams } from "@/types";

export default defineComponent({
  name: "MemberVerifyEmail",
  components: { BaseTextField, BaseButton },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      errorMessage: computed(() => store.state.members.errorMessage),
      createMemberSuccess: computed(() => store.state.members.createMemberSuccess),
      password: null as string | null,
      password2: null as string | null,
      formErrorMessage: null as string | null,
      verificationCode: route.params.invitation_code,
      showSuccess: false,
    });
    const createMemberData = () => {
      state.formErrorMessage = null;
      if (!state.password) {
        state.formErrorMessage = "パスワードは必須です";
      } else if (!state.password2) {
        state.formErrorMessage = "確認用パスワードは必須です";
      } else if (state.password !== state.password2) {
        state.formErrorMessage = "パスワードと確認用パスワードが違います";
      } else {
        const params: MemberCreateParams = {
          password: state.password,
          verificationCode: state.verificationCode,
        };
        store.dispatch("members/createMember", params);
      }
    };
    let createMemberSuccess = computed(() => state.createMemberSuccess);
    watch(createMemberSuccess, (val: boolean) => {
      if (val) state.showSuccess = true;
    });
    const judgementCreateMemberData = () => {
      if (state.password && state.password2) {
        createMemberData();
      }
    };
    return { ...toRefs(state), createMemberData, judgementCreateMemberData };
  },
});
