<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
    <path d="M16,17v2H2V17s0-4,7-4,7,4,7,4M12.5,7.5A3.5,3.5,0,1,0,9,11a3.5,3.5,0,0,0,3.5-3.5M15.94,13A5.321,5.321,0,0,1,18,17v2h4V17s0-3.63-6.06-4M15,4a3.391,3.391,0,0,0-1.93.59,5,5,0,0,1,0,5.82A3.391,3.391,0,0,0,15,11a3.5,3.5,0,0,0,0-7Z" transform="translate(12 13)"  :fill="fillColor" />
  </svg>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent ({
  name: "AccountListIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff"
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

</script>







