
import { defineComponent, reactive, computed } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      styles: computed(() => {
        return { "--color": props.color };
      }),
    });
    return { state };
  },
});
