<template>
  <svg class="material-design-icon__svg" :width="size" :height="size" viewBox="0 0 18 18">
    <path
      d="M8.927,36.209a4.614,4.614,0,1,0,4.614,4.614A4.607,4.607,0,0,0,8.927,36.209Zm0,7.614a3,3,0,1,1,3-3,3.005,3.005,0,0,1-3,3Zm5.879-7.8a1.076,1.076,0,1,1-1.076-1.076A1.074,1.074,0,0,1,14.806,36.02Zm3.056,1.092a5.326,5.326,0,0,0-1.454-3.771,5.361,5.361,0,0,0-3.771-1.454c-1.486-.084-5.939-.084-7.425,0a5.353,5.353,0,0,0-3.771,1.45A5.343,5.343,0,0,0-.012,37.109c-.084,1.486-.084,5.939,0,7.425A5.326,5.326,0,0,0,1.442,48.3a5.368,5.368,0,0,0,3.771,1.454c1.486.084,5.939.084,7.425,0A5.326,5.326,0,0,0,16.408,48.3a5.361,5.361,0,0,0,1.454-3.771c.084-1.486.084-5.935,0-7.421Zm-1.919,9.015a3.037,3.037,0,0,1-1.711,1.711c-1.185.47-4,.361-5.3.361s-4.124.1-5.3-.361a3.037,3.037,0,0,1-1.711-1.711c-.47-1.185-.361-4-.361-5.3s-.1-4.124.361-5.3a3.037,3.037,0,0,1,1.711-1.711c1.185-.47,4-.361,5.3-.361s4.124-.1,5.3.361a3.037,3.037,0,0,1,1.711,1.711c.47,1.185.361,4,.361,5.3S16.412,44.947,15.942,46.128Z"
      transform="translate(0.075 -31.825)"
      :fill="fillColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "InstagramIcon",
  props: {
    fillColor: {
      type: String,
      default: "#4c4c4c",
    },
    size: {
      type: Number,
      default: 18,
    },

    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
