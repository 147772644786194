<template>
  <div class="edit-icon">
    <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 27 24" @click="show = true">
      <path
        d="M18.872,4,23.1,8.223a.458.458,0,0,1,0,.647L12.862,19.108l-4.35.483A.912.912,0,0,1,7.5,18.583l.483-4.35L18.225,4a.458.458,0,0,1,.647,0Zm7.594-1.073L24.178.634a1.833,1.833,0,0,0-2.588,0L19.931,2.294a.458.458,0,0,0,0,.647l4.228,4.228a.458.458,0,0,0,.647,0l1.659-1.659A1.833,1.833,0,0,0,26.466,2.922ZM18,16.323V21.1H3V6.1H13.772a.576.576,0,0,0,.4-.164l1.875-1.875a.563.563,0,0,0-.4-.961H2.25A2.251,2.251,0,0,0,0,5.345v16.5A2.251,2.251,0,0,0,2.25,24.1h16.5A2.251,2.251,0,0,0,21,21.845v-7.4a.564.564,0,0,0-.961-.4l-1.875,1.875A.576.576,0,0,0,18,16.323Z"
        transform="translate(0 -0.1)"
        :fill="fillColor"
      />
    </svg>
    <div class="edit-icon__dialog" v-show="show">
      <div class="edit-icon__dialog__container">
        <div class="edit-icon__dialog__content">
          <div class="title">メンバーの権限を変更</div>
          <div class="image mt-40px">
            <profile-icon :src="member.user?.logo"/>
          </div>
          <div class="form">
            <div class="name mt-20px d-flex">
              <h4 class="label mr-10px">ユーザー名</h4>
              <h4>{{ member.user?.username }}</h4>
            </div>
            <div class="mt-20px d-flex">
              <h4 class="label mr-10px">メールアドレス</h4>
              <h4>{{ member.user?.email }}</h4>
            </div>
            <div class="mt-20px d-flex align-items-center">
              <h4 class="label mr-10px">権限</h4>
              <base-select-box :width="180" item-value="key" :items="MemberRole.getAll()" v-model="memberRole" disable-lookup></base-select-box>
            </div>
          </div>
          <div class="buttons d-flex justify-content-center mt-40px">
            <base-button :size="'short'" class="mr-20px" @click="updateMemberRole">変更</base-button>
            <base-button :size="'short'" :color="'dark'" @click="show = false">キャンセル</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { MemberRole } from "@/enum/memberRole";
import { CompanyMember } from "@/types";
import { useStore } from "vuex";
import BaseSelectBox from "@/components/BaseSelectBox.vue";

export default defineComponent({
  name: "EditIcon",
  components: {
    BaseSelectBox,
    BaseButton,
    ProfileIcon,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object as PropType<CompanyMember>,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      MemberRole,
      formErrorMessage: null as string | null,
      memberId: (props.member || { id: 0 }).id,
      memberRole: MemberRole.getObject((props.member || { role: 0 }).role),
    });

    const updateMemberRole = () => {
      state.formErrorMessage = null;
      store.dispatch("members/updateMemberRole", { role: state.memberRole, memberId: state.memberId });
    };
    return { ...toRefs(state), updateMemberRole };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.edit-icon {
  & svg {
    &:hover {
      opacity: 0.5;
    }
  }
  &__dialog {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 400px;
      padding: 40px 100px;
      top: 100px;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      & .title {
        font-size: 18px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        text-align: center;
      }
      & .image {
        text-align: center;
        & img {
          width: 80px;
          height: 80px;
        }
      }
      & .form {
        margin: 0 auto;
        width: fit-content;
      }
      & .label {
        width: 100px;
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
    }
  }
}
</style>
