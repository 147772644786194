<template>
  <div class="base-container hashtag-search">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header display-type="SEARCH" :key="searchUsernameSuccess"></base-header>
      <div v-if="company?.isExpired === false">
        <div class="search">
          <div class="search__contents">
            <h1>ハッシュタグで検索（ベータ版）</h1>
            <h3>関連するハッシュタグやハッシュタグが使われている投稿、<br>ハッシュタグを使っているインフルエンサーを検索することができます</h3>
            <div class="search__contents__buttons d-flex">
              <base-button color="blue" type="toggle" :selected="currentSearchTab === 'instagram'" @click="setCurrentSearchTab('instagram')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span></div>
              </base-button>
              <base-button color="white" type="toggle" :selected="currentSearchTab === 'twitter'" @click="setCurrentSearchTab('twitter')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><twitter-icon :size="24" fill-color="white" class="mr-5px" /><span>Twitter</span></div>
              </base-button>
            </div>
            <div class="search__contents__area d-flex justify-content-center">
              <combo-box :width="600" :sm-width="260" :height="60" :border-width="0" placeholder="ハッシュタグを入力" v-model="hashtag" @update:inputValue="handleHashtagChange" store-state-name="hashtags" store-name="hashtags"></combo-box>
              <div class="search__contents__area__button d-flex align-items-center justify-content-center" @click="searchHashtag">
                <search-icon />
              </div>
            </div>
            <div class="search__contents__area__error mt-20px" v-show="formErrorMessage || errorMessage">{{ formErrorMessage }}{{ errorMessage }}</div>
          </div>
        </div>
        <div class="search-result" v-if="hashtagRelationSuccess || hashtagPostsSuccess || hashtagInfluencersSuccess">
          <div class="d-flex mt-40px mb-10px align-items-end">
            <h3 class="mr-10px">検索結果</h3>
          </div>
          <div class="result__container">
            <div class="result__section">
              <h3>ハッシュタグの関係性<span>※結果の生成に時間がかかります</span></h3>
              <div class="result__section__content result__relation">
                <div class="loading" v-if="hashtagRelationLoading">
                  <loading width="80px"></loading>
                </div>
                <div class="result__relation__ranking" v-if="hashtagRelation">
                  <h4>『{{ currentHashtag }}』とよく一緒に使われているハッシュタグ一覧</h4>
                  <div class="result__relation__ranking__table__wrapper">
                    <div class="result__relation__ranking__table left">
                      <basic-table
                        :items="getSeparate2Arr(hashtagRelation?.hashtagRanking)[0]"
                        :key="hashtagRelation?.hashtagRanking[0]?.tag"
                        v-if="hashtagRelation?.hashtagRanking?.length"
                        :headers="[{ text: 'ハッシュタグ', value:'tag' }, { text: '同時に使われる割合', value: 'useRate' }]"
                        cell-height="40px"
                      >
                        <template #useRate="{ useRate }">{{ String(useRate * 100).slice(0,4) }}%</template>
                      </basic-table>
                    </div>
                    <div class="result__relation__ranking__table right">
                      <basic-table
                        :items="getSeparate2Arr(hashtagRelation?.hashtagRanking)[1]"
                        v-show="getSeparate2Arr(hashtagRelation?.hashtagRanking)[1]"
                        v-if="hashtagRelation?.hashtagRanking?.length"
                        :key="hashtagRelation?.hashtagRanking[0]?.tag"
                        :headers="[{ text: 'ハッシュタグ', value:'tag' }, { text: '同時に使われる割合', value: 'useRate' }]"
                        cell-height="40px"
                      >
                        <template #useRate="{ useRate }">{{ String(useRate * 100).slice(0,4) }}%</template>
                      </basic-table>
                    </div>
                    <div class="result__relation__ranking__table__no-data" v-if="!hashtagRelation?.hashtagRanking?.length">一緒に投稿されているハッシュタグは見つかりませんでした</div>
                  </div>
                </div>
                <div class="result__relation__relations" v-if="hashtagRelation">
                  <h4>『{{ currentHashtag }}』に関連するハッシュタグのワードクラウド</h4>
                  <h5>※各プロットはそれぞれのハッシュタグの投稿量を表し、<br>それぞれのハッシュタグ間の距離が近ければ近いほどよく一緒に使われていることを表しています。</h5>
                  <div class="result__relation__relations__graph">
                    <bubble-chart :chart-data="getChartData(hashtagRelation?.hashtagRelations)" :options="getChartOption(hashtagRelation?.hashtagRelations)" v-if="hashtagRelation?.hashtagRelations?.x?.length"></bubble-chart>
                    <div class="result__relation__relations__graph__no-data" v-else>『{{ currentHashtag }}』と一緒によく使われているハッシュタグが少ないため<br>図を生成できませんでした</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="result__section">
              <h3>ハッシュタグが使われている投稿（{{ getLocaleString(totalHashtagPosts) }}件）</h3>
              <div class="result__section__content">
                <div class="loading" v-if="hashtagPostsLoading">
                  <loading width="80px"></loading>
                </div>
                <div class="influencer-detail__post__wrapper" v-if="currentSnsType === 'instagram'" v-show="!hashtagPostsLoading">
                  <div class="influencer-detail__post" v-for="item in hashtagPosts" :key="item.id">
                    <div class="influencer-detail__post__account__card">
                      <div class="influencer-detail__post__account__profile">
                        <img :src="item.postedBy.picture" />
                        <div class="influencer-detail__post__account__profile__name">
                          <h4>{{ item.postedBy.fullname }}</h4>
                          <h5>{{ item.postedBy.username }}</h5>
                        </div>
                      </div>
                      <div class="influencer-detail__post__account__actions">
                        <open-detail-icon
                            v-model="detailChecked"
                            class="mb-20px"
                            @confirmView="viewInfluencerDetail(item.postedBy.id)"
                            :id="`detail-${item.postedBy.id}`"
                            disable-icon
                        >
                          <base-button size="short">詳細を表示</base-button>
                        </open-detail-icon>
                      </div>
                    </div>
                    <div class="influencer-detail__post__post__card">
                      <div class="influencer-detail__post__thumbnail">
                        <iframe :src="item.url.replace(/\/$/, '') + '/embed/'"></iframe>
                      </div>
                      <div class="influencer-detail__post__content">
                        <div class="influencer-detail__post__content__date">{{ getJPDate(item.postedAt) }}</div>
                        <div class="influencer-detail__post__content__engagements">
                          <div class="influencer-detail__post__content__engagement likes">
                            <img src="../assets/img/liker-icon.svg" alt="いいね数" />
                            <div v-if="item.likes && item.likes >= 0">{{ getLocaleString(item.likes) }}</div>
                            <div v-else>データ非表示</div>
                          </div>
                          <div class="influencer-detail__post__content__engagement comments"><img src="../assets/img/comment-icon.svg" alt="コメント数" />{{ getLocaleString(item.comments) }}</div>
                        </div>
                        <div class="influencer-detail__post__content__text__wrapper">
                          <div class="influencer-detail__post__content__text">{{ item.text }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="influencer-detail__post__wrapper" v-else v-show="!hashtagPostsLoading">
                  <div class="influencer-detail__post twitter" v-for="item in hashtagPosts" :key="item.id">
                    <div class="influencer-detail__post__account__card">
                      <div class="influencer-detail__post__account__profile">
                        <img :src="item.postedBy.picture" />
                        <div class="influencer-detail__post__account__profile__name">
                          <h4>{{ item.postedBy.fullname }}</h4>
                          <h5>{{ item.postedBy.username }}</h5>
                        </div>
                      </div>
                      <div class="influencer-detail__post__account__actions">
                        <open-detail-icon
                            v-model="detailChecked"
                            class="mb-20px"
                            @confirmView="viewInfluencerDetail(item.postedBy.id)"
                            :id="`detail-${item.postedBy.id}`"
                            disable-icon
                        >
                          <base-button size="short">詳細を表示</base-button>
                        </open-detail-icon>
                      </div>
                    </div>
                    <div class="influencer-detail__post__post__card">
                      <Suspense v-if="item.embedHtml">
                        <template #default>
                          <iframe :src="item.url" :srcdoc="item.embedHtml" frameborder="0"></iframe>
                        </template>
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
              <base-pagination :total="totalHashtagPosts" :total-pages="totalHashtagPostPages" :currentPage="currentHashtagPostPage" @move="submitHashtagPostsPages" v-show="!hashtagPostsLoading"></base-pagination>
            </div>
            <div class="result__section">
              <h3>ハッシュタグを使っているインフルエンサー（{{ getLocaleString(totalHashtagInfluencers) }}件）</h3>
              <div class="result__section__content">
                <div class="loading" v-if="hashtagInfluencersLoading">
                  <loading width="80px"></loading>
                </div>
                <base-table :items="safeHashtagInfluencers" :key="safeHashtagInfluencers[0]" v-show="!hashtagInfluencersLoading" />
              </div>
              <base-pagination :total="totalHashtagInfluencers" :total-pages="totalHashtagInfluencerPages" :currentPage="currentHashtagInfluencerPage" @move="submitHashtagInfluencersPages" v-show="!hashtagInfluencersLoading"></base-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import SearchIcon from "@/components/icon/SearchIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import ComboBox from "@/components/ComboBox.vue";
import { useStore } from "vuex";
import {
  ClickEvent,
  HashtagSearchRequestParams,
  Influencer,
  HashtagInfluencer,
  HashtagRelations, ChartPlug, HashtagRequestPrams, AddInfluencerListParams
} from "@/types";
import BaseTable from "@/components/BaseTable.vue";
import BasicTable from "@/components/BasicTable.vue";
import BasePagination from "@/components/BasePagination.vue";
import Loading from "@/components/Loading.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import { ChartData } from "chart.js";
import { BubbleChart } from "vue-chart-3";
import { set } from "vue-gtag";

export default defineComponent({
  name: "HashtagSearch",
  components: {
    Sidebar,
    BaseHeader,
    BasicTable,
    BaseTable,
    ComboBox,
    SearchIcon,
    BubbleChart,
    BaseButton,
    BasePagination,
    Loading,
    TwitterIcon,
    InstagramIcon,
    OpenDetailIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    const state = reactive({
      currentSearchTab: "instagram",
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      success: computed(() => store.state.influencers.usernameSearchSuccess),
      total: computed(() => store.state.influencers.total),
      formErrorMessage: null as string | null,
      hashtag: null as string | null,
      type: null as number | null,
      influencers: computed(() => store.state.influencers.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      searchUsernameSuccess: computed(() => store.state.influencers.usernameSearchSuccess),
      hashtagInfluencers: computed(() => store.state.influencers.hashtagInfluencers),
      hashtagPosts: computed(() => store.state.influencers.hashtagPosts),
      safeHashtagInfluencers: [] as Influencer[],
      hashtagItems: computed(() => store.state.hashtags.hashtags),
      hashtagRelation: computed(() => store.state.influencers.hashtagRelation),
      hashtagInfluencersSuccess: computed(() => store.state.influencers.hashtagInfluencersSuccess),
      hashtagPostsSuccess: computed(() => store.state.influencers.hashtagPostsSuccess),
      hashtagRelationSuccess: computed(() => store.state.influencers.hashtagRelationSuccess),
      hashtagInfluencersLoading: computed(() => store.state.influencers.hashtagInfluencersLoading),
      hashtagPostsLoading: computed(() => store.state.influencers.hashtagPostsLoading),
      hashtagRelationLoading: computed(() => store.state.influencers.hashtagRelationLoading),
      hashtagInfluencersErrorMessage: computed(() => store.state.influencers.hashtagInfluencersErrorMessage),
      hashtagPostsErrorMessage: computed(() => store.state.influencers.hashtagPostsErrorMessage),
      hashtagRelationErrorMessage: computed(() => store.state.influencers.hashtagRelationErrorMessage),
      currentHashtag: computed(() => store.state.influencers.currentHashtag),
      currentHashtagPostPage: computed(() => store.state.influencers.currentHashtagPostPage),
      totalHashtagPosts: computed(() => store.state.influencers.totalHashtagPosts),
      totalHashtagPostPages: computed(() => store.state.influencers.totalHashtagPostPages),
      currentHashtagInfluencerPage: computed(() => store.state.influencers.currentHashtagInfluencerPage),
      totalHashtagInfluencers: computed(() => store.state.influencers.totalHashtagInfluencers),
      totalHashtagInfluencerPages: computed(() => store.state.influencers.totalHashtagInfluencerPages),
      currentSnsType: null as null | string,
      chartData: [] as ChartData[],
      detailChecked: false,
    });
    set({ userId: state.user?.id });
    const setCurrentSearchTab = (index: string) => {
      state.currentSearchTab = index;
    };
    const searchHashtag = (event: ClickEvent) => {
      state.formErrorMessage = null;
      if (event.keyCode !== 229) {
        if (!state.hashtag) {
          state.formErrorMessage = "ハッシュタグは必須です";
        } else {
          state.currentSnsType = null;
          store.dispatch("influencers/hashtagSearchInitialize");
          const params: HashtagSearchRequestParams = {
            hashtag: state.hashtag,
            snsType: state.currentSearchTab,
            page: 1,
          };
          submitHashtagInfluencers(params);
          submitHashtagPosts(params);
          store.dispatch("influencers/getHashtagRelation", params);
          state.currentSnsType = params.snsType;
        }
      }
    };
    const submitHashtagInfluencers = (params: HashtagRequestPrams) => {
      store.dispatch("influencers/getHashtagInfluencers", params);
    };
    const submitHashtagPosts = (params: HashtagRequestPrams) => {
      store.dispatch("influencers/getHashtagPosts", params);
    };
    const submitHashtagInfluencersPages = (page: number) => {
      state.formErrorMessage = null;
      if (!state.currentHashtag) {
        state.formErrorMessage = "ハッシュタグは必須です";
      } else {
        const params: HashtagSearchRequestParams = {
          hashtag: state.currentHashtag,
          snsType: state.currentSearchTab,
          page: page,
        };
        submitHashtagInfluencers(params);
      }
    };
    const submitHashtagPostsPages = (page: number) => {
      state.formErrorMessage = null;
      if (!state.currentHashtag) {
        state.formErrorMessage = "ハッシュタグは必須です";
      } else {
        const params: HashtagSearchRequestParams = {
          hashtag: state.currentHashtag,
          snsType: state.currentSearchTab,
          page: page,
        };
        submitHashtagPosts(params);
      }
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const getJPDate = (val: string) => {
      const date = new Date(String(val).replace(/-/g, "/"));
      return Number(date.getFullYear()) + "年" + Number(date.getMonth() + 1) + "月" + Number(date.getDate()) + "日";
    };
    const getLocaleString = (val: number) => {
      return val ? val.toLocaleString() : 0;
    };
    const handleHashtagChange = (e: null | string) => {
      if (e) {
        const params: HashtagRequestPrams = {
          snsType: state.currentSearchTab,
          hashtag: e,
        };
        store.dispatch("hashtags/getHashtags", { params });
      }
    };
    let hashtagInfluencersSuccess = computed(() => state.hashtagInfluencersSuccess);
    watch(hashtagInfluencersSuccess, () => {
      store.dispatch("users/loadCurrentUser");
    });
    let hashtagInfluencers = computed(() => state.hashtagInfluencers);
    watch(hashtagInfluencers, (val: HashtagInfluencer[]) => {
      const influencers = [] as Influencer[];
      val.forEach((val: HashtagInfluencer) => {
        const influencer: Influencer = Object.assign({}, val.account);
        influencer.hashtagCount = val.count;
        influencers.push(influencer);
      });
      state.safeHashtagInfluencers = influencers;
    });
    const getChartData = (val: HashtagRelations) => {
      if (!val) return Object();
      const len = val.labels.length;
      const counts = val.counts.slice().map((x: number) => x / Number(Math.max(...val.counts)) * 50);
      const datasets = [];
      for (let i = 0; i < len; i++) {
        datasets.push({data: [{ x: val.x[i], y: val.y[i], r: counts[i] }], label: val.labels[i], backgroundColor: `rgba(255, ${Math.round((len - i) * 255 / len)}, 80, 0.4)`});
      }
      return {
        labels: val.labels,
        datasets: datasets,
      };
    };
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    const getChartOption = (val: HashtagRelations) => {
      if (!val) return Object();
      return {
        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
            font: {},
          },
          datalabels: {
            align: "center",
            offset: 0,
            font: {
              size: 14,
              weight: 700,
            },
            formatter: function (value: ChartPlug, context: ChartPlug) {
              console.log(context);
              return val.labels[context.datasetIndex];
            },
          },
        },
      };
    };
    const getSeparate2Arr = (arr: any[]) => {
      const len = arr.length;
      return [arr.slice(0, Math.ceil(len / 2)), arr.slice(Math.ceil(len / 2), len)];
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
      searchHashtag,
      closeLoadingDialog,
      getJPDate,
      getLocaleString,
      getChartData,
      getChartOption,
      getSeparate2Arr,
      handleHashtagChange,
      submitHashtagInfluencersPages,
      submitHashtagPostsPages,
      viewInfluencerDetail,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/img/hashtag-search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  &__contents {
    padding: 50px 0;
    @include mq(xs) {
      padding: 40px 0 60px 0;
    }
    @include mq(lg) {
      padding: 50px 0;
    }
    & h1 {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 18px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    & h3 {
      color: white;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 40px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 20px;
      }
      @include mq(lg) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    &__buttons {
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;
      & svg {
        margin-top: 2px;
      }
      & .button {
        width: 140px;
        @include mq(xs) {
          width: 60px;
        }
        @include mq(lg) {
          width: 140px;
        }
      }
      & span {
        display: inline-block;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: inline-block;
        }
      }
    }
    &__area {
      & input {
        width: 580px;
        height: 60px;
        border: 2px solid white;
        border-radius: 5px 0 0 5px;
        font-size: 18px;
        font-weight: 700;
        color: map-get($font-colors, "default");
        padding: 0 20px;
        @include mq(xs) {
          width: 90%;
          font-size: 14px;
        }
        @include mq(lg) {
          width: 580px;
          font-size: 18px;
        }
        &:focus {
          border: 2px solid map-get($colors, "accent");
          outline: none;
        }
        &::placeholder {
          color: map-get($colors, "gray200");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 18px;
          }
        }
      }
      &__button {
        width: 60px;
        height: 60px;
        background-color: #ee7382;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        &:hover {
          background-color: #efabb4;
        }
      }
      &__error {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
    }
  }
}
.result {
  &__container {
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    & h3 {
      & > span {
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    & .loading {
      width: 80px;
      margin: 40px auto;
    }
  }
  &__section {
    margin-bottom: 80px;
    & h3 {
      margin-bottom: 20px;
    }
  }
  &__relation {
    & h4 {
      font-weight: 900;
      margin: 30px 0 10px;
    }
    & h5 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    &__ranking {
      width: 80%;
      margin: 0 auto;
      &__table {
        width: 49%;
        min-width: 300px;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: 49%;
        }
        &__wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          @include mq(xs) {
            display: block;
          }
          @include mq(lg) {
            display: flex;
          }
        }
        &__no-data {
          width: 100%;
          min-width: 300px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #9c9c9c;
          margin: 20px 0;
        }
      }
    }
    &__relations {
      width: 80%;
      margin: 20px auto 0 auto;
      &__graph {
        width: 800px;
        border: solid 8px map-get($colors, "gray200");
        border-radius: 8px;
        margin: 0 auto;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: calc(100% - 20px);
        }
        &__no-data {
          width: 100%;
          height: 100%;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          vertical-align: center;
          color: map-get($colors, "gray200");
          margin: 80px 0;
        }
      }
    }
  }
}
.result-none {
  width: 100%;
  background: white;
  padding: 120px 0;
  border-radius: 10px;
  height: fit-content;
  text-align: center;
  align-items: center;
  @include mq(xs) {
    padding: 60px 0;
    margin-bottom: 40px;
  }
  @include mq(lg) {
    padding: 120px 0;
    margin-bottom: auto;
  }
  & h1,
  h3 {
    color: map-get($colors, "primary");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.influencer-detail {
  width: 100%;
  color: map-get($font-colors, "default");
  &__post {
    width: calc(50% - 50px);
    padding: 20px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    @include mq(xs) {
      width: calc(100% - 30px);
      height: 895px;
    }
    @include mq(lg) {
      width: calc(50% - 50px);
      height: 705px;
    }
    &__post__card {
      display: flex;
      width: 100%;
      @include mq(xs) {
        display: block;
        height: fit-content;
      }
      @include mq(lg) {
        display: flex;
        height: calc(100% - 160px);
      }
    }
    &__account {
      &__card {
        display: flex;
        align-content: space-between;
        width: 100%;
        height: 60px;
        margin: 20px auto 20px 0;
      }
      &__profile {
        width: 100%;
        display: flex;
        & > img {
          width: 60px;
          height: 60px;
          display: block;
          border-radius: 9999px;
        }
        &__name {
          margin-left: 10px;
          & h4 {
            font-weight: 700;
          }
        }
      }
      &__actions {
        width: 50%;
        text-align: right;
      }
    }
    &.twitter {
      display: block;
      height: 700px;
      padding: 20px;
      margin: 0 20px 30px 0;
      @include mq(xs) {
        width: calc(100% - 40px);
      }
      @include mq(lg) {
        width: calc(30% - 20px);
      }
      & iframe {
        height: 600px;
        margin: 0 auto;
      }
      &:nth-of-type(2n) {
        margin-right: 20px;
      }
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: flex;
      }
    }
    &__thumbnail {
      margin-right: 20px;
      width: 300px;
      height: 600px;
      position: relative;
      border-radius: 10px;
      background-image: url("../assets/img/post-background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 16px;
      @include mq(xs) {
        margin: auto;
      }
      @include mq(lg) {
        margin: 0 20px 0 0;
      }
      & iframe {
        width: 300px !important;
        height: 600px !important;
        border: 2px solid #f7f7f7;
        border-radius: 10px;
      }
    }
    &__content {
      position: relative;
      width: calc(100% - 320px);
      @include mq(xs) {
        width: 100%;
      }
      @include mq(lg) {
        width: calc(100% - 220px);
      }
      &__date {
        font-size: 12px;
        font-weight: 700;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 0;
        }
      }
      &__text {
        overflow: scroll;
        @include mq(xs) {
          height: 135px;
        }
        @include mq(lg) {
          height: 100%;
        }
        &__wrapper {
          font-size: 13px;
          height: 100%;
          margin-top: 10px;
        }
      }
      &__engagements {
        display: flex;
        bottom: 0;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 10px;
        }
      }
      &__engagement {
        display: flex;
        font-size: 14px;
        font-weight: 900;
        margin-right: 20px;
        & img {
          width: 10px;
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      & .likes {
        color: #ffa0a0;
      }
      & .comments {
        color: #79b5db;
      }
    }
  }
}
</style>
<style lang="scss">
.hashtag-search {
  .combobox {
    &__wrapper {
      border-radius: 5px 0 0 5px;
      border: 2px solid white;
      height: calc(100% - 4px);
      &:focus-within {
        outline: none;
        border: 2px solid #ee7382;
        box-shadow: none;
      }
    }
    & input {
      &::placeholder {
        color: #9c9c9c;
        font-weight: 900;
      }
    }
  }
}
</style>
