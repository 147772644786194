import axios from "@/store/axios";
import { transformResponse } from "@/store/axios";

interface State {
  signupStatus: "";
  errorMessage: string | null;
}

const state: State = {
  signupStatus: "",
  errorMessage: null,
};
const signUp = {
  namespaced: true,
  state,
};

export default signUp