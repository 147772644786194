<template>
  <div class="selectbox" :style="styles">
    <div :class="[label ? 'selectbox__label' : 'd-none']">
      <span :class="['selectbox__label--' + color, 'selectbox__label-title']">
        {{ label }}
      </span>
      <span class="selectbox__required-label" v-show="required">必須</span>
    </div>
    <div class="selectbox__container" :style="styles">
      <select :class="['selectbox__input--' + color, 'selectbox__input', 'custom-select']" :style="styles" v-model="selectedItem" @focus="setSelecting(true)" ref="SelectBox" :key="selectedItem[itemText]"></select>
      <div class="selected-item" :style="styles" v-if="!multiple">
        <span>{{ selectedItem[itemText] || selectedItem[itemName] }}</span>
      </div>
      <div class="selected-item-multiple" v-else>
        <div class="selected-item-multiple-container" :style="styles" v-show="multiple" v-for="mItem in selectedItems" :key="mItem[itemValue]">
          <span>{{ mItem[itemText] || mItem[itemName] }}</span>
        </div>
      </div>
    </div>
    <div :class="['selectbox__items', isSelecting ? 'selecting' : '']" :style="styles" v-click-outside="onClickOutside">
      <div class="selectbox__item selectbox__item__nodata" v-show="options.length < 1">{{ noDataText }}</div>
      <div :class="['selectbox__item', selectedItems.includes(option) ? 'selected' : '']" @click="setSelectedItem(option)" v-for="(option, index) in options" :key="index">
        {{ option.text || option.jpName }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "SelectBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 240,
    },
    height: {
      type: Number,
      default: 48,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemName: {
      type: String,
      default: "jpName",
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String as () => any | any[],
    },
  },
  setup(props, context) {
    const initialSelectedItem = {} as any;
    initialSelectedItem[props.itemText] = "";
    const state = reactive({
      selectedItem: (props.modelValue !== null ? props.modelValue : initialSelectedItem) as any,
      selectedItems: (props.modelValue !== null ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : [initialSelectedItem]) as any[],
      isSelecting: false,
    });
    const styles = computed(() => {
      return {
        "--width": props.width,
        "--height": props.height,
      };
    });
    const SelectBox = ref<HTMLImageElement>();
    const changeSelected = () => {
      if (props.multiple) {
        if (props.returnObject) {
          context.emit("update:modelValue", state.selectedItems);
          context.emit("updateSelected");
        } else {
          context.emit(
            "update:modelValue",
            state.selectedItems.slice().map((x: any) => x[props.itemValue])
          );
          context.emit("updateSelected");
        }
      } else {
        if (props.returnObject) {
          context.emit("update:modelValue", state.selectedItem);
          context.emit("updateSelected");
        } else {
          context.emit("update:modelValue", state.selectedItem[props.itemValue]);
          context.emit("updateSelected");
        }
      }
    };
    const setSelectedItem = (val: any) => {
      if (props.multiple) {
        if (props.allowDistinct || !state.selectedItems.includes(val)) {
          state.selectedItems.push(val);
        } else if (!props.allowDistinct || state.selectedItems.includes(val)) {
          const idx = state.selectedItems
            .slice()
            .map((x: any) => x[props.itemValue])
            .indexOf(val[props.itemValue]);
          state.selectedItems.splice(idx, 1);
        }
        changeSelected();
        state.isSelecting = false;
      } else {
        state.selectedItem = val;
        changeSelected();
        state.isSelecting = false;
      }
    };
    const setSelecting = (condition: boolean) => {
      state.isSelecting = condition;
    };
    const onClickOutside = (event: any) => {
      if (event.target !== SelectBox.value) state.isSelecting = false;
    };
    return {
      ...toRefs(state),
      changeSelected,
      styles,
      setSelectedItem,
      setSelecting,
      onClickOutside,
      SelectBox,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
.selectbox {
  position: relative;
  width: calc(var(--width) * 1px);
  &:focus-within {
    & .selectbox__container::after {
      transform: rotate(-90deg) scaleY(1.414);
      margin-right: 10px;
    }
  }
  &__container {
    position: relative;
    width: calc(var(--width) * 1px);
    height: fit-content;
    &::after {
      content: "▶︎";
      font-size: 10px;
      font-weight: 500;
      color: #79b5db;
      width: 10px;
      height: 10px;
      transform: rotate(90deg) scaleY(1.414);
      position: absolute;
      top: calc(50% - 5px);
      right: 10px;
      pointer-events: none;
    }
  }
  &__input {
    height: calc(var(--height) * 1px);
    width: 100%;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    font-size: 14px;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px map-get($colors, "blue300");
    }
    &--white {
      border: none;
    }
    &--primary {
      border: solid 2px #79b5db;
    }
  }
  &__required-label {
    color: map-get($colors, "accent");
    font-size: 10px;
    font-weight: 500;
    margin-left: 8px;
    vertical-align: text-bottom;
  }
  &__label {
    text-align: left;
    font-weight: 900;
    font-size: 14px;
    margin: 8px 10px 8px 0;
    &--primary {
      color: map-get($font-colors, "default");
    }
    &--white {
      color: white;
      font-weight: 700;
    }
  }
  &__items {
    display: none;
    position: absolute;
    top: var(--height);
    left: 0;
    width: calc(var(--width) * 1px - 4px);
    height: fit-content;
    max-height: 240px;
    overflow-y: scroll;
    background-color: white;
    border: 2px solid map-get($colors, "primary");
    border-radius: 5px;
    margin-top: 5px;
    z-index: 2;
    &.selecting {
      display: block;
    }
    &::-webkit-scrollbar {
      width: 10px;
      display: block!important;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: map-get($colors, "primary");
      border-radius: 10px;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, .3);
    }
  }
  &__item {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 14px;
    color: map-get($font-colors, "default");
    &:hover {
      background: map-get($colors, "gray100");
    }
    &.__nodata:hover {
      background-color: unset;
    }
  }
  & .selected {
    background: map-get($colors, "gray100");
    position: relative;
    padding-left: 30px;
    &::before {
      position: absolute;
      width: 16px;
      height: 16px;
      content: "×";
      top: 13px;
      left: 7px;
      font-size: 10px;
      font-weight: 700;
      background-color: map-get($colors, "gray200");
      border-radius: 10px;
      text-align: center;
      color: white;
    }
  }
  & option {
    display: none;
  }
  & .selected-item {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    align-items: center;
    width: calc(100% - 60px);
    height: calc(var(--height) * 1px);
    top: 0;
    left: 20px;
    font-size: 14px;
    color: map-get($font-colors, "default");
    pointer-events: none;
    &-multiple {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      align-items: center;
      width: calc(100% - 60px);
      height: calc(var(--height) * 1px);
      top: 0;
      left: 20px;
      font-size: 14px;
      color: map-get($font-colors, "default");
      pointer-events: none;
      &-container {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
