
import { defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { Invoice, InvoiceData } from "@/types";
import { formatDate } from "@/plugins/formatter";

export default defineComponent({
  name: "InvoiceTable",
  components: { BaseButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: {
        add: true,
        trash: false,
      },
    });
    const sortByKey = (arr: [], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const getPlanName = (invoice: Invoice): string => {
      let temp = "";
      invoice.lines.data.map((x: InvoiceData) => {
        temp += x.plan?.nickname ? x.plan?.nickname : "" + " ";
      });
      return temp;
    };
    return { ...toRefs(state), sortByKey, getPlanName, formatDate };
  },
});
