<template>
  <div class="edit-icon">
    <svg
        class="material-design-icon__svg"
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
    >
      <g transform="translate(-471 -619)">
        <circle cx="8" cy="8" r="8" transform="translate(471 619)" fill="#198fd9"/>
        <g transform="translate(193.427 -384.261) rotate(45)">
          <rect width="8" height="1.231" rx="0.615" transform="translate(913 512.523)" fill="#fff"/>
          <rect width="7.877" height="1.125" rx="0.563" transform="translate(917.679 509.2) rotate(90)" fill="#fff"/>
        </g>
      </g>
    </svg>
  </div>
</template>




<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CancelButtonIcon",
  props: {
    fillColor: {
      type: String,
      default: "#707070"
    },
    size: {
      type: Number,
      default: 16
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.edit-icon {
  cursor: pointer;
}
</style>