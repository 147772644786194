<template>
  <div class="base-container__gray">
    <div class="dialog__container" v-if="(addInfluencerSuccess || listErrorMessage) && show.addInfluencer">
      <div class="dialog" v-if="addInfluencerSuccess">
        <div class="title">リストにインフルエンサーを保存しました</div>
        <div class="description"><check-mark></check-mark></div>
        <div class="description"><base-button size="short" color="dark" @click="show.addInfluencer = false"> OK </base-button></div>
      </div>
      <div class="dialog" v-else>
        <div class="title error">インフルエンサーの保存に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ listErrorMessage }}</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.addInfluencer = false"> 戻る </base-button></div>
      </div>
    </div>
    <div class="influencer-detail__dialog" v-show="show.add">
      <div class="influencer-detail__dialog__container">
        <div class="influencer-detail__dialog__content">
          <div class="influencer-detail__dialog__content__title mb-40px">インフルエンサーリストに追加する</div>
          <div class="influencer-detail__dialog__content__text mb-20px">インフルエンサーリストに追加をすると詳細閲覧としてカウントされます。</div>
          <div class="influencer-detail__dialog__content__text mb-20px">
            ご利用中のプランの詳細閲覧可能数は<span>毎月{{ company?.plan.detailLimitation }}人</span>までです。
          </div>
          <div class="influencer-detail__dialog__content__text mb-40px">
            今月の残りの閲覧可能数は<span>{{ remainingViewing() }}人</span>となっています。
          </div>
          <div class="mb-20px">
            <base-button
              color="blue"
              size="wide"
              @click="
                addInfluencerList(influencerId);
                show.add = false;
              "
            >
              リストに追加する
            </base-button>
          </div>
          <div class="mb-20px">
            <base-button color="dark" size="wide" @click="show.add = false"> キャンセル </base-button>
          </div>
          <div>
            <base-checkbox v-model="checked" id="base-checkbox">
              <h4>次回から表示しない</h4>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
    <sidebar></sidebar>
    <div class="main">
      <base-header :header-title="'インフルエンサー詳細'" display-type="DETAIL" :key="setLoadSuccess"></base-header>
      <div class="influencer-detail__info">
        <div class="mr-20px">
          <h4>
            最終データ取得日 <span>{{ formatDate(account?.updatedAt) }}</span>
          </h4>
        </div>
        <div class="influencer-detail__info__right">
          <div class="influencer-detail report-button" v-if="account?.type === 0">
            <router-link v-if="influencerId" :to="{ name: 'InstagramInfluencerReport', params: { id: influencerId } }" target="_blank" rel="noreferrer noopener">
              <base-button color="primary" size="small" class="mr-20px"> レポートを表示 </base-button>
            </router-link>
          </div>
          <div class="influencer-detail report-button" v-else-if="account?.type === 1">
            <router-link v-if="influencerId" :to="{ name: 'YoutubeInfluencerReport', params: { id: influencerId } }" target="_blank" rel="noreferrer noopener">
              <base-button color="primary" size="small" class="mr-20px"> レポートを表示 </base-button>
            </router-link>
          </div>
          <div class="influencer-detail report-button" v-else-if="account?.type === 2">
            <router-link v-if="influencerId" :to="{ name: 'TiktokInfluencerReport', params: { id: influencerId } }" target="_blank" rel="noreferrer noopener">
              <base-button color="primary" size="small" class="mr-20px"> レポートを表示 </base-button>
            </router-link>
          </div>
          <div class="influencer-detail report-button" v-else-if="account?.type === 3">
            <router-link v-if="influencerId" :to="{ name: 'TwitterInfluencerReport', params: { id: influencerId } }" target="_blank" rel="noreferrer noopener">
              <base-button color="primary" size="small" class="mr-20px"> レポートを表示 </base-button>
            </router-link>
          </div>
          <add-list-icon v-if="company?.plan?.myListLimitation !== 0" v-model="listChecked" v-model:selected-folder="selectedFolder" @addList="addInfluencerList(influencerId)" :id="`add-list-${influencerId}`" :influencer-id="influencerId" disable-icon small :key="count"></add-list-icon>
        </div>
      </div>
      <div class="dialog__container" v-if="loading || errorMessage">
        <div class="dialog" v-show="loading">
          <div class="title">データを読み込んでいます</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>反映されるまでしばらくお待ちください</h4></div>
        </div>
        <div class="dialog" v-show="errorMessage">
          <div class="title">{{ errorMessage || "データの取得・分析に時間が掛かっております" }}</div>
          <div class="description"><exclamation-mark :fill-color="'#198FD9'"></exclamation-mark></div>
          <div class="description">
            <h4 class="text-center" style="white-space: break-spaces">{{ errorMessageDetail || "詳細閲覧の回数は消費しません。\nお手数をおかけしますが、3分ほど時間を置いてから\n更新ボタンを押してください。" }}</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="closeLoadingDialog">戻る</base-button><base-button class="ml-20px" size="short" onclick="window.location.reload()">更新</base-button></div>
        </div>
      </div>
      <div class="influencer-detail" v-else-if="account?.type === 0 && success">
        <instagram-detail :items="account"></instagram-detail>
      </div>
      <div class="influencer-detail" v-else-if="account?.type === 1 && success">
        <youtube-detail :items="account"></youtube-detail>
      </div>
      <div class="influencer-detail" v-else-if="account?.type === 2 && success">
        <tiktok-detail :items="account"></tiktok-detail>
      </div>
      <div class="influencer-detail" v-else-if="account?.type === 3 && success">
        <twitter-detail :items="account"></twitter-detail>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, watch, ComputedRef } from "vue";
import InstagramDetail from "../components/InstagramDetail.vue";
import YoutubeDetail from "../components/YoutubeDetail.vue";
import TiktokDetail from "../components/TiktokDetail.vue";
import TwitterDetail from "@/components/TwitterDetail.vue";
import Sidebar from "../components/Sidebar.vue";
import BaseHeader from "../components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { useRoute, useRouter } from "vue-router";
import { formatDate } from "@/plugins/formatter";
import { useStore } from "vuex";
import { AddInfluencerListParams, Influencer } from "@/types/models/influencers";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import AddListIcon from "@/components/icon/AddListIcon.vue";

export default defineComponent({
  name: "InfluencerDetail",
  components: {
    YoutubeDetail,
    InstagramDetail,
    TiktokDetail,
    TwitterDetail,
    AddListIcon,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    CheckMark,
    ExclamationMark,
    Loading,
  },
  props: {
    requiredConfirm: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const influencerId = route.params.id;
    store.dispatch("influencers/getInfluencerDetail", influencerId);
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      user: computed(() => store.state.users.user),
      errorMessage: computed(() => store.state.influencers.getInfluencerErrorMessage),
      errorMessageDetail: computed(() => store.state.influencers.getInfluencerErrorMessageDetail),
      listErrorMessage: computed(() => store.state.influencers.influencerListErrorMessage),
      loading: computed(() => store.state.influencers.loading),
      account: computed(() => store.state.influencers.influencer) as ComputedRef<Influencer>,
      success: computed(() => store.state.influencers.getInfluencerSuccess),
      show: {
        dialog: false,
        add: false,
        addInfluencer: false,
      },
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      addInfluencerSuccess: computed(() => store.state.influencers.addInfluencerListSuccess),
      loadInfluencerSuccess: computed(() => store.state.influencers.getInfluencerSuccess),
      listChecked: false,
      refreshChecked: false,
      checked: false,
      selectedFolder: null as number | null,
      count: 1,
      mounted: false,
    });
    const closeLoadingDialog = () => {
      router.push({ name: "Home" });
    };
    let success = computed(() => state.success);
    watch(success, (newVal: boolean, oldVal: boolean) => {
      if (!oldVal && newVal) state.show.dialog = false;
    });
    const remainingViewing = () => {
      if (state.company) {
        return state.company.plan.detailLimitation - state.company.actions.slice(-1)[0].detailCount;
      }
      return 0;
    };
    const addInfluencerList = (id: string) => {
      store.dispatch("influencers/initailizeAddInfluencerList");
      if (state.selectedFolder) {
        const params: AddInfluencerListParams = {
          influencerList: state.selectedFolder,
          influencers: [{ id: id }],
        };
        store.dispatch("influencers/addInfluencerList", params);
      }
      state.show.addInfluencer = true;
      store.dispatch("influencers/getCompaniesList");
    };
    let setLoadSuccess = computed(() => state.loadInfluencerSuccess);
    watch(setLoadSuccess, (val: boolean) => {
      if (val) store.dispatch("influencers/getCompaniesList");
    });
    let addInfluencerListSuccess = computed(() => state.addInfluencerSuccess);
    watch(addInfluencerListSuccess, (val: boolean) => {
      if (val) store.dispatch("influencers/getCompaniesList");
      state.count++;
    });
    return {
      ...toRefs(state),
      formatDate,
      closeLoadingDialog,
      remainingViewing,
      influencerId,
      addInfluencerList,
      setLoadSuccess,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.main {
  padding-bottom: 120px;
}
.influencer-detail {
  width: 100%;
  &.report-button {
    @include mq(xs) {
      display: none;
    }
    @include mq(lg) {
      display: block;
    }
  }
  &__info {
    align-items: center;
    padding-top: 20px;
    display: flex;
    @include mq(xs) {
      display: block;
    }
    @include mq(lg) {
      display: flex;
    }
    & h4 {
      font-weight: 700;
      color: map-get($colors, "gray300");
    }
    &__right {
      margin-left: auto;
      align-items: baseline;
      display: flex;
      @include mq(xs) {
        margin-top: 10px;
        flex-direction: row-reverse;
      }
      @include mq(lg) {
        margin-top: 0;
        flex-direction: initial;
      }
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 720px;
      padding: 100px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      text-align: center;
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
      }
    }
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 20px;
    margin: 20px auto;
    box-sizing: border-box;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    box-sizing: initial;
    border: none;
  }
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #40404047 0 0 no-repeat padding-box;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
