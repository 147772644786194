<template>
  <div
      :aria-label="title"
      :class="[
        'tooltip',
        'tooltip__size-' + size,
        'tooltip__inner-' + fillInnerColor,
        'tooltip__outer-' + fillOuterColor,
        shadow ? 'button__shadow-' + fillOuterColor : '',
      ]"
  >
    <svg
        class="tooltip--svg"
        :width="size"
        :height="size"
        viewBox="0 0 10 10"
        @mouseover="mouseOverAction"
        @mouseleave="mouseLeaveAction"
    >
      <circle cx="5" cy="5" r="5" :fill="fillOuterColor"/>
      <path d="M2.185.768a3.269,3.269,0,0,1-.02-.333.908.908,0,0,1,.5-.873,3.824,3.824,0,0,0,.66-.5,1.224,1.224,0,0,0,.38-.907,1.09,1.09,0,0,0-.327-.827,1.8,1.8,0,0,0-1.373-.5,1.976,1.976,0,0,0-1.38.447,1.668,1.668,0,0,0-.533.973l.8.1a1.048,1.048,0,0,1,.2-.513,1.079,1.079,0,0,1,.867-.373c.6,0,.973.273.973.707,0,.32-.153.52-.627.84a1.99,1.99,0,0,0-.613.5,1.19,1.19,0,0,0-.253.853,2.594,2.594,0,0,1-.02.407Zm-.8.58v.8h.833v-.8Z" transform="translate(3.102 5.512)" :fill="fillInnerColor"/>
    </svg>
    <p
        v-if="hoverFlag"
        :class="[
            'tooltip__description',
            'tooltip__description-' + fillOuterColor
        ]"
    ><slot /></p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, reactive, toRefs,
} from 'vue'

export default defineComponent({
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      default: 'tooltip'
    },
    fillInnerColor: {
      type: String,
      default: 'white'
    },
    fillOuterColor: {
      type: String,
      default: 'dark'
    },
    size: {
      type: Number,
      default: 12
    },
    shadow: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const state = reactive({
      hoverFlag: false,
    });
    const mouseOverAction = () => state.hoverFlag = true;
    const mouseLeaveAction = () => state.hoverFlag = false;
    return {
      ...toRefs(state),
      mouseLeaveAction,
      mouseOverAction,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../assets/styles/main.scss';
.tooltip {
  display: inline-block;
  margin: 0 4px 0 2px;
  width: 12px;
  height: 12px;
  transition: 0.25s;
  position: relative;
  align-self: end;
  z-index: auto;
  border-radius: 6px;
  background: none;
  @each $name, $color in $tooltip-colors {
    &__outer-#{$name} {
      background-color: $color;
    }
    &__inner-#{$name} {
      background-color: $color;
    }
    &__shadow-#{$name} {
      box-shadow: 0 0 1px $color;
      &:hover {
        box-shadow: none;
      }
    }
  }
  & svg {
    position: absolute;
  }
  &__svg {
    position: relative;
    align-self: end;
    background: none;
    &:hover {
      opacity: 0.9;
    }
  }
  &__description {
    position: absolute;
    opacity: 1!important;
    top: -12px;
    left: 20px;
    z-index: 2;
    width: 200px;
    word-wrap: break-word;
    color: white;
    font-size: 12px!important;
    font-weight: 500!important;
    padding: 10px;
    border-radius: 4px;
    @each $name, $color in $tooltip-colors {
      &-#{$name} {
        background-color: $color;
        &::before {
          position: absolute;
          top: 4px;
          left: -8px;
          content: '';
          border-right: 4px solid $color;
          border-top: 4px solid transparent;
          border-left: 4px solid transparent;
        }
        &::after {
          position: absolute;
          top: 8px;
          left: -8px;
          content: '';
          border-right: 4px solid $color;
          border-bottom: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
    }
  }
}
</style>
