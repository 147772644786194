
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { event } from "vue-gtag";

export default defineComponent({
  name: "SignIn",
  components: { BaseTextField, BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("signIn/signInInitialize");
    const state = reactive({
      email: null as string | null,
      password: null as string | null,
      errorMessage: computed(() => store.state.signIn.errorMessage),
      formErrorMessage: null as string | null,
      signInSuccess: computed(() => store.state.signIn.signInSuccess),
    });
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.email) {
        state.formErrorMessage = "メールアドレスは必須です";
      } else if (!state.password) {
        state.formErrorMessage = "パスワードは必須です";
      } else {
        const params = {
          email: state.email,
          password: state.password,
        };
        store.dispatch("signIn/postSignIn", params);
      }
    };
    const judgementSubmitUserData = () => {
      if (state.email && state.password) {
        submitUserData();
      }
    };
    let signInSuccess = computed(() => state.signInSuccess);
    watch(signInSuccess, (val: boolean) => {
      if (val) {
        event("login", { method: "Google" });
        router.push({ name: "Home" });
      }
    });
    return { ...toRefs(state), submitUserData, judgementSubmitUserData };
  },
});
