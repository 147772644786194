<template>
  <svg :width="size" :height="size" viewBox="0 0 48 48">
    <g transform="translate(9800 -2686)">
      <path d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z" transform="translate(-9800 2686)" :fill="rounded ? 'white' : 'none'" />
      <path d="M97.134,87.076,86,77.313a.819.819,0,0,0-1.08,0L73.779,87.076a.819.819,0,0,0,1.08,1.232l.862-.756V99.732a.819.819,0,0,0,.819.819H94.373a.819.819,0,0,0,.819-.819V87.552l.862.756a.819.819,0,0,0,1.08-1.232ZM83.565,98.913V92.167h3.782v6.747H83.565Zm9.989-12.791V98.913H88.986V91.348a.819.819,0,0,0-.819-.819h-5.42a.819.819,0,0,0-.819.819v7.566H77.359V86.122s0,0,0-.006l8.1-7.1,8.1,7.1S93.555,86.12,93.555,86.122Z" transform="translate(-9861.458 2620.867)" :fill="fillColor"/>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "HomeIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff",
    },
    size: {
      type: Number,
      default: 48,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
