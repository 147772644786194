<template>
  <svg class="material-design-icon__svg" :width="size" :height="size" viewBox="0 0 36 36" v-show="!src">
    <g transform="translate(12479 15111)">
      <rect width="36" height="36" rx="18" transform="translate(-12479 -15111)" :fill="fillColor" />
      <path d="M15.3,16.35v1.9H2v-1.9s0-3.8,6.65-3.8,6.65,3.8,6.65,3.8M11.975,7.325A3.325,3.325,0,1,0,8.65,10.65a3.325,3.325,0,0,0,3.325-3.325" transform="translate(-12469.75 -15104.5)" fill="#fff" />
    </g>
  </svg>
  <img :src="src" v-show="src"/>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfileIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    size: {
      type: Number,
      default: 36,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String as () => string | null,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
