import axios from "@/store/axios";
import { Company, CompanyCreateParams, CompanyUpdateParams, CompanyInfluencerList } from "@/types";
import { Commit } from "vuex";

interface State {
  errorMessage: string | null;
  company: Company | null;
  companiesInfluencerList: CompanyInfluencerList | null;
  createCompanySuccess: boolean;
  updateCompanySuccess: boolean;
  loadCompanySuccess: boolean;
}

const state: State = {
  errorMessage: null,
  company: null,
  companiesInfluencerList: null,
  createCompanySuccess: false,
  updateCompanySuccess: false,
  loadCompanySuccess: false,
};

const companies = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string) {
      state.errorMessage = errorMessage;
    },
    setCompany(state: State, company: Company | null) {
      state.company = company;
    },
    setCreateCompanySuccess(state: State, flag: boolean) {
      state.createCompanySuccess = flag;
    },
    setUpdateCompanySuccess(state: State, flag: boolean) {
      state.updateCompanySuccess = flag;
    },
    setLoadCompanySuccess(state: State, flag: boolean) {
      state.loadCompanySuccess = flag;
    },
  },
  actions: {
    createCompanyInitialize({ commit }: { commit: Commit }, flag: boolean) {
      commit("setCreateCompanySuccess", flag);
      commit("setErrorMessage", null);
    },
    async createCompany({ commit }: { commit: Commit }, params: CompanyCreateParams) {
      commit("setErrorMessage", null);
      commit("setCreateCompanySuccess", false);
      const formParams: any = new FormData();
      if (params.logo) formParams.append("logo", params.logo);
      formParams.append("name", params.name);
      if (params.department) formParams.append("department", params.department);
      formParams.append("type", params.type);
      try {
        const res = await axios.post("api/v1/companies/", formParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [(data: any) => data],
        });
        const company = res.data;
        commit("setCompany", company);
        commit("setCreateCompanySuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async loadCompany({ commit }: { commit: Commit }, companyId: string) {
      commit("setErrorMessage", null);
      commit("setLoadCompanySuccess", false);
      try {
        const res = await axios.get(`api/v1/companies/${companyId}/`);
        commit("setCompany", res.data);
        commit("setLoadCompanySuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateCompany({ commit }: { commit: Commit }, { params, companyId }: { params: CompanyUpdateParams; companyId: string }) {
      commit("setErrorMessage", null);
      commit("setUpdateCompanySuccess", false);
      const formParams: any = new FormData();
      if (params.logo) formParams.append("logo", params.logo);
      formParams.append("name", params.name);
      formParams.append("department", params.department);
      try {
        const res = await axios.patch(`/api/v1/companies/${companyId}/`, formParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [(data: any) => data],
        });
        const company = res.data;
        commit("setCompany", company);
        commit("setUpdateCompanySuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default companies;
