
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultipleToggle from "@/components/MultipleToggle.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import BasicTable from "@/components/BasicTable.vue";
import { useStore } from "vuex";
import { TableHeader } from "@/types";
import { formatDate } from "@/plugins/formatter";
import Loading from "@/components/Loading.vue";
import { set } from "vue-gtag";
import BaseTable from "@/components/BaseTable.vue";
import exclamationMark from "@/components/icon/ExclamationMark.vue";
import { useRouter } from "vue-router";
import { useStore as useStoreUsers } from "vuex";
import AISearchResult from "@/components/AISearchResult.vue";

export default defineComponent({
  name: "AISearchResultList",
  components: {
    AISearchResult,
    BasicTable,
    Sidebar,
    BaseHeader,
    BaseButton
  },
  setup() {
    const store = useStore();
    const state = reactive({
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      results: computed(() => store.state.influencers.aiSearchResults),
      show: false,
      key: "",
      text: "",
    });
    store.dispatch("influencers/getAISearchResultList");
    const headers: TableHeader[] = [
      {
        text: "検索文章",
        value: "text",
      },
      {
        text: "検索日時",
        value: "timestamp",
      },
      {
        text: "詳細",
        value: "key",
      }
    ];
    const showResult = (text: string, key: string) => {
      state.key = key;
      state.text = text;
      state.show = true;
    }
    const closeResult = () => {
      console.log("aaaa")
      state.show = false;
    }
    return {
      ...toRefs(state),
      headers,
      formatDate,
      showResult,
      closeResult,
    };
  },
});
