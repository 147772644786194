
import {computed, defineComponent, reactive, toRefs, watch} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";

export default defineComponent({
  name: "EditFolderIcon",
  components: {
    BaseTextField,
    BaseButton,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
    },
    modelValue: {
      type: String as () => string | null,
      default: null,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      formErrorMessage: null as string | null,
      folderName: props.modelValue,
      updateInfluencerListSuccess: computed(() => store.state.influencers.updateInfluencerListSuccess),
    });
    const handleEdit = () => {
      context.emit("confirmEdit");
    };
    const handleInput = () => {
      context.emit("confirmInput");
    };
    let folderName = computed(() => state.folderName);
    watch(folderName, (val: null | string) => {
      context.emit("update:modelValue", val);
    });
    let updateInfluencerListSuccess = computed(() => state.updateInfluencerListSuccess);
    watch(updateInfluencerListSuccess, (val: boolean) => {
      if (val) state.show = false;
    });
    return { ...toRefs(state), handleEdit, handleInput };
  },
});
