<template>
  <div class="base-container__white" v-show="!forgotPasswordSuccess">
    <div class="forget-password">
      <img src="@/assets/img/logo-wrap.svg" alt="INFRECT" />
      <div class="mt-60px">
        <h2>パスワードの再設定</h2>
      </div>
      <div class="mt-40px">
        <h3>ご登録いただいているメールアドレスにパスワードの再設定用URLを送信します。</h3>
      </div>
      <div class="mt-60px">
        <base-text-field placeholder="メールアドレス" :width="360" class="mt-60px" v-model="email"></base-text-field>
      </div>
      <div class="error-message" v-show="errorMessage">存在しないメールアドレスです。もう一度入力内容をご確認ください。</div>
      <div class="error-message" v-show="formErrorMessage">{{ formErrorMessage }}</div>
      <div class="d-flex justify-content-center mt-60px">
        <base-button size="short" class="mr-20px" @click="submitPasswordResetEmail">送信</base-button>
        <base-button size="short" color="dark" @click="cancelPasswordReset">キャンセル</base-button>
      </div>
    </div>
  </div>
  <div class="base-container__white" v-show="forgotPasswordSuccess">
    <div class="forget-password">
      <img src="@/assets/img/logo-wrap.svg" alt="INFRECT" />
      <div class="mt-60px">
        <h2>パスワード再発行用メールを送信しました。</h2>
      </div>
      <div class="mt-40px">
        <h3>
          登録いただいたメールアドレスにパスワード再発行用メールを送信しました。<br />
          受信したメールに記載されているURLから再発行手続きを行ってください。
        </h3>
      </div>
      <div class="mt-40px">
        <h3>
          メールが届かない場合は、再度メールアドレスの確認をしていただき、<br />
          迷惑メールボックスに弊社からのメールが入っていないかご確認ください。<br />
          ご不明点等ございましたら、お手数ではございますがこちらまでご連絡ください。
        </h3>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { ForgotPasswordParams } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ForgetPassword",
  components: { BaseButton, BaseTextField },
  setup() {
    const store = useStore();
    store.dispatch("users/forgotPasswordInitialize");
    const router = useRouter();
    const state = reactive({
      email: null as null | string,
      formErrorMessage: null as null | string,
      errorMessage: computed(() => store.state.users.errorMessage),
      forgotPasswordSuccess: computed(() => store.state.users.forgotPasswordSuccess),
    });
    const submitPasswordResetEmail = () => {
      if (state.email) {
        const params: ForgotPasswordParams = { email: state.email };
        store.dispatch("users/forgotPassword", params);
      } else {
        state.formErrorMessage = "メールアドレスは必須です";
      }
    };
    const cancelPasswordReset = () => {
      router.push({ name: "SignIn" });
    };
    return {
      ...toRefs(state),
      submitPasswordResetEmail,
      cancelPasswordReset,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.forget-password {
  text-align: center;
  padding-top: 120px;
  & h2 {
    color: map-get($colors, "blue400");
  }
  & h3 {
    font-weight: 500;
  }
  & img {
    width: 160px;
    height: auto;
  }
}
.error-message {
  padding: 20px;
  color: map-get($colors, "accent");
}
</style>
