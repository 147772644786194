
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "ToggleButton",
  props: {
    id: {
      type: String,
      default: "toggle-button-id",
    },
    modelValue: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      checked: props.reverse ? !props.modelValue : props.modelValue,
    });
    const handleCheck = () => {
      context.emit("update:modelValue", props.reverse ? !state.checked : state.checked);
      context.emit("toggle");
    };
    return {
      ...toRefs(state),
      handleCheck,
    };
  },
});
