import axios from "@/store/axios";
import { Commit, Dispatch } from "vuex";
import {
  SimplePredictionResponseParams,
  SimplePredictionRequestParams,
  DetailPredictionRequestParams, DetailPredictionResponseParams,
} from "@/types";

interface State {
  loading: boolean;
  errorMessage: string | null;
  simplePredictionSuccess: boolean;
  simplePredictionResult: SimplePredictionResponseParams | null;
  detailPredictionSuccess: boolean;
  detailPredictionResult: DetailPredictionResponseParams | null;
}
const state: State = {
  loading: false,
  errorMessage: null,
  simplePredictionSuccess: false,
  simplePredictionResult: null,
  detailPredictionSuccess: false,
  detailPredictionResult: null,
};

const prediction = {
  namespaced: true,
  state,
  mutations: {
    setInitialize(state: State) {
      state.loading = false;
      state.errorMessage = null;
      state.simplePredictionSuccess = false;
      state.simplePredictionResult = null;
      state.detailPredictionSuccess = false;
      state.detailPredictionResult = null;
    },
    setSimplePredictionResult(state: State, result: SimplePredictionResponseParams) {
      state.simplePredictionSuccess = true;
      state.simplePredictionResult = result;
    },
    setDetailPredictionResult(state: State, result: DetailPredictionResponseParams) {
      state.detailPredictionSuccess = true;
      state.detailPredictionResult = result;
    },
    setLoading(state: State, flag: boolean) {
      state.loading = flag;
    },
    setErrorMessage(state: State, message: string) {
      state.errorMessage = message;
    },
  },
  actions: {
    initialize({ commit }: { commit: Commit }) {
      commit("setInitialize");
    },
    async predictSimpleInstagramLikes({ commit }: { commit: Commit }, params: SimplePredictionRequestParams) {
      commit("setInitialize");
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/prediction/simple-instagram-likes/", params);
        commit("setSimplePredictionResult", res.data);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
    async predictDetailInstagramLikes({ commit }: { commit: Commit }, params: DetailPredictionRequestParams) {
      commit("setInitialize");
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/prediction/detail-instagram-likes/", params);
        commit("setDetailPredictionResult", res.data);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
  },
};

export default prediction;
