
import { defineComponent } from "vue";
export default defineComponent ({
  name: "SearchIcon",
  props: {
    fillColor: {
      type: String,
      default: "white"
    },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 35
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

