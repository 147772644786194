
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    id: {
      type: String,
      default: "check-box-id",
    },
    modelValue: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const state = reactive({
      checked: props.modelValue,
    });
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
      context.emit("check");
    };
    return {
      ...toRefs(state),
      handleCheck,
    };
  },
});
