<template>
  <div class="task-table">
    <div class="task-table__header d-flex">
      <div class="task-table__header__title">
        <h4 class="mr-10px">案件名</h4>
      </div>
      <div class="d-flex task-table__right">
        <div class="task-table__right__category mr-20px">
          <h4>案件の種類</h4>
        </div>
        <div class="task-table__right__list mr-40px">
          <h4>リスト要望人数</h4>
        </div>
        <div class="task-table__right__status mr-20px">
          <h4>ステータス</h4>
        </div>
        <div class="task-table__right__author mr-20px">
          <h4>作成者</h4>
        </div>
      </div>
    </div>
    <div class="task-table__body" v-for="item in items" :key="item">
      <div class="task-table__title">
        <router-link :to="{ name: 'TaskDetail', params: {id: item.id} }">
          <h4 class="mr-10px">{{ item.title }}</h4>
        </router-link>
      </div>
      <div class="d-flex task-table__right">
        <div class="task-table__right__category mr-20px">
          <h4>{{ TaskType.findTextByKey(item.type) }}</h4>
        </div>
        <div class="task-table__right__list mr-40px">
          <h4>{{ item.listingNum }}人</h4>
        </div>
        <div class="task-table__right__status mr-20px">
          <h4>{{ TaskStatus.findTextByKey(item.influencers.length) }}</h4>
        </div>
        <div class="task-table__right__author mr-20px">
          <h4>{{ item.createdBy.username }}</h4>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { TaskType } from "@/enum/taskType";
import {TaskStatus} from "@/enum/taskStatus";

export default defineComponent({
  name: "TaskTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      task: computed(() => store.state.tasks.task),
    });
    return {
      ...toRefs(state),
      TaskType,
      TaskStatus,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.task-table {
  @include mq(xs) {
    width: fit-content;
  }
  @include mq(lg) {
    width: 100%;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
    &__title {
      width: 400px;
      & h4 {
        width: 400px;
      }
    }
  }
  &__body {
    height: fit-content;
    display: flex;
    padding: 20px 20px;
    background-color: white;
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    & .task-table__title {
      & h4 {
        color: map-get($colors, "blue400");
        &:hover {
          color: map-get($colors, "blue200");
        }
      }
      & a {
        text-decoration: none;
      }
    }
  }
  &__title {
    width: 400px;
    align-self: center;
    & h4 {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__right {
    margin-left: auto;
    align-items: center;

    &__category {
      width: 120px;
    }
    &__list {
      width: 120px;
      text-align: right;
    }
    &__status {
      width: 120px;
    }
    &__author {
      width: 120px;
      word-break: break-all;
      & img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
