
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import TaskTable from "@/components/TaskTable.vue";
import { useStore } from "vuex";
import { Task } from "@/types";

export default defineComponent({
  name: "TaskManage",
  components: { TaskTable, BaseHeader, Sidebar, BaseButton },
  props: {
    tasks: {
      type: Array,
      default: () => [
        {
          taskTitle: "女性目線でコンビニで買いたいスイーツに関するインタビュー調査",
          taskCategory: "インタビュー",
          listValue: 10,
          status: "リストアップ中",
        },
        {
          taskTitle: "女性向けキャンプアパレル・キャンプ用品に関する商品開発共創",
          taskCategory: "コラボレーション",
          listValue: 100,
          status: "リストアップ済",
        },
        {
          taskTitle: "男児向けおもちゃに関するYoutube動画作成・広告案件",
          taskCategory: "PR広告",
          listValue: 10,
          status: "リストアップ中",
        },
      ],
    },
  },
  setup() {
    const store = useStore();
    store.dispatch("tasks/loadTasks");
    const state = reactive({
      show: {
        all: true,
        listed: false,
        listing: false,
      },
      tasks: computed(() => store.state.tasks.tasks),
      company: computed(() => store.state.companies.company),
      member: computed(() => store.state.members.member),
      loadTaskSuccess: computed(() => store.state.tasks.loadTaskSuccess),
      createId: null as string | null,
      filteredTasks: null as Task[] | null,
    });
    let loadTaskSuccess = computed(() => state.loadTaskSuccess);
    watch(loadTaskSuccess, () => {
      state.filteredTasks = state.tasks;
    });
    const toggleTab = (name: string) => {
      if (name === "all") {
        state.show.all = true;
        state.show.listed = false;
        state.show.listing = false;
        state.filteredTasks = state.tasks;
      } else if (name === "listed") {
        state.show.all = false;
        state.show.listed = true;
        state.show.listing = false;
        const filteredTasks: Task[] = [];
        state.tasks.slice().map((x: Task) => {
          if (x.influencers?.length >= 1) filteredTasks.push(x);
        });
        state.filteredTasks = filteredTasks;
      } else if (name === "listing") {
        state.show.all = false;
        state.show.listed = false;
        state.show.listing = true;
        const filteredTasks: Task[] = [];
        state.tasks.slice().map((x: Task) => {
          if (x.influencers?.length <= 0) filteredTasks.push(x);
        });
        state.filteredTasks = filteredTasks;
      }
    };
    return { ...toRefs(state), toggleTab };
  },
});
