
import { defineComponent, reactive, computed, toRefs, ref } from "vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "SelectBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 240,
    },
    height: {
      type: Number,
      default: 48,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemName: {
      type: String,
      default: "jpName",
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String as () => any | any[],
    },
  },
  setup(props, context) {
    const initialSelectedItem = {} as any;
    initialSelectedItem[props.itemText] = "";
    const state = reactive({
      selectedItem: (props.modelValue !== null ? props.modelValue : initialSelectedItem) as any,
      selectedItems: (props.modelValue !== null ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : [initialSelectedItem]) as any[],
      isSelecting: false,
    });
    const styles = computed(() => {
      return {
        "--width": props.width,
        "--height": props.height,
      };
    });
    const SelectBox = ref<HTMLImageElement>();
    const changeSelected = () => {
      if (props.multiple) {
        if (props.returnObject) {
          context.emit("update:modelValue", state.selectedItems);
          context.emit("updateSelected");
        } else {
          context.emit(
            "update:modelValue",
            state.selectedItems.slice().map((x: any) => x[props.itemValue])
          );
          context.emit("updateSelected");
        }
      } else {
        if (props.returnObject) {
          context.emit("update:modelValue", state.selectedItem);
          context.emit("updateSelected");
        } else {
          context.emit("update:modelValue", state.selectedItem[props.itemValue]);
          context.emit("updateSelected");
        }
      }
    };
    const setSelectedItem = (val: any) => {
      if (props.multiple) {
        if (props.allowDistinct || !state.selectedItems.includes(val)) {
          state.selectedItems.push(val);
        } else if (!props.allowDistinct || state.selectedItems.includes(val)) {
          const idx = state.selectedItems
            .slice()
            .map((x: any) => x[props.itemValue])
            .indexOf(val[props.itemValue]);
          state.selectedItems.splice(idx, 1);
        }
        changeSelected();
        state.isSelecting = false;
      } else {
        state.selectedItem = val;
        changeSelected();
        state.isSelecting = false;
      }
    };
    const setSelecting = (condition: boolean) => {
      state.isSelecting = condition;
    };
    const onClickOutside = (event: any) => {
      if (event.target !== SelectBox.value) state.isSelecting = false;
    };
    return {
      ...toRefs(state),
      changeSelected,
      styles,
      setSelectedItem,
      setSelecting,
      onClickOutside,
      SelectBox,
    };
  },
});
