
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { ForgotPasswordParams } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ForgetPassword",
  components: { BaseButton, BaseTextField },
  setup() {
    const store = useStore();
    store.dispatch("users/forgotPasswordInitialize");
    const router = useRouter();
    const state = reactive({
      email: null as null | string,
      formErrorMessage: null as null | string,
      errorMessage: computed(() => store.state.users.errorMessage),
      forgotPasswordSuccess: computed(() => store.state.users.forgotPasswordSuccess),
    });
    const submitPasswordResetEmail = () => {
      if (state.email) {
        const params: ForgotPasswordParams = { email: state.email };
        store.dispatch("users/forgotPassword", params);
      } else {
        state.formErrorMessage = "メールアドレスは必須です";
      }
    };
    const cancelPasswordReset = () => {
      router.push({ name: "SignIn" });
    };
    return {
      ...toRefs(state),
      submitPasswordResetEmail,
      cancelPasswordReset,
    };
  },
});
