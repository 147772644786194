
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import MemberTable from "@/components/MemberTable.vue";
import InvitationMemberTable from "@/components/InvitationMemberTable.vue";
import SelectBox from "@/components/SelectBox.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import InvoiceTable from "@/components/InvoiceTable.vue";
import PlanCard from "@/components/PlanCard.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import Loading from "@/components/Loading.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import CompanyIcon from "@/components/icon/CompanyIcon.vue";
import { MemberRole } from "@/enum/memberRole";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { CompanyMember, CompanyUpdateParams, MemberInvitationParams, UserUpdateInAccountSettingParams } from "@/types";

export default defineComponent({
  name: "AccountSetting",
  components: {
    CompanyIcon,
    ProfileIcon,
    Loading,
    InvitationMemberTable,
    CheckMark,
    ExclamationMark,
    PlanCard,
    InvoiceTable,
    BaseTextField,
    SelectBox,
    MemberTable,
    BaseHeader,
    Sidebar,
    BaseButton,
    ToggleButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("members/loadMember");
    store.dispatch("members/loadInvitationMember");
    store.dispatch("plans/getPlans");
    store.dispatch("invoices/getCompanyInvoices");
    const state = reactive({
      show: {
        invite: false,
        company: false,
        account: false,
        resign: false,
        update: false,
        invitationSuccess: false,
        errorDialog: false,
        deleteMemberSuccess: false,
        deleteInvitationMemberSuccess: false,
      },
      notification: {
        isReadListing: false,
        isReadAddList: false,
        isReadDetail: false,
        isReadRefresh: false,
        isReadDeleteList: false,
        isReadDeleteMember: false,
        enableEmailListedNotification: true,
        enableEmailMemberNotification: true,
      },
      user: computed(() => store.state.users.user),
      member: computed(() => store.state.members.member),
      company: computed(() => store.state.companies.company),
      invitationMember: computed(() => store.state.members.invitationMember),
      invoices: computed(() => store.state.invoices.invoices),
      plans: computed(() => store.state.plans.plans),
      loadPlansSuccess: computed(() => store.state.plans.loadPlansSuccess),
      loadCompanySuccess: computed(() => store.state.companies.loadCompanySuccess),
      invitationMemberSuccess: computed(() => store.state.members.invitationMemberSuccess),
      updateUserSuccess: computed(() => store.state.users.updateUserSuccess),
      updateCompanySuccess: computed(() => store.state.companies.updateCompanySuccess),
      invoiceLoading: computed(() => store.state.invoices.loading),
      invoiceSuccess: computed(() => store.state.invoices.success),
      errorMessage: computed(() => store.state.companies.errorMessage),
      InvitationErrorMessage: computed(() => store.state.members.errorMessage),
      deleteMemberSuccess: computed(() => store.state.members.deleteMemberSuccess),
      deleteInvitationMemberSuccess: computed(() => store.state.members.deleteInvitationMemberSuccess),
      updateMemberSuccess: computed(() => store.state.members.updateMemberSuccess),
      username: null as null | string,
      companyName: null as null | string,
      department: null as null | string,
      imgUrl: null as string | null,
      imgUrlForCompany: null as string | null,
      formErrorMessage: null as string | null,
      role: null as number | null,
      email: null as string | null,
      members: [] as CompanyMember[],
      notificationChanged: false,
      MemberRole,
      tab: "general" as string,
    });
    if (route.query.type) {
      if (["general", "notifications", "members", "invoices", "plans"].includes(String(route.query.type))) {
        state.tab = String(route.query.type);
      }
    }
    const selectTabs = (tab: string) => {
      router.push({ name: "AccountSetting", query: { type: tab } });
      state.tab = tab;
    };
    const loadUserState = (preventComputed = false) => {
      const username = state.user?.username;
      if (!preventComputed) state.username = username;
      const notification = {
        isReadListing: state.user?.isReadListing,
        isReadAddList: state.user?.isReadAddList,
        isReadDetail: state.user?.isReadDetail,
        isReadRefresh: state.user?.isReadRefresh,
        isReadDeleteList: state.user?.isReadDeleteList,
        isReadDeleteMember: state.user?.isReadDeleteMember,
        enableEmailListedNotification: state.user?.enableEmailListedNotification,
        enableEmailMemberNotification: state.user?.enableEmailMemberNotification,
      };
      if (!preventComputed) state.notification = notification;
      return { username: username, notification: notification };
    };
    loadUserState();
    state.companyName = state.company?.name;
    state.department = state.company?.department;
    state.members = state.company?.members;
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const uploadThumbnailInputForCompany = ref<HTMLInputElement>();
    const handleFileForCompany = () => {
      state.imgUrlForCompany = URL.createObjectURL((uploadThumbnailInputForCompany.value?.files || [])[0]);
    };
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.username) {
        state.formErrorMessage = "名前は必須です";
        state.show.errorDialog = true;
      } else {
        const params: UserUpdateInAccountSettingParams = {
          logo: (uploadThumbnailInput.value?.files || [])[0],
          username: state.username,
        };
        store.dispatch("users/updateUserInAccountSetting", { params: params, userId: state.user.id });
      }
    };
    let updateUserSuccess = computed(() => state.updateUserSuccess);
    watch(updateUserSuccess, (val: boolean) => {
      checkNotificationChanged(true);
      if (val) state.show.account = false;
      if (val) state.show.update = true;
    });
    const submitCompanyData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.companyName) {
        state.formErrorMessage = "会社名は必須です";
        state.show.errorDialog = true;
      } else {
        const params: CompanyUpdateParams = {
          logo: (uploadThumbnailInputForCompany.value?.files || [])[0],
          name: state.companyName,
          department: state.department,
        };
        store.dispatch("companies/updateCompany", { params: params, companyId: state.company.id });
      }
    };
    let updateCompanySuccess = computed(() => state.updateCompanySuccess);
    watch(updateCompanySuccess, (val: boolean) => {
      if (val) state.show.company = false;
      if (val) state.show.update = true;
    });
    let loadCompanySuccess = computed(() => state.loadCompanySuccess);
    watch(loadCompanySuccess, (val) => {
      if (val) {
        state.companyName = state.company?.name;
        state.department = state.company?.department;
        state.members = state.company?.members;
      }
    });
    const deleteUserData = () => {
      state.formErrorMessage = null;
      store.dispatch("users/deleteUser", { userId: state.user.id });
    };
    const submitInvitationMember = () => {
      store.dispatch("members/invitationMemberInitialize");
      state.formErrorMessage = null;
      if (!state.email) {
        state.formErrorMessage = "メールアドレスは必須です";
        state.show.errorDialog = true;
      } else if (!state.role && state.role !== 0) {
        state.formErrorMessage = "権限は必須です";
        state.show.errorDialog = true;
      } else {
        const params: MemberInvitationParams = {
          email: state.email,
          company: state.user?.companyId,
          role: state.role,
        };
        store.dispatch("members/invitationMember", params);
      }
    };
    let invitationMemberSuccess = computed(() => state.invitationMemberSuccess);
    watch(invitationMemberSuccess, (val: boolean) => {
      if (val) state.show.invite = false;
      if (val) state.show.invitationSuccess = true;
      if (val) state.email = null;
    });
    const checkNotificationChanged = (initialize = false) => {
      const { notification } = loadUserState(!initialize);
      const currObj = JSON.stringify(Object.entries(notification).sort());
      const prevObj = JSON.stringify(Object.entries(state.notification).sort());
      state.notificationChanged = currObj !== prevObj;
    };
    const updateUserNotifications = () => {
      if (!state.notificationChanged) return;
      store.dispatch("users/updateUserReadOption", { params: state.notification, userId: state.user.id });
    };
    let showNotificationSetting = computed(() => state.tab === "notifications");
    watch(showNotificationSetting, (val) => {
      if (val) checkNotificationChanged(true);
    });
    let deleteMemberSuccess = computed(() => state.deleteMemberSuccess);
    watch(deleteMemberSuccess, (val: boolean) => {
      if (val) state.show.deleteMemberSuccess = true;
    });
    let updateMemberSuccess = computed(() => state.updateMemberSuccess);
    watch(updateMemberSuccess, (val: boolean) => {
      if (val) state.show.update = true;
    });
    let deleteInvitationMemberSuccess = computed(() => state.deleteInvitationMemberSuccess);
    watch(deleteInvitationMemberSuccess, (val: boolean) => {
      if (val) state.show.deleteInvitationMemberSuccess = true;
    });
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      handleFile,
      uploadThumbnailInputForCompany,
      handleFileForCompany,
      submitUserData,
      submitCompanyData,
      deleteUserData,
      submitInvitationMember,
      checkNotificationChanged,
      updateUserNotifications,
      selectTabs,
    };
  },
});
