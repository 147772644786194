
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OpenDetailIcon",
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 36,
    },
    height: {
      type: Number,
      default: 29,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    requiredConfirm: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "open-detail-checkbox",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseCheckbox,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      checked: false,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    const handleConfirmView = () => {
      if (state.checked) {
        const params = { isReadDetail: state.checked };
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show = false;
      context.emit("confirmView");
    };
    const clickIcon = () => {
      if (!state.user?.isReadDetail) {
        state.show = true;
        store.dispatch("users/loadCurrentUser");
      } else {
        handleConfirmView();
      }
    };
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
    };
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    let checked = computed(() => state.checked);
    watch(checked, () => handleCheck());
    return {
      ...toRefs(state),
      clickIcon,
      handleCheck,
      handleConfirmView,
      getSafeCount,
    };
  },
});
