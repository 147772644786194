
import {defineComponent} from "vue";

export default defineComponent({
  name: "CheckMark",
  props: {
    fillColor: {
      type: String,
      default: "#707070"
    },
    width: {
      type: Number,
      default: 124
    },
    height: {
      type: Number,
      default: 124
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
})
