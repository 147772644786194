<template>
  <svg
      class="material-design-icon__svg"
      :width="width"
      :height="height"
      viewBox="0 0 30 35"
  >
      <path d="M1011.362,989.909a13.154,13.154,0,1,0-5.154,19.7l7.172,9.876,4.194-3.051-7.172-9.876A13.172,13.172,0,0,0,1011.362,989.909Zm-5.626,14.636a8.527,8.527,0,1,1,1.882-11.914h0a8.54,8.54,0,0,1-1.884,11.912Z" transform="translate(-987.574 -984.486)" :fill="fillColor"/>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "SearchIcon",
  props: {
    fillColor: {
      type: String,
      default: "white"
    },
    width: {
      type: Number,
      default: 30
    },
    height: {
      type: Number,
      default: 35
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

</script>





