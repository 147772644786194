import axios from "@/store/axios";
import { Commit } from "vuex";

interface State {
  errorMessage: string | null;
  submitVerificationSuccess: boolean;
}

const state: State = {
  errorMessage: null,
  submitVerificationSuccess: false,
};
const verificationEmail = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setSubmitVerificationSuccess(state: State, flag: boolean) {
      state.submitVerificationSuccess = flag;
    },
  },
  actions: {
    verificationEmailInitialize({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setSubmitVerificationSuccess", false);
    },
    async postVerificationEmail({ commit }: { commit: Commit }, verificationCode: string) {
      commit("setErrorMessage", null);
      try {
        const res = await axios.post("api/v1/users/verify/", { verificationCode: verificationCode });
        const user = res.data;
        commit("users/setUser", user, { root: true });
        commit("setSubmitVerificationSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default verificationEmail;
