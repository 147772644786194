<template>
  <a :class="['button', 'button--size_' + size, 'button--' + color, 'button--type_' + type, disabled ? 'disabled' : '', text ? 'button--text--' + color : '', selected ? 'selected' : '', shadow ? 'shadow' : '']">
    <slot />
    <div class="btn-text-hover-container"></div>
  </a>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      styles: computed(() => {
        return { "--color": props.color };
      }),
    });
    return { state };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
.button {
  display: inline-block;
  width: 160px;
  height: fit-content;
  max-width: 100%;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  padding: 10px 10px;
  transition: 0.25s;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  &:hover {
    opacity: 0.8;
    &.disabled {
      opacity: 1;
      cursor: initial;
    }
  }
  &.disabled {
    color: white;
    background-color: #9c9c9c !important;
  }
  &.shadow {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  }
  @each $name, $color in $btn-colors {
    &--#{$name} {
      background-color: $color;
    }
    &--text--#{$name} {
      background: none;
      border: none;
      color: $color;
      &:hover {
        & .btn-text-hover-container {
          display: block;
          width: 80%;
          height: 80%;
          top: 10%;
          left: 10%;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  &--size {
    &_wide {
      width: 240px;
    }
    &_short {
      width: 100px !important;
    }
    &_small {
      font-size: 10px;
      width: 120px;
      padding: 5px 10px;
      font-weight: 500;
      &_wide {
        width: 240px;
      }
    }
    &_large {
      font-size: 20px;
      width: fit-content;
      font-weight: 600;
      &_wide {
        width: fit-content;
        padding: 18px 156px;
        font-size: 20px;
        font-weight: 600;
        @include mq(xs) {
          padding: 18px 36px;
        }
        @include mq(lg) {
          padding: 18px 156px;
        }
      }
    }
  }
  &--type {
    &_toggle {
      font-size: 16px;
      width: 140px;
      height: 36px;
      font-weight: 700;
      color: white;
      background: none;
      padding: 0;
      border-radius: 4px 4px 0 0;
      align-self: center;
      &.selected {
        background-color: map-get($colors, "accent") !important;
      }
      &:hover {
        opacity: 1 !important;
        background-color: map-get($colors, "accent") !important;
      }
    }
    &_outline {
      width: fit-content;
      height: fit-content;
      padding: 10px 40px;
      background: none;
      color: map-get($colors, "gray200");
      font-weight: 700;
      border: 2px solid map-get($colors, "gray200");
      &.selected {
        color: white;
        background-color: map-get($colors, "primary") !important;
        border: 2px solid map-get($colors, "primary");
      }
    }
  }
}
.btn-text-hover-container {
  display: none;
  position: absolute;
}
</style>
