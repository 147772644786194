<template>
  <div class="base-container">
    <sidebar :current-tab="4"></sidebar>
    <div class="main">
      <base-header header-title="リストアップを編集する" display-type="LISTING"></base-header>
      <div class="dialog__container" v-if="(updateTaskSuccess || errorMessage) && show.updateTask">
        <div class="dialog" v-if="updateTaskSuccess">
          <div class="title">案件内容の編集が完了しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button size="short" color="dark" @click="updateTaskDone"> OK </base-button></div>
        </div>
        <div class="dialog" v-else>
          <div class="title error">案件内容の変更に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.updateTas = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-else-if="formErrorMessage && show.errorDialog">
        <div class="dialog">
          <div class="title error">入力エラー</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ formErrorMessage }}</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="listing" v-if="company?.isExpired === false">
        <div v-show="show.first">
          <div class="listing__contents d-flex">
            <div class="listing__contents__description">
              <h2>
                どのような内容の<br />
                案件ですか？
              </h2>
            </div>
            <div class="listing__contents__container">
              <div class="d-flex mb-20px">
                <base-select-box label="案件の種類" class="mr-40px" :width="236" item-value="key" :items="TaskType.getAll()" v-model="taskType" v-if="loadTaskSuccess" return-object disable-lookup required></base-select-box>
              </div>
              <div class="d-flex mb-20px">
                <base-text-field label="案件名" :width="480" v-model="taskTitle" v-if="loadTaskSuccess" required></base-text-field>
              </div>
              <div class="d-flex mb-20px">
                <base-text-area :width="480" :height="80" label="依頼背景" v-model="taskBackground" v-if="loadTaskSuccess" required></base-text-area>
              </div>
              <div class="d-flex mb-20px">
                <base-text-area :width="480" :height="80" label="案件詳細" v-model="taskDetail" v-if="loadTaskSuccess" required></base-text-area>
              </div>
              <div class="d-flex justify-content-end mt-60px">
                <h3 class="next" @click="validateFirst">次へ▶</h3>
              </div>
            </div>
          </div>
        </div>
        <div v-show="show.second">
          <div class="listing__contents d-flex">
            <div class="listing__contents__description">
              <h2>
                案件に活用したい<br />
                インフルエンサーの<br />
                情報を入力してください
              </h2>
            </div>
            <div class="listing__contents__container">
              <div class="d-flex mb-20px">
                <base-select-box label="SNSの種類" class="mr-40px" item-value="key" :width="236" :items="SNSType.getAll()" v-model="snsType" v-if="loadTaskSuccess" return-object disable-lookup required></base-select-box>
                <base-text-field label="リストアップ希望人数（変更不可）" type="number" class="mr-40px" :width="130" v-model="listingNum" v-if="loadTaskSuccess" disabled></base-text-field>
              </div>
              <div class="d-flex mb-20px">
                <base-select-box label="性別" class="mr-40px" item-value="key" item-text="text" :width="176" :items="GenderType.getAll()" v-model="genderType" v-if="loadTaskSuccess" return-object disable-lookup allow-null></base-select-box>
                <base-select-box label="年齢層" class="mr-40px" item-value="key" item-text="text" :width="296" :items="AgeGroup.getAll()" v-model="ages" v-if="loadTaskSuccess" multiple chips deletable-chips disable-lookup return-object></base-select-box>
              </div>
              <div class="d-flex mb-20px">
                <base-text-field label="最小フォロワー数" class="mr-40px" :width="130" v-model="minFollower" ref="minFollowerInput" v-if="loadTaskSuccess"></base-text-field>
                <base-text-field label="最大フォロワー数" :width="130" v-model="maxFollower" v-if="loadTaskSuccess"></base-text-field>
              </div>
              <div class="mb-20px">
                <base-text-field label="インフルエンサーの属性" :width="480" v-model="attributes" v-if="loadTaskSuccess"></base-text-field>
              </div>
              <div class="d-flex justify-content-space-between mt-60px">
                <div
                  @click="
                    show.first = true;
                    show.second = false;
                  "
                >
                  <h3 class="back">◀︎戻る</h3>
                </div>
                <div @click="validateSecond">
                  <h3 class="next">次へ▶</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="show.third">
          <div class="listing__contents d-flex">
            <div class="listing__contents__description">
              <h2>
                案件に活用したい<br />
                インフルエンサーの<br />
                フォロワーの情報を<br />
                入力してください
              </h2>
            </div>
            <div class="listing__contents__container">
              <div class="d-flex mb-20px">
                <base-select-box label="性別" class="mr-40px" item-value="key" :width="176" :items="GenderType.getAll()" v-model="followerGenderType" v-if="loadTaskSuccess" return-object disable-lookup allow-null></base-select-box>
                <base-select-box label="年齢層" class="mr-40px" item-value="key" :width="296" :items="AgeGroup.getAll()" v-model="followerAges" v-if="loadTaskSuccess" multiple chips deletable-chips return-object return-text disable-lookup></base-select-box>
              </div>
              <div class="mb-20px">
                <base-text-field label="フォロワーの属性" :width="480" v-model="followerAttributes" v-if="loadTaskSuccess"></base-text-field>
              </div>
              <div class="d-flex justify-content-center">
                <base-button size="large_wide" color="accent" class="mt-40px" @click="openListDialog"> リストアップの内容を変更する </base-button>
              </div>
              <h4 class="listing__comment d-flex justify-content-center mt-20px">※インフルエンサーのリストアップには数営業日頂きます</h4>
              <div class="d-flex justify-content-left mt-60px">
                <div
                  @click="
                    show.third = false;
                    show.second = true;
                  "
                >
                  <h3 class="back">◀︎戻る</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
  <div class="listing__dialog" v-show="show.dialog">
    <div class="listing__dialog__container">
      <div class="listing__dialog__content">
        <div class="listing__dialog__content__title mb-40px">リストアップの内容を変更します</div>
        <div class="listing__dialog__content__text mb-40px">
          リストアップの内容を変更します。<br />
          よろしいですか？
        </div>
        <div class="listing-error mt-20px" v-show="formErrorMessage || errorMessage">{{ formErrorMessage }}{{ errorMessage }}</div>
        <div class="mb-20px">
          <base-button color="blue" size="wide" @click="updateTask">リストアップの内容を変更する</base-button>
        </div>
        <div class="mb-20px">
          <base-button color="dark" @click="show.dialog = false"> キャンセル </base-button>
        </div>
        <div>
          <base-checkbox v-model="checked">
            <h4>次回から表示しない</h4>
          </base-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch, PropType } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GenderType } from "@/enum/genderType";
import { SNSType } from "@/enum/snsType";
import { TaskType } from "@/enum/taskType";
import { AgeGroup } from "@/enum/ageGroup";
import { Task, UpdateTaskParams, Age, Hashtag } from "@/types";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
export default defineComponent({
  name: "Listing",
  components: {
    BaseSelectBox,
    BaseTextArea,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    CheckMark,
    ExclamationMark,
  },
  props: {
    limitViewing: {
      type: Number,
      default: 0,
    },
    remainingViewing: {
      type: Number,
      default: 0,
    },
    task: {
      type: Object as PropType<Task>,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("tasks/loadTask", route.params.id);
    store.dispatch("tasks/loadTasks");
    type ItemType = {
      key: string;
      text: string;
    };
    type ItemNumberType = {
      key: number;
      text: string;
    };
    const state = reactive({
      show: {
        first: true,
        second: false,
        third: false,
        dialog: false,
        updateTask: false,
        errorDialog: false,
      },
      company: computed(() => store.state.companies.company),
      user: computed(() => store.state.users.user),
      task: computed(() => store.state.tasks.task),
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      createTaskSuccess: computed(() => store.state.tasks.createTaskSuccess),
      loadTaskSuccess: computed(() => store.state.tasks.loadTaskSuccess),
      updateTaskSuccess: computed(() => store.state.tasks.updateTaskSuccess),
      checked: false,
      hashtags: [] as string[],
      attributes: null as null | string,
      taskType: null as null | ItemNumberType,
      snsType: null as null | ItemNumberType,
      genderType: null as null | ItemNumberType,
      listingNum: null as null | number,
      ages: [] as ItemType[],
      minFollower: null as null | number,
      maxFollower: null as null | number,
      followerGenderType: null as null | ItemNumberType,
      followerAges: [] as ItemType[],
      followerAttributes: null as null | string,
      followerHashtags: [] as string[],
      taskTitle: null as null | string,
      taskBackground: null as null | string,
      taskDetail: null as null | string,
    });
    let loadTaskSuccess = computed(() => state.loadTaskSuccess);
    watch(loadTaskSuccess, (val) => {
      if (val) {
        state.taskTitle = state.task?.title;
        state.taskType = {
          key: state.task?.type,
          text: TaskType.findTextByKey(state.task?.type),
        };
        state.taskBackground = state.task?.backGround;
        state.taskDetail = state.task?.detail;
        state.snsType = {
          key: state.task?.snsType,
          text: SNSType.findTextByKey(state.task?.snsType),
        };
        state.listingNum = state.task?.listingNum;
        state.genderType = {
          key: state.task?.gender,
          text: GenderType.findTextByKey(state.task?.gender),
        };
        state.followerGenderType = {
          key: state.task?.followerGender,
          text: GenderType.findTextByKey(state.task?.followerGender),
        };
        state.ages = state.task?.ages.slice().map((x: Age) => ({ key: x.id, text: x.age }));
        state.followerAges = state.task?.followerAges.slice().map((x: Age) => ({ key: x.id, text: x.age }));
        state.minFollower = state.task?.minFollowers;
        state.maxFollower = state.task?.maxFollowers;
        state.attributes = state.task?.attribute;
        state.hashtags = state.task?.hashtags.slice().map((x: Hashtag) => x.tag);
        state.followerHashtags = state.task?.followerHashtags.slice().map((x: Hashtag) => x.tag);
        state.followerAttributes = state.task?.followerAttribute;
      }
    });
    const validateFirst = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      }
      if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else {
        state.show.first = false;
        state.show.second = true;
      }
    };
    const validateSecond = () => {
      state.formErrorMessage = null;
      if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else if (state.minFollower && (state.minFollower < 0 || !Number.isInteger(Number(state.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && (state.maxFollower < 0 || !Number.isInteger(Number(state.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && state.minFollower && Number(state?.minFollower) > Number(state?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数より大きい数である必要があります";
        state.show.errorDialog = true;
      } else {
        state.show.second = false;
        state.show.third = true;
      }
    };
    const updateTask = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else {
        if (state.checked) {
          const params = { isReadListing: state.checked };
          store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
        }
        const params: UpdateTaskParams = {
          type: state.taskType.key,
          title: state.taskTitle,
          backGround: state.taskBackground,
          detail: state.taskDetail,
          snsType: state.snsType.key,
          attribute: state.attributes,
          followerAttribute: state.followerAttributes,
          ages: state.ages.slice().map((x: ItemType) => ({ age: x.text })),
          followerAges: state.followerAges.slice().map((x: ItemType) => ({ age: x.text })),
          hashtags: state.hashtags.slice().map((x: string) => ({ tag: x })),
          followerHashtags: state.followerHashtags.slice().map((x: string) => ({ tag: x })),
        };
        if (state.minFollower) {
          params.minFollowers = state.minFollower;
        } else {
          params.minFollowers = null;
        }
        if (state.maxFollower) {
          params.maxFollowers = state.maxFollower;
        } else {
          params.maxFollowers = null;
        }
        if (state.genderType?.key || state.genderType?.key === 0) params["gender"] = state.genderType?.key;
        if (state.followerGenderType?.key || state.followerGenderType?.key === 0) params["followerGender"] = state.followerGenderType?.key;
        store.dispatch("tasks/updateTask", { params, taskId: state.task.id });
      }
      state.show.updateTask = true;
    };
    let updateTaskSuccess = computed(() => state.updateTaskSuccess);
    watch(updateTaskSuccess, (val: boolean) => {
      if (val) state.show.dialog = false;
    });
    const openListDialog = () => {
      state.formErrorMessage = null;
      if (state.user?.isReadListing) {
        state.show.errorDialog = false;
        updateTask();
      } else {
        state.show.dialog = true;
      }
    };
    const updateTaskDone = () => {
      router.push({ name: "TaskDetail", params: { id: state.task?.id } });
    };
    return {
      ...toRefs(state),
      GenderType,
      SNSType,
      TaskType,
      AgeGroup,
      updateTask,
      updateTaskDone,
      openListDialog,
      validateFirst,
      validateSecond
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.listing {
  width: 100%;
  &__contents {
    height: calc(100vh - 140px);
    background-color: white;
    border-radius: 10px;
    margin: 24px 0 0 0;
    &__description {
      padding: 24px;
      background: map-get($colors, "blue400");
      width: 20%;
      border-radius: 10px 0 0 10px;
      & h2, h3,
      h4 {
        font-weight: 700;
        color: white;
        margin-top: 60px;
      }
    }
    &__container {
      padding: 40px 60px;
      width: 80%;
      position: relative;
      & .next {
        color: map-get($colors, "primary");
        cursor: pointer;
        position: absolute;
        bottom: 40px;
        right: 60px;
        &:hover {
          opacity: 0.5;
        }
      }
      & .title {
        font-weight: 900;
        margin: 8px 0;
      }
      & .back {
        color: map-get($colors, "gary300");
        cursor: pointer;
        position: absolute;
        bottom: 40px;
        left: 60px;
        &:hover {
          opacity: 0.5;
        }
      }
      & .combo-label {
        text-align: left;
        font-weight: 900;
        font-size: 13px;
        margin: 8px 10px 8px 0;
        color: map-get($colors, "gray300");
      }
      &__box {
        white-space: nowrap;
        border-radius: 5px;
        border: 2px solid map-get($colors, "primary");
        padding: 10px;
        margin-right: 10px;
        width: fit-content;
        height: fit-content;
      }
    }
    & .choice {
      border-radius: 5px;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 8px;
        display: block !important;
      }
      &::-webkit-scrollbar-track {
        background-color: white;
      }
      &::-webkit-scrollbar-thumb {
        background-color: map-get($colors, "gray100");
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
      }
    }
  }
  &__comment {
    color: map-get($colors, "accent");
    font-weight: 700;
  }
  &__dialog {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 800px;
      padding: 70px 70px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 15%);
      -webkit-transform: translate(-50%, 15%);
      -ms-transform: translate(-50%, 15%);
      background-color: white;
      border-radius: 10px;
      text-align: center;
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
      }
    }
  }
  &-error {
    color: map-get($colors, "accent");
    font-size: 12px;
    font-weight: 500;
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
