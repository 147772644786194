
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";
import vClickOutside from "click-outside-vue3";
import FolderIcon from "@/components/icon/FolderIcon.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import { TaskType } from "@/enum/taskType";
import { CreateInfluencerListParams, Influencer, InfluencerList } from "@/types";

export default defineComponent({
  name: "AddListIcon",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 36,
    },
    height: {
      type: Number,
      default: 29,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "add-list-checkbox",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    influencerId: {
      type: String,
      default: "",
    },
    selectedFolder: {
      type: Number as () => null | number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    requireGetCompanyInfluencerList: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    FolderIcon,
    BaseButton,
    BaseCheckbox,
  },
  setup(props, context) {
    const store = useStore();
    if (props.requireGetCompanyInfluencerList) store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      show: {
        select: false,
        purpose: false,
        dialog: false,
        companyFolder: true,
        addFolder: false,
        childFolder: false,
        createFolder: false,
      },
      checked: false,
      folderChecked: {} as any,
      folder: null as null | TaskType,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers?.influencerList),
      createdInfluencerList: computed(() => store.state.influencers.createdInfluencerList),
      InfluencerChildList: computed(() => store.state.influencers?.childInfluencerList),
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      createFolderSuccess: computed(() => store.state.influencers.createInfluencerListSuccess),
      currentInfluencerList: null as null | InfluencerList,
      listNestRank: 0,
      newFolderName: null as null | string,
    });
    const clickIcon = () => {
      state.checked = false;
      if (state.currentInfluencerList && state.listNestRank > 0) {
        context.emit("update:selectedFolder", state.currentInfluencerList.id);
      } else {
        context.emit("update:selectedFolder", state.influencerList.id);
      }
      if (!state.user?.isReadAddList) {
        state.show.dialog = true;
        state.show.select = false;
        store.dispatch("users/loadCurrentUser");
      } else {
        context.emit("addList");
      }
    };
    const selectFolder = () => {
      store.dispatch("influencers/getCompaniesList");
      state.show.select = true;
      state.listNestRank = 0;
      state.currentInfluencerList = state.influencerList;
    };
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
    };
    const handleAddList = () => {
      if (state.checked) {
        const params = { isReadAddList: state.checked };
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show.dialog = false;
      context.emit("addList");
    };
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    const AddListIcon = ref<HTMLImageElement>();
    const onClickOutside = (event: any) => {
      if (event.target !== state.show.purpose) state.show.purpose = false;
    };
    const moveChildInfluencerList = (id: number) => {
      let nextInfluencerList = state.influencerList;
      (state.currentInfluencerList?.children || []).map((child: InfluencerList) => {
        if (child.id == id) {
          state.listNestRank += 1;
          nextInfluencerList = child;
        }
      });
      state.currentInfluencerList = nextInfluencerList;
    };
    const moveParentInfluencerList = () => {
      let tempList = state.influencerList;
      if ((state.currentInfluencerList?.familyTree || []).length <= 1) {
        state.listNestRank = 0;
        state.currentInfluencerList = tempList;
      } else {
        for (let i = 1; i + 1 < (state.currentInfluencerList?.familyTree || []).length; i++) {
          let tempChildList = tempList;
          (tempList?.children || []).map((child: InfluencerList) => {
            if (child.id === state.currentInfluencerList?.familyTree[i].id) {
              tempChildList = child;
            }
          });
          tempList = tempChildList;
        }
        state.listNestRank -= 1;
        state.currentInfluencerList = tempList;
      }
    };
    const moveNewInfluencerList = (name: string | null) => {
      if (state.currentInfluencerList) {
        state.currentInfluencerList.name = name;
        state.currentInfluencerList.influencers = [];
        state.currentInfluencerList.children = [];
        state.currentInfluencerList.familyTree.push({ id: 0, name: name });
        state.listNestRank += 1;
      }
    };
    const checkInfluencerExists = (currentInfluencerList: InfluencerList) => {
      return (currentInfluencerList.influencers || [])
        .slice()
        .map((x: Influencer) => x.id)
        .includes(props.influencerId);
    };
    const createFolder = () => {
      if (state.currentInfluencerList?.id && state.newFolderName) {
        const createFolderParams: CreateInfluencerListParams = {
          company: state.user.companyId,
          derivedFrom: state.currentInfluencerList.id,
          name: state.newFolderName,
        };
        store.dispatch("influencers/createInfluencerList", createFolderParams).then(() => {
          state.show.createFolder = false;
          store.dispatch("influencers/getCompaniesList");
          moveNewInfluencerList(state.newFolderName);
          state.newFolderName = null;
        });
      }
    };
    let checked = computed(() => state.checked);
    watch(checked, () => handleCheck());
    let createdInfluencerList = computed(() => state.createdInfluencerList);
    watch(createdInfluencerList, (val) => {
      if (state.currentInfluencerList && val.id) {
        state.currentInfluencerList.id = val.id;
      }
    });
    const closeFolder = () => {
      state.show.select = false;
      state.show.purpose = false;
      state.listNestRank = 0;
      state.currentInfluencerList = state.influencerList;
    };
    return {
      ...toRefs(state),
      clickIcon,
      selectFolder,
      handleCheck,
      handleAddList,
      onClickOutside,
      AddListIcon,
      moveChildInfluencerList,
      moveParentInfluencerList,
      checkInfluencerExists,
      createFolder,
      closeFolder,
      getSafeCount,
    };
  },
});
