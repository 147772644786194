
import {computed, defineComponent, reactive, toRefs} from "vue";
import { TableHeader } from "@/types";

export default defineComponent({
  name: "BasicTable",
  props: {
    headers: {
      type: Array,
      default: [] as TableHeader[] | null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headerFontSize: {
      type: String,
      default: "13px",
    },
    cellFontSize: {
      type: String,
      default: "13px",
    },
    cellHeight: {
      type: String,
      default: "78px",
    },
  },
  setup(props, context) {
    const state = reactive({
      headers: props.headers,
      items: props.items,
      sortedPos: {
        value: "",
        direction: "",
      },
      styles: computed(() => {
        return {
          "--header-font-size": props.headerFontSize,
          "--cell-font-size": props.cellFontSize,
          "--cell-height": props.cellHeight,
        };
      }),
    });
    const sortItems = (sortValue: string, direction: string) => {
      state.items = state.items.sort((a: any, b: any) => {
        if (direction == "asc") {
          return a[sortValue] > b[sortValue] ? 1 : -1;
        } else {
          return a[sortValue] < b[sortValue] ? 1 : -1;
        }
      });
      state.sortedPos.value = sortValue;
      state.sortedPos.direction = direction;
    };
    const checkSorted = (sortValue: string, direction: string) => {
      return state.sortedPos.value === sortValue && state.sortedPos.direction === direction;
    };
    return {
      ...toRefs(state),
      sortItems,
      checkSorted,
    };
  },
});
