
import { defineComponent } from "vue";

export default defineComponent({
  name: "BasePagination",
  props: {
    total: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    const firstPageArr = Array.from(Array(5), (v,k) => k+1);
    const lastPageNum = Array.from(Array(5), (v,k) => k);
    const lastPageArr = lastPageNum.slice().reverse();
    const checkNextPage = (currentPage: number, total: number) => {
      return currentPage + 1 < Math.ceil(total / 15);
    };
    const move = (page: number) => {
      context.emit("move", page);
    };
    return {
      firstPageArr,
      lastPageArr,
      checkNextPage,
      move,
    };
  },
});
