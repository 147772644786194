
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import { UpdateInfluencerListParams } from "@/types";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import FolderIcon from "@/components/icon/FolderIcon.vue";
import EditFolderIcon from "@/components/icon/EditFolderIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import { useStore } from "vuex";
import { formatDate } from "@/plugins/formatter";
import router from "@/router";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "InfluencerListChildrenTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EditFolderIcon,
    FolderIcon,
    TrashIcon,
    SelectBox,
    CheckMark,
    ExclamationMark,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        delete: false,
        errorDialog: false,
        update: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "name",
          text: "フォルダ名",
        },
        {
          key: "createdAt",
          text: "作成日",
        },
      ],
      sortedItems: props.items.slice(),
      listChecked: false,
      detailChecked: false,
      deleteChecked: false,
      company: computed(() => store.state.companies.company),
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      childList: computed(() => store.state.influencers?.influencerList?.children),
      influencerListName: computed(() => store.state.influencers.influencerList?.name),
      influencerListId: computed(() => store.state.influencers.influencerList?.id),
      deleteInfluencerListSuccess: computed(() => store.state.influencers.deleteInfluencerListSuccess),
      updateInfluencerListSuccess: computed(() => store.state.influencers.updateInfluencerListSuccess),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      influencerId: null as string | null,
      folderName: null as string | null,
      formErrorMessage: null as string | null,
    });
    const moveToChildFolder = (id: number) => {
      router.push({ name: "InfluencerListChildren", params: { id: id } });
      context.emit("moveFolder");
    };
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as boolean[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const deleteInfluencerList = (influenserListId: number) => {
      store.dispatch("influencers/deleteInfluencerList", influenserListId);
    };
    let deleteInfluencerListSuccess = computed(() => state.deleteInfluencerListSuccess);
    watch(deleteInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.trash = false;
      if (val) state.show.delete = true;
      if (val) context.emit("folderDelete");
    });
    const updateInfluencerList = (influencerFolderId: number, influencerFolderName: string) => {
      if (!influencerFolderName) {
        state.show.errorDialog = true;
        state.formErrorMessage = "フォルダ名は必須です";
      } else {
        const params: UpdateInfluencerListParams = {
          name: influencerFolderName,
        };
        store.dispatch("influencers/updateInfluencerList", { params: params, influencerFolderId: influencerFolderId });
      }
    };
    let updateInfluencerListSuccess = computed(() => state.updateInfluencerListSuccess);
    watch(updateInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.update = true;
      if (val) store.dispatch("influencers/getCompaniesList");
    });
    const closeSuccess = () => {
      state.show.update = false;
      window.location.reload();
    };
    return { ...toRefs(state), sortByKey, formatDate, deleteInfluencerList, updateInfluencerList, moveToChildFolder, closeSuccess };
  },
});
