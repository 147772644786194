
import { computed, defineComponent, reactive, toRefs } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { TaskType } from "@/enum/taskType";
import { GenderType } from "@/enum/genderType";
import { AgeGroup } from "@/enum/ageGroup";
import { SNSType } from "@/enum/snsType";
import { formatDate } from "@/plugins/formatter";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TaskDetail",
  components: { BaseHeader, Sidebar, BaseButton, BaseTable, ProfileIcon },
  props: {
    resultValue: {
      type: Number,
      default: 100,
    },
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    store.dispatch("tasks/loadTask", route.params.id);
    store.dispatch("tasks/loadTasks");
    const state = reactive({
      show: {
        open: false,
        close: true,
        influencer: false,
        blank: false,
      },
      task: computed(() => store.state.tasks.task),
      company: computed(() => store.state.companies.company),
      influencers: [],
    });
    return { ...toRefs(state), TaskType, GenderType, AgeGroup, SNSType, formatDate };
  },
});
