
import {computed, defineComponent, reactive, toRefs} from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    backGround: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "200px",
    },
    lineHeight: {
      type: String,
      default: "3px",
    },
  },
  setup(props, context) {
    const state = reactive({
      styles: computed(() => {
        return { "--background": props.backGround, "--width": props.width, "--line-height": props.lineHeight };
      }),
    });
    return {
      ...toRefs(state),
    };
  },
});
