<template>
  <div class="base-text-field">
    <h4 :class="[label ? 'base-text-field__label' : 'd-none']">
      {{ label }}
      <span v-if="required" v-show="required" class="required">必須</span>
      <span v-if="multiple" v-show="multiple" class="multiple">複数入力</span>
    </h4>
    <input v-model="text" :type="type" :placeholder="placeholder" :style="styles" @change="handleText" :class="['text-field', disabled ? 'disabled' : '', readonly ? 'readonly' : '']" :disabled="disabled ? 'disabled' : null" :readonly="readonly ? 'readonly' : null" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, PropType } from "vue";

export default defineComponent({
  name: "BaseTextField",
  props: {
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 240,
    },
    height: {
      type: Number,
      default: 42,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      text: props.modelValue,
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--width": props.width,
        "--height": props.height,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
      };
    });
    const handleText = () => {
      context.emit("update:modelValue", state.text);
    };
    return {
      ...toRefs(state),
      styles,
      handleText,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.base-text-field {
  &__label {
    text-align: left;
    font-weight: 900;
    font-size: 13px;
    margin: 8px 10px 8px 0;
    color: map-get($colors, "gray300");
  }
  & input {
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    @include mq(xs) {
      width: calc(var(--sm-width) * 1px);
      height: calc(var(--sm-height) * 1px);
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px);
      height: calc(var(--height) * 1px);
    }
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid map-get($colors, "primary");
    color: map-get($font-colors, "default") !important;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px map-get($colors, "blue300");
    }
    &::placeholder {
      font-size: 14px;
      color: map-get($colors, "blue300");
    }
  }
  & .disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
    &:focus {
      box-shadow: none;
    }
  }
  & .readonly {
    cursor: not-allowed;
  }
}
.required, .multiple {
  margin-left: 14px;
  padding: 1px 10px;
  background-color: map-get($colors, "accent");
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 700;
}
.multiple {
  background-color: map-get($colors, "gray300");
}
</style>
