<template>
  <div class="hamburger-menu" :style="styles">
    <div :class="['hamburger-menu__logo', show ? 'show' : '']">
      <img src="../assets/img/logo-white.svg" alt="INFRECT" />
    </div>
    <div :class="['hamburger-menu__icon', show ? 'show' : '']" @click="show = !show">
      <div class="hamburger-menu__icon__line hamburger-menu__icon__line_1"></div>
      <div class="hamburger-menu__icon__line hamburger-menu__icon__line_2"></div>
      <div class="hamburger-menu__icon__line hamburger-menu__icon__line_3"></div>
    </div>
    <div :class="['hamburger-menu__dialog', show ? 'show' : '']" @click="show = false">
      <div class="hamburger-menu__dialog__container">
        <div :class="['hamburger-menu__dialog__content', item.isSubItem ? 'sub' : '']" v-for="item in links" :key="item.name" @click="moveToLink(item)">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import {HamburgerMenuItem, LinkItem} from "@/types";

export default defineComponent({
  name: "HamburgerMenu",
  props: {
    color: {
      type: String,
      default: "#198FD9",
    },
    backgroundColor: {
      type: String,
      default: "#198FD9",
    },
    width: {
      type: Number,
      default: 24,
    },
    links: {
      type: Array as () => HamburgerMenuItem[],
      default: () => [],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: false,
    });
    const styles = computed(() => {
      return {
        "--width": props.width,
        "--color": props.color,
        "--background-color": props.backgroundColor,
      };
    });
    const router = useRouter();
    const moveToLink = (menuItem: HamburgerMenuItem) => {
      if (menuItem.isTitle) return;
      state.show = false;
      if (menuItem.isHref) {
        window.location.href = menuItem.link.name;
      } else {
        router.push(menuItem.link);
      }
    };
    return { ...toRefs(state), styles, moveToLink };
  },
});
</script>

<style lang="scss" scoped>
.hamburger-menu {
  &__logo {
    display: none;
    z-index: 10;
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 20px;
    transition-duration: 0.4s;
    & > img {
      width: 180px;
      height: auto;
      opacity: 1;
    }
    &.show {
      display: block;
      opacity: 1;
      position: fixed;
    }
  }
  &__icon {
    z-index: auto;
    position: relative;
    width: calc(var(--width) * 1px);
    height: calc(var(--width) / 8 * 1px);
    &.show {
      z-index: 10!important;
      & .hamburger-menu__icon__line {
        background-color: white!important;
        &.hamburger-menu__icon__line_1 {
          transform: rotate(45deg);
          top: 0;
          left: 0;
        }
        &.hamburger-menu__icon__line_2 {
          display: none;
        }
        &.hamburger-menu__icon__line_3 {
          transform: rotate(-45deg);
          top: 0;
          left: 0;
        }
      }
    }
    &__line {
      width: calc(var(--width) * 1px);
      height: calc(var(--width) / 8 * 1px);
      border-radius: calc(var(--width) / 16 * 1px);
      background-color: var(--color);
      transition-duration: 0.4s;
      position: absolute;
      left: 0;
      &.hamburger-menu__icon__line_1 {
        top: calc(var(--width) / 4 * (-1px));
      }
      &.hamburger-menu__icon__line_2 {
        top: 0;
      }
      &.hamburger-menu__icon__line_3 {
        top: calc(var(--width) / 4 * 1px);
      }
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 100vw;
    z-index: 4;
    background: var(--background-color) 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    transition-property: left;
    transition-duration: 0.4s;
    &.show {
      left: 0;
    }
    &__container {
      margin-top: 70px;
      height: calc(100vh - 70px);
      z-index: 4;
      padding-top: 10px;
      overflow-y: scroll;
      background-color: rgba(255, 255, 255, 0.1);
      border-top: solid 1px white;
    }
    &__content {
      z-index: 4;
      width: fit-content;
      padding: 10px 0;
      margin: 10px 0 10px 42px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      border: none;
      transition-duration: 0.4s;
      &:hover {
        border-bottom: white solid 2px;
        font-weight: 900;
      }
      &.sub {
        margin: 0 0 5px 62px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
</style>
