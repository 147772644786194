
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { TaskType } from "@/enum/taskType";
import {TaskStatus} from "@/enum/taskStatus";

export default defineComponent({
  name: "TaskTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      task: computed(() => store.state.tasks.task),
    });
    return {
      ...toRefs(state),
      TaskType,
      TaskStatus,
    };
  },
});
