<template>
  <div class="multiple-toggle__wrapper" :style="styles">
    <div :class="[
        'multiple-toggle__item',
        index === selectedIndex && selectable ? 'selected' : '',
        selectable ? 'selectable': '',
        ]"
         v-for="(item, index) in items"
         :key="index"
         @click="selectItem(index)">
      <slot name="item" v-bind="{item, index}">{{ item }}</slot>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, reactive, toRefs} from "vue";

export default {
  name: "MultipleToggle",
  props: {
    items: {
      type: Array,
      default: [] as string[],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    fontSize: {
      type: String,
      default: "12px",
    },
    background: {
      type: String,
      default: "#909090",
    },
    selectedBackground: {
      type: String,
      default: "#0a6dc7",
    },
    selectable: {
      type: Boolean,
      default: true,
    }
  },
  setup(props: any, context: any) {
    const state = reactive({
      selectedIndex: props.modelValue,
      styles: computed(() => {
        return { "--width": props.width, "--font-size": props.fontSize, "--background": props.background, "--selected-background": props.selectedBackground };
      }),
    });
    const selectItem = (index: number) => {
      if (!props.selectable) return;
      state.selectedIndex = index;
      context.emit("update:modelValue", state.selectedIndex);
      context.emit("change");
    };
    return {
      ...toRefs(state),
      selectItem
    };
  },
}
</script>

<style scoped lang="scss">
.multiple-toggle__wrapper {
  display: flex;
  width: var(--width);
  gap: 10px;
  overflow-x: scroll;
  flex-wrap: wrap;
}
.multiple-toggle__item {
  display: block;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 4px 10px;
  background: var(--background);
  color: white;
  width: fit-content;
  &.selected {
    background: var(--selected-background);
  }
  &.selectable:hover {
    background: var(--selected-background);
    cursor: pointer;
  }
}
</style>