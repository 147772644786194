<template>
  <div class="base-container__white">
    <div class="access-denied">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" />
      </header>
      <div class="access-denied__content">
        <div class="access-denied__content__catch">
          <img src="@/assets/img/errorpage-image.svg" />
          <div class="access-denied__content__catch__text">
            <div class="access-denied__content__catch__text__error-code">403</div>
            <div class="access-denied__content__catch__text__error-text">Access Denied</div>
          </div>
        </div>
        <div class="access-denied__content__text mt-40px">
          <h3>
            ページを表示できません。<br />
            権限がないためページの表示ができませんでした。アクセスが必要な場合は管理者に問い合わせてください。
          </h3>
        </div>
        <router-link :to="{ name: 'Home' }">
          <base-button class="mt-80px">トップページに戻る</base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "access-denied",
  components: { BaseButton },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.access-denied {
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    text-align: center;
    margin-top: 10%;
    &__catch {
      width: fit-content;
      margin: 0 auto;
      position: relative;
      &__text {
        position: absolute;
        top: 50px;
        left: 180px;
        &__error-code {
          font-size: 64px;
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
        &__error-text {
          font-size: 18px;
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
      }
    }
  }
}
</style>
