<template>
  <div class="plan-banner" v-if="!company?.isExpired">
    <div class="plan-banner__plan">{{ plan?.name }}</div>
    <div class="plan-banner__counter" v-if="displayType === 'HOME' && company?.plan?.id === 1">
      <div class="plan-banner__counter__text">有効期限</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">
        {{ company?.expiredIn }}
      </div>
    </div>
    <div class="plan-banner__counter" v-else-if="displayType === 'HOME' && company?.plan?.id !== 1">
      <div class="plan-banner__counter__text">今月の検索回数</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.searchLimitation, company?.actions.slice(-1)[0].searchCount) }}
      </div>
      <div class="plan-banner__counter__text">回</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'SEARCH'">
      <div class="plan-banner__counter__text">今月の検索回数</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.searchLimitation, company?.actions.slice(-1)[0].searchCount) }}
      </div>
      <div class="plan-banner__counter__text">回</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'AISEARCH'">
      <div class="plan-banner__counter__text">今月のAI検索回数</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.aiSearchLimitation, company?.actions.slice(-1)[0].aiSearchCount) }}
      </div>
      <div class="plan-banner__counter__text">回</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'PREDICTION'">
      <div class="plan-banner__counter__text">今月の効果測定回数</div>
      <div class="plan-banner__counter__text" v-show="plan?.likePredictionLimitation > 0">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.likePredictionLimitation, company?.actions.slice(-1)[0].likePredictionCount) }}
      </div>
      <div class="plan-banner__counter__text" v-show="plan?.likePredictionLimitation > 0">回</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'MEMBER'">
      <div class="plan-banner__counter__text">メンバーの上限</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">{{ getSafeCount(plan?.memberLimitation, company?.members.length) }}</div>
      <div class="plan-banner__counter__text">人</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'DETAIL'">
      <div class="plan-banner__counter__text">詳細閲覧回数</div>
      <div class="plan-banner__counter__text">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.detailLimitation, company?.actions.slice(-1)[0].detailCount) }}
      </div>
      <div class="plan-banner__counter__text">人</div>
    </div>
    <div class="plan-banner__counter" v-if="displayType === 'MYLIST'">
      <div class="plan-banner__counter__text">マイリスト件数</div>
      <div class="plan-banner__counter__text" v-show="plan?.myListLimitation > 0">残り</div>
      <div class="plan-banner__counter__number">
        {{ getSafeCount(plan?.myListLimitation, company?.myListCount) }}
      </div>
      <div class="plan-banner__counter__text" v-show="plan?.myListLimitation > 0">人</div>
    </div>
  </div>
  <div class="plan-banner is-expired" v-else>
    <div class="plan-banner__plan">トライアルの有効期限が切れています</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "PlanBanner",
  props: {
    displayType: {
      type: String,
      default: "SEARCH",
    },
  },
  setup() {
    const store = useStore();
    const state = reactive({
      plan: computed(() => store.state.companies.company?.plan),
      company: computed(() => store.state.companies?.company),
    });
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    return {
      ...toRefs(state),
      getSafeCount,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.plan-banner {
  display: flex;
  width: fit-content;
  height: 40px;
  background: linear-gradient(96deg, #79b5db 0%, #aae3df 100%);
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  &__plan {
    color: white;
    font-size: 14px;
    font-weight: 900;
  }
  &__counter {
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: white;
    &__text {
      font-weight: 700;
      font-size: 12px;
      margin: 0 5px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__number {
      font-size: 22px;
      margin-bottom: 5px;
      font-weight: 900;
    }
  }
}
.is-expired {
  background: #ea9090;
}
</style>
