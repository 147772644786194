<template>
  <div class="plan-card__wrapper">
    <div class="plan-card" v-for="plan in displayPlans" :key="plan.id" :class="['plan-card', getPlanColor(plan)]">
      <div class="title">{{ plan.name }}<span class="usage-period" v-if="plan?.name === 'トライアルプラン'">（7日間）</span></div>
      <div class="content">
        <div class="text">検索可能数</div>
        <div class="value">月{{ plan.searchLimitation?.toLocaleString() }}回まで</div>
      </div>
      <div class="content">
        <div class="text">レポート表示可能数</div>
        <div class="value">月{{ plan.detailLimitation }}回まで</div>
      </div>
      <div class="content">
        <div class="text">AI検索（ベータ版）可能数</div>
        <div class="value">月{{ plan.aiSearchLimitation?.toLocaleString() }}回まで</div>
      </div>
      <div class="content">
        <div class="text">インフルエンサーリスト保存上限</div>
        <div class="value">{{ displayLimitation(plan.myListLimitation) }}</div>
      </div>
      <div class="content">
        <div class="text">いいね分析</div>
        <div class="value">{{ displayLimitation(plan.likePredictionLimitation, before="月") }}</div>
      </div>
      <div class="content">
        <div class="text">メンバー上限</div>
        <div class="value">{{ plan.memberLimitation }}人</div>
      </div>
      <div class="content">
        <div class="text">最低利用期間</div>
        <div class="value">{{ displayLimitation(plan.minUsageMonth, before="", after="ヶ月", noDataVal="なし") }}</div>
      </div>
      <div class="content">
        <div class="text">初期費用</div>
        <div class="value">{{ displayLimitation(plan.initialPrice?.toLocaleString(), before="¥", after="", noDataVal="無料") }}</div>
      </div>
      <div class="price">
        <div class="text">月額</div>
        <div class="value">¥{{ plan.price?.toLocaleString() }}<span class="excluding-tax"> 税抜</span></div>
      </div>
      <div class="button__container" v-if="hasCompanyAdminRole">
        <base-button color="disabled" shadow disabled v-if="plan?.name === 'トライアルプラン' && company?.plan?.id === plan.id">現在のプラン<span v-if="company?.isExpired"><br />（トライアル期間終了）</span></base-button>
        <base-button color="success" shadow @click="purchasePlan(plan)" v-else-if="company?.plan?.name === 'トライアルプラン' && company?.plan?.id !== plan.id">サブスクリプション<br />の購入</base-button>
        <base-button color="accent" shadow @click="cancelPlan" v-else-if="company?.plan?.id === plan.id">現在のプランを解約</base-button>
        <base-button color="success" shadow @click="changePlan(plan)" v-else>プランの変更</base-button>
      </div>
      <div class="button__container" v-else>
        <base-button color="disabled" shadow disabled v-if="company?.plan?.id=== plan.id">現在のプラン</base-button>
      </div>
    </div>
  </div>
  <div class="plan-card__dialog" v-show="showPurchase" v-if="selectedPlan && showPurchase">
    <div class="plan-card__dialog__container">
      <div class="plan-card__dialog__content">
        <div class="title">サブスクリプションを購入する</div>
        <div class="mt-40px d-flex justify-content-center">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="mt-40px d-flex justify-content-center">
          <h4>{{ selectedPlan?.name }}を購入しますか？</h4>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-stripe-checkout :plan-id="selectedPlan?.id" v-if="selectedPlan">
            <base-button size="short'" color="success" class="mr-20px">購入する</base-button>
          </base-stripe-checkout>
          <base-button size="short" color="dark" @click="cancelPurchase">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="plan-card__dialog" v-show="showChange" v-else-if="selectedPlan && showChange">
    <div class="plan-card__dialog__container">
      <div class="plan-card__dialog__content">
        <div class="title">サブスクリプションプランを変更する</div>
        <div class="mt-40px d-flex justify-content-center">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="mt-40px d-flex justify-content-center">
          <h4>プランを変更すると、ご登録いただいているクレジットカードに使用プランごとのご利用日数分が請求されます<br /><br />現在のプランは{{ company?.plan?.name }}です<br />{{ selectedPlan?.name }}に変更しますか？</h4>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button :size="'short'" :color="'success'" class="mr-20px" @click="confirmChangePlan">変更する</base-button>
          <base-button :size="'short'" :color="'dark'" @click="showChange = false">キャンセル</base-button>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmChange && loading">
          <div class="title">プランを変更しています</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>反映されるまでしばらくお待ちください</h4></div>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmChange && loadSessionSuccess">
          <div class="title">プランを変更しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><h4>新しいプランをお楽しみください</h4></div>
          <div class="description"><base-button :size="'short'" :color="'dark'" @click="submitBackBtn">戻る</base-button></div>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmChange && loadSessionErrorMessage">
          <div class="title">プランの変更に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description"><h4>お手数をおかけしますが、最初からやり直してください</h4></div>
          <div class="description"><base-button :size="'short'" :color="'dark'" @click="submitBackBtn">戻る</base-button></div>
        </div>
      </div>
    </div>
  </div>
  <div class="plan-card__dialog" v-show="showNotChange" v-else-if="showNotChange">
    <div class="plan-card__dialog__container">
      <div class="plan-card__dialog__content">
        <div class="title">サブスクリプションプランを変更出来ません</div>
        <div class="mt-40px d-flex justify-content-center">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="mt-40px d-flex justify-content-center">
          <h4>{{ company?.plan?.name }}の最低利用期間である<br />{{ company?.plan?.minUsageMonth }}ヶ月を超えていないため、変更することが出来ません。<br />変更希望の方はお問い合わせ下さい。</h4>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">
            <base-button :size="'short'" :color="'success'" class="mr-20px">お問い合せ</base-button>
          </router-link>
          <base-button :size="'short'" :color="'dark'" @click="showNotChange = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="plan-card__dialog" v-show="showCancel" v-else-if="showCancel">
    <div class="plan-card__dialog__container">
      <div class="plan-card__dialog__content">
        <div class="title">サブスクリプションプランを解約する</div>
        <div class="mt-40px d-flex justify-content-center">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="mt-40px d-flex justify-content-center">
          <h4>プランを解約すると、解約時点よりトライアルプランへと変更されます。<br /><br />現在のプランは{{ company?.plan?.name }}です<br />トライアルプランに変更してもよろしいですか？</h4>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button :size="'short'" :color="'success'" class="mr-20px" @click="confirmCancelPlan">変更する</base-button>
          <base-button :size="'short'" :color="'dark'" @click="showCancel = false">キャンセル</base-button>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmCancel && loading">
          <div class="title">プランを解約しています</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>反映されるまでしばらくお待ちください</h4></div>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmCancel && cancelPlanSuccess">
          <div class="title">プランを解約しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button :size="'short'" :color="'dark'" @click="submitBackBtn">戻る</base-button></div>
        </div>
        <div class="plan-card__dialog__confirm__dialog" v-show="showConfirmCancel && loadSessionErrorMessage">
          <div class="title">プランの解約に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description"><h4>お手数をおかけしますが、最初からやり直してください</h4></div>
          <div class="description"><base-button :size="'short'" :color="'dark'" @click="submitBackBtn">戻る</base-button></div>
        </div>
      </div>
    </div>
  </div>
  <div class="plan-card__dialog" v-show="showNotCancel" v-else-if="showNotCancel">
    <div class="plan-card__dialog__container">
      <div class="plan-card__dialog__content">
        <div class="title">サブスクリプションプランを解約出来ません</div>
        <div class="mt-40px d-flex justify-content-center">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="mt-40px d-flex justify-content-center">
          <h4>{{ company?.plan?.name }}の最低利用期間である<br />{{ company?.plan?.minUsageMonth }}ヶ月を超えていないため、解約することが出来ません。</h4>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">
            <base-button :size="'short'" :color="'success'" class="mr-20px">お問い合せ</base-button>
          </router-link>
          <base-button :size="'short'" :color="'dark'" @click="showNotCancel = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref, onMounted, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { useStore } from "vuex";
import { Plan, Company } from "@/types";
import BaseStripeCheckout from "@/components/BaseStripeCheckout.vue";
import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: "PlanCard",
  components: { BaseButton, ExclamationMark, CheckMark, BaseStripeCheckout, Loading },
  props: {
    enableSubscriptionLink: {
      type: Boolean,
      default: false,
    },
    hasCompanyAdminRole: {
      type: Boolean,
      default: false,
    },
    scrollOffset: {
      type: Number,
      default: 0,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const planCardRef = ref<HTMLElement>();
    store.dispatch("plans/getPlans");
    if (props.enableSubscriptionLink) store.dispatch("users/loadCurrentUser");
    const state = reactive({
      showPurchase: false,
      showChange: false,
      showConfirmChange: false,
      showCancel: false,
      showNotCancel: false,
      showNotChange: false,
      showConfirmCancel: false,
      selectedPlan: null as null | Plan,
      displayPlans: [] as Plan[],
      plans: computed(() => store.state.plans.plans),
      loadPlansSuccess: computed(() => store.state.plans.loadPlansSuccess),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.plans.loading),
      loadSessionSuccess: computed(() => store.state.plans.loadSessionIdSuccess),
      loadSessionErrorMessage: computed(() => store.state.plans.errorMessage),
      cancelPlanSuccess: computed(() => store.state.plans.cancelPlanSuccess),
    });
    onMounted(() => {
      if (!props.disableScroll) {
        window.addEventListener("scroll", () => {
          if (planCardRef.value?.getBoundingClientRect().top && planCardRef.value?.getBoundingClientRect().bottom && state.plans && planCardRef.value?.getBoundingClientRect().top < props.scrollOffset && planCardRef.value?.getBoundingClientRect().bottom >= props.scrollOffset) {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "position: absolute; width: 100%; top: " + (props.scrollOffset - planCardRef.value?.getBoundingClientRect().top) + "px; z-index: 5; border: solid 2px white; opacity: 0.9;");
              planCardRef.value?.children[i].children[1].setAttribute("style", "margin-top: 120px;");
            }
          } else if (planCardRef.value?.getBoundingClientRect().bottom && state.plans && planCardRef.value?.getBoundingClientRect().bottom < props.scrollOffset) {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "position: absolute; width: 100%; top: " + planCardRef.value?.clientHeight + "px; z-index: 5; border-radius: 0 0 10px 10px;");
              planCardRef.value?.children[i].children[1].setAttribute("style", "margin-top: 120px;");
            }
          } else {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "");
              planCardRef.value?.children[i].children[1].setAttribute("style", "");
            }
          }
        });
      }
    });
    const displayLimitation = (limitation: number, before = "", after = "回まで", noDataVal = "×"): string => {
      if (limitation < 0) return "無制限";
      else if (limitation == 0) return noDataVal;
      else return before + String(limitation.toLocaleString()) + after;
    };
    const purchasePlan = (plan: Plan) => {
      state.selectedPlan = plan;
      state.showPurchase = true;
    };
    const changePlan = (plan: Plan) => {
      state.selectedPlan = plan;
      if (state.company?.isCancelablePlan) {
        state.showChange = true;
      } else {
        state.showNotChange = true;
      }
    };
    const confirmChangePlan = () => {
      store.dispatch("plans/getSessionId", state.selectedPlan?.id);
      state.showConfirmChange = true;
    };
    const cancelPlan = () => {
      if (state.company?.isCancelablePlan) {
        state.showCancel = true;
      } else {
        state.showNotCancel = true;
      }
    };
    const confirmCancelPlan = () => {
      store.dispatch("plans/cancelPlan");
      state.showConfirmCancel = true;
    };
    const submitBackBtn = () => {
      window.location.reload();
    };
    const cancelPurchase = () => {
      window.location.reload();
    };
    let plans = computed(() => state.plans);
    watch(plans, (val: Plan[]) => {
      state.displayPlans = val.filter((x: Plan) => {
        if (x.isPublic) return true;
        return x.id === state.company?.plan?.id;
      });
    });
    let company = computed(() => state.company);
    watch(company, (val: Company) => {
      state.displayPlans = state.plans.filter((x: Plan) => {
        if (x.isPublic) return true;
        return x.id === val?.plan?.id;
      });
    });
    const getPlanColor = (plan: Plan) => {
      if (plan?.id === state.company?.plan?.id) {
        return "gray";
      } else if (plan?.name === "アドバンスドプラン") {
        return "orange";
      } else {
        return "blue";
      }
    };
    return {
      ...toRefs(state),
      displayLimitation,
      purchasePlan,
      changePlan,
      confirmChangePlan,
      cancelPlan,
      submitBackBtn,
      confirmCancelPlan,
      cancelPurchase,
      planCardRef,
      getPlanColor,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.plan-card {
  width: 460px;
  border-radius: 20px;
  background: white;
  margin-top: 20px;
  &.orange {
    border: solid 2px #eda400;
    color: #eda400;
    & .title::after {
      background: #eda400;
    }
    & .button__container {
      border-top: solid 2px #eda400;
    }
  }
  &.blue {
    border: solid 2px #198fd9;
    color: #198fd9;
    & .title::after {
      background: #198fd9;
    }
    & .button__container {
      border-top: solid 2px #198fd9;
    }
  }
  &.gray {
    border: solid 2px #a8a8a8;
    color: #a8a8a8;
    & .title::before {
      content: "現在のプラン";
      color: white;
      border-radius: 9999px;
      width: fit-content;
      padding: 14px 24px;
      font-size: 14px;
      font-weight: 900;
      background: #a8a8a8;
      position: absolute;
      text-align: center;
      top: -10px;
      right: 0;
    }
    & .title::after {
      background: #a8a8a8;
    }
    & .button__container {
      border-top: solid 2px #a8a8a8;
    }
  }
  &__wrapper {
    display: flex;
    gap: 24px;
  }
  & .title {
    font-size: 24px;
    margin: 35px 20px 50px 20px;
    position: relative;
    font-weight: 900;
    &::after {
      content: "";
      width: 140px;
      height: 2px;
      position: absolute;
      bottom: -26px;
      left: 0;
    }
    & .usage-period {
      font-weight: 500;
      font-size: 13px;
      align-self: flex-end;
    }
  }
  & .content {
    margin: 17px 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    & .value {
      color: #6a6a6a;
    }
  }
  & .price {
    margin: 13px 20px 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .text {
      font-weight: 900;
      font-size: 20px;
    }
    & .value {
      font-weight: 900;
      font-size: 36px;
      & .excluding-tax {
        font-size: 16px;
        align-self: flex-end;
        margin-left: 4px;
      }
    }
  }
  & .button__container {
    text-align: center;
    padding: 18px 0;
  }
  &__body {
    padding: 20px;
    & .item {
      & .label {
        & h4 {
          font-weight: 700;
          color: map-get($colors, "blue300");
        }
      }
      & span {
        font-size: 14px;
      }
    }
    & .button {
      text-align: center;
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: fixed;
    }
    &__content {
      position: fixed;
      width: 400px;
      padding: 80px 140px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      & .title {
        flex-direction: column;
        font-size: 18px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        text-align: center;
      }
      & .form {
        width: fit-content;
        margin: 0 auto;
      }
      & h4 {
        text-align: center;
      }
    }
    &__confirm__dialog {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      align-items: center;
      justify-items: center;
      justify-content: center;
      & .title {
        flex-direction: column;
        margin: 140px 0 40px 0;
      }
      & .loading-box {
        width: fit-content;
        margin: 20px auto;
      }
      & .description {
        width: fit-content;
        margin: 20px auto;
      }
    }
  }
}
.d-flex-end {
  align-self: flex-end;
}
</style>
