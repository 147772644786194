
import { defineComponent, reactive, computed, toRefs, watch, PropType } from "vue";
import { LineChart, BarChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import { useStore } from "vuex";
import BasicTable from "@/components/BasicTable.vue";
import { ChartPlug, Code, GenderPerAge, Gender, GenderLabel, AudienceCountry, Post, StatHistory, TableHeader, Influencer, Tag, AccountHashtag, ChartData, AudienceData, Language, Age } from "@/types";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default defineComponent({
  name: "TwitterReport",
  components: {
    BasicTable,
    LineChart,
    BarChart,
    DoughnutChart,
    TwitterIcon,
  },
  props: {
    account: {
      type: Object as PropType<Influencer>,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      monthlyFollowerData: {} as ChartData,
      monthlyViewData: {} as ChartData,
      monthlyLikeData: {} as ChartData,
      engagementData: {} as ChartData,
      followerLanguages: [] as AudienceData<Language>[],
      followerCountries: [] as AudienceCountry[],
      followerLocationCountryData: {} as ChartData,
      followerGenderData: {} as ChartData,
      followerGenderPerAgeData: {} as ChartData,
      popularPosts: [] as Post[],
      recentPosts: [] as Post[],
      hashtags: [] as Tag[],
      hashtagsForTable: [] as AccountHashtag[],
      success: false,
      loadInfluencerSuccess: computed(() => store.state.influencers.getInfluencerSuccess),
    });
    const getJPDate = (val: string) => {
      const date = new Date(String(val).replace(/-/g, "/"));
      return Number(date.getFullYear()) + "年" + Number(date.getMonth() + 1) + "月" + Number(date.getDate()) + "日";
    };
    const getPercentage = (val: number) => {
      return (Math.round(val * 10000) / 100).toString() + "%";
    };
    const getLocaleString = (val: number) => {
      return val ? Math.round(val).toLocaleString() : "データなし";
    };
    const getDeltaRate = (dataArr: number[]) => {
      let resArr = [""];
      for (let i = 0; i < dataArr.length; i++) {
        if (i !== 0) {
          let tempVal = "";
          if (dataArr[i] < dataArr[i - 1]) {
            tempVal = "▼" + (((dataArr[i - 1] - dataArr[i]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          } else if (dataArr[i] > dataArr[i - 1]) {
            tempVal = "▲" + (((dataArr[i] - dataArr[i - 1]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          }
          resArr.push(tempVal);
        }
      }
      return resArr;
    };
    const getGenderPerAge = (genderPerAgeData: GenderPerAge[]) => {
      const res: GenderLabel = {
        label: [],
        female: [],
        male: [],
      };
      genderPerAgeData.map((x: GenderPerAge) => {
        if (!res.label.includes(x.age.code)) res.label.push(x.age.code);
      });
      res.label.sort();
      res.label.map((x: string) => {
        genderPerAgeData.map((y: GenderPerAge) => {
          if (y.age.code === x) {
            if (y.gender.code === "FEMALE") res.female.push(y.weight * 100);
            if (y.gender.code === "MALE") res.male.push(y.weight * 100);
          }
        });
      });
      return res;
    };
    type KeyPluralIndex = "genders" | "ages" | "languages";
    type StrIndex = "gender" | "age" | "language";
    type CodeIndex = "code" | "name";
    const mergeFollowerAndLikerData = (keyPlural: KeyPluralIndex, key: StrIndex, code: CodeIndex = "code", useJPName = false) => {
      const codes: Array<Code | string> = [];
      const res: Gender[] = [];
      const emptyArr: AudienceData<Language | Age | Gender>[] = [];
      const baseDict = { [keyPlural]: emptyArr };
      (props.account?.audience || baseDict)[keyPlural].slice().map((x: AudienceData<Language | Age | Gender>) => {
        const obj = x[key];
        if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
          const safeCode = String(obj[code]);
          const safeJpName = typeof obj.jpName === "string" ? obj.jpName : "";
          const codeObj: Code = { code: safeCode, jpName: safeJpName };
          return useJPName ? codes.push(codeObj) : codes.push(String(obj[code]));
        }
      });
      if (!useJPName) codes.sort();
      codes.slice().map((x: Code | string) => {
        const resDict: { follower?: number; code: string } = typeof x === "string" ? { code: String(x) } : { code: String(x.jpName) || x.code || "" };
        ((props.account?.audience || baseDict)[keyPlural] || []).slice().map((y: AudienceData<Language | Age | Gender>) => {
          const obj = y[key];
          if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
            if (typeof x !== "string") {
              if (obj[code] === x.code) resDict.follower = y.weight;
            } else {
              if (obj[code] === String(x)) resDict.follower = y.weight;
            }
          }
        });
        res.push(resDict);
      });
      return res;
    };
    const mergeFollowerAndLikerGenderPerAge = (gender: string) => {
      const codes: string[] = [];
      const res: Gender[] = [];
      ((props.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((x: GenderPerAge) => {
        if (!codes.includes(x.age.code)) codes.push(x.age.code);
      });
      codes.sort();
      codes.map((x: string) => {
        const resDict: Gender = { code: x };
        ((props.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((y: GenderPerAge) => {
          if (y.age.code === x && y.gender.code === gender) resDict.follower = y.weight;
        });
        res.push(resDict);
      });
      return res.sort((x: Gender, y: Gender) => (x.code > y.code ? 1 : -1));
    };
    const getRealNumAndPercentage = (totalNum: number | void, currentRatio: number | void) => {
      if (totalNum === undefined) return "-　/　-";
      if (currentRatio === undefined) return "-　/　-";
      return Math.round(currentRatio * totalNum).toLocaleString() + "　/　" + getPercentage(currentRatio);
    };
    Chart.defaults.font.size = 10;
    const monthlyChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          min: 0,
          maxTicksLimit: 8,
          padding: 20,
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
          ticks: {
            min: 0,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          font: {},
        },
        datalabels: {
          align: "end",
          offset: 8,
          font: {
            size: 10,
            weight: 400,
          },
          formatter: function (value: ChartPlug, context: ChartPlug) {
            return getDeltaRate(context.chart.data.datasets[0].data)[context.dataIndex];
          },
          color: function (value: ChartPlug) {
            if (getDeltaRate(value.chart.data.datasets[0].data)[value.dataIndex][0] === "▼") {
              return "#FF0000";
            } else {
              return "#46D540";
            }
          },
        },
      },
    };
    const engagementChartOption = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
          },
        },
        yAxis1: {
          stacked: true,
          id: "y-axis-1",
          type: "linear",
          position: "left",
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
        yAxis2: {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            fontSize: 12,
            callback: function (value: number) {
              if (value.toFixed(0).length === 1) {
                return "      " + value.toFixed(0) + "%";
              }
              return "    " + value.toFixed(0) + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: function (val: ChartPlug) {
            return val.dataset.yAxisID === "yAxis2";
          },
          align: "end",
          offset: 8,
          color: "#9299F7",
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return value.toFixed(1) + "%";
          },
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let labelIndex = context.datasetIndex;
              let label = ["エンゲージメント率", "いいね数", "コメント数", "リツイート数"][labelIndex];
              return label + ": " + context.parsed.y.toLocaleString();
            },
          },
        },
      },
    };
    const locationChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            min: 0,
            max: 100,
            padding: 10,
          },
        },
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#f7f7f7",
          },
          ticks: {
            fontSize: 10,
            padding: 10,
            callback: function (value: number) {
              return value + "%";
            },
          },
        },
      },
      layout: {
        padding: {
          right: 40,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: 10,
            weight: "Bold",
          },
          anchor: "end",
          align: "end",
          padding: {
            bottom: 0,
          },
          formatter: function (value: number) {
            return (value || 0).toFixed(1) + "%";
          },
        },
      },
    };
    const genderChartOption = {
      cutoutPercentage: 65,
      legend: {
        display: true,
        position: "chartArea",
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
          align: "end",
          offset: 16,
          color: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return (value * 100).toFixed(1) + "%";
          },
        },
      },
    };
    const genderPerAgeChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            fontSize: 10,
          },
        },
        y: {
          ticks: {
            fontSize: 10,
            callback: function (value: number) {
              return value + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: 10,
            weight: "Bold",
          },
          anchor: "end",
          align: "end",
          padding: {
            bottom: -8,
          },
          formatter: function (value: number | string) {
            if (value == "") {
              return "";
            } else {
              return (Number(value) || 0).toFixed(1) + "%";
            }
          },
        },
      },
    };
    const genderHeaders: TableHeader[] = [
      {
        text: "性別",
        value: "code",
        disableSort: true,
      },
      {
        text: "フォロワー",
        value: "follower",
        disableSort: true,
      },
    ];
    const ageHeaders: TableHeader[] = [
      {
        text: "年齢",
        value: "code",
        disableSort: true,
      },
      {
        text: "フォロワー",
        value: "follower",
        disableSort: true,
      },
    ];
    const countryHeaders: TableHeader[] = [
      {
        text: "国",
        value: "country",
        disableSort: true,
      },
      {
        text: "フォロワー",
        value: "weight",
        disableSort: true,
      },
    ];
    const languageHeaders: TableHeader[] = [
      {
        text: "言語",
        value: "language",
        disableSort: true,
      },
      {
        text: "フォロワー",
        value: "weight",
        disableSort: true,
      },
    ];
    const fillData = () => {
      state.popularPosts = (props.account?.popularPosts || [])
        .slice()
        .sort((x: Post, y: Post) => (x.likes > y.likes ? -1 : 1))
        .slice(0, 6);
      state.recentPosts = (props.account?.recentPosts || [])
        .slice()
        .sort((x: Post, y: Post) => (x.postedAt > y.postedAt ? -1 : 1))
        .slice(0, 6);
      state.hashtagsForTable = (props.account?.hashtags || []).sort((x: AccountHashtag, y: AccountHashtag) => (x.weight > y.weight ? -1 : 1)).slice(0, 5);
      state.followerLanguages = (props.account?.audience?.languages || [])
        .reduce((x: AudienceData<Language>[], y: AudienceData<Language>) => {
          let element = x.find((p: AudienceData<Language>) => {
            if (p.language !== undefined && typeof p.language !== "string" && typeof p.language !== "number" && y.language !== undefined && typeof y.language !== "string" && typeof y.language !== "number") {
              return p.language.code === y.language.code;
            } else {
              return false;
            }
          });
          if (element) {
            element.weight += y.weight;
          } else {
            x.push({
              language: y.language,
              weight: y.weight,
              id: "",
            });
          }
          return x;
        }, [])
        .slice()
        .sort((x: AudienceData<Language>, y: AudienceData<Language>) => (x.weight > y.weight ? -1 : 1))
        .slice(0, 10);
      state.followerCountries = (props.account?.audience?.countries || [])
        .reduce((x: AudienceCountry[], y: AudienceCountry) => {
          let element = x.find((p: AudienceCountry) => {
            return p.country.code === y.country.code;
          });
          if (element) {
            element.weight += y.weight;
          } else {
            x.push({
              country: y.country,
              weight: y.weight,
              id: 0,
              audience: 0,
            });
          }
          return x;
        }, [])
        .slice()
        .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
        .slice(0, 10);
      state.monthlyFollowerData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.followers;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyViewData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgViews;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyLikeData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgLikes;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.engagementData = {
        labels: (state.recentPosts || [])
          .slice()
          .reverse()
          .map((x: Post) => {
            return getJPDate(x.postedAt);
          }),
        datasets: [
          {
            type: "line",
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return ((x.likes + x.comments) / Number(props.account?.followers)) * 100;
              }),
            lineTension: 0.3,
            borderColor: "#9299F7",
            pointBackgroundColor: "#9299F7",
            fill: false,
            yAxisID: "yAxis2",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.likes;
              }),
            borderColor: "#FFA0A0",
            backgroundColor: "rgba(255, 160, 160, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.comments;
              }),
            borderColor: "#79B5DB",
            backgroundColor: "rgba(121, 181, 219, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
                .slice()
                .reverse()
                .map((x: Post) => {
                  return x.retweets ? x.retweets : 0;
                }),
            borderColor: "#79B5DB",
            backgroundColor: "rgba(255, 170, 80, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
        ],
      };
      state.followerLocationCountryData = {
        labels: (props.account?.audience?.countries || [])
          .slice()
          .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
          .map((x: AudienceCountry) => {
            return x.country.jpName ? x.country.jpName : x.country.name;
          })
          .slice(0, 6),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.audience?.countries || [])
              .slice()
              .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
              .map((x: AudienceCountry) => {
                return x.weight * 100;
              })
              .slice(0, 6),
            backgroundColor: "rgba(148, 229, 176, 1)",
            barPercentage: 0.7,
          },
        ],
      };
      let genders = ((props.account?.audience || { genders: [] }).genders || []).slice();
      if (genders[0] && typeof genders[0].gender !== "string" && typeof genders[0].gender !== "number" && genders[0].gender !== undefined && genders[0].gender.code === "MALE") {
        genders = genders.reverse();
      }
      state.followerGenderData = {
        datasets: [
          {
            label: {
              display: false,
            },
            data: genders.slice().map((x: AudienceData<Gender>) => x.weight),
            backgroundColor: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          },
        ],
      };
      const followerGenderPerAge = getGenderPerAge((props.account?.audience || { genderPerAges: [] }).genderPerAges.sort((x: GenderPerAge, y: GenderPerAge) => (x.age.code > y.age.code ? 1 : -1)));
      state.followerGenderPerAgeData = {
        labels: followerGenderPerAge.label.slice(),
        datasets: [
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.female.slice(),
            borderColor: "rgba(255, 160, 160, 1)",
            backgroundColor: "rgba(255, 160, 160, 1)",
            barPercentage: 0.7,
          },
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.male.slice(),
            borderColor: "rgba(121, 181, 219, 1)",
            backgroundColor: "rgba(121, 181, 219, 1)",
            barPercentage: 0.7,
          },
        ],
      };
      state.success = true;
    };
    let account = computed(() => props.account);
    watch(account, () => fillData());
    return {
      ...toRefs(state),
      getPercentage,
      getLocaleString,
      monthlyChartOption,
      engagementChartOption,
      locationChartOption,
      genderChartOption,
      genderPerAgeChartOption,
      getJPDate,
      getRealNumAndPercentage,
      mergeFollowerAndLikerData,
      mergeFollowerAndLikerGenderPerAge,
      genderHeaders,
      ageHeaders,
      countryHeaders,
      languageHeaders,
    };
  },
});
