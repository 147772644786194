<template>
  <svg class="material-design-icon__svg" :width="size" :height="size" viewBox="0 0 54 54" v-show="!src">
    <g transform="translate(14079 5900)">
      <circle cx="24" cy="24" r="24" transform="translate(-14079 -5900)" :fill="fillColor" />
      <path
        d="M23.074,25.4H22.015V1.27A1.27,1.27,0,0,0,20.745,0H2.964a1.27,1.27,0,0,0-1.27,1.27V25.4H.635A.635.635,0,0,0,0,26.038V27.1H23.709V26.038A.635.635,0,0,0,23.074,25.4ZM6.774,4.022a.635.635,0,0,1,.635-.635H9.526a.635.635,0,0,1,.635.635V6.139a.635.635,0,0,1-.635.635H7.409a.635.635,0,0,1-.635-.635Zm0,5.08a.635.635,0,0,1,.635-.635H9.526a.635.635,0,0,1,.635.635v2.117a.635.635,0,0,1-.635.635H7.409a.635.635,0,0,1-.635-.635Zm2.752,7.832H7.409a.635.635,0,0,1-.635-.635V14.183a.635.635,0,0,1,.635-.635H9.526a.635.635,0,0,1,.635.635V16.3A.635.635,0,0,1,9.526,16.935ZM13.548,25.4H10.161V20.957a.635.635,0,0,1,.635-.635h2.117a.635.635,0,0,1,.635.635Zm3.387-9.1a.635.635,0,0,1-.635.635H14.183a.635.635,0,0,1-.635-.635V14.183a.635.635,0,0,1,.635-.635H16.3a.635.635,0,0,1,.635.635Zm0-5.08a.635.635,0,0,1-.635.635H14.183a.635.635,0,0,1-.635-.635V9.1a.635.635,0,0,1,.635-.635H16.3a.635.635,0,0,1,.635.635Zm0-5.08a.635.635,0,0,1-.635.635H14.183a.635.635,0,0,1-.635-.635V4.022a.635.635,0,0,1,.635-.635H16.3a.635.635,0,0,1,.635.635Z"
        transform="translate(-14066.854 -5889.548)"
        fill="#fff"
      />
    </g>
  </svg>
  <img :src="src" v-show="src" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CompanyIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    size: {
      type: Number,
      default: 54,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String as () => string | null,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
img {
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
