<script lang="ts">
import {computed, defineComponent, reactive, toRefs} from "vue";
import { TableHeader } from "@/types";

export default defineComponent({
  name: "BasicTable",
  props: {
    headers: {
      type: Array,
      default: [] as TableHeader[] | null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headerFontSize: {
      type: String,
      default: "13px",
    },
    cellFontSize: {
      type: String,
      default: "13px",
    },
    cellHeight: {
      type: String,
      default: "78px",
    },
  },
  setup(props, context) {
    const state = reactive({
      headers: props.headers,
      items: props.items,
      sortedPos: {
        value: "",
        direction: "",
      },
      styles: computed(() => {
        return {
          "--header-font-size": props.headerFontSize,
          "--cell-font-size": props.cellFontSize,
          "--cell-height": props.cellHeight,
        };
      }),
    });
    const sortItems = (sortValue: string, direction: string) => {
      state.items = state.items.sort((a: any, b: any) => {
        if (direction == "asc") {
          return a[sortValue] > b[sortValue] ? 1 : -1;
        } else {
          return a[sortValue] < b[sortValue] ? 1 : -1;
        }
      });
      state.sortedPos.value = sortValue;
      state.sortedPos.direction = direction;
    };
    const checkSorted = (sortValue: string, direction: string) => {
      return state.sortedPos.value === sortValue && state.sortedPos.direction === direction;
    };
    return {
      ...toRefs(state),
      sortItems,
      checkSorted,
    };
  },
});
</script>

<template>
  <table :style="styles">
    <thead>
      <tr>
        <th v-for="head in headers" :key="head.value">
          <slot name="header" :headItem="head">{{ head.text }}</slot>
          <span :class="['asc-arrow', checkSorted(head.value, 'asc') ? 'sorted' : '']" v-show="!head?.disableSort" @click="sortItems(head.value, 'asc')">↑</span>
          <span :class="['desc-arrow', checkSorted(head.value, 'desc') ? 'sorted' : '']" v-show="!head?.disableSort" @click="sortItems(head.value, 'desc')">↓</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.length">
        <td v-for="head in headers" :key="head.value">
          <slot :name="head.value" v-bind="item">{{ item[head.value] }}</slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss">
@import "src/assets/styles/main";
table {
  width: 100%;
  border-radius: 11px;
  word-break: break-all;
  border-spacing: unset;
  font-size: var(--cell-font-size);
}
th {
  background: map-get($colors, "blue300");
  padding-right: 10px;
  text-align: left;
  color: white;
  font-size: var(--header-font-size);
  & .asc-arrow {
    margin-left: 6px;
  }
  & .asc-arrow,
  & .desc-arrow {
    visibility: collapse;
    color: white;
    font-size: var(--header-font-size);
    &.sorted {
      color: #0f0f0f;
      visibility: visible;
    }
    &:hover {
      color: #0f0f0f;
    }
  }
  &:hover .asc-arrow,
  &:hover .desc-arrow {
    visibility: visible;
    cursor: pointer;
  }
  &:first-child {
    border-radius: 10px 0 0 0;
  }
  &:last-child {
    border-radius: 0 10px 0 0;
  }
}
tr {
  &:nth-child(odd) {
    background: #eeeeee;
  }
  &:nth-child(even) {
    background: white;
  }
  &:last-child {
    & td {
      &:first-child {
        border-radius: 0 0 0 10px;
      }
      &:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }
}
th,
td {
  padding: 0 20px;
  box-sizing: border-box;
  height: var(--cell-height);
}
</style>
