import axios from "@/store/axios";
import { Commit } from "vuex";
import {Task, CreateTaskParams, UpdateTaskParams} from "@/types";

interface State {
  tasks: Task[];
  task: Task | null;
  errorMessage: string | null;
  createTaskSuccess: boolean;
  loadTaskSuccess: boolean;
  updateTaskSuccess: boolean;
}
const state: State = {
  tasks: [],
  task: null,
  errorMessage: null,
  createTaskSuccess: false,
  loadTaskSuccess: false,
  updateTaskSuccess: false,
};

const tasks = {
  namespaced: true,
  state,
  mutations: {
    setTasks(state: State, tasks: Task[]) {
      state.tasks = tasks;
    },
    setTask(state: State, task: Task) {
      state.task = task;
    },
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setCreateTaskSuccess(state: State, flag: boolean) {
      state.createTaskSuccess = flag;
    },
    setLoadTaskSuccess(state: State, flag: boolean) {
      state.loadTaskSuccess = flag;
    },
    setUpdateTaskSuccess(state: State, flag: boolean) {
      state.updateTaskSuccess = flag;
    },
  },
  actions: {
    createTaskInitialize({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setCreateTaskSuccess", false);
    },
    async createTask({ commit }: { commit: Commit }, params: CreateTaskParams) {
      commit("setErrorMessage", null);
      commit("setCreateTaskSuccess", false);
      try {
        await axios.post("api/v1/listing-tasks/", params);
        commit("setCreateTaskSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateTask({ commit }: { commit: Commit },{ params, taskId }: { params: UpdateTaskParams, taskId: string}) {
      commit("setErrorMessage", null);
      commit("setUpdateTaskSuccess", false);
      try {
        await axios.patch(`api/v1/listing-tasks/${taskId}/`, params);
        commit("setUpdateTaskSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async loadTasks({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setLoadTaskSuccess", false);
      try {
        const res = await axios.get("api/v1/listing-tasks/");
        commit("setTasks", res.data.results);
        commit("setLoadTaskSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async loadTask({ commit }: { commit: Commit }, taskId: string) {
      commit("setErrorMessage", null);
      try {
        const res = await axios.get(`api/v1/listing-tasks/${taskId}/`);
        commit("setTask", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default tasks;
