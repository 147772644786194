export class DayOfWeek {
  static readonly MONDAY = new DayOfWeek("Monday", "月曜日");
  static readonly TUESDAY = new DayOfWeek("Tuesday", "火曜日");
  static readonly WEDNESDAY = new DayOfWeek("Wednesday", "水曜日");
  static readonly THURSDAY = new DayOfWeek("Thursday", "木曜日");
  static readonly FRIDAY = new DayOfWeek("Friday", "金曜日");
  static readonly SATURDAY = new DayOfWeek("Saturday", "土曜日");
  static readonly SUNDAY = new DayOfWeek("Sunday", "日曜日");

  private constructor(private readonly key: string, public readonly text: string) {}

  static findTextByKey(key: string): string {
    const dayOfWeek = this.getAll().find((dayOfWeek) => dayOfWeek.key === key);
    return dayOfWeek ? dayOfWeek.text : "";
  }
  static getObject(key: string): DayOfWeek | null {
    const dayOfWeek = this.getAll().find((dayOfWeek) => dayOfWeek.key === key);
    return dayOfWeek !== undefined ? dayOfWeek : null;
  }
  private static getAll(): Array<DayOfWeek> {
    return [DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY, DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
  }
}
