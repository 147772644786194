<template>
  <div class="youtube-report-fullscreen">
    <youtube-report :account="account"></youtube-report>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs } from "vue";
import YoutubeReport from "@/components/YoutubeReport.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";


export default defineComponent({
  name: "YoutubeInfluencerReport",
  components: { YoutubeReport },
  setup() {
    const store = useStore();
    const route = useRoute();
    const influencerId = route.params.id;
    store.dispatch("influencers/getInfluencerDetail", influencerId);
    const state = reactive({
      account: computed(() => store.state.influencers.influencer),
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.youtube-report-fullscreen {
  width: 100%;
  background-color: #F0F2F4 !important;
  overflow-y: hidden !important;
  size: 1400px 1960px;
}
@page {
  size: 1400px 1960px;
  margin: 0;
}
</style>
