export class AgeGroup {
  static readonly FIRST= new AgeGroup(1, "13-17");
  static readonly SECOND = new AgeGroup(2, "18-24");
  static readonly THIRD = new AgeGroup(3, "25-34");
  static readonly FOURTH = new AgeGroup(4, "35-44");
  static readonly FIFTH = new AgeGroup(5, "45-64");
  static readonly SIXTH = new AgeGroup(6, "65-");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const ageGroup = this.getAll().find((ageGroup) => ageGroup.key === key);
    return ageGroup ? ageGroup.text : "";
  }

  static getObject(key: number): AgeGroup | null {
    const ageGroup = this.getAll().find((ageGroup) => ageGroup.key === key);
    return ageGroup !== undefined ? ageGroup : null;
  }

  private static getAll(): Array<AgeGroup> {
    return [AgeGroup.FIRST, AgeGroup.SECOND, AgeGroup.THIRD, AgeGroup.FOURTH, AgeGroup.FIFTH, AgeGroup.SIXTH];
  }
}
