<template>
  <div class="notification-icon__wrapper material-design-icon__svg" :style="styles">
    <svg class="notification-icon__content" viewBox="0 0 18 21">
      <path d="M21,19v1H3V19l2-2V11a6.99,6.99,0,0,1,5-6.71V4a2,2,0,0,1,4,0v.29A6.99,6.99,0,0,1,19,11v6l2,2m-7,2a2,2,0,0,1-4,0" transform="translate(-3 -2)" :fill="fillColor" />
    </svg>
    <div class="notification-icon__badge" v-show="newsCount">
      <div class="notification-icon__badge__num">{{ getNewsCount(newsCount) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
export default defineComponent({
  name: "NotificationIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 21,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    newsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        "--height": props.height,
        "--width": props.width,
      };
    });
    const getNewsCount = (newsCount: number) => {
      return newsCount > 99 ? "99+" : String(newsCount);
    };
    return { styles, getNewsCount };
  },
});
</script>

<style lang="scss">
.notification-icon {
  &__wrapper {
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    position: relative;
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  &__badge {
    position: absolute;
    width: calc(var(--width) * 1px);
    height: calc(var(--width) * 1px);
    top: -50%;
    right: -50%;
    font-weight: 900;
    color: white;
    border-radius: 50%;
    background-color: #f75977;
    text-align: center;
    &__num {
      display: flex;
      margin-top: 2px;
      justify-content: center;
      font-size: 8px;
    }
  }
}
</style>
