<template>
  <div class="base-container">
    <div class="result__dialog__container" v-if="(createInfluencerListSuccess || errorMessage) && show.create">
      <div class="result__dialog" v-if="createInfluencerListSuccess">
        <div class="title">作成が完了しました</div>
        <div class="description"><check-mark></check-mark></div>
        <div class="description"><base-button size="short" color="dark" @click="show.create = false"> OK </base-button></div>
      </div>
      <div class="result__dialog" v-else>
        <div class="title error">作成に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.create = false"> 戻る </base-button></div>
      </div>
    </div>
    <div class="result__dialog__container" v-else-if="formErrorMessage && show.errorDialog">
      <div class="result__dialog">
        <div class="title error">作成に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ formErrorMessage }}</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
      </div>
    </div>
    <div class="result__dialog__container" v-if="(reloadInfluencerList || errorMessage) && show.delete">
      <div class="result__dialog" v-if="reloadInfluencerList">
        <div class="title">削除が完了しました</div>
        <div class="description"><check-mark></check-mark></div>
        <div class="description"><base-button size="short" color="dark" @click="show.delete = false"> OK </base-button></div>
      </div>
      <div class="result__dialog" v-else>
        <div class="title error">削除に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.delete = false"> 戻る </base-button></div>
      </div>
    </div>
    <div class="result__dialog__container" v-else-if="formErrorMessage && show.errorDialog">
      <div class="result__dialog">
        <div class="title error">削除に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ formErrorMessage }}</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
      </div>
    </div>
    <sidebar :current-tab="5"></sidebar>
    <div class="main" v-show="company">
      <base-header header-title="インフルエンサーリスト" display-type="MYLIST"></base-header>
      <div class="bread mt-20px d-flex">
        <div v-for="(item, idx) in childInfluencerList?.familyTree" :key="item.id" class="d-flex">
          <h4 v-show="idx === 0" @click="moveFolder(item.id, true)">リストTOP</h4>
          <h4 v-show="idx !== 0" class="disabled">></h4>
          <h4 v-show="idx !== 0" :class="item.id === childInfluencerList?.id ? 'disabled' : ''" @click="moveFolder(item.id)">{{ item.name }}</h4>
        </div>
      </div>
      <div class="influencer-list" v-if="company?.plan?.myListLimitation !== 0" :key="childInfluencerList">
        <router-link :to="{ name: 'IDSearch' }">
          <base-button color="success" size="wide" class="mt-20px mb-20px mr-20px"> インフルエンサーを検索する </base-button>
        </router-link>
        <base-button class="mr-20px" size="short" color="dark" @click="show.folder = true">フォルダ作成</base-button>
        <base-button color="dark" size="wide" @click="openList">インフルエンサーをフォルダにコピー</base-button>
        <influencer-list-children-table v-if="(childInfluencerList?.children || []).length > 0" @folderDelete="reloadInfluencerList" :key="(childInfluencerList?.children || []).length" :items="childInfluencerList?.children"></influencer-list-children-table>
        <influencer-list-table class="mt-20px" @influencer-delete="reloadInfluencerList" :list-id="influencerListId" v-show="(childInfluencerList?.influencers || []).length > 0" :items="childInfluencerList?.influencers" :key="(childInfluencerList?.influencers || []).length"></influencer-list-table>
      </div>
      <div class="influencer-list" v-else>
        <div class="influencer-list__no-function-container">
          <h1>ご利用中のプランではこの機能はお使いいただけません。</h1>
          <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
            <base-button color="success" size="wide" class="mt-20px mr-20px"> プランを変更する </base-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-table" v-show="show.list">
    <div class="dialog-table__container">
      <div class="dialog-table__content">
        <div class="dialog-table__content__table">
          <div class="dialog-table__content__table__header d-flex">
            <base-checkbox v-model="allChecked" :id="String(influencerListId)"></base-checkbox>
          </div>
          <div class="dialog-table__content__table__body">
            <div :class="['dialog-table__content__table__body__content']" v-for="influencer in companyInfluencers" :ref="setItemRefs" :key="influencer.id" v-show="filterInfluencer(influencer)">
              <div v-if="filterInfluencer(influencer)" class="d-flex">
                <base-checkbox v-model="checked[influencer.id]" :id="String(influencer.id)" v-if="count"></base-checkbox>
                <div class="dialog-table__content__table__body__thumbnail">
                  <img :src="influencer?.picture" />
                </div>
                <div class="dialog-table__content__table__body__sns">
                  <instagram-icon v-if="influencer.type === 0"></instagram-icon>
                  <youtube-icon v-if="influencer.type === 1"></youtube-icon>
                  <tiktok-icon v-if="influencer.type === 2"></tiktok-icon>
                </div>
                <div class="dialog-table__content__table__body__influencer-name">
                  <h4>{{ influencer?.fullname }}</h4>
                  <h6>{{ influencer?.username }}</h6>
                </div>
                <div class="d-flex dialog-table__content__table__right align-items-center">
                  <div class="dialog-table__content__table__body__gender">
                    <h4>{{ influencer?.gender }}</h4>
                  </div>
                  <div class="dialog-table__content__table__body__follower">
                    <h3>{{ influencer?.followers?.toLocaleString() }}</h3>
                  </div>
                  <div class="dialog-table__content__table__body__engagement">
                    <h3>{{ influencer?.engagements?.toLocaleString() }}</h3>
                    <h6>{{ String((influencer?.engagements / influencer?.followers) * 100).slice(0, 4) }}%</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-table__content__button d-flex justify-content-center">
          <base-button color="dark" class="mr-20px" @click="show.list = false">キャンセル</base-button>
          <base-button @click="addInfluencerList">追加する</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog" v-show="show.folder">
    <div class="dialog__container">
      <div class="dialog__content">
        <div class="title">フォルダを作成する</div>
        <div class="mt-40px">
          <div class="form">
            <base-text-field placeholder="フォルダ名を入力" v-model="folderName" label="フォルダ名" class="mt-20px"></base-text-field>
          </div>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button size="short" class="mr-20px" @click="createInfluencerList">作成</base-button>
          <base-button size="short" color="dark" @click="show.folder = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import InfluencerListTable from "@/components/InfluencerListTable.vue";
import InfluencerListChildrenTable from "@/components/InfluencerListChildrenTable.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { InfluencerList, CreateInfluencerListParams, Influencer, AddInfluencerListParams } from "@/types";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import { AddInfluencerParams } from "@/types/models/influencers";
import BaseTextField from "@/components/BaseTextField.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";

export default defineComponent({
  name: "InfluencerListChildren",
  components: {
    BaseHeader,
    Sidebar,
    BaseButton,
    InfluencerListTable,
    InfluencerListChildrenTable,
    BaseCheckbox,
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    BaseTextField,
    CheckMark,
    ExclamationMark,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("influencers/getCompanyInfluencers");
    let influencerListId = Number(route.params.id);
    store.dispatch("influencers/getInfluencerList", influencerListId);
    const state = reactive({
      show: {
        influencer: false,
        list: false,
        folder: false,
        errorDialog: false,
        create: false,
        delete: false,
      },
      id: {
        type: String,
        default: "checkbox",
      },
      count: 1,
      allChecked: false,
      checked: {} as any,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers?.influencerList),
      childInfluencerList: computed(() => store.state.influencers?.childInfluencerList),
      companyInfluencers: computed(() => store.state.influencers?.companyInfluencers),
      childList: computed(() => store.state.influencers?.influencerList?.children),
      createInfluencerListSuccess: computed(() => store.state.influencers.createInfluencerListSuccess),
      addInfluencerListSuccess: computed(() => store.state.influencers?.addInfluencerListSuccess),
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      listName: null as string | null,
      selectedInfluencers: [] as Influencer[],
      folderName: null as string | null,
      influencerListId: null as number | null,
    });
    state.influencerListId = influencerListId;
    let itemRefs = [] as any[];
    const setItemRefs = (el: any) => {
      itemRefs.push(el);
    };
    let allChecked = computed(() => state.allChecked);
    watch(allChecked, (val: boolean) => {
      state.count++;
      // header のチェックボックスが選択されたら全選択、外れたら全部選択を外す
      state.companyInfluencers.slice().map((item: any, index: number) => {
        if (state.checked[item.id] !== val && itemRefs[index].children[0]?.children[0]) itemRefs[index].children[0].children[0].click();
      });
    });
    const openList = () => {
      state.show.list = true;
      state.selectedInfluencers = [];
    };
    const addInfluencerList = () => {
      state.companyInfluencers.map((item: Influencer) => {
        if (state.checked[item.id]) state.selectedInfluencers.push(item);
      });
      const influencersParams = [] as AddInfluencerParams[];
      state.selectedInfluencers.slice().map((x: Influencer) => {
        influencersParams.push({ id: x.id });
        state.checked[x.id] = false;
      });
      const params: AddInfluencerListParams = {
        influencerList: Number(state.childInfluencerList?.id),
        influencers: influencersParams,
      };
      store.dispatch("influencers/addInfluencerList", params);
      state.show.list = false;
    };
    const reloadInfluencerList = () => {
      store.dispatch("influencers/getInfluencerList", influencerListId);
      state.show.delete = true;
    };
    const createInfluencerList = () => {
      state.formErrorMessage = null;
      if (!state.folderName) {
        state.formErrorMessage = "フォルダ名は必須です";
        state.show.errorDialog = true;
      } else {
        const params: CreateInfluencerListParams = {
          company: state.user?.companyId,
          derivedFrom: influencerListId,
          name: state?.folderName,
        };
        store.dispatch("influencers/createInfluencerList", params);
        store.dispatch("influencers/getInfluencerList", influencerListId);
      }
    };
    let createInfluencerListSuccess = computed(() => state.createInfluencerListSuccess);
    watch(createInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.folder = false;
      if (val) state.show.create = true;
      if (val) store.dispatch("influencers/getInfluencerList", influencerListId);
    });
    const filterInfluencer = (influencer: Influencer) => {
      if (Array.isArray(state.childInfluencerList?.influencers) && influencer.id) {
        const influencers = state.childInfluencerList.influencers.slice().map((x: Influencer) => {
          return x.id;
        });
        return !influencers.includes(influencer.id);
      }
      return false;
    };
    onBeforeRouteUpdate((to) => {
      const toId = Number(to?.params?.id);
      influencerListId = toId;
      store.dispatch("influencers/getInfluencerList", influencerListId);
    });
    const moveFolder = (id: number | null, isRoot = false) => {
      if (isRoot) {
        router.push({ name: "InfluencerList" });
      } else {
        router.push({ name: "InfluencerListChildren", params: { id: id } });
      }
    };
    return {
      ...toRefs(state),
      addInfluencerList,
      setItemRefs,
      influencerListId,
      reloadInfluencerList,
      createInfluencerList,
      filterInfluencer,
      moveFolder,
      openList,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.influencer-list {
  &__no-function-container {
    width: 80%;
    padding: 80px;
    text-align: center;
    background-color: white;
    border-radius: 20px;
    margin-top: 60px;
    & h1 {
      color: map-get($colors, "primary");
      margin-bottom: 40px;
    }
  }
}
.bread {
  padding: 5px 20px;
  margin: 20px 20px 0 20px;
  border-radius: 5px;
  background-color: white;
  white-space: nowrap;
  overflow-x: scroll;
  & h4 {
    color: map-get($colors, "blue300");
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    font-weight: 700;
    &:hover {
      color: map-get($colors, "blue200");
    }
  }
  & .disabled {
    color: map-get($colors, "gray300");
    cursor: default;
    &:hover {
      color: map-get($colors, "gray300");
    }
  }
}
.dialog {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: #40404047 0 0 no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100%;
  &__container {
    position: relative;
  }
  &__content {
    position: absolute;
    width: 280px;
    padding: 60px 120px;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 20%);
    -webkit-transform: translate(-50%, 20%);
    -ms-transform: translate(-50%, 20%);
    background-color: white;
    border-radius: 10px;
    & .title {
      font-size: 18px;
      font-weight: 700;
      color: map-get($colors, "blue400");
      text-align: center;
    }
    & .form {
      width: fit-content;
      margin: 0 auto;
    }
  }
  & .icon {
    width: 54px;
    height: 54px;
    border: 2px solid map-get($colors, "blue300");
    background: map-get($colors, "blue300");
    border-radius: 50%;
    cursor: pointer;
    &__input {
      position: relative;
      &__image {
        pointer-events: none;
        & img {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &__btn {
        display: inline-block;
        position: relative;
        cursor: pointer;
        border-radius: 5px;
        width: 54px;
        height: 54px;
        & input[type="file"] {
          top: 0;
          left: 0;
          opacity: 0;
          position: absolute;
          width: 54px;
          height: 54px;
          cursor: pointer;
        }
      }
    }
    &__label {
      & h4 {
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
      & h3 {
        font-weight: 500;
      }
    }
  }
}
.dialog-table {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: #40404047 0 0 no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100%;
  &__container {
    position: relative;
  }
  &__content {
    position: absolute;
    width: 800px;
    top: 0;
    left: 50%;
    height: calc(100vh - 200px);
    transform: translate(-50%, 20%);
    -webkit-transform: translate(-50%, 20%);
    -ms-transform: translate(-50%, 20%);
    &__table {
      &__right {
        margin-left: auto;
        margin-right: 0;
        align-items: center;
      }
      &__header {
        padding: 20px 20px;
        border-radius: 10px 10px 0 0;
        background: map-get($colors, "blue300");
        & h4 {
          color: white;
          font-weight: 700;
        }
        &__gender {
          width: 56px;
          text-align: center;
        }
        &__follower {
          width: 80px;
          text-align: right;
          margin: 0 20px;
        }
        &__engagement {
          width: 100px;
          text-align: right;
          margin: 0 20px;
        }
        &__post-date {
          width: 90px;
          margin: 0 20px;
          text-align: left;
        }
      }
      &__body {
        height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: white;
        &::-webkit-scrollbar {
          width: 10px;
          display: block !important;
        }
        &::-webkit-scrollbar-track {
          background-color: white;
        }
        &::-webkit-scrollbar-thumb {
          background-color: map-get($colors, "primary");
          border-radius: 10px;
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
        }
        &__content {
          padding: 20px 20px;
          background-color: white;
          align-items: center;
          position: relative;
        }
        &__thumbnail {
          & img {
            width: 54px;
            height: 54px;
            border-radius: 50%;
          }
        }
        &__sns {
          margin: 0 10px;
        }
        &__influencer-name {
          width: 280px;
          & h4 {
            font-weight: 900;
          }
        }
        &__gender {
          width: 56px;
          text-align: center;
        }
        &__follower {
          width: 100px;
          text-align: right;
          margin: 0 20px;
        }
        &__engagement {
          width: 80px;
          text-align: right;
          margin: 0 20px;
        }
        &__post-date {
          width: 90px;
          margin: 0 20px;
          & h4 {
            text-align: left;
          }
        }
      }
    }
    &__button {
      width: 760px;
      height: fit-content;
      padding: 20px;
      background: white;
      border-radius: 0 0 10px 10px;
    }
  }
}
.result__dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
