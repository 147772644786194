<template>
  <div class="member-table">
    <div class="member-table__header d-flex">
      <div class="d-flex base-table__right">
        <div class="mail">
          <h4>メールアドレス</h4>
        </div>
        <div class="authority d-flex align-items-center">
          <h4>権限</h4>
          <tooltip class="align-self-center">権限とは、そのユーザーがチーム内のコンテンツのアクセスできる権限のことを指します。</tooltip>
        </div>
        <div><h4>招待日</h4></div>
        <div class="blank"></div>
      </div>
    </div>
    <div class="member-table__body d-flex align-items-center" v-for="item in items" :key="item.id">
      <div class="mail">
        <h4>{{ item.email }}</h4>
      </div>
      <div class="authority">
        <h4>{{ MemberRole.findTextByKey(item.role) }}</h4>
      </div>
      <div class="date">
        <h4>{{ formatDate(item.createdAt) }}</h4>
      </div>
      <div class="member-table__body__icons d-flex">
        <trash-icon :invitation-member="item" @confirmDelete="handleInvitationConfirmDelete(item.id)" v-if="hasCompanyAdminRole" trash-type="member" :id="`member-invitation-${item.id}`"></trash-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import { useStore } from "vuex";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import { formatDate } from "@/plugins/formatter";
import { MemberRole } from "@/enum/memberRole";

export default defineComponent({
  name: "InvitationMemberTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hasCompanyAdminRole: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tooltip,
    TrashIcon,
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: {
        add: true,
        trash: false,
      },
      invitationMember: computed(() => store.state.members.invitationMember),
    });
    const sortByKey = (arr: [], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const handleInvitationConfirmDelete = (invitationId: number) => {
      store.dispatch("members/deleteInvitationMember", invitationId);
    };
    return { ...toRefs(state), sortByKey, formatDate, MemberRole, handleInvitationConfirmDelete };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.member-table {
  width: 100%;
  &__right {
    margin-left: auto;
    margin-right: 0;
    align-items: center;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
  }
  &__body {
    height: fit-content;
    padding: 20px 20px;
    background-color: white;
    word-break: break-all;
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    &__icons {
      margin-left: auto;
    }
  }
  & .blank {
    width: 92px;
  }
  & .mail {
    width: 280px;
  }
  & .authority {
    width: 120px;
  }
  & .status {
    width: 100px;
  }
}
</style>
