<template>
  <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 40 30">
    <path d="M36.25,69h-15l-5-5H3.75A3.75,3.75,0,0,0,0,67.75v22.5A3.75,3.75,0,0,0,3.75,94h32.5A3.75,3.75,0,0,0,40,90.25V72.75A3.75,3.75,0,0,0,36.25,69Z" transform="translate(0 -64)" fill="#707070"/>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FolderIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 30,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String as () => string | null,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
</style>
