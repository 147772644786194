
import { computed, ComputedRef, defineComponent, reactive, ref, toRefs } from "vue";
import { ComboBoxItem, HamburgerMenuItem, Inquiry, InquiryError } from "@/types";
import { useStore } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import InfrectLogo from "@/components/icon/InfrectLogo.vue";

export default defineComponent({
  name: "TOP",
  components: { InfrectLogo, BaseTextField, BaseSelectBox, BaseTextArea, BaseCheckbox, BaseButton, HamburgerMenu, CheckMark },
  setup() {
    const store = useStore();
    const state = reactive({
      search: {
        detail: true,
        id: false,
        hashtag: false,
      },
      report: {
        detail: true,
        post: false,
        audience: false,
      },
      form: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: false as boolean,
      },
      formError: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: null as string | null,
      },
      errorMessage: computed(() => store.state.inquiries.errorMessage) as ComputedRef<InquiryError>,
      success: computed(() => store.state.inquiries.success) as ComputedRef<boolean>,
      fillColor: "#fff",
    });
    const links: HamburgerMenuItem[] = [
      {
        name: "ログイン",
        link: { name: "SignIn" },
      },
      {
        name: "7日間の無料トライアル",
        link: { name: "Trial" },
      },
    ];
    const LPHeader = ref<HTMLElement>();
    const Contact = ref<HTMLElement>();
    window.addEventListener("scroll", () => {
      let opacity = 1 - (1 - (window.scrollY > 100 ? 100 : window.scrollY) / 120);
      if (LPHeader.value) {
        LPHeader.value.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
        if (window.scrollY > 100) {
          LPHeader.value.style.boxShadow = "2px 0 3px 3px rgba(0, 0, 0, 0.1)";
          state.fillColor = "#198FD9";
        } else {
          LPHeader.value.style.boxShadow = "none";
          state.fillColor = "#fff";
        }
      }
    });
    const contactTypes: string[] = ["新規登録およびトライアルについて", "資料請求", "インフルエンサーの追加希望", "サービスに関する相談", "その他"];
    const selectItems: ComboBoxItem[] = contactTypes.map((x: string) => {
      return {
        text: x,
        value: x,
      };
    });
    const getContentPlaceHolder = () => {
      if (!state.form) return "お問い合わせ内容の詳細をこちらにご記入ください";
      if (state.form.title === "インフルエンサーの追加希望") return "対応を希望されるインフルエンサーのURLを入力してください";
      return "お問い合わせ内容の詳細をこちらにご記入ください";
    };
    const submit = () => {
      let err = false;
      state.formError = {
        companyName: null,
        departmentName: null,
        name: null,
        email: null,
        phone: null,
        title: null,
        content: null,
        check: null,
      };
      if (!state.form?.companyName) {
        state.formError.companyName = "会社名は必須です";
        err = true;
      }
      if (!state.form?.name) {
        state.formError.name = "お名前は必須です";
        err = true;
      }
      if (!state.form?.email) {
        state.formError.email = "メールアドレスは必須です";
        err = true;
      }
      if (!state.form?.title) {
        state.formError.title = "お問い合わせ種別を選択してください";
        err = true;
      }
      if (!state.form?.content) {
        state.formError.content = "お問い合わせ内容は必須です";
        err = true;
      }
      if (!state.form?.check) {
        state.formError.check = "お問い合わせには利用規約・プライバシーポリシーに同意する必要があります";
        err = true;
      }
      if (err) {
        Contact.value?.scrollIntoView({
          behavior: "smooth",
        });
        return;
      }
      const data: Inquiry = {
        companyName: String(state.form.companyName),
        departmentName: state.form.departmentName,
        name: String(state.form.name),
        email: String(state.form.email),
        phone: state.form.phone ? String(state.form.phone) : null,
        title: String(state.form.title),
        content: state.form.content,
      };
      store.dispatch("inquiries/createInquiry", data);
      Contact.value?.scrollIntoView({
        behavior: "smooth",
      });
    };
    const searchImageToggle = (name: string) => {
      if (name === "detail") {
        state.search.detail = true;
        state.search.id = false;
        state.search.hashtag = false;
      } else if (name === "id") {
        state.search.detail = false;
        state.search.id = true;
        state.search.hashtag = false;
      } else if (name === "hashtag") {
        state.search.detail = false;
        state.search.id = false;
        state.search.hashtag = true;
      }
    };
    const reportImageToggle = (name: string) => {
      if (name === "detail") {
        state.report.detail = true;
        state.report.post = false;
        state.report.audience = false;
      } else if (name === "post") {
        state.report.detail = false;
        state.report.post = true;
        state.report.audience = false;
      } else if (name === "audience") {
        state.report.detail = false;
        state.report.post = false;
        state.report.audience = true;
      }
    };
    return {
      ...toRefs(state),
      LPHeader,
      Contact,
      links,
      selectItems,
      getContentPlaceHolder,
      submit,
      searchImageToggle,
      reportImageToggle,
    };
  },
});
