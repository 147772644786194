
import { defineComponent } from "vue";
export default defineComponent({
  name: "YoutubeIcon",
  props: {
    fillColor: {
      type: String,
      default: "#4c4c4c",
    },
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 12.656,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
