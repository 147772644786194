<template>
  <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 18 12.656">
    <path
      d="M32.557,65.98a2.262,2.262,0,0,0-1.591-1.6A53.44,53.44,0,0,0,23.933,64a53.44,53.44,0,0,0-7.032.379,2.262,2.262,0,0,0-1.591,1.6,25.465,25.465,0,0,0,0,8.721A2.228,2.228,0,0,0,16.9,76.278a53.441,53.441,0,0,0,7.032.379,53.441,53.441,0,0,0,7.032-.379A2.228,2.228,0,0,0,32.557,74.7a25.466,25.466,0,0,0,0-8.721ZM22.092,73.017V67.665l4.7,2.676-4.7,2.676Z"
      transform="translate(-14.933 -64)"
      :fill="fillColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "YoutubeIcon",
  props: {
    fillColor: {
      type: String,
      default: "#4c4c4c",
    },
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 12.656,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
