<template>
  <div class="infrect-report__container__top">
    <div class="infrect-report white">
      <div class="d-flex">
        <div class="side">infrect</div>
        <div class="infrect-report-main">
          <div class="infrect-report__account-info">
            <div class="infrect-report__account-info__logo">
              <div class="infrect-report__account-info__logo__sns-type d-flex align-items-center">
                <youtube-icon class="mr-10px" :width="24" :height="24" :fill-color="'white'"></youtube-icon>
                <h3>YouTube</h3>
              </div>
              <img src="@/assets/infrect-report-logo.svg" alt="INFRECT" />
            </div>
            <div class="infrect-report__account-info__name">
              <img id="influencer-thumbnail" :src="account?.picture" :alt="account?.fullname" />
              <div>
                <h2>{{ account?.fullname }}</h2>
                <h5>{{ account?.username }}</h5>
              </div>
            </div>
            <div class="infrect-report__account-info__bio">
              <div class="infrect-report__account-info__bio__title">自己紹介文</div>
              <div class="infrect-report__account-info__bio__text" v-if="account?.description">
                {{ account?.description }}
              </div>
              <div class="infrect-report__account-info__bio__text" v-else>データなし</div>
            </div>
          </div>
        </div>
        <div class="side">infrect</div>
      </div>
    </div>
    <div class="infrect-report">
      <div class="d-flex">
        <div class="side">infrect</div>
        <div class="infrect-report-main" v-if="success">
          <div class="d-flex mt-40px">
            <div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/img/follower-icon.svg" alt="登録者数" />登録者数</div>
                  <div class="infrect-report__account-value__content">{{ getLocaleString(account?.followers) }}</div>
                </div>
              </div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/img/liker-icon.svg" alt="平均高評価数" />平均高評価数</div>
                  <div class="infrect-report__account-value__content">{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgLikes: 0 }).avgLikes) }}</div>
                </div>
              </div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/img/engagement-icon.svg" alt="エンゲージメント率" />エンゲージメント率</div>
                  <div class="infrect-report__account-value__content">{{ getPercentage(account?.engagementRate) }}</div>
                </div>
              </div>
            </div>
            <div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/multiple-play-icon.svg" alt="総投稿数" />総投稿数</div>
                  <div class="infrect-report__account-value__content">{{ getLocaleString((account || { postsCount: 0 }).postsCount) }}</div>
                </div>
              </div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/play-icon.svg" alt="平均再生数" />平均再生数</div>
                  <div class="infrect-report__account-value__content">{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgViews: 0 }).avgViews) }}</div>
                </div>
              </div>
              <div class="infrect-report__account-value">
                <div class="infrect-report__account-value__inner">
                  <div class="infrect-report__account-value__title"><img src="@/assets/img/comment-icon.svg" alt="平均コメント数" />平均コメント数</div>
                  <div class="infrect-report__account-value__content">{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgComments: 0 }).avgComments) }}</div>
                </div>
              </div>
            </div>
            <div class="infrect-report__engagement" v-if="recentPosts.length">
              <div class="infrect-report__engagement__title__wrapper">
                <div class="infrect-report__engagement__title">直近のエンゲージメント</div>
                <div class="infrect-report__engagement__labels">
                  <div class="infrect-report__engagement__label"><img src="@/assets/img/liker-icon.svg" alt="高評価数" />高評価数</div>
                  <div class="infrect-report__engagement__label"><img src="@/assets/img/comment-icon.svg" alt="コメント数" />コメント数</div>
                  <div class="infrect-report__engagement__label"><img src="@/assets/img/engagement-icon.svg" alt="エンゲージメント率" />エンゲージメント率</div>
                </div>
              </div>
              <div class="infrect-report__engagement__graph">
                <bar-chart :chart-id="'latest-engagements'" :chart-data="engagementData" :options="engagementChartOption" :style="'width: 95%;'" :height="250"></bar-chart>
              </div>
            </div>
          </div>
          <div class="d-flex" v-if="account?.statHistories?.length">
            <div class="infrect-report__monthly-data">
              <div class="infrect-report__monthly-data__title">月ごとの登録者推移</div>
              <line-chart :chart-data="monthlyFollowerData" :options="monthlyChartOption" :style="'width: 360px;'" :height="220"></line-chart>
            </div>
            <div class="infrect-report__monthly-data">
              <div class="infrect-report__monthly-data__title">月ごとの再生数推移</div>
              <line-chart :chart-id="'monthly-view-graph'" :chart-data="monthlyViewData" :options="monthlyChartOption" :style="'width: 360px;'" :height="220"></line-chart>
            </div>
            <div class="infrect-report__monthly-data">
              <div class="infrect-report__monthly-data__title">月ごとの平均高評価数推移</div>
              <line-chart :chart-id="'monthly-like-graph'" :chart-data="monthlyLikeData" :options="monthlyChartOption" :style="'width: 360px;'" :height="220"></line-chart>
            </div>
          </div>
          <div class="d-flex" v-if="account?.hashtags?.length">
            <div class="infrect-report__tags">
              <div class="infrect-report__tags__title">
                <div>よく使うハッシュタグ</div>
                <div>使用率</div>
              </div>
              <div class="infrect-report__tags__content" v-for="hashtag in hashtagsForTable" :key="hashtag">
                <div class="infrect-report__tags__content__tag">#{{ hashtag?.hashtag?.tag }}</div>
                <div class="infrect-report__tags__content__weight">{{ (hashtag?.weight * 100).toFixed(1) + "%" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="side">infrect</div>
      </div>
    </div>
  </div>
  <div class="infrect-report">
    <div class="d-flex">
      <div class="side">infrect</div>
      <div class="infrect-report-main" v-if="success">
        <div class="infrect-report__container" v-if="popularPosts.length">
          <div class="infrect-report__container__header">
            <div class="infrect-report__container__header__title">人気の投稿</div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="infrect-report__post" v-for="item in popularPosts" :key="item.id">
              <div class="infrect-report__post__date">{{ getJPDate(item.postedAt) }}</div>
              <div class="infrect-report__post__img-container thumbnail">
                <img :src="item?.thumbnail" alt="人気の投稿"/>
              </div>
              <div class="infrect-report__post__title">{{ item.title }}</div>
              <div class="infrect-report__post__text">{{ item.text }}</div>
              <div class="infrect-report__post__engagements">
                <div class="infrect-report__post__engagement likes" v-if="item.likes && item.likes > 0"><img src="../assets/img/liker-icon.svg" alt="高評価数" />{{ getLocaleString(item.likes) }}</div>
                <div class="infrect-report__post__engagement comments" v-if="item.comments && item.comments > 0"><img src="../assets/img/comment-icon.svg" alt="コメント数" />{{ getLocaleString(item.comments) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="infrect-report__container" v-if="recentPosts.length">
          <div class="infrect-report__container__header">
            <div class="infrect-report__container__header__title">最近の投稿</div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="infrect-report__post" v-for="item in recentPosts" :key="item.id">
              <div class="infrect-report__post__date">{{ getJPDate(item.postedAt) }}</div>
              <div class="infrect-report__post__img-container thumbnail">
                <img :src="item?.thumbnail" alt="最近の投稿"/>
              </div>
              <div class="infrect-report__post__title">{{ item.title }}</div>
              <div class="infrect-report__post__text">{{ item.text }}</div>
              <div class="infrect-report__post__engagements">
                <div class="infrect-report__post__engagement likes" v-if="item.likes && item.likes > 0"><img src="../assets/img/liker-icon.svg" alt="高評価数" />{{ getLocaleString(item.likes) }}</div>
                <div class="infrect-report__post__engagement comments" v-if="item.comments && item.comments > 0"><img src="../assets/img/comment-icon.svg" alt="コメント数" />{{ getLocaleString(item.comments) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="infrect-report__container" v-if="account?.audience || account?.audience?.countries?.length || account?.audience?.genders?.length || account?.audience?.ages?.length">
          <div class="infrect-report__container__header">
            <div class="infrect-report__container__header__title">登録者のデータ</div>
          </div>
          <div>
            <div class="d-flex">
              <div class="infrect-report__gender" v-if="account?.audience?.genders?.length">
                <div class="infrect-report__gender__title">登録者の男女比</div>
                <div class="infrect-report__gender__content">
                  <doughnut-chart :chart-id="'follower-gender'" :chart-data="followerGenderData" :options="genderChartOption" :style="'margin-left: 0px;'" :width="180" :height="180"></doughnut-chart>
                  <div class="infrect-report__gender__content__labels">
                    <div class="infrect-report__gender__content__label" v-for="age in account?.audience.genders[0].gender.code === 'MALE' ? (account?.audience?.genders || []).reverse() : account?.audience.genders" :key="age">
                      <h4 :class="[age.gender.jpName === '女性' ? 'female' : 'male']">{{ age.gender.jpName }}</h4>
                      <h1 class="">{{ (age.weight * 100).toFixed(1) + "%" }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div class="infrect-report__age" v-if="account?.audience?.ages?.length">
                <div class="infrect-report__age__title">登録者の年齢層と男女比</div>
                <bar-chart :chart-id="'follower-gender-age'" :chart-data="followerGenderPerAgeData" :options="genderPerAgeChartOption" :style="'width: calc(100%);'" :height="200"></bar-chart>
              </div>
            </div>
          </div>
          <div class="section-title-container">
            <div v-if="account?.audience?.genders.length">性別</div>
            <div v-if="account?.audience?.ages.length">年齢層</div>
          </div>
          <div class="d-flex">
            <basic-table v-if="account?.audience?.genders.length" :headers="genderHeaders" :items="mergeFollowerAndLikerData('genders', 'gender', 'code', true)">
              <template #follower="{ follower }">
                <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
              </template>
            </basic-table>
            <basic-table v-if="account?.audience?.ages.length" :headers="ageHeaders" :items="mergeFollowerAndLikerData('ages', 'age')">
              <template #follower="{ follower }">
                <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
              </template>
            </basic-table>
          </div>
          <div v-if="account?.audience?.ages.length">
            <div class="section-title-container">
              <div>女性の年齢層</div>
              <div>男性の年齢層</div>
            </div>
            <div class="d-flex">
              <basic-table v-if="loadInfluencerSuccess" :headers="ageHeaders" :items="mergeFollowerAndLikerGenderPerAge('FEMALE')">
                <template #follower="{ follower }">
                  <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
                </template>
              </basic-table>
              <basic-table v-if="loadInfluencerSuccess" :headers="ageHeaders" :items="mergeFollowerAndLikerGenderPerAge('MALE')">
                <template #follower="{ follower }">
                  <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
                </template>
              </basic-table>
            </div>
          </div>
          <div v-if="account?.audience?.languages?.length">
            <div class="section-title-container">
              <div>言語</div>
            </div>
            <div class="d-flex">
              <basic-table :headers="languageHeaders" :items="followerLanguages" :key="followerLanguages">
                <template #language="{ language }">
                  <div v-if="language?.jpName">{{ language?.jpName }}</div>
                  <div v-else>{{ language?.name }}</div>
                </template>
                <template #weight="{ weight }">
                  <div>{{ getRealNumAndPercentage(account?.followers, weight) }}</div>
                </template>
              </basic-table>
            </div>
          </div>
        </div>
      </div>
      <div class="side">infrect</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, toRefs, watch, PropType } from "vue";
import { LineChart, BarChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import { Code, GenderPerAge, Gender, GenderLabel, Post, StatHistory, ChartPlug, TableHeader, Influencer, ChartData, AccountHashtag, Tag, AudienceData, Language, Age } from "@/types";
import { useStore } from "vuex";
import BasicTable from "@/components/BasicTable.vue";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default defineComponent({
  name: "YoutubeReport",
  components: {
    BasicTable,
    LineChart,
    BarChart,
    DoughnutChart,
    YoutubeIcon,
  },
  props: {
    account: {
      type: Object as PropType<Influencer>,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      monthlyFollowerData: {} as ChartData,
      monthlyViewData: {} as ChartData,
      monthlyLikeData: {} as ChartData,
      engagementData: {} as ChartData,
      followerGenderData: {} as ChartData,
      followerGenderPerAgeData: {} as ChartData,
      popularPosts: [] as Post[],
      recentPosts: [] as Post[],
      hashtags: [] as Tag[],
      hashtagsForTable: [] as AccountHashtag[],
      followerLanguages: [] as AudienceData<Language>[],
      success: false,
      loadInfluencerSuccess: computed(() => store.state.influencers.getInfluencerSuccess),
    });
    const getJPDate = (val: string) => {
      const date = new Date(String(val).replace(/-/g, "/"));
      return Number(date.getFullYear()) + "年" + Number(date.getMonth() + 1) + "月" + Number(date.getDate()) + "日";
    };
    const getPercentage = (val: number) => {
      return (Math.round(val * 10000) / 100).toString() + "%";
    };
    const getLocaleString = (val: number) => {
      return val ? Math.round(val).toLocaleString() : "データなし";
    };
    const getDeltaRate = (dataArr: number[]) => {
      let resArr = [""];
      for (let i = 0; i < dataArr.length; i++) {
        if (i !== 0) {
          let tempVal = "";
          if (dataArr[i] < dataArr[i - 1]) {
            tempVal = "▼" + (((dataArr[i - 1] - dataArr[i]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          } else if (dataArr[i] > dataArr[i - 1]) {
            tempVal = "▲" + (((dataArr[i] - dataArr[i - 1]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          }
          resArr.push(tempVal);
        }
      }
      return resArr;
    };
    const getGenderPerAge = (genderPerAgeData: GenderPerAge[]) => {
      const res: GenderLabel = {
        label: [],
        female: [],
        male: [],
      };
      genderPerAgeData.map((x: GenderPerAge) => {
        if (!res.label.includes(x.age.code)) res.label.push(x.age.code);
      });
      res.label.sort();
      res.label.map((x: string) => {
        genderPerAgeData.map((y: GenderPerAge) => {
          if (y.age.code === x) {
            if (y.gender.code === "FEMALE") res.female.push(y.weight * 100);
            if (y.gender.code === "MALE") res.male.push(y.weight * 100);
          }
        });
      });
      return res;
    };
    type KeyPluralIndex = "genders" | "ages" | "languages";
    type StrIndex = "gender" | "age" | "language";
    type CodeIndex = "code" | "name";
    const mergeFollowerAndLikerData = (keyPlural: KeyPluralIndex, key: StrIndex, code: CodeIndex = "code", useJPName = false) => {
      const codes: Array<Code | string> = [];
      const res: Gender[] = [];
      const emptyArr: AudienceData<Language | Age | Gender>[] = [];
      const baseDict = { [keyPlural]: emptyArr };
      (props.account?.audience || baseDict)[keyPlural].slice().map((x: AudienceData<Language | Age | Gender>) => {
        const obj = x[key];
        if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
          const safeCode = String(obj[code]);
          const safeJpName = typeof obj.jpName === "string" ? obj.jpName : "";
          const codeObj: Code = { code: safeCode, jpName: safeJpName };
          return useJPName ? codes.push(codeObj) : codes.push(String(obj[code]));
        }
      });
      if (!useJPName) codes.sort();
      codes.slice().map((x: Code | string) => {
        const resDict: { follower?: number; code: string } = typeof x === "string" ? { code: String(x) } : { code: String(x.jpName) || x.code || "" };
        ((props.account?.audience || baseDict)[keyPlural] || []).slice().map((y: AudienceData<Language | Age | Gender>) => {
          const obj = y[key];
          if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
            if (typeof x !== "string") {
              if (obj[code] === x.code) resDict.follower = y.weight;
            } else {
              if (obj[code] === String(x)) resDict.follower = y.weight;
            }
          }
        });
        res.push(resDict);
      });
      return res;
    };
    const mergeFollowerAndLikerGenderPerAge = (gender: string) => {
      const codes: string[] = [];
      const res: Gender[] = [];
      ((props.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((x: GenderPerAge) => {
        if (!codes.includes(x.age.code)) codes.push(x.age.code);
      });
      codes.sort();
      codes.map((x: string) => {
        const resDict: Gender = { code: x };
        ((props.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((y: GenderPerAge) => {
          if (y.age.code === x && y.gender.code === gender) resDict.follower = y.weight;
        });
        res.push(resDict);
      });
      return res.sort((x: Gender, y: Gender) => (x.code > y.code ? 1 : -1));
    };
    const getRealNumAndPercentage = (totalNum: number | void, currentRatio: number | void) => {
      if (totalNum === undefined) return "-　/　-";
      if (currentRatio === undefined) return "-　/　-";
      return Math.round(currentRatio * totalNum).toLocaleString() + "　/　" + getPercentage(currentRatio);
    };
    Chart.defaults.font.size = 10;
    const monthlyChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          min: 0,
          maxTicksLimit: 8,
          padding: 20,
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
          ticks: {
            min: 0,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          font: {},
        },
        datalabels: {
          align: "end",
          offset: 8,
          font: {
            size: 10,
            weight: 400,
          },
          formatter: function (value: ChartPlug, context: ChartPlug) {
            return getDeltaRate(context.chart.data.datasets[0].data)[context.dataIndex];
          },
          color: function (value: ChartPlug) {
            if (getDeltaRate(value.chart.data.datasets[0].data)[value.dataIndex][0] === "▼") {
              return "#FF0000";
            } else {
              return "#46D540";
            }
          },
        },
      },
    };
    const engagementChartOption = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
          },
        },
        yAxis1: {
          stacked: true,
          id: "y-axis-1",
          type: "linear",
          position: "left",
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
        yAxis2: {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            fontSize: 12,
            callback: function (value: number) {
              if (value.toFixed(0).length === 1) {
                return "      " + value.toFixed(0) + "%";
              }
              return "    " + value.toFixed(0) + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: function (val: ChartPlug) {
            return val.dataset.yAxisID === "yAxis2";
          },
          align: "end",
          offset: 8,
          color: "#9299F7",
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return value.toFixed(1) + "%";
          },
        },
      },
    };
    const genderChartOption = {
      cutoutPercentage: 65,
      legend: {
        display: true,
        position: "chartArea",
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
          align: "end",
          offset: 16,
          color: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return (value * 100).toFixed(1) + "%";
          },
        },
      },
    };
    const genderPerAgeChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            fontSize: 10,
          },
        },
        y: {
          ticks: {
            fontSize: 10,
            callback: function (value: string) {
              return value + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: 10,
            weight: "Bold",
          },
          anchor: "end",
          align: "end",
          padding: {
            bottom: -8,
          },
          formatter: function (value: number | string) {
            if (value == "") {
              return "";
            } else {
              return (Number(value) || 0).toFixed(1) + "%";
            }
          },
        },
      },
    };
    const genderHeaders: TableHeader[] = [
      {
        text: "性別",
        value: "code",
        disableSort: true,
      },
      {
        text: "登録者",
        value: "follower",
        disableSort: true,
      },
    ];
    const ageHeaders: TableHeader[] = [
      {
        text: "年齢",
        value: "code",
        disableSort: true,
      },
      {
        text: "登録者",
        value: "follower",
        disableSort: true,
      },
    ];
    const languageHeaders: TableHeader[] = [
      {
        text: "言語",
        value: "language",
        disableSort: true,
      },
      {
        text: "登録者",
        value: "weight",
        disableSort: true,
      },
    ];
    const hashtagHeaders: TableHeader[] = [
      {
        text: "人気のハッシュタグ",
        value: "hashtag",
        disableSort: true,
      },
      {
        text: "使用率",
        value: "weight",
        disableSort: true,
      },
    ];
    const fillData = () => {
      state.popularPosts = (props.account?.popularPosts || [])
        .slice()
        .sort((x: Post, y: Post) => (x.likes > y.likes ? -1 : 1))
        .slice(0, 6);
      state.recentPosts = (props.account?.recentPosts || [])
        .slice()
        .sort((x: Post, y: Post) => (x.postedAt > y.postedAt ? -1 : 1))
        .slice(0, 6);
      state.followerLanguages = (props.account?.audience?.languages || [])
        .reduce((x: AudienceData<Language>[], y: AudienceData<Language>) => {
          let element = x.find((p: AudienceData<Language>) => {
            if (p.language !== undefined && typeof p.language !== "string" && typeof p.language !== "number" && y.language !== undefined && typeof y.language !== "string" && typeof y.language !== "number") {
              return p.language.code === y.language.code;
            } else {
              return false;
            }
          });
          if (element) {
            element.weight += y.weight;
          } else {
            x.push({
              language: y.language,
              weight: y.weight,
              id: "",
            });
          }
          return x;
        }, [])
        .slice()
        .sort((x: AudienceData<Language>, y: AudienceData<Language>) => (x.weight > y.weight ? -1 : 1))
        .slice(0, 10);
      state.hashtagsForTable = (props.account?.hashtags || []).sort((x: AccountHashtag, y: AccountHashtag) => (x.weight > y.weight ? -1 : 1)).slice(0, 5);
      state.monthlyFollowerData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.followers;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyViewData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgViews;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyLikeData = {
        labels: (props.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (props.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgLikes;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.engagementData = {
        labels: (state.recentPosts || [])
          .slice()
          .reverse()
          .map((x: Post) => {
            return getJPDate(x.postedAt);
          }),
        datasets: [
          {
            type: "line",
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return ((x.likes + x.comments) / Number(props.account?.followers)) * 100;
              }),
            lineTension: 0.3,
            borderColor: "#9299F7",
            pointBackgroundColor: "#9299F7",
            fill: false,
            yAxisID: "yAxis2",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.likes;
              }),
            borderColor: "#FFA0A0",
            backgroundColor: "rgba(255, 160, 160, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.comments;
              }),
            borderColor: "#79B5DB",
            backgroundColor: "rgba(121, 181, 219, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
        ],
      };
      let genders = ((props.account?.audience || { genders: [] }).genders || []).slice();
      if (genders[0] && typeof genders[0].gender !== "string" && typeof genders[0].gender !== "number" && genders[0].gender !== undefined && genders[0].gender.code === "MALE") {
        genders = genders.reverse();
      }
      state.followerGenderData = {
        datasets: [
          {
            label: {
              display: false,
            },
            data: genders.slice().map((x: AudienceData<Gender>) => x.weight),
            backgroundColor: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          },
        ],
      };
      const followerGenderPerAge = getGenderPerAge((props.account?.audience || { genderPerAges: [] }).genderPerAges.sort((x: GenderPerAge, y: GenderPerAge) => (x.age.code > y.age.code ? 1 : -1)));
      state.followerGenderPerAgeData = {
        labels: followerGenderPerAge.label.slice(),
        datasets: [
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.female.slice(),
            borderColor: "rgba(255, 160, 160, 1)",
            backgroundColor: "rgba(255, 160, 160, 1)",
            barPercentage: 0.7,
          },
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.male.slice(),
            borderColor: "rgba(121, 181, 219, 1)",
            backgroundColor: "rgba(121, 181, 219, 1)",
            barPercentage: 0.7,
          },
        ],
      };
      state.success = true;
    };
    let account = computed(() => props.account);
    watch(account, () => fillData());
    return {
      ...toRefs(state),
      getPercentage,
      getLocaleString,
      monthlyChartOption,
      engagementChartOption,
      genderChartOption,
      genderPerAgeChartOption,
      getJPDate,
      getRealNumAndPercentage,
      mergeFollowerAndLikerData,
      mergeFollowerAndLikerGenderPerAge,
      genderHeaders,
      ageHeaders,
      languageHeaders,
      hashtagHeaders,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.white {
  background-color: white !important;
  padding-bottom: 0 !important;
}
.infrect-report {
  background-color: #f0f2f4;
  margin: 0;
  width: 1400px !important;
  text-align: left !important;
  &-main {
    display: block;
    overflow-x: hidden !important;
    overflow-y: hidden;
    width: 1200px;
    margin: 0;
    color: #606060;
  }
  & .side {
    display: block;
    width: 100px !important;
    color: rgba(0, 0, 0, 0);
    font-size: 40px;
  }
  & .flex-wrap {
    flex-wrap: wrap;
  }
  & .section-title {
    font-size: 24px !important;
    font-weight: 900 !important;
    margin-top: 60px !important;
    &-container {
      display: flex;
      & > div {
        width: 590px;
        font-size: 24px;
        font-weight: 900;
        margin-right: 20px;
        margin-top: 60px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__container {
    height: 1900px;
    padding-top: 60px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #aec1cc;
      color: white;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 30px;
      &__title {
        font-size: 24px;
        font-weight: 900;
      }
      &__page-num {
        font-size: 20px;
        font-weight: 900;
      }
    }
    &__top {
      height: 1960px;
    }
  }
  &__account-info {
    padding: 60px 0 40px;
    background-color: white;
    &__logo {
      display: flex;
      justify-content: space-between;
      &__sns-type {
        width: fit-content;
        border-radius: 20px;
        padding: 6px 20px;
        background-color: #aec1cc;
        & h3 {
          color: white;
          font-weight: 700;
        }
      }
    }
    &__name {
      display: flex;
      align-items: center;
      margin-top: 30px;
      & h2 {
        color: #606060;
        font-weight: 900;
      }
      & h5 {
        color: #606060;
      }
      & img {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border: 4px solid #aec1cc;
        margin-right: 20px;
        position: relative;
        &:before {
          content: "画像を取得中...";
          width: 80px;
          height: 80px;
          border: 4px solid #aec1cc;
          color: map-get($colors, "gray300");
          font-size: 10px;
          font-weight: 700;
          position: absolute;
          z-index: 3;
          top: -4px;
          left: -4px;
          background-color: white;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:after {
          content: "";
          background-color: white;
          z-index: 2;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &__bio {
      margin-top: 30px;
      &__title {
        font-size: 14px;
        font-weight: 900;
      }
      &__text {
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }
  &__account-value {
    background-color: white;
    border: 0;
    border-radius: 10px;
    display: flex;
    margin-top: 20px;
    width: 180px;
    height: 100px;
    min-width: 180px;
    align-items: center;
    margin-right: 20px;
    &__inner {
      margin-left: 20px;
    }
    &__title {
      font-size: 12px !important;
      margin-bottom: 10px;
      font-weight: 700;
      & > img {
        width: 14px;
        margin-right: 5px;
      }
    }
    &__content {
      font-size: 20px;
      font-weight: 700;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  &__engagement {
    width: 100%;
    background-color: white;
    border: 0;
    border-radius: 10px;
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin: 20px;
      &__wrapper {
        display: flex;
        justify-content: space-between;
      }
    }
    &__labels {
      display: flex;
      margin: 20px 20px 20px 0;
    }
    &__label {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 10px;
      font-weight: bold;
      & img {
        width: 12px;
        margin-right: 5px;
      }
    }
    &__graph {
      display: block;
      margin: auto;
      height: 200px;
      & canvas {
        padding: 10px;
        width: calc(100% - 40px) !important;
      }
    }
  }
  &__monthly-data {
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(100% / 3);
    margin-right: 20px;
    margin-top: 30px;
    height: 280px;
    &__title {
      font-size: 14px !important;
      font-weight: 900 !important;
      margin: 20px !important;
    }
    & canvas {
      padding: 10px;
      width: 340px !important;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__tags {
    display: block;
    width: calc(100% / 3 - 54px);
    height: calc(220px - 40px);
    background-color: white;
    border: 0;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    margin-right: 20px;
    &__wrapper {
      width: calc(100% / 3);
    }
    &__title {
      display: flex;
      justify-content: space-between;
      font-size: 14px !important;
      font-weight: 900 !important;
      margin-bottom: 14px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      &__tag {
        padding: 3px 10px;
        font-weight: 500;
        font-size: 12px;
        color: white;
        background: #79b5db;
        border-radius: 30px;
      }
      &__tag.mention {
        background: #9299f7;
      }
      &__weight {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  &__post {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(1160px / 3 - 20px);
    margin-right: 20px;
    margin-top: 30px;
    padding: 10px;
    &__date {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &__title {
      font-size: 14px;
      font-weight: 900;
      width: 340px;
      white-space: nowrap;
      overflow: hidden;
      margin: 5px 0 10px;
    }
    &__engagements {
      display: flex;
      margin-top: 10px;
    }
    &__engagement {
      font-size: 14px;
      font-weight: 900;
      margin-right: 20px;
      & img {
        margin-right: 5px;
      }
    }
    & .likes {
      color: #ffa0a0;
    }
    & .comments {
      color: #79b5db;
    }
    &__text {
      height: 116px;
      font-size: 13px;
      overflow-y: scroll;
      text-overflow: ellipsis;
      margin-top: 10px;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    & .thumbnail {
      width: 100%;
      height: 195px;
      & img {
        width: 100%;
        height: 195px;
        object-fit: cover;
      }
    }
  }
  &__location {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(75% / 2 - 50px);
    margin-right: 20px;
    margin-top: 30px;
    height: 240px;
    padding: 20px;
    &__title {
      font-size: 13px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__gender {
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(100% / 3 - 40px);
    margin-right: 20px;
    margin-top: 30px;
    height: 240px;
    padding: 20px;
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 30px 0 20px;
      &__labels {
        margin-right: 10px;
      }
      &__label {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        & h1 {
          font-weight: 900;
          color: #606060;
        }
        & .female {
          color: rgba(255, 160, 160, 1);
          font-weight: 900;
        }
        & .male {
          color: rgba(121, 181, 219, 1);
          font-weight: 900;
        }
      }
    }
  }
  &__age {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: 770px;
    height: 240px;
    margin-top: 30px;
    padding: 20px;
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 20px;
    }
  }
}
</style>
<style lang="scss">
.infrect-report {
  iframe {
    width: 100% !important;
    height: 195px !important;
    border-radius: 10px;
  }
}
</style>
