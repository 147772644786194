import axios from "@/store/axios";
import { transformResponse } from "@/store/axios";
import { Commit } from "vuex";

interface State {
  errorMessage: string | null;
  signInSuccess: boolean;
}

const state: State = {
  errorMessage: null,
  signInSuccess: false,
};
const signIn = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setSignInSuccess(state: State, flag: boolean) {
      state.signInSuccess = flag;
    },
  },
  actions: {
    signInInitialize({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setSignInSuccess", false);
    },
    async postSignIn({ commit }: { commit: Commit }, { email, password }: { email: string; password: string }) {
      try {
        const res = await axios.post("api/v1/token/", { email: email, password: password });
        const token = res.data.token;
        commit("users/setToken", token, { root: true });
        commit("setSignInSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default signIn;
