
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { useStore } from "vuex";
import { DetailSearchParams, HashtagRequestPrams, Influencer } from "@/types";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BasePagination from "@/components/BasePagination.vue";
import ComboBox from "@/components/ComboBox.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AdvancedSearch",
  components: {
    ComboBox,
    BasePagination,
    BaseSelectBox,
    BaseTextField,
    BaseHeader,
    BaseTable,
    Sidebar,
    BaseButton,
    Loading,
    ExclamationMark,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("influencers/detailSearchInitialize");
    interface Item {
      key: string;
      text: string;
    }
    interface Sort {
      name: string;
      value: string;
    }
    interface Direction {
      name: string;
      value: string;
    }
    const snsTypes: Item[] = [
      { key: "instagram", text: "Instagram" },
      { key: "youtube", text: "YouTube" },
      { key: "tiktok", text: "TikTok" },
      { key: "twitter", text: "Twitter" },
    ];
    const sort: Sort[] = [
      {
        name: "フォロワー数",
        value: "followers",
      },
      {
        name: "エンゲージメント率",
        value: "engagement_rate",
      },
    ];
    const directions: Direction[] = [
      {
        name: "降順 (Z → A)",
        value: "desc",
      },
      {
        name: "昇順 (A → Z)",
        value: "asc",
      },
    ];
    const initialSNSType = router.currentRoute.value.query.sns;
    const initialSNS = snsTypes[0];
    if (initialSNSType) {
      snsTypes.forEach(({ key, text }) => {
        if (initialSNSType === key) {
          initialSNS.key = key;
          initialSNS.text = text;
        }
      });
    }
    const initialHashtag = router.currentRoute.value.query.hashtag;
    const initialKeyword = router.currentRoute.value.query.keyword;
    const state = reactive({
      show: {
        influencer: false,
      },
      listChecked: false,
      detailChecked: false,
      influencers: computed(() => store.state.influencers.influencers),
      company: computed(() => store.state.companies.company),
      total: computed(() => store.state.influencers.total),
      currentPage: computed(() => store.state.influencers.currentPage),
      success: computed(() => store.state.influencers.detailSearchSuccess),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      formErrorMessage: null as null | string,
      totalPages: computed(() => store.state.influencers.totalPages),
      hashtagItems: computed(() => store.state.hashtags.hashtags),
      form: {
        snsType: initialSNS,
        minFollower: null as null | number,
        maxFollower: null as null | number,
        engagementRate: null as number | null,
        keyword: initialKeyword && typeof initialKeyword === "string" ? initialKeyword : null as null | string,
        hashtags: initialHashtag && typeof initialHashtag === "string" ? [initialHashtag,] : [] as string[],
        sort: sort[0] as Sort,
        direction: directions[0] as Direction,
      },
    });
    const getInfluencers = () => {
      const form = state.form;
      const influencers = state.influencers?.slice();
      if (form.sort?.value == "engagement_rate") {
        influencers.sort((x: Influencer, y: Influencer) => x.engagementRate > y.engagementRate ? -1 : 1);
      }
      if (form.direction.value === "asc") {
        return influencers.reverse();
      } else {
        return influencers;
      }
    };
    const validate = () => {
      const form = state.form;
      if (form.minFollower && (form.minFollower < 0 || !Number.isInteger(Number(form.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        return false;
      } else if (form.maxFollower && (form.maxFollower < 0 || !Number.isInteger(Number(form.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        return false;
      } else if (form.maxFollower && form.minFollower && Number(form?.minFollower) > Number(form?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数以上である必要があります";
        return false;
      } else if (form.engagementRate && (form.engagementRate < 0 || (Number(form.engagementRate) !== 0 && !Number(form.engagementRate)))) {
        state.formErrorMessage = "エンゲージメント率は0以上である必要があります";
        return false;
      } else {
        return true;
      }
    };
    const submitDetailSearch = (page: number) => {
      if (!validate()) return false;
      store.dispatch("influencers/detailSearchInitialize");
      state.formErrorMessage = null;
      const form = state.form;
      const params: DetailSearchParams = {
        snsType: state.form.snsType.key,
      };
      if (form.sort) params.sort = form.sort.value;
      if (form.direction) params.direction = form.direction.value;
      if (page) params.page = page;
      if (form.minFollower) params.minFollowers = form.minFollower;
      if (form.maxFollower) params.maxFollowers = form.maxFollower;
      if (form.engagementRate) params.engagementRate = form.engagementRate / 100;
      if (form.keyword) params.keyword = form.keyword;
      if (form.hashtags) params.hashtags = form.hashtags;
      store.dispatch("influencers/detailSearch", params);
    };
    const submitPages = (page: number) => {
      return submitDetailSearch(page);
    };
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    const checkNextPage = (currentPage: number, total: number) => {
      return currentPage + 1 < Math.ceil(total / 15);
    };
    const handleHashtagChange = (e: null | string) => {
      if (e) {
        const params: HashtagRequestPrams = {
          snsType: state.form.snsType.key,
          hashtag: e,
        };
        store.dispatch("hashtags/getHashtags", { params });
      }
    };
    let setDetailSearchSuccess = computed(() => state.success);
    watch(setDetailSearchSuccess, () => {
      store.dispatch("users/loadCurrentUser");
    });
    return {
      ...toRefs(state),
      snsTypes,
      sort,
      directions,
      setDetailSearchSuccess,
      getInfluencers,
      submitDetailSearch,
      closeLoadingDialog,
      checkNextPage,
      submitPages,
      handleHashtagChange,
    };
  },
});
