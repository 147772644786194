<template>
  <svg class="material-design-icon__svg" :width="size" :height="size" viewBox="0 0 18 18">
    <path
        d="M14.128,6.562A6.566,6.566,0,0,1,10.29,5.335v5.587A5.081,5.081,0,1,1,5.907,5.886V8.7A2.333,2.333,0,1,0,7.54,10.921V0H10.29a3.788,3.788,0,0,0,.058.693h0A3.819,3.819,0,0,0,12.034,3.2a3.8,3.8,0,0,0,2.094.63Z"
        transform="translate(-0.128)"
        :fill="fillColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TikTokIcon",
  props: {
    fillColor: {
      type: String,
      default: "#4c4c4c",
    },
    size: {
      type: Number,
      default: 18,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
