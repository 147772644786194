<template>
  <div class="base-container">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header display-type="SEARCH"></base-header>
      <div class="home">
        <div class="home__card ai">
          <div>
            <h2>AI検索</h2>
            <p>
              商品・サービスの概要を入力するだけで、<br />
              AIが商品・サービスにマッチした最適なインフルエンサーを検索します。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'AISearch' }">
                <base-button color="white" size="short">検索する</base-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="home__card id">
          <div>
            <h2>名前・IDで検索</h2>
            <p>インフルエンサーの名前や、アカウントのIDで該当のインフルエンサーを検索します。</p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'IDSearch' }">
                <base-button color="white" size="short">検索する</base-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="home__card advanced">
          <div>
            <h2>詳細検索</h2>
            <p>
              関連するキーワードやフォロワー数、エンゲージメント数を用いて<br />
              インフルエンサーを検索します。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'AdvancedSearch' }">
                <base-button color="white" size="short">検索する</base-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="home__card hashtag">
          <div>
            <h2>ハッシュタグ検索</h2>
            <p>
              関連するハッシュタグやハッシュタグが使われている投稿、<br />
              ハッシュタグを使っているインフルエンサーを検索することができます。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'HashtagSearch' }">
                <base-button color="white" size="short">検索する</base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { set } from "vue-gtag";

export default defineComponent({
  name: "SearchHome",
  components: {
    Sidebar,
    BaseHeader,
    BaseButton,
  },
  setup() {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      currentSearchTab: 0,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    set({ userId: state.user?.id });
    const setCurrentSearchTab = (index: number) => {
      state.currentSearchTab = index;
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.home {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
  @include mq(xs) {
    display: block;
    margin-top: 40px;
  }
  @include mq(lg) {
    display: flex;
    margin-top: 40px;
  }
  &__card {
    display: flex;
    position: relative;
    width: calc(50% - 55px);
    min-width: 480px;
    height: 240px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    @include mq(xs) {
      width: 90%;
      padding: 20px 10px;
      height: fit-content;
      min-width: 240px;
      margin: 20px auto;
    }
    @include mq(lg) {
      width: calc(50% - 55px);
      padding: 20px;
      min-width: 480px;
      height: 240px;
      margin: auto;
    }
    &.ai {
      background-image: url("../assets/img/ai-search-bg.jpg");
      background-size: cover;
    }
    &.id {
      background-image: url("../assets/img/id-search-bg.jpg");
      background-size: cover;
    }
    &.advanced {
      background-image: url("../assets/img/advanced-search-bg.jpg");
      background-size: cover;
    }
    &.hashtag {
      background-image: url("../assets/img/hashtag-search-bg.jpg");
      background-size: cover;
    }
    & h2 {
      color: white;
      font-weight: 900;
      font-size: 28px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 17px;
        margin-bottom: 12px;
      }
      @include mq(lg) {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
    & p {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 45px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 14px;
        margin-bottom: 45px;
      }
    }
    & img {
      width: 190px;
      height: auto;
      @include mq(xs) {
        width: 80px;
        margin-left: 10px;
        vertical-align: bottom;
      }
      @include mq(lg) {
        width: 190px;
        margin-left: unset;
        vertical-align: unset;
      }
    }
    & .button {
      color: map-get($colors, "blue300") !important;
    }
  }
}
</style>
