// デフォルトの型定義だとないものがあるため再定義
type Date = {
  getFullYear(): string;
  getMonth(): string;
  getDate(): string;
  getHours(): string;
  getMinutes(): string;
};

export const formatDate = (date: Date): string => {
  if (!date) {
    return "";
  }

  let format = "yyyy/MM/dd HH:mm";
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));

  return format;
};
