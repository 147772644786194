
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { PasswordResetParams } from "@/types";

export default defineComponent({
  name: "ChangePassword",
  components: { BaseButton, BaseTextField },
  setup() {
    const store = useStore();
    store.dispatch("users/changePasswordInitialize");
    const router = useRouter();
    const state = reactive({
      errorMessage: computed(() => store.state.users.errorMessage),
      changePasswordSuccess: computed(() => store.state.users.changePasswordSuccess),
      formErrorMessage: null as string | null,
      oldPassword: null as string | null,
      password1: null as string | null,
      password2: null as string | null,
    });
    const submitChangePassword = () => {
      store.dispatch("users/forgotPasswordResetInitialize");
      if (!state.oldPassword) {
        state.formErrorMessage = "現在のパスワードは必須です";
      } else if (!state.password1) {
        state.formErrorMessage = "新しいパスワードは必須です";
      } else if (!state.password2) {
        state.formErrorMessage = "新しいパスワード確認用は必須です";
      } else if (state.password1 !== state.password2) {
        state.formErrorMessage = "新しいパスワードと新しいパスワード確認用が違います";
      } else if (state.oldPassword === state.password1) {
        state.formErrorMessage = "現在のパスワードと新しいパスワードが同じです";
      } else {
        state.formErrorMessage = null;
        const params: PasswordResetParams = { oldPassword: state.oldPassword, newPassword: state.password1 };
        store.dispatch("users/changePassword", params);
      }
    };
    const cancelChangePassword = () => {
      router.push({ name: "AccountSetting" });
    };
    const resetToken = () => {
      store.dispatch("users/logOut");
    };
    return { ...toRefs(state), submitChangePassword, cancelChangePassword, resetToken };
  },
});
