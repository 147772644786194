<template>
  <div class="base-container">
    <sidebar :current-tab="4"></sidebar>
    <div class="main">
      <BaseHeader header-title="案件管理" display-type="LISTING"> </BaseHeader>
      <div class="task-detail" v-if="company?.isExpired === false">
        <div class="task-detail__title mt-20px d-flex align-items-center">
          <h1>{{ task?.title }}</h1>
          <div class="task-detail__title__edit" v-if="(task?.influencers || []).length === 0">
            <router-link v-if="task" :to="{ name: 'EditListing', params: { id: task.id } }">
              <base-button size="short" color="dark">編集</base-button>
            </router-link>
          </div>
        </div>
        <div class="task-detail__sub mt-20px d-flex">
          <div class="task-detail__sub__container d-flex mr-30px">
            <div class="task-detail__sub__container__title mr-10px">作成日:</div>
            <div class="task-detail__sub__container__text">
              <h5>{{ formatDate(task?.createdAt) }}</h5>
            </div>
          </div>
          <div class="task-detail__sub__container d-flex mr-30px">
            <div class="task-detail__sub__container__title mr-10px">作成者:</div>
            <div class="task-detail__sub__container__text d-flex align-items-center">
              <profile-icon :src="task?.createdBy?.logo" :size="24"></profile-icon>
              <h5 class="ml-10px">{{ task?.createdBy?.username }}</h5>
            </div>
          </div>
          <div class="task-detail__sub__container d-flex">
            <div class="task-detail__sub__container__title mr-10px">ステータス:</div>
            <div class="task-detail__sub__container__text d-flex align-items-center">
              <h5 v-if="(task?.influencers || []).length > 0" class="listed">リストアップ済</h5>
              <h5 v-else>リストアップ中</h5>
            </div>
          </div>
        </div>
        <div class="task-detail__description mt-20px">
          <div>
            <div class="task-detail__content__main__section__title">案件の種類</div>
            <div class="task-detail__content__main__section__text">
              <h4>{{ TaskType.findTextByKey(task?.type) }}</h4>
            </div>
          </div>
          <div class="mt-30px">
            <div class="task-detail__content__main__section__title">依頼背景</div>
            <div class="task-detail__content__main__section__text">
              <h4>{{ task?.backGround }}</h4>
            </div>
          </div>
          <div class="mt-30px">
            <div class="task-detail__content__main__section__title">案件詳細</div>
            <div class="task-detail__content__main__section__text">
              <h4>{{ task?.detail }}</h4>
            </div>
          </div>
          <div v-show="show.close">
            <div class="d-flex mt-10px justify-content-center">
              <div
                class="open-detail__button mr-10px"
                @click="
                  show.open = true;
                  show.close = false;
                "
              >
                <h4 class="mr-10px">▼</h4>
                <h4>詳細を表示</h4>
              </div>
            </div>
          </div>
          <div v-show="show.open">
            <div class="d-flex mt-10px justify-content-center">
              <div
                class="open-detail__button mr-10px"
                @click="
                  show.close = true;
                  show.open = false;
                "
              >
                <h4 class="mr-10px">▲</h4>
                <h4>閉じる</h4>
              </div>
            </div>
          </div>
          <div v-show="show.open">
            <div class="task-detail__content d-flex mt-20px">
              <div class="task-detail__content__main">
                <div class="d-flex">
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">SNSの種類</div>
                    <div class="task-detail__content__main__section__text">
                      <h4>{{ SNSType.findTextByKey(task?.snsType) }}</h4>
                    </div>
                  </div>
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">募集するインフルエンサーの数</div>
                    <div class="task-detail__content__main__section__text">
                      <h4>{{ task?.listingNum }}人</h4>
                    </div>
                  </div>
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">インフルエンサーの性別</div>
                    <div class="task-detail__content__main__section__text">
                      <h4>{{ GenderType.findTextByKey(task?.gender) }}</h4>
                    </div>
                  </div>
                </div>
                <div class="dividing-line"></div>
                <div class="d-flex">
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">インフルエンサーの年齢層</div>
                    <div class="task-detail__content__main__section__text d-flex">
                      <h4 v-for="age in task?.ages" :key="age.age" class="mr-10px">{{ age.age }}歳</h4>
                    </div>
                  </div>
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">フォロワー数</div>
                    <div class="task-detail__content__main__section__text">
                      <h4>{{ task?.minFollowers }} 〜 {{ task?.maxFollowers }}人</h4>
                    </div>
                  </div>
                </div>
                <div class="dividing-line"></div>
                <div class="task-detail__content__main__section mr-40px">
                  <div class="task-detail__content__main__section__title">インフルエンサーの属性</div>
                  <div class="task-detail__content__main__section__text">
                    <h4>{{ task?.attribute }}</h4>
                  </div>
                </div>
                <div class="dividing-line"></div>
                <div class="d-flex">
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">フォロワーの性別</div>
                    <div class="task-detail__content__main__section__text">
                      <h4>{{ GenderType.findTextByKey(task?.followerGender) }}</h4>
                    </div>
                  </div>
                  <div class="task-detail__content__main__section mr-40px">
                    <div class="task-detail__content__main__section__title">フォロワーの年齢層</div>
                    <div class="task-detail__content__main__section__text d-flex">
                      <h4 v-for="age in task?.followerAges" :key="age.age" class="mr-10px">{{ age.age }}歳</h4>
                    </div>
                  </div>
                </div>
                <div class="dividing-line"></div>
                <div class="task-detail__content__main__section mr-40px">
                  <div class="task-detail__content__main__section__title">フォロワーの属性</div>
                  <div class="task-detail__content__main__section__text">
                    <h4>{{ task?.followerAttribute }}</h4>
                  </div>
                </div>
                <div class="dividing-line"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-40px mb-10px align-items-end">
          <h3 class="mr-10px">リストアップ</h3>
          <h4>{{ (task?.influencers || []).length.toLocaleString() }}インフルエンサー</h4>
        </div>
        <base-table v-show="(task?.influencers || []).length > 0" :items="task?.influencers" :key="(task?.influencers || []).length"></base-table>
        <div class="result-none" v-show="(task?.influencers || []).length === 0">
          <h1 class="mb-30px">現在インフルエンサーを選定中です。</h1>
          <h3>インフルエンサーの選定が終わり次第、こちらにリストが表示されます。</h3>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { TaskType } from "@/enum/taskType";
import { GenderType } from "@/enum/genderType";
import { AgeGroup } from "@/enum/ageGroup";
import { SNSType } from "@/enum/snsType";
import { formatDate } from "@/plugins/formatter";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TaskDetail",
  components: { BaseHeader, Sidebar, BaseButton, BaseTable, ProfileIcon },
  props: {
    resultValue: {
      type: Number,
      default: 100,
    },
  },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    store.dispatch("tasks/loadTask", route.params.id);
    store.dispatch("tasks/loadTasks");
    const state = reactive({
      show: {
        open: false,
        close: true,
        influencer: false,
        blank: false,
      },
      task: computed(() => store.state.tasks.task),
      company: computed(() => store.state.companies.company),
      influencers: [],
    });
    return { ...toRefs(state), TaskType, GenderType, AgeGroup, SNSType, formatDate };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.task-detail {
  &__title {
    &__edit {
      margin-left: auto;
    }
  }
  &__sub {
    line-height: 40px;
    flex-wrap: wrap;
    &__container {
      &__title {
        font-size: 14px;
        color: map-get($colors, "blue400");
        font-weight: 700;
      }
      &__text {
        & img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        & h5 {
          word-break: break-all;
        }
        & .listed {
          color: map-get($colors, "accent");
        }
      }
    }
  }
  &__description {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
  }
  &__content {
    width: 100%;
    &__main {
      width: 100%;
      height: fit-content;
      border-radius: 10px;
      &__section {
        width: fit-content;
        border-radius: 10px;
        &__title {
          position: relative;
          font-size: 16px;
          color: map-get($colors, "blue400");
          font-weight: 700;
          margin-left: 10px;
          margin-bottom: 10px;
          &:after {
            position: absolute;
            top: 5px;
            left: -10px;
            content: "";
            width: 5px;
            height: 18px;
            background-color: map-get($colors, "blue400");
          }
        }
        &__text {
          & h4 {
            word-break: break-all;
          }
        }
      }
      & .d-flex {
        & .task-detail__content__main__section {
          width: fit-content;
        }
      }
    }
  }
  & .open-detail {
    &__button {
      width: fit-content;
      height: 36px;
      background: white;
      border-radius: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & h4 {
        color: map-get($colors, "primary");
      }
    }
    &__text {
      display: flex;
      align-items: center;
      & h4 {
        color: map-get($colors, "primary");
        font-weight: 700;
      }
    }
  }
  & .result-none {
    width: 100%;
    background: white;
    padding: 120px 0;
    border-radius: 10px;
    height: fit-content;
    text-align: center;
    align-items: center;
    & h1,
    h3 {
      color: map-get($colors, "primary");
    }
  }
  & .dividing-line {
    width: 80%;
    height: 2px;
    margin: 24px 0;
    background: transparent linear-gradient(270deg, #ffffff 100%, #eff2f6 0%) 0 0;
  }
}
</style>
