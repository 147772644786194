<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent ({
  name: "InvoiceDetail"
})
</script>

<style lang="scss" scoped>

</style>