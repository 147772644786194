<template>
  <div class="sidebar">
    <div class="sidebar__inner">
      <div class="mb-60px">
        <router-link :to="{ name: 'Home' }">
          <img class="sidebar__logo" src="@/assets/img/logo-white.svg" alt="INFRECT" />
          <img class="sidebar__logo-short d-flex" src="@/assets/img/logo-short.svg" alt="INFRECT" />
        </router-link>
      </div>
      <div class="sidebar__items">
        <div v-for="tab in tabs" :key="tab.index" :class="['sidebar__item', currentTab === tab.index ? 'sidebar__item__selected' : null]" @mouseover="hoverIndex = tab.index" @mouseleave="hoverIndex = null">
          <div class="d-flex align-items-center sidebar__item__container">
            <router-link :to="{ name: tab.link }">
              <home-icon class="sidebar__item__logo" v-show="tab.index === 0" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <router-link :to="{ name: tab.link }">
              <account-icon class="sidebar__item__logo" v-show="tab.index === 1" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <router-link :to="{ name: tab.link }">
              <Order-icon class="sidebar__item__logo" v-show="tab.index === 2" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <router-link :to="{ name: tab.link }">
              <Meter-icon class="sidebar__item__logo" v-show="tab.index === 3" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <router-link :to="{ name: tab.link }">
              <Order-manage-icon class="sidebar__item__logo" v-show="tab.index === 4" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <router-link :to="{ name: tab.link }">
              <Account-list-icon class="sidebar__item__logo" v-show="tab.index === 5" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            </router-link>
            <Account-edit-icon class="sidebar__item__logo" v-show="tab.index === 6" :rounded="currentTab === tab.index || hoverIndex === tab.index" :fill-color="currentTab === tab.index || hoverIndex === tab.index ? '#79B5DB' : 'white'" />
            <router-link :to="{ name: tab.link }">
              <div class="sidebar__item__text">{{ tab.text }}</div>
            </router-link>
          </div>
          <div class="sidebar__sub-items" v-show="tab.subItems">
            <div class="sidebar__sub-item" v-for="subItem in tab.subItems" :key="subItem.index">
              <router-link :to="{ name: subItem.link, query: subItem.query }" v-if="subItem.query">
                <div class="sidebar__sub-item__text">{{ subItem.text }}</div>
              </router-link>
              <router-link :to="{ name: subItem.link }" v-else>
                <div class="sidebar__sub-item__text">{{ subItem.text }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HomeIcon from "@/components/icon/HomeIcon.vue";
import AccountIcon from "@/components/icon/AccountIcon.vue";
import OrderIcon from "@/components/icon/OrderIcon.vue";
import MeterIcon from "@/components/icon/MeterIcon.vue";
import OrderManageIcon from "@/components/icon/OrderManageIcon.vue";
import AccountListIcon from "@/components/icon/AccountListIcon.vue";
import AccountEditIcon from "@/components/icon/AccountEditIcon.vue";
import { defineComponent, PropType, reactive, toRefs } from "vue";
import { TabItem } from "@/types";

const defaultTabItem: TabItem[] = [
  {
    index: 0,
    text: "ホーム",
    link: "Home",
  },
  {
    index: 1,
    text: "インフルエンサーを探す",
    link: "SearchHome",
    subItems: [
      {
        index: 0,
        link: "IDSearch",
        text: "名前・IDで検索",
      },
      {
        index: 1,
        link: "AdvancedSearch",
        text: "詳細検索",
      },
      {
        index: 2,
        link: "HashtagSearch",
        text: "ハッシュタグ検索",
      },
      {
        index: 3,
        link: "AISearch",
        text: "AI検索",
      },
    ],
  },
  {
    index: 2,
    text: "ビジネスクリエーション",
    link: "Listing",
    subItems: [
      {
        index: 0,
        link: "Listing",
        query: { type: 0 },
        text: "インタビュー",
      },
      {
        index: 1,
        link: "Listing",
        query: { type: 1 },
        text: "コラボレーション",
      },
      {
        index: 2,
        link: "Listing",
        query: { type: 2 },
        text: "アンバサダー・PR投稿",
      },
      {
        index: 3,
        link: "Listing",
        query: { type: 3 },
        text: "その他のお仕事",
      },
    ],
  },
  {
    index: 3,
    text: "効果を予測する",
    link: "AdPrediction",
  },
  {
    index: 4,
    text: "案件を管理する",
    link: "TaskManage",
  },
  {
    index: 5,
    text: "インフルエンサーリスト",
    link: "InfluencerList",
  },
];

export default defineComponent({
  name: "Sidebar",
  components: { HomeIcon, AccountIcon, OrderIcon, MeterIcon, OrderManageIcon, AccountListIcon, AccountEditIcon },
  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array as PropType<TabItem[]>,
      default: defaultTabItem,
    },
  },
  setup() {
    const state = reactive({
      hoverIndex: null as number | null,
    });
    return { ...toRefs(state) };
  },
});
</script>
<style lang="scss" scoped>
@import "src/assets/styles/main";
@keyframes show {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: visible;
    visibility: collapse;
  }
  99% {
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: visible;
    visibility: collapse;
  }
  100% {
    opacity: 1;
    width: fit-content;
    height: fit-content;
    visibility: visible;
  }
}
.sidebar {
  width: 80px;
  height: calc(100vh - 48px);
  overflow-y: scroll;
  position: fixed;
  z-index: 10;
  background: map-get($bg-colors, "sidebar");
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  @include mq(xs) {
    display: none;
  }
  @include mq(lg) {
    display: block;
  }
  &:hover {
    width: 280px;
    transition: 0.3s ease-in-out;
    & .sidebar__logo {
      visibility: visible;
      height: fit-content;
      margin-top: 20px;
      margin-left: 10px;
    }
    & .sidebar__logo-short {
      visibility: collapse;
      animation-name: fade;
      animation-duration: 0.3s;
      display: none;
      position: absolute;
    }
    & .sidebar__item__text {
      display: block;
      animation-name: show;
      animation-duration: 0.3s;
      text-decoration: none;
    }
    & .sidebar__sub-items {
      display: block;
      animation-name: show;
      animation-duration: 0.3s;
    }
    & .sidebar__item {
      &__selected {
        & .sidebar__sub-items {
          display: block;
          animation-name: show;
          animation-duration: 0.3s;
        }
      }
    }
  }
  &__inner {
    padding: 0 16px;
  }
  &__logo {
    visibility: collapse;
    height: 0;
    overflow: visible;
    margin: 0;
    &:hover {
      opacity: 0.6;
      transition: 0.3s ease-in-out;
    }
    &-short {
      visibility: visible;
      margin: 0 auto;
    }
  }
  &__items {
    width: 100%;
  }
  &__item {
    width: 100%;
    margin: 5px 0;
    &:hover {
      & .sidebar__item__text {
        font-weight: 900;
      }
    }
    &__text {
      display: none;
      color: map-get($font-colors, "white");
      font-size: 13px;
      font-weight: 500;
      margin-left: 14px;
    }
    &__selected {
      & .sidebar__item__text {
        font-weight: 900;
      }
    }
  }
  &__sub-items {
    display: none;
    width: 100%;
    position: relative;
    margin-top: 5px;
    &::after {
      position: absolute;
      content: "";
      width: 2px;
      height: calc(100% - 20px);
      border-left: solid 2px white;
      left: 23px;
      top: 10px;
    }
  }
  &__sub-item {
    width: calc(100% - 70px);
    margin-left: 70px;
    padding: 7px 0;
    &__text {
      color: map-get($font-colors, "white");
      font-size: 12px;
      font-weight: 700;
      &:hover {
        font-weight: 900;
      }
    }
  }
}
</style>
<style lang="scss">
.sidebar {
  & a:-webkit-any-link {
    text-decoration: none;
  }
  & a {
    text-decoration: none;
  }
}
</style>
