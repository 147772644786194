
import {computed, defineComponent} from "vue";
export default defineComponent({
  name: "NotificationIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 21,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    newsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        "--height": props.height,
        "--width": props.width,
      };
    });
    const getNewsCount = (newsCount: number) => {
      return newsCount > 99 ? "99+" : String(newsCount);
    };
    return { styles, getNewsCount };
  },
});
