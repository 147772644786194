
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import InfluencerListTable from "@/components/InfluencerListTable.vue";
import InfluencerListChildrenTable from "@/components/InfluencerListChildrenTable.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { InfluencerList, CreateInfluencerListParams, Influencer, AddInfluencerListParams } from "@/types";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import { AddInfluencerParams } from "@/types/models/influencers";
import BaseTextField from "@/components/BaseTextField.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";

export default defineComponent({
  name: "InfluencerListChildren",
  components: {
    BaseHeader,
    Sidebar,
    BaseButton,
    InfluencerListTable,
    InfluencerListChildrenTable,
    BaseCheckbox,
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    BaseTextField,
    CheckMark,
    ExclamationMark,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("influencers/getCompanyInfluencers");
    let influencerListId = Number(route.params.id);
    store.dispatch("influencers/getInfluencerList", influencerListId);
    const state = reactive({
      show: {
        influencer: false,
        list: false,
        folder: false,
        errorDialog: false,
        create: false,
        delete: false,
      },
      id: {
        type: String,
        default: "checkbox",
      },
      count: 1,
      allChecked: false,
      checked: {} as any,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers?.influencerList),
      childInfluencerList: computed(() => store.state.influencers?.childInfluencerList),
      companyInfluencers: computed(() => store.state.influencers?.companyInfluencers),
      childList: computed(() => store.state.influencers?.influencerList?.children),
      createInfluencerListSuccess: computed(() => store.state.influencers.createInfluencerListSuccess),
      addInfluencerListSuccess: computed(() => store.state.influencers?.addInfluencerListSuccess),
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      listName: null as string | null,
      selectedInfluencers: [] as Influencer[],
      folderName: null as string | null,
      influencerListId: null as number | null,
    });
    state.influencerListId = influencerListId;
    let itemRefs = [] as any[];
    const setItemRefs = (el: any) => {
      itemRefs.push(el);
    };
    let allChecked = computed(() => state.allChecked);
    watch(allChecked, (val: boolean) => {
      state.count++;
      // header のチェックボックスが選択されたら全選択、外れたら全部選択を外す
      state.companyInfluencers.slice().map((item: any, index: number) => {
        if (state.checked[item.id] !== val && itemRefs[index].children[0]?.children[0]) itemRefs[index].children[0].children[0].click();
      });
    });
    const openList = () => {
      state.show.list = true;
      state.selectedInfluencers = [];
    };
    const addInfluencerList = () => {
      state.companyInfluencers.map((item: Influencer) => {
        if (state.checked[item.id]) state.selectedInfluencers.push(item);
      });
      const influencersParams = [] as AddInfluencerParams[];
      state.selectedInfluencers.slice().map((x: Influencer) => {
        influencersParams.push({ id: x.id });
        state.checked[x.id] = false;
      });
      const params: AddInfluencerListParams = {
        influencerList: Number(state.childInfluencerList?.id),
        influencers: influencersParams,
      };
      store.dispatch("influencers/addInfluencerList", params);
      state.show.list = false;
    };
    const reloadInfluencerList = () => {
      store.dispatch("influencers/getInfluencerList", influencerListId);
      state.show.delete = true;
    };
    const createInfluencerList = () => {
      state.formErrorMessage = null;
      if (!state.folderName) {
        state.formErrorMessage = "フォルダ名は必須です";
        state.show.errorDialog = true;
      } else {
        const params: CreateInfluencerListParams = {
          company: state.user?.companyId,
          derivedFrom: influencerListId,
          name: state?.folderName,
        };
        store.dispatch("influencers/createInfluencerList", params);
        store.dispatch("influencers/getInfluencerList", influencerListId);
      }
    };
    let createInfluencerListSuccess = computed(() => state.createInfluencerListSuccess);
    watch(createInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.folder = false;
      if (val) state.show.create = true;
      if (val) store.dispatch("influencers/getInfluencerList", influencerListId);
    });
    const filterInfluencer = (influencer: Influencer) => {
      if (Array.isArray(state.childInfluencerList?.influencers) && influencer.id) {
        const influencers = state.childInfluencerList.influencers.slice().map((x: Influencer) => {
          return x.id;
        });
        return !influencers.includes(influencer.id);
      }
      return false;
    };
    onBeforeRouteUpdate((to) => {
      const toId = Number(to?.params?.id);
      influencerListId = toId;
      store.dispatch("influencers/getInfluencerList", influencerListId);
    });
    const moveFolder = (id: number | null, isRoot = false) => {
      if (isRoot) {
        router.push({ name: "InfluencerList" });
      } else {
        router.push({ name: "InfluencerListChildren", params: { id: id } });
      }
    };
    return {
      ...toRefs(state),
      addInfluencerList,
      setItemRefs,
      influencerListId,
      reloadInfluencerList,
      createInfluencerList,
      filterInfluencer,
      moveFolder,
      openList,
    };
  },
});
