<template>
  <div class="edit-icon">
    <svg
        class="material-design-icon__svg"
        :width="width"
        :height="height"
        viewBox="0 0 124 124"
    >
      <g transform="translate(2 2)">
        <path d="M60,0a60,60,0,1,0,60,60A60.066,60.066,0,0,0,60,0Zm0,0" fill="none" :stroke="fillColor" stroke-width="4"/>
        <path d="M10.151-27.905,8.745-48.955q-.4-6.152-.4-8.833a8.007,8.007,0,0,1,1.912-5.691,6.568,6.568,0,0,1,5.032-2.043c2.52,0,3.642.622,4.817,2.043a8.221,8.221,0,0,1,1.561,5.691c0,1.934-.27,6.841-.475,8.833l-1.71,21.05a17.674,17.674,0,0,1-1.431,6.086,3.331,3.331,0,0,1-3.29,2,3.324,3.324,0,0,1-3.3-2A19.9,19.9,0,0,1,10.151-27.905ZM15.029,1.011a6.915,6.915,0,0,1-4.68-1.736,6.088,6.088,0,0,1-2-4.856,6.313,6.313,0,0,1,1.912-4.636,6.365,6.365,0,0,1,4.68-1.912,6.512,6.512,0,0,1,4.724,1.912,6.242,6.242,0,0,1,1.956,4.636A6.135,6.135,0,0,1,19.644-.747,6.728,6.728,0,0,1,15.029,1.011Z" transform="translate(45 93)" :fill="fillColor"/>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExclamationMark",
  props: {
    fillColor: {
      type: String,
      default: "#ee7382"
    },
    width: {
      type: Number,
      default: 124
    },
    height: {
      type: Number,
      default: 124
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

</style>