<template>
  <div class="member-table">
    <div class="member-table__header d-flex">
      <div class="name">
        <h4>ユーザー名</h4>
      </div>
      <div class="d-flex base-table__right">
        <div class="mail">
          <h4>メールアドレス</h4>
        </div>
        <div class="authority d-flex align-items-center">
          <h4>権限</h4>
          <tooltip class="align-self-center">権限とは、そのユーザーがチーム内のコンテンツのアクセスできる権限のことを指します。</tooltip>
        </div>
        <div class="status">
          <h4>ステータス</h4>
        </div>
        <div class="blank"></div>
      </div>
    </div>
    <div class="member-table__body d-flex align-items-center" v-for="item in items" :key="item.id">
      <div class="name d-flex align-items-center">
        <div class="mr-10px">
          <profile-icon :src="item.user?.logo" />
        </div>
        <h4>{{ item.user?.username }}</h4>
      </div>
      <div class="mail">
        <h4>{{ item.user?.email }}</h4>
      </div>
      <div class="authority">
        <h4>{{ MemberRole.findTextByKey(item.role) }}</h4>
      </div>
      <div class="status">
        <h4>{{ item.user?.id === user?.id ? "あなた" : "認証済ユーザー" }}</h4>
      </div>
      <div class="member-table__body__icons d-flex">
        <edit-icon class="mr-20px" :member="item" v-if="hasCompanyAdminRole && members?.length > 1 && item.user?.id !== user?.id"></edit-icon>
        <trash-icon :member="item" @confirm-delete="handleConfirmDelete(item.id)" v-if="hasCompanyAdminRole && members?.length > 1 && item.user?.id !== user?.id" trash-type="member" :id="`member-${item.id}`" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { useStore } from "vuex";
import EditIcon from "@/components/icon/EditIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import { MemberRole } from "@/enum/memberRole";

export default defineComponent({
  name: "MemberTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hasCompanyAdminRole: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tooltip,
    ProfileIcon,
    EditIcon,
    TrashIcon,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        deleteSuccess: false,
      },
      user: computed(() => store.state.users.user),
      members: computed(() => store.state.members.member),
    });
    const sortByKey = (arr: [], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const handleConfirmDelete = (memberId: number) => {
      store.dispatch("members/deleteMember", memberId);
    };
    store.dispatch("members/loadMember");
    return { ...toRefs(state), sortByKey, MemberRole, handleConfirmDelete };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.member-table {
  width: 100%;
  &__right {
    margin-left: auto;
    margin-right: 0;
    align-items: center;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
  }
  &__body {
    height: fit-content;
    padding: 20px 20px;
    background-color: white;
    word-break: break-all;
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    &__icons {
      margin-left: auto;
    }
  }
  & .blank {
    width: 92px;
  }
  & .name {
    width: 280px;
    & img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
  & .mail {
    width: 280px;
    word-break: break-all;
  }
  & .authority {
    width: 120px;
  }
  & .status {
    width: 100px;
  }
}
</style>
