
import { defineComponent, PropType, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { MemberRole } from "@/enum/memberRole";
import { CompanyMember } from "@/types";
import { useStore } from "vuex";
import BaseSelectBox from "@/components/BaseSelectBox.vue";

export default defineComponent({
  name: "EditIcon",
  components: {
    BaseSelectBox,
    BaseButton,
    ProfileIcon,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object as PropType<CompanyMember>,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      MemberRole,
      formErrorMessage: null as string | null,
      memberId: (props.member || { id: 0 }).id,
      memberRole: MemberRole.getObject((props.member || { role: 0 }).role),
    });

    const updateMemberRole = () => {
      state.formErrorMessage = null;
      store.dispatch("members/updateMemberRole", { role: state.memberRole, memberId: state.memberId });
    };
    return { ...toRefs(state), updateMemberRole };
  },
});
