import axios from "@/store/axios";
import type {
  User,
  UserCreateParams,
  UserUpdateParams,
  UserUpdateInAccountSettingParams,
  ForgotPasswordParams,
  ForgotPasswordResetParams,
  PasswordResetParams,
} from "@/types";
import router from "@/router";
import { Commit, Dispatch } from "vuex";

interface State {
  user: User | null;
  errorMessage: string | null;
  createUserSuccess: boolean;
  updateUserSuccess: boolean;
  forgotPasswordSuccess: boolean;
  forgotPasswordResetSuccess: boolean;
  changePasswordSuccess: boolean;
  token: string;
  isLogin: boolean;
}
const state: State = {
  user: null,
  errorMessage: null,
  createUserSuccess: false,
  updateUserSuccess: false,
  forgotPasswordSuccess: false,
  forgotPasswordResetSuccess: false,
  changePasswordSuccess: false,
  token: "",
  isLogin: false,
};
const users = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setUser(state: State, user: User) {
      state.user = user;
    },
    setToken(state: State, token: string) {
      state.token = token;
      state.isLogin = true;
      localStorage.token = token;
    },
    removeToken(state: State) {
      state.token = "";
      state.isLogin = false;
      localStorage.removeItem("token");
      router.push({ name: "SignIn" });
    },
    deleteToken(state: State) {
      state.token = "";
      localStorage.removeItem("token");
    },
    setCreateUserSuccess(state: State, flag: boolean) {
      state.createUserSuccess = flag;
    },
    setUpdateUserSuccess(state: State, flag: boolean) {
      state.updateUserSuccess = flag;
    },
    setForgotPasswordSuccess(state: State, flag: boolean) {
      state.forgotPasswordSuccess = flag;
    },
    setForgotPasswordResetSuccess(state: State, flag: boolean) {
      state.forgotPasswordResetSuccess = flag;
    },
    setChangePasswordSuccess(state: State, flag: boolean) {
      state.changePasswordSuccess = flag;
    },
  },
  actions: {
    createUserInitialize({ commit }: { commit: Commit }, flag: boolean) {
      commit("setCreateUserSuccess", flag);
      commit("setErrorMessage", null);
    },
    async createUser({ commit }: { commit: Commit }, params: UserCreateParams) {
      commit("setCreateUserSuccess", false);
      try {
        const res = await axios.post("api/v1/users/", params);
        const user = res.data;
        commit("setUser", user);
        commit("setCreateUserSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    forgotPasswordInitialize({ commit }: { commit: Commit }) {
      commit("setForgotPasswordSuccess", false);
      commit("setErrorMessage", null);
    },
    async forgotPassword({ commit }: { commit: Commit }, params: ForgotPasswordParams) {
      commit("setErrorMessage", null);
      commit("setForgotPasswordSuccess", false);
      try {
        await axios.post("api/v1/users/reset-password/", params);
        commit("setForgotPasswordSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    forgotPasswordResetInitialize({ commit }: { commit: Commit }) {
      commit("setForgotPasswordResetSuccess", false);
      commit("setErrorMessage", null);
    },
    async forgotPasswordReset({ commit }: { commit: Commit }, params: ForgotPasswordResetParams) {
      commit("setErrorMessage", null);
      commit("setForgotPasswordResetSuccess", false);
      try {
        await axios.post("api/v1/users/reset-password/confirm/", params);
        commit("setForgotPasswordResetSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    changePasswordInitialize({ commit }: { commit: Commit }) {
      commit("setChangePasswordSuccess", false);
      commit("setErrorMessage", null);
    },
    async changePassword({ commit }: { commit: Commit }, params: PasswordResetParams) {
      commit("setErrorMessage", null);
      commit("setChangePasswordSuccess", false);
      try {
        await axios.post("api/v1/users/change-password/", params);
        commit("setChangePasswordSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    setToken({ commit }: { commit: Commit }, token: string) {
      commit("setToken", token);
    },
    signOut({ commit }: { commit: Commit }) {
      commit("removeToken");
    },
    async loadToken({ commit }: { commit: Commit }) {
      if (localStorage.token) {
        commit("setErrorMessage", null);
        try {
          if (!state.user) {
            const res = await axios.get("api/v1/users/me/");
            const user = res.data;
            commit("setUser", user);
          }
          commit("setToken", localStorage.token);
        } catch (err) {
          commit("setErrorMessage", err.response.data);
        }
      }
    },
    async loadCurrentUser({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      commit("setErrorMessage", null);
      try {
        const res = await axios.get("api/v1/users/me/");
        const user = res.data;
        commit("setUser", res.data);
        if (user.companyId) dispatch("companies/loadCompany", user.companyId, { root: true });
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateUser({ commit }: { commit: Commit }, { params, userId }: { params: UserUpdateParams; userId: string }) {
      commit("setErrorMessage", null);
      commit("setUpdateUserSuccess", false);
      const formParams: any = new FormData();
      if (params.logo) formParams.append("logo", params.logo);
      if (params.username) formParams.append("username", params.username);
      try {
        const res = await axios.patch(`api/v1/users/${userId}/`, formParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [(data: any) => data],
        });
        const user = res.data;
        commit("setUser", user);
        commit("setUpdateUserSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateUserReadOption({ commit }: { commit: Commit }, { params, userId }: { params: UserUpdateParams; userId: string }) {
      commit("setErrorMessage", null);
      commit("setUpdateUserSuccess", false);
      try {
        const res = await axios.patch(`api/v1/users/${userId}/`, params);
        const user = res.data;
        commit("setUser", user);
        commit("setUpdateUserSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateUserInAccountSetting({ commit }: { commit: Commit }, { params, userId }: { params: UserUpdateInAccountSettingParams; userId: string }) {
      commit("setErrorMessage", null);
      commit("setUpdateUserSuccess", false);
      const formParams: any = new FormData();
      if (params.logo) formParams.append("logo", params.logo);
      formParams.append("username", params.username);
      try {
        const res = await axios.patch(`api/v1/users/${userId}/`, formParams, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [(data: any) => data],
        });
        const user = res.data;
        commit("setUser", user);
        commit("setUpdateUserSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    logOut({ commit }: { commit: Commit }) {
      commit("removeToken");
    },
    async deleteUser({ commit }: { commit: Commit }, { userId }: { userId: string }) {
      commit("setErrorMessage", null);
      try {
        await axios.delete(`api/v1/users/${userId}/`);
        commit("deleteToken");
        router.push({ name: "DeleteUser" });
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};
export default users;
