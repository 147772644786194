
import { defineComponent, reactive, computed, toRefs } from "vue";
import { formatDate } from "@/plugins/formatter";
import TwitterReport from "@/components/TwitterReport.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TwitterInfluencerReport",
  components: { TwitterReport },
  setup() {
    const store = useStore();
    const route = useRoute();
    const influencerId = route.params.id;
    store.dispatch("influencers/getInfluencerDetail", influencerId);
    const state = reactive({
      account: computed(() => store.state.influencers.influencer),
    });
    return {
      ...toRefs(state),
      formatDate,
    };
  },
});
