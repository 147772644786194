<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
    <g>
      <g transform="translate(8.684 14.383)">
        <path d="M20.422,10.2V1.549H7.512V17.686H13.45a.775.775,0,0,1,0,1.549H6.738a.777.777,0,0,1-.775-.775V.775A.777.777,0,0,1,6.738,0H21.2a.777.777,0,0,1,.775.775V10.2a.775.775,0,1,1-1.549,0Zm3.989,1.885a.776.776,0,0,0-1.1.065l-4.247,4.725-2.13-2.053a.776.776,0,0,0-1.071,1.123l2.711,2.608a.761.761,0,0,0,.542.219h.026a.774.774,0,0,0,.542-.258l4.776-5.319A.779.779,0,0,0,24.411,12.083ZM17.581,4.518H10.61a.775.775,0,0,0,0,1.549h6.971a.775.775,0,0,0,0-1.549Zm.775,5.035a.777.777,0,0,0-.775-.775H10.61a.775.775,0,1,0,0,1.549h6.971A.777.777,0,0,0,18.356,9.553ZM10.61,12.909a.775.775,0,1,0,0,1.549h2.066a.775.775,0,0,0,0-1.549Z" :fill="fillColor"/>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "OrderIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff"
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

</script>




