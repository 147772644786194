<template>
  <div class="toggle-switch">
    <input type="checkbox" :id="id" v-model="checked" @change="handleCheck" :value="checked" />
    <label :for="id" :class="[color, size]">
      <span :class="[checked ? 'on-message' : 'off-message']">
        <slot name="on" v-if="checked" />
        <slot name="off" v-else />
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "ToggleButton",
  props: {
    id: {
      type: String,
      default: "toggle-button-id",
    },
    modelValue: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "success",
    },
    size: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      checked: props.reverse ? !props.modelValue : props.modelValue,
    });
    const handleCheck = () => {
      context.emit("update:modelValue", props.reverse ? !state.checked : state.checked);
      context.emit("toggle");
    };
    return {
      ...toRefs(state),
      handleCheck,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
input {
  display: none;
}
label {
  width: 72px;
  height: 40px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.4s;
  box-sizing: border-box;
  cursor: pointer;
  & .on-message {
    position: absolute;
    color: white;
    height: 100%;
    width: 32px;
    left: 11px;
    font-size: 11px;
    font-weight: 500;
    display: grid;
    text-align: left;
    align-items: center;
    transition-duration: 0.4s;
    overflow: hidden;
  }
  & .off-message {
    position: absolute;
    color: white;
    height: 100%;
    left: 29px;
    width: 32px;
    font-size: 11px;
    font-weight: 500;
    display: grid;
    text-align: right;
    align-items: center;
    transition-duration: 0.4s;
    overflow: hidden;
  }
  &.small {
    width: 54px;
    height: 30px;
    &::after {
      top: 3px;
      left: 3px;
      width: 24px;
      height: 24px;
    }
    & .on-message {
      width: 20px;
      left: 6px;
      font-size: 8px;
    }
    & .off-message {
      width: 20px;
      left: 28px;
      font-size: 8px;
    }
  }
  &.large {
    width: 108px;
    height: 60px;
    &::after {
      top: 6px;
      left: 6px;
      width: 48px;
      height: 48px;
    }
    & .on-message {
      width: 44px;
      left: 14px;
      font-size: 14px;
    }
    & .off-message {
      width: 44px;
      left: 50px;
      font-size: 14px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    left: 4px;
    top: 4px;
    z-index: 2;
    background: #fff;
    transition: 0.4s;
  }
}

input:checked {
  + label {
    background-color: #4BD865;
    &.primary {
      background-color: map-get($btn-colors, "primary");
    }
    &.accent {
      background-color: map-get($btn-colors, "accent");
    }
    &.success {
      background-color: map-get($btn-colors, "success");
    }
    &.dark {
      background-color: map-get($btn-colors, "dark");
    }
    &.disabled {
      background-color: map-get($btn-colors, "disabled");
    }
    &.blue {
      background-color: map-get($btn-colors, "blue");
    }
    &.green {
      background-color: map-get($btn-colors, "green");
    }
    &::after {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      left: 36px;
    }
    &.small::after {
      left: 28px;
    }
    &.large::after {
      left: 52px;
    }
  }
}

.toggle-switch {
  position: relative;
  width: 75px;
  height: 42px;
  margin: auto;
}
</style>
