<template>
  <div class="result__dialog__container" v-if="(updateInfluencerListSuccess || errorMessage) && show.update">
    <div class="result__dialog" v-if="updateInfluencerListSuccess">
      <div class="title">フォルダ名を変更しました</div>
      <div class="description"><check-mark></check-mark></div>
      <div class="description"><base-button size="short" color="dark" @click="closeSuccess"> OK </base-button></div>
    </div>
  </div>
  <div class="result__dialog__container" v-if="show.errorDialog">
    <div class="result__dialog">
      <div class="title">変更に失敗しました</div>
      <div class="description"><exclamation-mark></exclamation-mark></div>
      <div class="description">
        <h4>{{ formErrorMessage }}</h4>
      </div>
      <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false">戻る</base-button></div>
    </div>
  </div>
  <div class="influencer-list-table">
    <div class="influencer-list-table__header d-flex">
      <div class="influencer-list-table__header__sort d-flex align-items-center">
        <div class="influencer-list-table__header__sort d-flex align-items-center">
          <h4 class="mr-10px">並び替え</h4>
          <select-box class="influencer-list-table__header__sort__selectbox" :width="180" :height="32" v-model="sortingItem" :options="sortItems" return-object></select-box>
        </div>
      </div>
      <div class="d-flex influencer-list-table__right">
        <div class="influencer-list-table__header__post-date d-flex align-items-center">
          <h4>作成日</h4>
        </div>
        <div class="influencer-list-table__header__blank"></div>
      </div>
    </div>
    <div class="influencer-list-table__body d-flex align-items-center" v-for="item in sortedItems" :key="item.key">
      <div class="influencer-list-table__body__thumbnail mr-20px">
        <folder-icon></folder-icon>
      </div>
      <div class="influencer-list-table__body__influencer-name">
        <h4>
          <a @click="moveToChildFolder(item.id)">
            {{ item.name }}
          </a>
        </h4>
      </div>
      <div class="d-flex influencer-list-table__right">
        <div class="influencer-list-table__body__post-date">
          <h4>{{ formatDate(item.createdAt) }}</h4>
        </div>
        <div class="influencer-list-table__body__icons">
          <edit-folder-icon class="mr-20px" v-model="folderName" @confirmEdit="updateInfluencerList(item.id, folderName)"></edit-folder-icon>
          <trash-icon :id="String(item.id)" v-model="deleteChecked" @confirmDelete="deleteInfluencerList(item.id)"></trash-icon>
        </div>
      </div>
      <div class="influencer-list-table__body__border"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import { UpdateInfluencerListParams } from "@/types";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import FolderIcon from "@/components/icon/FolderIcon.vue";
import EditFolderIcon from "@/components/icon/EditFolderIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import { useStore } from "vuex";
import { formatDate } from "@/plugins/formatter";
import router from "@/router";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "InfluencerListChildrenTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EditFolderIcon,
    FolderIcon,
    TrashIcon,
    SelectBox,
    CheckMark,
    ExclamationMark,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        delete: false,
        errorDialog: false,
        update: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "name",
          text: "フォルダ名",
        },
        {
          key: "createdAt",
          text: "作成日",
        },
      ],
      sortedItems: props.items.slice(),
      listChecked: false,
      detailChecked: false,
      deleteChecked: false,
      company: computed(() => store.state.companies.company),
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      childList: computed(() => store.state.influencers?.influencerList?.children),
      influencerListName: computed(() => store.state.influencers.influencerList?.name),
      influencerListId: computed(() => store.state.influencers.influencerList?.id),
      deleteInfluencerListSuccess: computed(() => store.state.influencers.deleteInfluencerListSuccess),
      updateInfluencerListSuccess: computed(() => store.state.influencers.updateInfluencerListSuccess),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      influencerId: null as string | null,
      folderName: null as string | null,
      formErrorMessage: null as string | null,
    });
    const moveToChildFolder = (id: number) => {
      router.push({ name: "InfluencerListChildren", params: { id: id } });
      context.emit("moveFolder");
    };
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as boolean[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const deleteInfluencerList = (influenserListId: number) => {
      store.dispatch("influencers/deleteInfluencerList", influenserListId);
    };
    let deleteInfluencerListSuccess = computed(() => state.deleteInfluencerListSuccess);
    watch(deleteInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.trash = false;
      if (val) state.show.delete = true;
      if (val) context.emit("folderDelete");
    });
    const updateInfluencerList = (influencerFolderId: number, influencerFolderName: string) => {
      if (!influencerFolderName) {
        state.show.errorDialog = true;
        state.formErrorMessage = "フォルダ名は必須です";
      } else {
        const params: UpdateInfluencerListParams = {
          name: influencerFolderName,
        };
        store.dispatch("influencers/updateInfluencerList", { params: params, influencerFolderId: influencerFolderId });
      }
    };
    let updateInfluencerListSuccess = computed(() => state.updateInfluencerListSuccess);
    watch(updateInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.update = true;
      if (val) store.dispatch("influencers/getCompaniesList");
    });
    const closeSuccess = () => {
      state.show.update = false;
      window.location.reload();
    };
    return { ...toRefs(state), sortByKey, formatDate, deleteInfluencerList, updateInfluencerList, moveToChildFolder, closeSuccess };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.influencer-list-table {
  width: 100%;
  &__right {
    margin-left: auto;
    margin-right: 0;
    align-items: center;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
    &__sort {
      width: 370px;
    }
    &__gender {
      width: 56px;
      text-align: center;
    }
    &__follower {
      width: 100px;
      text-align: right;
      margin: 0 20px;
    }
    &__engagement {
      width: 112px;
      text-align: right;
      margin: 0 20px;
    }
    &__post-date {
      width: 120px;
      margin-right: 40px;
    }
    &__blank {
      width: 92px;
    }
  }
  &__body {
    height: fit-content;
    padding: 20px 20px;
    background-color: white;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: calc(100% - 40px);
      bottom: 0;
      left: 20px;
      height: 2px;
      background-color: #f1f1f1;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      &:after {
        display: none;
      }
    }
    &__thumbnail {
      & img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }
    }
    &__sns {
      margin: 0 10px;
    }
    &__influencer-name {
      width: 280px;
      & h4 {
        font-weight: 500;
        cursor: pointer;
        & a {
          text-decoration: none;
          color: map-get($colors, "blue400");
        }
        & :hover {
          color: map-get($colors, "blue200");
        }
      }
    }
    &__gender {
      width: 56px;
      text-align: center;
    }
    &__follower {
      width: 100px;
      text-align: right;
      margin: 0 20px;
    }
    &__engagement {
      width: 112px;
      text-align: right;
      margin: 0 20px;
    }
    &__post-date {
      width: 120px;
      margin-right: 64px;
    }
    &__icons {
      display: flex;
    }
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.result__dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
