import axios from "@/store/axios";
import { Commit } from "vuex";
import { Inquiry, InquiryError } from "@/types";

interface State {
  errorMessage: InquiryError | null;
  success: boolean;
}

const state: State = {
  errorMessage: null,
  success: false,
};
const inquiries = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: InquiryError | null) {
      state.errorMessage = errorMessage;
    },
    setCreateInquirySuccess(state: State, flag: boolean) {
      state.success = flag;
    },
  },
  actions: {
    async createInquiry({ commit }: { commit: Commit }, params: Inquiry) {
      commit("setCreateInquirySuccess", false);
      try {
        await axios.post("api/v1/inquiries/", params);
        commit("setErrorMessage", null);
        commit("setCreateInquirySuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default inquiries;
