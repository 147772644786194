<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
    <path d="M19.733,20.467V23H2V20.467S2,15.4,10.867,15.4s8.867,5.067,8.867,5.067M15.3,8.433a4.433,4.433,0,1,0-4.433,4.433A4.433,4.433,0,0,0,15.3,8.433" transform="translate(13 10)" :fill="fillColor" />
  </svg>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent ({
    name: "AccountIcon",
    props: {
      fillColor: {
        type: String,
        default: "#fff"
      },
      size: {
        type: Number,
        default: 48
      },
      rounded: {
        type: Boolean,
        default: false
      }
    }
})

</script>
