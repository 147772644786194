
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultipleToggle from "@/components/MultipleToggle.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";

export default defineComponent({
  name: "AISearchSample",
  components: {
    MultipleToggle,
    BaseTextArea,
    BaseButton,
    Sidebar,
    BaseHeader,
    InstagramIcon,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
  },
  setup() {
    const state = reactive({
      selectedKeywordIndex: 0,
      selectedKeywordSNSIndex: 0,
      selectedHashtagIndex: 0,
      selectedHashtagSNSIndex: 0,
      selectedSampleIndex: 0,
      samples: [
          "サンプル1: INFRECT",
          "サンプル2: ソロキャンパー用テント",
          "サンプル3: 森の図書館",
      ],
      data: [
        {
          text: "INFRECTとはAIとインフルエンサーで御社の商品・サービスが売れ続ける仕組みを内製化できるツールです。\nInstagram・YouTube・TikTok・Twitterで活躍するインフルエンサーの検索・分析からお仕事の共創までまるごとサポート！\nインフルエンサーの検索・分析・効果検証・レポート作成などの数日かかる業務の工数をINFRECTを活用することで大幅に削減することが出来ます。\n【AIによるインフルエンサーの検索】\nニーズに合わせたインフルエンサーを、「簡単」にかつ「高精度」でスピーディーに探し出すことが出来ます。\n【AIが抽出する分析レポート】\nSNSや、従来のインフルエンサー分析ツールでは得られない情報を取得できるので、高度な分析の情報をもとに適切なインフルエンサーをビジネスに登用出来ます。\n【AIによるいいね分析機能】\n弊社が開発した最新鋭のAIが、実在するインフルエンサーの情報や、プロモーションでお使いいただく画像、投稿文章、投稿時間を用いてInstagramで獲得できるいいね数を予測いたします。\n【インフルエンサーとのビジネスをサポート】\nインフルエンサーのリストアップからアサイン交渉まで弊社が代行することもできます！自社で工数をかけずにインフルエンサーとのお仕事を実現できます。",
          personas: [
            "SNSを積極的に利用するビジネスマン・ビジネスウーマン",
            "自社の商品・サービスを広く知ってもらいたいマーケター",
            "インフルエンサーマーケティングを取り入れたい企業経営者",
            "インフルエンサーマーケティングに興味のある学生やフリーランス",
            "インフルエンサーマーケティングをより効率的に行いたいマーケティング担当者"
          ],
          keywords: [
            "ビジネス",
            "マーケティング",
            "起業",
            "セルフィー",
            "コスメ",
            "旅行",
            "モデル",
            "ファッション",
            "グルメ",
            "カフェ",
            "音楽",
            "映画",
            "スポーツ",
            "アウトドア",
            "ペット",
            "アート",
            "おしゃれ",
            "自然",
            "くらし",
            "健康"
          ],
          hashtags: [
            "いいね返し",
            "いいねした人で気になった人フォロー",
            "フォローしてくれた人全員フォローする",
            "フォローミー",
            "フォロー返し",
            "l4l",
            "like4like",
            "フォトジェニック",
            "お洒落さんと繋がりたい",
            "カフェ巡り",
            "美味しいもの",
            "旅行",
            "インスタ映え",
            "今日のコーデ",
            "コスメ好きな人と繋がりたい",
            "おしゃれさんと繋がりたい",
            "美容好きな人と繋がりたい",
            "コスメレポ",
            "メイク好きな人と繋がりたい"
          ],
        },
        {
          text: "「ソロキャンパー3000」は、1人用のテントで、ソロキャンプに特化した設計となっています。広々とした空間を提供し、ポリエステル素材を使用しているため、耐久性が高く防水性も優れています。\n" +
              "セットアップが簡単で、1人でも10分程度で組み立てることができます。夜間でも明るい内部照明が備わっており、暗闇でも快適に過ごせます。また、通気口によって蒸れた空気を排出し、快適な空気の流れを確保しています。\n" +
              "外部には2つのポケットがあり、貴重品や小物の収納に便利です。コンパクトに収納することができ、持ち運びにも便利です。\n" +
              "定員は1人で、サイズは210cm（長さ） x 100cm（幅） x 100cm（高さ）、重量は2kg以下と軽量です。強風や悪天候時にはご使用にならないでください。組み立ての際には、説明書をよく読んで正しく設置してください。ポケットに貴重品を入れる場合は、慎重に取り扱ってください。\n" +
              "「ソロキャンパー3000」は、1人でのキャンプを快適に過ごすための優れた選択肢です。",
          personas: [
            "アウトドアが好きで、特にソロキャンプを楽しむ人",
            "手軽にテントを組み立てたい人",
            "防水性が高く、耐久性に優れたテントを探している人",
            "小物や貴重品を収納したい人",
            "コンパクトに収納して持ち運びたい人",
            "内部照明があるテントを探している人",
            "十分な通気性があるテントを探している人"
          ],
          keywords: [
            "アウトドア",
            "キャンプ",
            "テント",
            "防水",
            "耐久",
            "ポケット",
            "内部照明",
            "通気性",
            "コンパクト"
          ],
          hashtags: [
            "ソロキャンプ",
            "キャンプグッズ",
            "アウトドアギア",
            "テント",
            "ソロキャンプグッズ",
            "キャンプ好きな人と繋がりたい",
            "アウトドアライフ",
            "キャンプライフ",
            "キャンプ女子",
            "キャンプ初心者"
          ]
        },
        {
          text: "「森の図書館」とは、森の中にある図書館であり、自然と本の世界が融合した環境が特徴的です。\n" +
              "\n" +
              "まず、森の中にあるため、周囲には木々や草花が豊富にあり、季節ごとに美しい景色が楽しめます。図書館自体も、木造の建物であり、外観は森に調和したデザインとなっています。\n" +
              "\n" +
              "入館すると、まず目につくのは、大きな窓から差し込む自然光です。天井も高く、開放感があり、森の中にいるかのような感覚を味わえます。書棚には、自然や環境問題に関する書籍や、自然の中で過ごすためのアウトドアガイドなどが豊富に揃っています。\n" +
              "\n" +
              "また、図書館には、森をテーマとしたイベントや講演会が頻繁に開催され、専門家や環境保護団体などから、自然や環境問題についての知識を深めることができます。\n" +
              "\n" +
              "さらに、図書館の周囲には、トレッキングコースやキャンプ場、バードウォッチングポイントなどが整備されており、自然と本を組み合わせた充実した体験ができる場所となっています。\n" +
              "\n" +
              "森の図書館は、自然と本を融合させ、人々により身近な存在として環境問題への関心を高めることができる、素晴らしい場所です。",
          personas: [
            "自然愛好家",
            "本好き",
            "アウトドア体験が好き",
            "環境問題に関心がある",
            "トレッキングやバードウォッチングが好き",
            "緑豊かな場所が好き",
            "開放的な場所が好き",
            "知的好奇心が旺盛",
            "イベントや講演会に参加することが好き",
            "新しい体験を楽しみたい人"
          ],
          keywords: [
            "旅行",
            "コスメ",
            "ファッション",
            "フード",
            "ダイエット",
            "ヨガ",
            "スポーツ",
            "映画",
            "音楽",
            "アート",
            "読書",
            "ペット",
            "ハンドメイド",
            "グルメ",
            "カフェ巡り",
            "美容",
            "健康",
            "おしゃれ",
            "リラックス",
            "恋愛",
            "結婚",
            "出産",
            "子育て",
            "引っ越し",
            "仕事",
            "実家暮らし",
            "一人暮らし",
            "ペアルック",
            "ファミリールック",
            "サステイナブル",
            "エコロジー",
            "リユース",
            "ビーガン",
            "ヴィーガン",
            "動物愛護",
            "フェアトレード"
          ],
          hashtags: [
            "自然好きな人と繋がりたい",
            "アウトドアライフ",
            "緑の中で",
            "自然との一体感",
            "環境問題に考える",
            "トレッキング",
            "バードウォッチング",
            "森の図書館",
            "本と自然の融合",
            "新しい体験",
            "イベント参加",
            "知的好奇心",
            "読書好き",
            "緑豊かな場所",
            "開放的な場所"
          ],
        },
      ],
      SNSTypes: [
        "Instagram",
        "YouTube",
        "TikTok",
        "Twitter",
      ],
      HashtagSNSTypes: [
        "Instagram",
        "Twitter",
      ]
    });
    const resetIndex = () => {
      state.selectedHashtagIndex = 0;
      state.selectedKeywordIndex = 0;
      state.selectedKeywordSNSIndex = 0;
      state.selectedHashtagSNSIndex = 0;
    }
    return {
      ...toRefs(state),
      resetIndex,
    };
  },
});
