
import { defineComponent } from "vue";

export default defineComponent ({
  name: "FlowCard",
  props: {
    flowNumber: {
      type: Number,
      default: 1,
    },
    request: {
      type: Boolean,
      default: false,
    },
  },
});
