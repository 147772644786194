
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { GenderType } from "@/enum/genderType";
import { SNSType } from "@/enum/snsType";
import { TaskType } from "@/enum/taskType";
import { AgeGroup } from "@/enum/ageGroup";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import { useStore } from "vuex";
import { CreateTaskParams } from "@/types";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";
import FlowCard from "@/components/FlowCard.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";

export default defineComponent({
  name: "Listing",
  components: {
    BaseSelectBox,
    FlowCard,
    BaseTextArea,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    ExclamationMark,
    CheckMark,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    let taskType = route.query.type ? TaskType.getObject(Number(route.query.type)) : null;
    type ItemType = {
      key: string;
      text: string;
    };
    type ItemNumberType = {
      key: number;
      text: string;
    };
    const state = reactive({
      show: {
        first: true,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        dialog: false,
        createTask: false,
        errorDialog: false,
      },
      company: computed(() => store.state.companies.company),
      user: computed(() => store.state.users.user),
      errorMessage: computed(() => store.state.tasks.errorMessage),
      formErrorMessage: null as string | null,
      createTaskSuccess: computed(() => store.state.tasks.createTaskSuccess),
      checked: false,
      hashtags: [] as string[],
      attributes: null as null | string,
      taskType: null as null | TaskType,
      snsType: null as null | ItemNumberType,
      genderType: null as null | ItemNumberType,
      listingNum: null as null | number,
      ages: [] as ItemType[],
      minFollower: null as number | null,
      maxFollower: null as number | null,
      followerGenderType: null as null | ItemNumberType,
      followerAges: [] as ItemType[],
      followerAttributes: null as null | string,
      followerHashtags: [] as string[],
      taskTitle: null as null | string,
      taskBackground: null as null | string,
      taskDetail: null as null | string,
    });
    state.taskType = taskType;
    if (route.query.type) {
      state.show.first = false;
      state.show.second = true;
      state.show.third = false;
      state.show.fourth = false;
      state.show.fifth = false;
    } else {
      state.show.first = true;
      state.show.second = false;
      state.show.third = false;
      state.show.fourth = false;
      state.show.fifth = false;
    }
    const selectPurpose = (taskType: number) => {
      state.taskType = TaskType.getObject(taskType);
      router.push({ name: "Listing", query: { type: taskType } });
    };
    const back2First = () => {
      router.push({ name: "Listing" });
    };
    const validateThird = () => {
      state.formErrorMessage = null;
      if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else {
        state.show.third = false;
        state.show.fourth = true;
      }
    };
    const validateFourth = () => {
      state.formErrorMessage = null;
      if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else if (state.minFollower && (state.minFollower < 0 || !Number.isInteger(Number(state.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && (state.maxFollower < 0 || !Number.isInteger(Number(state.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && state.minFollower && Number(state?.minFollower) > Number(state?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数より大きい数である必要があります";
        state.show.errorDialog = true;
      } else {
        state.show.fourth = false;
        state.show.fifth = true;
      }
    };
    const createTask = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else {
        store.dispatch("tasks/createTaskInitialize");
        if (state.checked) {
          const params = { isReadListing: state.checked };
          store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
        }
        const params: CreateTaskParams = {
          company: state.company?.id,
          type: state.taskType.key,
          snsType: state.snsType.key,
          title: state.taskTitle,
          backGround: state.taskBackground,
          detail: state.taskDetail,
          attribute: state.attributes,
          followerAttribute: state.followerAttributes,
          listingNum: state.listingNum,
          ages: state.ages.slice().map((x: ItemType) => ({ age: x.text })),
          followerAges: state.followerAges.slice().map((x: ItemType) => ({ age: x.text })),
          hashtags: state.hashtags.slice().map((x: string) => ({ tag: x })),
          followerHashtags: state.followerHashtags.slice().map((x: string) => ({ tag: x })),
        };
        if (state.minFollower) {
          params.minFollowers = state.minFollower;
        } else {
          params.minFollowers = null;
        }
        if (state.maxFollower) {
          params.maxFollowers = state.maxFollower;
        } else {
          params.maxFollowers = null;
        }
        if (state.genderType?.key) params["gender"] = state.genderType?.key;
        if (state.followerGenderType?.key) params["followerGender"] = state.followerGenderType?.key;
        store.dispatch("tasks/createTask", params);
        state.show.dialog = false;
        state.show.createTask = true;
      }
      state.show.dialog = false;
    };
    let createTaskSuccess = computed(() => state.createTaskSuccess);
    watch(createTaskSuccess, (val: boolean) => {
      store.dispatch("users/loadCurrentUser");
    });
    const openListDialog = () => {
      state.formErrorMessage = null;
      if (state.user?.isReadListing) {
        state.show.errorDialog = false;
        createTask();
      } else {
        state.show.dialog = true;
      }
    };
    const createTaskDone = () => {
      router.push({ name: "TaskManage" });
    };
    onBeforeRouteUpdate((to) => {
      const toTaskType = to.query.type ? TaskType.getObject(Number(to.query.type)) : null;
      state.taskType = Object.assign({}, toTaskType);
      if (toTaskType) {
        state.show.first = false;
        state.show.second = true;
        state.show.third = false;
        state.show.fourth = false;
        state.show.fifth = false;
      } else {
        state.show.first = true;
        state.show.second = false;
        state.show.third = false;
        state.show.fourth = false;
        state.show.fifth = false;
      }
    });
    return {
      ...toRefs(state),
      GenderType,
      SNSType,
      TaskType,
      AgeGroup,
      selectPurpose,
      back2First,
      validateThird,
      validateFourth,
      createTask,
      openListDialog,
      createTaskDone,
    };
  },
});
