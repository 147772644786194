<template>
  <div class="base-container">
    <sidebar :current-tab="4"></sidebar>
    <div class="main">
      <BaseHeader header-title="案件管理" display-type="LISTING"></BaseHeader>
      <div v-if="company?.isExpired === false">
        <div class="task-manage">
          <div class="task-manage__tabs">
            <div :class="['task-manage__tab', show.all ? 'selected' : '']" @click="toggleTab('all')">
              <h4>全て</h4>
            </div>
            <div :class="['task-manage__tab', show.listed ? 'selected' : '']" @click="toggleTab('listed')">
              <h4>リストアップ済</h4>
            </div>
            <div :class="['task-manage__tab', show.listing ? 'selected' : '']" @click="toggleTab('listing')">
              <h4>リストアップ中</h4>
            </div>
          </div>
        </div>
        <task-table class="mt-20px" v-if="filteredTasks" :items="filteredTasks"></task-table>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import TaskTable from "@/components/TaskTable.vue";
import { useStore } from "vuex";
import { Task } from "@/types";

export default defineComponent({
  name: "TaskManage",
  components: { TaskTable, BaseHeader, Sidebar, BaseButton },
  props: {
    tasks: {
      type: Array,
      default: () => [
        {
          taskTitle: "女性目線でコンビニで買いたいスイーツに関するインタビュー調査",
          taskCategory: "インタビュー",
          listValue: 10,
          status: "リストアップ中",
        },
        {
          taskTitle: "女性向けキャンプアパレル・キャンプ用品に関する商品開発共創",
          taskCategory: "コラボレーション",
          listValue: 100,
          status: "リストアップ済",
        },
        {
          taskTitle: "男児向けおもちゃに関するYoutube動画作成・広告案件",
          taskCategory: "PR広告",
          listValue: 10,
          status: "リストアップ中",
        },
      ],
    },
  },
  setup() {
    const store = useStore();
    store.dispatch("tasks/loadTasks");
    const state = reactive({
      show: {
        all: true,
        listed: false,
        listing: false,
      },
      tasks: computed(() => store.state.tasks.tasks),
      company: computed(() => store.state.companies.company),
      member: computed(() => store.state.members.member),
      loadTaskSuccess: computed(() => store.state.tasks.loadTaskSuccess),
      createId: null as string | null,
      filteredTasks: null as Task[] | null,
    });
    let loadTaskSuccess = computed(() => state.loadTaskSuccess);
    watch(loadTaskSuccess, () => {
      state.filteredTasks = state.tasks;
    });
    const toggleTab = (name: string) => {
      if (name === "all") {
        state.show.all = true;
        state.show.listed = false;
        state.show.listing = false;
        state.filteredTasks = state.tasks;
      } else if (name === "listed") {
        state.show.all = false;
        state.show.listed = true;
        state.show.listing = false;
        const filteredTasks: Task[] = [];
        state.tasks.slice().map((x: Task) => {
          if (x.influencers?.length >= 1) filteredTasks.push(x);
        });
        state.filteredTasks = filteredTasks;
      } else if (name === "listing") {
        state.show.all = false;
        state.show.listed = false;
        state.show.listing = true;
        const filteredTasks: Task[] = [];
        state.tasks.slice().map((x: Task) => {
          if (x.influencers?.length <= 0) filteredTasks.push(x);
        });
        state.filteredTasks = filteredTasks;
      }
    };
    return { ...toRefs(state), toggleTab };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.no-function-container {
  width: 80%;
  padding: 80px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin-top: 60px;
  & h1 {
    color: map-get($colors, "primary");
    margin-bottom: 40px;
  }
}
.task-manage {
  &__tabs {
    display: flex;
    margin-top: 20px;
    background: map-get($btn-colors, "disabled");
    width: 480px;
    height: fit-content;
    border-radius: 24px;
    @include mq(xs) {
      width: calc(100vw - 20px);
    }
    @include mq(lg) {
      width: 480px;
    }
  }
  &__tab {
    width: calc(100% / 3);
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    & h4 {
      font-weight: 700;
      color: map-get($colors, "gray200");
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  & .selected {
    background: map-get($colors, "blue400");
    border-radius: 24px;
    & h4 {
      color: white !important;
    }
  }
}
.main {
  @include mq(xs) {
    width: 100%;
    overflow-x: scroll;
  }
  @include mq(lg) {
    width: calc(100% - 104px);
    overflow-x: unset;
  }
}
</style>
