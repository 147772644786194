
import { computed, defineComponent, reactive, toRefs } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import { useStore } from "vuex";
import EditIcon from "@/components/icon/EditIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import { MemberRole } from "@/enum/memberRole";

export default defineComponent({
  name: "MemberTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hasCompanyAdminRole: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tooltip,
    ProfileIcon,
    EditIcon,
    TrashIcon,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        deleteSuccess: false,
      },
      user: computed(() => store.state.users.user),
      members: computed(() => store.state.members.member),
    });
    const sortByKey = (arr: [], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const handleConfirmDelete = (memberId: number) => {
      store.dispatch("members/deleteMember", memberId);
    };
    store.dispatch("members/loadMember");
    return { ...toRefs(state), sortByKey, MemberRole, handleConfirmDelete };
  },
});
