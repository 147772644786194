
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import AddListIcon from "@/components/icon/AddListIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseButton from "@/components/BaseButton.vue";
import { AddInfluencerListParams } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BaseTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    TwitterIcon,
    SelectBox,
    AddListIcon,
    OpenDetailIcon,
    ExclamationMark,
    BaseButton,
    CheckMark,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      show: {
        add: true,
        trash: false,
        addInfluencer: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "influencerName",
          text: "名前",
        },
        {
          key: "genderType",
          text: "性別",
        },
        {
          key: "followerValue",
          text: "フォロワー",
        },
        {
          key: "engagementValue",
          text: "エンゲージメント",
        },
        {
          key: "latestPost",
          text: "最終投稿日",
        },
      ],
      sortedItems: ((props || { items: [] }).items || []).slice(),
      listChecked: false,
      detailChecked: false,
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      addInfluencerSuccess: computed(() => store.state.influencers.addInfluencerListSuccess),
      errorMessage: computed(() => store.state.influencers.influencerListErrorMessage),
      selectedFolder: null as number | null,
      count: 1,
    });
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as boolean[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const addInfluencerList = (id: string) => {
      store.dispatch("influencers/initailizeAddInfluencerList");
      if (state.selectedFolder) {
        const params: AddInfluencerListParams = {
          influencerList: state.selectedFolder,
          influencers: [{ id: id }],
        };
        store.dispatch("influencers/addInfluencerList", params);
      }
      state.show.addInfluencer = true;
      store.dispatch("influencers/getCompaniesList");
    };
    let addInfluencerListSuccess = computed(() => state.addInfluencerSuccess);
    watch(addInfluencerListSuccess, (val) => {
      if (val) store.dispatch("influencers/getCompaniesList");
      state.count++;
    });
    return { ...toRefs(state), sortByKey, addInfluencerList, viewInfluencerDetail };
  },
});
