<template>
  <div class="influencer-detail">
    <div class="influencer-detail__sns-type">
      <a :href="account?.url" target="_blank" rel="noopener noreferrer">
        <twitter-icon class="mr-10px" :width="24" :height="24"></twitter-icon>
        <h3>Twitter</h3>
      </a>
    </div>
    <div class="influencer-detail__profile">
      <div class="m-0 p-0 influencer-detail__profile__left">
        <div class="m-0 p-0 d-flex align-items-center">
          <div class="influencer-detail__profile__thumbnail__tiktok" :style="`background-image: url(${account?.picture});`"></div>
          <div class="mr-20px">
            <h2>
              <a :href="account?.url" target="_blank" rel="noopener noreferrer">{{ account?.fullname }}</a>
            </h2>
            <h5>{{ account?.username }}</h5>
          </div>
        </div>
      </div>
      <div class="influencer-detail__profile__right">
        <div class="influencer-detail__profile__value">
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/follower-icon.svg" alt="フォロワー数" />
            <div class="ml-10px">
              <h5>フォロワー数</h5>
              <h2>{{ getLocaleString((account || { followers: 0 }).followers) }}</h2>
            </div>
          </div>
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/engagement-icon.svg" alt="エンゲージメント率" />
            <div class="ml-10px">
              <h5>エンゲージメント率</h5>
              <h2>{{ getPercentage((account || { engagementRate: 0 }).engagementRate) }}</h2>
            </div>
          </div>
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/tweet-icon.svg" alt="総ツイート数" />
            <div class="ml-10px">
              <h5>総ツイート数</h5>
              <h2>{{ getLocaleString((account || { postsCount: 0 }).postsCount) }}</h2>
            </div>
          </div>
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/liker-icon.svg" alt="平均いいね" />
            <div class="ml-10px">
              <h5>平均いいね数</h5>
              <h2>{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgLikes: 0 }).avgLikes) }}</h2>
            </div>
          </div>
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/comment-icon.svg" alt="コメント数" />
            <div class="ml-10px">
              <h5>平均コメント数</h5>
              <h2>{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgComments: 0 }).avgComments) }}</h2>
            </div>
          </div>
          <div class="influencer-detail__profile__value__container">
            <img src="@/assets/img/retweet-icon.svg" alt="平均日リツイート数" />
            <div class="ml-10px">
              <h5>平均被リツイート数</h5>
              <h2>{{ getLocaleString((account?.statHistories.slice(-1)[0] || { avgRetweets: 0 }).avgRetweets) }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="influencer-detail__bio">
      <div class="influencer-detail__bio__title">自己紹介文</div>
      <p :class="hideDescription ? 'hide_description' : ''" v-if="account?.description">{{ account?.description }}</p>
      <p class="text-align-center" v-else>データなし</p>
    </div>
    <div class="influencer-detail__tab__container">
      <div
        :class="['influencer-detail__tab', show.first ? 'selected' : '']"
        @click="
          show.first = true;
          show.second = false;
          show.third = false;
        "
      >
        <h4>投稿<br>情報</h4>
      </div>
      <div
        :class="['influencer-detail__tab', show.second ? 'selected' : '']"
        @click="
          show.second = true;
          show.first = false;
          show.third = false;
        "
      >
        <h4>詳細<br>情報</h4>
      </div>
      <div
        :class="['influencer-detail__tab', show.third ? 'selected' : '']"
        @click="
          show.third = true;
          show.first = false;
          show.second = false;
        "
      >
        <h4>オーディエンス<br>情報</h4>
      </div>
    </div>
    <div class="influencer-detail__section__outer" v-show="show.first">
      <div v-if="popularPosts?.length">
        <div class="influencer-detail__section__title">人気の投稿</div>
        <div class="influencer-detail__section__wrapper post">
          <div class="post-card post-card__twitter" v-for="item in popularPosts" :key="item.id">
            <div class="post-img-container">
              <Suspense v-if="item.embedHtml">
                <template #default>
                  <iframe :src="item.url" :srcdoc="item.embedHtml" frameborder="0" style="height: auto;!important;"></iframe>
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <div v-if="recentPosts?.length">
        <div class="influencer-detail__section__title">最近の投稿</div>
        <div class="influencer-detail__section__wrapper post">
          <div class="post-card post-card__twitter" v-for="item in recentPosts" :key="item.id">
            <div class="post-img-container">
              <Suspense v-if="item.embedHtml">
                <template #default>
                  <iframe :src="item.url" :srcdoc="item.embedHtml" frameborder="0"></iframe>
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <div v-if="sponsoredPosts?.length">
        <div class="influencer-detail__section__title">PR投稿</div>
        <div class="influencer-detail__section__wrapper post">
          <div class="post-card post-card__twitter" v-for="item in sponsoredPosts" :key="item.id">
            <div class="post-img-container">
              <Suspense v-if="item.embedHtml">
                <template #default>
                  <iframe :src="item.url" :srcdoc="item.embedHtml" frameborder="0"></iframe>
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="influencer-detail__section__outer" v-show="show.second">
      <div class="influencer-detail__section__wrapper" v-if="account?.statHistories?.length">
        <div class="influencer-detail__monthly-data">
          <div class="influencer-detail__monthly-data__title">月ごとのフォロワー数推移</div>
          <line-chart :chart-data="monthlyFollowerData" :options="monthlyChartOption" :style="'width: 90%; height: 100%; min-height: 200px;'"></line-chart>
        </div>
        <div class="influencer-detail__monthly-data">
          <div class="influencer-detail__monthly-data__title">月ごとの再生数推移</div>
          <line-chart :chart-id="'monthly-view-graph'" :chart-data="monthlyViewData" :options="monthlyChartOption" :style="'width: 90%; height: 100%; min-height: 200px;'"></line-chart>
        </div>
        <div class="influencer-detail__monthly-data">
          <div class="influencer-detail__monthly-data__title">月ごとの平均いいね数推移</div>
          <line-chart :chart-id="'monthly-like-graph'" :chart-data="monthlyLikeData" :options="monthlyChartOption" :style="'width: 90%; height: 100%; min-height: 200px;'"></line-chart>
        </div>
      </div>
      <div class="influencer-detail__section__wrapper">
        <div class="influencer-detail__engagement" v-if="account?.recentPosts?.length">
          <div class="influencer-detail__engagement__title__wrapper">
            <div class="influencer-detail__engagement__title">直近のエンゲージメント</div>
            <div class="influencer-detail__engagement__labels">
              <div class="influencer-detail__engagement__label"><img src="../assets/img/liker-icon.svg" alt="いいね数" />いいね数</div>
              <div class="influencer-detail__engagement__label"><img src="../assets/img/comment-icon.svg" alt="コメント数" />コメント数</div>
              <div class="influencer-detail__engagement__label"><img src="../assets/img/retweet-icon.svg" alt="リツイート数" />リツイート数</div>
              <div class="influencer-detail__engagement__label"><img src="../assets/img/engagement-icon.svg" alt="エンゲージメント率" />エンゲージメント率</div>
            </div>
          </div>
          <div class="influencer-detail__engagement__graph">
            <bar-chart :chart-id="'latest-engagements'" :chart-data="engagementData" :options="engagementChartOption" :style="'width: calc(100% - 20px); height: 100%; min-height: 280px;'"></bar-chart>
          </div>
        </div>
        <div class="influencer-detail__tags__wrapper" v-if="account?.hashtags?.length">
          <div class="influencer-detail__tags">
            <div class="influencer-detail__tags__title">
              <div>よく使うハッシュタグ</div>
              <div>使用率</div>
            </div>
            <div class="influencer-detail__tags__content" v-for="hashtag in hashtagsForTable" :key="hashtag">
              <div class="influencer-detail__tags__content__tag">#{{ hashtag?.hashtag?.tag }}</div>
              <div class="influencer-detail__tags__content__weight">{{ (hashtag?.weight * 100).toFixed(1) + "%" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="influencer-detail__section__outer" v-show="show.third">
      <div v-if="account?.audience">
        <div class="influencer-detail__section__title">フォロワーのデータ</div>
        <div class="influencer-detail__location" v-if="account?.audience?.countries?.length">
          <div class="influencer-detail__location__title">フォロワーの居住地（国）</div>
          <bar-chart :chart-id="'follower-location-country'" :chart-data="followerLocationCountryData" :options="locationChartOption" :style="'width: 100%; height: calc(100% - 40px);'"></bar-chart>
        </div>
        <div class="influencer-detail__section__wrapper">
          <div class="influencer-detail__gender" v-if="account?.audience?.genders?.length">
            <div class="influencer-detail__gender__title">フォロワーの男女比</div>
            <div class="influencer-detail__gender__content">
              <doughnut-chart :chart-id="'follower-gender'" :chart-data="followerGenderData" :options="genderChartOption" :style="'margin-left: 0px; width: 100%; height: 180px;'"></doughnut-chart>
              <div class="influencer-detail__gender__content__labels">
                <div class="influencer-detail__gender__content__label" v-for="age in account?.audience?.genders[0]?.gender.code === 'MALE' ? account?.audience?.genders?.reverse() : account?.audience?.genders" :key="age">
                  <h4 :class="[age?.gender?.jpName === '女性' ? 'female' : 'male']">{{ age?.gender?.jpName }}</h4>
                  <h1 class="">{{ (age?.weight * 100).toFixed(1) + "%" }}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="influencer-detail__age" v-if="account?.audience?.ages?.length && followerGenderPerAgeData?.labels">
            <div class="influencer-detail__age__title">フォロワーの年齢層と男女比</div>
            <bar-chart :chart-id="'follower-gender-age'" :chart-data="followerGenderPerAgeData" :options="genderPerAgeChartOption" :style="'width: 100%; height: calc(100% - 40px);'"></bar-chart>
          </div>
        </div>
        <div class="influencer-detail__section__title__container">
          <div v-if="account?.audience?.genders.length">性別</div>
          <div v-if="account?.audience?.ages.length">年齢層</div>
        </div>
        <div class="influencer-detail__section__wrapper">
          <div class="influencer-detail__section__title__sm" v-if="account?.audience?.genders.length">性別</div>
          <basic-table v-if="account?.audience?.genders.length" :headers="genderHeaders" :items="mergeFollowerAndLikerData('genders', 'gender', 'code', true)">
            <template #follower="{ follower }">
              <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
            </template>
          </basic-table>
          <div class="influencer-detail__section__title__sm" v-if="account?.audience?.ages?.length">年齢層</div>
          <basic-table v-if="account?.audience?.ages.length" :headers="ageHeaders" :items="mergeFollowerAndLikerData('ages', 'age')">
            <template #follower="{ follower }">
              <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
            </template>
          </basic-table>
        </div>
        <div v-if="account?.audience?.ages.length">
          <div class="influencer-detail__section__title__container">
            <div>女性の年齢層</div>
            <div>男性の年齢層</div>
          </div>
          <div class="influencer-detail__section__wrapper">
            <div class="influencer-detail__section__title__sm">女性の年齢層</div>
            <basic-table :headers="ageHeaders" :items="mergeFollowerAndLikerGenderPerAge('FEMALE')">
              <template #follower="{ follower }">
                <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
              </template>
            </basic-table>
            <div class="influencer-detail__section__title__sm">男性の年齢層</div>
            <basic-table :headers="ageHeaders" :items="mergeFollowerAndLikerGenderPerAge('MALE')">
              <template #follower="{ follower }">
                <div>{{ getRealNumAndPercentage(account?.followers, follower) }}</div>
              </template>
            </basic-table>
          </div>
        </div>
        <div v-if="account?.audience?.countries?.length || account?.audience?.languages?.length">
          <div class="influencer-detail__section__title__container">
            <div v-if="account?.audience?.languages?.length">言語</div>
          </div>
          <div class="influencer-detail__section__wrapper">
            <div class="influencer-detail__section__title__sm">言語</div>
            <basic-table :headers="languageHeaders" :items="followerLanguages" :key="followerLanguages" class="last-table">
              <template #language="{ language }">
                <div v-if="language?.jpName">{{ language?.jpName }}</div>
                <div v-else>{{ language?.name }}</div>
              </template>
              <template #weight="{ weight }">
                <div>{{ getRealNumAndPercentage(account?.followers, weight) }}</div>
              </template>
            </basic-table>
          </div>
        </div>
      </div>
      <div v-else-if="!account?.audience" class="influencer-detail__no-audience-container">
        <div>
          <h1>オーディエンス情報を取得中です。</h1>
          <h4>データが反映されるまで15分ほどお待ちいただき、<br />再度アクセスして下さい。</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { LineChart, BarChart, DoughnutChart } from "vue-chart-3";
import BasicTable from "@/components/BasicTable.vue";
import { ChartPlug, Code, GenderPerAge, Gender, GenderLabel, Influencer, AudienceCountry, Post, StatHistory, TableHeader, ChartData, AccountHashtag, Tag, AudienceData, Language, Age } from "@/types";
import { Chart } from "chart.js";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
export default defineComponent({
  name: "TiktokDetail.vue",
  components: {
    TwitterIcon,
    LineChart,
    BarChart,
    DoughnutChart,
    BasicTable,
  },
  props: {
    items: {
      type: Object as PropType<Influencer>,
    },
  },
  setup() {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      account: computed(() => store.state.influencers.influencer) as ComputedRef<Influencer>,
      show: {
        first: true,
        second: false,
        third: false,
      },
      hideDescription: true,
      popularPosts: [] as Post[],
      recentPosts: [] as Post[],
      sponsoredPosts: [] as Post[],
      monthlyFollowerData: {} as ChartData,
      monthlyViewData: {} as ChartData,
      monthlyLikeData: {} as ChartData,
      engagementData: {} as ChartData,
      followerLanguages: [] as AudienceData<Language>[],
      followerCountries: [] as AudienceCountry[],
      followerLocationCountryData: {} as ChartData,
      followerGenderData: {} as ChartData,
      followerGenderPerAgeData: {} as ChartData,
      hashtags: [] as Tag[],
      hashtagsForTable: [] as AccountHashtag[],
      detailChecked: false,
    });
    const getGenderPerAge = (genderPerAgeData: GenderPerAge[]) => {
      genderPerAgeData = genderPerAgeData.slice().sort((a: GenderPerAge, b: GenderPerAge) => (a?.age?.code > b?.age?.code ? 1 : -1));
      const res: GenderLabel = {
        label: [],
        female: [],
        male: [],
      };
      genderPerAgeData.slice().map((x: GenderPerAge) => {
        if (!res.label.includes(x.age.code)) res.label.push(x.age.code);
      });
      res.label.slice().map((x: string) => {
        genderPerAgeData.slice().map((y: GenderPerAge) => {
          if (y.age.code === x) {
            if (y.gender.code === "FEMALE") res.female.push(y.weight * 100);
            if (y.gender.code === "MALE") res.male.push(y.weight * 100);
          }
        });
      });
      return res;
    };
    const getLocaleString = (val: number) => {
      return val ? Math.round(val).toLocaleString() : "データなし";
    };
    const getPercentage = (val: number) => {
      return (Math.round(val * 10000) / 100).toString() + "%";
    };
    const getDeltaRate = (dataArr: number[]) => {
      let resArr = [""];
      for (let i = 0; i < dataArr.length; i++) {
        if (i !== 0) {
          let tempVal = "";
          if (dataArr[i] < dataArr[i - 1]) {
            tempVal = "▼" + (((dataArr[i - 1] - dataArr[i]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          } else if (dataArr[i] > dataArr[i - 1]) {
            tempVal = "▲" + (((dataArr[i] - dataArr[i - 1]) / dataArr[i - 1]) * 100).toFixed(2) + "%";
          }
          resArr.push(tempVal);
        }
      }
      return resArr;
    };
    Chart.defaults.font.size = 10;
    Chart.defaults.font.size = 10;
    const monthlyChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        y: {
          min: 0,
          maxTicksLimit: 8,
          padding: 20,
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
          ticks: {
            min: 0,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          font: {},
        },
        datalabels: {
          align: "end",
          offset: 8,
          font: {
            size: 10,
            weight: 400,
          },
          formatter: function (value: ChartPlug, context: ChartPlug) {
            return getDeltaRate(context.chart.data.datasets[0].data)[context.dataIndex];
          },
          color: function (value: ChartPlug) {
            if (getDeltaRate(value.chart.data.datasets[0].data)[value.dataIndex][0] === "▼") {
              return "#FF0000";
            } else {
              return "#46D540";
            }
          },
        },
      },
    };
    const engagementChartOption = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
          },
        },
        yAxis1: {
          stacked: true,
          id: "y-axis-1",
          type: "linear",
          position: "left",
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            size: 24,
            callback: function (value: number) {
              if (value >= 999 && value <= 99999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 100000 && value <= 999999) {
                return "      " + (value / 1000).toFixed(1) + "K";
              } else if (value >= 1000000 && value <= 999999999) {
                return "      " + (value / 1000000).toFixed(1) + "M";
              } else if (value >= 1000000000) {
                return "      " + (value / 1000000000).toFixed(1) + "B";
              } else {
                return "      " + value;
              }
            },
          },
        },
        yAxis2: {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            fontSize: 12,
            callback: function (value: number) {
              if (value.toFixed(0).length === 1) {
                return "      " + value.toFixed(0) + "%";
              }
              return "    " + value.toFixed(0) + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: function (val: ChartPlug) {
            return val.dataset.yAxisID === "yAxis2";
          },
          align: "end",
          offset: 8,
          color: "#9299F7",
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return value.toFixed(1) + "%";
          },
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let labelIndex = context.datasetIndex;
              let label = ["エンゲージメント率", "いいね数", "コメント数", "リツイート数"][labelIndex];
              return label + ": " + context.parsed.y.toLocaleString();
            },
          },
        },
      },
    };
    const locationChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            min: 0,
            max: 100,
            padding: 10,
          },
        },
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#f7f7f7",
          },
          ticks: {
            fontSize: 10,
            padding: 10,
            callback: function (value: number) {
              return value + "%";
            },
          },
        },
      },
      layout: {
        padding: {
          right: 40,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: 10,
            weight: "Bold",
          },
          anchor: "end",
          align: "end",
          padding: {
            bottom: 0,
          },
          formatter: function (value: number) {
            return (value || 0).toFixed(1) + "%";
          },
        },
      },
    };
    const genderChartOption = {
      cutoutPercentage: 65,
      legend: {
        display: true,
        position: "chartArea",
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false,
          align: "end",
          offset: 16,
          color: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          font: {
            size: 12,
            weight: 500,
          },
          formatter: function (value: number) {
            return (value * 100).toFixed(1) + "%";
          },
        },
      },
    };
    const genderPerAgeChartOption = {
      scales: {
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            fontSize: 10,
          },
        },
        y: {
          ticks: {
            fontSize: 10,
            callback: function (value: string) {
              return value + "%";
            },
          },
          grid: {
            drawBorder: false,
            color: "#f7f7f7",
          },
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          font: {
            size: 10,
            weight: "Bold",
          },
          anchor: "end",
          align: "end",
          padding: {
            bottom: -8,
          },
          formatter: function (value: number | string) {
            if (value == "") {
              return "";
            } else {
              return (Number(value) || 0).toFixed(1) + "%";
            }
          },
        },
      },
    };
    type KeyPluralIndex = "genders" | "ages" | "languages";
    type StrIndex = "gender" | "age" | "language";
    type CodeIndex = "code" | "name";
    const mergeFollowerAndLikerData = (keyPlural: KeyPluralIndex, key: StrIndex, code: CodeIndex = "code", useJPName = false) => {
      const codes: Array<Code | string> = [];
      const res: Gender[] = [];
      const emptyArr: AudienceData<Language | Age | Gender>[] = [];
      const baseDict = { [keyPlural]: emptyArr };
      (state.account?.audience || baseDict)[keyPlural].slice().map((x: AudienceData<Language | Age | Gender>) => {
        const obj = x[key];
        if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
          const safeCode = String(obj[code]);
          const safeJpName = typeof obj.jpName === "string" ? obj.jpName : "";
          const codeObj: Code = { code: safeCode, jpName: safeJpName };
          return useJPName ? codes.push(codeObj) : codes.push(String(obj[code]));
        }
      });
      if (!useJPName) codes.sort();
      codes.slice().map((x: Code | string) => {
        const resDict: { follower?: number; code: string } = typeof x === "string" ? { code: String(x) } : { code: String(x.jpName) || x.code || "" };
        ((state.account?.audience || baseDict)[keyPlural] || []).slice().map((y: AudienceData<Language | Age | Gender>) => {
          const obj = y[key];
          if (obj !== undefined && typeof obj !== "string" && typeof obj !== "number") {
            if (typeof x !== "string") {
              if (obj[code] === x.code) resDict.follower = y.weight;
            } else {
              if (obj[code] === String(x)) resDict.follower = y.weight;
            }
          }
        });
        res.push(resDict);
      });
      return res;
    };
    const getRealNumAndPercentage = (totalNum: number | void, currentRatio: number | void) => {
      if (totalNum === undefined) return "-　/　-";
      if (currentRatio === undefined) return "-　/　-";
      return Math.round(currentRatio * totalNum).toLocaleString() + "　/　" + getPercentage(currentRatio);
    };
    const mergeFollowerAndLikerGenderPerAge = (gender: string) => {
      const codes: string[] = [];
      const res: Gender[] = [];
      ((state.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((x: GenderPerAge) => {
        if (!codes.includes(x.age.code)) codes.push(x.age.code);
      });
      codes.sort();
      codes.map((x: string) => {
        const resDict: Gender = { code: x };
        ((state.account?.audience || { genderPerAges: [] as GenderPerAge[] }).genderPerAges || []).map((y: GenderPerAge) => {
          if (y.age.code === x && y.gender.code === gender) resDict.follower = y.weight;
        });
        res.push(resDict);
      });
      return res.sort((x: Gender, y: Gender) => (x.code > y.code ? 1 : -1));
    };
    const genderHeaders: TableHeader[] = [
      {
        text: "性別",
        value: "code",
      },
      {
        text: "フォロワー",
        value: "follower",
      },
    ];
    const ageHeaders: TableHeader[] = [
      {
        text: "年齢",
        value: "code",
      },
      {
        text: "フォロワー",
        value: "follower",
      },
    ];
    const countryHeaders: TableHeader[] = [
      {
        text: "国",
        value: "country",
      },
      {
        text: "フォロワー",
        value: "weight",
      },
    ];
    const languageHeaders: TableHeader[] = [
      {
        text: "言語",
        value: "language",
      },
      {
        text: "フォロワー",
        value: "weight",
      },
    ];
    const hashtagHeaders: TableHeader[] = [
      {
        text: "人気のハッシュタグ",
        value: "hashtag",
        disableSort: true,
      },
      {
        text: "使用率",
        value: "weight",
        disableSort: true,
      },
    ];
    const fillData = () => {
      state.popularPosts = (state.account?.popularPosts || [])
        .slice()
        .sort((x: Post, y: Post) => (x.likes > y.likes ? -1 : 1))
        .slice(0, 6);
      state.recentPosts = (state.account?.recentPosts || []).sort((x: Post, y: Post) => (x.postedAt > y.postedAt ? -1 : 1)).slice(0, 6);
      state.sponsoredPosts = (state.account?.sponsoredPosts || []).sort((x: Post, y: Post) => (x.postedAt > y.postedAt ? -1 : 1)).slice(0, 6);
      state.hashtagsForTable = (state.account?.hashtags || []).sort((x: AccountHashtag, y: AccountHashtag) => (x.weight > y.weight ? -1 : 1)).slice(0, 5);
      state.followerLanguages = (state.account?.audience?.languages || [])
        .reduce((x: AudienceData<Language>[], y: AudienceData<Language>) => {
          let element = x.find((p: AudienceData<Language>) => {
            if (p.language !== undefined && typeof p.language !== "string" && typeof p.language !== "number" && y.language !== undefined && typeof y.language !== "string" && typeof y.language !== "number") {
              return p.language.code === y.language.code;
            } else {
              return false;
            }
          });
          if (element) {
            element.weight += y.weight;
          } else {
            x.push({
              language: y.language,
              weight: y.weight,
              id: "",
            });
          }
          return x;
        }, [])
        .slice()
        .sort((x: AudienceData<Language>, y: AudienceData<Language>) => (x.weight > y.weight ? -1 : 1))
        .slice(0, 10);
      state.followerCountries = (state.account?.audience?.countries || [])
        .reduce((x: AudienceCountry[], y: AudienceCountry) => {
          let element = x.find((p: AudienceCountry) => {
            return p.country.code === y.country.code;
          });
          if (element) {
            element.weight += y.weight;
          } else {
            x.push({
              country: y.country,
              weight: y.weight,
              id: 0,
              audience: 0,
            });
          }
          return x;
        }, [])
        .slice()
        .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
        .slice(0, 10);
      state.monthlyFollowerData = {
        labels: (state.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (state.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.followers;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyViewData = {
        labels: (state.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (state.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgViews;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.monthlyLikeData = {
        labels: (state.account?.statHistories || [])
          .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
          .filter((v1, i1, a1) => {
            return a1.findIndex((v) => v1.month === v.month) === i1;
          })
          .slice(0, 6)
          .reverse()
          .map((x: StatHistory) => {
            return x.month;
          }),
        datasets: [
          {
            label: {
              display: false,
            },
            data: (state.account?.statHistories || [])
              .sort((x: StatHistory, y: StatHistory) => (x.month > y.month ? -1 : 1))
              .filter((v1, i1, a1) => {
                return a1.findIndex((v) => v1.month === v.month) === i1;
              })
              .slice(0, 6)
              .reverse()
              .map((x: StatHistory) => {
                return x.avgLikes;
              }),
            fill: false,
            borderColor: "#79B5DB",
            pointBorderColor: "#79B5DB",
            pointBackgroundColor: "#79B5DB",
            lineTension: 0.3,
          },
        ],
      };
      state.engagementData = {
        labels: (state.recentPosts || [])
          .slice()
          .reverse()
          .map((x: Post) => {
            return getJPDate(x.postedAt);
          }),
        datasets: [
          {
            type: "line",
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return ((x.likes + x.comments) / Number(state.account?.followers)) * 100;
              }),
            lineTension: 0.3,
            borderColor: "#9299F7",
            pointBackgroundColor: "#9299F7",
            fill: false,
            yAxisID: "yAxis2",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.likes;
              }),
            borderColor: "#FFA0A0",
            backgroundColor: "rgba(255, 160, 160, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
              .slice()
              .reverse()
              .map((x: Post) => {
                return x.comments;
              }),
            borderColor: "#79B5DB",
            backgroundColor: "rgba(121, 181, 219, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
          {
            label: {
              display: false,
            },
            data: (state.recentPosts || [])
                .slice()
                .reverse()
                .map((x: Post) => {
                  return x.retweets ? x.retweets : 0;
                }),
            borderColor: "#79B5DB",
            backgroundColor: "rgba(255, 170, 80, .5)",
            barPercentage: 0.7,
            yAxisID: "yAxis1",
          },
        ],
      };
      state.followerLocationCountryData = {
        labels: ((state.account?.audience || { countries: [] }).countries || [])
          .slice()
          .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
          .map((x: AudienceCountry) => {
            return x.country.jpName ? x.country.jpName : x.country.name;
          })
          .slice(0, 6),
        datasets: [
          {
            label: {
              display: false,
            },
            data: ((state.account?.audience || { countries: [] }).countries || [])
              .slice()
              .sort((x: AudienceCountry, y: AudienceCountry) => (x.weight > y.weight ? -1 : 1))
              .map((x: AudienceCountry) => {
                return x.weight * 100;
              })
              .slice(0, 6),
            backgroundColor: "rgba(148, 229, 176, 1)",
            barPercentage: 0.7,
          },
        ],
      };
      let genders = ((state.account?.audience || { genders: [] }).genders || []).slice();
      if (genders[0] && typeof genders[0].gender !== "string" && typeof genders[0].gender !== "number" && genders[0].gender !== undefined && genders[0].gender.code === "MALE") {
        genders = genders.reverse();
      }
      state.followerGenderData = {
        datasets: [
          {
            label: {
              display: false,
            },
            data: genders.slice().map((x: AudienceData<Gender>) => x.weight),
            backgroundColor: ["rgba(255, 160, 160, 1)", "rgba(121, 181, 219, 1)"],
          },
        ],
      };
      const followerGenderPerAge = getGenderPerAge((state.account?.audience || { genderPerAges: [] }).genderPerAges.sort((x: GenderPerAge, y: GenderPerAge) => (x.age.code > y.age.code ? 1 : -1)));
      state.followerGenderPerAgeData = {
        labels: followerGenderPerAge.label.slice(),
        datasets: [
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.female.slice(),
            borderColor: "rgba(255, 160, 160, 1)",
            backgroundColor: "rgba(255, 160, 160, 1)",
            barPercentage: 0.7,
          },
          {
            label: {
              display: false,
            },
            data: followerGenderPerAge.male.slice(),
            borderColor: "rgba(121, 181, 219, 1)",
            backgroundColor: "rgba(121, 181, 219, 1)",
            barPercentage: 0.7,
          },
        ],
      };
    };
    let getInfluencerSuccess = computed(() => store.state.influencers.getCompaniesListSuccess);
    watch(getInfluencerSuccess, (val: boolean) => {
      if (val) fillData();
    });
    const getJPDate = (val: string) => {
      const date = new Date(String(val).replace(/-/g, "/"));
      return Number(date.getFullYear()) + "年" + Number(date.getMonth() + 1) + "月" + Number(date.getDate()) + "日";
    };
    return {
      ...toRefs(state),
      getLocaleString,
      getPercentage,
      getJPDate,
      fillData,
      monthlyChartOption,
      engagementChartOption,
      locationChartOption,
      genderChartOption,
      getGenderPerAge,
      genderPerAgeChartOption,
      mergeFollowerAndLikerData,
      getRealNumAndPercentage,
      mergeFollowerAndLikerGenderPerAge,
      genderHeaders,
      ageHeaders,
      countryHeaders,
      languageHeaders,
      hashtagHeaders,
    };
  },
});
</script>

<style scoped lang="scss">
@import "src/assets/styles/main";
.influencer-detail {
  width: 100%;
  color: map-get($font-colors, "default");
  &__sns-type {
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 30px;
    padding: 6px 20px;
    margin-top: 30px;
    background-color: map-get($colors, "gray100");
    transition: 0.3s;
    &:hover {
      opacity: 0.6;
      transition: 0.3s;
    }
    & a {
      display: flex;
      text-decoration: none;
    }
  }
  &__profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @include mq(xs) {
      display: block;
    }
    @include mq(lg) {
      display: flex;
    }
    & a {
      text-decoration: none;
      cursor: pointer;
      color: map-get($font-colors, "default");
    }
    &__thumbnail {
      &__tiktok {
        width: 100px;
        min-width: 100px;
        height: 100px;
        border-radius: 50px;
        background-size: cover;
        margin-right: 20px;
        @include mq(xs) {
          width: 60px;
          height: 60px;
          min-width: 60px;
          margin-right: 10px;
          border-radius: 30px;
        }
        @include mq(lg) {
          width: 100px;
          height: 100px;
          min-width: 100px;
          margin-right: 20px;
          border-radius: 50px;
        }
      }
      & img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        @include mq(xs) {
          width: 60px;
          height: 60px;
          border-radius: 30px;
        }
        @include mq(lg) {
          width: 100px;
          height: 100px;
          border-radius: 50px;
        }
      }
    }
    &__name {
      & h2 {
        font-weight: 900;
        @include mq(xs) {
          font-size: 14px;
          width: calc(100vw - 90px);
        }
        @include mq(lg) {
          font-size: initial;
          width: initial;
        }
      }
    }
    &__left {
      display: flex;
      align-items: center;
      width: 30%;
      box-sizing: border-box;
      padding-right: 20px;
      @include mq(xs) {
        width: 90vw;
      }
      @include mq(lg) {
        width: 30%;
      }
    }
    &__right {
      width: 70%;
      max-width: 760px;
      @include mq(xs) {
        display: flex;
        margin-top: 20px;
        width: 90vw;
      }
      @include mq(lg) {
        display: block;
        margin-top: 0;
        width: 70%;
      }
    }
    &__value {
      margin-left: auto;
      gap: 20px 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      @include mq(xs) {
        gap: 10px 10px;
        max-width: 90vw;
        justify-content: start;
      }
      @include mq(lg) {
        gap: 20px 20px;
        max-width: initial;
        justify-content: flex-end;
      }
      &__container {
        display: flex;
        align-items: center;
        background: white;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px 20px;
        width: calc(50% - 20px);
        max-width: 240px;
        min-width: 190px;
        @include mq(xs) {
          padding: 6px;
          width: calc(50% - 12px);
          max-width: 200px;
          min-width: 110px;
        }
        @include mq(lg) {
          padding: 20px 20px;
          width: calc(50% - 20px);
          max-width: 240px;
          min-width: 190px;
        }
        &:last-child {
          margin-right: 0;
        }
        & img {
          width: 30px;
          height: auto;
          @include mq(xs) {
            width: 18px;
          }
          @include mq(lg) {
            width: 30px;
          }
        }
      }
    }
  }
  &__bio {
    margin-top: 30px;
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    box-sizing: border-box;
    word-break: break-all;
    background-color: #fff;
    padding: 20px;
    @include mq(xs) {
      padding: 12px;
    }
    @include mq(lg) {
      padding: 20px;
    }
    &__title {
      font-size: 16px;
      font-weight: 900;
      margin-bottom: 10px;
      color: map-get($font-colors, "primary");
      @include mq(xs) {
        font-size: 13px;
        margin-bottom: 6px;
      }
      @include mq(lg) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    &__btn {
      margin-top: 10px;
      width: 100%;
      text-align: right;
      cursor: pointer;
      color: #0a6dc7;
      font-weight: 700;
      @include mq(xs) {
        font-size: 12px;
      }
      @include mq(lg) {
        font-size: initial;
      }
    }
    & p {
      margin: 0;
      font-size: 16px;
      color: map-get($font-colors, "default");
      @include mq(xs) {
        font-size: 11px;
      }
      @include mq(lg) {
        font-size: 16px;
      }
    }
    & p.hide_description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__tab {
    width: calc(100% / 3);
    padding: 20px 0;
    text-align: center;
    background: map-get($colors, "gray100");
    border: 2px solid map-get($bg-colors, "gray");
    cursor: pointer;
    @include mq(xs) {
      padding: 14px 0;
    }
    @include mq(lg) {
      padding: 20px 0;
    }
    &__container {
      display: flex;
      margin-top: 60px;
      @include mq(xs) {
        margin-top: 20px;
      }
      @include mq(lg) {
        margin-top: 20px;
      }
    }
    & h4 {
      font-weight: 900;
      color: map-get($colors, "gray200");
      & br {
        @include mq(xs) {
          display: block;
        }
        @include mq(lg) {
          display: none;
        }
      }
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  & .selected {
    background: map-get($colors, "primary");
    & h4 {
      color: white !important;
    }
  }
  &__section {
    margin-top: 60px;
    @include mq(xs) {
      margin-top: 20px;
      margin-bottom: 80px;
    }
    @include mq(lg) {
      margin-top: 60px;
      margin-bottom: 0;
    }
    &__wrapper {
      display: flex;
      @include mq(xs) {
        display: block;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        display: flex;
        margin-bottom: 0;
      }
      &.post {
        flex-wrap: wrap;
      }
    }
    &__outer {
      margin-top: 60px;
      @include mq(xs) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
      @include mq(lg) {
        margin-top: 60px;
        margin-bottom: 0;
      }
    }
    &__title {
      font-size: 24px;
      font-weight: 900;
      margin-top: 60px;
      @include mq(xs) {
        font-size: 18px !important;
        font-weight: 900 !important;
        margin-top: 20px !important;
      }
      @include mq(lg) {
        font-size: 24px !important;
        font-weight: 900 !important;
        margin-top: 60px !important;
      }
      &__sm {
        @include mq(xs) {
          display: block;
          font-size: 18px !important;
          font-weight: 900 !important;
          margin-top: 20px !important;
          width: 100%;
        }
        @include mq(lg) {
          display: none;
        }
      }
      &__container {
        display: flex;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: flex;
        }
        & > div {
          width: 50%;
          font-size: 24px;
          font-weight: 900;
          margin-right: 20px;
          margin-top: 60px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__stat {
      &__wrapper {
        display: flex;
        @include mq(xs) {
          display: block;
        }
        @include mq(lg) {
          display: flex;
        }
        &:nth-child(1) {
          margin-top: 30px;
        }
      }
    }
  }
  & .flex-wrap {
    flex-wrap: wrap;
  }
  & .post-card {
    display: block;
    background: none;
    border: 0;
    border-radius: 10px;
    width: 360px;
    margin-right: 20px;
    height: 360px;
    margin-top: 30px;
    &__twitter {
      height: 100%;
    }
    & > .post-date {
      width: calc(100% - 40px);
      font-size: 12px;
      font-weight: 700;
      padding: 10px 20px 0;
    }
    & .post-img-container {
      height: 760px;
      &__no-result {
        width: 320px !important;
        height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        &__content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: #f1f1f1;
          border-radius: 10px;
          color: map-get($colors, "gray300");
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  &__monthly-data {
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(100% / 3);
    margin-right: 20px;
    height: 100%;
    min-height: 260px;
    padding-bottom: 20px;
    @include mq(xs) {
      width: 100%;
    }
    @include mq(lg) {
      width: calc(100% / 3);
    }
    &__title {
      font-size: 14px !important;
      font-weight: 900 !important;
      margin: 20px !important;
      @include mq(xs) {
        padding-top: 20px;
      }
      @include mq(lg) {
        padding-top: 0;
      }
    }
    & canvas {
      padding: 10px;
      width: 340px !important;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__engagement {
    width: calc((100% / 3) * 2 + 20px);
    height: 360px;
    margin-right: 20px;
    margin-top: 30px;
    background-color: white;
    border: 0;
    border-radius: 10px;
    @include mq(xs) {
      width: calc(100% - 20px);
      padding: 20px 0 40px 20px;
    }
    @include mq(lg) {
      padding: 0;
      width: calc((100% / 3) * 2 + 20px);
    }
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin: 20px;
      @include mq(xs) {
        margin: 0;
      }
      @include mq(lg) {
        margin: 20px;
      }
      &__wrapper {
        display: flex;
        justify-content: space-between;
        @include mq(xs) {
          display: block;
        }
        @include mq(lg) {
          display: flex;
        }
      }
    }
    &__labels {
      display: flex;
      margin: 20px 20px 20px 0;
    }
    &__label {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 10px;
      font-weight: bold;
      & img {
        width: 12px;
        margin-right: 5px;
      }
    }
    &__graph {
      display: block;
      margin: auto;
      height: 200px;
      & canvas {
        padding: 10px;
        width: calc(100% - 40px) !important;
      }
    }
  }
  &__tags {
    display: block;
    width: calc(100% - 40px);
    height: calc(220px - 40px);
    background-color: white;
    border: 0;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    &__wrapper {
      width: calc(100% / 3);
      @include mq(xs) {
        width: 100%;
        margin-bottom: 100px;
      }
      @include mq(lg) {
        width: calc(100% / 3);
        margin: 0;
      }
    }
    &__title {
      display: flex;
      justify-content: space-between;
      font-size: 14px !important;
      font-weight: 900 !important;
      margin-bottom: 14px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      &__tag {
        padding: 3px 10px;
        font-weight: 500;
        font-size: 12px;
        color: white;
        background: #79b5db;
        border-radius: 30px;
      }
      &__tag.mention {
        background: #9299f7;
      }
      &__weight {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  &__location {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(75% / 2 - 50px);
    margin-top: 30px;
    margin-right: 20px;
    height: 240px;
    padding: 20px;
    @include mq(xs) {
      width: calc(100% - 40px);
    }
    @include mq(lg) {
      width: calc(75% / 2 - 50px);
    }
    &__title {
      font-size: 13px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__gender {
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: calc(100% / 3 - 40px);
    margin-top: 30px;
    margin-right: 20px;
    height: 240px;
    padding: 20px;
    @include mq(xs) {
      width: calc(100% - 40px);
    }
    @include mq(lg) {
      width: calc(100% / 3 - 40px);
    }
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 30px 0 20px;
      &__labels {
        margin-right: 10px;
      }
      &__label {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        & h1 {
          font-weight: 900;
          color: #606060;
        }
        & .female {
          color: rgba(255, 160, 160, 1);
          font-weight: 900;
        }
        & .male {
          color: rgba(121, 181, 219, 1);
          font-weight: 900;
        }
      }
    }
  }
  &__age {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 10px;
    margin-top: 30px;
    width: calc((100% / 3) * 2 + 80px);
    height: 240px;
    padding: 20px;
    @include mq(xs) {
      width: calc(100% - 40px);
    }
    @include mq(lg) {
      width: calc((100% / 3) * 2 + 80px);
    }
    &__title {
      font-size: 14px;
      font-weight: 900;
      margin-bottom: 20px;
    }
  }
  &__no-audience-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    height: 360px;
    background-color: white;
    text-align: center;
    border-radius: 10px;
    & h1 {
      color: map-get($colors, "primary");
      font-weight: 700;
    }
    & h4 {
      color: map-get($colors, "primary");
      font-weight: 700;
      margin-top: 10px;
    }
  }
}
.last-table {
  margin-bottom: 0;
  @include mq(xs) {
    margin-bottom: 100px;
  }
  @include mq(lg) {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss">
.post-card__twitter {
  iframe {
    width: 360px !important;
    height: 760px !important;
    border-radius: 10px;
  }
}
</style>
