<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
    <g>
      <g transform="translate(-900 -2561)">
        <path d="M9,29.25H20.077v2.077H9Z" transform="translate(909.462 2562.673)" :fill="fillColor" />
        <path d="M15.85,17.235a1.385,1.385,0,1,0-.381-2.717L12.583,12.41l-.173.151,2.116,2.886a1.351,1.351,0,0,0-.061.4A1.383,1.383,0,0,0,15.85,17.235Z" transform="translate(908.15 2569.149)" :fill="fillColor" />
        <path d="M12.375,3.375A9,9,0,0,0,5.283,17.913H7.827L8,17.74l.956-.956-.991-.991-.956.952a6.9,6.9,0,0,1-1.523-3.678h1.35V11.683H5.487A6.9,6.9,0,0,1,7.01,8l.956.952.991-.991L8,7.01a6.9,6.9,0,0,1,3.678-1.523v1.35h1.385V5.487A6.9,6.9,0,0,1,16.745,7.01l-.952.956.991.991L17.74,8a6.9,6.9,0,0,1,1.523,3.678h-1.35v1.385h1.35a6.9,6.9,0,0,1-1.523,3.678l-.956-.952-.991.991.956.956.173.173h2.544A9,9,0,0,0,12.375,3.375Z" transform="translate(911.625 2572.625)" :fill="fillColor" />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "MeterIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff"
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
</script>




