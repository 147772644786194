<template>
  <div class="base-container__white">
    <div class="sign-in">
      <img src="@/assets/img/logo-wrap.svg" alt="INFRECT" />
      <base-text-field placeholder="メールアドレス" :width="360" :smWidth="240" class="mt-60px" v-model="email"></base-text-field>
      <base-text-field placeholder="パスワード" :type="'password'" :width="360" :smWidth="240" class="mt-20px" v-model="password" @keydown.enter="judgementSubmitUserData"></base-text-field>
      <div class="sign-in-error mt-20px" v-show="formErrorMessage || errorMessage">
        <span>{{ formErrorMessage }}</span>
        <span v-if="errorMessage?.nonFieldErrors[0]">{{ errorMessage?.nonFieldErrors[0] }}</span>
        <span v-else>{{ errorMessage }}</span>
      </div>
      <div class="mt-60px">
        <base-button size="wide" @click="submitUserData">ログイン</base-button>
      </div>
      <div class="mt-20px">
        <h4><router-link to="/forget-password">パスワードをお忘れですか？</router-link></h4>
      </div>
      <div class="mt-20px">
        <h4>アカウントをお持ちでない方は<router-link :to="{ name: 'Contact' }">こちら</router-link>からお問い合せ下さい</h4>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { event } from "vue-gtag";

export default defineComponent({
  name: "SignIn",
  components: { BaseTextField, BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("signIn/signInInitialize");
    const state = reactive({
      email: null as string | null,
      password: null as string | null,
      errorMessage: computed(() => store.state.signIn.errorMessage),
      formErrorMessage: null as string | null,
      signInSuccess: computed(() => store.state.signIn.signInSuccess),
    });
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.email) {
        state.formErrorMessage = "メールアドレスは必須です";
      } else if (!state.password) {
        state.formErrorMessage = "パスワードは必須です";
      } else {
        const params = {
          email: state.email,
          password: state.password,
        };
        store.dispatch("signIn/postSignIn", params);
      }
    };
    const judgementSubmitUserData = () => {
      if (state.email && state.password) {
        submitUserData();
      }
    };
    let signInSuccess = computed(() => state.signInSuccess);
    watch(signInSuccess, (val: boolean) => {
      if (val) {
        event("login", { method: "Google" });
        router.push({ name: "Home" });
      }
    });
    return { ...toRefs(state), submitUserData, judgementSubmitUserData };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.sign-in {
  text-align: center;
  padding-top: 120px;
  &-error {
    color: map-get($colors, "accent");
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
