
import { defineComponent, reactive, computed, toRefs, watch, ComputedRef } from "vue";
import InstagramDetail from "../components/InstagramDetail.vue";
import YoutubeDetail from "../components/YoutubeDetail.vue";
import TiktokDetail from "../components/TiktokDetail.vue";
import TwitterDetail from "@/components/TwitterDetail.vue";
import Sidebar from "../components/Sidebar.vue";
import BaseHeader from "../components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { useRoute, useRouter } from "vue-router";
import { formatDate } from "@/plugins/formatter";
import { useStore } from "vuex";
import { AddInfluencerListParams, Influencer } from "@/types/models/influencers";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import AddListIcon from "@/components/icon/AddListIcon.vue";

export default defineComponent({
  name: "InfluencerDetail",
  components: {
    YoutubeDetail,
    InstagramDetail,
    TiktokDetail,
    TwitterDetail,
    AddListIcon,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    CheckMark,
    ExclamationMark,
    Loading,
  },
  props: {
    requiredConfirm: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const influencerId = route.params.id;
    store.dispatch("influencers/getInfluencerDetail", influencerId);
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      user: computed(() => store.state.users.user),
      errorMessage: computed(() => store.state.influencers.getInfluencerErrorMessage),
      errorMessageDetail: computed(() => store.state.influencers.getInfluencerErrorMessageDetail),
      listErrorMessage: computed(() => store.state.influencers.influencerListErrorMessage),
      loading: computed(() => store.state.influencers.loading),
      account: computed(() => store.state.influencers.influencer) as ComputedRef<Influencer>,
      success: computed(() => store.state.influencers.getInfluencerSuccess),
      show: {
        dialog: false,
        add: false,
        addInfluencer: false,
      },
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      addInfluencerSuccess: computed(() => store.state.influencers.addInfluencerListSuccess),
      loadInfluencerSuccess: computed(() => store.state.influencers.getInfluencerSuccess),
      listChecked: false,
      refreshChecked: false,
      checked: false,
      selectedFolder: null as number | null,
      count: 1,
      mounted: false,
    });
    const closeLoadingDialog = () => {
      router.push({ name: "Home" });
    };
    let success = computed(() => state.success);
    watch(success, (newVal: boolean, oldVal: boolean) => {
      if (!oldVal && newVal) state.show.dialog = false;
    });
    const remainingViewing = () => {
      if (state.company) {
        return state.company.plan.detailLimitation - state.company.actions.slice(-1)[0].detailCount;
      }
      return 0;
    };
    const addInfluencerList = (id: string) => {
      store.dispatch("influencers/initailizeAddInfluencerList");
      if (state.selectedFolder) {
        const params: AddInfluencerListParams = {
          influencerList: state.selectedFolder,
          influencers: [{ id: id }],
        };
        store.dispatch("influencers/addInfluencerList", params);
      }
      state.show.addInfluencer = true;
      store.dispatch("influencers/getCompaniesList");
    };
    let setLoadSuccess = computed(() => state.loadInfluencerSuccess);
    watch(setLoadSuccess, (val: boolean) => {
      if (val) store.dispatch("influencers/getCompaniesList");
    });
    let addInfluencerListSuccess = computed(() => state.addInfluencerSuccess);
    watch(addInfluencerListSuccess, (val: boolean) => {
      if (val) store.dispatch("influencers/getCompaniesList");
      state.count++;
    });
    return {
      ...toRefs(state),
      formatDate,
      closeLoadingDialog,
      remainingViewing,
      influencerId,
      addInfluencerList,
      setLoadSuccess,
    };
  },
});
