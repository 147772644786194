
import {defineComponent, PropType, reactive, toRefs, computed, watch } from "vue";
import { ComboBoxItem, ComboBoxId } from "@/types";
import vClickOutside from "click-outside-vue3";
import {useStore} from "vuex";

export default defineComponent({
  name: "ComboBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#79B5DB",
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableLookup: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: "#707070",
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 48,
    },
    id: {
      type: Object as PropType<ComboBoxId>,
      default: null,
    },
    items: {
      type: Array as PropType<Array<ComboBoxItem>>,
      default: () => [],
    },
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    storeName: {
      type: String,
      default: "",
    },
    storeStateName: {
      type: String,
      default: "",
    },
    itemColor: {
      type: String,
      default: "#707070",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 636,
    },
    modelValue: {
      type: Object as () => string | string[] | null,
      default: null,
    },
  },
  setup(props, context) {
    const store = useStore();
    const storeState = reactive({
      items: computed(() => store.state[props.storeName][props.storeStateName]),
      key: "",
    });
    const state = reactive({
      currentInputValue: props.modelValue ? (Array.isArray(props.modelValue) ? null : props.modelValue) : null,
      selectedItems: props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : []) : [],
      isSearching: false,
      filteredIndexArr: [...Array((storeState.items || []).length).keys()] as number[], //items の range
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--height": props.height,
        "--width": props.width,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
        "--border-radius": props.rounded ? props.height / 2 : props.borderRadius, // roundedの時は強制で高さの半分にする
        "--border-width": props.borderWidth,
        "--color": props.color,
        "--font-color": props.fontColor,
        "--font-size": props.fontSize,
        "--item-color": props.itemColor,
      };
    });
    const handleEvent = () => {
      let resValue: string | string[] | null;
      if (props.multiple) {
        if (state.currentInputValue !== null) {
          resValue = state.selectedItems.length > 0 ? state.selectedItems : [state.currentInputValue];
        } else {
          resValue = state.selectedItems.length > 0 ? state.selectedItems : [];
        }
      } else {
        resValue = state.currentInputValue;
      }
      if (state.currentInputValue) context.emit("update:inputValue", state.currentInputValue);
      if (resValue) context.emit("update:modelValue", resValue); // update:modelValue で全部のイベントを handling
    };
    const setSelectedItems = () => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (state.currentInputValue !== "" && state.currentInputValue !== null) {
        if (props.multiple) {
          // 通常は重複を許容しない
          if (props.allowDistinct || !state.selectedItems.includes(state.currentInputValue)) {
            state.selectedItems.push(state.currentInputValue);
          }
          state.currentInputValue = null;
        } else {
          state.isSearching = false;
        }
        handleEvent();
      }
    };
    const setSelectedItemsFromFilter = (val: string) => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (props.multiple) {
        // 通常は重複を許容しない
        if (props.allowDistinct || !state.selectedItems.includes(val)) {
          state.selectedItems.push(val);
        }
        state.currentInputValue = null;
      } else {
        state.currentInputValue = val;
      }
      handleEvent();
    };
    interface HTMLEvent<T extends EventTarget> extends Event {
      target: T;
    }
    const deleteLastSelectedItem = (e: HTMLEvent<HTMLInputElement>) => {
      if (e?.target?.value === "" || e?.target?.value === null) {
        state.selectedItems.pop();
      }
      handleEvent();
    };
    const deleteSelectedItem = (idx: number) => {
      state.selectedItems.splice(idx, 1);
      handleEvent();
    };
    const indexOfAll = (arr: string[], val: string): number[] => {
      const resArr: number[] = [];
      arr.map((arrVal: string, i: number) => {
        if (arrVal.indexOf(val) > -1) resArr.push(i);
      });
      return resArr;
    };
    // inputに入力している時は検索アイテムを表示する
    let inputVal = computed(() => state.currentInputValue);
    watch(inputVal, () => {
      state.isSearching = inputVal.value !== null && inputVal.value !== "";
      if (inputVal.value !== null && inputVal.value !== "") {
        state.filteredIndexArr = indexOfAll(
          storeState.items.map((val: ComboBoxItem) => val.text),
          inputVal.value
        );
      } else {
        // 初期値に戻す
        state.filteredIndexArr = [...Array((storeState.items || []).length).keys()];
      }
    });
    let comboBoxItems = computed(() => storeState.items);
    watch(comboBoxItems, (val) => {
      if (val && val.length) {
        state.filteredIndexArr = [...Array((storeState.items || []).length).keys()]
      }
    });
    return {
      ...toRefs(state),
      storeState,
      styles,
      setSelectedItems,
      setSelectedItemsFromFilter,
      deleteLastSelectedItem,
      deleteSelectedItem,
      handleEvent,
    };
  },
});
