import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import TOP from "@/components/TOP.vue";
import Home from "../views/Home.vue";
import SearchHome from "@/views/SearchHome.vue";
import IDSearch from "@/views/IDSearch.vue";
import AdvancedSearch from "@/views/AdvancedSearch.vue";
import HashtagSearch from "@/views/HashtagSearch.vue";
import AISearch from "@/views/AISearch.vue";
import AISearchSample from "@/views/AISearchSample.vue";
import AISearchResultList from "@/views/AISearchResultList.vue";
import InfluencerDetail from "@/views/InfluencerDetail.vue";
import InstagramInfluencerReport from "@/views/InstagramInfluencerReport.vue";
import YoutubeInfluencerReport from "@/views/YoutubeInfluencerReport.vue";
import TiktokInfluencerReport from "@/views/TiktokInfluencerReport.vue";
import TwitterInfluencerReport from "@/views/TwitterInfluencerReport.vue";
import TaskManage from "@/views/TaskManage.vue";
import TaskDetail from "@/views/TaskDetail.vue";
import Listing from "@/views/Listing.vue";
import EditListing from "@/views/EditListing.vue";
import AdPrediction from "@/views/AdPrediction.vue";
import InfluencerList from "@/views/InfluencerList.vue";
import InfluencerListChildren from "@/views/InfluencerListChildren.vue";
import AccountSetting from "@/views/AccountSetting.vue";
import SignUp from "@/views/SignUp.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import SignIn from "@/views/SignIn.vue";
import PassReset from "@/views/PassReset.vue";
import FirstSetting from "@/views/FirstSetting.vue";
import InvoiceDetail from "@/views/InvoiceDetail.vue";
import DeleteUser from "@/views/DeleteUser.vue";
import MemberVerifyEmail from "@/views/MemberVerifyEmail.vue";
import Terms from "@/views/Terms.vue";
import FAQ from "@/views/FAQ.vue";
import Contact from "@/views/Contact.vue";
import PurchaseSuccess from "@/views/PurchaseSuccess.vue";
import PurchaseCancel from "@/views/PurchaseCancel.vue";
import NotFound from "@/views/404.vue";
import AccessDenied from "@/views/403.vue";
import ServerError from "@/views/500.vue";
import store from "@/store";
import { User } from "@/types";
import ChangePassword from "@/views/ChangePassword.vue";
import Trial from "@/views/Trial.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "TOP",
    component: TOP,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/search-home",
    name: "SearchHome",
    component: SearchHome,
    meta: { requiresAuth: true },
  },
  {
    path: "/id-search",
    name: "IDSearch",
    component: IDSearch,
    meta: { requiresAuth: true },
  },
  {
    path: "/advanced-search",
    name: "AdvancedSearch",
    component: AdvancedSearch,
    meta: { requiresAuth: true },
  },
  {
    path: "/hashtag-search",
    name: "HashtagSearch",
    component: HashtagSearch,
    meta: { requiresAuth: true },
  },
  {
    path: "/ai-search",
    name: "AISearch",
    component: AISearch,
    meta: { requiresAuth: true },
  },
  {
    path: "/ai-search/sample",
    name: "AISearchSample",
    component: AISearchSample,
    meta: { requiresAuth: true },
  },
  {
    path: "/ai-search/results",
    name: "AISearchResultList",
    component: AISearchResultList,
    meta: { requiresAuth: true },
  },
  {
    path: "/influencer-detail/:id",
    name: "InfluencerDetail",
    component: InfluencerDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/instagram-influencer-report/:id",
    name: "InstagramInfluencerReport",
    component: InstagramInfluencerReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/youtube-influencer-report/:id",
    name: "YoutubeInfluencerReport",
    component: YoutubeInfluencerReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/tiktok-influencer-report/:id",
    name: "TiktokInfluencerReport",
    component: TiktokInfluencerReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/twitter-influencer-report/:id",
    name: "TwitterInfluencerReport",
    component: TwitterInfluencerReport,
    meta: { requiresAuth: true },
  },
  {
    path: "/task-manage",
    name: "TaskManage",
    component: TaskManage,
    meta: { requiresAuth: true },
  },
  {
    path: "/task-detail/:id",
    name: "TaskDetail",
    component: TaskDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/listing",
    name: "Listing",
    component: Listing,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-listing/:id",
    name: "EditListing",
    component: EditListing,
    meta: { requiresAuth: true },
  },
  {
    path: "/ad-prediction",
    name: "AdPrediction",
    component: AdPrediction,
    meta: { requiresAuth: true },
  },
  {
    path: "/influencer-list",
    name: "InfluencerList",
    component: InfluencerList,
    meta: { requiresAuth: true },
  },
  {
    path: "/influencer-list-folder/:id",
    name: "InfluencerListChildren",
    component: InfluencerListChildren,
    meta: { requiresAuth: true },
  },
  {
    path: "/account-setting",
    name: "AccountSetting",
    component: AccountSetting,
    meta: { requiresAuth: true },
  },
  {
    path: "/purchase-success",
    name: "PurchaseSuccess",
    component: PurchaseSuccess,
  },
  {
    path: "/purchase-cancel",
    name: "PurchaseCancel",
    component: PurchaseCancel,
  },
  {
    path: "/sign-up/:token",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/verify-email/:verificationCode",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/reset-password/:token",
    name: "PassReset",
    component: PassReset,
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/first-setting",
    name: "FirstSetting",
    component: FirstSetting,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoice-detail",
    name: "InvoiceDetail",
    component: InvoiceDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/delete-user",
    name: "DeleteUser",
    component: DeleteUser,
    meta: { requiresAuth: true },
  },
  {
    path: "/member-verify-email/:invitation_code",
    name: "MemberVerifyEmail",
    component: MemberVerifyEmail,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/trial",
    name: "Trial",
    component: Trial,
  },
  {
    path: "/server-error",
    name: "ServerError",
    component: ServerError,
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || "/"),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("users/loadToken").then(() => {
    // @ts-ignore
    const isLogin: boolean = store.state.users.isLogin;
    // @ts-ignore
    const user: User = store.state.users.user;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (isLogin) {
        if (to.name === "FirstSetting" && !user.isSetCompany) {
          next();
        } else {
          !user.isSetCompany ? next({ name: "FirstSetting" }) : next();
        }
      } else {
        next({ name: "TOP" });
      }
    } else if (isLogin && (to.name === "SignIn" || to.name === "SignUp")) {
      !user.isSetCompany ? next({ name: "FirstSetting" }) : next({ name: "Home" });
    } else {
      next();
    }
  });
});

export default router;
