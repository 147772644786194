
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import { useRouter } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import BaseTable from "@/components/BaseTable.vue";
import Loading from "@/components/Loading.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import multipleToggle from "@/components/MultipleToggle.vue";
import { set } from "vue-gtag";
import BaseTextArea from "@/components/BaseTextArea.vue";
import exclamationMark from "@/components/icon/ExclamationMark.vue";

export default defineComponent({
  name: "AISearch",
  components: {
    Loading,
    BaseTable,
    BaseTextArea,
    BaseButton,
    Sidebar,
    BaseHeader,
    InstagramIcon,
    TiktokIcon,
    TwitterIcon,
    YoutubeIcon,
    multipleToggle,
    exclamationMark
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    store.dispatch("influencers/initializeAISearch");
    const state = reactive({
      count: 0,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      text: "",
      errorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
      loading: computed(() => store.state.influencers.aiSearchLoading),
      success: computed(() => store.state.influencers.aiSearchSuccess),
      keywordLoading: computed(() => store.state.influencers.aiKeywordsLoading),
      hashtagLoading: computed(() => store.state.influencers.aiHashtagsLoading),
      keywordSuccess: computed(() => store.state.influencers.aiKeywordsSuccess),
      hashtagSuccess: computed(() => store.state.influencers.aiHashtagsSuccess),
      selectedKeywordIndex: 0,
      selectedKeywordSNSIndex: 0,
      selectedHashtagIndex: 0,
      selectedHashtagSNSIndex: 0,
      personas: computed(() => store.state.influencers.aiPersonas),
      keywords: computed(() => store.state.influencers.aiKeywords),
      hashtags: computed(() => store.state.influencers.aiHashtags),
      keywordInfluencers : computed(() => store.state.influencers.aiKeywordInfluencers),
      hashtagInfluencers : computed(() => store.state.influencers.aiHashtagInfluencers),
      SNSTypes: [
        "Instagram",
        "YouTube",
        "TikTok",
        "Twitter",
      ],
      HashtagSNSTypes: [
        "Instagram",
        "Twitter",
      ],
      formErrorMessage: null as string | null,
      aiSearchErrorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
    });
    set({ userId: state.user?.id });
    const submitSearch = () => {
      state.count += 1;
      if (!state.text) {
        state.formErrorMessage = "商品・サービスの説明は必須です"
        return
      }
      store.dispatch("influencers/aiSearch", state.text);
      state.selectedHashtagIndex = 0;
      state.selectedKeywordIndex = 0;
      state.selectedHashtagSNSIndex = 0;
      state.selectedKeywordSNSIndex = 0;
    }
    const closeDialog = () => {
      store.dispatch("influencers/initializeAISearch");
      state.formErrorMessage = null;
    }
    const moveToSample = () => {
      router.push({ name: "AISearchSample" });
    }
    const moveToResults = () => {
      router.push({ name: "AISearchResultList" });
    }
    const searchAIHashtagInfluencers = () => {
      if (state.hashtags) {
        store.dispatch("influencers/getAIHashtagSearch",
            {
              snsType: state.SNSTypes[state.selectedHashtagSNSIndex].toLowerCase(),
              hashtags: [state.hashtags[state.selectedHashtagIndex]],
            }
        );
      }
    }
    const searchAIKeywordInfluencers = () => {
      if (state.keywords) {
        store.dispatch("influencers/getAIKeywordSearch",
            {
              snsType: state.SNSTypes[state.selectedKeywordSNSIndex].toLowerCase(),
              keyword: state.keywords[state.selectedKeywordIndex],
            }
        );
      }
    }
    let success = computed(() => state.success);
    watch(success, (val) => {
      if (val) {
        state.count += 1;
        searchAIKeywordInfluencers();
        searchAIHashtagInfluencers();
      }
    })
    return {
      ...toRefs(state),
      moveToSample,
      moveToResults,
      submitSearch,
      closeDialog,
      searchAIHashtagInfluencers,
      searchAIKeywordInfluencers,
    };
  },
});
