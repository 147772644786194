import axios from "@/store/axios";
import { Commit } from "vuex";
import { Hashtag, ListResponse, HashtagRequestPrams, ComboBoxItem } from "@/types";

interface State {
  errorMessage: string | null;
  hashtags: ComboBoxItem[];
}

const state: State = {
  errorMessage: null,
  hashtags: [],
};
const hashtags = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setHashtags(state: State, hashtagResponse: ListResponse<Hashtag>) {
      const hashtags: string[] = [];
      hashtagResponse.results.forEach((val: Hashtag) => {
        if (!hashtags.includes(val.tag)) hashtags.push(val.tag);
      });
      const comboBoxItems = hashtags.map((val: string) => {
        return {
          text: val,
          value: val,
        };
      });
      state.hashtags = comboBoxItems;
    },
  },
  actions: {
    async getHashtags({ commit }: { commit: Commit }, { params }: { params: HashtagRequestPrams }) {
      try {
        const res = await axios.get("api/v1/hashtags/", { params: params });
        commit("setHashtags", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default hashtags;
