<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch, ref, Ref, PropType } from "vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "BaseSelectBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "white",
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#79b5db",
    },
    focusColor: {
      type: String,
      default: "#79b5db",
    },
    focusBorderWidth: {
      type: Number,
      default: 3,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableLookup: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: "#4c4c4c",
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 44,
    },
    id: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemColor: {
      type: String,
      default: "#4c4c4c",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    placeholder: {
      type: String,
      default: "選択してください",
    },
    searchBoxPlaceholder: {
      type: String,
      default: "検索",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 636,
    },
    modelValue: {
      type: Object as PropType<string | number | null>,
      default: null,
    },
  },
  emits: ["update:modelValue", "updateSelected"],
  setup(props, context) {
    // ===========
    // state
    // ===========
    const state = reactive({
      currentInputValue: props.modelValue ? (Array.isArray(props.modelValue) ? null : props.modelValue) : null,
      selectedItems: props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : [],
      isSearching: false,
      isFocused: false,
      filteredIndexArr: [...Array((props.items || []).length).keys()] as number[], //items の range
    });
    const styles = computed(() => {
      return {
        "--height": props.height,
        "--width": props.width,
        "--border-radius": props.rounded ? props.height / 2 : props.borderRadius, // roundedの時は強制で高さの半分にする
        "--border-width": props.borderWidth,
        "--color": props.color,
        "--focus-color": props.focusColor,
        "--focus-border-width": props.focusBorderWidth,
        "--font-color": props.fontColor,
        "--font-size": props.fontSize,
        "--item-color": props.itemColor,
        "--bg-color": props.bgColor,
      };
    });
    // ===========
    // functions
    // ===========
    const handleEvent = () => {
      let resValue: any | any[] | null;
      if (props.multiple) {
        resValue = state.selectedItems.length > 0 ? state.selectedItems : [];
        if (!props.returnObject) resValue = resValue.map((x: any) => x[props.itemValue]);
      } else {
        resValue = state.selectedItems.length > 0 ? state.selectedItems[0] : null;
        if (!props.returnObject) resValue = resValue[props.itemValue];
      }
      context.emit("update:modelValue", resValue);
      // update:modelValue で全部のイベントを handling
      context.emit("updateSelected");
    };
    const isInclude = (arr: any[], val: any) => {
      let tempArr = Array.isArray(arr) ? arr.slice() : Array(arr);
      let res = false;
      tempArr.map((x) => {
        if (x[props.itemValue] === val[props.itemValue]) res = true;
      });
      return res;
    };
    const setSelectedItemsFromFilter = (val: any) => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (props.multiple) {
        // 通常は重複を許容しない
        if (props.allowDistinct || !isInclude(state.selectedItems, val)) {
          state.selectedItems.push(val);
        } else {
          // 重複を許可しないのであれば、削除を行う
          state.selectedItems = state.selectedItems.filter((x: any) => x[props.itemText] !== val[props.itemText]);
        }
        state.currentInputValue = null;
      } else {
        if (isInclude(state.selectedItems, val) && props.allowNull) {
          state.selectedItems = [];
        } else {
          state.selectedItems = [val];
        }
        state.isFocused = false;
        state.isSearching = false;
      }
      handleEvent();
    };
    const deleteLastSelectedItem = () => {
      // delete キーは通常の文字削除にも判定してしまうため、input 内に文字がないときにのみ selectedItems の削除を行うようにする
      if (state.currentInputValue === "" || state.currentInputValue === null) {
        state.selectedItems.pop();
      }
      handleEvent();
    };
    const deleteSelectedItem = (idx: number) => {
      state.selectedItems.splice(idx, 1);
      handleEvent();
    };
    const indexOfAll = (arr: string[], val: string): number[] => {
      const resArr: number[] = [];
      arr.map((arrVal: string, i: number) => {
        if (arrVal.indexOf(val) > -1) resArr.push(i);
      });
      return resArr;
    };
    const handleClickOutside = (event: any) => {
      // セレクトボックスの検索欄をクリックしている時は非表示にしない
      if (!(event.target === searchBox.value || event.target === searchBoxContainer.value)) {
        if (selectItems.includes(event.target)) {
          event.preventDefault();
        } else {
          state.isFocused = false;
          state.isSearching = false;
        }
      }
    };
    // ===================
    // watch
    // ===================
    // inputに入力している時は検索アイテムを表示する
    let inputVal = computed(() => state.currentInputValue);
    watch(inputVal, (inputVal) => {
      state.isSearching = inputVal !== null && inputVal !== "";
      if (inputVal !== null && inputVal !== "") {
        state.filteredIndexArr = indexOfAll(
          props.items?.map((val: any) => val[props.itemText]),
          String(inputVal)
        );
      } else {
        // 初期値に戻す
        state.filteredIndexArr = [...Array((props.items || []).length).keys()];
      }
    });
    // ===================
    // ref
    // ===================
    const searchBoxContainer = ref<HTMLElement>();
    const searchBox = ref<HTMLImageElement>();
    const selectItems = [] as Ref<HTMLElement>[];
    const selectItem = (el: Ref<HTMLElement>) => {
      if (el) selectItems.push(el);
    };
    return {
      ...toRefs(state),
      styles,
      isInclude,
      setSelectedItemsFromFilter,
      deleteLastSelectedItem,
      deleteSelectedItem,
      handleEvent,
      handleClickOutside,
      searchBox,
      searchBoxContainer,
      selectItem,
    };
  },
});
</script>

<template>
  <div>
    <div :class="[label ? 'selectbox__label' : 'd-none']">
      <span :class="['selectbox__label--' + color, 'selectbox__label-title']">
        {{ label }}
      </span>
      <span class="required" v-show="required">必須</span>
      <span class="multiple" v-show="multiple">複数選択</span>
    </div>
    <div :class="['selectbox__container', isFocused ? 'focus' : '']" :style="styles">
      <div v-click-outside="handleClickOutside" :class="['selectbox__wrapper', isFocused ? 'focus' : '']" :style="styles" @click="isFocused = !isFocused">
        <div v-for="(item, idx) in selectedItems" :key="idx" :class="['selectbox__selected_item', chips ? 'chips' : '', deletableChips ? 'deletable-chips' : '']">
          <span v-show="deletableChips" @click="deleteSelectedItem(idx)">×</span>
          {{ item[itemText] }}
        </div>
        <span v-show="!selectedItems?.length" class="selectbox__placeholder">{{ placeholder }}</span>
      </div>
      <div v-show="isFocused || isSearching" ref="searchBoxContainer" class="selectbox__items" :style="styles">
        <div v-show="!disableLookup" class="search-box__wrapper">
          <img class="search-box__icon" src="../assets/img/search-icon.svg" alt="search" />
          <!-- @keydown.enter でエンターキーを入力後も発火するように。日本語の変換時のエンターは発火しない。 -->
          <input
            :id="id"
            ref="searchBox"
            v-model="currentInputValue"
            :class="['search-box', disabled ? 'disabled' : '', readonly ? 'readonly' : '']"
            :style="styles"
            :placeholder="searchBoxPlaceholder"
            :disabled="disabled ? 'disabled' : null"
            :readonly="readonly ? 'readonly' : null"
            :autofocus="autofocus"
            @focusin="isFocus = true"
            @focusout="isFocus = false"
          />
        </div>
        <div v-show="filteredIndexArr.length < 1" class="selectbox__item no-data">{{ noDataText }}</div>
        <div v-for="(searchItem, sIdx) in items" v-show="filteredIndexArr.includes(sIdx)" :ref="selectItem" :key="searchItem[itemValue]" :class="['selectbox__item', isInclude(selectedItems, searchItem) ? 'selected' : '']" @click="setSelectedItemsFromFilter(searchItem)">
          <span class="batsu" v-if="allowNull || multiple" />
          {{ searchItem[itemText] }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.selectbox {
  width: calc(100% - 40px);
  min-width: fit-content;
  flex-wrap: nowrap;
  height: 100%;
  border: none;
  padding: 0 20px;
  background: none;
  color: map-get($font-colors, "default");
  overflow-x: scroll;
  -ms-overflow-style: none;
  font-size: calc(var(--font-size) * 1px);
  &::-webkit-scrollbar {
    display: none;
  }
  @include mq(xs) {
    width: calc(100% - 44px);
  }
  @include mq(lg) {
    width: calc(100% - 40px);
  }
  &__container {
    width: calc(var(--width) * 1px + 4px);
    height: calc(var(--height) * 1px + 4px);
    position: relative;
    @include mq(xs) {
      width: calc(var(--width) * 1px + 4px);
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px + 4px);
    }
    &.focus {
      outline: none;
      border-color: var(--focus-color);
      & .selectbox__wrapper {
        border-width: calc(var(--focus-border-width) * 1px) !important;
      }
    }
    &::after {
      content: "▼";
      transform: scaleY(0.8);
      transition: all 0.6s ease-in-out;
      color: var(--focus-color);
      position: absolute;
      top: 12px;
      right: 20px;
      pointer-events: none;
    }
    &.focus::after {
      transform: scaleY(0.8) rotate(-180deg);
      transition: all 0.6s ease-in-out;
    }
  }
  &__label {
    text-align: left;
    font-weight: 900;
    font-size: 13px;
    margin: 8px 10px 8px 0;
    color: map-get($colors, "gray300");
    &--primary {
      color: map-get($font-colors, "default");
    }
    &--white {
      color: white;
      font-weight: 700;
    }
  }
  &__placeholder {
    color: map-get($font-colors, "primary");
    position: absolute;
    font-size: calc(var(--font-size) * 1px);
    font-weight: 500;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    left: 20px;
  }
  &__wrapper {
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    border-radius: calc(var(--border-radius) * 1px);
    border: solid calc(var(--border-width) * 1px) var(--color);
    background-color: var(--bg-color);
    overflow-x: scroll;
    display: flex;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px);
    }
  }
  &.disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
  }
  &.readonly {
    cursor: not-allowed;
  }
  &__selected_item {
    align-self: center;
    margin-left: 20px;
    font-size: calc(var(--font-size) * 1px);
    color: var(--font-color);
    min-width: fit-content;
    font-weight: 500;
    &.chips {
      display: block;
      border: solid 1px var(--focus-color);
      width: fit-content;
      padding: 4px 12px;
      border-radius: 5px;
      &.deletable-chips {
        padding: 4px 12px 4px 6px;
        & > span {
          color: var(--focus-color);
          margin-right: 6px;
          &:hover {
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__items {
    z-index: 5;
    position: absolute;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    top: calc(var(--height) * 1px + 10px);
    left: 0;
    border: solid 2px var(--focus-color);
    color: var(--item-color);
    border-radius: 5px;
    background-color: white;
    padding: 8px 0;
    & .search-box {
      width: calc(100% - (var(--font-size) * 2px) - 40px);
      background: none;
      color: map-get($font-colors, "default");
      font-size: calc(var(--font-size) * 1px);
      padding: calc(var(--height) * 0.2px) calc(var(--font-size) * 2px + 20px);
      border: none;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: map-get($colors, "blue300");
        font-size: calc(var(--font-size) * 1px);
      }
      &.disabled {
        cursor: not-allowed;
        background-color: #e0e0e0;
        &:focus {
          box-shadow: none;
        }
      }
      &__icon {
        width: calc(var(--font-size) * 1.4px);
        height: auto;
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: calc(var(--font-size) * 1px);
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
      }
      &__wrapper {
        width: calc(90% - 40px);
        margin: 10px auto 20px auto;
        border: solid 1px var(--focus-color);
        border-radius: 9999px;
        position: relative;
        &:focus-within {
          box-shadow: 0 0 0 2px var(--focus-color); /* outlineは角丸にできないのでboc-shadowで代用 */
        }
      }
    }
  }
  &__item {
    margin-bottom: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
      &.no-data {
        cursor: not-allowed;
        background-color: white;
      }
    }
    &.selected {
      background-color: #e0e0e0;
      & .batsu {
        position: relative;
        margin-left: 10px;
        &::before {
          position: absolute;
          width: 15px;
          height: 16px;
          padding-left: 1px;
          content: "×";
          top: 5px;
          left: -22px;
          font-size: 10px;
          font-weight: 700;
          background-color: map-get($colors, "gray200");
          border-radius: 10px;
          text-align: center;
          color: white;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.required {
  margin-left: 14px;
  padding: 1px 10px;
  background-color: map-get($colors, "accent");
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 700;
}
.multiple {
  margin-left: 14px;
  padding: 1px 10px;
  background-color: map-get($colors, "gray300");
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 700;
}
</style>
