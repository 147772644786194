<template>
  <div class="base-container hashtag-search">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header></base-header>
      <div class="mt-40px" v-if="!show">
        <router-link :to="{name: 'AISearch'}">
          <base-button color="accent" size="wide">AI検索に戻る</base-button>
        </router-link>
        <basic-table :headers="headers" :items="results" :key="results.length" class="mt-20px">
          <template #text="{ text }">
            {{ text.slice(0, 30) }}
          </template>
          <template #timestamp="{ timestamp }">
            {{ formatDate(new Date(Number(timestamp)*1000)) }}
          </template>
          <template #key="{ text, key }">
            <base-button size="small" @click="showResult(text, key)">詳細を閲覧</base-button>
          </template>
        </basic-table>
      </div>
      <div class="mt-40px" v-else>
        <a-i-search-result :keyName="key" :text="text" @back="closeResult"></a-i-search-result>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultipleToggle from "@/components/MultipleToggle.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import BasicTable from "@/components/BasicTable.vue";
import { useStore } from "vuex";
import { TableHeader } from "@/types";
import { formatDate } from "@/plugins/formatter";
import Loading from "@/components/Loading.vue";
import { set } from "vue-gtag";
import BaseTable from "@/components/BaseTable.vue";
import exclamationMark from "@/components/icon/ExclamationMark.vue";
import { useRouter } from "vue-router";
import { useStore as useStoreUsers } from "vuex";
import AISearchResult from "@/components/AISearchResult.vue";

export default defineComponent({
  name: "AISearchResultList",
  components: {
    AISearchResult,
    BasicTable,
    Sidebar,
    BaseHeader,
    BaseButton
  },
  setup() {
    const store = useStore();
    const state = reactive({
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      results: computed(() => store.state.influencers.aiSearchResults),
      show: false,
      key: "",
      text: "",
    });
    store.dispatch("influencers/getAISearchResultList");
    const headers: TableHeader[] = [
      {
        text: "検索文章",
        value: "text",
      },
      {
        text: "検索日時",
        value: "timestamp",
      },
      {
        text: "詳細",
        value: "key",
      }
    ];
    const showResult = (text: string, key: string) => {
      state.key = key;
      state.text = text;
      state.show = true;
    }
    const closeResult = () => {
      console.log("aaaa")
      state.show = false;
    }
    return {
      ...toRefs(state),
      headers,
      formatDate,
      showResult,
      closeResult,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/img/ai-search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  &__contents {
    padding: 50px 0;
    @include mq(xs) {
      padding: 40px 0 60px 0;
    }
    @include mq(lg) {
      padding: 50px 0;
    }
    & h1 {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 18px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    & h3 {
      color: white;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 40px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 20px;
      }
      @include mq(lg) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    &__buttons {
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;
      & svg {
        margin-top: 2px;
      }
      & .button {
        width: 140px;
        @include mq(xs) {
          width: 60px;
        }
        @include mq(lg) {
          width: 140px;
        }
      }
      & span {
        display: inline-block;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: inline-block;
        }
      }
    }
    &__area {
      & input {
        width: 580px;
        height: 60px;
        border: 2px solid white;
        border-radius: 5px 0 0 5px;
        font-size: 18px;
        font-weight: 700;
        color: map-get($font-colors, "default");
        padding: 0 20px;
        @include mq(xs) {
          width: 90%;
          font-size: 14px;
        }
        @include mq(lg) {
          width: 580px;
          font-size: 18px;
        }
        &:focus {
          border: 2px solid map-get($colors, "accent");
          outline: none;
        }
        &::placeholder {
          color: map-get($colors, "gray200");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 18px;
          }
        }
      }
      &__button {
        width: 60px;
        height: 60px;
        background-color: #ee7382;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        &:hover {
          background-color: #efabb4;
        }
      }
      &__error {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
    }
  }
}
.result {
  &__container {
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    & h3 {
      & > span {
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    & .loading {
      width: 80px;
      margin: 40px auto;
    }
  }
  &__section {
    margin-bottom: 80px;
    & h3 {
      margin-bottom: 20px;
    }
  }
  &__relation {
    & h4 {
      font-weight: 900;
      margin: 30px 0 10px;
    }
    & h5 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    &__ranking {
      width: 80%;
      margin: 0 auto;
      &__table {
        width: 49%;
        min-width: 300px;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: 49%;
        }
        &__wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          @include mq(xs) {
            display: block;
          }
          @include mq(lg) {
            display: flex;
          }
        }
        &__no-data {
          width: 100%;
          min-width: 300px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #9c9c9c;
          margin: 20px 0;
        }
      }
    }
    &__relations {
      width: 80%;
      margin: 20px auto 0 auto;
      &__graph {
        width: 800px;
        border: solid 8px map-get($colors, "gray200");
        border-radius: 8px;
        margin: 0 auto;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: calc(100% - 20px);
        }
        &__no-data {
          width: 100%;
          height: 100%;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          vertical-align: center;
          color: map-get($colors, "gray200");
          margin: 80px 0;
        }
      }
    }
  }
}
.result-none {
  width: 100%;
  background: white;
  padding: 120px 0;
  border-radius: 10px;
  height: fit-content;
  text-align: center;
  align-items: center;
  @include mq(xs) {
    padding: 60px 0;
    margin-bottom: 40px;
  }
  @include mq(lg) {
    padding: 120px 0;
    margin-bottom: auto;
  }
  & h1,
  h3 {
    color: map-get($colors, "primary");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.influencer-detail {
  width: 100%;
  color: map-get($font-colors, "default");
  &__post {
    width: calc(50% - 50px);
    padding: 20px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    @include mq(xs) {
      width: calc(100% - 30px);
      height: 895px;
    }
    @include mq(lg) {
      width: calc(50% - 50px);
      height: 705px;
    }
    &__post__card {
      display: flex;
      width: 100%;
      @include mq(xs) {
        display: block;
        height: fit-content;
      }
      @include mq(lg) {
        display: flex;
        height: calc(100% - 160px);
      }
    }
    &__account {
      &__card {
        display: flex;
        align-content: space-between;
        width: 100%;
        height: 60px;
        margin: 20px auto 20px 0;
      }
      &__profile {
        width: 100%;
        display: flex;
        & > img {
          width: 60px;
          height: 60px;
          display: block;
          border-radius: 9999px;
        }
        &__name {
          margin-left: 10px;
          & h4 {
            font-weight: 700;
          }
        }
      }
      &__actions {
        width: 50%;
        text-align: right;
      }
    }
    &.twitter {
      display: block;
      height: 700px;
      padding: 20px;
      margin: 0 20px 30px 0;
      @include mq(xs) {
        width: calc(100% - 40px);
      }
      @include mq(lg) {
        width: calc(30% - 20px);
      }
      & iframe {
        height: 600px;
        margin: 0 auto;
      }
      &:nth-of-type(2n) {
        margin-right: 20px;
      }
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: flex;
      }
    }
    &__thumbnail {
      margin-right: 20px;
      width: 300px;
      height: 600px;
      position: relative;
      border-radius: 10px;
      background-image: url("../assets/img/post-background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 16px;
      @include mq(xs) {
        margin: auto;
      }
      @include mq(lg) {
        margin: 0 20px 0 0;
      }
      & iframe {
        width: 300px !important;
        height: 600px !important;
        border: 2px solid #f7f7f7;
        border-radius: 10px;
      }
    }
    &__content {
      position: relative;
      width: calc(100% - 320px);
      @include mq(xs) {
        width: 100%;
      }
      @include mq(lg) {
        width: calc(100% - 220px);
      }
      &__date {
        font-size: 12px;
        font-weight: 700;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 0;
        }
      }
      &__text {
        overflow: scroll;
        @include mq(xs) {
          height: 135px;
        }
        @include mq(lg) {
          height: 100%;
        }
        &__wrapper {
          font-size: 13px;
          height: 100%;
          margin-top: 10px;
        }
      }
      &__engagements {
        display: flex;
        bottom: 0;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 10px;
        }
      }
      &__engagement {
        display: flex;
        font-size: 14px;
        font-weight: 900;
        margin-right: 20px;
        & img {
          width: 10px;
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      & .likes {
        color: #ffa0a0;
      }
      & .comments {
        color: #79b5db;
      }
    }
  }
}
.search-button {
  width: 80%;
  margin: 20px auto;
  padding: 14px;
  background: #f75977;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: 500;
}
</style>
