export class TaskStatus {
  static readonly LISTING = new TaskStatus(0, "リストアップ中");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const taskStatus = this.getAll().find((taskStatus) => taskStatus.key === key);
    return taskStatus ? taskStatus.text : "リストアップ済";
  }
  static getObject(key: number): TaskStatus | null {
    const taskStatus = this.getAll().find((taskStatus) => taskStatus.key === key);
    return taskStatus !== undefined ? taskStatus : null;
  }
  private static getAll(): Array<TaskStatus> {
    return [TaskStatus.LISTING];
  }
}
