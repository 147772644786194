
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import InfluencerListTable from "@/components/InfluencerListTable.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import { useStore } from "vuex";
import { AddInfluencerListParams, CreateInfluencerListParams, Influencer } from "@/types";
import InfluencerListChildrenTable from "@/components/InfluencerListChildrenTable.vue";
import { AddInfluencerParams } from "@/types/models/influencers";

export default defineComponent({
  name: "InfluencerList",
  components: {
    InfluencerListChildrenTable,
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    BaseHeader,
    Sidebar,
    BaseButton,
    InfluencerListTable,
    BaseTextField,
    BaseCheckbox,
    CheckMark,
    ExclamationMark,
  },
  setup() {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/getCompanyInfluencers");
    const state = reactive({
      show: {
        influencer: false,
        folder: false,
        create: false,
        errorDialog: false,
        delete: false,
        list: false,
      },
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers?.influencerList),
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      createInfluencerListSuccess: computed(() => store.state.influencers.createInfluencerListSuccess),
      companyInfluencers: computed(() => store.state.influencers?.companyInfluencers),
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      folderName: null as string | null,
      selectedInfluencers: [] as Influencer[],
      allChecked: false,
      checked: {} as any,
      count: 1,
    });
    const createInfluencerList = () => {
      state.formErrorMessage = null;
      if (!state.folderName) {
        state.formErrorMessage = "フォルダ名は必須です";
        state.show.errorDialog = true;
      } else {
        const params: CreateInfluencerListParams = {
          company: state.user?.companyId,
          derivedFrom: state.influencerList?.id,
          name: state?.folderName,
        };
        store.dispatch("influencers/createInfluencerList", params);
      }
    };
    let createInfluencerListSuccess = computed(() => state.createInfluencerListSuccess);
    watch(createInfluencerListSuccess, (val: boolean) => {
      if (val) state.show.folder = false;
      if (val) state.show.create = true;
      if (val) store.dispatch("influencers/getCompaniesList");
    });
    const reloadInfluencerList = () => {
      store.dispatch("influencers/getCompaniesList");
      state.show.delete = true;
    };
    const openList = () => {
      state.show.list = true;
      state.selectedInfluencers = [];
    };
    const filterInfluencer = (influencer: Influencer) => {
      if (Array.isArray(state.influencerList?.influencers) && influencer.id) {
        const influencers = state.influencerList.influencers.slice().map((x: Influencer) => {
          return x.id;
        });
        return !influencers.includes(influencer.id);
      }
      return false;
    };
    let itemRefs = [] as any[];
    const setItemRefs = (el: any) => {
      itemRefs.push(el);
    };
    let allChecked = computed(() => state.allChecked);
    watch(allChecked, (val: boolean) => {
      state.count++;
      // header のチェックボックスが選択されたら全選択、外れたら全部選択を外す
      state.companyInfluencers.slice().map((item: any, index: number) => {
        if (state.checked[item.id] !== val && itemRefs[index].children[0]?.children[0]) itemRefs[index].children[0].children[0].click();
      });
    });
    const addInfluencerList = () => {
      state.companyInfluencers.map((item: Influencer) => {
        if (state.checked[item.id]) state.selectedInfluencers.push(item);
      });
      const influencersParams = [] as AddInfluencerParams[];
      state.selectedInfluencers.slice().map((x: Influencer) => {
        influencersParams.push({ id: x.id });
        state.checked[x.id] = false;
      });
      const params: AddInfluencerListParams = {
        influencerList: Number(state.influencerList?.id),
        influencers: influencersParams,
      };
      store.dispatch("influencers/addInfluencerList", params);
      state.show.list = false;
    };
    return {
      ...toRefs(state),
      createInfluencerList,
      reloadInfluencerList,
      openList,
      filterInfluencer,
      setItemRefs,
      addInfluencerList,
    };
  },
});
