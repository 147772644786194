
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultipleToggle from "@/components/MultipleToggle.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import {useStore} from "vuex";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseTable from "@/components/BaseTable.vue";

export default defineComponent({
  name: "AISearchResult",
  components: {
    MultipleToggle,
    BaseTextArea,
    BaseButton,
    BaseTable,
    InstagramIcon,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
    Loading,
    ExclamationMark,
  },
  props: {
    keyName: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    }
  },
  setup(props, context) {
    const store = useStore();
    store.dispatch("influencers/initializeAISearch");
    store.dispatch("influencers/getAISearchResult", props.keyName);
    const state = reactive({
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      errorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
      loading: computed(() => store.state.influencers.aiSearchLoading),
      success: computed(() => store.state.influencers.aiSearchSuccess),
      keywordLoading: computed(() => store.state.influencers.aiKeywordsLoading),
      hashtagLoading: computed(() => store.state.influencers.aiHashtagsLoading),
      keywordSuccess: computed(() => store.state.influencers.aiKeywordsSuccess),
      hashtagSuccess: computed(() => store.state.influencers.aiHashtagsSuccess),
      selectedKeywordIndex: 0,
      selectedKeywordSNSIndex: 0,
      selectedHashtagIndex: 0,
      selectedHashtagSNSIndex: 0,
      personas: computed(() => store.state.influencers.aiPersonas),
      keywords: computed(() => store.state.influencers.aiKeywords),
      hashtags: computed(() => store.state.influencers.aiHashtags),
      keywordInfluencers : computed(() => store.state.influencers.aiKeywordInfluencers),
      hashtagInfluencers : computed(() => store.state.influencers.aiHashtagInfluencers),
      formErrorMessage: null as string | null,
      aiSearchErrorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
      SNSTypes: [
        "Instagram",
        "YouTube",
        "TikTok",
        "Twitter",
      ],
      HashtagSNSTypes: [
        "Instagram",
        "Twitter",
      ]
    });
    const closeDialog = () => {
      store.dispatch("influencers/initializeAISearch");
      state.formErrorMessage = null;
    }
    const resetIndex = () => {
      state.selectedHashtagIndex = 0;
      state.selectedKeywordIndex = 0;
      state.selectedKeywordSNSIndex = 0;
      state.selectedHashtagSNSIndex = 0;
    }
    const backToIndex = () => {
      resetIndex();
      context.emit("back");
    }
    const searchAIHashtagInfluencers = () => {
      if (state.hashtags) {
        store.dispatch("influencers/getAIHashtagSearch",
            {
              snsType: state.SNSTypes[state.selectedHashtagSNSIndex].toLowerCase(),
              hashtags: [state.hashtags[state.selectedHashtagIndex]],
            }
        );
      }
    }
    const searchAIKeywordInfluencers = () => {
      if (state.keywords) {
        store.dispatch("influencers/getAIKeywordSearch",
            {
              snsType: state.SNSTypes[state.selectedKeywordSNSIndex].toLowerCase(),
              keyword: state.keywords[state.selectedKeywordIndex],
            }
        );
      }
    }
    let success = computed(() => state.success);
    watch(success, (val) => {
      if (val) {
        searchAIKeywordInfluencers();
        searchAIHashtagInfluencers();
      }
    })
    return {
      ...toRefs(state),
      resetIndex,
      backToIndex,
      searchAIHashtagInfluencers,
      searchAIKeywordInfluencers,
    };
  },
});
