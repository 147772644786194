<template>
  <input type="checkbox" :id="id" v-model="checked" @change="handleCheck" :value="checked" />
  <label :for="id" class="checkbox01">
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    id: {
      type: String,
      default: "check-box-id",
    },
    modelValue: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const state = reactive({
      checked: props.modelValue,
    });
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
      context.emit("check");
    };
    return {
      ...toRefs(state),
      handleCheck,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/main.scss";
input {
  display: none;
}
.checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
  &::before {
    background: #fff;
    border: 2px solid map-get($colors, "blue300");
    border-radius: 5px;
    content: "";
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }
  &::after {
    border-right: 3px solid map-get($colors, "blue300");
    border-bottom: 3px solid map-get($colors, "blue300");
    content: "";
    display: block;
    height: 9px;
    left: 10px;
    margin-top: -7px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 5px;
  }
}
input[type="checkbox"]:checked + .checkbox01::after {
  opacity: 1;
}
</style>
