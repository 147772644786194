
import { defineComponent } from "vue";

export default defineComponent({
  name: "TikTokIcon",
  props: {
    fillColor: {
      type: String,
      default: "#4c4c4c",
    },
    size: {
      type: Number,
      default: 18,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
});
