<template>
  <div class="base-container__white">
    <div class="faq">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" alt="INFRECT" />
      </header>
      <div class="faq__content">
        <div class="mt-60px">
          <h1>よくある質問</h1>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>INFRECTについて</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>INFRECTは世の中でアカウントを公開しているインフルエンサーの情報を弊社が収集・分析し、お客様のニーズ・ご要望にマッチしたインフルエンサーをご紹介差し上げるサービスです。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>「インフルエンサー検索」はどの様な機能ですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>お客様のニーズ・ご要望にマッチしたインフルエンサーをAIが自動的に選定し、インフルエンサーの情報をご提供するサービスです。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>「ビジネスクリエーション」はどの様な機能ですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>お客様のニーズ・ご要望に併せて、最適なインフルエンサーを適切な人数分リストアップさせていただき、お客様とインフルエンサー間でのお仕事のアサインまで弊社が代行するサービスです。お客様は工数をかけずにインフルエンサーとのお仕事を実現できます。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>「効果を測定する」とはどのような機能ですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                弊社が開発したAIを用いて、特定の情報を入力して頂くだけで、業界最高水準(*誤差平均27％)のInstagram のいいね数が予測できるサービスです。<br />
                本サービスはβ版となっている為、必ずしも正しい予測をご提供するものではございません。<br />
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>どのようなインフルエンサーを検索・分析・お仕事にアサインできますか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                現時点で、Instagram・Youtube・Tiktok・Twitter上でアカウントを公開しており、フォロワーが5000人以上いるインフルエンサーを検索・分析・お仕事にアサインできます。<br />
                今後のアップデートで、フォロワーが5000人以下のインフルエンサーに順次対応させていただきます。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>リストアップして頂いたインフルエンサーについて、インフルエンサーがお仕事の受託を確約するものではないのはなぜですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                本サービスを通してご紹介させて頂くインフルエンサーは弊社のプラットフォームに登録しているインフルエンサーではなく、<br />
                世の中で公開されているインフルエンサーのアカウント情報を弊社が収集・分析し、お客様のニーズ・ご要望に合ったインフルエンサーとしてご紹介させて頂いております。<br />
                その為、ご紹介させて頂いたインフルエンサーの状況によっては、案件を受け付けていない・他社のアンバサダーを行っている等により、お仕事を引き受けることが出来ない場合がございます。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>使いきれなかった検索件数や詳細情報表示回数などは翌月に繰り越せますか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>サービスの仕様上、繰り越すことは出来ません。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>インフルエンサーの詳細の情報が実際とは違うように見受けられるのですがなぜでしょうか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>インフルエンサーの投稿内容やいいね・コメントした人等のデータに基づき、AIが算出したデータとなるため、実際のデータと誤差がある場合がございます。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>インフルエンサーのIDや名前で検索しても、該当のインフルエンサーが検索にヒットしません。</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                お手数ですが、お問い合せページより該当のインフルエンサーのURLを記載し送信して下さい。<br />
                弊社にて該当インフルエンサーのデータを収集し、サービスに反映いたします。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>どのようにすればサービスを利用できますか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                お問い合せフォームより、お問い合せ種別を『新規登録及びトライアルについて』をご選択いただきご送信ください。<br />
                弊社より新規登録のURLが記載されたメールを送らせて頂きます。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>サービスの利用金額について</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                INFRECTでは利用頻度に応じてご選択いただける、月額のサブスクリプションプランをご用意しております。<br />
                プランの詳細や金額に関しましてはお気軽にお問い合わせ下さい。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>支払方法はクレジットカードだけですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>現時点ではクレジットカードのみの対応とさせて頂いております。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>決済のタイミングはいつですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                毎月月末に翌月分のプラン利用権の決済を行わせて頂きます。<br />
                サービス利用開始月につきましても、プランの金額を請求させて頂きます。<br />
                サービスを退会する場合は決済前に退会を行ってください。<br />
                決済を行った月の月末までサービスを利用することが可能です。<br />
                都度課金のサービスについては、サービス利用を行ったタイミングでご登録のクレジットカードに課金させて頂きます。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>プランの最低利用期間に達していないのですが、プランの変更・解約はできますか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                本サービスは、スタンダードプランで6ヶ月、アドバンスドプランで3ヶ月の最低利用期間を設けております。<br />
                最低利用期間内でのプラン変更・解約をご希望の場合はお問い合わせください。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>トライアルプランにてサービスが利用できなくなりました。</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                トライアルプランには7日間の利用期間制限を設けております。７日間を超えてしまうとサービスの一部機能が利用できなくなってしまいます。<br />
                サービスの継続利用をご希望の場合は、サブスクリプションプランをご購入ください。<br />
                その他の理由でサービスが利用できない際は、お手数ですが<router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">お問い合せページ</router-link>よりサービスを利用できない旨をご記載の上ご送信ください。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>ログインパスワードを忘れてしまいました。</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4><router-link :to="{ name: 'ForgetPassword' }" target="_blank" rel="noreferrer noopener">パスワードの再設定ページ</router-link>からパスワードを変更してください。</h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>登録しているメールアドレスを忘れてしまい、ログイン出来ません。</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>
                <router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">お問い合せページ</router-link>より、アカウントのアドレスを忘れてしまった旨をご記載いただきご送信ください。<br />
                後日、弊社よりお問い合せ時にご入力いただいたアドレスに、メールを送信させていただきます。
              </h4>
            </div>
          </div>
        </div>
        <div class="mt-40px">
          <div class="d-flex">
            <div class="faq__content__square question">
              <h3>Q</h3>
            </div>
            <div class="faq__content__title">
              <h3>API連携したいのですが、可能ですか？</h3>
            </div>
          </div>
          <div class="d-flex">
            <div class="faq__content__square answer">
              <h3>A</h3>
            </div>
            <div class="faq__content__text">
              <h4>一般プランでは公開していない為、弊社までお問い合わせ下さい。</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Faq",
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.faq {
  margin-bottom: 120px;
  & a {
    color: map-get($colors, "blue400");
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      font-weight: 700;
    }
  }
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    margin: 0 20px;
    & h1 {
      color: map-get($colors, "blue400");
    }
    &__square {
      width: 48px;
      height: 48px;
      border-radius: 5px;
      margin: 10px 10px 10px 0;
      display: flex;
      align-items: center;
      & h3 {
        color: white;
        text-align: center;
        width: 48px;
      }
    }
    & .question {
      background-color: map-get($colors, "blue400");
    }
    & .answer {
      background-color: map-get($colors, "blue300");
    }
    &__title {
      width: fit-content;
      display: flex;
      align-items: center;
      & h3 {
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
    }
    &__text {
      width: fit-content;
      display: flex;
      align-items: flex-start;
      padding-top: 12px;
    }
  }
}
</style>
