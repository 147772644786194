import { createStore } from "vuex";
import users from "@/store/users";
import influencers from "@/store/influencers";
import companies from "@/store/companies";
import members from "@/store/members";
import signIn from "@/store/signIn";
import signUp from "@/store/signUp";
import verificationEmail from "@/store/verificationEmail";
import tasks from "@/store/tasks";
import prediction from "@/store/prediction";
import interests from "@/store/interests";
import languages from "@/store/languages";
import cities from "@/store/cities";
import countries from "@/store/countries";
import plans from "@/store/plans";
import invoices from "@/store/invoices";
import youtubeLocations from "@/store/youtubeLocations";
import notifications from "../store/notifications";
import inquiries from "../store/inquiries";
import hashtags from "@/store/hashtags";

export default createStore({
  modules: {
    users,
    influencers,
    plans,
    companies,
    members,
    signIn,
    signUp,
    verificationEmail,
    tasks,
    interests,
    languages,
    cities,
    countries,
    prediction,
    invoices,
    youtubeLocations,
    notifications,
    inquiries,
    hashtags,
  },
});
