<template>
  <div class="base-text-area">
    <div :class="[label ? 'base-text-area__label' : 'd-none']">
      {{ label }}<span v-if="required" v-show="required" class="required">必須</span>
    </div>
    <textarea :rows="rows" :cols="cols" v-model="text" :placeholder="placeholder" :readonly="readonly" :style="styles" ref="textArea" @change="handleText"></textarea>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive, toRefs } from "vue";

export default defineComponent({
  name: "BaseTextArea",
  props: {
    label: {
      type: String,
      default: "ラベル",
    },
    width: {
      type: Number,
      default: 480,
    },
    height: {
      type: Number,
      default: 80,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Number,
      default: 6,
    },
    cols: {
      type: Number,
      default: 40,
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    modelValue: {
      type: String as () => string | null,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const textArea = ref<HTMLTextAreaElement>();
    const state = reactive({
      text: props.modelValue,
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--width": props.width,
        "--height": props.height,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
      };
    });
    const handleText = () => {
      context.emit("update:modelValue", state.text);
    };
    return {
      ...toRefs(state),
      styles,
      handleText,
      textArea,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.required {
  margin-left: 14px;
  padding: 1px 10px;
  background-color: map-get($colors, "accent");
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 700;
}
.base-text-area {
  max-width: 100%!important;
  &__label {
    text-align: left;
    font-weight: 900;
    font-size: 13px;
    margin: 8px 10px 8px 0;
    color: map-get($colors, "gray300");
    max-width: 100%!important;
  }
  & textarea {
    resize: none;
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    max-width: 100%!important;
    @include mq(xs) {
      width: calc(var(--sm-width) * 1px);
      height: calc(var(--sm-height) * 1px);
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px);
      height: calc(var(--height) * 1px);
    }
    border-radius: 5px;
    padding: 14px 20px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: map-get($font-colors, "default")!important;
    border: 2px solid map-get($colors, "primary");
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px map-get($colors, "blue300");
    }
    &::placeholder {
      font-size: 14px;
      color: map-get($colors, "blue300");
    }
  }
}
</style>
