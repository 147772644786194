
import {
  defineComponent, reactive, toRefs,
} from 'vue'

export default defineComponent({
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      default: 'tooltip'
    },
    fillInnerColor: {
      type: String,
      default: 'white'
    },
    fillOuterColor: {
      type: String,
      default: 'dark'
    },
    size: {
      type: Number,
      default: 12
    },
    shadow: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const state = reactive({
      hoverFlag: false,
    });
    const mouseOverAction = () => state.hoverFlag = true;
    const mouseLeaveAction = () => state.hoverFlag = false;
    return {
      ...toRefs(state),
      mouseLeaveAction,
      mouseOverAction,
    }
  },
})
