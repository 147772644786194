
import {defineComponent} from "vue";
export default defineComponent ({
  name: "AccountListIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff"
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

