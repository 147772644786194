
import { defineComponent } from "vue";
export default defineComponent ({
  name: "QuestionIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db"
    },
    size: {
      type: Number,
      default: 20
    },

    rounded: {
      type: Boolean,
      default: false
    }
  }
})

