<template>
  <div class="flow-card">
    <div class="flow-card__inner">
      <div class="flow-card__number">{{ flowNumber }}</div>
      <div class="flow-card__title mt-10px">
        <slot name="title"></slot>
      </div>
      <div class="flow-card__border" />
      <slot name="description"></slot>
    </div>
    <div :class="[request ? 'request' : 'd-none']">
      INFRECTに<br />
      ご依頼OK
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent ({
  name: "FlowCard",
  props: {
    flowNumber: {
      type: Number,
      default: 1,
    },
    request: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.flow-card {
  width: 186px;
  height: 280px;
  padding: 20px 14px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  border: 2px solid map-get($colors, "blue300");
  @include mq(xs) {
    min-width: 186px;
  }
  @include mq(lg) {
    min-width: 186px;
  }
  &__number {
    margin: 0 auto;
    background-color: map-get($colors, "blue300");
    width: 48px;
    height: 48px;
    line-height: 42px;
    border: none;
    border-radius: 26px;
    text-align: center;
    font-size: 36px;
    color: white;
    font-weight: 700;
  }
  &__title {
    height: 48px;
    width: 100%;
    display: table;
  }
  &__border {
    height: 2px;
    background-color: map-get($colors, "gray200");
    margin: 5px 0 10px;
  }
  & .request {
    position: absolute;
    top: -40px;
    right: -20px;
    background-color: white;
    border: 4px solid map-get($colors, "accent");
    padding: 21px 10px;
    border-radius: 60px;
    box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 10px;
    color: map-get($colors, "accent");
    font-weight: 700;
    transform:rotate(20deg);
  }
}
</style>