<template>
  <div class="base-container hashtag-search">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header></base-header>
      <div>
        <div class="search">
          <div class="search__contents">
            <h1>AI検索（ベータ版）</h1>
            <h3>商品・サービスの内容からAIが適切なインフルエンサーを検索します</h3>
            <multiple-toggle :items="samples" v-model="selectedSampleIndex" selected-background="#8866ee" class="mt-20px mb-20px" @change="resetIndex"></multiple-toggle>
            <base-text-area label="" :height="200" :width="1000" :sm-width="280" readonly disabled :model-value="data[selectedSampleIndex].text" :key="selectedSampleIndex"></base-text-area>
            <h5>※このページはサンプルです</h5>
            <h5>※実際にAI検索に商品・サービス内容を入力して得られた結果になります。</h5>
            <h5>※結果は再現性があるわけではございません。</h5>
            <router-link :to="{name: 'AISearch'}">
              <base-button class="mt-20px" size="wide" color="accent">AI検索に戻る</base-button>
            </router-link>
          </div>
        </div>
        <div class="search-result">
          <div class="d-flex mt-40px mb-10px align-items-end">
            <h3 class="mr-10px">検索結果</h3>
          </div>
          <div class="result__container">
            <div class="result__section">
              <h3>この商品・サービスに共感できる人のトライブ</h3>
              <div class="result__section__content">
                <multiple-toggle :items="data[selectedSampleIndex].personas" :selectable="false" background="#8866ee">
                  <template #item="{ item }">
                    <div style="display: flex; align-items: baseline;">
                      <div style="width: 20px"><img src="@/assets/img/anno_user.svg" style="width: 100%; vertical-align: sub" /></div>
                      <div style="margin-left: 10px;">{{ item }}</div>
                    </div>
                  </template>
                </multiple-toggle>
              </div>
            </div>
            <div class="result__section">
              <h3>トライブと関係のあるキーワード</h3>
              <div class="result__section__content">
                <h4 class="mb-10px">キーワード:</h4>
                <multiple-toggle :items="data[selectedSampleIndex].keywords" :model-value="selectedKeywordIndex"></multiple-toggle>
              </div>
              <div class="result__section__content mt-20px">
                <h4 class="mb-10px">SNSの種類:</h4>
                <multiple-toggle :items="SNSTypes" :model-value="selectedKeywordSNSIndex" selected-background="orange">
                  <template #item="{ item }">
                    <div class="d-flex align-items-center" v-if="item === 'Instagram'">
                      <instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'Twitter'">
                      <twitter-icon :size="20" fill-color="white" class="mr-5px" /><span>Twitter</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'TikTok'">
                      <tiktok-icon :size="20" fill-color="white" class="mr-5px" /><span>TikTok</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'YouTube'">
                      <youtube-icon :size="20" fill-color="white" class="mr-5px" /><span>YouTube</span>
                    </div>
                    <div class="d-flex align-items-center" v-else>
                      <span>{{ item }}</span>
                    </div>
                  </template>
                </multiple-toggle>
              </div>
              <div class="result__section__content">
                <h4 class="mt-10px">インフルエンサー:</h4>
                <div class="search-button">ここにインフルエンサーの一覧が表示されます</div>
              </div>
            </div>
            <div class="result__section">
              <h3>トライブと関係のあるハッシュタグ</h3>
              <div class="result__section__content">
                <multiple-toggle :items="data[selectedSampleIndex].hashtags" :model-value="selectedHashtagIndex">
                  <template #item="{ item }">
                    # {{ item }}
                  </template>
                </multiple-toggle>
                <div class="result__section__content mt-20px">
                  <multiple-toggle :items="HashtagSNSTypes" :model-value="selectedHashtagSNSIndex" selected-background="orange">
                    <template #item="{ item }">
                      <div class="d-flex align-items-center" v-if="item === 'Instagram'">
                        <instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'Twitter'">
                        <twitter-icon :size="20" fill-color="white" class="mr-5px" /><span>Twitter</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'TikTok'">
                        <tiktok-icon :size="20" fill-color="white" class="mr-5px" /><span>TikTok</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'YouTube'">
                        <youtube-icon :size="20" fill-color="white" class="mr-5px" /><span>YouTube</span>
                      </div>
                      <div class="d-flex align-items-center" v-else>
                        <span>{{ item }}</span>
                      </div>
                    </template>
                  </multiple-toggle>
                </div>
                <div class="result__section__content">
                  <h4 class="mt-10px">インフルエンサー:</h4>
                  <div class="search-button">ここにインフルエンサーの一覧が表示されます</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MultipleToggle from "@/components/MultipleToggle.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";

export default defineComponent({
  name: "AISearchSample",
  components: {
    MultipleToggle,
    BaseTextArea,
    BaseButton,
    Sidebar,
    BaseHeader,
    InstagramIcon,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
  },
  setup() {
    const state = reactive({
      selectedKeywordIndex: 0,
      selectedKeywordSNSIndex: 0,
      selectedHashtagIndex: 0,
      selectedHashtagSNSIndex: 0,
      selectedSampleIndex: 0,
      samples: [
          "サンプル1: INFRECT",
          "サンプル2: ソロキャンパー用テント",
          "サンプル3: 森の図書館",
      ],
      data: [
        {
          text: "INFRECTとはAIとインフルエンサーで御社の商品・サービスが売れ続ける仕組みを内製化できるツールです。\nInstagram・YouTube・TikTok・Twitterで活躍するインフルエンサーの検索・分析からお仕事の共創までまるごとサポート！\nインフルエンサーの検索・分析・効果検証・レポート作成などの数日かかる業務の工数をINFRECTを活用することで大幅に削減することが出来ます。\n【AIによるインフルエンサーの検索】\nニーズに合わせたインフルエンサーを、「簡単」にかつ「高精度」でスピーディーに探し出すことが出来ます。\n【AIが抽出する分析レポート】\nSNSや、従来のインフルエンサー分析ツールでは得られない情報を取得できるので、高度な分析の情報をもとに適切なインフルエンサーをビジネスに登用出来ます。\n【AIによるいいね分析機能】\n弊社が開発した最新鋭のAIが、実在するインフルエンサーの情報や、プロモーションでお使いいただく画像、投稿文章、投稿時間を用いてInstagramで獲得できるいいね数を予測いたします。\n【インフルエンサーとのビジネスをサポート】\nインフルエンサーのリストアップからアサイン交渉まで弊社が代行することもできます！自社で工数をかけずにインフルエンサーとのお仕事を実現できます。",
          personas: [
            "SNSを積極的に利用するビジネスマン・ビジネスウーマン",
            "自社の商品・サービスを広く知ってもらいたいマーケター",
            "インフルエンサーマーケティングを取り入れたい企業経営者",
            "インフルエンサーマーケティングに興味のある学生やフリーランス",
            "インフルエンサーマーケティングをより効率的に行いたいマーケティング担当者"
          ],
          keywords: [
            "ビジネス",
            "マーケティング",
            "起業",
            "セルフィー",
            "コスメ",
            "旅行",
            "モデル",
            "ファッション",
            "グルメ",
            "カフェ",
            "音楽",
            "映画",
            "スポーツ",
            "アウトドア",
            "ペット",
            "アート",
            "おしゃれ",
            "自然",
            "くらし",
            "健康"
          ],
          hashtags: [
            "いいね返し",
            "いいねした人で気になった人フォロー",
            "フォローしてくれた人全員フォローする",
            "フォローミー",
            "フォロー返し",
            "l4l",
            "like4like",
            "フォトジェニック",
            "お洒落さんと繋がりたい",
            "カフェ巡り",
            "美味しいもの",
            "旅行",
            "インスタ映え",
            "今日のコーデ",
            "コスメ好きな人と繋がりたい",
            "おしゃれさんと繋がりたい",
            "美容好きな人と繋がりたい",
            "コスメレポ",
            "メイク好きな人と繋がりたい"
          ],
        },
        {
          text: "「ソロキャンパー3000」は、1人用のテントで、ソロキャンプに特化した設計となっています。広々とした空間を提供し、ポリエステル素材を使用しているため、耐久性が高く防水性も優れています。\n" +
              "セットアップが簡単で、1人でも10分程度で組み立てることができます。夜間でも明るい内部照明が備わっており、暗闇でも快適に過ごせます。また、通気口によって蒸れた空気を排出し、快適な空気の流れを確保しています。\n" +
              "外部には2つのポケットがあり、貴重品や小物の収納に便利です。コンパクトに収納することができ、持ち運びにも便利です。\n" +
              "定員は1人で、サイズは210cm（長さ） x 100cm（幅） x 100cm（高さ）、重量は2kg以下と軽量です。強風や悪天候時にはご使用にならないでください。組み立ての際には、説明書をよく読んで正しく設置してください。ポケットに貴重品を入れる場合は、慎重に取り扱ってください。\n" +
              "「ソロキャンパー3000」は、1人でのキャンプを快適に過ごすための優れた選択肢です。",
          personas: [
            "アウトドアが好きで、特にソロキャンプを楽しむ人",
            "手軽にテントを組み立てたい人",
            "防水性が高く、耐久性に優れたテントを探している人",
            "小物や貴重品を収納したい人",
            "コンパクトに収納して持ち運びたい人",
            "内部照明があるテントを探している人",
            "十分な通気性があるテントを探している人"
          ],
          keywords: [
            "アウトドア",
            "キャンプ",
            "テント",
            "防水",
            "耐久",
            "ポケット",
            "内部照明",
            "通気性",
            "コンパクト"
          ],
          hashtags: [
            "ソロキャンプ",
            "キャンプグッズ",
            "アウトドアギア",
            "テント",
            "ソロキャンプグッズ",
            "キャンプ好きな人と繋がりたい",
            "アウトドアライフ",
            "キャンプライフ",
            "キャンプ女子",
            "キャンプ初心者"
          ]
        },
        {
          text: "「森の図書館」とは、森の中にある図書館であり、自然と本の世界が融合した環境が特徴的です。\n" +
              "\n" +
              "まず、森の中にあるため、周囲には木々や草花が豊富にあり、季節ごとに美しい景色が楽しめます。図書館自体も、木造の建物であり、外観は森に調和したデザインとなっています。\n" +
              "\n" +
              "入館すると、まず目につくのは、大きな窓から差し込む自然光です。天井も高く、開放感があり、森の中にいるかのような感覚を味わえます。書棚には、自然や環境問題に関する書籍や、自然の中で過ごすためのアウトドアガイドなどが豊富に揃っています。\n" +
              "\n" +
              "また、図書館には、森をテーマとしたイベントや講演会が頻繁に開催され、専門家や環境保護団体などから、自然や環境問題についての知識を深めることができます。\n" +
              "\n" +
              "さらに、図書館の周囲には、トレッキングコースやキャンプ場、バードウォッチングポイントなどが整備されており、自然と本を組み合わせた充実した体験ができる場所となっています。\n" +
              "\n" +
              "森の図書館は、自然と本を融合させ、人々により身近な存在として環境問題への関心を高めることができる、素晴らしい場所です。",
          personas: [
            "自然愛好家",
            "本好き",
            "アウトドア体験が好き",
            "環境問題に関心がある",
            "トレッキングやバードウォッチングが好き",
            "緑豊かな場所が好き",
            "開放的な場所が好き",
            "知的好奇心が旺盛",
            "イベントや講演会に参加することが好き",
            "新しい体験を楽しみたい人"
          ],
          keywords: [
            "旅行",
            "コスメ",
            "ファッション",
            "フード",
            "ダイエット",
            "ヨガ",
            "スポーツ",
            "映画",
            "音楽",
            "アート",
            "読書",
            "ペット",
            "ハンドメイド",
            "グルメ",
            "カフェ巡り",
            "美容",
            "健康",
            "おしゃれ",
            "リラックス",
            "恋愛",
            "結婚",
            "出産",
            "子育て",
            "引っ越し",
            "仕事",
            "実家暮らし",
            "一人暮らし",
            "ペアルック",
            "ファミリールック",
            "サステイナブル",
            "エコロジー",
            "リユース",
            "ビーガン",
            "ヴィーガン",
            "動物愛護",
            "フェアトレード"
          ],
          hashtags: [
            "自然好きな人と繋がりたい",
            "アウトドアライフ",
            "緑の中で",
            "自然との一体感",
            "環境問題に考える",
            "トレッキング",
            "バードウォッチング",
            "森の図書館",
            "本と自然の融合",
            "新しい体験",
            "イベント参加",
            "知的好奇心",
            "読書好き",
            "緑豊かな場所",
            "開放的な場所"
          ],
        },
      ],
      SNSTypes: [
        "Instagram",
        "YouTube",
        "TikTok",
        "Twitter",
      ],
      HashtagSNSTypes: [
        "Instagram",
        "Twitter",
      ]
    });
    const resetIndex = () => {
      state.selectedHashtagIndex = 0;
      state.selectedKeywordIndex = 0;
      state.selectedKeywordSNSIndex = 0;
      state.selectedHashtagSNSIndex = 0;
    }
    return {
      ...toRefs(state),
      resetIndex,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/img/ai-search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  &__contents {
    padding: 50px 0;
    @include mq(xs) {
      padding: 40px 0 60px 0;
    }
    @include mq(lg) {
      padding: 50px 0;
    }
    & h1 {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 18px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    & h3 {
      color: white;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 40px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 20px;
      }
      @include mq(lg) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    &__buttons {
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;
      & svg {
        margin-top: 2px;
      }
      & .button {
        width: 140px;
        @include mq(xs) {
          width: 60px;
        }
        @include mq(lg) {
          width: 140px;
        }
      }
      & span {
        display: inline-block;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: inline-block;
        }
      }
    }
    &__area {
      & input {
        width: 580px;
        height: 60px;
        border: 2px solid white;
        border-radius: 5px 0 0 5px;
        font-size: 18px;
        font-weight: 700;
        color: map-get($font-colors, "default");
        padding: 0 20px;
        @include mq(xs) {
          width: 90%;
          font-size: 14px;
        }
        @include mq(lg) {
          width: 580px;
          font-size: 18px;
        }
        &:focus {
          border: 2px solid map-get($colors, "accent");
          outline: none;
        }
        &::placeholder {
          color: map-get($colors, "gray200");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 18px;
          }
        }
      }
      &__button {
        width: 60px;
        height: 60px;
        background-color: #ee7382;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        &:hover {
          background-color: #efabb4;
        }
      }
      &__error {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
    }
  }
}
.result {
  &__container {
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    & h3 {
      & > span {
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    & .loading {
      width: 80px;
      margin: 40px auto;
    }
  }
  &__section {
    margin-bottom: 80px;
    & h3 {
      margin-bottom: 20px;
    }
  }
  &__relation {
    & h4 {
      font-weight: 900;
      margin: 30px 0 10px;
    }
    & h5 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    &__ranking {
      width: 80%;
      margin: 0 auto;
      &__table {
        width: 49%;
        min-width: 300px;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: 49%;
        }
        &__wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          @include mq(xs) {
            display: block;
          }
          @include mq(lg) {
            display: flex;
          }
        }
        &__no-data {
          width: 100%;
          min-width: 300px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #9c9c9c;
          margin: 20px 0;
        }
      }
    }
    &__relations {
      width: 80%;
      margin: 20px auto 0 auto;
      &__graph {
        width: 800px;
        border: solid 8px map-get($colors, "gray200");
        border-radius: 8px;
        margin: 0 auto;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: calc(100% - 20px);
        }
        &__no-data {
          width: 100%;
          height: 100%;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          vertical-align: center;
          color: map-get($colors, "gray200");
          margin: 80px 0;
        }
      }
    }
  }
}
.result-none {
  width: 100%;
  background: white;
  padding: 120px 0;
  border-radius: 10px;
  height: fit-content;
  text-align: center;
  align-items: center;
  @include mq(xs) {
    padding: 60px 0;
    margin-bottom: 40px;
  }
  @include mq(lg) {
    padding: 120px 0;
    margin-bottom: auto;
  }
  & h1,
  h3 {
    color: map-get($colors, "primary");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.influencer-detail {
  width: 100%;
  color: map-get($font-colors, "default");
  &__post {
    width: calc(50% - 50px);
    padding: 20px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    @include mq(xs) {
      width: calc(100% - 30px);
      height: 895px;
    }
    @include mq(lg) {
      width: calc(50% - 50px);
      height: 705px;
    }
    &__post__card {
      display: flex;
      width: 100%;
      @include mq(xs) {
        display: block;
        height: fit-content;
      }
      @include mq(lg) {
        display: flex;
        height: calc(100% - 160px);
      }
    }
    &__account {
      &__card {
        display: flex;
        align-content: space-between;
        width: 100%;
        height: 60px;
        margin: 20px auto 20px 0;
      }
      &__profile {
        width: 100%;
        display: flex;
        & > img {
          width: 60px;
          height: 60px;
          display: block;
          border-radius: 9999px;
        }
        &__name {
          margin-left: 10px;
          & h4 {
            font-weight: 700;
          }
        }
      }
      &__actions {
        width: 50%;
        text-align: right;
      }
    }
    &.twitter {
      display: block;
      height: 700px;
      padding: 20px;
      margin: 0 20px 30px 0;
      @include mq(xs) {
        width: calc(100% - 40px);
      }
      @include mq(lg) {
        width: calc(30% - 20px);
      }
      & iframe {
        height: 600px;
        margin: 0 auto;
      }
      &:nth-of-type(2n) {
        margin-right: 20px;
      }
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: flex;
      }
    }
    &__thumbnail {
      margin-right: 20px;
      width: 300px;
      height: 600px;
      position: relative;
      border-radius: 10px;
      background-image: url("../assets/img/post-background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 16px;
      @include mq(xs) {
        margin: auto;
      }
      @include mq(lg) {
        margin: 0 20px 0 0;
      }
      & iframe {
        width: 300px !important;
        height: 600px !important;
        border: 2px solid #f7f7f7;
        border-radius: 10px;
      }
    }
    &__content {
      position: relative;
      width: calc(100% - 320px);
      @include mq(xs) {
        width: 100%;
      }
      @include mq(lg) {
        width: calc(100% - 220px);
      }
      &__date {
        font-size: 12px;
        font-weight: 700;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 0;
        }
      }
      &__text {
        overflow: scroll;
        @include mq(xs) {
          height: 135px;
        }
        @include mq(lg) {
          height: 100%;
        }
        &__wrapper {
          font-size: 13px;
          height: 100%;
          margin-top: 10px;
        }
      }
      &__engagements {
        display: flex;
        bottom: 0;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 10px;
        }
      }
      &__engagement {
        display: flex;
        font-size: 14px;
        font-weight: 900;
        margin-right: 20px;
        & img {
          width: 10px;
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      & .likes {
        color: #ffa0a0;
      }
      & .comments {
        color: #79b5db;
      }
    }
  }
}
.search-button {
  width: 80%;
  margin: 20px auto;
  padding: 14px;
  background: #f75977;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-weight: 500;
}
</style>
