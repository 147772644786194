<template>
  <div class="edit-icon">
    <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 27 24" @click="show = true">
      <path
        d="M18.872,4,23.1,8.223a.458.458,0,0,1,0,.647L12.862,19.108l-4.35.483A.912.912,0,0,1,7.5,18.583l.483-4.35L18.225,4a.458.458,0,0,1,.647,0Zm7.594-1.073L24.178.634a1.833,1.833,0,0,0-2.588,0L19.931,2.294a.458.458,0,0,0,0,.647l4.228,4.228a.458.458,0,0,0,.647,0l1.659-1.659A1.833,1.833,0,0,0,26.466,2.922ZM18,16.323V21.1H3V6.1H13.772a.576.576,0,0,0,.4-.164l1.875-1.875a.563.563,0,0,0-.4-.961H2.25A2.251,2.251,0,0,0,0,5.345v16.5A2.251,2.251,0,0,0,2.25,24.1h16.5A2.251,2.251,0,0,0,21,21.845v-7.4a.564.564,0,0,0-.961-.4l-1.875,1.875A.576.576,0,0,0,18,16.323Z"
        transform="translate(0 -0.1)"
        :fill="fillColor"
      />
    </svg>
    <div class="edit-icon__dialog" v-show="show">
      <div class="edit-icon__dialog__container">
        <div class="edit-icon__dialog__content">
          <div class="title">フォルダ名を変更</div>
          <div class="form">
            <div class="name mt-20px d-flex">
              <base-text-field v-model="folderName" label="フォルダ名" :width="240"></base-text-field>
            </div>
          </div>
          <div class="buttons d-flex justify-content-center mt-40px">
            <base-button :size="'short'" class="mr-20px" @click="handleEdit">変更</base-button>
            <base-button :size="'short'" :color="'dark'" @click="show = false">キャンセル</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs, watch} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";

export default defineComponent({
  name: "EditFolderIcon",
  components: {
    BaseTextField,
    BaseButton,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
    },
    modelValue: {
      type: String as () => string | null,
      default: null,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      formErrorMessage: null as string | null,
      folderName: props.modelValue,
      updateInfluencerListSuccess: computed(() => store.state.influencers.updateInfluencerListSuccess),
    });
    const handleEdit = () => {
      context.emit("confirmEdit");
    };
    const handleInput = () => {
      context.emit("confirmInput");
    };
    let folderName = computed(() => state.folderName);
    watch(folderName, (val: null | string) => {
      context.emit("update:modelValue", val);
    });
    let updateInfluencerListSuccess = computed(() => state.updateInfluencerListSuccess);
    watch(updateInfluencerListSuccess, (val: boolean) => {
      if (val) state.show = false;
    });
    return { ...toRefs(state), handleEdit, handleInput };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.edit-icon {
  & svg {
    &:hover {
      opacity: 0.5;
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 400px;
      padding: 40px 100px;
      top: 100px;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      & .title {
        font-size: 18px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        text-align: center;
      }
      & .form {
        margin: 0 auto;
        width: fit-content;
      }
      & .label {
        width: 100px;
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
    }
  }
}
</style>
