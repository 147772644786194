
import { defineComponent, reactive, toRefs, computed, ref, onMounted, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { useStore } from "vuex";
import { Plan, Company } from "@/types";
import BaseStripeCheckout from "@/components/BaseStripeCheckout.vue";
import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: "PlanCard",
  components: { BaseButton, ExclamationMark, CheckMark, BaseStripeCheckout, Loading },
  props: {
    enableSubscriptionLink: {
      type: Boolean,
      default: false,
    },
    hasCompanyAdminRole: {
      type: Boolean,
      default: false,
    },
    scrollOffset: {
      type: Number,
      default: 0,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const planCardRef = ref<HTMLElement>();
    store.dispatch("plans/getPlans");
    if (props.enableSubscriptionLink) store.dispatch("users/loadCurrentUser");
    const state = reactive({
      showPurchase: false,
      showChange: false,
      showConfirmChange: false,
      showCancel: false,
      showNotCancel: false,
      showNotChange: false,
      showConfirmCancel: false,
      selectedPlan: null as null | Plan,
      displayPlans: [] as Plan[],
      plans: computed(() => store.state.plans.plans),
      loadPlansSuccess: computed(() => store.state.plans.loadPlansSuccess),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.plans.loading),
      loadSessionSuccess: computed(() => store.state.plans.loadSessionIdSuccess),
      loadSessionErrorMessage: computed(() => store.state.plans.errorMessage),
      cancelPlanSuccess: computed(() => store.state.plans.cancelPlanSuccess),
    });
    onMounted(() => {
      if (!props.disableScroll) {
        window.addEventListener("scroll", () => {
          if (planCardRef.value?.getBoundingClientRect().top && planCardRef.value?.getBoundingClientRect().bottom && state.plans && planCardRef.value?.getBoundingClientRect().top < props.scrollOffset && planCardRef.value?.getBoundingClientRect().bottom >= props.scrollOffset) {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "position: absolute; width: 100%; top: " + (props.scrollOffset - planCardRef.value?.getBoundingClientRect().top) + "px; z-index: 5; border: solid 2px white; opacity: 0.9;");
              planCardRef.value?.children[i].children[1].setAttribute("style", "margin-top: 120px;");
            }
          } else if (planCardRef.value?.getBoundingClientRect().bottom && state.plans && planCardRef.value?.getBoundingClientRect().bottom < props.scrollOffset) {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "position: absolute; width: 100%; top: " + planCardRef.value?.clientHeight + "px; z-index: 5; border-radius: 0 0 10px 10px;");
              planCardRef.value?.children[i].children[1].setAttribute("style", "margin-top: 120px;");
            }
          } else {
            for (let i = 1; i <= state.plans?.length; i++) {
              planCardRef.value?.children[i].children[0].setAttribute("style", "");
              planCardRef.value?.children[i].children[1].setAttribute("style", "");
            }
          }
        });
      }
    });
    const displayLimitation = (limitation: number, before = "", after = "回まで", noDataVal = "×"): string => {
      if (limitation < 0) return "無制限";
      else if (limitation == 0) return noDataVal;
      else return before + String(limitation.toLocaleString()) + after;
    };
    const purchasePlan = (plan: Plan) => {
      state.selectedPlan = plan;
      state.showPurchase = true;
    };
    const changePlan = (plan: Plan) => {
      state.selectedPlan = plan;
      if (state.company?.isCancelablePlan) {
        state.showChange = true;
      } else {
        state.showNotChange = true;
      }
    };
    const confirmChangePlan = () => {
      store.dispatch("plans/getSessionId", state.selectedPlan?.id);
      state.showConfirmChange = true;
    };
    const cancelPlan = () => {
      if (state.company?.isCancelablePlan) {
        state.showCancel = true;
      } else {
        state.showNotCancel = true;
      }
    };
    const confirmCancelPlan = () => {
      store.dispatch("plans/cancelPlan");
      state.showConfirmCancel = true;
    };
    const submitBackBtn = () => {
      window.location.reload();
    };
    const cancelPurchase = () => {
      window.location.reload();
    };
    let plans = computed(() => state.plans);
    watch(plans, (val: Plan[]) => {
      state.displayPlans = val.filter((x: Plan) => {
        if (x.isPublic) return true;
        return x.id === state.company?.plan?.id;
      });
    });
    let company = computed(() => state.company);
    watch(company, (val: Company) => {
      state.displayPlans = state.plans.filter((x: Plan) => {
        if (x.isPublic) return true;
        return x.id === val?.plan?.id;
      });
    });
    const getPlanColor = (plan: Plan) => {
      if (plan?.id === state.company?.plan?.id) {
        return "gray";
      } else if (plan?.name === "アドバンスドプラン") {
        return "orange";
      } else {
        return "blue";
      }
    };
    return {
      ...toRefs(state),
      displayLimitation,
      purchasePlan,
      changePlan,
      confirmChangePlan,
      cancelPlan,
      submitBackBtn,
      confirmCancelPlan,
      cancelPurchase,
      planCardRef,
      getPlanColor,
    };
  },
});
