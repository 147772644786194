import axios from "@/store/axios";
import { Commit } from "vuex";
import { YoutubeLocation } from "@/types";

interface Location {
  key: number;
  text: string;
}

interface State {
  errorMessage: string | null;
  locations: Location[];
}

const state: State = {
  errorMessage: null,
  locations: [],
};

const youtubeLocations = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setYoutubeLocations(state: State, locations: Location[]) {
      state.locations = locations;
    },
  },
  actions: {
    async getLocations({ commit }: { commit: Commit }) {
      try {
        const res = await axios.get("api/v1/youtube-locations/");
        const locations = res.data.slice().map(( x: YoutubeLocation ) => {
          const params = {
            key: x.id,
            text: x.jpName ? x.jpName : x.name,
          };
          return params;
        });
        commit("setYoutubeLocations", locations);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default youtubeLocations;
