
import { defineComponent } from "vue";
export default defineComponent ({
  name: "AccountEditIcon",
  props: {
      fillColor: {
        type: String,
        default: "#fff"
      },
      size: {
        type: Number,
        default: 48
      },
      rounded: {
        type: Boolean,
        default: false
      }
    }
})

