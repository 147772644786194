<template>
  <div class="base-container">
    <sidebar :current-tab="1"></sidebar>
    <div class="main" v-show="company">
      <base-header :header-title="'詳細検索でインフルエンサーを探す'" display-type="SEARCH" :key="setDetailSearchSuccess"></base-header>
      <div class="dialog__container" v-if="loading || errorMessage">
        <div class="dialog" v-show="loading">
          <div class="title">検索中です</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>表示されるまでしばらくお待ちください</h4></div>
        </div>
        <div class="dialog" v-show="errorMessage">
          <div class="title">データの取得に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>検索回数は消費されません。<br />お手数をおかけしますが、ページをリロードしてください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="closeLoadingDialog">戻る</base-button></div>
        </div>
      </div>
      <div class="advanced-search" v-if="company?.isExpired === false">
        <div class="advanced-search__contents d-flex">
          <div class="advanced-search__contents__container">
            <div class="advanced-search__contents__container__row">
              <base-select-box label="SNSの種類" class="mr-20px" :width="166" :sm-width="260" item-value="key" item-text="text" :items="snsTypes" v-model="form.snsType" return-object disable-lookup required></base-select-box>
              <base-text-field label="最小フォロワー数" class="mr-20px" :width="126" :sm-width="260" v-model="form.minFollower"></base-text-field>
              <base-text-field label="最大フォロワー数" class="mr-20px" :width="126" :sm-width="260" v-model="form.maxFollower"></base-text-field>
              <base-text-field label="エンゲージメント率(%)" v-model="form.engagementRate" :width="126" :sm-width="260" :placeholder="'0~100'"> </base-text-field>
            </div>
            <div class="advanced-search__contents__container__row">
              <base-text-field label="関連するキーワード" :width="695" :sm-width="260" placeholder="スイーツ好き サッカー" class="mr-40px" v-model="form.keyword" multiple></base-text-field>
            </div>
            <div class="advanced-search__contents__container__row">
              <combo-box label="ハッシュタグ（ベータ版）" :width="695" :sm-width="260" placeholder="ファッション ootd" class="mr-40px" v-model="form.hashtags" multiple chips deletable-chips @update:inputValue="handleHashtagChange" store-state-name="hashtags" store-name="hashtags" v-if="form.snsType?.key === 'instagram' || form.snsType?.key === 'twitter'" v-show="form.snsType?.key === 'instagram' || form.snsType?.key === 'twitter'"></combo-box>
            </div>
            <div class="advanced-search__contents__container__row">
              <base-select-box label="並び替え" class="mr-20px" :width="186" :sm-width="260" item-value="value" item-text="name" :items="sort" v-model="form.sort" return-object disable-lookup></base-select-box>
              <base-select-box label="並び順" :width="166" :sm-width="260" :items="directions" v-model="form.direction" :key="directions" item-value="value" item-text="name" return-object disable-lookup></base-select-box>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center error-message" v-if="errorMessage || formErrorMessage">
          <h4>{{ errorMessage || formErrorMessage }}</h4>
        </div>
        <div class="d-flex justify-content-center">
          <base-button size="large_wide" color="accent" class="mt-40px" @click="submitDetailSearch(1)"> インフルエンサーを探す </base-button>
        </div>
        <div class="advanced-search__search-result" v-if="total || total === 0">
          <div class="d-flex mt-40px mb-10px align-items-end">
            <h3>検索結果：</h3>
            <h4 class="advanced-search__search-result__num">
              <span>{{ total?.toLocaleString() }}</span>
              人（{{ currentPage }}ページ目）
            </h4>
          </div>
          <base-table v-if="total > 0 ? (show.influencer = true) : ''" :items="getInfluencers()"></base-table>
          <div class="result-none" v-else>
            <h1 class="mb-30px">
              該当するインフルエンサーは<br />
              見つかりませんでした。
            </h1>
            <h3>検索条件を変更し検索をしてください。</h3>
          </div>
          <base-pagination :total="total" :total-pages="totalPages" :currentPage="currentPage" @move="submitPages"></base-pagination>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTable from "@/components/BaseTable.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { useStore } from "vuex";
import { DetailSearchParams, HashtagRequestPrams, Influencer } from "@/types";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BasePagination from "@/components/BasePagination.vue";
import ComboBox from "@/components/ComboBox.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AdvancedSearch",
  components: {
    ComboBox,
    BasePagination,
    BaseSelectBox,
    BaseTextField,
    BaseHeader,
    BaseTable,
    Sidebar,
    BaseButton,
    Loading,
    ExclamationMark,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("influencers/detailSearchInitialize");
    interface Item {
      key: string;
      text: string;
    }
    interface Sort {
      name: string;
      value: string;
    }
    interface Direction {
      name: string;
      value: string;
    }
    const snsTypes: Item[] = [
      { key: "instagram", text: "Instagram" },
      { key: "youtube", text: "YouTube" },
      { key: "tiktok", text: "TikTok" },
      { key: "twitter", text: "Twitter" },
    ];
    const sort: Sort[] = [
      {
        name: "フォロワー数",
        value: "followers",
      },
      {
        name: "エンゲージメント率",
        value: "engagement_rate",
      },
    ];
    const directions: Direction[] = [
      {
        name: "降順 (Z → A)",
        value: "desc",
      },
      {
        name: "昇順 (A → Z)",
        value: "asc",
      },
    ];
    const initialSNSType = router.currentRoute.value.query.sns;
    const initialSNS = snsTypes[0];
    if (initialSNSType) {
      snsTypes.forEach(({ key, text }) => {
        if (initialSNSType === key) {
          initialSNS.key = key;
          initialSNS.text = text;
        }
      });
    }
    const initialHashtag = router.currentRoute.value.query.hashtag;
    const initialKeyword = router.currentRoute.value.query.keyword;
    const state = reactive({
      show: {
        influencer: false,
      },
      listChecked: false,
      detailChecked: false,
      influencers: computed(() => store.state.influencers.influencers),
      company: computed(() => store.state.companies.company),
      total: computed(() => store.state.influencers.total),
      currentPage: computed(() => store.state.influencers.currentPage),
      success: computed(() => store.state.influencers.detailSearchSuccess),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      formErrorMessage: null as null | string,
      totalPages: computed(() => store.state.influencers.totalPages),
      hashtagItems: computed(() => store.state.hashtags.hashtags),
      form: {
        snsType: initialSNS,
        minFollower: null as null | number,
        maxFollower: null as null | number,
        engagementRate: null as number | null,
        keyword: initialKeyword && typeof initialKeyword === "string" ? initialKeyword : null as null | string,
        hashtags: initialHashtag && typeof initialHashtag === "string" ? [initialHashtag,] : [] as string[],
        sort: sort[0] as Sort,
        direction: directions[0] as Direction,
      },
    });
    const getInfluencers = () => {
      const form = state.form;
      const influencers = state.influencers?.slice();
      if (form.sort?.value == "engagement_rate") {
        influencers.sort((x: Influencer, y: Influencer) => x.engagementRate > y.engagementRate ? -1 : 1);
      }
      if (form.direction.value === "asc") {
        return influencers.reverse();
      } else {
        return influencers;
      }
    };
    const validate = () => {
      const form = state.form;
      if (form.minFollower && (form.minFollower < 0 || !Number.isInteger(Number(form.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        return false;
      } else if (form.maxFollower && (form.maxFollower < 0 || !Number.isInteger(Number(form.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        return false;
      } else if (form.maxFollower && form.minFollower && Number(form?.minFollower) > Number(form?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数以上である必要があります";
        return false;
      } else if (form.engagementRate && (form.engagementRate < 0 || (Number(form.engagementRate) !== 0 && !Number(form.engagementRate)))) {
        state.formErrorMessage = "エンゲージメント率は0以上である必要があります";
        return false;
      } else {
        return true;
      }
    };
    const submitDetailSearch = (page: number) => {
      if (!validate()) return false;
      store.dispatch("influencers/detailSearchInitialize");
      state.formErrorMessage = null;
      const form = state.form;
      const params: DetailSearchParams = {
        snsType: state.form.snsType.key,
      };
      if (form.sort) params.sort = form.sort.value;
      if (form.direction) params.direction = form.direction.value;
      if (page) params.page = page;
      if (form.minFollower) params.minFollowers = form.minFollower;
      if (form.maxFollower) params.maxFollowers = form.maxFollower;
      if (form.engagementRate) params.engagementRate = form.engagementRate / 100;
      if (form.keyword) params.keyword = form.keyword;
      if (form.hashtags) params.hashtags = form.hashtags;
      store.dispatch("influencers/detailSearch", params);
    };
    const submitPages = (page: number) => {
      return submitDetailSearch(page);
    };
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    const checkNextPage = (currentPage: number, total: number) => {
      return currentPage + 1 < Math.ceil(total / 15);
    };
    const handleHashtagChange = (e: null | string) => {
      if (e) {
        const params: HashtagRequestPrams = {
          snsType: state.form.snsType.key,
          hashtag: e,
        };
        store.dispatch("hashtags/getHashtags", { params });
      }
    };
    let setDetailSearchSuccess = computed(() => state.success);
    watch(setDetailSearchSuccess, () => {
      store.dispatch("users/loadCurrentUser");
    });
    return {
      ...toRefs(state),
      snsTypes,
      sort,
      directions,
      setDetailSearchSuccess,
      getInfluencers,
      submitDetailSearch,
      closeLoadingDialog,
      checkNextPage,
      submitPages,
      handleHashtagChange,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.no-function-container {
  width: 80%;
  padding: 80px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin-top: 60px;
  & h1 {
    color: map-get($colors, "primary");
    margin-bottom: 40px;
  }
}
.advanced-search {
  width: 100%;
  &__contents {
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    margin: 24px 0 0 0;
    &__container {
      padding: 24px;
      width: 80%;
      &__row {
        display: flex;
        &:nth-child(1) {
          margin-top: 0;
        }
        @include mq(xs) {
          display: block;
          margin-top: 0;
        }
        @include mq(lg) {
          display: flex;
          margin-top: 20px;
        }
      }
      &__title {
        margin: 20px 0 10px;
        color: map-get($colors, "blue400");
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  &__search-result {
    &__num {
      color: map-get($colors, "gray300");
      & span {
        color: map-get($colors, "blue400");
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  & .result-none {
    width: 100%;
    background: white;
    padding: 120px 0;
    border-radius: 10px;
    height: fit-content;
    text-align: center;
    align-items: center;
    & h1,
    h3 {
      color: map-get($colors, "primary");
    }
  }
}
.error-message {
  margin-top: 20px;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  & h4 {
    color: map-get($colors, "error");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90vw;
    padding: 20px;
    margin: auto;
    border: solid 2px map-get($colors, "primary");
    box-sizing: border-box;
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: solid 1px white;
    box-sizing: initial;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
