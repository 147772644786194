
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { set } from "vue-gtag";

export default defineComponent({
  name: "SearchHome",
  components: {
    Sidebar,
    BaseHeader,
    BaseButton,
  },
  setup() {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      currentSearchTab: 0,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    set({ userId: state.user?.id });
    const setCurrentSearchTab = (index: number) => {
      state.currentSearchTab = index;
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
    };
  },
});
