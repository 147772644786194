
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "PlanBanner",
  props: {
    displayType: {
      type: String,
      default: "SEARCH",
    },
  },
  setup() {
    const store = useStore();
    const state = reactive({
      plan: computed(() => store.state.companies.company?.plan),
      company: computed(() => store.state.companies?.company),
    });
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    return {
      ...toRefs(state),
      getSafeCount,
    };
  },
});
