
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import SearchIcon from "@/components/icon/SearchIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import ComboBox from "@/components/ComboBox.vue";
import { useStore } from "vuex";
import {
  ClickEvent,
  HashtagSearchRequestParams,
  Influencer,
  HashtagInfluencer,
  HashtagRelations, ChartPlug, HashtagRequestPrams, AddInfluencerListParams
} from "@/types";
import BaseTable from "@/components/BaseTable.vue";
import BasicTable from "@/components/BasicTable.vue";
import BasePagination from "@/components/BasePagination.vue";
import Loading from "@/components/Loading.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import { ChartData } from "chart.js";
import { BubbleChart } from "vue-chart-3";
import { set } from "vue-gtag";

export default defineComponent({
  name: "HashtagSearch",
  components: {
    Sidebar,
    BaseHeader,
    BasicTable,
    BaseTable,
    ComboBox,
    SearchIcon,
    BubbleChart,
    BaseButton,
    BasePagination,
    Loading,
    TwitterIcon,
    InstagramIcon,
    OpenDetailIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    const state = reactive({
      currentSearchTab: "instagram",
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      success: computed(() => store.state.influencers.usernameSearchSuccess),
      total: computed(() => store.state.influencers.total),
      formErrorMessage: null as string | null,
      hashtag: null as string | null,
      type: null as number | null,
      influencers: computed(() => store.state.influencers.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      searchUsernameSuccess: computed(() => store.state.influencers.usernameSearchSuccess),
      hashtagInfluencers: computed(() => store.state.influencers.hashtagInfluencers),
      hashtagPosts: computed(() => store.state.influencers.hashtagPosts),
      safeHashtagInfluencers: [] as Influencer[],
      hashtagItems: computed(() => store.state.hashtags.hashtags),
      hashtagRelation: computed(() => store.state.influencers.hashtagRelation),
      hashtagInfluencersSuccess: computed(() => store.state.influencers.hashtagInfluencersSuccess),
      hashtagPostsSuccess: computed(() => store.state.influencers.hashtagPostsSuccess),
      hashtagRelationSuccess: computed(() => store.state.influencers.hashtagRelationSuccess),
      hashtagInfluencersLoading: computed(() => store.state.influencers.hashtagInfluencersLoading),
      hashtagPostsLoading: computed(() => store.state.influencers.hashtagPostsLoading),
      hashtagRelationLoading: computed(() => store.state.influencers.hashtagRelationLoading),
      hashtagInfluencersErrorMessage: computed(() => store.state.influencers.hashtagInfluencersErrorMessage),
      hashtagPostsErrorMessage: computed(() => store.state.influencers.hashtagPostsErrorMessage),
      hashtagRelationErrorMessage: computed(() => store.state.influencers.hashtagRelationErrorMessage),
      currentHashtag: computed(() => store.state.influencers.currentHashtag),
      currentHashtagPostPage: computed(() => store.state.influencers.currentHashtagPostPage),
      totalHashtagPosts: computed(() => store.state.influencers.totalHashtagPosts),
      totalHashtagPostPages: computed(() => store.state.influencers.totalHashtagPostPages),
      currentHashtagInfluencerPage: computed(() => store.state.influencers.currentHashtagInfluencerPage),
      totalHashtagInfluencers: computed(() => store.state.influencers.totalHashtagInfluencers),
      totalHashtagInfluencerPages: computed(() => store.state.influencers.totalHashtagInfluencerPages),
      currentSnsType: null as null | string,
      chartData: [] as ChartData[],
      detailChecked: false,
    });
    set({ userId: state.user?.id });
    const setCurrentSearchTab = (index: string) => {
      state.currentSearchTab = index;
    };
    const searchHashtag = (event: ClickEvent) => {
      state.formErrorMessage = null;
      if (event.keyCode !== 229) {
        if (!state.hashtag) {
          state.formErrorMessage = "ハッシュタグは必須です";
        } else {
          state.currentSnsType = null;
          store.dispatch("influencers/hashtagSearchInitialize");
          const params: HashtagSearchRequestParams = {
            hashtag: state.hashtag,
            snsType: state.currentSearchTab,
            page: 1,
          };
          submitHashtagInfluencers(params);
          submitHashtagPosts(params);
          store.dispatch("influencers/getHashtagRelation", params);
          state.currentSnsType = params.snsType;
        }
      }
    };
    const submitHashtagInfluencers = (params: HashtagRequestPrams) => {
      store.dispatch("influencers/getHashtagInfluencers", params);
    };
    const submitHashtagPosts = (params: HashtagRequestPrams) => {
      store.dispatch("influencers/getHashtagPosts", params);
    };
    const submitHashtagInfluencersPages = (page: number) => {
      state.formErrorMessage = null;
      if (!state.currentHashtag) {
        state.formErrorMessage = "ハッシュタグは必須です";
      } else {
        const params: HashtagSearchRequestParams = {
          hashtag: state.currentHashtag,
          snsType: state.currentSearchTab,
          page: page,
        };
        submitHashtagInfluencers(params);
      }
    };
    const submitHashtagPostsPages = (page: number) => {
      state.formErrorMessage = null;
      if (!state.currentHashtag) {
        state.formErrorMessage = "ハッシュタグは必須です";
      } else {
        const params: HashtagSearchRequestParams = {
          hashtag: state.currentHashtag,
          snsType: state.currentSearchTab,
          page: page,
        };
        submitHashtagPosts(params);
      }
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const getJPDate = (val: string) => {
      const date = new Date(String(val).replace(/-/g, "/"));
      return Number(date.getFullYear()) + "年" + Number(date.getMonth() + 1) + "月" + Number(date.getDate()) + "日";
    };
    const getLocaleString = (val: number) => {
      return val ? val.toLocaleString() : 0;
    };
    const handleHashtagChange = (e: null | string) => {
      if (e) {
        const params: HashtagRequestPrams = {
          snsType: state.currentSearchTab,
          hashtag: e,
        };
        store.dispatch("hashtags/getHashtags", { params });
      }
    };
    let hashtagInfluencersSuccess = computed(() => state.hashtagInfluencersSuccess);
    watch(hashtagInfluencersSuccess, () => {
      store.dispatch("users/loadCurrentUser");
    });
    let hashtagInfluencers = computed(() => state.hashtagInfluencers);
    watch(hashtagInfluencers, (val: HashtagInfluencer[]) => {
      const influencers = [] as Influencer[];
      val.forEach((val: HashtagInfluencer) => {
        const influencer: Influencer = Object.assign({}, val.account);
        influencer.hashtagCount = val.count;
        influencers.push(influencer);
      });
      state.safeHashtagInfluencers = influencers;
    });
    const getChartData = (val: HashtagRelations) => {
      if (!val) return Object();
      const len = val.labels.length;
      const counts = val.counts.slice().map((x: number) => x / Number(Math.max(...val.counts)) * 50);
      const datasets = [];
      for (let i = 0; i < len; i++) {
        datasets.push({data: [{ x: val.x[i], y: val.y[i], r: counts[i] }], label: val.labels[i], backgroundColor: `rgba(255, ${Math.round((len - i) * 255 / len)}, 80, 0.4)`});
      }
      return {
        labels: val.labels,
        datasets: datasets,
      };
    };
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    const getChartOption = (val: HashtagRelations) => {
      if (!val) return Object();
      return {
        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
            font: {},
          },
          datalabels: {
            align: "center",
            offset: 0,
            font: {
              size: 14,
              weight: 700,
            },
            formatter: function (value: ChartPlug, context: ChartPlug) {
              console.log(context);
              return val.labels[context.datasetIndex];
            },
          },
        },
      };
    };
    const getSeparate2Arr = (arr: any[]) => {
      const len = arr.length;
      return [arr.slice(0, Math.ceil(len / 2)), arr.slice(Math.ceil(len / 2), len)];
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
      searchHashtag,
      closeLoadingDialog,
      getJPDate,
      getLocaleString,
      getChartData,
      getChartOption,
      getSeparate2Arr,
      handleHashtagChange,
      submitHashtagInfluencersPages,
      submitHashtagPostsPages,
      viewInfluencerDetail,
    };
  },
});
