import axios from "@/store/axios";
import { transformResponse } from "@/store/axios";
import { Commit } from "vuex";
import { Country } from "@/types";

interface State {
  errorMessage: string | null;
  countries: Country[];
}

const state: State = {
  errorMessage: null,
  countries: [],
};
const countries = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setCountry(state: State, countries: Country[]) {
      state.countries = countries;
    },
  },
  actions: {
    async getCountry({ commit }: { commit: Commit }) {
      try {
        const res = await axios.get("api/v1/countries/");
        commit("setCountry", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default countries;
