<template>
  <svg data-name="グループ 6634" xmlns="http://www.w3.org/2000/svg" width="182.001" height="27.678" viewBox="0 0 182.001 27.678">
    <g>
      <g transform="translate(102.266 13.403)">
        <path d="M432.983,504.425l1.05-.071h2.736v1.008h-3.786Zm.995-1.228c0-1.487.678-2.432,2.146-2.432a3.247,3.247,0,0,1,1.216.252l-.262.946a2,2,0,0,0-.829-.189c-.693,0-1.032.483-1.032,1.428v8.469h-1.24Z" transform="translate(-432.983 -500.766)" :fill="fillColor" />
        <path d="M440.06,510.172c0-2.442,1.6-3.849,3.4-3.849s3.4,1.407,3.4,3.849c0,2.423-1.6,3.83-3.4,3.83S440.06,512.6,440.06,510.172Zm5.509,0c0-1.677-.854-2.818-2.114-2.818s-2.114,1.141-2.114,2.818.854,2.8,2.114,2.8S445.569,511.848,445.569,510.172Z" transform="translate(-435.714 -502.915)" :fill="fillColor" />
        <path d="M453.269,506.5h1.024l.105,1.333h.041a2.521,2.521,0,0,1,2.083-1.513,1.7,1.7,0,0,1,.781.15l-.231,1.087a2.05,2.05,0,0,0-.71-.12c-.619,0-1.359.445-1.854,1.683v4.7h-1.24Z" transform="translate(-440.814 -502.915)" :fill="fillColor" />
        <path d="M466.393,502.438h1.516l1.9,5.28c.238.675.465,1.372.7,2.058h.06c.238-.686.446-1.384.684-2.058l1.874-5.28h1.516v9.88h-1.17v-5.44c0-.86.1-2.048.16-2.921h-.06l-.785,2.253-1.863,5.111h-.834l-1.872-5.111-.784-2.253h-.06c.063.874.16,2.061.16,2.921v5.44h-1.14Z" transform="translate(-445.88 -501.413)" :fill="fillColor" />
        <path
          d="M482.329,511.921c0-1.594,1.377-2.391,4.5-2.73,0-.941-.316-1.848-1.513-1.848a4.043,4.043,0,0,0-2.185.784l-.485-.849a5.377,5.377,0,0,1,2.878-.956c1.788,0,2.544,1.187,2.544,3.009v4.489h-1.024l-.1-.874H486.9A3.9,3.9,0,0,1,484.475,514,1.991,1.991,0,0,1,482.329,511.921Zm4.5.114V510c-2.456.3-3.285.9-3.285,1.831,0,.831.565,1.171,1.284,1.171A3.015,3.015,0,0,0,486.829,512.035Z"
          transform="translate(-452.031 -502.915)"
          :fill="fillColor"
        />
        <path d="M494.457,506.5h1.024l.1,1.333h.041a2.522,2.522,0,0,1,2.083-1.513,1.709,1.709,0,0,1,.782.15l-.232,1.087a2.049,2.049,0,0,0-.71-.12c-.619,0-1.358.445-1.853,1.683v4.7h-1.24Z" transform="translate(-456.713 -502.915)" :fill="fillColor" />
        <path d="M502.974,501.061h1.218v7.263h.042l3.128-3.856h1.375l-2.455,2.941,2.776,4.376H507.7l-2.136-3.517-1.375,1.6v1.921h-1.218Z" transform="translate(-460.001 -500.88)" :fill="fillColor" />
        <path
          d="M513.428,510.172c0-2.382,1.608-3.849,3.294-3.849,1.869,0,2.924,1.341,2.924,3.433a3.936,3.936,0,0,1-.058.7h-4.934a2.435,2.435,0,0,0,2.434,2.555,3.207,3.207,0,0,0,1.824-.586l.444.812a4.307,4.307,0,0,1-2.42.76A3.5,3.5,0,0,1,513.428,510.172Zm5.123-.6c0-1.466-.66-2.263-1.806-2.263a2.246,2.246,0,0,0-2.1,2.263Z"
          transform="translate(-464.036 -502.915)"
          :fill="fillColor"
        />
        <path d="M526.159,510.378v-4.047h-1.09v-.937l1.146-.071.148-2.052H527.4v2.052h1.985v1.008H527.4V510.4c0,.895.289,1.413,1.133,1.413a2.462,2.462,0,0,0,.835-.2l.239.934a4.429,4.429,0,0,1-1.359.271C526.686,512.821,526.159,511.834,526.159,510.378Z" transform="translate(-468.53 -501.735)" :fill="fillColor" />
        <path d="M534.417,502.355a.858.858,0,0,1,1.713,0,.858.858,0,0,1-1.713,0Zm.227,2.3h1.24v7.318h-1.24Z" transform="translate(-472.138 -501.071)" :fill="fillColor" />
        <path d="M540.334,506.5h1.024l.1,1.055h.041a3.5,3.5,0,0,1,2.458-1.236c1.544,0,2.237.995,2.237,2.862v4.636h-1.24v-4.476c0-1.363-.414-1.947-1.383-1.947a2.779,2.779,0,0,0-2,1.124v5.3h-1.24Z" transform="translate(-474.422 -502.915)" :fill="fillColor" />
        <path
          d="M551.873,515.227a2.054,2.054,0,0,1,1.075-1.655v-.06a1.355,1.355,0,0,1-.656-1.2,1.752,1.752,0,0,1,.806-1.358v-.06a2.543,2.543,0,0,1-.949-1.965,2.6,2.6,0,0,1,2.75-2.606,2.972,2.972,0,0,1,1.034.181h2.556v.953h-1.5a2.11,2.11,0,0,1,.6,1.506A2.5,2.5,0,0,1,554.9,511.5a2.618,2.618,0,0,1-1.092-.247,1.068,1.068,0,0,0-.467.864c0,.464.3.794,1.284.794h1.426c1.7,0,2.56.534,2.56,1.748,0,1.36-1.441,2.531-3.7,2.531C553.11,517.189,551.873,516.487,551.873,515.227Zm5.522-.384c0-.677-.515-.908-1.477-.908h-1.264a4.192,4.192,0,0,1-.951-.109,1.524,1.524,0,0,0-.748,1.227c0,.786.794,1.283,2.125,1.283C556.476,516.335,557.4,515.607,557.4,514.843Zm-.94-5.914a1.564,1.564,0,1,0-3.114,0,1.566,1.566,0,1,0,3.114,0Z"
          transform="translate(-478.877 -502.915)"
          :fill="fillColor"
        />
      </g>
      <g>
        <path
          d="M268.323,478.908a1.9,1.9,0,1,1-1.9,1.9,1.9,1.9,0,0,1,1.9-1.9Zm-1.167,5.876h2.333V503.1h-2.333Zm8.514-5.876a1.9,1.9,0,1,1-1.9,1.9,1.9,1.9,0,0,1,1.9-1.9ZM287.562,503.1h-2.216v-9.586c0-1.915.179-3.868.3-5.721h-.112l-1.979,3.782L276.914,503.1H274.5V484.787h2.216v9.48c0,1.915-.179,3.98-.3,5.825h.112l1.98-3.793,6.638-11.515h2.41Zm5.017-18.316h10.578v1.973h-8.243v6.176H301.9v1.973h-6.989V503.1h-2.333ZM319.089,503.1l-4.672-8a4.971,4.971,0,0,0,4.125-5.157c0-3.815-2.682-5.155-6.417-5.155h-5.744V503.1h2.333v-7.742h3.315l4.415,7.742Zm-10.376-16.425h3.075c2.854,0,4.424.855,4.424,3.264,0,2.374-1.57,3.529-4.424,3.529h-3.075Zm13.563-1.891h10.57v1.973h-8.233v5.74h6.948v1.973h-6.948v6.659h8.516V503.1h-10.85Zm13.659,9.174c0-5.907,3.484-9.509,8.138-9.509a6.839,6.839,0,0,1,5.044,2.259l-1.279,1.518a4.989,4.989,0,0,0-3.724-1.727c-3.465,0-5.772,2.835-5.772,7.4,0,4.6,2.2,7.49,5.678,7.49a5.508,5.508,0,0,0,4.251-2.057l1.278,1.462a7.076,7.076,0,0,1-5.627,2.644c-4.563,0-7.985-3.528-7.985-9.478Zm20.836-7.2h-5.545v-1.973h13.429v1.973h-5.548V503.1h-2.333Z"
          transform="translate(-266.42 -478.908)"
          :fill="fillColor"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "InfrectLogo",
  props: {
    fillColor: {
      type: String,
      default: "#fff",
    },
  },
});
</script>
