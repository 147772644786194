export class CompanyType {
  static readonly AGRICULTURE = new CompanyType(0, "農業、林業");
  static readonly FISHERY = new CompanyType(1, "漁業");
  static readonly MINING = new CompanyType(2, "鉱業、採石業、砂利採取業");
  static readonly CONSTRUCTION = new CompanyType(3, "建設業");
  static readonly MANUFACTURING = new CompanyType(4, "製造業");
  static readonly ENERGY = new CompanyType(5, "電気・ガス・熱供給・水道業");
  static readonly IT = new CompanyType(6, "IT・情報通信業");
  static readonly TRANSPORTATION = new CompanyType(7, "運輸業、郵便業");
  static readonly RETAIL = new CompanyType(8, "卸売業、小売業");
  static readonly FINANCE = new CompanyType(9, "金融業、保険業");
  static readonly ESTATE = new CompanyType(10, "不動産業、物品賃貸業");
  static readonly ACADEMIA = new CompanyType(11, "学術研究、専門・技術サービス業");
  static readonly LODGING = new CompanyType(12, "宿泊業、飲食サービス業");
  static readonly ENTERTAINMENT = new CompanyType(13, "生活関連サービス業、娯楽業");
  static readonly EDUCATION = new CompanyType(14, "教育・学習支援業");
  static readonly MEDICAL = new CompanyType(15, "医療・福祉");
  static readonly COMPLEXSERVICE = new CompanyType(16, "複合サービス業");
  static readonly OTHERSERVICE = new CompanyType(17, "その他サービス業");
  static readonly OFFICIAL = new CompanyType(18, "公務");
  static readonly OTHERS = new CompanyType(19, "その他");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const value = this.getAll().find((value) => value.key === key);
    return value ? value.text : "";
  }
  static getAll(): Array<CompanyType> {
    return [
      CompanyType.AGRICULTURE,
      CompanyType.FISHERY,
      CompanyType.MINING,
      CompanyType.CONSTRUCTION,
      CompanyType.MANUFACTURING,
      CompanyType.ENERGY,
      CompanyType.IT,
      CompanyType.TRANSPORTATION,
      CompanyType.RETAIL,
      CompanyType.FINANCE,
      CompanyType.ESTATE,
      CompanyType.ACADEMIA,
      CompanyType.LODGING,
      CompanyType.ENTERTAINMENT,
      CompanyType.EDUCATION,
      CompanyType.MEDICAL,
      CompanyType.COMPLEXSERVICE,
      CompanyType.OTHERSERVICE,
      CompanyType.OFFICIAL,
      CompanyType.OTHERS,
    ];
  }
}
