
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import SearchIcon from "@/components/icon/SearchIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { SearchUsernameInfluencerParams, ClickEvent } from "@/types";
import BaseTable from "@/components/BaseTable.vue";
import BasePagination from "@/components/BasePagination.vue";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import { set } from "vue-gtag";

export default defineComponent({
  name: "IDSearch",
  components: {
    BaseTable,
    Sidebar,
    BaseHeader,
    SearchIcon,
    BaseButton,
    BasePagination,
    Loading,
    ExclamationMark,
    TiktokIcon,
    TwitterIcon,
    InstagramIcon,
    YoutubeIcon,
  },
  setup: function () {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    const state = reactive({
      currentSearchTab: "instagram",
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      success: computed(() => store.state.influencers.usernameSearchSuccess),
      total: computed(() => store.state.influencers.total),
      totalPages: computed(() => store.state.influencers.totalPages),
      currentPage: computed(() => store.state.influencers.currentPage),
      formErrorMessage: null as string | null,
      username: null as string | null,
      type: null as number | null,
      influencers: computed(() => store.state.influencers.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      searchUsernameSuccess: computed(() => store.state.influencers.usernameSearchSuccess),
    });
    set({userId: state.user?.id});
    const setCurrentSearchTab = (index: string) => {
      state.currentSearchTab = index;
    };
    const searchUsername = (event: ClickEvent) => {
      state.formErrorMessage = null;
      if (event.keyCode !== 229) {
        submitUsernameSearch(1);
      }
    };
    const submitUsernameSearch = (page: number) => {
      if (!state.username) {
        state.formErrorMessage = "名前やIDの入力は必須です";
      } else {
        store.dispatch("influencers/simpleSearchInitialize");
        const params: SearchUsernameInfluencerParams = {
          username: state.username,
          snsType: state.currentSearchTab,
          page: page,
        };
        store.dispatch("influencers/searchUsername", params);
      }
    };
    const submitPages = (page: number) => {
      return submitUsernameSearch(page);
    };
    let searchUsernameSuccess = computed(() => state.searchUsernameSuccess);
    watch(searchUsernameSuccess, (val: boolean) => {
      store.dispatch("users/loadCurrentUser");
    });
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
      searchUsername,
      closeLoadingDialog,
      submitPages,
    };
  },
});
