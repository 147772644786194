
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import AddCandidateButton from "@/components/AddCandidateButton.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import CancelButtonIcon from "@/components/icon/CancelButtonIcon.vue";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import { DayOfWeek } from "@/enum/dayOfWeek";
import { Influencer, SimplePredictionRequestParams, DetailPredictionRequestParams } from "@/types";

export default defineComponent({
  name: "AdPrediction",
  components: {
    BaseSelectBox,
    CancelButtonIcon,
    AddCandidateButton,
    BaseTextArea,
    InstagramIcon,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    ProfileIcon,
    Loading,
    ExclamationMark,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompanyInfluencers");
    store.dispatch("users/loadCurrentUser");
    const state = reactive({
      show: {
        first: true,
        second: false,
        dialog: false,
      },
      influencerList: computed(() => store.state.influencers.companyInfluencers),
      company: computed(() => store.state.companies.company),
      likeValue: 0,
      imgUrl: null as string | null,
      isDragOver: false,
      isDragOverForInfluencer: false,
      selectedInfluencers: [] as any[],
      form: {
        caption: null as string | null,
        captionForInfluencer: null as null | string,
        followers: null as null | number,
        follow: null as null | number,
        day: { key: "Monday", text: "月曜日" },
        hour: { key: 0, text: "0時" },
        minute: { key: 0, text: "0分" },
      },
      formErrorMessage: null as string | null,
      formErrorMessageForInfluencer: null as string | null,
      errorMessage: computed(() => store.state.prediction.errorMessage),
      loading: computed(() => store.state.prediction.loading),
      simplePredictSuccess: computed(() => store.state.prediction.simplePredictionSuccess),
      simpleSearchResult: computed(() => store.state.prediction.simplePredictionResult),
      detailPredictionSuccess: computed(() => store.state.prediction.detailPredictionSuccess),
      detailPredictionResult: computed(() => store.state.prediction.detailPredictionResult),
    });
    const hours = Array.from(Array(24), (v, k) => ({ key: k, text: k + "時" }));
    const minutes = Array.from(Array(60), (v, k) => ({ key: k, text: k + "分" }));
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const uploadThumbnailInputForInfluencer = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const handleFileForInfluencer = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInputForInfluencer.value?.files || [])[0]);
    };
    const onDrag = (type: string) => {
      state.isDragOver = type === "over";
    };
    const onDragForInfluencer = (type: string) => {
      state.isDragOverForInfluencer = type === "over";
    };
    const onDrop = (e: any) => {
      state.isDragOver = false;
      if (uploadThumbnailInput.value?.files) uploadThumbnailInput.value.files = null;
      const file = e.dataTransfer.files[0];
      Object.defineProperty(uploadThumbnailInput.value, "files", { value: [file], writable: true });
      handleFile();
    };
    const onDropForInfluencer = (e: any) => {
      state.isDragOverForInfluencer = false;
      if (uploadThumbnailInputForInfluencer.value?.files) uploadThumbnailInputForInfluencer.value.files = null;
      const file = e.dataTransfer.files[0];
      Object.defineProperty(uploadThumbnailInputForInfluencer.value, "files", { value: [file], writable: true });
      handleFileForInfluencer();
    };
    const submitPrediction = () => {
      state.formErrorMessage = null;
      if (!state.form.follow && state.form.follow != 0) {
        state.formErrorMessage = "フォロー数は必須です";
      } else if (!state.form.followers && state.form.followers != 0) {
        state.formErrorMessage = "フォロワー数は必須です";
      } else {
        const form: SimplePredictionRequestParams = {
          day: state.form.day.key,
          follow: state.form.follow,
          followers: state.form.followers,
          hour: state.form.hour.key,
          minute: state.form.minute.key,
        };
        !Number.isNaN(form.follow) ? (form.follow = Number(form.follow)) : (state.formErrorMessage = "フォロー数には整数を入れてください");
        !Number.isNaN(form.followers) ? (form.followers = Number(form.followers)) : (state.formErrorMessage = "フォロワー数には整数を入れてください");
        if (!state.formErrorMessage) store.dispatch("prediction/predictSimpleInstagramLikes", form);
      }
    };
    const submitDetailPrediction = () => {
      state.formErrorMessageForInfluencer = null;
      if (state.selectedInfluencers.length === 0) {
        state.formErrorMessageForInfluencer = "インフルエンサーの選択は必須です";
      } else {
        const form: DetailPredictionRequestParams = {
          influencers: state.selectedInfluencers.slice().map((x: Influencer) => x.id),
          day: state.form.day.key,
          hour: state.form.hour.key,
          minute: state.form.minute.key,
        };
        if (state.form.caption) form.caption = state.form.caption;
        store.dispatch("prediction/predictDetailInstagramLikes", form);
      }
    };
    const filterInstagramAccount = (items: Influencer[]) => {
      return items.slice().filter((x: Influencer) => x.type === 0);
    };
    const closeLoadingDialog = () => {
      router.push({ name: "Home" });
    };
    const deleteInfluencer = (id: string) => {
      state.selectedInfluencers = state.selectedInfluencers.filter((value: Influencer) => value.isPredictable);
      state.selectedInfluencers = state.selectedInfluencers.filter((value: Influencer) => value.id !== id);
    };
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      uploadThumbnailInputForInfluencer,
      handleFile,
      handleFileForInfluencer,
      onDrag,
      onDragForInfluencer,
      onDrop,
      onDropForInfluencer,
      DayOfWeek,
      hours,
      minutes,
      submitPrediction,
      submitDetailPrediction,
      filterInstagramAccount,
      closeLoadingDialog,
      deleteInfluencer,
    };
  },
});
