export class MemberRole {
  static readonly MASTER = new MemberRole(0, "管理者");
  static readonly GENERAL_ACCOUNT = new MemberRole(1, "一般アカウント");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const memberRole = this.getAll().find((memberRole) => memberRole.key === key);
    return memberRole ? memberRole.text : "";
  }
  static getObject(key: number): MemberRole | null {
    const memberRole = this.getAll().find((memberRole) => memberRole.key === key);
    return memberRole !== undefined ? memberRole : null;
  }
  private static getAll(): Array<MemberRole> {
    return [MemberRole.MASTER, MemberRole.GENERAL_ACCOUNT];
  }
}
