<template>
  <div class="base-container hashtag-search">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header display-type="AISEARCH" :key="count"></base-header>
      <div v-if="company?.isExpired === false" style="position: relative;">
        <div class="search">
          <div class="search__contents">
            <h1>AI検索（ベータ版）</h1>
            <h3>商品・サービスの内容からAIが適切なインフルエンサーを検索します</h3>
            <div class="d-flex justify-content-space-between mx-auto mb-20px" style="width: 80%;">
              <base-button color="primary" size="wide" @click="moveToResults">過去の検索結果を見る</base-button>
              <base-button color="primary" size="wide" @click="moveToSample">サンプルをみる</base-button>
            </div>
            <base-text-area label="" placeholder="商品・サービスの説明をここに入力してください" :width="800" :sm-width="280" :height="200" v-model="text"></base-text-area>
            <h5 class="mt-10px">※結果の生成に最大で1分ほどかかります</h5>
            <h5>※AIによる生成のため、必ずしも結果が正しいとは限りません</h5>
            <h5>※結果に再現性はございません。再度検索していただくことで違う検索結果となる場合がございます</h5>
            <div class="d-flex justify-content-center mt-20px">
              <base-button color="accent" size="wide" @click="submitSearch">検索する</base-button>
            </div>
          </div>
        </div>
        <div class="search-result" v-if="success">
          <div class="d-flex mt-40px mb-10px align-items-end">
            <h3 class="mr-10px">検索結果</h3>
          </div>
          <div class="result__container">
            <div class="result__section">
              <h3>この商品・サービスに共感できる人のトライブ</h3>
              <div class="result__section__content">
                <multiple-toggle :items="personas" :selectable="false" background="#8866ee">
                  <template #item="{ item }">
                    <div style="display: flex; align-items: baseline;">
                      <div style="width: 20px"><img src="@/assets/img/anno_user.svg" style="width: 100%; vertical-align: sub" /></div>
                      <div style="margin-left: 10px;">{{ item }}</div>
                    </div>
                  </template>
                </multiple-toggle>
              </div>
            </div>
            <div class="result__section">
              <h3>トライブと関係のあるキーワード</h3>
              <div class="result__section__content">
                <h4 class="mb-10px">キーワード:</h4>
                <multiple-toggle
                    :items="keywords"
                    v-model="selectedKeywordIndex"
                    @change="searchAIKeywordInfluencers"
                ></multiple-toggle>
              </div>
              <div class="result__section__content mt-20px">
                <h4 class="mb-10px">SNSの種類:</h4>
                <multiple-toggle
                    :items="SNSTypes"
                    v-model="selectedKeywordSNSIndex"
                    selected-background="orange"
                    @change="searchAIKeywordInfluencers"
                >
                  <template #item="{ item }">
                    <div class="d-flex align-items-center" v-if="item === 'Instagram'">
                      <instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'Twitter'">
                      <twitter-icon :size="20" fill-color="white" class="mr-5px" /><span>Twitter</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'TikTok'">
                      <tiktok-icon :size="20" fill-color="white" class="mr-5px" /><span>TikTok</span>
                    </div>
                    <div class="d-flex align-items-center" v-else-if="item === 'YouTube'">
                      <youtube-icon :size="20" fill-color="white" class="mr-5px" /><span>YouTube</span>
                    </div>
                    <div class="d-flex align-items-center" v-else>
                      <span>{{ item }}</span>
                    </div>
                  </template>
                </multiple-toggle>
              </div>
              <div class="result__section__content mt-20px">
                <loading width="20px" v-if="keywordLoading"></loading>
                <div v-if="keywordInfluencers.length">
                  <h4 class="mb-10px">インフルエンサー:</h4>
                  <base-table
                      v-if="keywordSuccess"
                      :items="keywordInfluencers" />
                  <div style="width: 100%; text-align: center;">
                    <router-link
                        :to="{
                        name: 'AdvancedSearch',
                        query: {
                          sns: SNSTypes[selectedKeywordSNSIndex].toLowerCase(),
                          keyword: keywords[selectedKeywordIndex],
                        }
                      }"
                        target="_blank"
                        class="mt-40px"
                    >
                      <base-button color="accent" size="large_wide">詳細に検索する</base-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="result__section">
              <h3>トライブと関係のあるハッシュタグ</h3>
              <div class="result__section__content">
                <h4 class="mb-10px">キーワード:</h4>
                <multiple-toggle
                    :items="hashtags"
                    v-model="selectedHashtagIndex"
                    @click="searchAIHashtagInfluencers"
                >
                  <template #item="{ item }">
                    # {{ item }}
                  </template>
                </multiple-toggle>
                <div class="result__section__content mt-20px">
                  <h4 class="mb-10px">SNSの種類:</h4>
                  <multiple-toggle
                      :items="HashtagSNSTypes"
                      v-model="selectedHashtagSNSIndex"
                      selected-background="orange"
                      @change="searchAIHashtagInfluencers"
                  >
                    <template #item="{ item }">
                      <div class="d-flex align-items-center" v-if="item === 'Instagram'">
                        <instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'Twitter'">
                        <twitter-icon :size="20" fill-color="white" class="mr-5px" /><span>Twitter</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'TikTok'">
                        <tiktok-icon :size="20" fill-color="white" class="mr-5px" /><span>TikTok</span>
                      </div>
                      <div class="d-flex align-items-center" v-else-if="item === 'YouTube'">
                        <youtube-icon :size="20" fill-color="white" class="mr-5px" /><span>YouTube</span>
                      </div>
                      <div class="d-flex align-items-center" v-else>
                        <span>{{ item }}</span>
                      </div>
                    </template>
                  </multiple-toggle>
                </div>
                <div class="result__section__content mt-20px">
                  <loading width="20px" v-if="hashtagLoading"></loading>
                  <div v-if="hashtagInfluencers.length">
                    <h4 class="mb-10px">インフルエンサー:</h4>
                    <base-table
                        v-if="hashtagSuccess"
                        :items="hashtagInfluencers" />
                    <div style="width: 100%; text-align: center;">
                      <router-link
                          :to="{
                        name: 'AdvancedSearch',
                        query: {
                          sns: SNSTypes[selectedHashtagSNSIndex].toLowerCase(),
                          hashtag: hashtags[selectedHashtagIndex],
                        }
                      }"
                          target="_blank"
                          class="mt-40px"
                      >
                        <base-button color="accent" size="large_wide">詳細に検索する</base-button>
                      </router-link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog__container" v-if="(loading || errorMessage || formErrorMessage)  && !company?.isExpired">
          <div class="dialog" v-show="loading" v-if="loading">
            <div class="title">検索中です</div>
            <div class="loading-box"><loading></loading></div>
            <div class="description"><h4>結果をAIが生成しています。ページを離れずにお待ちください。</h4></div>
          </div>
          <div class="dialog" v-show="errorMessage" v-if="errorMessage">
            <div class="title">データの取得に失敗しました</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>AIの生成中にエラーが発生しました。再度検索してください。<br>※検索回数は消費されません。</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="closeDialog">戻る</base-button></div>
          </div>
          <div class="dialog" v-show="formErrorMessage" v-if="formErrorMessage">
            <div class="title">商品・サービスの内容は必須です</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>検索前に商品・サービスの内容を入力してください。<br>※検索回数は消費されません。</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="closeDialog">戻る</base-button></div>
          </div>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import { useRouter } from "vue-router";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import BaseTable from "@/components/BaseTable.vue";
import Loading from "@/components/Loading.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import multipleToggle from "@/components/MultipleToggle.vue";
import { set } from "vue-gtag";
import BaseTextArea from "@/components/BaseTextArea.vue";
import exclamationMark from "@/components/icon/ExclamationMark.vue";

export default defineComponent({
  name: "AISearch",
  components: {
    Loading,
    BaseTable,
    BaseTextArea,
    BaseButton,
    Sidebar,
    BaseHeader,
    InstagramIcon,
    TiktokIcon,
    TwitterIcon,
    YoutubeIcon,
    multipleToggle,
    exclamationMark
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    store.dispatch("influencers/initializeAISearch");
    const state = reactive({
      count: 0,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      text: "",
      errorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
      loading: computed(() => store.state.influencers.aiSearchLoading),
      success: computed(() => store.state.influencers.aiSearchSuccess),
      keywordLoading: computed(() => store.state.influencers.aiKeywordsLoading),
      hashtagLoading: computed(() => store.state.influencers.aiHashtagsLoading),
      keywordSuccess: computed(() => store.state.influencers.aiKeywordsSuccess),
      hashtagSuccess: computed(() => store.state.influencers.aiHashtagsSuccess),
      selectedKeywordIndex: 0,
      selectedKeywordSNSIndex: 0,
      selectedHashtagIndex: 0,
      selectedHashtagSNSIndex: 0,
      personas: computed(() => store.state.influencers.aiPersonas),
      keywords: computed(() => store.state.influencers.aiKeywords),
      hashtags: computed(() => store.state.influencers.aiHashtags),
      keywordInfluencers : computed(() => store.state.influencers.aiKeywordInfluencers),
      hashtagInfluencers : computed(() => store.state.influencers.aiHashtagInfluencers),
      SNSTypes: [
        "Instagram",
        "YouTube",
        "TikTok",
        "Twitter",
      ],
      HashtagSNSTypes: [
        "Instagram",
        "Twitter",
      ],
      formErrorMessage: null as string | null,
      aiSearchErrorMessage: computed(() => store.state.influencers.aiSearchErrorMessage),
    });
    set({ userId: state.user?.id });
    const submitSearch = () => {
      state.count += 1;
      if (!state.text) {
        state.formErrorMessage = "商品・サービスの説明は必須です"
        return
      }
      store.dispatch("influencers/aiSearch", state.text);
      state.selectedHashtagIndex = 0;
      state.selectedKeywordIndex = 0;
      state.selectedHashtagSNSIndex = 0;
      state.selectedKeywordSNSIndex = 0;
    }
    const closeDialog = () => {
      store.dispatch("influencers/initializeAISearch");
      state.formErrorMessage = null;
    }
    const moveToSample = () => {
      router.push({ name: "AISearchSample" });
    }
    const moveToResults = () => {
      router.push({ name: "AISearchResultList" });
    }
    const searchAIHashtagInfluencers = () => {
      if (state.hashtags) {
        store.dispatch("influencers/getAIHashtagSearch",
            {
              snsType: state.SNSTypes[state.selectedHashtagSNSIndex].toLowerCase(),
              hashtags: [state.hashtags[state.selectedHashtagIndex]],
            }
        );
      }
    }
    const searchAIKeywordInfluencers = () => {
      if (state.keywords) {
        store.dispatch("influencers/getAIKeywordSearch",
            {
              snsType: state.SNSTypes[state.selectedKeywordSNSIndex].toLowerCase(),
              keyword: state.keywords[state.selectedKeywordIndex],
            }
        );
      }
    }
    let success = computed(() => state.success);
    watch(success, (val) => {
      if (val) {
        state.count += 1;
        searchAIKeywordInfluencers();
        searchAIHashtagInfluencers();
      }
    })
    return {
      ...toRefs(state),
      moveToSample,
      moveToResults,
      submitSearch,
      closeDialog,
      searchAIHashtagInfluencers,
      searchAIKeywordInfluencers,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/img/ai-search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  &__contents {
    padding: 50px 0;
    @include mq(xs) {
      padding: 40px 0 60px 0;
    }
    @include mq(lg) {
      padding: 50px 0;
    }
    & h1 {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 18px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    & h3 {
      color: white;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 40px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 20px;
      }
      @include mq(lg) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    &__buttons {
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;
      & svg {
        margin-top: 2px;
      }
      & .button {
        width: 140px;
        @include mq(xs) {
          width: 60px;
        }
        @include mq(lg) {
          width: 140px;
        }
      }
      & span {
        display: inline-block;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: inline-block;
        }
      }
    }
    &__area {
      & input {
        width: 580px;
        height: 60px;
        border: 2px solid white;
        border-radius: 5px 0 0 5px;
        font-size: 18px;
        font-weight: 700;
        color: map-get($font-colors, "default");
        padding: 0 20px;
        @include mq(xs) {
          width: 90%;
          font-size: 14px;
        }
        @include mq(lg) {
          width: 580px;
          font-size: 18px;
        }
        &:focus {
          border: 2px solid map-get($colors, "accent");
          outline: none;
        }
        &::placeholder {
          color: map-get($colors, "gray200");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 18px;
          }
        }
      }
      &__button {
        width: 60px;
        height: 60px;
        background-color: #ee7382;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        &:hover {
          background-color: #efabb4;
        }
      }
      &__error {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
    }
  }
}
.result {
  &__container {
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    & h3 {
      & > span {
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    & .loading {
      width: 80px;
      margin: 40px auto;
    }
  }
  &__section {
    margin-bottom: 80px;
    & h3 {
      margin-bottom: 20px;
    }
  }
  &__relation {
    & h4 {
      font-weight: 900;
      margin: 30px 0 10px;
    }
    & h5 {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    &__ranking {
      width: 80%;
      margin: 0 auto;
      &__table {
        width: 49%;
        min-width: 300px;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: 49%;
        }
        &__wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          @include mq(xs) {
            display: block;
          }
          @include mq(lg) {
            display: flex;
          }
        }
        &__no-data {
          width: 100%;
          min-width: 300px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: #9c9c9c;
          margin: 20px 0;
        }
      }
    }
    &__relations {
      width: 80%;
      margin: 20px auto 0 auto;
      &__graph {
        width: 800px;
        border: solid 8px map-get($colors, "gray200");
        border-radius: 8px;
        margin: 0 auto;
        @include mq(xs) {
          width: 100%;
        }
        @include mq(lg) {
          width: calc(100% - 20px);
        }
        &__no-data {
          width: 100%;
          height: 100%;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          vertical-align: center;
          color: map-get($colors, "gray200");
          margin: 80px 0;
        }
      }
    }
  }
}
.result-none {
  width: 100%;
  background: white;
  padding: 120px 0;
  border-radius: 10px;
  height: fit-content;
  text-align: center;
  align-items: center;
  @include mq(xs) {
    padding: 60px 0;
    margin-bottom: 40px;
  }
  @include mq(lg) {
    padding: 120px 0;
    margin-bottom: auto;
  }
  & h1,
  h3 {
    color: map-get($colors, "primary");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.influencer-detail {
  width: 100%;
  color: map-get($font-colors, "default");
  &__post {
    width: calc(50% - 50px);
    padding: 20px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    @include mq(xs) {
      width: calc(100% - 30px);
      height: 895px;
    }
    @include mq(lg) {
      width: calc(50% - 50px);
      height: 705px;
    }
    &__post__card {
      display: flex;
      width: 100%;
      @include mq(xs) {
        display: block;
        height: fit-content;
      }
      @include mq(lg) {
        display: flex;
        height: calc(100% - 160px);
      }
    }
    &__account {
      &__card {
        display: flex;
        align-content: space-between;
        width: 100%;
        height: 60px;
        margin: 20px auto 20px 0;
      }
      &__profile {
        width: 100%;
        display: flex;
        & > img {
          width: 60px;
          height: 60px;
          display: block;
          border-radius: 9999px;
        }
        &__name {
          margin-left: 10px;
          & h4 {
            font-weight: 700;
          }
        }
      }
      &__actions {
        width: 50%;
        text-align: right;
      }
    }
    &.twitter {
      display: block;
      height: 700px;
      padding: 20px;
      margin: 0 20px 30px 0;
      @include mq(xs) {
        width: calc(100% - 40px);
      }
      @include mq(lg) {
        width: calc(30% - 20px);
      }
      & iframe {
        height: 600px;
        margin: 0 auto;
      }
      &:nth-of-type(2n) {
        margin-right: 20px;
      }
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: flex;
      }
    }
    &__thumbnail {
      margin-right: 20px;
      width: 300px;
      height: 600px;
      position: relative;
      border-radius: 10px;
      background-image: url("../assets/img/post-background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 16px;
      @include mq(xs) {
        margin: auto;
      }
      @include mq(lg) {
        margin: 0 20px 0 0;
      }
      & iframe {
        width: 300px !important;
        height: 600px !important;
        border: 2px solid #f7f7f7;
        border-radius: 10px;
      }
    }
    &__content {
      position: relative;
      width: calc(100% - 320px);
      @include mq(xs) {
        width: 100%;
      }
      @include mq(lg) {
        width: calc(100% - 220px);
      }
      &__date {
        font-size: 12px;
        font-weight: 700;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 0;
        }
      }
      &__text {
        overflow: scroll;
        @include mq(xs) {
          height: 135px;
        }
        @include mq(lg) {
          height: 100%;
        }
        &__wrapper {
          font-size: 13px;
          height: 100%;
          margin-top: 10px;
        }
      }
      &__engagements {
        display: flex;
        bottom: 0;
        @include mq(xs) {
          margin-top: 6px;
        }
        @include mq(lg) {
          margin-top: 10px;
        }
      }
      &__engagement {
        display: flex;
        font-size: 14px;
        font-weight: 900;
        margin-right: 20px;
        & img {
          width: 10px;
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      & .likes {
        color: #ffa0a0;
      }
      & .comments {
        color: #79b5db;
      }
    }
  }
}
.loading__container,
.error__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 10px;
  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    & > div {
      margin: 20px auto;
    }
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
<style lang="scss">
.hashtag-search {
  .combobox {
    &__wrapper {
      border-radius: 5px 0 0 5px;
      border: 2px solid white;
      height: calc(100% - 4px);
      &:focus-within {
        outline: none;
        border: 2px solid #ee7382;
        box-shadow: none;
      }
    }
    & input {
      &::placeholder {
        color: #9c9c9c;
        font-weight: 900;
      }
    }
  }
}
</style>
