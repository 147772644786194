<template>
  <div class="edit-icon">
    <svg
        class="material-design-icon__svg"
        :width="width"
        :height="height"
        viewBox="0 0 124 124"
    >
      <g id="check" transform="translate(2 2)">
        <path d="M60,0a60,60,0,1,0,60,60A60.066,60.066,0,0,0,60,0Zm0,0" fill="none" stroke="#198fd9" stroke-width="4"/>
        <path d="M198.615,173.864l-32.5,32.5a5,5,0,0,1-7.07,0l-16.25-16.25a5,5,0,0,1,7.07-7.07l12.715,12.715,28.965-28.964a5,5,0,0,1,7.07,7.07Zm0,0" transform="translate(-110.828 -126.579)" fill="#198fd9"/>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "CheckMark",
  props: {
    fillColor: {
      type: String,
      default: "#707070"
    },
    width: {
      type: Number,
      default: 124
    },
    height: {
      type: Number,
      default: 124
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

</style>