<template>
  <div class="base-container__white">
    <div class="terms">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" />
      </header>
      <div class="terms__content" id="terms">
        <div class="mt-60px">
          <h1>利用規約</h1>
        </div>
        <div class="mt-40px">
          <h3>第１条（適用）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>本規約は、ゼロスタ株式会社（以下「当社」といいます）が運営する、インフルエンサー紹介サービス「INFRECT」に関する提供条件及び当社と契約者との間の権利義務関係を定めることを目的とします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>本サービスの利用に関し、当社が本サイト上で本規約以外の利用条件及び注意事項等（以下総称して「利用条件等」といいます）を定めた場合は、当該利用条件等も本規約の一部を構成します。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第２条（定義）</h3>
          <h4>本規約で使用する用語は、次の内容を有するものとします。</h4>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>「本サービス」とは、当社が運営する、インフルエンサー紹介サービス「INFRECT」をいいます。</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>「本サイト」とは、本サービスに係るウェブサイトをいいます。</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>「本契約」とは、本規約を契約条件として当社と契約者との間で締結される本サービスの利用契約をいいます。</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>「契約者」とは、当社と本契約を締結し、本サービスを利用する者をいいます。</h4>
          </div>
          <div class="d-flex">
            <h4>（5）</h4>
            <h4>「ユーザー」とは、契約者から本サービスの利用権限を付与され、契約者の管理の下で本サービスを利用する個人をいいます。</h4>
          </div>
          <div class="d-flex">
            <h4>（6）</h4>
            <h4>「インフルエンサー」とは、本サービスを通じて契約者に紹介され、又は紹介され得る個人をいいます。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第３条（登録）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ、当社の定める一定の情報（以下「登録情報」といいます）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>当社は、当社の基準に従って、前項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録の可否は当社の裁量により判断されるものとし、登録を拒否した場合も当社はその理由について一切説明する義務を負いません。</h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>登録申請者の登録は、当社が前項の通知を行ったことをもって完了するものとします。登録完了時に本契約が契約者と当社との間に成立し、契約者及びユーザーは本サービスを本規約に従い利用することができるようになります</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第４条（登録情報）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>契約者及びユーザーは、登録情報を正確に当社に提供するものとします。登録情報に関して虚偽の情報を提供、掲載等することは固く禁止されます。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>登録情報に変更があった場合、契約者又はユーザーは速やかに当社所定の方法により当社に届け出るものとします。かかる届出の遅滞の結果生じた損害について、当社は一切責任を負わないものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第５条（メールアドレス及びパスワードの管理）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>契約者及びユーザーは、本サイトの利用に係るメールアドレス及びパスワードを適切に管理するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>メールアドレス及びパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は契約者が負うものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第６条（料金及び支払方法）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>契約者は、本サービス利用の対価として、別途当社が定め、利用料金を、当社が指定する支払方法により当社に支払うものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>契約者が利用料金の支払いを遅滞した場合、契約者は年１４．６%の割合による遅延損害金を当社に支払うものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第７条（禁止事項）</h3>
          <h4>契約者及びユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。</h4>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>法令に違反する行為または犯罪行為に関連する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>公序良俗に反する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>当社、利用会員、その他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利または利益を侵害し、または侵害するおそれのある行為</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>インタビュー、コラボレーション、ＰＲその他の案件依頼ではなく、インフルエンサーとの出会いを主な目的として本件サービスを利用する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（5）</h4>
            <h4>インフルエンサーから得た知見を第三者に提供することを目的として本件サービスを利用する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（6）</h4>
            <h4>他のユーザーのメールアドレス及びパスワードを使用する行為その他第三者になりすまして本サービスを利用する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（7）</h4>
            <h4>政治的行為、宗教行為、またはそれらへの勧誘を目的とする行為</h4>
          </div>
          <div class="d-flex">
            <h4>（8）</h4>
            <h4>インフルエンサーへの迷惑行為、嫌がらせ、誹謗中傷、その他精神的または経済的な被害を与える可能性のある行為</h4>
          </div>
          <div class="d-flex">
            <h4>（9）</h4>
            <h4>本サービスの運営を妨害するおそれのある行為</h4>
          </div>
          <div class="d-flex">
            <h4>（10）</h4>
            <h4>前号までのほか、当社が不適切と判断する行為</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第８条（秘密保持）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>当社は、契約者から事前に承諾を得ることなく、本サービスを通じて取得した契約者に係る営業上、技術上その他一切の情報を第三者に開示せず、かつ契約者に対する本サービスの提供以外の目的に利用してはならないものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>前項にかかわらず、当社は、契約者及びユーザーによる本サイト及び本サービスの利用状況に基づいて、契約者及びユーザーを特定できないように加工した統計データを作成することがあります。当社は、かかる統計データを何ら制限なく利用することができるものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>契約者は、当社から事前に承諾を得ることなく、本サービスを通じて取得したインフルエンサーに係る情報を第三者に開示せず、かつ本サービス以外の目的に利用してはならないものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第９条（利用者情報の取扱い）</h3>
          <h4>当社によるユーザーの利用者情報の取扱いについては、下記当社<a href="#privacy-policy">プライバシーポリシー</a>の定めによるものとし、ユーザーは、当社が当該プライバシーポリシーに従って利用者情報を取り扱うことに同意するものとします。</h4>
        </div>
        <div class="mt-40px">
          <h3>第１０条（権利）</h3>
          <h4>本サービス及び本サイトに係る知的財産権は、当社、当社にライセンスを許諾する者またはインフルエンサーに帰属しており、本規約に基づく本サービスの利用許諾は、本サービス及び本サイトに係る当社、当社にライセンスを許諾する者またはインフルエンサーの知的財産権の使用許諾を意味するものではありません。</h4>
        </div>
        <div class="mt-40px">
          <h3>第１１条（保証の否認及び免責）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>
              当社は、本サービスが契約者の特定の目的に適合すること、期待する有用性、正確性を有すること、契約者に適用される法令または業界団体の内部規則等に適合すること、継続的に利用できること、不具合が生じないこと、第三者の特許権その他の権利を侵害しないこと、社会的非難を受けないこと、その他一切について、明示または黙示を問わず何ら保証するものではありません。また、本サービスを通じて紹介されたインフルエンサーに対するインタビュー、コラボレーション、ＰＲその他の案件依頼の交渉及びこれに基づく事業の遂行等は、契約者自身の責任と危険負担において行われるものとし、当社及びインフルエンサーは一切責任を負わないものとします。
            </h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>
              本サービスを通じて契約者に提供されるインフルエンサーに係る一切の情報（以下「インフルエンサー情報」といいます）は、当社がインフルエンサーの各種SNSから情報を収集し、分析したものに基づいています。当社は、インフルエンサー情報の正確性の維持、向上に努めますが、インフルエンサー情報の正確性、最新性その他一切について、明示または黙示を問わず何ら保証するものではありません。インフルエンサー情報の誤り等に起因して契約者に損害が生じた場合も、当社は一切責任を負わないものとします。
            </h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>当社は、本サービスに関して契約者が被った損害につき、過去１２ヶ月間に契約者が当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>４．</h4>
            <h4>当社は、本サービスに関して契約者が被った損害につき、前項に定める以外には一切責任を負わないものとします。また、本サービスに関連して契約者と第三者との間において生じた紛争等については、契約者が自己の責任によって解決するものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１２条（本サービスの停止等）</h3>
          <h4>当社は、以下のいずれかに該当する場合は、契約者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</h4>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>その他、当社が停止または中断を必要と判断した場合</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１３条（本サービスの内容の変更、終了）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>当社が本サービスの提供を終了する場合、当社は契約者に事前に通知するものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１４条（退会）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>契約者は、当社所定の手続を完了することにより、本サービスから退会して本契約を解除することができるものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>退会に際し、契約者が当社に対して負担する債務がある場合は、契約者は、退会と同時に当社に対して負担する全ての債務について当然に期限の利益を喪失し、当社に対して直ちに全額を支払うものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>退会後の契約者及びユーザー情報の取扱いについては、第９条の定めに従うものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１５条（登録抹消等）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>当社は、契約者又はユーザーが次の各号のいずれかに該当する場合は、催告その他の手続を要することなく、直ちに契約者及びユーザーによる本サービスの利用を一時的に停止し、または当該契約者及びユーザーに係る登録を抹消して本契約を解除することができるものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>本規約のいずれかの条項に違反した場合</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>登録情報に虚偽、誤り等があることが判明した場合</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>当社からの問い合わせその他の回答を求める連絡に対して３０日間以上応答がない場合</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>監督官庁による営業許可取消し、停止その他行政処分があったとき</h4>
          </div>
          <div class="d-flex">
            <h4>（5）</h4>
            <h4>支払不能若しくは支払停止または手形もしくは小切手が不渡りとなったとき</h4>
          </div>
          <div class="d-flex">
            <h4>（6）</h4>
            <h4>破産手続開始、再生手続開始、更生手続開始または特別清算開始の申立てがあったとき</h4>
          </div>
          <div class="d-flex">
            <h4>（7）</h4>
            <h4>仮差押え、仮処分、強制執行または競売の申立てがあったとき</h4>
          </div>
          <div class="d-flex">
            <h4>（8）</h4>
            <h4>公租公課の滞納処分を受けたとき</h4>
          </div>
          <div class="d-flex">
            <h4>（9）</h4>
            <h4>手形交換所の取引停止の処分を受けたとき</h4>
          </div>
          <div class="d-flex">
            <h4>（10）</h4>
            <h4>財産状態が悪化し、または悪化するおそれがあると認められる相当の事由があるとき</h4>
          </div>
          <div class="d-flex">
            <h4>（11）</h4>
            <h4>本契約に定める条項につき重大な違反があったとき</h4>
          </div>
          <div class="d-flex">
            <h4>（12）</h4>
            <h4>その他本契約を継続し難い重大な事由が生じたとき</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>前項各号のいずれかの事由に該当した場合、契約者は、当社に対して負担する全ての債務について当然に期限の利益を喪失し、当社に対して直ちに全額を支払うものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>
              当社は、第１項に基づく登録抹消により契約者に損害が生じた場合であっても、当該損害の賠償義務を負わないものとします。また、当社は、契約者が第１項各号に該当したことにより当社に生じた損害につき、契約者に対して損害賠償請求することができるものとします。
            </h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１６条（反社会的勢力の排除）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>
              契約者及び当社は、その役員（取締役、執行役、執行役員、監査役またはこれらに準ずる者をいいます。）または従業員において、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下「反社会的勢力等」といいます。）に該当しないこと、及び次の各号のいずれにも該当せず、かつ本契約期間中においても該当しないことを確約し、これを保証するものとします。
            </h4>
          </div>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>反社会的勢力等が経営を支配していると認められる関係を有すること</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>反社会的勢力等が経営に実質的に関与していると認められる関係を有すること</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>自己、自社若しくは第三者の不正の利益を図る目的または第三者に損害を加える 目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>反社会的勢力等に対して暴力団員等であることを知りながら資金等を提供し、または便宜を供与するなどの関与</h4>
          </div>
          <div class="d-flex">
            <h4>（5）</h4>
            <h4>役員または経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>契約者及び当社は、自らまたは第三者を利用して次の各号の一にても該当する行為を行わないことを確約し、これを保証するものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>暴力的な要求行為</h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>法的な責任を超えた不当な要求行為</h4>
          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>取引に関して、脅迫的な言動をし、または暴力を用いる行為</h4>
          </div>
          <div class="d-flex">
            <h4>（4）</h4>
            <h4>風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為</h4>
          </div>
          <div class="d-flex">
            <h4>（5）</h4>
            <h4>その他前各号に準ずる行為</h4>
          </div>
          <div class="d-flex">
            <h4>３．</h4>
            <h4>契約者及び当社は、相手方が本条に違反した場合には、催告その他の手続を要しないで、直ちに本契約を解除することができるものとします。</h4>
          </div>
          <div class="d-flex">
            <h4>４．</h4>
            <h4>契約者及び当社は、本条に基づく解除により相手方に損害が生じた場合であっても、当該損害の賠償義務を負わないものとします。また、当該解除に 起因して自己に生じた損害につき、相手方に対し損害賠償請求することができるものとします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１７条（存続条項）</h3>
          <h4>退会、登録抹消、その他終了原因の如何にかかわらず、本契約が終了した後も、本規約第８条、第９条、第１０条、第１１条、第１４条、第１５条、第１６条第４項、本条及び第１９条の各規定は引き続き有効に存続するものとします。</h4>
        </div>
        <div class="mt-40px">
          <h3>第１８条（本契約上の地位の譲渡等）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>契約者は、当社の書面による事前の承諾なく、本契約上の地位または本契約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>
              当社は、本サービスに係る事業を第三者に譲渡した場合は、当該事業譲渡に伴い本契約上の地位、本契約に基づく権利及び義務並びに契約者に係る登録情報を当該事業譲渡の譲受人に譲渡することができるものとし、契約者は、かかる譲渡につき予め同意するものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
            </h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>第１９条（準拠法及び管轄裁判所）</h3>
          <div class="d-flex">
            <h4>１．</h4>
            <h4>本規約の準拠法は日本法とします。</h4>
          </div>
          <div class="d-flex">
            <h4>２．</h4>
            <h4>本契約に関連する一切の紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。</h4>
          </div>
        </div>
        <div class="mt-40px">
          <h4>令和３年１１月５日制定</h4>
        </div>
      </div>
      <div class="terms__content" id="privacy-policy">
        <div class="mt-60px">
          <h1>プライバシーポリシー</h1>
          <h4>ゼロスタ株式会社（以下「当社」といいます。）は、当社が提供するインフルエンサー検索サービス「INFRECT」（以下「本サービス」といいます。）における、個人情報を含む個人に関する情報（以下「パーソナルデータ」といいます。）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。</h4>
        </div>
        <div class="mt-40px">
          <h3>１ 取得するパーソナルデータ</h3>
          <h4>本サービスにおいて当社が取得するパーソナルデータは、その取得方法に応じ、以下のようなものとなります。</h4>
          <div class="d-flex">
            <h4>（1）</h4>
            <h4>
              ユーザー（<a href="#terms">本サービス利用規約</a>第２条第５号の「ユーザー」をいいます。以下同様です。）からご提供いただくパーソナルデータ本サービスを利用するために、又は本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。<br/>
              ・氏名等プロフィールに関する情報<br />
              ・メールアドレス等連絡先に関する情報<br />
              ・ユーザーの肖像を含む静止画情報<br />
              ・入力フォームその他当社が定める方法を通じてユーザーが入力又は送信する情報
            </h4>
          </div>
          <div class="d-flex">
            <h4>（2）</h4>
            <h4>
              ユーザーが本サービスを利用するに当たって当社が取得するパーソナルデータ当社は、本サービスへのアクセス状況やご利用方法に関する情報を取得することがあります。これには以下の情報が含まれます。<br />
              ・リファラ<br />
              ・IP アドレス<br />
              ・サーバーアクセスログに関する情報<br />
              ・Cookie、ADID、IDFA その他の識別子
            </h4>

          </div>
          <div class="d-flex">
            <h4>（3）</h4>
            <h4>
              インフルエンサーに係るパーソナルデータ<br />
              当社は、インフルエンサーがSNS 等を通じて公開しているパーソナルデータを取得することがあります。
            </h4>
          </div>
        </div>
        <div class="mt-40px">
          <h3>２ 利用目的</h3>
          <h4>
            本サービスの提供に関するパーソナルデータの具体的な利用目的は以下のとおりです。<br />
            ・本サービスの提供、維持、保護及び改善のため<br />
            ・ユーザーのトラフィック測定及び行動測定のため<br />
            ・広告の配信、表示及び効果測定のため<br />
            ・本サービスに関するご案内、お問い合わせ等への対応のため<br />
            ・本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為への対応のため<br />
            ・本サービスに関する規約等の変更を通知するため
          </h4>
        </div>
        <div class="mt-40px">
          <h3>３ 第三者提供</h3>
          <h4>当社は、個人情報保護法その他の法令に基づく場合を除き、あらかじめご本人の同意を得ないで、個人情報を第三者に提供しません。</h4>
        </div>
        <div class="mt-40px">
          <h3>４ 個人情報の開示等</h3>
          <h4>当社は、ご本人から、個人情報の開示、訂正・追加・削除、利用停止・消去等の請求があったときは、ご本人からの請求であることを確認の上、遅滞なく対応します。ただし、個人情報保護法その他の法令により、当社が当該請求に応じる義務を負わない場合は、この限りではありません。</h4>
        </div>
        <div class="mt-40px">
          <h3>５ お問い合わせ等の窓口</h3>
          <h4>ご意見、ご質問、苦情のお申出その他パーソナルデータの取扱いに関するお問い合わせは、当社のメールアドレス（<a href="mailto:support@infrect.com">support@infrect.com</a>）までお願いいたします。</h4>
          <h4>また、当社は、インフルエンサーがSNS 等を通じて公開しているパーソナルデータ（これらを利用した分析結果等を含む）を、本サービスを通じてユーザーに提供することがあります。当社は、インフルエンサー本人からかかる提供の停止の求めがあった場合は、その後の提供を停止します。停止の求めは上記メールアドレスまでお願いいたします。</h4>
        </div>
        <div class="mt-40px">
          <h3>６ プライバシーポリシーの変更手続</h3>
          <h4>当社は、必要に応じて本ポリシーを変更します。ただし、法令上ご本人の同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意した方に対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、当社のウェブサイト上での表示その他の適切な方法により、変更後の本ポリシーの施行時期及び内容を周知し、又はご本人に通知します。</h4>
        </div>
        <div class="mt-40px">
          <h4>令和３年１１月５日制定</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Terms",
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.terms {
  margin-bottom: 120px;
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    margin: 0 20px;
    & h3, h4 {
      margin: 5px 0;
    }
    & h1 {
      color: map-get($colors, "blue400");
    }
    & h3 {
      color: map-get($colors, "blue400");
    }
  }
}
</style>
