<template>
  <div class="base-container">
    <sidebar :current-tab="0"></sidebar>
    <div class="main">
      <base-header display-type="HOME" header-title="ホーム"></base-header>
      <div class="home">
        <div class="home__card look_up">
          <div>
            <h2>インフルエンサーを探す</h2>
            <p>AIによる高度な検索ツールを活用して、 <br />希望のインフルエンサーを探しましょう。</p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'SearchHome' }">
                <base-button color="white" size="short">はじめる</base-button>
              </router-link>
            </div>
          </div>
          <img src="@/assets/img/search-influencer.png" alt="検索" />
        </div>
        <div class="home__card interview">
          <div>
            <h2>インタビュー</h2>
            <p>
              各領域で活躍する先進的なインフルエンサーから<br />
              専門知識やノウハウ等の示唆を得る事で<br />
              顧客や市場の理解を深める事が可能です。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'Listing', query: { type: 0 } }">
                <base-button color="white" size="short">はじめる</base-button>
              </router-link>
            </div>
          </div>
          <img src="@/assets/img/interview_influencer_image.png" alt="インタビュー" />
        </div>
        <div class="home__card collaboration">
          <div>
            <h2>コラボレーション</h2>
            <p>
              高度な専門知識やノウハウを持つ<br />
              インフルエンサーと共創を行う事で、<br />
              顧客に刺さり売れ続ける商品やサービスの<br />
              開発を行う事が可能です。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'Listing', query: { type: 1 } }">
                <base-button color="white" size="short">はじめる</base-button>
              </router-link>
            </div>
          </div>
          <img src="@/assets/img/collaborate_influencer_image.png" alt="コラボレーション" />
        </div>
        <div class="home__card pr">
          <div>
            <h2>アンバサダー・PR投稿</h2>
            <p>
              効果を創出できるインフルエンサーをAIで見つけだし、<br />
              PR投稿してもらう事で、本当に届けたい顧客に<br />
              商品・サービスを訴求する事が可能です。
            </p>
            <div class="home__card__btn-container">
              <router-link :to="{ name: 'Listing', query: { type: 2 } }">
                <base-button color="white" size="short">はじめる</base-button>
              </router-link>
            </div>
          </div>
          <img src="@/assets/img/pr_influencer_image.png" alt="PR" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { set } from "vue-gtag";

export default defineComponent({
  name: "Home",
  components: {
    Sidebar,
    BaseHeader,
    BaseButton,
  },
  setup() {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      currentSearchTab: 0,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    set({ userId: state.user?.id });
    const setCurrentSearchTab = (index: number) => {
      state.currentSearchTab = index;
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.home {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
  @include mq(xs) {
    display: block;
    margin-top: 40px;
  }
  @include mq(lg) {
    display: flex;
    margin-top: 40px;
  }
  &__card {
    display: flex;
    position: relative;
    width: calc(50% - 55px);
    min-width: 480px;
    height: 240px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    @include mq(xs) {
      width: 90%;
      padding: 20px 10px;
      height: fit-content;
      min-width: 240px;
      margin: 20px auto;
    }
    @include mq(lg) {
      width: calc(50% - 55px);
      padding: 20px;
      min-width: 480px;
      height: 240px;
      margin: auto;
    }
    &.look_up {
      background-color: #adb8f5;
    }
    &.interview {
      background-color: #8bd5ad;
    }
    &.collaboration {
      background-color: #adcde6;
    }
    &.pr {
      background-color: #ea9090;
    }
    & h2 {
      color: white;
      font-weight: 900;
      font-size: 28px;
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 17px;
        margin-bottom: 12px;
      }
      @include mq(lg) {
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
    & p {
      color: white;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 45px;
      @include mq(xs) {
        font-size: 12px;
        margin-bottom: 10px;
      }
      @include mq(lg) {
        font-size: 14px;
        margin-bottom: 45px;
      }
    }
    & img {
      width: 190px;
      height: auto;
      @include mq(xs) {
        width: 80px;
        margin-left: 10px;
        vertical-align: bottom;
      }
      @include mq(lg) {
        width: 190px;
        margin-left: unset;
        vertical-align: unset;
      }
    }
    & .button {
      color: map-get($colors, "blue300") !important;
    }
  }
}
</style>
