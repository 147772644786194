import axios from "@/store/axios";
import { transformResponse } from "@/store/axios";
import { Commit } from "vuex";
import { Plan, StripeSessionResponse } from "@/types";

interface State {
  errorMessage: string | null;
  plans: Plan[];
  loadPlansSuccess: boolean;
  sessionId: string | null;
  loadSessionIdSuccess: boolean;
  loading: boolean;
  cancelPlanSuccess: boolean;
}

const state: State = {
  errorMessage: null,
  plans: [],
  loadPlansSuccess: false,
  sessionId: null,
  loadSessionIdSuccess: false,
  loading: false,
  cancelPlanSuccess: false,
};
const plans = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setPlans(state: State, plans: Plan[]) {
      state.plans = plans;
    },
    setLoadPlansSuccess(state: State, flag: boolean) {
      state.loadPlansSuccess = flag;
    },
    setSessionId(state: State, sessionId: string) {
      state.sessionId = sessionId;
    },
    setLoadSessionIdSuccess(state: State, flag: boolean) {
      state.loadSessionIdSuccess = flag;
    },
    setLoading(state: State, flag: boolean) {
      state.loading = flag;
    },
    setCancelPlanSuccess(state: State, flag: boolean) {
      state.cancelPlanSuccess = flag;
    },
  },
  actions: {
    async getPlans({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setLoadPlansSuccess", false);
      try {
        const res = await axios.get("api/v1/plans/");
        commit("setPlans", res.data);
        commit("setLoadPlansSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async getSessionId({ commit }: { commit: Commit }, planId: string) {
      commit("setErrorMessage", null);
      commit("setLoadSessionIdSuccess", false);
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/plans/purchase/", { planId: planId });
        commit("setSessionId", res.data.sessionId);
        commit("setLoadSessionIdSuccess", true);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
    async cancelPlan({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      commit("setCancelPlanSuccess", false);
      commit("setLoading", true);
      try {
        await axios.post("api/v1/plans/cancel/");
        commit("setCancelPlanSuccess", true);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
  },
};

export default plans;
