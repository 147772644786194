
import { defineComponent, reactive, toRefs, computed, watch, PropType } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GenderType } from "@/enum/genderType";
import { SNSType } from "@/enum/snsType";
import { TaskType } from "@/enum/taskType";
import { AgeGroup } from "@/enum/ageGroup";
import { Task, UpdateTaskParams, Age, Hashtag } from "@/types";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
export default defineComponent({
  name: "Listing",
  components: {
    BaseSelectBox,
    BaseTextArea,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    CheckMark,
    ExclamationMark,
  },
  props: {
    limitViewing: {
      type: Number,
      default: 0,
    },
    remainingViewing: {
      type: Number,
      default: 0,
    },
    task: {
      type: Object as PropType<Task>,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("tasks/loadTask", route.params.id);
    store.dispatch("tasks/loadTasks");
    type ItemType = {
      key: string;
      text: string;
    };
    type ItemNumberType = {
      key: number;
      text: string;
    };
    const state = reactive({
      show: {
        first: true,
        second: false,
        third: false,
        dialog: false,
        updateTask: false,
        errorDialog: false,
      },
      company: computed(() => store.state.companies.company),
      user: computed(() => store.state.users.user),
      task: computed(() => store.state.tasks.task),
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      createTaskSuccess: computed(() => store.state.tasks.createTaskSuccess),
      loadTaskSuccess: computed(() => store.state.tasks.loadTaskSuccess),
      updateTaskSuccess: computed(() => store.state.tasks.updateTaskSuccess),
      checked: false,
      hashtags: [] as string[],
      attributes: null as null | string,
      taskType: null as null | ItemNumberType,
      snsType: null as null | ItemNumberType,
      genderType: null as null | ItemNumberType,
      listingNum: null as null | number,
      ages: [] as ItemType[],
      minFollower: null as null | number,
      maxFollower: null as null | number,
      followerGenderType: null as null | ItemNumberType,
      followerAges: [] as ItemType[],
      followerAttributes: null as null | string,
      followerHashtags: [] as string[],
      taskTitle: null as null | string,
      taskBackground: null as null | string,
      taskDetail: null as null | string,
    });
    let loadTaskSuccess = computed(() => state.loadTaskSuccess);
    watch(loadTaskSuccess, (val) => {
      if (val) {
        state.taskTitle = state.task?.title;
        state.taskType = {
          key: state.task?.type,
          text: TaskType.findTextByKey(state.task?.type),
        };
        state.taskBackground = state.task?.backGround;
        state.taskDetail = state.task?.detail;
        state.snsType = {
          key: state.task?.snsType,
          text: SNSType.findTextByKey(state.task?.snsType),
        };
        state.listingNum = state.task?.listingNum;
        state.genderType = {
          key: state.task?.gender,
          text: GenderType.findTextByKey(state.task?.gender),
        };
        state.followerGenderType = {
          key: state.task?.followerGender,
          text: GenderType.findTextByKey(state.task?.followerGender),
        };
        state.ages = state.task?.ages.slice().map((x: Age) => ({ key: x.id, text: x.age }));
        state.followerAges = state.task?.followerAges.slice().map((x: Age) => ({ key: x.id, text: x.age }));
        state.minFollower = state.task?.minFollowers;
        state.maxFollower = state.task?.maxFollowers;
        state.attributes = state.task?.attribute;
        state.hashtags = state.task?.hashtags.slice().map((x: Hashtag) => x.tag);
        state.followerHashtags = state.task?.followerHashtags.slice().map((x: Hashtag) => x.tag);
        state.followerAttributes = state.task?.followerAttribute;
      }
    });
    const validateFirst = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      }
      if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else {
        state.show.first = false;
        state.show.second = true;
      }
    };
    const validateSecond = () => {
      state.formErrorMessage = null;
      if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else if (state.minFollower && (state.minFollower < 0 || !Number.isInteger(Number(state.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && (state.maxFollower < 0 || !Number.isInteger(Number(state.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && state.minFollower && Number(state?.minFollower) > Number(state?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数より大きい数である必要があります";
        state.show.errorDialog = true;
      } else {
        state.show.second = false;
        state.show.third = true;
      }
    };
    const updateTask = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else {
        if (state.checked) {
          const params = { isReadListing: state.checked };
          store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
        }
        const params: UpdateTaskParams = {
          type: state.taskType.key,
          title: state.taskTitle,
          backGround: state.taskBackground,
          detail: state.taskDetail,
          snsType: state.snsType.key,
          attribute: state.attributes,
          followerAttribute: state.followerAttributes,
          ages: state.ages.slice().map((x: ItemType) => ({ age: x.text })),
          followerAges: state.followerAges.slice().map((x: ItemType) => ({ age: x.text })),
          hashtags: state.hashtags.slice().map((x: string) => ({ tag: x })),
          followerHashtags: state.followerHashtags.slice().map((x: string) => ({ tag: x })),
        };
        if (state.minFollower) {
          params.minFollowers = state.minFollower;
        } else {
          params.minFollowers = null;
        }
        if (state.maxFollower) {
          params.maxFollowers = state.maxFollower;
        } else {
          params.maxFollowers = null;
        }
        if (state.genderType?.key || state.genderType?.key === 0) params["gender"] = state.genderType?.key;
        if (state.followerGenderType?.key || state.followerGenderType?.key === 0) params["followerGender"] = state.followerGenderType?.key;
        store.dispatch("tasks/updateTask", { params, taskId: state.task.id });
      }
      state.show.updateTask = true;
    };
    let updateTaskSuccess = computed(() => state.updateTaskSuccess);
    watch(updateTaskSuccess, (val: boolean) => {
      if (val) state.show.dialog = false;
    });
    const openListDialog = () => {
      state.formErrorMessage = null;
      if (state.user?.isReadListing) {
        state.show.errorDialog = false;
        updateTask();
      } else {
        state.show.dialog = true;
      }
    };
    const updateTaskDone = () => {
      router.push({ name: "TaskDetail", params: { id: state.task?.id } });
    };
    return {
      ...toRefs(state),
      GenderType,
      SNSType,
      TaskType,
      AgeGroup,
      updateTask,
      updateTaskDone,
      openListDialog,
      validateFirst,
      validateSecond
    };
  },
});
