<template>
  <div class="top" id="top">
    <header id="scrollArea" ref="LPHeader">
      <a href="#top"><InfrectLogo :fill-color="fillColor"></InfrectLogo></a>
      <div class="header-menu">
        <router-link :to="{ name: 'SignIn' }" target="_blank" rel="noreferrer noopener">
          <div class="header-menu__login">ログイン</div>
        </router-link>
        <router-link :to="{ name: 'Trial' }" target="_blank" rel="noreferrer noopener">
          <div class="header-menu__trial">無料で体験</div>
        </router-link>
      </div>
      <hamburger-menu class="hamburger-menu" :links="links" :background-color="'#79B5DB'"></hamburger-menu>
    </header>
    <div class="top__main-image">
      <div class="top__main-image__left">
        <div class="top__main-image__left__catch">
          <div>
            <div class="top__main-image__left__catch__text">
              <span>世</span><span>界</span><span>最</span><span>高</span><span>峰</span>の<br />
              データ分析AIを用いた<br />
              インフルエンサーマーケティングツール
            </div>
          </div>
        </div>
        <div class="top__main-image__left__button">
          <router-link :to="{ name: 'Trial' }" target="_blank" rel="noreferrer noopener">
            <div class="trial-button">
              <div class="trial-button__main">まずは無料で体験する ▶︎</div>
              <div class="trial-button__catch">7日間の無料トライアル実施中！︎</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="top__main-image__right">
        <img src="@/assets/img/top-main-view.png" alt="main image" />
      </div>
      <div class="top__main-image__button">
        <router-link :to="{ name: 'Trial' }">
          <div class="trial-button">
            <div class="trial-button__main">まずは無料で体験する ▶︎</div>
            <div class="trial-button__catch">7日間の無料トライアル実施中！︎</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="top__container">
      <div class="top__container__title">
        <div class="top__container__title__main">間違った<br class="sm" />インフルエンサーマーケティングは<br />もうやめにしませんか？</div>
      </div>
      <div class="top__pains">
        <div class="top__pains__pain">
          <img src="@/assets/img/top-pain-1.svg" alt="search" />
          <div class="top__pains__pain__text">商品・サービスにマッチした<br />インフルエンサーが探せない</div>
        </div>
        <div class="top__pains__pain">
          <img src="@/assets/img/top-pain-2.svg" alt="search" />
          <div class="top__pains__pain__text">インフルエンサーの<br />属性が把握できない</div>
        </div>
        <div class="top__pains__pain">
          <img src="@/assets/img/top-pain-3.svg" alt="search" />
          <div class="top__pains__pain__text">ビジネスにインフルエンサーを<br />起用したことがない</div>
        </div>
      </div>
    </div>
    <div class="top__container">
      <div class="top__arrow">
        <img src="@/assets/img/top-pain-arrow.svg" alt="pain-arrow" />
      </div>
    </div>
    <div class="top__container">
      <div class="top__service__title"><span>誰でも簡単に効果の出る</span><br />インフルエンサーマーケティングを実現</div>
      <div class="top__service">
        <div class="top__service__services search">
          <img src="@/assets/img/top-service-1.svg" alt="検索機能" />
          <div class="top__service__services__text">AIを用いた検索機能で<br />ビジネスにマッチした<br />最適なインフルエンサーを探せる</div>
        </div>
        <div class="top__service__services">
          <img src="@/assets/img/top-service-2.svg" alt="分析機能" />
          <div class="top__service__services__text">分析レポートで<br />インフルエンサーの<br />属性を可視化</div>
        </div>
        <div class="top__service__services">
          <img src="@/assets/img/top-service-3.svg" alt="アサインまでサポート" />
          <div class="top__service__services__text">専任スタッフが伴走して<br />インフルエンサーとの<br />ビジネスをサポート</div>
        </div>
      </div>
    </div>
    <div class="top__button-aria">
      <router-link :to="{ name: 'Trial' }" target="_blank" rel="noreferrer noopener">
        <div class="trial-button">
          <div class="trial-button__main">まずは無料で体験する ▶︎</div>
          <div class="trial-button__catch">7日間の無料トライアル実施中！︎</div>
        </div>
      </router-link>
    </div>
    <div class="top__about" id="about">
      <img src="@/assets/img/top-about-laptop.jpg" alt="laptop" />
      <div class="top__about__description">
        <div class="top__about__description__logo"><img src="@/assets/img/logo-blue.svg" alt="infrect for marketing" />とは</div>
        <div class="top__about__description__text">
          <span>AIとインフルエンサーで</span><br />
          御社の商品・サービスが売れ続ける<br />
          仕組みを内製化できるツールです。
        </div>
      </div>
    </div>
    <div class="top__about__sm">
      <div class="top__about__sm__logo"><img src="@/assets/img/logo-blue.svg" alt="infrect for marketing" />とは</div>
      <img src="@/assets/img/top-about-laptop-sm.png" alt="laptop" />
      <div class="top__about__sm__text">
        AIとインフルエンサーで御社の商品・サービスが<br />
        売れ続ける仕組みを内製化できるツールです。
      </div>
    </div>
    <div class="top__container">
      <div class="top__container__title">
        <div class="top__container__title__main">対応しているSNS</div>
      </div>
      <div class="top__sns">
        <div class="top__sns__wrapper">
          <div class="top__sns__card"><img src="@/assets/img/instagram.jpg" alt="Instagram" />Instagram</div>
          <div class="top__sns__card"><img src="@/assets/img/youtube.jpg" alt="YouTube" />YouTube</div>
          <div class="top__sns__card"><img src="@/assets/img/tiktok.jpg" alt="TikTok" />TikTok</div>
          <div class="top__sns__card"><img src="@/assets/img/twitter.jpg" alt="Twitter" />Twitter</div>
        </div>
        <div class="top__sns__text">Instagram・YouTube・TikTok・Twitterで活躍する<br />インフルエンサーの検索・分析からお仕事の共創までまるごとサポート！</div>
      </div>
    </div>
    <div class="top__container__blue">
      <div class="top__container__title">
        <div class="top__container__title__main">最適なインフルエンサーを<br class="sm" />アサインするための<br class="sm" />時間を大幅に削減！</div>
      </div>
      <div class="top__workload">
        <img src="@/assets/img/top-workload.png" alt="workload" />
        <div class="top__workload__text">インフルエンサーの検索・分析・効果検証・レポート作成などの数日かかる業務の工数を<br />INFRECTを活用することで<br class="sm" />大幅に削減することが出来ます。</div>
      </div>
    </div>
    <div class="top__container" id="function">
      <div class="top__container__title">
        <div class="top__container__title__main">INFRECTの機能とサポート</div>
      </div>
      <div class="top__functions">
        <div class="top__functions__function">
          <div class="top__functions__function__image">
            <img src="@/assets/img/top-function-search-detail.jpg" v-if="search.detail" alt="詳細検索" />
            <img src="@/assets/img/top-function-search-id.jpg" v-else-if="search.id" alt="名前・IDで検索" />
            <img src="@/assets/img/top-function-search-hashtag.jpg" v-else-if="search.hashtag" alt="ハッシュタグ検索" />
          </div>
          <div class="top__functions__function__description">
            <div class="top__functions__function__description__title">AIによるインフルエンサーの検索</div>
            <div class="top__functions__function__description__text">
              ニーズに合わせたインフルエンサーを、<br />
              「簡単」にかつ「高精度」で<br />
              スピーディーに探し出すことが出来ます。
            </div>
            <div class="top__functions__function__description__tabs">
              <div :class="['top__functions__function__description__tabs__tab', search.detail ? 'selected' : '']" @click="searchImageToggle('detail')">詳細検索</div>
              <div :class="['top__functions__function__description__tabs__tab', search.id ? 'selected' : '']" @click="searchImageToggle('id')">名前・IDで検索</div>
              <div :class="['top__functions__function__description__tabs__tab', search.hashtag ? 'selected' : '']" @click="searchImageToggle('hashtag')">ハッシュタグ検索</div>
            </div>
          </div>
        </div>
        <div class="top__functions__function">
          <div class="top__functions__function__description">
            <div class="top__functions__function__description__title">AIが抽出する分析レポート</div>
            <div class="top__functions__function__description__text">
              SNSや、従来のインフルエンサー分析ツールで<br />
              は得られない情報を取得できるので、<br />
              高度な分析の情報をもとに適切な<br />
              インフルエンサーをビジネスに登用出来ます。
            </div>
            <div class="top__functions__function__description__tabs">
              <div :class="['top__functions__function__description__tabs__tab', report.detail ? 'selected' : '']" @click="reportImageToggle('detail')">詳細情報</div>
              <div :class="['top__functions__function__description__tabs__tab', report.post ? 'selected' : '']" @click="reportImageToggle('post')">投稿情報</div>
              <div :class="['top__functions__function__description__tabs__tab', report.audience ? 'selected' : '']" @click="reportImageToggle('audience')">オーディエンス情報</div>
            </div>
          </div>
          <div class="top__functions__function__image">
            <img src="@/assets/img/top-function-report-detail.jpg" v-if="report.detail" alt="詳細情報" />
            <img src="@/assets/img/top-function-report-post.jpg" v-if="report.post" alt="投稿情報" />
            <img src="@/assets/img/top-function-report-audience.jpg" v-if="report.audience" alt="オーディエンス情報" />
          </div>
        </div>
        <div class="top__functions__function">
          <img src="@/assets/img/top-function-prediction.jpg" alt="いいね分析" />
          <div class="top__functions__function__description">
            <div class="top__functions__function__description__title">AIによるいいね分析機能</div>
            <div class="top__functions__function__description__text">
              弊社が開発した最新鋭のAIが、<br />
              実在するインフルエンサーの情報や、<br />
              プロモーションでお使いいただく画像、<br />
              投稿文章、投稿時間を用いてInstagramで<br />
              獲得できるいいね数を予測いたします。
            </div>
          </div>
        </div>
        <div class="top__functions__function">
          <div class="top__functions__function__description">
            <div class="top__functions__function__description__title">インフルエンサーとの<br />ビジネスをサポート</div>
            <div class="top__functions__function__description__text">
              インフルエンサーのリストアップから<br />
              アサイン交渉まで弊社が代行することもできます！<br />
              自社で工数をかけずに<br />
              インフルエンサーとのお仕事を実現できます。<br />
            </div>
          </div>
          <img src="@/assets/img/top-function-business.jpg" alt="ビジネス" />
        </div>
      </div>
      <div class="top__functions__sm">
        <div class="top__functions__sm__function">
          <div class="top__functions__sm__function__title">AIによるインフルエンサーの検索</div>
          <div class="top__functions__sm__function__image">
            <img src="@/assets/img/top-function-search-detail.jpg" v-if="search.detail" alt="詳細検索" />
            <img src="@/assets/img/top-function-search-id.jpg" v-else-if="search.id" alt="名前・IDで検索" />
            <img src="@/assets/img/top-function-search-hashtag.jpg" v-else-if="search.hashtag" alt="ハッシュタグ検索" />
          </div>
          <div class="top__functions__sm__function__tabs">
            <div :class="['top__functions__sm__function__tabs__tab', search.detail ? 'selected' : '']" @click="searchImageToggle('detail')">詳細検索</div>
            <div :class="['top__functions__sm__function__tabs__tab', search.id ? 'selected' : '']" @click="searchImageToggle('id')">名前・IDで検索</div>
            <div :class="['top__functions__sm__function__tabs__tab', search.hashtag ? 'selected' : '']" @click="searchImageToggle('hashtag')">ハッシュタグ検索</div>
          </div>
          <div class="top__functions__sm__function__text">
            ニーズに合わせたインフルエンサーを、<br />
            「簡単」にかつ「高精度」で<br />
            スピーディーに探し出すことが出来ます。
          </div>
        </div>
        <div class="top__functions__sm__function">
          <div class="top__functions__sm__function__title">AIが抽出する分析レポート</div>
          <div class="top__functions__sm__function__image">
            <img src="@/assets/img/top-function-report-detail.jpg" v-if="report.detail" alt="詳細情報" />
            <img src="@/assets/img/top-function-report-post.jpg" v-if="report.post" alt="投稿情報" />
            <img src="@/assets/img/top-function-report-audience.jpg" v-if="report.audience" alt="オーディエンス情報" />
          </div>
          <div class="top__functions__sm__function__tabs">
            <div :class="['top__functions__sm__function__tabs__tab', report.detail ? 'selected' : '']" @click="reportImageToggle('detail')">詳細情報</div>
            <div :class="['top__functions__sm__function__tabs__tab', report.post ? 'selected' : '']" @click="reportImageToggle('post')">投稿情報</div>
            <div :class="['top__functions__sm__function__tabs__tab', report.audience ? 'selected' : '']" @click="reportImageToggle('audience')">オーディエンス情報</div>
          </div>
          <div class="top__functions__sm__function__text">
            SNSや、従来のインフルエンサー分析ツールで<br />
            は得られない情報を取得できるので、<br />
            高度な分析の情報をもとに適切な<br />
            インフルエンサーをビジネスに登用出来ます。
          </div>
        </div>
        <div class="top__functions__sm__function">
          <div class="top__functions__sm__function__title">AIによるいいね分析機能</div>
          <div class="top__functions__sm__function__image">
            <img src="@/assets/img/top-function-prediction.jpg" alt="いいね分析" />
          </div>
          <div class="top__functions__sm__function__text">
            弊社が開発した最新鋭のAIが、<br />
            実在するインフルエンサーの情報や、<br />
            プロモーションでお使いいただく画像、<br />
            投稿文章、投稿時間を用いてInstagramで<br />
            獲得できるいいね数を予測いたします。
          </div>
        </div>
        <div class="top__functions__sm__function">
          <div class="top__functions__sm__function__title">インフルエンサーとの<br />ビジネスをサポート</div>
          <div class="top__functions__sm__function__image">
            <img src="@/assets/img/top-function-business.jpg" alt="ビジネス" />
          </div>
          <div class="top__functions__sm__function__text">
            インフルエンサーのリストアップから<br />
            アサイン交渉まで弊社が代行することもできます！<br />
            自社で工数をかけずに<br />
            インフルエンサーとのお仕事を実現できます。<br />
          </div>
        </div>
      </div>
    </div>
    <div class="top__container__blue">
      <div class="top__container__title">
        <div class="top__container__title__main">INFRECTが選ばれている３つの理由</div>
      </div>
      <div class="top__reasons">
        <div class="top__reasons__inner">
          <div class="top__reasons__reason">
            <img src="@/assets/img/top-reason-data.png" alt="reason" />
          </div>
          <div class="top__reasons__reason">
            <img src="@/assets/img/top-reason-ai.png" alt="reason" />
          </div>
          <div class="top__reasons__reason">
            <img src="@/assets/img/top-reason-support.png" alt="reason" />
          </div>
        </div>
        <div class="top__reasons__text">従来のインフルエンサー分析ツールよりも<br class="sm" />高度な情報取得・分析が可能となります！</div>
      </div>
    </div>
    <div class="top__container">
      <div class="top__container__title">
        <div class="top__container__title__main">INFRECTを導入する具体的なメリット</div>
      </div>
      <div class="top__merit">
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-3.jpg" alt="merit3" />
          <div class="top__merit__card__text">
            自社の商品・サービスのPRに適した<br />
            インフルエンサーを簡単に見つけられる。
          </div>
        </div>
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-2.jpg" alt="merit2" />
          <div class="top__merit__card__text">
            インフルエンサーマーケティングの<br />
            業務工数を大幅削減。
          </div>
        </div>
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-1.jpg" alt="merit1" />
          <div class="top__merit__card__text">
            世界最高峰のAIデータ分析によって<br />
            効果検証が可能に。
          </div>
        </div>
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-4.jpg" alt="merit4" />
          <div class="top__merit__card__text">
            効果の出るSNS運用のノウハウを<br />
            社内に蓄積することができる。
          </div>
        </div>
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-5.jpg" alt="merit5" />
          <div class="top__merit__card__text">
            競合・業界分析が<br />
            誰でも簡単に行えるように。
          </div>
        </div>
        <div class="top__merit__card">
          <img src="@/assets/img/top-merit-6.jpg" alt="merit6" />
          <div class="top__merit__card__text">
            インフルエンサーを<br />
            ビジネスにアサインすることが可能。
          </div>
        </div>
      </div>
    </div>
    <div class="top__supports">
      <div class="top__supports__title">充実のサポート内容</div>
      <div class="top__supports__text">インフルエンサーとのビジネスが未経験でも、弊社担当者が徹底的にサポート</div>
      <div class="top__supports__wrapper">
        <div class="top__supports__support">
          <div class="top__supports__support__title">初回サポート</div>
          <img src="@/assets/img/top-support-1.png" alt="support1" />
          <div class="top__supports__support__text">
            検索方法や<br />
            分析レポートの見方など、<br />
            INFRECTの使い方をサポート
          </div>
        </div>
        <div class="top__supports__support">
          <div class="top__supports__support__title">運用サポート</div>
          <img src="@/assets/img/top-support-2.png" alt="support2" />
          <div class="top__supports__support__text">
            専属のサポートがつき<br />
            定期的にINFRECTの<br />
            運用をサポート
          </div>
        </div>
        <div class="top__supports__support">
          <div class="top__supports__support__title">ビジネスを成功に導くサポート</div>
          <img src="@/assets/img/top-support-3.png" alt="support3" />
          <div class="top__supports__support__text">
            インフルエンサーとのお仕事を<br />
            アサインからマネジメントまで<br />
            丸ごとサポート
          </div>
        </div>
      </div>
      <div class="top__button-aria">
        <router-link :to="{ name: 'Trial' }" target="_blank" rel="noreferrer noopener">
          <div class="trial-button">
            <div class="trial-button__main">まずは無料で体験する ▶︎</div>
            <div class="trial-button__catch">7日間の無料トライアル実施中！︎</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="top__container">
      <div class="top__container__title">
        <div class="top__container__title__main">
          多様なカテゴリの<br class="sm" />インフルエンサーを分析し<br />
          御社のビジネスに寄与
        </div>
      </div>
      <div class="top__category">
        <img src="@/assets/img/top-category-fashion.jpg" alt="ファッション" />
        <img src="@/assets/img/top-category-gourmet.jpg" alt="グルメ" />
        <img src="@/assets/img/top-category-beauty.jpg" alt="美容" />
        <img src="@/assets/img/top-category-health.jpg" alt="健康" />
        <img src="@/assets/img/top-category-sports.jpg" alt="スポーツ" />
        <img src="@/assets/img/top-category-culture.jpg" alt="カルチャー" />
        <img src="@/assets/img/top-category-leisure.jpg" alt="レジャー" />
        <img src="@/assets/img/top-category-other.jpg" alt="その他" />
      </div>
    </div>
    <div class="top__container">
      <div class="top__container__title">
        <div class="top__container__title__main">プラン</div>
        <div class="top__container__title__description">INFRECTではご利用状況に合わせたプランを<br class="sm" />ご用意しております。</div>
      </div>
      <div class="top__plans">
        <div class="top__plans__plan trial">
          <div class="top__plans__plan__title">７日間の無料トライアル</div>
          <table>
            <tr>
              <th>検索可能数</th>
              <td>250回まで</td>
            </tr>
            <tr>
              <th>レポート表示可能数</th>
              <td>150人まで</td>
            </tr>
            <tr>
              <th>インフルエンサー<br />リスト保存上限</th>
              <td>500人まで</td>
            </tr>
            <tr>
              <th>いいね分析</th>
              <td>150回まで</td>
            </tr>
            <tr>
              <th>メンバー上限</th>
              <td>3人</td>
            </tr>
            <tr>
              <th>利用期間</th>
              <td>７日間</td>
            </tr>
          </table>
        </div>
        <div class="top__plans__plan standard">
          <div class="top__plans__plan__title">スタンダードプラン</div>
          <table>
            <tr>
              <th>検索可能数</th>
              <td>月250回まで</td>
            </tr>
            <tr>
              <th>レポート表示可能数</th>
              <td>月150回まで</td>
            </tr>
            <tr>
              <th>インフルエンサー<br />リスト保存上限</th>
              <td>500人まで</td>
            </tr>
            <tr>
              <th>いいね分析</th>
              <td>月150回まで</td>
            </tr>
            <tr>
              <th>メンバー上限</th>
              <td>3人</td>
            </tr>
            <tr>
              <th>最低利用期間</th>
              <td>6ヶ月</td>
            </tr>
          </table>
        </div>
        <div class="top__plans__plan advanced">
          <div class="top__plans__plan__title">アドバンスドプラン</div>
          <table>
            <tr>
              <th>検索可能数</th>
              <td>月1,000回まで</td>
            </tr>
            <tr>
              <th>レポート表示可能数</th>
              <td>月500回まで</td>
            </tr>
            <tr>
              <th>インフルエンサー<br />リスト保存上限</th>
              <td>無制限</td>
            </tr>
            <tr>
              <th>いいね分析</th>
              <td>無制限</td>
            </tr>
            <tr>
              <th>メンバー上限</th>
              <td>10人</td>
            </tr>
            <tr>
              <th>最低利用期間</th>
              <td>3ヶ月</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="top__container__blue" id="faq">
      <div class="top__container__title">
        <div class="top__container__title__main">よくある質問</div>
      </div>
      <div class="top__faqs">
        <div class="top__faqs__faq">
          <div class="top__faqs__faq__question">
            <img src="@/assets/img/top-question-mark.png" alt="question-mark" />
            どのようなインフルエンサーを検索・分析・お仕事にアサインできますか？
          </div>
          <div class="top__faqs__faq__answer">
            <img src="@/assets/img/top-answer-mark.png" alt="answer-mark" />
            現時点で、Instagram・Youtube・Tiktok・Twitter上でアカウントを公開しており、<br />フォロワーが5000人以上いるインフルエンサーを検索・分析・お仕事にアサインできます。<br />
            今後のアップデートで、フォロワーが5000人以下のインフルエンサーに順次対応させていただきます。
          </div>
        </div>
        <div class="top__faqs__faq">
          <div class="top__faqs__faq__question">
            <img src="@/assets/img/top-question-mark.png" alt="question-mark" />
            使いきれなかった検索件数やレポート表示回数などは翌月に繰り越せますか？
          </div>
          <div class="top__faqs__faq__answer">
            <img src="@/assets/img/top-answer-mark.png" alt="answer-mark" />
            サービスの仕様上、繰り越すことは出来ません。
          </div>
        </div>
        <div class="top__faqs__faq">
          <div class="top__faqs__faq__question">
            <img src="@/assets/img/top-question-mark.png" alt="question-mark" />
            サービスの利用金額について
          </div>
          <div class="top__faqs__faq__answer">
            <img src="@/assets/img/top-answer-mark.png" alt="answer-mark" />
            INFRECTでは利用頻度に応じてご選択いただける、月額のサブスクリプションプランをご用意しております。<br />
            プランの詳細や金額に関しましてはお気軽にお問い合わせ下さい。
          </div>
        </div>
        <div class="top__faqs__faq">
          <div class="top__faqs__faq__question">
            <img src="@/assets/img/top-question-mark.png" alt="question-mark" />
            支払方法はクレジットカードだけですか？
          </div>
          <div class="top__faqs__faq__answer">
            <img src="@/assets/img/top-answer-mark.png" alt="answer-mark" />
            現時点ではクレジットカードのみの対応とさせて頂いております。
          </div>
        </div>
        <div class="top__faqs__faq">
          <div class="top__faqs__faq__question">
            <img src="@/assets/img/top-question-mark.png" alt="question-mark" />
            決済のタイミングはいつですか？
          </div>
          <div class="top__faqs__faq__answer">
            <img src="@/assets/img/top-answer-mark.png" alt="answer-mark" />
            毎月月末に翌月分のプラン利用権の決済を行わせて頂きます。<br />
            サービス利用開始月につきましても、プランの金額を請求させて頂きます。<br />
            サービスを退会する場合は決済前に退会を行ってください。<br />
            決済を行った月の月末までサービスを利用することが可能です。<br />
            都度課金のサービスについては、サービス利用を行ったタイミングでご登録のクレジットカードに課金させて頂きます。
          </div>
        </div>
      </div>
    </div>
    <div class="top__container" id="contact" ref="Contact">
      <div class="top__container__title">
        <div class="top__container__title__main">お問い合わせ</div>
        <div class="top__container__title__description">サービスをご利用希望の方や、サービスに関するご質問・ご意見・ご相談などお気軽にお問い合わせ下さい。</div>
      </div>
      <div class="contact">
        <div class="contact__main__container">
          <div class="contact__main__form" v-if="!success">
            <div class="error large" v-if="formError?.companyName || errorMessage?.companyName || formError?.departmentName || errorMessage?.departmentName || formError?.name || errorMessage?.name || formError?.email || errorMessage?.email || formError?.phone || errorMessage?.phone || formError?.content || errorMessage?.content || formError?.check">
              入力エラーがあります
            </div>
            <BaseSelectBox label="お問い合わせ種別" v-model="form.title" :border-width="1.5" :focus-border-width="2" required disable-lookup :items="selectItems"></BaseSelectBox>
            <div class="error" v-if="formError?.title || errorMessage?.title">
              {{ formError?.title || errorMessage?.title?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextField label="会社名" placeholder="ゼロスタ株式会社" v-model="form.companyName" required></BaseTextField>
            <div class="error" v-if="formError?.companyName || errorMessage?.companyName">
              {{ formError?.companyName || errorMessage?.companyName?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextField label="部署名" placeholder="マーケティング部" v-model="form.departmentName"></BaseTextField>
            <div class="error" v-if="formError?.departmentName || errorMessage?.departmentName">
              {{ formError?.departmentName || errorMessage?.departmentName?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextField label="お名前" placeholder="田中太郎" v-model="form.name" required></BaseTextField>
            <div class="error" v-if="formError?.name || errorMessage?.name">
              {{ formError?.name || errorMessage?.name?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextField label="メールアドレス" placeholder="example@infrect.com" v-model="form.email" required></BaseTextField>
            <div class="error" v-if="formError?.email || errorMessage?.email">
              {{ formError?.email || errorMessage?.email?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextField label="電話番号" placeholder="090-0000-0000" v-model="form.phone"></BaseTextField>
            <div class="error" v-if="formError?.phone || errorMessage?.phone">
              {{ formError?.phone || errorMessage?.phone?.join(" ") }}
            </div>
            <div class="spacer"></div>
            <BaseTextArea label="お問い合わせ内容" v-model="form.content" :height="234" :placeholder="getContentPlaceHolder()" required></BaseTextArea>
            <div class="error" v-if="formError?.content || errorMessage?.content">
              {{ formError?.content || errorMessage?.content?.join(" ") }}
            </div>
            <div class="spacer__lg"></div>
            <div class="checkbox__container">
              <BaseCheckbox v-model="form.check"><router-link :to="{ name: 'Terms' }" target="_blank" rel="noreferrer noopener">INFRECT利用規約・プライバシーポリシー</router-link><span>に同意する</span></BaseCheckbox>
              <div class="error" v-if="formError?.check">{{ formError?.check }}</div>
              <BaseButton @click="submit()">この内容で送信する</BaseButton>
            </div>
            <div class="contact__main__form__attention">
              ※フリーメールドメインや悪戯、競合他社様のご登録はお断りしております。<br />
              弊社が上記だと判断した場合は、登録用のメールが届かない場合がありますのでご容赦ください。
            </div>
          </div>
          <div class="contact__main__success" v-else>
            <div class="contact__main__success__title">お問い合わせが完了しました</div>
            <check-mark></check-mark>
            <div class="contact__main__success__description">
              お問い合せありがとうございます。<br />
              確認のため、自動返信メールをお送りいたします。
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer__left">
        <div class="footer__left__logo">
          <img src="@/assets/img/zerosta-logo.svg" alt="zerosta" />
        </div>
        <div class="footer__left__company">© ZEROSTA, Inc. All Rights Reserved</div>
      </div>
      <div class="footer__right">
        <div class="footer__right__link"><router-link :to="{ name: 'Terms' }" target="_blank" rel="noreferrer noopener">利用規約</router-link></div>
        <div class="footer__right__link"><a href="https://0-sta.com/" target="_blank" rel="noopener noreferrer">運営会社</a></div>
        <div class="footer__right__link"><router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">お問い合せ</router-link></div>
        <div class="footer__right__link"><router-link :to="{ name: 'FAQ' }" target="_blank" rel="noreferrer noopener">よくある質問</router-link></div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, reactive, ref, toRefs } from "vue";
import { ComboBoxItem, HamburgerMenuItem, Inquiry, InquiryError } from "@/types";
import { useStore } from "vuex";
import BaseButton from "@/components/BaseButton.vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import InfrectLogo from "@/components/icon/InfrectLogo.vue";

export default defineComponent({
  name: "TOP",
  components: { InfrectLogo, BaseTextField, BaseSelectBox, BaseTextArea, BaseCheckbox, BaseButton, HamburgerMenu, CheckMark },
  setup() {
    const store = useStore();
    const state = reactive({
      search: {
        detail: true,
        id: false,
        hashtag: false,
      },
      report: {
        detail: true,
        post: false,
        audience: false,
      },
      form: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: false as boolean,
      },
      formError: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: null as string | null,
      },
      errorMessage: computed(() => store.state.inquiries.errorMessage) as ComputedRef<InquiryError>,
      success: computed(() => store.state.inquiries.success) as ComputedRef<boolean>,
      fillColor: "#fff",
    });
    const links: HamburgerMenuItem[] = [
      {
        name: "ログイン",
        link: { name: "SignIn" },
      },
      {
        name: "7日間の無料トライアル",
        link: { name: "Trial" },
      },
    ];
    const LPHeader = ref<HTMLElement>();
    const Contact = ref<HTMLElement>();
    window.addEventListener("scroll", () => {
      let opacity = 1 - (1 - (window.scrollY > 100 ? 100 : window.scrollY) / 120);
      if (LPHeader.value) {
        LPHeader.value.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
        if (window.scrollY > 100) {
          LPHeader.value.style.boxShadow = "2px 0 3px 3px rgba(0, 0, 0, 0.1)";
          state.fillColor = "#198FD9";
        } else {
          LPHeader.value.style.boxShadow = "none";
          state.fillColor = "#fff";
        }
      }
    });
    const contactTypes: string[] = ["新規登録およびトライアルについて", "資料請求", "インフルエンサーの追加希望", "サービスに関する相談", "その他"];
    const selectItems: ComboBoxItem[] = contactTypes.map((x: string) => {
      return {
        text: x,
        value: x,
      };
    });
    const getContentPlaceHolder = () => {
      if (!state.form) return "お問い合わせ内容の詳細をこちらにご記入ください";
      if (state.form.title === "インフルエンサーの追加希望") return "対応を希望されるインフルエンサーのURLを入力してください";
      return "お問い合わせ内容の詳細をこちらにご記入ください";
    };
    const submit = () => {
      let err = false;
      state.formError = {
        companyName: null,
        departmentName: null,
        name: null,
        email: null,
        phone: null,
        title: null,
        content: null,
        check: null,
      };
      if (!state.form?.companyName) {
        state.formError.companyName = "会社名は必須です";
        err = true;
      }
      if (!state.form?.name) {
        state.formError.name = "お名前は必須です";
        err = true;
      }
      if (!state.form?.email) {
        state.formError.email = "メールアドレスは必須です";
        err = true;
      }
      if (!state.form?.title) {
        state.formError.title = "お問い合わせ種別を選択してください";
        err = true;
      }
      if (!state.form?.content) {
        state.formError.content = "お問い合わせ内容は必須です";
        err = true;
      }
      if (!state.form?.check) {
        state.formError.check = "お問い合わせには利用規約・プライバシーポリシーに同意する必要があります";
        err = true;
      }
      if (err) {
        Contact.value?.scrollIntoView({
          behavior: "smooth",
        });
        return;
      }
      const data: Inquiry = {
        companyName: String(state.form.companyName),
        departmentName: state.form.departmentName,
        name: String(state.form.name),
        email: String(state.form.email),
        phone: state.form.phone ? String(state.form.phone) : null,
        title: String(state.form.title),
        content: state.form.content,
      };
      store.dispatch("inquiries/createInquiry", data);
      Contact.value?.scrollIntoView({
        behavior: "smooth",
      });
    };
    const searchImageToggle = (name: string) => {
      if (name === "detail") {
        state.search.detail = true;
        state.search.id = false;
        state.search.hashtag = false;
      } else if (name === "id") {
        state.search.detail = false;
        state.search.id = true;
        state.search.hashtag = false;
      } else if (name === "hashtag") {
        state.search.detail = false;
        state.search.id = false;
        state.search.hashtag = true;
      }
    };
    const reportImageToggle = (name: string) => {
      if (name === "detail") {
        state.report.detail = true;
        state.report.post = false;
        state.report.audience = false;
      } else if (name === "post") {
        state.report.detail = false;
        state.report.post = true;
        state.report.audience = false;
      } else if (name === "audience") {
        state.report.detail = false;
        state.report.post = false;
        state.report.audience = true;
      }
    };
    return {
      ...toRefs(state),
      LPHeader,
      Contact,
      links,
      selectItems,
      getContentPlaceHolder,
      submit,
      searchImageToggle,
      reportImageToggle,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.top {
  overflow-x: hidden;
  color: #505050;
  @include mq(xs) {
    background-position: center;
    background-size: 120%;
  }
  @include mq(lg) {
    background-position: top right;
    background-size: 54%;
  }
  & a {
    text-decoration: none;
  }
  & .sm {
    @include mq(xs) {
      display: block;
    }
    @include mq(lg) {
      display: none;
    }
  }
  & .lg {
    @include mq(xs) {
      display: none;
    }
    @include mq(lg) {
      display: block;
    }
  }
  & header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    align-items: center;
    @include mq(xs) {
      padding: 20px;
      width: calc(100% - 40px);
    }
    @include mq(lg) {
      padding: 28px 60px;
      width: calc(100% - 120px);
    }
    & .header-menu {
      width: 40%;
      justify-content: end;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: map-get($colors, "blue400");
      gap: 20px;
      @include mq(xs) {
        display: none !important;
      }
      @include mq(lg) {
        display: flex !important;
      }
      &__login {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 48px;
        font-size: 16px;
        font-weight: 900;
        color: map-get($colors, "blue400");
        background-color: white;
        border-radius: 24px;
        box-shadow: 0 4px 0 map-get($colors, "blue400");
        transition: 0.1s;
        &:hover {
          opacity: 0.9;
          transition: 0.3s;
        }
        &:active {
          box-shadow: none;
          top: 4px;
        }
      }
      &__trial {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        height: 48px;
        font-size: 16px;
        font-weight: 900;
        color: white;
        background-color: #ff9300;
        border-radius: 24px;
        box-shadow: 0 4px 0 #bc6f06;
        transition: 0.1s;
        &:hover {
          opacity: 0.9;
          transition: 0.3s;
        }
        &:active {
          box-shadow: none;
          top: 4px;
        }
      }
    }
    & .hamburger-menu {
      @include mq(xs) {
        display: block !important;
      }
      @include mq(lg) {
        display: none !important;
      }
    }
  }
  & footer {
    align-items: center;
    background-color: white;
    @include mq(xs) {
      padding: 40px;
      margin-top: 80px;
    }
    @include mq(lg) {
      display: flex;
      justify-content: space-between;
      padding: 100px;
      margin-top: 120px;
    }
  }
  & .footer {
    &__left {
      @include mq(lg) {
        width: 60%;
      }
      &__logo {
        & img {
          @include mq(xs) {
            width: 120px;
          }
          @include mq(lg) {
            width: 210px;
          }
        }
      }
      &__company {
        font-weight: 700;
        color: map-get($colors, "gray300");
        @include mq(xs) {
          margin-top: 10px;
          font-size: 10px;
        }
        @include mq(lg) {
          margin-top: 14px;
          font-size: 13px;
        }
      }
    }
    &__right {
      display: flex;
      justify-content: space-between;
      @include mq(lg) {
        width: 40%;
      }
      &__link {
        @include mq(xs) {
          margin-top: 20px;
        }
        @include mq(lg) {
          margin-top: 0;
        }
        & a {
          font-weight: 700;
          color: map-get($colors, "gray300");
          text-decoration: none;
          transition: 0.3s;
          @include mq(xs) {
            font-size: 12px;
          }
          @include mq(lg) {
            font-size: 14px;
          }
          &:hover {
            color: map-get($colors, "gray200");
            transition: 0.3s;
          }
        }
      }
    }
  }
  &__container {
    margin: 0 auto;
    @include mq(xs) {
      width: 90%;
      padding-top: 80px;
    }
    @include mq(lg) {
      max-width: 1000px;
      padding-top: 120px;
    }
    &__white {
      margin: 0 auto;
      background-color: white;
      @include mq(xs) {
        width: 90%;
        margin-top: 80px;
        border-radius: 30px;
      }
      @include mq(lg) {
        max-width: 1246px;
        margin-top: 120px;
        border-radius: 40px;
      }
      &__inner {
        margin: 0 auto;
        @include mq(xs) {
          width: 90%;
          padding: 40px 0;
        }
        @include mq(lg) {
          max-width: 1000px;
          padding: 60px 0;
        }
      }
    }
    &__blue {
      margin: 0 auto;
      background-color: #eaf2ff;
      @include mq(xs) {
        margin-top: 80px;
        padding-top: 64px;
      }
      @include mq(lg) {
        margin-top: 120px;
        padding-top: 64px;
      }
    }
    &__title {
      @include mq(xs) {
        margin-bottom: 48px;
      }
      @include mq(lg) {
        margin-bottom: 64px;
      }
      &__sub {
        color: map-get($colors, "blue300");
        @include mq(xs) {
          font-size: 10px;
          margin-bottom: -5px;
        }
        @include mq(lg) {
          font-size: 14px;
          margin-bottom: -10px;
        }
      }
      &__main {
        color: map-get($colors, "blue400");
        font-weight: 900;
        text-align: center;
        @include mq(xs) {
          font-size: 20px;
        }
        @include mq(lg) {
          font-size: 27px;
        }
      }
      &__description {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        @include mq(xs) {
          font-size: 14px;
          margin-top: 14px;
        }
        @include mq(lg) {
          font-size: 18px;
          margin-top: 24px;
        }
      }
    }
  }
  &__button-aria {
    display: flex;
    justify-content: center;
    @include mq(xs) {
      margin-top: 62px;
    }
    @include mq(lg) {
      margin-top: 86px;
    }
  }
  & .trial-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff9300;
    border-radius: 10px;
    box-shadow: 0 5px 0 #bc6f06;
    cursor: pointer;
    transition: 0.1s;
    @include mq(xs) {
      width: 300px;
      height: 64px;
    }
    @include mq(lg) {
      width: 380px;
      height: 94px;
    }
    &:hover {
      opacity: 0.9;
      transition: 0.3s;
    }
    &:active {
      top: 5px;
      box-shadow: none;
    }
    &__main {
      font-weight: 900;
      color: white;
      @include mq(xs) {
        font-size: 18px;
      }
      @include mq(lg) {
        font-size: 24px;
      }
    }
    &__catch {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: #ff9300;
      border-radius: 22px;
      border: 2px solid #ff9300;
      background-color: white;
      @include mq(xs) {
        top: -14px;
        width: 240px;
        height: 24px;
        font-size: 12px;
      }
      @include mq(lg) {
        top: -21px;
        width: 300px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
  &__main-image {
    background-color: #74a9ff;
    @include mq(xs) {
      display: block;
      padding: 120px 20px 24px;
    }
    @include mq(lg) {
      display: flex;
      align-items: center;
      padding: 100px 64px 24px;
    }
    &__left {
      @include mq(xs) {
        width: 100%;
        margin-bottom: 48px;
      }
      @include mq(lg) {
        width: 50%;
        margin-bottom: 36px;
      }
      &__catch {
        @include mq(xs) {
          display: flex;
          justify-content: center;
          width: auto;
        }
        @include mq(lg) {
          display: block;
        }
        & span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #ff9300;
          margin-right: 4px;
          @include mq(xs) {
            width: 36px;
            height: 36px;
          }
          @include mq(lg) {
            width: 40px;
            height: 40px;
          }
        }
        &__text {
          color: white;
          font-weight: 900;
          @include mq(xs) {
            font-size: 20px;
          }
          @include mq(lg) {
            font-size: 27px;
          }
        }
      }
      &__button {
        margin-top: 80px;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: block;
        }
      }
    }
    &__right {
      @include mq(xs) {
        width: 100%;
      }
      @include mq(lg) {
        width: 50%;
      }
      & img {
        width: 100%;
      }
    }
    &__button {
      margin-top: 48px;
      justify-content: center;
      @include mq(xs) {
        display: flex;
      }
      @include mq(lg) {
        display: none;
      }
    }
  }
  &__pains {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
    &__pain {
      width: 290px;
      height: 290px;
      border: 5px solid #74a9ff;
      border-radius: 10px;
      text-align: center;
      & img {
        width: auto;
        height: 100px;
        margin: 60px 0 24px 36px;
      }
      &__text {
        font-weight: 900;
        font-size: 18px;
        color: #909090;
      }
    }
  }
  &__arrow {
    display: flex;
    justify-content: center;
  }
  &__service {
    display: flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    gap: 24px;
    &__title {
      font-weight: 900;
      text-align: center;
      @include mq(xs) {
        font-size: 20px;
        margin-bottom: 48px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 64px;
      }
      & span {
        position: relative;
        z-index: auto;
        &::before {
          content: "";
          top: -12px;
          left: -5px;
          width: calc(100% + 10px);
          height: 100%;
          position: absolute;
          z-index: -1;
          border-bottom: 18px solid #ffd153;
        }
      }
    }
    &__services {
      width: 290px;
      height: 290px;
      border: 5px solid #74a9ff;
      border-radius: 10px;
      text-align: center;
      & img {
        width: 120px;
        height: 179px;
      }
      &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 111px;
        font-weight: 900;
        font-size: 16px;
        color: white;
        border-radius: 0 0 10px 10px;
        background-color: #74a9ff;
      }
    }
    & .search {
      & img {
        width: 160px;
      }
    }
  }
  &__about {
    width: 100%;
    margin-top: 120px;
    justify-content: space-between;
    align-items: center;
    @include mq(xs) {
      display: none;
    }
    @include mq(lg) {
      display: flex;
    }
    & img {
      width: 55%;
    }
    &__description {
      width: 40%;
      &__logo {
        display: flex;
        align-items: end;
        padding-bottom: 10px;
        color: map-get($colors, "blue400");
        font-size: 18px;
        font-weight: 900;
        border-bottom: 3px solid map-get($colors, "blue400");
        & img {
          margin-right: 7px;
          width: 240px;
        }
      }
      &__text {
        font-weight: 700;
        font-size: 20px;
        margin-top: 36px;
        & span {
          position: relative;
          z-index: auto;
          &::before {
            content: "";
            top: -12px;
            left: -5px;
            width: calc(100% + 10px);
            height: 100%;
            position: absolute;
            z-index: -1;
            border-bottom: 12px solid #ffd153;
          }
        }
      }
    }
    &__sm {
      width: 90%;
      margin: 120px auto 0;
      text-align: center;
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: none;
      }
      & img {
        width: 100%;
        margin-top: 36px;
      }
      &__logo {
        display: flex;
        align-items: end;
        justify-content: center;
        color: map-get($colors, "blue400");
        font-size: 20px;
        & img {
          width: 240px;
          margin-right: 7px;
          margin-top: 0;
        }
      }
      &__text {
        margin-top: 24px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  &__sns {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: center;
      @include mq(xs) {
        width: 90%;
        gap: 14px;
      }
      @include mq(lg) {
        width: 660px;
        gap: 30px 20px;
      }
    }
    &__card {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px solid #74a9ff;
      border-radius: 10px;
      font-weight: 900;
      @include mq(xs) {
        width: 140px;
        height: 56px;
        font-size: 15px;
      }
      @include mq(lg) {
        width: 312px;
        height: 92px;
        font-size: 27px;
      }
      & img {
        height: auto;
        @include mq(xs) {
          width: 24px;
          margin-right: 14px;
        }
        @include mq(lg) {
          width: 48px;
          margin-right: 24px;
        }
      }
    }
    &__text {
      font-weight: 500;
      text-align: center;
      @include mq(xs) {
        margin-top: 24px;
        font-size: 14px;
      }
      @include mq(lg) {
        margin-top: 48px;
        font-size: 18px;
      }
    }
  }
  &__workload {
    margin: 0 auto;
    padding-bottom: 64px;
    @include mq(xs) {
      width: 90%;
    }
    @include mq(lg) {
      width: 720px;
    }
    & img {
      width: 100%;
    }
    &__text {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      @include mq(xs) {
        font-size: 14px;
      }
      @include mq(lg) {
        font-size: 18px;
      }
    }
  }
  &__functions {
    &__function {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 84px;
      @include mq(xs) {
        display: none;
      }
      @include mq(lg) {
        display: flex;
      }
      &__image {
        width: 480px;
        height: auto;
      }
      & img {
        width: 480px;
        height: 360px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      &__description {
        width: 400px;
        &__title {
          font-weight: 900;
          font-size: 24px;
          color: map-get($colors, "blue400");
          margin-bottom: 24px;
        }
        &__text {
          font-weight: 500;
          font-size: 16px;
        }
        &__tabs {
          display: flex;
          margin-top: 64px;
          &__tab {
            font-size: 14px;
            font-weight: 700;
            padding: 10px 14px;
            transition: 0.1s;
            cursor: pointer;
            &:hover {
              color: map-get($colors, "blue400");
              transition: 0.1s;
            }
          }
          & .selected {
            background: map-get($colors, "blue300");
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: white !important;
            transition: 0.1s;
          }
        }
      }
    }
    &__sm {
      @include mq(xs) {
        display: block;
      }
      @include mq(lg) {
        display: none;
      }
      &__function {
        margin-bottom: 64px;
        &__title {
          font-size: 18px;
          font-weight: 700;
        }
        &__image {
          width: 100%;
          height: calc((100vw * 0.9) * 0.75);
          margin-top: 24px;
          & img {
            width: 100%;
            border-radius: 10px;
          }
        }
        &__tabs {
          display: flex;
          margin-top: 24px;
          &__tab {
            font-size: 12px;
            font-weight: 700;
            padding: 10px 12px;
            transition: 0.1s;
          }
          & .selected {
            background: map-get($colors, "blue300");
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: white !important;
            transition: 0.1s;
          }
        }
        &__text {
          font-size: 14px;
          margin-top: 24px;
        }
      }
    }
  }
  &__reasons {
    text-align: center;
    padding-bottom: 64px;
    margin: 0 auto;
    @include mq(xs) {
      width: 90%;
    }
    @include mq(lg) {
      max-width: 1000px;
    }
    &__inner {
      @include mq(lg) {
        display: flex;
        justify-content: space-between;
      }
    }
    &__reason {
      & img {
        @include mq(xs) {
          display: flex;
          width: 60%;
          margin: 0 auto 30px;
        }
        @include mq(lg) {
          width: 300px;
          margin: 0 auto 60px;
        }
      }
    }
    &__text {
      font-weight: 500;
      @include mq(xs) {
        font-size: 14px;
      }
      @include mq(lg) {
        font-size: 18px;
      }
      & span {
        color: #eda400;
        font-weight: 900;
        @include mq(xs) {
          font-size: 24px;
        }
        @include mq(lg) {
          font-size: 36px;
        }
      }
    }
  }
  &__merit {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    &__card {
      & img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
      &__text {
        font-size: 14px;
        font-weight: 500;
        margin-top: 7px;
      }
      @include mq(xs) {
        width: 150px;
      }
      @include mq(lg) {
        width: 240px;
      }
    }
  }
  &__supports {
    text-align: center;
    padding: 64px 10%;
    margin: 120px auto 0;
    background-color: #74a9ff;
    color: white;
    &__title {
      font-size: 27px;
      font-weight: 900;
    }
    &__text {
      font-size: 18px;
      font-weight: 700;
      color: #434343;
      margin-top: 24px;
    }
    &__wrapper {
      display: flex;
      justify-content: center;
      margin: 64px auto 0;
      flex-wrap: wrap;
      gap: 48px 30px;
      @include mq(xs) {
        width: 90%;
      }
      @include mq(lg) {
        max-width: 1000px;
      }
    }
    &__support {
      width: 280px;
      &__title {
        font-size: 20px;
        font-weight: 900;
      }
      & img {
        width: 240px;
        height: 240px;
        margin-top: 24px;
      }
      &__text {
        font-size: 18px;
        font-weight: 900;
        margin-top: 24px;
      }
    }
  }
  &__category {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    & img {
      border-radius: 10px;
      @include mq(xs) {
        min-width: 150px;
        width: calc((100% - 90px) / 4);
      }
      @include mq(lg) {
        min-width: 200px;
        width: calc((100% - 90px) / 4);
      }
    }
  }
  &__plans {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    &__plan {
      width: 248px;
      border-radius: 10px;
      border: 5px solid #000;
      padding: 24px 20px 36px;
      &__title {
        font-size: 21px;
        font-weight: 900;
      }
      & table {
        width: 100%;
        margin-top: 36px;
        text-align: left;
        font-size: 15px;
        & th {
          padding: 7px 0;
        }
        & td {
          color: #404040 !important;
        }
        & tr:nth-of-type(3) {
          & th {
            font-size: 14px;
          }
        }
        & td {
          text-align: right;
        }
      }
    }
    & .trial {
      border: 5px solid #eb6877;
      color: #eb6877;
    }
    & .standard {
      border: 5px solid #277aff;
      color: #277aff;
    }
    & .advanced {
      border: 5px solid #ff9300;
      color: #ff9300;
    }
  }
  &__faqs {
    margin: 0 auto;
    @include mq(xs) {
      width: 90%;
      padding-bottom: 48px;
    }
    @include mq(lg) {
      max-width: 1000px;
      padding-bottom: 64px;
    }
    &__faq {
      background-color: white;
      border-radius: 20px;
      font-weight: 700;
      color: map-get($colors, "gray300");
      margin-bottom: 20px;
      @include mq(xs) {
        font-size: 13px;
      }
      @include mq(lg) {
        font-size: 16px;
      }
      &__question {
        display: flex;
        align-items: center;
        padding: 30px;
      }
      &__answer {
        display: flex;
        align-items: center;
        border-top: 2px solid #f0f3f5;
        padding: 30px;
      }
      & img {
        @include mq(xs) {
          width: 40px;
          height: 40px;
          margin-right: 14px;
        }
        @include mq(lg) {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
      }
    }
    & .button {
      width: 240px;
      border: 2px solid map-get($colors, "blue400");
      background-color: white;
      color: map-get($colors, "blue400");
      padding: 14px 38px !important;
      display: flex;
      margin: 60px auto 0;
    }
  }
  & .contact {
    &__main {
      &__container {
        margin: 60px 0;
        max-width: 1000px;
        background: #eaf2ff;
        border-radius: 20px;
        @include mq(xs) {
          width: calc(100% - 40px);
          padding: 40px 20px;
        }
        @include mq(lg) {
          width: 100%;
          padding: 80px 0;
        }
      }
      &__form {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        &__attention {
          font-size: 13px;
          text-align: center;
          font-weight: 500;
          color: map-get($colors, "gray300");
          margin-top: 48px;
        }
      }
      &__success {
        text-align: center;
        &__title {
          color: map-get($colors, "blue400");
          font-weight: 900;
          margin-bottom: 30px;
          @include mq(xs) {
            font-size: 16px;
          }
          @include mq(lg) {
            font-size: 20px;
          }
        }
        &__description {
          color: map-get($colors, "blue300");
          font-weight: 700;
          margin-top: 30px;
          @include mq(xs) {
            font-size: 13px;
          }
          @include mq(lg) {
            font-size: 14px;
          }
        }
      }
    }
    & .spacer {
      width: 100%;
      max-width: 600px;
      margin-top: 40px;
      &__lg {
        width: 100%;
        max-width: 600px;
        margin-top: 60px;
      }
    }
    & .error {
      font-weight: 500;
      color: map-get($colors, "error");
      font-size: 12px;
      margin: 4px 0;
      &.large {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  &__submit-success {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__container {
      padding: 60px;
      background-color: white;
      border-radius: 10px;
      text-align: center;
      @include mq(xs) {
        width: calc(90% - 120px);
      }
      @include mq(lg) {
        width: 400px;
      }
      &__title {
        color: map-get($colors, "blue400");
        font-weight: 900;
        margin-bottom: 30px;
        @include mq(xs) {
          font-size: 16px;
        }
        @include mq(lg) {
          font-size: 20px;
        }
      }
      &__description {
        color: map-get($colors, "blue300");
        font-weight: 700;
        margin: 30px 0;
        @include mq(xs) {
          font-size: 13px;
        }
        @include mq(lg) {
          font-size: 14px;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
<style lang="scss">
@import "../../src/assets/styles/main";
.top {
  & header {
    & .header-menu {
      & .button {
        color: white !important;
      }
    }
  }
  &__container__contact {
    & input {
      width: calc(100% - 44px) !important;
      max-width: 600px !important;
      border: solid 1.5px map-get($colors, "primary") !important;
      &:focus {
        border: solid 1.5px map-get($colors, "primary") !important;
        outline: solid 0.5px map-get($colors, "primary") !important;
      }
    }
    & .selectbox__container {
      width: 100% !important;
      max-width: 600px !important;
    }
    & .selectbox__wrapper,
    .selectbox__items {
      width: calc(100% - 3px) !important;
      max-width: 600px !important;
      border-width: 1.5px !important;
    }
    & .focus .selectbox__wrapper {
      width: calc(100% - 4px) !important;
    }
    & textarea {
      width: calc(100% - 44px) !important;
      max-width: 600px !important;
      border: solid 1.5px map-get($colors, "primary") !important;
      &:focus {
        border: solid 1.5px map-get($colors, "primary") !important;
        outline: solid 0.5px map-get($colors, "primary") !important;
      }
    }
    & a.button {
      margin-top: 60px;
      font-size: 20px !important;
      font-weight: 900 !important;
      color: white !important;
      background: transparent linear-gradient(90deg, #198fd9 0%, #93cce2 100%) 0 0 no-repeat padding-box;
      padding: 18px 68px !important;
      width: fit-content !important;
    }
    & .checkbox__container {
      text-align: center;
      font-weight: 700;
      & label {
        & span {
          color: map-get($colors, "gray300");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 16px;
          }
        }
        & a {
          color: #198fd9;
          text-decoration: none;
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 16px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
