
import {computed, defineComponent, reactive, toRefs} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRoute } from "vue-router";
import {useStore} from "vuex";

export default defineComponent({
  name: "VerifyEmail",
  components: { BaseButton },
  setup(props, context){
    const store = useStore();
    const route = useRoute();
    store.dispatch("verificationEmail/verificationEmailInitialize");
    const state = reactive({
      showSuccess: computed(() => store.state.verificationEmail.submitVerificationSuccess),
      verificationCode: route.params.verificationCode,
      email: computed(() => store.state.users.user?.email),
    });
    const submitVerify = () => {
      store.dispatch("verificationEmail/postVerificationEmail", state.verificationCode);
    };
    return { ...toRefs(state), submitVerify };
  }
})
