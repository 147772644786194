<template>
  <div class="base-container__white">
    <div class="purchase-cancel">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" />
      </header>
      <div class="purchase-cancel__content">
        <div class="purchase-cancel__title">
          <h1>サブスクリプションの購入をキャンセルしました</h1>
        </div>
        <div class="purchase-cancel__text">
          <exclamation-mark></exclamation-mark>
        </div>
        <div class="purchase-cancel__text">
          <router-link :to="{ name: 'AccountSetting' }"><base-button color="dark">戻る</base-button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "PurchaseCancel",
  components: { ExclamationMark, BaseButton },
});
</script>

<style lang="scss" scoped>
.purchase-cancel {
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    margin: 100px auto;
    & h1 {
      color: map-get($colors, "blue400");
      text-align: center;
    }
  }
  &__text {
    margin: 60px auto;
    text-align: center;
  }
}
</style>