export class GenderType {
  static readonly NOTHING = new GenderType(0, "選択しない");
  static readonly FEMALE = new GenderType(1, "女性");
  static readonly MALE = new GenderType(2, "男性");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const genderType = this.getAll().find((genderType) => genderType.key === key);
    return genderType ? genderType.text : "";
  }
  static getObject(key: number): GenderType | null {
    const genderType = this.getAll().find((genderType) => genderType.key === key);
    return genderType !== undefined ? genderType : null;
  }
  private static getAll(): Array<GenderType> {
    return [GenderType.NOTHING, GenderType.FEMALE, GenderType.MALE];
  }
}
