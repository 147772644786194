
import { defineComponent } from "vue";

export default defineComponent({
  name: "CancelButtonIcon",
  props: {
    fillColor: {
      type: String,
      default: "#707070"
    },
    size: {
      type: Number,
      default: 16
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
})
