
import { computed, defineComponent, reactive, toRefs } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ForgotPasswordResetParams } from "@/types";

export default defineComponent({
  name: "PassReset",
  components: { BaseButton, BaseTextField },
  setup() {
    const store = useStore();
    store.dispatch("users/forgotPasswordResetInitialize");
    const route = useRoute();
    const router = useRouter();
    let token = "";
    if (typeof route.params.token === "string") {
      token = route.params.token;
    } else {
      router.push({ name: "NotFound" });
    }
    const state = reactive({
      errorMessage: computed(() => store.state.users.errorMessage),
      passwordResetSuccess: computed(() => store.state.users.forgotPasswordResetSuccess),
      formErrorMessage: null as string | null,
      password1: null as string | null,
      password2: null as string | null,
    });
    const submitPasswordReset = () => {
      store.dispatch("users/forgotPasswordResetInitialize");
      if (!state.password1) {
        state.formErrorMessage = "パスワードは必須です";
      } else if (!state.password2) {
        state.formErrorMessage = "パスワード確認用は必須です";
      } else if (state.password1 !== state.password2) {
        state.formErrorMessage = "パスワードと確認用パスワードが違います";
      } else {
        state.formErrorMessage = null;
        const params: ForgotPasswordResetParams = { token: token, password: state.password1 };
        store.dispatch("users/forgotPasswordReset", params);
      }
    };
    return { ...toRefs(state), submitPasswordReset };
  },
});
