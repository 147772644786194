<template>
  <div class="base-container">
    <sidebar :current-tab="3"></sidebar>
    <div class="main">
      <base-header header-title="効果を予測する" display-type="PREDICTION"></base-header>
      <div class="dialog__container" v-if="loading || errorMessage">
        <div class="dialog" v-show="loading">
          <div class="title">予測獲得いいね数を測定しています</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>測定結果が出るまでしばらくお待ちください</h4></div>
        </div>
        <div class="dialog" v-show="errorMessage">
          <div class="title">効果測定に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>効果測定の回数は消費しません。<br />お手数をおかけしますが、ページをリロードしてください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="closeLoadingDialog">戻る</base-button></div>
        </div>
      </div>
      <div class="ad-prediction" v-if="company?.isExpired === false">
        <div class="ad-prediction__tab__container">
          <div class="ad-prediction__tabs d-flex">
            <div
              :class="['ad-prediction__tab', show.first ? 'selected' : '']"
              @click="
                show.first = true;
                show.second = false;
                show.third = false;
              "
            >
              <h4>情報を入力して予測</h4>
            </div>
            <div
              :class="['ad-prediction__tab', show.second ? 'selected' : '']"
              @click="
                show.second = true;
                show.first = false;
                show.third = false;
              "
            >
              <h4>インフルエンサーリストから予測</h4>
            </div>
          </div>
          <div class="ad-prediction__description ml-20px">
            <h4>投稿に使う画像やキャプション、フォロワー数、使用するハッシュタグ等の情報を用いて、AIがいいね数を予測し、効果的な投稿をご支援します。</h4>
          </div>
        </div>
        <div v-show="show.first">
          <div class="ad-prediction__content d-flex mt-40px">
            <div :class="['ad-prediction__content__post-image', 'mr-40px', isDragOver ? 'over' : '']" @dragover.prevent="onDrag('over')" @dragleave="onDrag('leave')" @drop.prevent="onDrop($event)" v-show="!imgUrl">
              <img src="@/assets/img/phone.png" class="mr-10px" alt="" />
              <div class="ad-prediction__content__post-image__content">
                <div class="d-flex align-items-center justify-content-center height-100">
                  <div>
                    <h4>ここに画像をドロップ</h4>
                    <h5 class="mt-10px">または</h5>
                    <div class="original-file-btn mt-10px">
                      <h4>画像を選択</h4>
                      <input type="file" ref="uploadThumbnailInput" accept="image/jpeg,image/png" @change="handleFile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ad-prediction__content__post-image mr-40px" v-show="imgUrl" @dragover.prevent="onDrag('over')" @dragleave="onDrag('leave')" @drop.prevent="onDrop($event)">
              <img src="@/assets/img/phone.png" class="mr-10px" alt="" />
              <div class="ad-prediction__content__post-image__content">
                <div class="ad-prediction__content__post-image__content__header d-flex">
                  <img src="@/assets/img/Infrect-predictor.svg" alt="" />
                  <div class="ad-prediction__content__post-image__content__header__right d-flex">
                    <img src="@/assets/img/post-icon.svg" alt="" />
                    <img src="@/assets/img/reload.svg" alt="" />
                  </div>
                </div>
                <div class="ad-prediction__content__post-image__content__profile d-flex align-items-center">
                  <ProfileIcon :size="18"></ProfileIcon>
                  <h6>インフルエンサー</h6>
                </div>
                <div class="ad-prediction__content__post-image__content__thumbnail">
                  <img :src="imgUrl" alt="" />
                </div>
                <div class="ad-prediction__content__post-image__content__like">
                  <img src="@/assets/like-heart.svg" class="mr-10px" alt="" />
                </div>
                <div class="ad-prediction__content__post-image__content__caption">
                  {{ form.caption }}
                </div>
              </div>
            </div>
            <div class="ad-prediction__content__post-form">
              <base-text-area label="キャプション" :width="640" :height="100" :placeholder="'投稿内容を入力してください'" v-model="form.caption" :key="form.caption"></base-text-area>
              <div class="d-flex mt-20px">
                <base-text-field label="フォロワー数" :width="140" class="mr-20px" v-model="form.followers" type="number"> </base-text-field>
                <base-text-field label="フォロー数" :width="140" class="mr-20px" v-model="form.follow" type="number"> </base-text-field>
              </div>
              <div class="d-flex mt-20px">
                <base-select-box label="曜日" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.day" :key="form.day" :items="DayOfWeek.getAll()" return-object disable-lookup></base-select-box>
                <base-select-box label="時間" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.hour" :key="form.hour" :items="hours" return-object disable-lookup></base-select-box>
                <base-select-box label="分" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.minute" :key="form.minute" :items="minutes" return-object disable-lookup></base-select-box>
                <div class="align-self-end" @click="submitPrediction">
                  <base-button color="success"> 効果を測定する </base-button>
                </div>
              </div>
              <div class="ad-prediction__content__like-counter mt-40px" v-if="simplePredictSuccess">
                <h3 class="mr-20px">予測獲得いいね数</h3>
                <div>
                  <img src="../assets/img/like-heart-white.svg" alt="" />
                  {{ (simpleSearchResult?.prediction || 0).toLocaleString() }}
                </div>
              </div>
              <div class="ad-prediction__content__error mt-10px">
                {{ errorMessage }}
                {{ formErrorMessage }}
              </div>
            </div>
          </div>
        </div>
        <div v-show="show.second" v-if="company?.plan?.myListLimitation !== 0">
          <div class="ad-prediction__content d-flex mt-40px">
            <div :class="['ad-prediction__content__post-image', 'mr-40px', isDragOver ? 'over' : '']" @dragover.prevent="onDragForInfluencer('over')" @dragleave="onDragForInfluencer('leave')" @drop.prevent="onDropForInfluencer($event)" v-show="!imgUrl">
              <img src="@/assets/img/phone.png" class="mr-10px" alt="" />
              <div class="ad-prediction__content__post-image__content">
                <div class="d-flex align-items-center justify-content-center height-100">
                  <div>
                    <h4>ここに画像をドロップ</h4>
                    <h5 class="mt-10px">または</h5>
                    <div class="original-file-btn mt-10px">
                      <h4>画像を選択</h4>
                      <input type="file" ref="uploadThumbnailInputForInfluencer" accept="image/jpeg,image/png" @change="handleFileForInfluencer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ad-prediction__content__post-image mr-40px" v-show="imgUrl" @dragover.prevent="onDragForInfluencer('over')" @dragleave="onDragForInfluencer('leave')" @drop.prevent="onDropForInfluencer($event)">
              <img src="@/assets/img/phone.png" class="mr-10px" alt="" />
              <div class="ad-prediction__content__post-image__content">
                <div class="ad-prediction__content__post-image__content__header d-flex">
                  <img src="@/assets/img/Infrect-predictor.svg" alt="" />
                  <div class="ad-prediction__content__post-image__content__header__right d-flex">
                    <img src="@/assets/img/post-icon.svg" alt="" />
                    <img src="@/assets/img/reload.svg" alt="" />
                  </div>
                </div>
                <div class="ad-prediction__content__post-image__content__profile d-flex align-items-center">
                  <ProfileIcon :size="18"></ProfileIcon>
                  <h6>インフルエンサー</h6>
                </div>
                <div class="ad-prediction__content__post-image__content__thumbnail">
                  <img :src="imgUrl" alt="" />
                </div>
                <div class="ad-prediction__content__post-image__content__like">
                  <img src="@/assets/like-heart.svg" class="mr-10px" alt="" />
                </div>
                <div class="ad-prediction__content__post-image__content__caption">
                  {{ form.caption }}
                </div>
              </div>
            </div>
            <div class="ad-prediction__content__post-form">
              <base-text-area label="キャプション" :width="640" :height="100" :placeholder="'投稿内容を入力してください'" v-model="form.caption" :key="form.caption"></base-text-area>
              <div class="d-flex mt-20px">
                <base-select-box label="曜日" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.day" :key="form.day" :items="DayOfWeek.getAll()" return-object disable-lookup></base-select-box>
                <base-select-box label="時間" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.hour" :key="form.hour" :items="hours" return-object disable-lookup></base-select-box>
                <base-select-box label="分" :width="120" class="mr-20px" item-value="key" item-text="text" v-model="form.minute" :key="form.minute" :items="minutes" return-object disable-lookup></base-select-box>
              </div>
              <div class="ad-prediction__content__post-form__add-influencer mt-30px">
                <div class="d-flex">
                  <h4>インフルエンサーリストから追加</h4>
                  <div class="delete-selected ml-20px" @click="selectedInfluencers = []">候補者を一括削除</div>
                </div>
                <div class="d-flex selected_influencers">
                  <div
                    class="ad-prediction__content__post-form__add-influencer__card mr-20px d-flex"
                    v-for="influencer in selectedInfluencers.filter((x) => {
                      if (x.isPredictable) return true;
                    })"
                    :key="influencer.id"
                  >
                    <cancel-button-icon class="mr-10px" @click="deleteInfluencer(influencer.id)"></cancel-button-icon>
                    <img :src="influencer.picture" class="mr-10px" alt="" />
                    <instagram-icon></instagram-icon>
                    <div class="ml-10px ad-prediction__content__post-form__add-influencer__card__text">
                      <h5>{{ influencer.fullname }}</h5>
                      <h6>{{ influencer.username }}</h6>
                    </div>
                  </div>
                  <add-candidate-button :items="filterInstagramAccount(influencerList)" v-model="selectedInfluencers" v-if="influencerList" :key="selectedInfluencers.length"></add-candidate-button>
                </div>
              </div>
              <div class="align-self-end mt-20px d-flex">
                <base-button color="success" @click="submitDetailPrediction"> 効果を測定する</base-button>
              </div>
              <div class="ad-prediction__content__error mt-10px">
                {{ errorMessage }}
                {{ formErrorMessageForInfluencer }}
              </div>
            </div>
          </div>
          <div class="ad-prediction__list mt-20px" v-if="detailPredictionResult">
            <h1>測定結果</h1>
            <div class="ad-prediction__list__card d-flex mt-20px" v-for="(item, index) in detailPredictionResult" :key="index">
              <img :src="item.influencer.picture" class="mr-10px" alt="" />
              <instagram-icon class="mr-10px"></instagram-icon>
              <div class="ad-prediction__list__card__name">
                <h3>{{ item.influencer.fullname }}</h3>
                <h5>{{ item.influencer.username }}</h5>
              </div>
              <div class="ad-prediction__list__card__right d-flex">
                <div class="ad-prediction__list__card__right__follower d-flex justify-content-right">
                  <h3>{{ item.influencer.followers.toLocaleString() }}</h3>
                  <h5>フォロワー</h5>
                </div>
                <h4 class="ml-60px">予想獲得いいね数</h4>
                <div class="ad-prediction__list__card__right__like d-flex justify-content-right">
                  <h3>{{ item.prediction.toLocaleString() }}</h3>
                  <h5>いいね</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ad-prediction" v-else-if="company?.plan?.myListLimitation === 0 && show.second">
          <div class="no-function-container">
            <h1>ご利用中のプランではこの機能はお使いいただけません。</h1>
            <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
              <base-button color="success" size="wide" class="mt-20px mr-20px"> プランを変更する </base-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import AddCandidateButton from "@/components/AddCandidateButton.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import CancelButtonIcon from "@/components/icon/CancelButtonIcon.vue";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import { DayOfWeek } from "@/enum/dayOfWeek";
import { Influencer, SimplePredictionRequestParams, DetailPredictionRequestParams } from "@/types";

export default defineComponent({
  name: "AdPrediction",
  components: {
    BaseSelectBox,
    CancelButtonIcon,
    AddCandidateButton,
    BaseTextArea,
    InstagramIcon,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    ProfileIcon,
    Loading,
    ExclamationMark,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompanyInfluencers");
    store.dispatch("users/loadCurrentUser");
    const state = reactive({
      show: {
        first: true,
        second: false,
        dialog: false,
      },
      influencerList: computed(() => store.state.influencers.companyInfluencers),
      company: computed(() => store.state.companies.company),
      likeValue: 0,
      imgUrl: null as string | null,
      isDragOver: false,
      isDragOverForInfluencer: false,
      selectedInfluencers: [] as any[],
      form: {
        caption: null as string | null,
        captionForInfluencer: null as null | string,
        followers: null as null | number,
        follow: null as null | number,
        day: { key: "Monday", text: "月曜日" },
        hour: { key: 0, text: "0時" },
        minute: { key: 0, text: "0分" },
      },
      formErrorMessage: null as string | null,
      formErrorMessageForInfluencer: null as string | null,
      errorMessage: computed(() => store.state.prediction.errorMessage),
      loading: computed(() => store.state.prediction.loading),
      simplePredictSuccess: computed(() => store.state.prediction.simplePredictionSuccess),
      simpleSearchResult: computed(() => store.state.prediction.simplePredictionResult),
      detailPredictionSuccess: computed(() => store.state.prediction.detailPredictionSuccess),
      detailPredictionResult: computed(() => store.state.prediction.detailPredictionResult),
    });
    const hours = Array.from(Array(24), (v, k) => ({ key: k, text: k + "時" }));
    const minutes = Array.from(Array(60), (v, k) => ({ key: k, text: k + "分" }));
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const uploadThumbnailInputForInfluencer = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const handleFileForInfluencer = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInputForInfluencer.value?.files || [])[0]);
    };
    const onDrag = (type: string) => {
      state.isDragOver = type === "over";
    };
    const onDragForInfluencer = (type: string) => {
      state.isDragOverForInfluencer = type === "over";
    };
    const onDrop = (e: any) => {
      state.isDragOver = false;
      if (uploadThumbnailInput.value?.files) uploadThumbnailInput.value.files = null;
      const file = e.dataTransfer.files[0];
      Object.defineProperty(uploadThumbnailInput.value, "files", { value: [file], writable: true });
      handleFile();
    };
    const onDropForInfluencer = (e: any) => {
      state.isDragOverForInfluencer = false;
      if (uploadThumbnailInputForInfluencer.value?.files) uploadThumbnailInputForInfluencer.value.files = null;
      const file = e.dataTransfer.files[0];
      Object.defineProperty(uploadThumbnailInputForInfluencer.value, "files", { value: [file], writable: true });
      handleFileForInfluencer();
    };
    const submitPrediction = () => {
      state.formErrorMessage = null;
      if (!state.form.follow && state.form.follow != 0) {
        state.formErrorMessage = "フォロー数は必須です";
      } else if (!state.form.followers && state.form.followers != 0) {
        state.formErrorMessage = "フォロワー数は必須です";
      } else {
        const form: SimplePredictionRequestParams = {
          day: state.form.day.key,
          follow: state.form.follow,
          followers: state.form.followers,
          hour: state.form.hour.key,
          minute: state.form.minute.key,
        };
        !Number.isNaN(form.follow) ? (form.follow = Number(form.follow)) : (state.formErrorMessage = "フォロー数には整数を入れてください");
        !Number.isNaN(form.followers) ? (form.followers = Number(form.followers)) : (state.formErrorMessage = "フォロワー数には整数を入れてください");
        if (!state.formErrorMessage) store.dispatch("prediction/predictSimpleInstagramLikes", form);
      }
    };
    const submitDetailPrediction = () => {
      state.formErrorMessageForInfluencer = null;
      if (state.selectedInfluencers.length === 0) {
        state.formErrorMessageForInfluencer = "インフルエンサーの選択は必須です";
      } else {
        const form: DetailPredictionRequestParams = {
          influencers: state.selectedInfluencers.slice().map((x: Influencer) => x.id),
          day: state.form.day.key,
          hour: state.form.hour.key,
          minute: state.form.minute.key,
        };
        if (state.form.caption) form.caption = state.form.caption;
        store.dispatch("prediction/predictDetailInstagramLikes", form);
      }
    };
    const filterInstagramAccount = (items: Influencer[]) => {
      return items.slice().filter((x: Influencer) => x.type === 0);
    };
    const closeLoadingDialog = () => {
      router.push({ name: "Home" });
    };
    const deleteInfluencer = (id: string) => {
      state.selectedInfluencers = state.selectedInfluencers.filter((value: Influencer) => value.isPredictable);
      state.selectedInfluencers = state.selectedInfluencers.filter((value: Influencer) => value.id !== id);
    };
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      uploadThumbnailInputForInfluencer,
      handleFile,
      handleFileForInfluencer,
      onDrag,
      onDragForInfluencer,
      onDrop,
      onDropForInfluencer,
      DayOfWeek,
      hours,
      minutes,
      submitPrediction,
      submitDetailPrediction,
      filterInstagramAccount,
      closeLoadingDialog,
      deleteInfluencer,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.no-function-container {
  width: 80%;
  padding: 80px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin-top: 60px;
  & h1 {
    color: map-get($colors, "primary");
    margin-bottom: 40px;
  }
}
.ad-prediction {
  &__tabs {
    background: map-get($btn-colors, "disabled");
    width: 528px;
    height: fit-content;
    border-radius: 24px;
  }
  &__tab {
    width: calc(100% / 2);
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    &__container {
      margin-top: 20px;
      display: flex;
      width: 100%;
      min-width: 1024px;
    }
    & h4 {
      font-weight: 700;
      color: map-get($colors, "gray200");
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  &__description {
    width: 50%;
  }
  & .selected {
    background: map-get($colors, "blue400");
    border-radius: 24px;
    & h4 {
      color: white !important;
    }
  }
  &__content {
    &__post-image {
      margin-left: 60px;
      position: relative;
      width: fit-content;
      height: fit-content;
      & img {
        height: 400px;
        width: auto;
        z-index: 4;
      }
      &__content {
        position: absolute;
        top: 25px;
        left: 10px;
        background: white;
        width: 186px;
        height: 367px;
        text-align: center;
        align-items: center;
        border-radius: 0 0 24px 24px;
        z-index: 0;
        overflow-y: scroll;
        & img {
          width: fit-content;
          height: auto;
        }
        &__header {
          padding: 5px 5px;
          justify-content: space-between;
          &__right {
            & img {
              width: 12px;
              height: auto;
              margin-left: 5px;
            }
          }
        }
        &__profile {
          padding: 5px 5px;
          & h6 {
            font-size: 9px;
            font-weight: 500;
            margin-left: 5px;
          }
        }
        &__thumbnail {
          & img {
            width: 186px;
            height: 186px;
            object-fit: cover;
          }
        }
        &__like {
          padding: 2px 5px;
          text-align: left;
          & img {
            width: 8px;
            height: auto;
            margin-right: 2px;
          }
        }
        &__caption {
          padding: 5px;
          text-align: left;
          font-size: 9px;
        }
      }
    }
    & .over {
      & .ad-prediction__content__post-image__content {
        opacity: 0.5;
        border: 2px dotted map-get($colors, "blue400");
      }
    }
    &__post-form {
      &__add-influencer {
        & .selected_influencers {
          width: calc(100% - 60px);
          flex-wrap: wrap;
        }
        & h4 {
          font-weight: 900;
          margin: 10px 0;
        }
        &__card {
          width: 200px;
          height: 64px;
          margin-top: 10px;
          background: white;
          border-radius: 5px;
          align-items: center;
          padding: 0 10px;
          overflow-x: hidden;
          & img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
          &__text {
            width: 100px;
            & h5 {
              font-weight: 700;
              white-space: pre;
              overflow: scroll;
            }
            & h6 {
              color: map-get($colors, "gray300");
              white-space: pre;
              overflow: scroll;
            }
          }
          &__delete {
            width: 14px;
            height: 14px;
            background: map-get($colors, "blue400");
            color: white;
            font-weight: 700;
            border-radius: 14px;
            text-align: center;
            cursor: pointer;
          }
          & svg {
            min-width: 18px;
            min-height: 18px;
          }
        }
        & .delete-selected {
          margin: 10px 20px;
          padding: 1px 10px;
          border-radius: 5px;
          background: map-get($colors, "gray200");
          cursor: pointer;
          color: white;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    &__like-counter {
      padding: 20px;
      width: 210px;
      height: 80px;
      background: map-get($colors, "accent");
      border-radius: 10px;
      & div {
        font-size: 36px;
        font-weight: 900;
        color: white;
        text-align: right;
      }
      & h3 {
        color: white;
      }
      & img {
        margin-right: 5px;
      }
    }
    &__error {
      font-size: 14px;
      color: map-get($colors, "accent");
      font-weight: 500;
    }
  }
  &__list {
    &__card {
      background: white;
      border-radius: 10px;
      padding: 20px;
      align-items: center;
      & img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }
      &__right {
        margin-left: auto;
        line-height: 14px;
        &__follower {
          width: 200px;
          & h4,
          h5 {
            align-self: end;
            margin-left: 5px;
          }
        }
        &__like {
          width: 140px;
          & h3 {
            color: map-get($colors, "accent");
          }
          & h5 {
            align-self: end;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.original-file-btn {
  display: inline-block;
  padding: 2px 1em;
  position: relative;
  cursor: pointer;
  background: map-get($colors, "primary");
  border-radius: 5px;
  & h4 {
    color: white;
    cursor: pointer;
  }
}
.original-file-btn input[type="file"] {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
}
.height-100 {
  height: 100%;
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.main {
  @include mq(xs) {
    width: 100%;
    overflow-x: scroll;
  }
  @include mq(lg) {
    width: calc(100% - 104px);
    overflow-x: unset;
  }
}
</style>
<style lang="scss">
.selected_influencers > div {
  margin-top: 10px;
}
</style>
