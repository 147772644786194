
import { defineComponent, reactive, toRefs, computed, ComputedRef } from "vue";
import { useStore } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { Inquiry, InquiryError, ComboBoxItem } from "@/types";

export default defineComponent({
  name: "Contact",
  components: { BaseTextField, BaseSelectBox, BaseTextArea, BaseCheckbox, BaseButton, CheckMark },
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: false as boolean,
      },
      formError: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: null as string | null,
      },
      errorMessage: computed(() => store.state.inquiries.errorMessage) as ComputedRef<InquiryError>,
      success: computed(() => store.state.inquiries.success) as ComputedRef<boolean>,
    });
    const contactTypes: string[] = ["新規登録およびトライアルについて", "資料請求", "インフルエンサーの追加希望", "サービスに関する相談", "その他"];
    const selectItems: ComboBoxItem[] = contactTypes.map((x: string) => {
      return {
        text: x,
        value: x,
      };
    });
    const getContentPlaceHolder = () => {
      if (!state.form) return "お問い合わせ内容の詳細をこちらにご記入ください";
      if (state.form.title === "インフルエンサーの追加希望") return "対応を希望されるインフルエンサーのURLを入力してください";
      return "お問い合わせ内容の詳細をこちらにご記入ください";
    };
    const submit = () => {
      let err = false;
      state.formError = {
        companyName: null,
        departmentName: null,
        name: null,
        email: null,
        phone: null,
        title: null,
        content: null,
        check: null,
      };
      if (!state.form?.companyName) {
        state.formError.companyName = "会社名は必須です";
        err = true;
      }
      if (!state.form?.name) {
        state.formError.name = "お名前は必須です";
        err = true;
      }
      if (!state.form?.email) {
        state.formError.email = "メールアドレスは必須です";
        err = true;
      }
      if (!state.form?.title) {
        state.formError.title = "お問い合わせ種別を選択してください";
        err = true;
      }
      if (!state.form?.content) {
        state.formError.content = "お問い合わせ内容は必須です";
        err = true;
      }
      if (!state.form?.check) {
        state.formError.check = "お問い合わせには利用規約・プライバシーポリシーに同意する必要があります";
        err = true;
      }
      if (err) {
        window.scrollTo(0, 0);
        return;
      }
      const data: Inquiry = {
        companyName: String(state.form.companyName),
        departmentName: state.form.departmentName,
        name: String(state.form.name),
        email: String(state.form.email),
        phone: state.form.phone ? String(state.form.phone) : null,
        title: String(state.form.title),
        content: state.form.content,
      };
      store.dispatch("inquiries/createInquiry", data);
      window.scrollTo(0, 0);
    };
    const closeWindow = () => {
      window.close();
    };
    return {
      ...toRefs(state),
      selectItems,
      getContentPlaceHolder,
      submit,
      close,
      closeWindow,
    };
  },
});
