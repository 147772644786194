<template>
  <div class="spinner-box" :style="styles">
    <div class="circle-border">
      <div class="circle-core" :style="styles"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs} from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    backGround: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "200px",
    },
    lineHeight: {
      type: String,
      default: "3px",
    },
  },
  setup(props, context) {
    const state = reactive({
      styles: computed(() => {
        return { "--background": props.backGround, "--width": props.width, "--line-height": props.lineHeight };
      }),
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner-box {
  width: var(--width);
  height: var(--width);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.circle-border {
  width: calc(var(--width) - var(--line-height));
  height: calc(var(--width) - var(--line-height));
  padding: var(--line-height);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin .8s linear 0s infinite;
}
.circle-core {
  width: 100%;
  height: 100%;
  background-color: var(--background);
  border-radius: 50%;
}
</style>