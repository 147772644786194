<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 20 20"
  >
      <path d="M11,18h2V16H11v2M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2m0,18a8,8,0,1,1,8-8,8.011,8.011,0,0,1-8,8M12,6a4,4,0,0,0-4,4h2a2,2,0,0,1,4,0c0,2-3,1.75-3,5h2c0-2.25,3-2.5,3-5A4,4,0,0,0,12,6Z" transform="translate(-2 -2)" :fill="fillColor"/>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "QuestionIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db"
    },
    size: {
      type: Number,
      default: 20
    },

    rounded: {
      type: Boolean,
      default: false
    }
  }
})

</script>





