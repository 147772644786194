import axios from "@/store/axios";
import { Commit, Dispatch } from "vuex";
import {
  Influencer,
  SearchUsernameInfluencerParams,
  InfluencerList,
  AddInfluencerListParams,
  CreateInfluencerListParams,
  UpdateInfluencerListParams,
  DeleteInfluencerListParams,
  SimpleSearchParams,
  GetHashtagsParams,
  DetailSearchParams,
  SearchResultParams,
  Post,
  HashtagInfluencer,
  HashtagRanking,
  HashtagRelations,
  HashtagRelation,
  HashtagSearchRequestParams, ListResponse, AISearchResultItem,
} from "@/types";
import {resolveDirective} from "vue";


interface State {
  influencer: Influencer | null;
  influencers: Influencer[];
  companyInfluencers: Influencer[];
  influencerList: InfluencerList | null;
  createdInfluencerList: InfluencerList | null;
  childInfluencerList: InfluencerList | null;
  relatedInfluencer: Influencer | null;
  hashtagInfluencers: HashtagInfluencer[];
  hashtagPosts: Post[];
  hashtagRelation: HashtagRelation | null;
  aiPersonas: string[];
  aiKeywords: string[];
  aiHashtags: string[];
  aiKeywordInfluencers: Influencer[];
  aiHashtagInfluencers: Influencer[];
  aiSearchResults: AISearchResultItem[];
  errorMessage: string | null;
  influencerListErrorMessage: string | null;
  companyInfluencersSuccess: boolean;
  getCompaniesListSuccess: boolean;
  createInfluencerListSuccess: boolean;
  addInfluencerListSuccess: boolean;
  updateInfluencerListSuccess: boolean;
  deleteInfluencerListSuccess: boolean;
  deleteInfluencerSuccess: boolean;
  searchInfluencerSuccess: boolean;
  relatedInfluencerSuccess: boolean;
  hashtagInfluencersSuccess: boolean;
  hashtagPostsSuccess: boolean;
  hashtagRelationSuccess: boolean;
  hashtagInfluencersErrorMessage: null | string;
  hashtagPostsErrorMessage: null | string;
  hashtagRelationErrorMessage: null | string;
  aiSearchErrorMessage: null | string;
  aiSearchLoading: boolean;
  aiKeywordsLoading: boolean;
  aiHashtagsLoading: boolean;
  aiSearchSuccess: boolean;
  aiKeywordsSuccess: boolean;
  aiHashtagsSuccess: boolean;
  loading: boolean;
  hashtagInfluencersLoading: boolean;
  hashtagPostsLoading: boolean;
  hashtagRelationLoading: boolean;
  getInfluencerErrorMessage: string | null;
  getInfluencerErrorMessageDetail: string | null;
  getInfluencerSuccess: boolean;
  keywords: string[];
  currentPage: number | null;
  total: number | null;
  totalPages: number | null;
  currentHashtag: string | null;
  currentHashtagPostPage: number;
  totalHashtagPosts: number | null;
  totalHashtagPostPages: number | null;
  currentHashtagInfluencerPage: number;
  totalHashtagInfluencers: number | null;
  totalHashtagInfluencerPages: number | null;
  usernameSearchSuccess: boolean;
  simpleSearchSuccess: boolean;
  detailSearchSuccess: boolean;
}

const state: State = {
  influencer: null,
  influencers: [],
  companyInfluencers: [],
  hashtagInfluencers: [],
  hashtagPosts: [],
  hashtagRelation: null,
  aiHashtags: [],
  aiPersonas: [],
  aiKeywords: [],
  aiHashtagInfluencers: [],
  aiKeywordInfluencers: [],
  aiSearchResults: [],
  influencerList: null,
  childInfluencerList: null,
  createdInfluencerList: null,
  relatedInfluencer: null,
  errorMessage: null,
  influencerListErrorMessage: null,
  companyInfluencersSuccess: false,
  getCompaniesListSuccess: false,
  createInfluencerListSuccess: false,
  addInfluencerListSuccess: false,
  updateInfluencerListSuccess: false,
  deleteInfluencerListSuccess: false,
  deleteInfluencerSuccess: false,
  searchInfluencerSuccess: false,
  relatedInfluencerSuccess: false,
  hashtagInfluencersSuccess: false,
  hashtagPostsSuccess: false,
  hashtagRelationSuccess: false,
  hashtagInfluencersErrorMessage: null,
  hashtagPostsErrorMessage: null,
  hashtagRelationErrorMessage: null,
  aiSearchErrorMessage: null,
  aiSearchLoading: false,
  aiHashtagsLoading: false,
  aiKeywordsLoading: false,
  aiSearchSuccess: false,
  aiHashtagsSuccess: false,
  aiKeywordsSuccess: false,
  loading: false,
  hashtagInfluencersLoading: false,
  hashtagPostsLoading: false,
  hashtagRelationLoading: false,
  getInfluencerErrorMessage: null,
  getInfluencerErrorMessageDetail: null,
  getInfluencerSuccess: false,
  keywords: [],
  currentPage: null,
  total: null,
  totalPages: null,
  currentHashtag: null,
  currentHashtagPostPage: 1,
  totalHashtagPosts: null,
  totalHashtagPostPages: null,
  currentHashtagInfluencerPage: 1,
  totalHashtagInfluencers: null,
  totalHashtagInfluencerPages: null,
  usernameSearchSuccess: false,
  simpleSearchSuccess: false,
  detailSearchSuccess: false,
};

const influencers = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setInfluencerListErrorMessage(state: State, errorMessage: string | null) {
      state.influencerListErrorMessage = errorMessage;
    },
    setInfluencer(state: State, influencer: Influencer) {
      state.influencer = influencer;
    },
    setInfluencers(state: State, influencers: Influencer[]) {
      state.influencers = influencers;
    },
    setCompanyInfluencers(state: State, influencers: Influencer[]) {
      state.companyInfluencers = influencers;
    },
    setInfluencerList(state: State, influencerList: InfluencerList) {
      state.influencerList = influencerList;
    },
    setChildInfluencerList(state: State, childInfluencerList: InfluencerList) {
      state.childInfluencerList = childInfluencerList;
    },
    setCreatedInfluencerList(state: State, createdInfluencerList: InfluencerList | null) {
      state.createdInfluencerList = createdInfluencerList
    },
    setRelatedInfluencer(state: State, influencer: Influencer) {
      state.relatedInfluencer = influencer;
    },
    setCurrentHashtag(state: State, hashtag: string) {
      state.currentHashtag = hashtag;
    },
    setHashtagInfluencers(state: State, res: HashtagRelation) {
      if (res.influencers) {
        state.hashtagInfluencers = res.influencers.results;
        state.currentHashtagInfluencerPage = res.influencers.currentPage ? res.influencers.currentPage : 1;
        state.totalHashtagInfluencers = res.influencers.count ? res.influencers.count : null;
        if (state.totalHashtagInfluencers) {
          state.totalHashtagInfluencerPages = Math.ceil(state.totalHashtagInfluencers / 15);
        }
      } else {
        state.hashtagInfluencers = [];
        state.currentHashtagInfluencerPage = 1;
        state.totalHashtagInfluencers = null;
        state.totalHashtagInfluencerPages = null;
      }
    },
    setHashtagPosts(state: State, res: HashtagRelation) {
      if (res.posts) {
        state.hashtagPosts = res.posts.results;
        state.currentHashtagPostPage = res.posts.currentPage ? res.posts.currentPage : 1;
        state.totalHashtagPosts = res.posts.count ? res.posts.count : null;
        if (state.totalHashtagPosts) {
          state.totalHashtagPostPages = Math.ceil(state.totalHashtagPosts / 15);
        }
      } else {
        state.hashtagPosts = [];
        state.currentHashtagPostPage = 1;
        state.totalHashtagPosts = null;
        state.totalHashtagPostPages = null;
      }
    },
    setHashtagRelation(state: State, res: HashtagRelation) {
      state.hashtagRelation = res;
    },
    setAISearchResponse(state: State, {personas, hashtags, keywords}: {personas: string[], hashtags: string[], keywords: string[]}) {
      state.aiPersonas = personas;
      state.aiHashtags = hashtags;
      state.aiKeywords = keywords;
    },
    setAISearchKeywordInfluencers(state: State, influencers: Influencer[]) {
      state.aiKeywordInfluencers = influencers;
    },
    setAISearchHashtagInfluencers(state: State, influencers: Influencer[]) {
      state.aiHashtagInfluencers = influencers;
    },
    setAISearchSuccess(state: State, flag: boolean) {
      state.aiSearchSuccess = flag;
    },
    setAIKeywordsSuccess(state: State, flag: boolean) {
      state.aiKeywordsSuccess = flag;
    },
    setAIHashtagsSuccess(state: State, flag: boolean) {
      state.aiHashtagsSuccess = flag;
    },
    setAISearchErrorMessage(state: State, errorMessage: string | null) {
      state.aiSearchErrorMessage = errorMessage;
    },
    setCompanyInfluencersSuccess(state: State, flag: boolean) {
      state.companyInfluencersSuccess = flag;
    },
    setGetCompaniesListSuccess(state: State, flag: boolean) {
      state.getCompaniesListSuccess = flag;
    },
    setCreateInfluencerListSuccess(state: State, flag: boolean) {
      state.createInfluencerListSuccess = flag;
    },
    setAddInfluencerListSuccess(state: State, flag: boolean) {
      state.addInfluencerListSuccess = flag;
    },
    setUpdateInfluencerListSuccess(state: State, flag: boolean) {
      state.updateInfluencerListSuccess = flag;
    },
    setDeleteInfluencerListSuccess(state: State, flag: boolean) {
      state.deleteInfluencerListSuccess = flag;
    },
    setDeleteInfluencerSuccess(state: State, flag: boolean) {
      state.deleteInfluencerSuccess = flag;
    },
    setSearchInfluencerSuccess(state: State, flag: boolean) {
      state.searchInfluencerSuccess = flag;
    },
    setRelatedInfluencerSuccess(state: State, flag: boolean) {
      state.relatedInfluencerSuccess = flag;
    },
    setHashtagInfluencersSuccess(state: State, flag: boolean) {
      state.hashtagInfluencersSuccess = flag;
    },
    setHashtagPostsSuccess(state: State, flag: boolean) {
      state.hashtagPostsSuccess = flag;
    },
    setHashtagRelationSuccess(state: State, flag: boolean) {
      state.hashtagRelationSuccess = flag;
    },
    setHashtagInfluencersErrorMessage(state: State, message: string) {
      state.hashtagInfluencersErrorMessage = message;
    },
    setHashtagPostsErrorMessage(state: State, message: string) {
      state.hashtagPostsErrorMessage = message;
    },
    setHashtagRelationErrorMessage(state: State, message: string) {
      state.hashtagRelationErrorMessage = message;
    },
    setLoading(state: State, flag: boolean) {
      state.loading = flag;
    },
    setHashtagInfluencersLoading(state: State, flag: boolean) {
      state.hashtagInfluencersLoading = flag;
    },
    setHashtagPostsLoading(state: State, flag: boolean) {
      state.hashtagPostsLoading = flag;
    },
    setHashtagRelationLoading(state: State, flag: boolean) {
      state.hashtagRelationLoading = flag;
    },
    setAISearchLoading(state: State, flag: boolean) {
      state.aiSearchLoading = flag;
    },
    setAIKeywordsLoading(state: State, flag: boolean) {
      state.aiKeywordsLoading = flag;
    },
    setAIHashtagsLoading(state: State, flag: boolean) {
      state.aiHashtagsLoading = flag;
    },
    setAISearchResultList(state: State, results: AISearchResultItem[]) {
      state.aiSearchResults = results;
    },
    setGetInfluencerErrorMessage(state: State, message: string) {
      state.getInfluencerErrorMessage = message;
    },
    setGetInfluencerErrorMessageDetail(state: State, detail: string | null) {
      state.getInfluencerErrorMessageDetail = detail;
    },
    setGetInfluencerSuccess(state: State, flag: boolean) {
      state.getInfluencerSuccess = flag;
    },
    setHashtags(state: State, keywords: string[]) {
      state.keywords = keywords;
    },
    setSearchResult(state: State, result: SearchResultParams) {
      state.influencers = result.influencers;
      state.total = result.total;
      state.currentPage = result.currentPage;
      state.totalPages = Math.ceil(result.total / 15);
    },
    setTotal(state: State, total: null | number) {
      state.total = total;
    },
    setCurrentPage(state: State, currentPage: null | number) {
      state.total = currentPage;
    },
    setUsernameSearchSuccess(state: State, flag: boolean) {
      state.usernameSearchSuccess = flag;
    },
    setSimpleSearchSuccess(state: State, flag: boolean) {
      state.simpleSearchSuccess = flag;
    },
    setDetailSearchSuccess(state: State, flag: boolean) {
      state.detailSearchSuccess = flag;
    },
  },
  actions: {
    async searchUsername({ commit }: { commit: Commit }, params: SearchUsernameInfluencerParams) {
      commit("setUsernameSearchSuccess", false);
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/influencers/search-username/", params);
        commit("setSearchResult", res.data);
        commit("setLoading", false);
        commit("setUsernameSearchSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
    usernameSearchInitialize({ commit }: { commit: Commit }) {
      commit("setInfluencers", []);
      commit("setUsernameSearchSuccess", false);
      commit("setCurrentPage", null);
      commit("setTotal", null);
      commit("setErrorMessage", null);
    },
    initializeErrorMessage({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
    },
    async getCompaniesList({ commit }: { commit: Commit }) {
      commit("setGetCompaniesListSuccess", false);
      try {
        const res = await axios.get("api/v1/companies/influencer-lists/");
        commit("setInfluencerList", res.data);
        commit("setGetCompaniesListSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    initailizeAddInfluencerList({ commit }: { commit: Commit }) {
      commit("setInfluencerListErrorMessage", null);
      commit("setAddInfluencerListSuccess", false);
    },
    async addInfluencerList({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, params: AddInfluencerListParams) {
      commit("setAddInfluencerListSuccess", false);
      try {
        const res = await axios.post("api/v1/companies/influencer-lists/influencers/", params);
        commit("setInfluencerList", res.data);
        commit("setAddInfluencerListSuccess", true);
        dispatch("getInfluencerList", params.influencerList);
      } catch (err) {
        err.response.data.message ? commit("setInfluencerListErrorMessage", err.response.data.message) : commit("setInfluencerListErrorMessage", err.response.data);
      }
    },
    async getCompanyInfluencers({ commit }: { commit: Commit }) {
      commit("setCompanyInfluencersSuccess", false);
      try {
        const res = await axios.get("api/v1/companies/influencers/");
        commit("setCompanyInfluencers", res.data);
        commit("setCompanyInfluencersSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async getInfluencerList({ commit }: { commit: Commit }, listId: number) {
      try {
        const res = await axios.get(`api/v1/companies/influencer-lists/${listId}/`);
        commit("setChildInfluencerList", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async deleteInfluencer({ commit }: { commit: Commit }, params: DeleteInfluencerListParams) {
      commit("setDeleteInfluencerSuccess", false);
      try {
        await axios.delete("api/v1/companies/influencer-lists/influencers/", { data: params });
        commit("setDeleteInfluencerSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async createInfluencerList({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, params: CreateInfluencerListParams) {
      commit("setCreateInfluencerListSuccess", false);
      try {
        const res = await axios.post("api/v1/companies/influencer-lists/", params);
        commit("setCreateInfluencerListSuccess", true);
        commit("setCreatedInfluencerList", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async deleteInfluencerList({ commit }: { commit: Commit }, influencerListId: number) {
      commit("setDeleteInfluencerListSuccess", false);
      try {
        await axios.delete(`api/v1/companies/influencer-lists/${influencerListId}/`);
        commit("setDeleteInfluencerListSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateInfluencerList({ commit }: { commit: Commit }, { params, influencerFolderId }: { params: UpdateInfluencerListParams; influencerFolderId: number }) {
      commit("setUpdateInfluencerListSuccess", false);
      try {
        await axios.patch(`/api/v1/companies/influencer-lists/${influencerFolderId}/`, params);
        commit("setUpdateInfluencerListSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    simpleSearchInitialize({ commit }: { commit: Commit }) {
      commit("setInfluencers", []);
      commit("setCurrentPage", null);
      commit("setTotal", null);
      commit("setSimpleSearchSuccess", false);
      commit("setErrorMessage", null);
    },
    async simpleSearch({ commit }: { commit: Commit }, params: SimpleSearchParams) {
      commit("setSimpleSearchSuccess", false);
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/influencers/simple-search/", params);
        commit("setSearchResult", res.data);
        commit("setSimpleSearchSuccess", true);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
    async getKeywords({ commit }: { commit: Commit }, params: GetHashtagsParams) {
      try {
        const res = await axios.post("api/v1/influencers/simple-search/hashtags/", params);
        commit("setHashtags", res.data);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    detailSearchInitialize({ commit }: { commit: Commit }) {
      commit("setInfluencers", []);
      commit("setCurrentPage", null);
      commit("setTotal", null);
      commit("setDetailSearchSuccess", false);
      commit("setErrorMessage", null);
    },
    async detailSearch({ commit }: { commit: Commit }, params: DetailSearchParams) {
      commit("setDetailSearchSuccess", false);
      commit("setLoading", true);
      try{
        const res = await axios.post("api/v1/influencers/detail-search/", params);
        commit("setSearchResult", res.data);
        commit("setDetailSearchSuccess", true);
        commit("setLoading", false);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
        commit("setLoading", false);
      }
    },
    async getInfluencerDetail({ commit }: { commit: Commit }, influencerId: string) {
      commit("setGetInfluencerSuccess", false);
      commit("setLoading", true);
      try {
        const res = await axios.post("api/v1/influencers/", { influencer: influencerId });
        commit("setInfluencer", res.data);
        commit("setLoading", false);
        commit("setGetInfluencerSuccess", true);
      } catch (err) {
        commit("setGetInfluencerErrorMessage", err.message);
        commit("setLoading", false);
      }
    },
    hashtagSearchInitialize({ commit }: { commit: Commit }) {
      commit("setHashtagInfluencers", {});
      commit("setHashtagPosts", {});
      commit("setHashtagRelation", null);
      commit("setHashtagInfluencersSuccess", false);
      commit("setHashtagPostsSuccess", false);
      commit("setHashtagRelationSuccess", false);
      commit("setHashtagInfluencersLoading", false);
      commit("setHashtagPostsLoading", false);
      commit("setHashtagRelationLoading", false);
      commit("setHashtagInfluencersErrorMessage", null);
      commit("setHashtagPostsErrorMessage", null);
      commit("setHashtagRelationErrorMessage", null);
    },
    async getHashtagRelation({ commit }: { commit: Commit }, params: HashtagSearchRequestParams) {
      commit("setHashtagRelationSuccess", false);
      commit("setHashtagRelationLoading", true);
      commit("setHashtagRelationErrorMessage", null);
      commit("setCurrentHashtag", params.hashtag);
      try {
        const res = await axios.post("api/v1/influencers/hashtag-search/relations/", params);
        commit("setHashtagRelationSuccess", true);
        commit("setHashtagRelationLoading", false);
        commit("setHashtagRelation", res.data);
      } catch (err) {
        commit("setHashtagRelationSuccess", false);
        commit("setHashtagRelationLoading", false);
        commit("setHashtagRelationErrorMessage", err.response.data.message);
      }
    },
    async getHashtagInfluencers({ commit }: { commit: Commit }, params: HashtagSearchRequestParams) {
      commit("setHashtagInfluencersSuccess", false);
      commit("setHashtagInfluencersLoading", true);
      commit("setHashtagInfluencersErrorMessage", null);
      commit("setCurrentHashtag", params.hashtag);
      try {
        const res = await axios.post("api/v1/influencers/hashtag-search/influencers/", params);
        commit("setHashtagInfluencersSuccess", true);
        commit("setHashtagInfluencersLoading", false);
        commit("setHashtagInfluencers", res.data);
      } catch (err) {
        commit("setHashtagInfluencersSuccess", false);
        commit("setHashtagInfluencersLoading", false);
        commit("setHashtagInfluencersErrorMessage", err.response.data.message);
      }
    },
    async getHashtagPosts({ commit }: { commit: Commit }, params: HashtagSearchRequestParams) {
      commit("setHashtagPostsSuccess", false);
      commit("setHashtagPostsLoading", true);
      commit("setHashtagPostsErrorMessage", null);
      commit("setCurrentHashtag", params.hashtag);
      try {
        const res = await axios.post("api/v1/influencers/hashtag-search/posts/", params);
        commit("setHashtagPostsSuccess", true);
        commit("setHashtagPostsLoading", false);
        commit("setHashtagPosts", res.data);
      } catch (err) {
        commit("setHashtagPostsSuccess", false);
        commit("setHashtagPostsLoading", false);
        commit("setHashtagPostsErrorMessage", err.response.data.message);
      }
    },
    initializeAISearch({ commit }: { commit: Commit }) {
      commit("setAISearchSuccess", false);
      commit("setAIKeywordsSuccess", false);
      commit("setAIHashtagsSuccess", false);
      commit("setAISearchLoading", false);
      commit("setAIKeywordsLoading", false);
      commit("setAIHashtagsLoading", false);
      commit("setAISearchResponse", {personas: [], hashtags: [], keywords: []});
      commit("setAISearchKeywordInfluencers", []);
      commit("setAISearchHashtagInfluencers", []);
      commit("setAISearchErrorMessage", null);
    },
    async aiSearch({ commit }: { commit: Commit }, text: string) {
      commit("setAISearchLoading", true);
      commit("setAISearchSuccess", false);
      commit("setAIKeywordsLoading", true);
      commit("setAIKeywordsSuccess", false);
      commit("setAIHashtagsLoading", true);
      commit("setAIHashtagsSuccess", false);
      commit("setAISearchResponse", {personas: [], hashtags: [], keywords: []});
      commit("setAISearchKeywordInfluencers", []);
      commit("setAISearchHashtagInfluencers", []);
      commit("setAISearchErrorMessage", null);
      try {
        const res = await axios.post("api/v1/influencers/ai-search/", { text: text });
        interface resultResponseType {
          success: boolean;
          progress: boolean;
          data: {
            personas: string[],
            hashtags: string[],
            keywords: string[]
          }
        }
        const getRes = (key: string) => new Promise<resultResponseType> ((resolve) => {
          setTimeout(async () => {
            const asyncRes = await axios.post("api/v1/influencers/ai-search/result/", { key: key });
            const { success, progress, data } = asyncRes.data;
            resolve({ success, progress, data });
          }, 3000)
        });
        let result: resultResponseType = {success: false, progress: true, data: {personas: [], hashtags: [], keywords: []}};
        for (let i=0; i < 21; i++) {
          if (!result.progress) {
            break
          }
          result = await getRes(res.data.key);
        }
        if (result.success) {
          commit("setAISearchSuccess", true);
          commit("setAISearchLoading", false);
          commit("setAISearchResponse", result.data);
        } else {
          commit("setAISearchSuccess", false);
          commit("setAISearchLoading", false);
          commit("setAISearchErrorMessage", "AI検索結果の生成に失敗しました。再度検索してください。なお、検索結果は消費されません")
        }
      } catch (err) {
        commit("setAISearchSuccess", false);
        commit("setAISearchLoading", false);
        commit("setAISearchErrorMessage", "AI検索結果の生成に失敗しました。再度検索してください。なお、検索結果は消費されません")
      }
    },
    async getAIKeywordSearch({ commit }: { commit: Commit }, params: DetailSearchParams) {
      commit("setAIKeywordsLoading", true);
      commit("setAIKeywordsSuccess", false);
      try {
        const res = await axios.post("api/v1/influencers/ai-search/result/detail/", params);
        commit("setAIKeywordsSuccess", true);
        commit("setAIKeywordsLoading", false);
        commit("setAISearchKeywordInfluencers", res.data.influencers);
      } catch (e) {
        commit("setAIKeywordsSuccess", false);
        commit("setAIKeywordsLoading", false);
      }
    },
    async getAIHashtagSearch({ commit }: { commit: Commit }, params: DetailSearchParams) {
      commit("setAIHashtagsLoading", true);
      commit("setAIHashtagsSuccess", false);
      try {
        const res = await axios.post("api/v1/influencers/ai-search/result/detail/", params);
        commit("setAIHashtagsSuccess", true);
        commit("setAIHashtagsLoading", false);
        commit("setAISearchHashtagInfluencers", res.data.influencers);
      } catch (e) {
        commit("setAIHashtagsSuccess", false);
        commit("setAIHashtagsLoading", false);
      }
    },
    async getAISearchResultList({ commit }: { commit: Commit }) {
      try {
        const res = await axios.get("api/v1/influencers/ai-search/results/");
        commit("setAISearchResultList", res.data);
      } catch(e) {
        return
      }
    },
    async getAISearchResult({ commit }: { commit: Commit }, key: string) {
      commit("setAISearchLoading", true);
      commit("setAISearchSuccess", false);
      commit("setAIKeywordsLoading", true);
      commit("setAIKeywordsSuccess", false);
      commit("setAIHashtagsLoading", true);
      commit("setAIHashtagsSuccess", false);
      commit("setAISearchResponse", {personas: [], hashtags: [], keywords: []});
      commit("setAISearchKeywordInfluencers", []);
      commit("setAISearchHashtagInfluencers", []);
      commit("setAISearchErrorMessage", null);
      try{
        const res = await axios.post("api/v1/influencers/ai-search/result/", { key: key });
        if (res.data.success) {
          commit("setAISearchSuccess", true);
          commit("setAISearchLoading", false);
          commit("setAISearchResponse", res.data.data);
        } else {
          commit("setAISearchSuccess", false);
          commit("setAISearchLoading", false);
          commit("setAISearchErrorMessage", "AI検索結果の生成に失敗しました。再度検索してください。なお、検索結果は消費されません")
        }
      } catch (e) {
        commit("setAISearchSuccess", false);
        commit("setAISearchLoading", false);
        commit("setAISearchErrorMessage", "AI検索結果の生成に失敗しました。再度検索してください。なお、検索結果は消費されません")
      }
    }
  },
};

export default influencers;
