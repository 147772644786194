import axios, { AxiosTransformer, AxiosRequestConfig, AxiosResponse } from "axios";
import humps from "humps";

const dateFormat = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/;
const isInvalidDate = (date: Date) => Number.isNaN(date.getDate());

export const transformRequest = (data: any) => (data ? JSON.stringify(humps.decamelizeKeys(JSON.parse(data))) : data);
export const transformResponse = (data: any) => {
  return JSON.parse(JSON.stringify(humps.camelizeKeys(data)), (_, value) => {
    if (typeof value === "string" && dateFormat.test(value)) {
      return !isInvalidDate(new Date(value)) ? new Date(value) : value;
    } else {
      return value;
    }
  });
};

const headers = {
  "Content-Type": "application/json",
};

// DRF の csrf 対策用
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

// 型定義エラー対策
const defaultTransformersRequest = (): AxiosTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  } else if (transformRequest instanceof Array) {
    return transformRequest;
  } else {
    return [transformRequest];
  }
};

// 型定義エラー対策
const defaultTransformersResponse = (): AxiosTransformer[] => {
  const { transformResponse } = axios.defaults;
  if (!transformResponse) {
    return [];
  } else if (transformResponse instanceof Array) {
    return transformResponse;
  } else {
    return [transformResponse];
  }
};

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers,
  transformRequest: [...defaultTransformersRequest(), (data: any) => transformRequest(data)],
  transformResponse: [...defaultTransformersResponse(), (data: any) => transformResponse(data)],
});

client.interceptors.request.use((config: AxiosRequestConfig) => {
  // devの時だけ通信をコンソールに表示
  if (process.env.NODE_ENV === "development") console.log(config);
  if (localStorage.token) {
    config.headers["Authorization"] = `JWT ${localStorage.token}`;
  }
  // params にもhumps適用
  if (config.params) {
    config.params = config.params ? humps.decamelizeKeys(config.params) : config.params;
  }
  return config;
});
client.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    throw error;
  }
);
export default client;
