<template>
  <div class="trash-icon">
    <div class="trash-icon__text" v-if="disableIcon" @click="clickIcon"><slot></slot></div>
    <svg class="material-design-icon__svg" v-else :width="width" :height="height" viewBox="0 0 21 24" @click="clickIcon">
      <path
        d="M1.5,21.75A2.25,2.25,0,0,0,3.75,24h13.5a2.25,2.25,0,0,0,2.25-2.25h0V6H1.5Zm12.75-12a.75.75,0,0,1,1.5,0v10.5a.75.75,0,1,1-1.5,0Zm-4.5,0a.75.75,0,0,1,1.5,0v10.5a.75.75,0,1,1-1.5,0Zm-4.5,0a.75.75,0,1,1,1.5,0v10.5a.75.75,0,1,1-1.5,0Zm15-8.25H14.625L14.184.623A1.125,1.125,0,0,0,13.177,0H7.819a1.112,1.112,0,0,0-1,.623L6.375,1.5H.75A.75.75,0,0,0,0,2.25v1.5a.75.75,0,0,0,.75.75h19.5A.75.75,0,0,0,21,3.75V2.25A.75.75,0,0,0,20.25,1.5Z"
        transform="translate(0 0)"
        :fill="fillColor"
      />
    </svg>
    <div class="trash-icon__dialog" v-show="show">
      <div class="trash-icon__dialog__container">
        <div class="trash-icon__dialog__content">
          <div class="trash-icon__dialog__content__title mb-40px">本当に削除しますか？</div>
          <div class="trash-icon__dialog__content__text mb-40px">削除すると元に戻すことはできません。削除してもよろしいですか？</div>
          <div class="mb-20px">
            <base-button color="accent" size="wide" @click="handleConfirmDelete">削除する</base-button>
          </div>
          <div class="mb-20px">
            <base-button color="dark" size="wide" @click="show = false"> キャンセル </base-button>
          </div>
          <div>
            <base-checkbox v-model="deleteChecked" :id="String(id)">
              <h4>次回から表示しない</h4>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, reactive, toRefs, watch} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";
import {CompanyMember, MemberInvitationParamsForSetting, UserUpdateParams} from "@/types";

export default defineComponent({
  name: "TrashIcon",
  components: { BaseButton, BaseCheckbox },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object as PropType<CompanyMember>,
    },
    invitationMember: {
      type: Object as PropType<MemberInvitationParamsForSetting>,
    },
    id: {
      type: String,
      default: "delete-checkbox",
    },
    trashType: {
      type: String,
      validator: (value: string): boolean => {
        // The value must match one of these strings
        return ["influencer", "member"].includes(value);
      },
      default: "influencer",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      deleteChecked: false,
      formErrorMessage: null as string | null,
      memberId: props.member?.id,
      invitationMemberId: props.invitationMember?.id,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    const checkSkipConfirmDelete = () => {
      if (props.trashType === "influencer") {
        return state.user?.isReadDeleteList;
      } else if (props.trashType === "member") {
        return state.user?.isReadDeleteMember;
      } else return false;
    };
    const handleConfirmDelete = () => {
      if (state.deleteChecked) {
        let params = {} as UserUpdateParams;
        if (props.trashType === "influencer") {
          params = { isReadDeleteList: state.deleteChecked };
        } else if (props.trashType === "member") {
          params = { isReadDeleteMember: state.deleteChecked };
        } else return;
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show = false;
      context.emit("confirmDelete");
    };
    const clickIcon = () => {
      if (!checkSkipConfirmDelete()) {
        state.show = true;
      } else {
        handleConfirmDelete();
      }
    };
    const handleDeleteCheck = () => {
      context.emit("update:modelValue", state.deleteChecked);
    };
    return { ...toRefs(state), handleDeleteCheck, clickIcon, handleConfirmDelete };
  },
});
</script>

<style lang="scss" scoped>
.trash-icon {
  & svg {
    &:hover {
      opacity: 0.5;
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      position: absolute;
      width: 400px;
      padding: 100px 200px;
      background-color: white;
      border-radius: 10px;
      text-align: center;
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "accent");
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "accent");
        }
      }
    }
  }
}
</style>
