export class SNSType {
  static readonly NOSELECT = new SNSType(0, "指定しない");
  static readonly INSTAGRAM = new SNSType(1, "Instagram");
  static readonly YOUTUBE = new SNSType(2, "YouTube");
  static readonly TIKTOK = new SNSType(3, "TikTok");
  static readonly TWITTER = new SNSType(4, "Twitter");

  private constructor(private readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const snsType = this.getAll().find((snsType) => snsType.key === key);
    return snsType ? snsType.text : "";
  }
  static getObject(key: number): SNSType | null {
    const snsType = this.getAll().find((snsType) => snsType.key === key);
    return snsType !== undefined ? snsType : null;
  }
  private static getAll(): Array<SNSType> {
    return [SNSType.NOSELECT, SNSType.INSTAGRAM, SNSType.YOUTUBE, SNSType.TIKTOK, SNSType.TWITTER];
  }
}
