
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import { DeleteInfluencerListParams, Influencer } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "InfluencerListTable",
  props: {
    items: {
      type: Array as () => Influencer[],
      default: () => [],
    },
    listId: {
      type: Number,
      required: false,
    },
  },
  components: {
    YoutubeIcon,
    TiktokIcon,
    TwitterIcon,
    TrashIcon,
    InstagramIcon,
    SelectBox,
    OpenDetailIcon,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        delete: false,
        errorDialog: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "fullname",
          text: "名前",
        },
        {
          key: "followers",
          text: "フォロワー",
        },
        {
          key: "engagementRate",
          text: "エンゲージメント",
        },
      ],
      sortedItems: props.items.slice(),
      listChecked: false,
      detailChecked: false,
      deleteChecked: false,
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      deleteInfluencerSuccess: computed(() => store.state.influencers.deleteInfluencerSuccess),
      childInfluencerList: computed(() => store.state.influencers?.childInfluencerList),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      influencerId: null as string | null,
      formErrorMessage: null as string | null,
    });
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as Influencer[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const deleteInfluencer = (influencerId: string) => {
      if (!props.listId) {
        const params: DeleteInfluencerListParams = {
          influencerList: state.influencerList,
          influencers: [{ id: influencerId }],
        };
        store.dispatch("influencers/deleteInfluencer", params);
      } else {
        const params: DeleteInfluencerListParams = {
          influencerList: Number(state.childInfluencerList?.id),
          influencers: [{ id: influencerId }],
        };
        store.dispatch("influencers/deleteInfluencer", params);
      }
    };
    let deleteInfluencerSuccess = computed(() => state.deleteInfluencerSuccess);
    watch(deleteInfluencerSuccess, (val: boolean) => {
      if (val) state.show.trash = false;
      if (val) state.show.delete = true;
      if (val) context.emit("influencerDelete");
    });
    return { ...toRefs(state), sortByKey, deleteInfluencer, viewInfluencerDetail };
  },
});
