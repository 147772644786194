import axios from "@/store/axios";
import { Commit, Dispatch } from "vuex";
import { CompanyMember, MemberInvitationParams, MemberCreateParams, MemberInvitationParamsForSetting } from "@/types";

interface State {
  member: CompanyMember | null;
  invitationMember: MemberInvitationParamsForSetting | null;
  errorMessage: string | null;
  invitationMemberSuccess: boolean;
  createMemberSuccess: boolean;
  updateMemberSuccess: boolean;
  deleteMemberSuccess: boolean;
  deleteInvitationMemberSuccess: boolean;
  token: string;
}
const state: State = {
  member: null,
  invitationMember: null,
  errorMessage: null,
  invitationMemberSuccess: false,
  createMemberSuccess: false,
  updateMemberSuccess: false,
  deleteMemberSuccess: false,
  deleteInvitationMemberSuccess: false,
  token: "",
};

const members = {
  namespaced: true,
  state,
  mutations: {
    setMember(state: State, member: CompanyMember) {
      state.member = member;
    },
    setInvitationMember(state: State, member: MemberInvitationParamsForSetting) {
      state.invitationMember = member;
    },
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setInvitationMemberSuccess(state: State, flag: boolean) {
      state.invitationMemberSuccess = flag;
    },
    setCreateMemberSuccess(state: State, flag: boolean) {
      state.createMemberSuccess = flag;
    },
    setUpdateMemberSuccess(state: State, flag: boolean) {
      state.updateMemberSuccess = flag;
    },
    setDeleteMemberSuccess(state: State, flag: boolean) {
      state.deleteMemberSuccess = flag;
    },
    setDeleteInvitationMemberSuccess(state: State, flag: boolean) {
      state.deleteInvitationMemberSuccess = flag;
    },
  },
  actions: {
    invitationMemberInitialize({commit}: {commit: Commit}) {
      commit("setInvitationMemberSuccess", false);
      commit("setErrorMessage", null);
    },
    async invitationMember({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, params: MemberInvitationParams) {
      commit("setInvitationMemberSuccess", false);
      try {
        await axios.post("api/v1/companies/member-invitations/", params);
        commit("setInvitationMemberSuccess", true);
        dispatch("loadInvitationMember");
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async createMember({ commit }: { commit: Commit }, params: MemberCreateParams) {
      commit("setErrorMessage", null);
      commit("setCreateMemberSuccess", false);
      try {
        await axios.post("api/v1/companies/member-verify/", params);
        commit("setCreateMemberSuccess", true);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async loadMember({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      try {
        const res = await axios.get("api/v1/companies/members/");
        commit("setMember", res.data.results);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async loadInvitationMember({ commit }: { commit: Commit }) {
      commit("setErrorMessage", null);
      try {
        const res = await axios.get("api/v1/companies/member-invitations/");
        commit("setInvitationMember", res.data.results);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async updateMemberRole({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, { role, memberId }: { role: number; memberId: number }) {
      commit("setUpdateMemberSuccess", false);
      commit("setErrorMessage", null);
      try {
        const res = await axios.patch(`api/v1/companies/members/${memberId}/`, { role: role });
        commit("setUpdateMemberSuccess", true);
        const member = res.data;
        commit("setMember", member);
        dispatch("companies/loadCompany", member.company, {root: true});
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async deleteMember({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, memberId: number) {
      commit("setErrorMessage", null);
      commit("setDeleteMemberSuccess", false);
      try {
        const res = await axios.delete(`api/v1/companies/members/${memberId}/`);
        const member = res.data;
        commit("setMember", member);
        commit("setDeleteMemberSuccess", true);
        dispatch("companies/loadCompany", member.company, {root: true});
      }catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
    async deleteInvitationMember({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, invitationId: number) {
      commit("setErrorMessage", null);
      commit("setDeleteInvitationMemberSuccess", false);
      try {
        await axios.delete(`api/v1/companies/member-invitations/${invitationId}/`);
        dispatch("loadInvitationMember");
        commit("setDeleteInvitationMemberSuccess", true);
      }catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default members;
