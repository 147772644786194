<template>
  <div class="open-detail">
    <div class="open-detail__text" v-if="disableIcon" @click="clickIcon"><slot></slot></div>
    <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 36 29" @click="clickIcon" v-else>
      <path
        d="M11.7,19.938a1.279,1.279,0,0,0-.4.062,7.374,7.374,0,0,1-2.3.391A7.38,7.38,0,0,1,6.7,20a1.275,1.275,0,0,0-.4-.062A6.323,6.323,0,0,0,0,26.316,2.7,2.7,0,0,0,2.7,29H15.3A2.7,2.7,0,0,0,18,26.316,6.323,6.323,0,0,0,11.7,19.938ZM9,18.125a5.438,5.438,0,1,0-5.4-5.438A5.419,5.419,0,0,0,9,18.125ZM33.3,0H11.7A2.759,2.759,0,0,0,9,2.809V5.438a7.081,7.081,0,0,1,3.6,1.008V3.625H32.4V19.938H17.31a7.226,7.226,0,0,1,2.233,3.625H33.3A2.759,2.759,0,0,0,36,20.754V2.809A2.759,2.759,0,0,0,33.3,0ZM25.389,5.314v3.67a.74.74,0,0,1-1.265.528l-1-1.007L20.16,11.49a.983.983,0,0,1-1.4,0L16.5,9.209l-.717.722a7.382,7.382,0,0,0-1.025-1.781L15.8,7.1a.983.983,0,0,1,1.4,0L19.461,9.38,21.727,7.1l-1-1.007a.747.747,0,0,1,.524-1.274H24.9A.5.5,0,0,1,25.389,5.314Zm5.252,8.516H21.074V12.225h9.567Zm0,2.408H21.074V14.633h9.567Zm0,2.408H21.074V17.041h9.567Z"
        transform="translate(0.002)"
        :fill="fillColor"
      />
    </svg>
    <div class="open-detail__dialog" v-show="show">
      <div class="open-detail__dialog__container">
        <div class="open-detail__dialog__content">
          <div class="open-detail__dialog__content__title mb-40px">インフルエンサー詳細を閲覧する</div>
          <div class="open-detail__dialog__content__text mb-40px">
            ご利用中のプランのインフルエンサー詳細閲覧は<span>毎月{{ company?.plan.detailLimitation }}人</span>までです。 <br />
            今月の残りの閲覧可能数は<span> {{ getSafeCount(company?.plan?.detailLimitation, company?.actions.slice(-1)[0].detailCount) }}人</span>となっています。
          </div>
          <div class="mb-20px">
            <base-button color="blue" size="wide" @click="handleConfirmView"> 詳細を閲覧する </base-button>
          </div>
          <div class="mb-20px">
            <base-button color="dark" size="wide" @click="show = false"> キャンセル </base-button>
          </div>
          <div>
            <base-checkbox v-model="checked" :id="id">
              <h4>次回から表示しない</h4>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OpenDetailIcon",
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 36,
    },
    height: {
      type: Number,
      default: 29,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    requiredConfirm: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "open-detail-checkbox",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseCheckbox,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      checked: false,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    const handleConfirmView = () => {
      if (state.checked) {
        const params = { isReadDetail: state.checked };
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show = false;
      context.emit("confirmView");
    };
    const clickIcon = () => {
      if (!state.user?.isReadDetail) {
        state.show = true;
        store.dispatch("users/loadCurrentUser");
      } else {
        handleConfirmView();
      }
    };
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
    };
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    let checked = computed(() => state.checked);
    watch(checked, () => handleCheck());
    return {
      ...toRefs(state),
      clickIcon,
      handleCheck,
      handleConfirmView,
      getSafeCount,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main.scss";
.open-detail {
  &__text {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & svg {
    &:hover {
      opacity: 0.5;
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 720px;
      padding: 100px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      text-align: center;
      @include mq(xs) {
        width: 80vw;
        padding: 20px;
        border: solid 2px map-get($colors, "blue400");
      }
      @include mq(lg) {
        width: 720px;
        padding: 100px;
        border: none;
      }
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        @include mq(xs) {
          font-size: 20px;
        }
        @include mq(lg) {
          font-size: 24px;
        }
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        @include mq(xs) {
          font-size: 14px;
        }
        @include mq(lg) {
          font-size: 18px;
        }
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "blue400");
          @include mq(xs) {
            font-size: 16px;
          }
          @include mq(lg) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
