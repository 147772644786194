export class TaskType {
  static readonly INTERVIEW = new TaskType(0, "インタビュー");
  static readonly COLLABORATION = new TaskType(1, "コラボレーション");
  static readonly PR = new TaskType(2, "PR");
  static readonly OTHERS = new TaskType(3, "その他");

  private constructor(public readonly key: number, public readonly text: string) {}

  static findTextByKey(key: number): string {
    const taskType = this.getAll().find((taskType) => taskType.key === key);
    return taskType ? taskType.text : "";
  }
  static getObject(key: number): TaskType | null {
    const taskType = this.getAll().find((taskType) => taskType.key === key);
    return taskType !== undefined ? taskType : null;
  }
  private static getAll(): Array<TaskType> {
    return [TaskType.INTERVIEW, TaskType.COLLABORATION, TaskType.PR, TaskType.OTHERS];
  }
}
