<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
      <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
      <path d="M291.673,247.449H278.339a1.31,1.31,0,0,0-1.333,1.285v9.429a1.31,1.31,0,0,0,1.333,1.285h13.334a1.31,1.31,0,0,0,1.333-1.285v-9.429A1.31,1.31,0,0,0,291.673,247.449Zm-9.778,2.572a1.715,1.715,0,1,1-1.778,1.714A1.749,1.749,0,0,1,281.894,250.021Zm3.112,6.342a.576.576,0,0,1-.622.514h-4.978a.576.576,0,0,1-.622-.514v-.514a1.728,1.728,0,0,1,1.867-1.542h.139a2.963,2.963,0,0,0,2.211,0h.139a1.728,1.728,0,0,1,1.866,1.542Zm6.222-1.414a.219.219,0,0,1-.222.214h-4a.219.219,0,0,1-.223-.214v-.429a.219.219,0,0,1,.223-.214h4a.219.219,0,0,1,.222.214Zm0-1.714a.219.219,0,0,1-.222.214h-4a.219.219,0,0,1-.223-.214v-.429a.22.22,0,0,1,.223-.215h4a.219.219,0,0,1,.222.215Zm0-1.715a.219.219,0,0,1-.222.215h-4a.22.22,0,0,1-.223-.215v-.428a.22.22,0,0,1,.223-.215h4a.219.219,0,0,1,.222.215Z" transform="translate(-261.006 -229.449)" :fill="fillColor" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "AccountEditIcon",
  props: {
      fillColor: {
        type: String,
        default: "#fff"
      },
      size: {
        type: Number,
        default: 48
      },
      rounded: {
        type: Boolean,
        default: false
      }
    }
})

</script>




