<template>
  <div class="open-list" v-click-outside="onClickOutside">
    <div v-if="disableIcon">
      <div v-if="small" class="d-flex">
        <div class="open-list__button__small" @click="clickIcon">リストに保存</div>
        <div class="open-list__pull-down__small" @click="show.purpose = true">▼</div>
      </div>
      <div v-else class="d-flex">
        <div class="open-list__button" @click="clickIcon">リストに保存</div>
        <div class="open-list__pull-down" @click="show.purpose = true">▼</div>
      </div>
    </div>
    <svg class="material-design-icon__svg" :width="width" :height="height" viewBox="0 0 36 25" @click="show.select = true" v-else-if="!disableIcon">
      <path
        d="M62.838,101.082H51.064a.906.906,0,0,1-.907-.9V98.372a.905.905,0,0,1,.907-.9H67.485A8.6,8.6,0,0,0,62.838,101.082ZM43.8,96.565a2.711,2.711,0,1,0,2.723,2.711A2.717,2.717,0,0,0,43.8,96.565Zm0-9.036a2.711,2.711,0,1,0,2.723,2.711A2.717,2.717,0,0,0,43.8,87.529Zm7.26,18.975a.906.906,0,0,0-.907.9v1.807a.905.905,0,0,0,.907.9H62.838a8.466,8.466,0,0,1-1.25-3.614Zm-7.26-.9a2.711,2.711,0,1,0,2.723,2.711A2.717,2.717,0,0,0,43.8,105.6Zm7.26-13.553H69.214a.906.906,0,0,0,.908-.9V89.336a.906.906,0,0,0-.908-.9H51.064a.906.906,0,0,0-.907.9v1.807A.906.906,0,0,0,51.064,92.047Zm26.017,13.058v.99a.992.992,0,0,1-.994.99H71.613v4.454a.992.992,0,0,1-.994.99h-.994a.992.992,0,0,1-.994-.99v-4.454H64.157a.992.992,0,0,1-.994-.99v-.99a.992.992,0,0,1,.994-.99h4.474V99.661a.992.992,0,0,1,.994-.99h.994a.992.992,0,0,1,.994.99v4.454h4.474A.992.992,0,0,1,77.081,105.105Z"
        transform="translate(-41.081 -87.529)"
        fill="#707070"
      />
    </svg>
    <div class="open-list__purpose" v-show="show.purpose">
      <div class="open-list__purpose__option" @click="clickIcon">保存先を指定しない</div>
      <div class="open-list__purpose__option" @click="selectFolder">保存先を指定する</div>
      <div class="open-list__purpose__option" @click="show.purpose = false">キャンセル</div>
    </div>
    <div class="open-list__select-dialog" v-show="show.select">
      <div class="open-list__select-dialog__container">
        <div class="open-list__select-dialog__content">
          <div class="open-list__select-dialog__content__head create-folder" v-if="show.createFolder">
            <div class="create-folder-cancel-btn" @click="show.createFolder = false">
              <img src="@/assets/img/arrow-white.svg" />
            </div>
            <div class="create-folder-input__wapper">
              <input class="create-folder-input" v-model="newFolderName" placeholder="フォルダ名を入力" />
              <div class="create-folder-input__btn" @click="createFolder"><img src="@/assets/img/check-white.svg" /></div>
            </div>
          </div>
          <div class="open-list__select-dialog__content__head" v-else-if="listNestRank === 0">
            {{ company?.name }}のリストに保存する
            <span
              class="batsu"
              @click="
                show.select = false;
                show.purpose = false;
              "
            ></span>
          </div>
          <div class="open-list__select-dialog__content__head d-flex" v-else>
            <img src="@/assets/img/arrow-white.svg" @click="moveParentInfluencerList" />
            <div>{{ currentInfluencerList?.name || `${company.name}のフォルダ` }}のリストに保存する</div>
            <div class="batsu" @click="closeFolder"></div>
          </div>
          <div class="open-list__select-dialog__content__options" v-if="show.createFolder">
            <div class="no-item-text">新しいフォルダを作成する</div>
          </div>
          <div class="open-list__select-dialog__content__options" v-else>
            <div v-for="item in currentInfluencerList?.children" :key="item.id">
              <div class="open-list__select-dialog__content__option d-flex justify-content-space-between" @click="moveChildInfluencerList(item.id)">
                <div class="d-flex align-items-center">
                  <folder-icon class="mr-20px"></folder-icon>
                  {{ item.name }}
                </div>
                <div class="open-list__select-dialog__content__option__open-folder d-flex">
                  <img src="@/assets/img/arrow-blue.svg" />
                </div>
              </div>
            </div>
            <div class="open-list__select-dialog__content__no-select d-flex" v-for="item in currentInfluencerList?.influencers" :key="item.key">
              <div class="open-list__select-dialog__content__no-select__thumbnail">
                <img :src="item.picture" />
              </div>
              <instagram-icon v-if="item.type === 0"></instagram-icon>
              <youtube-icon v-else-if="item.type === 1"></youtube-icon>
              <tiktok-icon v-else-if="item.type === 2"></tiktok-icon>
              <div class="open-list__select-dialog__content__no-select__name">
                <h4>{{ item.fullname }}</h4>
                <h6>{{ item.username }}</h6>
              </div>
            </div>
            <div class="no-item-text" v-if="(currentInfluencerList?.children || []).length === 0 && (currentInfluencerList?.influencers || []).length === 0">このフォルダは空です</div>
          </div>
          <div class="open-list__select-dialog__content__button d-flex justify-content-space-between align-items-center" v-if="currentInfluencerList?.id">
            <div class="create-folder d-flex align-items-center" @click="show.createFolder = true" v-show="!show.createFolder">
              <img src="@/assets/img/create-folder.svg" class="mr-10px" v-show="!show.createFolder" />
              <h4 class="create-folder__text" v-show="!show.createFolder">フォルダを新規作成</h4>
            </div>
            <base-button @click="clickIcon" v-if="!checkInfluencerExists(currentInfluencerList)" v-show="!show.createFolder">保存する</base-button>
            <base-button disabled v-else v-show="!show.createFolder">保存済です</base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="open-list__dialog" v-show="show.dialog">
      <div class="open-list__dialog__container">
        <div class="open-list__dialog__content">
          <div class="open-list__dialog__content__title mb-40px">インフルエンサーリストに追加する</div>
          <div class="open-list__dialog__content__text mb-40px" v-if="company?.plan.myListLimitation > 0">
            ご利用中のプランのリスト追加可能数は<span>{{ company?.plan.myListLimitation }}人</span>までです。 <br />
            残りのリスト追加可能数は<span>{{ getSafeCount(company?.plan?.myListLimitation, company?.myListCount) }}人</span>となっています。
          </div>
          <div class="open-list__dialog__content__text mb-40px" v-else>ご利用中のプランのリスト追加可能数は<span>無制限</span>となっております。<br /></div>
          <div class="mb-20px">
            <base-button size="wide" color="blue" @click="handleAddList"> リストに追加する </base-button>
          </div>
          <div class="mb-20px">
            <base-button
              color="dark"
              size="wide"
              @click="
                show.dialog = false;
                show.purpose = false;
                show.select = false;
              "
            >
              キャンセル
            </base-button>
          </div>
          <div>
            <base-checkbox v-model="checked" :id="String(id)">
              <h4>次回から表示しない</h4>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";
import vClickOutside from "click-outside-vue3";
import FolderIcon from "@/components/icon/FolderIcon.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import { TaskType } from "@/enum/taskType";
import { CreateInfluencerListParams, Influencer, InfluencerList } from "@/types";

export default defineComponent({
  name: "AddListIcon",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 36,
    },
    height: {
      type: Number,
      default: 29,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "add-list-checkbox",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    influencerId: {
      type: String,
      default: "",
    },
    selectedFolder: {
      type: Number as () => null | number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    requireGetCompanyInfluencerList: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    FolderIcon,
    BaseButton,
    BaseCheckbox,
  },
  setup(props, context) {
    const store = useStore();
    if (props.requireGetCompanyInfluencerList) store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      show: {
        select: false,
        purpose: false,
        dialog: false,
        companyFolder: true,
        addFolder: false,
        childFolder: false,
        createFolder: false,
      },
      checked: false,
      folderChecked: {} as any,
      folder: null as null | TaskType,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers?.influencerList),
      createdInfluencerList: computed(() => store.state.influencers.createdInfluencerList),
      InfluencerChildList: computed(() => store.state.influencers?.childInfluencerList),
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      createFolderSuccess: computed(() => store.state.influencers.createInfluencerListSuccess),
      currentInfluencerList: null as null | InfluencerList,
      listNestRank: 0,
      newFolderName: null as null | string,
    });
    const clickIcon = () => {
      state.checked = false;
      if (state.currentInfluencerList && state.listNestRank > 0) {
        context.emit("update:selectedFolder", state.currentInfluencerList.id);
      } else {
        context.emit("update:selectedFolder", state.influencerList.id);
      }
      if (!state.user?.isReadAddList) {
        state.show.dialog = true;
        state.show.select = false;
        store.dispatch("users/loadCurrentUser");
      } else {
        context.emit("addList");
      }
    };
    const selectFolder = () => {
      store.dispatch("influencers/getCompaniesList");
      state.show.select = true;
      state.listNestRank = 0;
      state.currentInfluencerList = state.influencerList;
    };
    const handleCheck = () => {
      context.emit("update:modelValue", state.checked);
    };
    const handleAddList = () => {
      if (state.checked) {
        const params = { isReadAddList: state.checked };
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show.dialog = false;
      context.emit("addList");
    };
    const getSafeCount = (limitVal: number, countVal: number): string => {
      if (limitVal < 0) return "無制限";
      const val = limitVal - countVal;
      return val < 0 ? "0" : String(val);
    };
    const AddListIcon = ref<HTMLImageElement>();
    const onClickOutside = (event: any) => {
      if (event.target !== state.show.purpose) state.show.purpose = false;
    };
    const moveChildInfluencerList = (id: number) => {
      let nextInfluencerList = state.influencerList;
      (state.currentInfluencerList?.children || []).map((child: InfluencerList) => {
        if (child.id == id) {
          state.listNestRank += 1;
          nextInfluencerList = child;
        }
      });
      state.currentInfluencerList = nextInfluencerList;
    };
    const moveParentInfluencerList = () => {
      let tempList = state.influencerList;
      if ((state.currentInfluencerList?.familyTree || []).length <= 1) {
        state.listNestRank = 0;
        state.currentInfluencerList = tempList;
      } else {
        for (let i = 1; i + 1 < (state.currentInfluencerList?.familyTree || []).length; i++) {
          let tempChildList = tempList;
          (tempList?.children || []).map((child: InfluencerList) => {
            if (child.id === state.currentInfluencerList?.familyTree[i].id) {
              tempChildList = child;
            }
          });
          tempList = tempChildList;
        }
        state.listNestRank -= 1;
        state.currentInfluencerList = tempList;
      }
    };
    const moveNewInfluencerList = (name: string | null) => {
      if (state.currentInfluencerList) {
        state.currentInfluencerList.name = name;
        state.currentInfluencerList.influencers = [];
        state.currentInfluencerList.children = [];
        state.currentInfluencerList.familyTree.push({ id: 0, name: name });
        state.listNestRank += 1;
      }
    };
    const checkInfluencerExists = (currentInfluencerList: InfluencerList) => {
      return (currentInfluencerList.influencers || [])
        .slice()
        .map((x: Influencer) => x.id)
        .includes(props.influencerId);
    };
    const createFolder = () => {
      if (state.currentInfluencerList?.id && state.newFolderName) {
        const createFolderParams: CreateInfluencerListParams = {
          company: state.user.companyId,
          derivedFrom: state.currentInfluencerList.id,
          name: state.newFolderName,
        };
        store.dispatch("influencers/createInfluencerList", createFolderParams).then(() => {
          state.show.createFolder = false;
          store.dispatch("influencers/getCompaniesList");
          moveNewInfluencerList(state.newFolderName);
          state.newFolderName = null;
        });
      }
    };
    let checked = computed(() => state.checked);
    watch(checked, () => handleCheck());
    let createdInfluencerList = computed(() => state.createdInfluencerList);
    watch(createdInfluencerList, (val) => {
      if (state.currentInfluencerList && val.id) {
        state.currentInfluencerList.id = val.id;
      }
    });
    const closeFolder = () => {
      state.show.select = false;
      state.show.purpose = false;
      state.listNestRank = 0;
      state.currentInfluencerList = state.influencerList;
    };
    return {
      ...toRefs(state),
      clickIcon,
      selectFolder,
      handleCheck,
      handleAddList,
      onClickOutside,
      AddListIcon,
      moveChildInfluencerList,
      moveParentInfluencerList,
      checkInfluencerExists,
      createFolder,
      closeFolder,
      getSafeCount,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main.scss";
.open-list {
  position: relative;
  &__button {
    width: 110px;
    padding: 10px;
    border-radius: 30px 0 0 30px;
    cursor: pointer;
    background-color: map-get($colors, "success");
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: 700;
    @include mq(xs) {
      font-size: 12px;
    }
    @include mq(lg) {
      font-size: 14px;
    }
    &:hover {
      opacity: 0.8;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    &__small {
      width: 110px;
      padding: 5px;
      border-radius: 30px 0 0 30px;
      cursor: pointer;
      background-color: map-get($colors, "success");
      text-align: center;
      font-size: 10px;
      color: white;
      &:hover {
        opacity: 0.8;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }
  &__pull-down {
    position: relative;
    width: 29px;
    margin-left: 1px;
    padding: 10px;
    border-radius: 0 30px 30px 0;
    background-color: map-get($colors, "success");
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: 700;
    &:hover {
      opacity: 0.8;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    &__small {
      position: relative;
      width: 29px;
      margin-left: 1px;
      padding: 5px;
      border-radius: 0 30px 30px 0;
      background-color: map-get($colors, "success");
      cursor: pointer;
      text-align: center;
      font-size: 10px;
      color: white;
      &:hover {
        opacity: 0.8;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }
  & svg {
    &:hover {
      opacity: 0.5;
    }
  }
  &__purpose {
    position: absolute;
    top: 45px;
    z-index: 3;
    width: 176px;
    background-color: white;
    border: 2px solid map-get($colors, "success");
    border-radius: 10px;
    &__option {
      padding: 10px 10px;
      cursor: pointer;
      font-size: 14px;
      color: map-get($colors, "gray400");
      &:hover {
        background-color: map-get($colors, "gray100");
        -webkit-transition: all 0.1s ease;
        -moz-transition: all 0.1s ease;
        -o-transition: all 0.1s ease;
        transition: all 0.1s ease;
      }
      &:first-child {
        border-radius: 7px 7px 0 0;
      }
      &:last-child {
        border-radius: 0 0 7px 7px;
      }
    }
  }
  &__select-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 3;
      width: 620px;
      background-color: white;
      border-radius: 10px;
      transform: translate(-50%, 14%);
      -webkit-transform: translate(-50%, 14%);
      -ms-transform: translate(-50%, 14%);
      @include mq(xs) {
        width: 90vw;
        top: -80px;
      }
      @include mq(lg) {
        width: 620px;
        top: 0;
      }
      & .no-item-text {
        font-size: 24px;
        font-weight: 500;
        color: map-get($colors, "primary");
        width: 100%;
        height: 100%;
        text-align: center;
        display: grid;
        place-items: center;
        @include mq(xs) {
          font-size: 18px;
        }
        @include mq(lg) {
          font-size: 24px;
        }
      }
      &__head {
        padding: 17px 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: map-get($colors, "blue300");
        border-radius: 10px 10px 0 0;
        color: white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        @include mq(xs) {
          font-size: 14px;
          padding: 10px 20px;
        }
        @include mq(lg) {
          font-size: 18px;
          padding: 17px 34px;
        }
        &.create-folder {
          padding: 12px 70px 12px 34px !important;
          justify-items: flex-start;
          justify-content: space-around;
          @include mq(xs) {
            padding: 10px 40px 10px 20px !important;
          }
          @include mq(lg) {
            padding: 12px 70px 12px 34px !important;
          }
        }
        & .create-folder {
          &-cancel-btn {
            height: 36px;
            width: 36px;
            display: grid;
            place-items: center;
            text-align: center;
            @include mq(xs) {
              height: 20px;
              width: 20px;
            }
            @include mq(lg) {
              height: 36px;
              width: 36px;
            }
          }
          &-input {
            height: 36px;
            width: 364px;
            border-radius: 5px 0 0 5px;
            border: none;
            padding-left: 20px;
            box-sizing: border-box;
            @include mq(xs) {
              height: 36px;
              width: 200px;
            }
            @include mq(lg) {
              height: 36px;
              width: 364px;
            }
            &::placeholder {
              color: map-get($colors, "primary");
            }
            &:focus {
              outline: none;
            }
            &__wapper {
              display: flex;
              width: 100%;
              justify-content: center;
            }
            &__btn {
              height: 36px;
              width: 36px;
              display: grid;
              place-items: center;
              text-align: center;
              border-radius: 0 5px 5px 0;
              background-color: #198fd9;
              box-sizing: border-box;
            }
          }
        }
        & .batsu {
          display: block;
          position: relative;
          cursor: pointer;
          width: 19.8px;
          height: 19.8px;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 4px;
            height: 24px;
            background-color: white;
            transform: rotate(45deg);
            border-radius: 5px;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 4px;
            height: 24px;
            background-color: white;
            transform: rotate(-45deg);
            border-radius: 5px;
          }
        }
        & img {
          cursor: pointer;
        }
      }
      &__options {
        height: 480px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 8px;
          display: block !important;
        }
        &::-webkit-scrollbar-track {
          background-color: white;
        }
        &::-webkit-scrollbar-thumb {
          background-color: map-get($colors, "gray200");
          border-radius: 10px;
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
        }
      }
      &__option {
        padding: 20px 20px;
        background-color: white;
        font-size: 14px;
        font-weight: 500;
        color: map-get($colors, "gray400");
        cursor: pointer;
        align-items: center;
        &:hover {
          background-color: map-get($colors, "gray100");
          -webkit-transition: all 0.1s ease;
          -moz-transition: all 0.1s ease;
          -o-transition: all 0.1s ease;
          transition: all 0.1s ease;
        }
        &__open-folder {
          cursor: pointer;
        }
      }
      &__no-select {
        opacity: 0.5;
        padding: 20px 20px;
        background-color: white;
        font-size: 14px;
        font-weight: 500;
        color: map-get($colors, "gray400");
        cursor: auto;
        align-items: center;
        &__thumbnail {
          & img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        &__name {
          & h4 {
            font-weight: 700;
          }
          & h6 {
            color: map-get($colors, "gray200");
          }
        }
      }
      &__button {
        margin: 20px 30px;
        & .create-folder {
          cursor: pointer;
          &__text {
            @include mq(xs) {
              display: none;
            }
            @include mq(lg) {
              display: block;
            }
          }
          &:hover {
            opacity: 0.8;
            -webkit-transition: all 0.1s ease;
            -moz-transition: all 0.1s ease;
            -o-transition: all 0.1s ease;
            transition: all 0.1s ease;
          }
        }
      }
      & svg {
        width: 40px;
      }
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 720px;
      padding: 100px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 20%);
      -webkit-transform: translate(-50%, 20%);
      -ms-transform: translate(-50%, 20%);
      background-color: white;
      border-radius: 10px;
      text-align: center;
      @include mq(xs) {
        width: 80vw;
        padding: 20px;
        border: solid 2px map-get($colors, "blue400");
      }
      @include mq(lg) {
        width: 720px;
        padding: 100px;
        border: none;
      }
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        @include mq(xs) {
          font-size: 20px;
        }
        @include mq(lg) {
          font-size: 24px;
        }
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        @include mq(xs) {
          font-size: 14px;
        }
        @include mq(lg) {
          font-size: 18px;
        }
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "blue400");
          @include mq(xs) {
            font-size: 16px;
          }
          @include mq(lg) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
