<template>
  <div class="base-container__white">
    <div class="server-error">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" />
      </header>
      <div class="server-error__content">
        <div class="server-error__content__catch">
          <img src="@/assets/img/errorpage-image.svg" />
          <div class="server-error__content__catch__text">
            <div class="server-error__content__catch__text__error-code">500</div>
            <div class="server-error__content__catch__text__error-text">Server Error</div>
          </div>
        </div>
        <div class="server-error__content__text mt-40px">
          <h3>アクセスしようとしたページは<br />
            表示できませんでした。</h3>
        </div>
        <router-link :to="{name: 'Home'}">
          <base-button class="mt-80px">トップページに戻る</base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "NotFound",
  components: {BaseButton},
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.server-error {
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    text-align: center;
    margin-top: 10%;
    &__catch {
      width: fit-content;
      margin: 0 auto;
      position: relative;
      &__text {
        position: absolute;
        top: 50px;
        left: 180px;
        &__error-code {
          font-size: 64px;
          font-weight: 700;
          color: map-get($colors, 'blue400');
        }
        &__error-text {
          font-size: 18px;
          font-weight: 700;
          color: map-get($colors, 'blue400');
        }
      }
    }
  }
}
</style>
