<template>
  <div class="pagination">
    <div class="pagination__content">
      <div :class="[currentPage === 1 ? 'pagination__back__disable' : 'pagination__back']" @click="move(currentPage - 1)">◀</div>
      <div class="pagination__pages" v-if="totalPages <= 5">
        <a v-for="num in totalPages" :key="num" @click="move(num)">
          <div :class="[num === currentPage ? 'current' : 'link']">{{ num }}</div>
        </a>
      </div>
      <div class="pagination__pages" v-else-if="totalPages > 5 && currentPage < 4">
        <a :class="[currentPage === page ? 'current' : 'link']" v-for="page in firstPageArr" :key="page" @click="move(page)">
          {{ page }}
        </a>
      </div>
      <div class="pagination__pages" v-else-if="totalPages >= 5 && currentPage > 3 && currentPage < (totalPages - 2)">
        <a class="link" @click="move(1)">
          <div>1</div>
        </a>
        <div class="dots">...</div>
        <a class="link" @click="move(currentPage - 1)">
          <div>{{ currentPage - 1 }}</div>
        </a>
        <a class="current">
          <div>{{ currentPage }}</div>
        </a>
        <a class="link" @click="move(currentPage + 1)">
          <div>{{ currentPage + 1 }}</div>
        </a>
        <div class="dots">...</div>
        <a class="link" @click="move(totalPages)">
          <div>{{ totalPages }}</div>
        </a>
      </div>
      <div class="pagination__pages" v-else-if="totalPages >= 6 && currentPage > (totalPages - 3)">
        <a :class="[currentPage === totalPages - page ? 'current' : 'link']" v-for="page in lastPageArr" :key="page" @click="move(totalPages - page)">
          {{ totalPages - page }}
        </a>
      </div>
      <div class="pagination__next" :class="[currentPage > total / 15 ? 'pagination__next__disable' : 'pagination__next']" @click="move(currentPage + 1)">▶</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BasePagination",
  props: {
    total: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    const firstPageArr = Array.from(Array(5), (v,k) => k+1);
    const lastPageNum = Array.from(Array(5), (v,k) => k);
    const lastPageArr = lastPageNum.slice().reverse();
    const checkNextPage = (currentPage: number, total: number) => {
      return currentPage + 1 < Math.ceil(total / 15);
    };
    const move = (page: number) => {
      context.emit("move", page);
    };
    return {
      firstPageArr,
      lastPageArr,
      checkNextPage,
      move,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.pagination {
  margin: 60px 0;
  @include mq(xs) {
    margin: 10px 0;
  }
  @include mq(lg) {
    margin: 60px 0;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__pages {
    display: flex;
    & .link {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      height: 40px;
      margin: 0 10px;
      padding: 0 13px;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 700;
      color: map-get($colors, "gray200");
      line-height: 12px;
      transition: .3s;
      cursor: pointer;
      @include mq(xs) {
        font-size: 12px;
        margin: 0 4px;
        padding: 0 5px;
      }
      @include mq(lg) {
        font-size: 20px;
        margin: 0 10px;
        padding: 0 13px;
      }
      &:hover {
        color: white;
        background-color: map-get($colors, "blue300");
        transition: .3s;
      }
    }
    & .current {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: fit-content;
      height: 40px;
      margin: 0 10px;
      padding: 0 13px;
      font-size: 20px;
      font-weight: 700;
      color: map-get($colors, "blue400");
      line-height: 12px;
      pointer-events: none;
      @include mq(xs) {
        font-size: 14px;
        margin: 0 4px;
        padding: 0 5px;
      }
      @include mq(lg) {
        font-size: 20px;
        margin: 0 10px;
        padding: 0 13px;
      }
      &:after {
        position: absolute;
        content: "";
        width: 30px;
        height: 3px;
        border-radius: 3px;
        background-color: map-get($colors, "blue400");
        bottom: -1px;
        @include mq(xs) {
          width: 20px;
          height: 2px;
          bottom: 2px;
        }
        @include mq(lg) {
          width: 30px;
          height: 3px;
          bottom: -1px;
        }
      }
    }
    & .dots {
      margin-top: 2px;
      color: map-get($colors, "gray200");
    }
  }
  &__next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    background-color: map-get($colors, "blue300");
    border-radius: 4px;
    margin-left: 20px;
    font-size: 14px;
    transition: .3s;
    &__disable {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      border-radius: 4px;
      margin-left: 20px;
      font-size: 14px;
      background-color: map-get($colors, "gray100");
      pointer-events: none;
    }
    &:hover {
      opacity: .5;
      transition: .3s;
    }
  }
  &__back {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    background-color: map-get($colors, "blue300");
    border-radius: 4px;
    margin-right: 20px;
    font-size: 14px;
    transition: .3s;
    &__disable {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      border-radius: 4px;
      margin-right: 20px;
      font-size: 14px;
      background-color: map-get($colors, "gray100");
      pointer-events: none;
    }
    &:hover {
      opacity: .5;
      transition: .3s;
    }
  }
}
</style>