
import { defineComponent, reactive, toRefs, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import { CompanyCreateParams, UserUpdateParams } from "@/types";
import { CompanyType } from "@/enum/companyType";

export default defineComponent({
  name: "FirstSetting",
  components: { BaseSelectBox, CheckMark, BaseTextField, BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("companies/createCompanyInitialize", false);
    const state = reactive({
      show: {
        base: true,
        first: false,
        second: false,
        third: false,
      },
      companyName: null as string | null,
      department: null as string | null,
      companyType: null as number | null,
      username: null as string | null,
      imgUrl: null as string | null,
      imgUrlForCompany: null as string | null,
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      createCompanySuccess: computed(() => store.state.companies.createCompanySuccess),
      updateUserSuccess: computed(() => store.state.users.updateUserSuccess),
      user: computed(() => store.state.users.user),
      CompanyType,
    });
    if (state.user?.isSetCompany) router.push({ name: "Home" });
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const uploadThumbnailInputForCompany = ref<HTMLInputElement>();
    const handleFileForCompany = () => {
      state.imgUrlForCompany = URL.createObjectURL((uploadThumbnailInputForCompany.value?.files || [])[0]);
    };
    const submitCompanyData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.companyName) {
        state.formErrorMessage = "会社名は必須です";
      } else if (!state.companyType && state.companyType !== 0) {
        state.formErrorMessage = "業種は必須です";
      } else {
        const params: CompanyCreateParams = {
          name: state.companyName,
          department: state.department,
          type: state.companyType,
        };
        if ((uploadThumbnailInputForCompany?.value?.files || [])[0]) params.logo = (uploadThumbnailInputForCompany?.value?.files || [])[0];
        store.dispatch("companies/createCompany", params);
        if (!state.user) store.dispatch("users/loadCurrentUser");
      }
    };
    let createCompanySuccess = computed(() => state.createCompanySuccess);
    watch(createCompanySuccess, (val: boolean) => {
      if (val) state.show.second = true;
      state.show.first = false;
    });
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.username) {
        state.formErrorMessage = "名前は必須です";
      } else {
        const params: UserUpdateParams = {
          username: state.username,
        };
        if ((uploadThumbnailInput.value?.files || [])[0]) params.logo = (uploadThumbnailInput.value?.files || [])[0];
        store.dispatch("users/updateUser", { params: params, userId: state.user.id });
      }
    };
    let updateUserSuccess = computed(() => state.updateUserSuccess);
    watch(updateUserSuccess, (val: boolean) => {
      if (val) state.show.third = true;
      state.show.second = false;
    });
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      handleFile,
      uploadThumbnailInputForCompany,
      handleFileForCompany,
      submitCompanyData,
      submitUserData,
    };
  },
});
