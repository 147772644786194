import axios from "@/store/axios";
import { UserNotification } from "@/types";
import {Commit, Dispatch} from "vuex";

interface State {
  errorMessage: string | null;
  loading: boolean;
  notifications: UserNotification[];
  updateNotificationSuccess: boolean;
}

const state: State = {
  errorMessage: null,
  loading: false,
  notifications: [],
  updateNotificationSuccess: false,
};

const notifications = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setLoading(state: State, loading: boolean) {
      state.loading = loading;
    },
    setNotifications(state: State, notifications: UserNotification[]) {
      state.notifications = notifications;
    },
    setUpdateNotificationSuccess(state: State, flag: boolean) {
      state.updateNotificationSuccess = flag;
    },
  },
  actions: {
    async getNotifications({ commit }: { commit: Commit }) {
      commit("setLoading", true);
      commit("setErrorMessage", null);
      try {
        const res = await axios.get("api/v1/notifications/");
        commit("setNotifications", res.data);
        commit("setLoading", false);
      } catch (err) {
        commit("setLoading", false);
        err.response ? commit("setErrorMessage", err.response.data) : commit("setErrorMessage", err);
      }
    },
    async updateNotification({ commit }: { commit: Commit }, notificationId: number) {
      // まとめて登録する
      commit("setUpdateNotificationSuccess", false);
      commit("setErrorMessage", null);
      try {
        await axios.patch(`api/v1/notifications/${notificationId}/`, { isRead: true });
      } catch (err) {
        err.response ? commit("setErrorMessage", err.response.data) : commit("setErrorMessage", err);
      }
    },
    async updateNotifications({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, notificationIds: number[]) {
      // まとめて登録する
      commit("setUpdateNotificationSuccess", false);
      commit("setErrorMessage", null);
      new Promise((resolve) => {
        notificationIds.map((notificationId: number) => {
          dispatch("updateNotification", notificationId);
        });
        resolve(null);
      }).then(() => commit("setUpdateNotificationSuccess", true));
    },
  },
};

export default notifications;
