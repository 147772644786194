
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import QuestionIcon from "@/components/icon/QuestionIcon.vue";
import NotificationIcon from "@/components/icon/Notification.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import PlanBanner from "@/components/PlanBanner.vue";
import { useStore } from "vuex";
import { formatDate } from "@/plugins/formatter";
import vClickOutside from "click-outside-vue3";
import {HamburgerMenuItem, UserNotification} from "@/types";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BaseHeader",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { QuestionIcon, NotificationIcon, ProfileIcon, PlanBanner, HamburgerMenu },
  props: {
    headerTitle: {
      type: String,
      default: "インフルエンサーを探す",
    },
    displayType: {
      type: String,
      default: null,
    },
    enableBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("notifications/getNotifications");
    const state = reactive({
      show: {
        notification: false,
        profile: false,
        viewMore: true,
      },
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      notifications: computed(() => store.state.notifications.notifications),
      notificationLoading: computed(() => store.state.notifications.loading),
      updateNotificationSuccess: computed(() => store.state.notifications.updateNotificationSuccess),
      notificationViewables: 3,
    });
    const ViewMore = ref<HTMLImageElement>();
    const links: HamburgerMenuItem[] = [
      {
        name: "ホーム",
        link: { name: "Home" },
      },
      {
        name: "インフルエンサーを探す",
        link: { name: "SearchHome" },
      },
      {
        name: "名前・IDで検索",
        isSubItem: true,
        link: { name: "IDSearch" },
      },
      {
        name: "詳細検索",
        isSubItem: true,
        link: { name: "AdvancedSearch" },
      },
      {
        name: "ハッシュタグ検索",
        isSubItem: true,
        link: { name: "HashtagSearch" },
      },
      {
        name: "AI検索",
        isSubItem: true,
        link: { name: "AISearch" },
      },
      {
        name: "ビジネスクリエーション",
        link: { name: "Listing" },
      },
      {
        name: "インタビュー",
        isSubItem: true,
        link: { name: "Listing", query: { type: 0 } },
      },
      {
        name: "コラボレーション",
        isSubItem: true,
        link: { name: "Listing", query: { type: 1 } },
      },
      {
        name: "PR投稿",
        isSubItem: true,
        link: { name: "Listing", query: { type: 2 } },
      },
      {
        name: "その他のお仕事",
        isSubItem: true,
        link: { name: "Listing", query: { type: 3 } },
      },
      {
        name: "効果を予測する",
        link: { name: "AdPrediction" },
      },
      {
        name: "案件を管理する",
        link: { name: "TaskManage" },
      },
      {
        name: "インフルエンサーリスト",
        link: { name: "InfluencerList" },
      },
      {
        name: "よくある質問",
        link: { name: "Faq" },
      },
    ];
    const logOut = () => {
      store.dispatch("users/logOut");
    };
    const onClickProfileOutside = (event: any) => {
      if (event.target !== ProfileIcon.value) state.show.profile = false;
    };
    const onClickNotificationOutside = (event: any) => {
      if (event.target !== NotificationIcon.value && event.target !== ViewMore.value) {
        beforeCloseNotification();
        state.show.notification = false;
      }
    };
    const beforeCloseNotification = () => {
      if (state.show.notification) {
        // 閉じる前に更新
        const currNotifications = viewableNotifications();
        store.dispatch(
          "notifications/updateNotifications",
          currNotifications.map((x: UserNotification) => x.id)
        );
        store.dispatch("notifications/getNotifications");
        state.notificationViewables = 3;
        state.show.viewMore = true;
      }
    };
    const toggleNotification = () => {
      beforeCloseNotification();
      state.show.notification = !state.show.notification;
      store.dispatch("notifications/getNotifications");
    };
    const viewableNotifications = () => {
      return state.notifications.slice(0, state.notificationViewables);
    };
    const viewMore = () => {
      if (state.notifications.length >= state.notificationViewables + 3) {
        state.notificationViewables += 3;
      } else {
        state.notificationViewables = state.notifications.length;
        state.show.viewMore = false;
      }
    };
    const newCount = () => {
      if (!state.notifications) return 0;
      const newArr = state.notifications.filter((x: UserNotification) => !x.isRead);
      return newArr.length;
    };
    let updateNotificationSuccess = computed(() => state.updateNotificationSuccess);
    watch(updateNotificationSuccess, (val) => {
      if (val) store.dispatch("notifications/getNotifications");
    });
    const moveToPlan = () => {
      window.location.href = "/account-setting?type=plans";
    };
    const moveToEditProfile = () => {
      window.location.href = "/account-setting?type=general";
    };
    const moveToContact = () => {
      router.push({ name: "Contact" });
    };
    return {
      ...toRefs(state),
      links,
      logOut,
      onClickProfileOutside,
      onClickNotificationOutside,
      toggleNotification,
      viewableNotifications,
      formatDate,
      viewMore,
      ViewMore,
      newCount,
      moveToPlan,
      moveToEditProfile,
      moveToContact,
    };
  },
});
