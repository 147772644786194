
import { defineComponent, reactive, toRefs, computed, watch, ref, Ref, PropType } from "vue";
import vClickOutside from "click-outside-vue3";

export default defineComponent({
  name: "BaseSelectBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "white",
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#79b5db",
    },
    focusColor: {
      type: String,
      default: "#79b5db",
    },
    focusBorderWidth: {
      type: Number,
      default: 3,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableLookup: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: "#4c4c4c",
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 44,
    },
    id: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemColor: {
      type: String,
      default: "#4c4c4c",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    placeholder: {
      type: String,
      default: "選択してください",
    },
    searchBoxPlaceholder: {
      type: String,
      default: "検索",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 636,
    },
    modelValue: {
      type: Object as PropType<string | number | null>,
      default: null,
    },
  },
  emits: ["update:modelValue", "updateSelected"],
  setup(props, context) {
    // ===========
    // state
    // ===========
    const state = reactive({
      currentInputValue: props.modelValue ? (Array.isArray(props.modelValue) ? null : props.modelValue) : null,
      selectedItems: props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : [],
      isSearching: false,
      isFocused: false,
      filteredIndexArr: [...Array((props.items || []).length).keys()] as number[], //items の range
    });
    const styles = computed(() => {
      return {
        "--height": props.height,
        "--width": props.width,
        "--border-radius": props.rounded ? props.height / 2 : props.borderRadius, // roundedの時は強制で高さの半分にする
        "--border-width": props.borderWidth,
        "--color": props.color,
        "--focus-color": props.focusColor,
        "--focus-border-width": props.focusBorderWidth,
        "--font-color": props.fontColor,
        "--font-size": props.fontSize,
        "--item-color": props.itemColor,
        "--bg-color": props.bgColor,
      };
    });
    // ===========
    // functions
    // ===========
    const handleEvent = () => {
      let resValue: any | any[] | null;
      if (props.multiple) {
        resValue = state.selectedItems.length > 0 ? state.selectedItems : [];
        if (!props.returnObject) resValue = resValue.map((x: any) => x[props.itemValue]);
      } else {
        resValue = state.selectedItems.length > 0 ? state.selectedItems[0] : null;
        if (!props.returnObject) resValue = resValue[props.itemValue];
      }
      context.emit("update:modelValue", resValue);
      // update:modelValue で全部のイベントを handling
      context.emit("updateSelected");
    };
    const isInclude = (arr: any[], val: any) => {
      let tempArr = Array.isArray(arr) ? arr.slice() : Array(arr);
      let res = false;
      tempArr.map((x) => {
        if (x[props.itemValue] === val[props.itemValue]) res = true;
      });
      return res;
    };
    const setSelectedItemsFromFilter = (val: any) => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (props.multiple) {
        // 通常は重複を許容しない
        if (props.allowDistinct || !isInclude(state.selectedItems, val)) {
          state.selectedItems.push(val);
        } else {
          // 重複を許可しないのであれば、削除を行う
          state.selectedItems = state.selectedItems.filter((x: any) => x[props.itemText] !== val[props.itemText]);
        }
        state.currentInputValue = null;
      } else {
        if (isInclude(state.selectedItems, val) && props.allowNull) {
          state.selectedItems = [];
        } else {
          state.selectedItems = [val];
        }
        state.isFocused = false;
        state.isSearching = false;
      }
      handleEvent();
    };
    const deleteLastSelectedItem = () => {
      // delete キーは通常の文字削除にも判定してしまうため、input 内に文字がないときにのみ selectedItems の削除を行うようにする
      if (state.currentInputValue === "" || state.currentInputValue === null) {
        state.selectedItems.pop();
      }
      handleEvent();
    };
    const deleteSelectedItem = (idx: number) => {
      state.selectedItems.splice(idx, 1);
      handleEvent();
    };
    const indexOfAll = (arr: string[], val: string): number[] => {
      const resArr: number[] = [];
      arr.map((arrVal: string, i: number) => {
        if (arrVal.indexOf(val) > -1) resArr.push(i);
      });
      return resArr;
    };
    const handleClickOutside = (event: any) => {
      // セレクトボックスの検索欄をクリックしている時は非表示にしない
      if (!(event.target === searchBox.value || event.target === searchBoxContainer.value)) {
        if (selectItems.includes(event.target)) {
          event.preventDefault();
        } else {
          state.isFocused = false;
          state.isSearching = false;
        }
      }
    };
    // ===================
    // watch
    // ===================
    // inputに入力している時は検索アイテムを表示する
    let inputVal = computed(() => state.currentInputValue);
    watch(inputVal, (inputVal) => {
      state.isSearching = inputVal !== null && inputVal !== "";
      if (inputVal !== null && inputVal !== "") {
        state.filteredIndexArr = indexOfAll(
          props.items?.map((val: any) => val[props.itemText]),
          String(inputVal)
        );
      } else {
        // 初期値に戻す
        state.filteredIndexArr = [...Array((props.items || []).length).keys()];
      }
    });
    // ===================
    // ref
    // ===================
    const searchBoxContainer = ref<HTMLElement>();
    const searchBox = ref<HTMLImageElement>();
    const selectItems = [] as Ref<HTMLElement>[];
    const selectItem = (el: Ref<HTMLElement>) => {
      if (el) selectItems.push(el);
    };
    return {
      ...toRefs(state),
      styles,
      isInclude,
      setSelectedItemsFromFilter,
      deleteLastSelectedItem,
      deleteSelectedItem,
      handleEvent,
      handleClickOutside,
      searchBox,
      searchBoxContainer,
      selectItem,
    };
  },
});
