<template>
  <div v-if="company?.isExpired === false">
    <div class="base-container" v-show="show.first">
      <sidebar :current-tab="2"></sidebar>
      <div class="main" v-show="company">
        <base-header header-title="ビジネスクリエーション案件の作成" display-type="LISTING" :key="createTaskSuccess"></base-header>
        <div class="dialog__container" v-if="(createTaskSuccess || errorMessage) && show.createTask">
          <div class="dialog" v-if="createTaskSuccess">
            <div class="title">ビジネスクリエーション案件の<br />作成が完了しました</div>
            <div class="description"><check-mark></check-mark></div>
            <div class="description"><base-button size="short" color="dark" @click="createTaskDone"> OK </base-button></div>
          </div>
          <div class="dialog" v-else>
            <div class="title error">ビジネスクリエーション案件の<br />作成に失敗しました</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>{{ errorMessage?.detail?.maxFollowers }} || {{ errorMessage?.detail?.minFollowers }} || {{ errorMessage?.detail }}<br />お手数ですが、最初からやり直してください。</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="show.createTask = false"> 戻る </base-button></div>
          </div>
        </div>
        <div class="dialog__container" v-else-if="formErrorMessage && show.errorDialog">
          <div class="dialog">
            <div class="title error">入力エラー</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>{{ formErrorMessage }}</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
          </div>
        </div>
        <div class="listing">
          <div class="listing__first__contents">
            <div class="listing__first__contents__description">
              <h2>どのような目的でインフルエンサーとお仕事しますか？</h2>
            </div>
            <div class="listing__first__contents__container">
              <div class="cards">
                <div class="card interview" @click="selectPurpose(0)">
                  <div class="d-flex card__contents">
                    <div>
                      <h3>インフルエンサーにインタビューする</h3>
                      <p>
                        消費者インタビューやアンケート調査の実施に向け、<br />
                        ご希望の条件に合わせたインフルエンサーを<br />
                        ご紹介致します。
                      </p>
                    </div>
                    <img src="@/assets/img/interview_influencer_image.png" alt="インタビュー" />
                  </div>
                </div>
                <div class="card collaboration" @click="selectPurpose(1)">
                  <div class="d-flex card__contents">
                    <div>
                      <h4>インフルエンサーとコラボレーションする</h4>
                      <p>
                        新規事業開発など、中長期的なPJTの<br />
                        コラボレーションを目的とした<br />
                        インフルエンサーをご紹介致します。
                      </p>
                    </div>
                    <img src="@/assets/img/collaborate_influencer_image.png" alt="コラボレーション" />
                  </div>
                </div>
                <div class="card pr" @click="selectPurpose(2)">
                  <div class="d-flex card__contents">
                    <div>
                      <h3>インフルエンサーにPRしてもらう</h3>
                      <p>
                        御社の商品・サービスの広告宣伝を行う<br />
                        インフルエンサーをご紹介致します。
                      </p>
                    </div>
                    <img src="@/assets/img/pr_influencer_image.png" alt="PR" />
                  </div>
                </div>
                <div class="card others" @click="selectPurpose(3)">
                  <h3>その他のお仕事</h3>
                  <p>
                    座談会やグループインタビュー、ミステリーショッピングなど<br />
                    「インタビュー」・「コラボレーション」・「PR投稿」以外の<br />
                    お仕事はこちらから依頼できます。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container" v-show="show.second">
      <sidebar :current-tab="2"></sidebar>
      <div class="main" v-show="company">
        <base-header header-title="ビジネスクリエーション案件の作成" display-type="LISTING" :key="createTaskSuccess"></base-header>
        <div class="listing" v-if="taskType?.key === 0">
          <div class="listing__first__contents">
            <div class="listing__first__contents__section">
              <div class="listing__first__contents__section__title">
                <h3>インタビューのメリット</h3>
              </div>
              <div class="listing__first__contents__section__merit mt-20px">
                <h4>インタビューでは、定量調査だけでは捉えることができない、インフルエンサーと消費者の意識や行動、実態といった顧客の情報を取得する事ができます。</h4>
              </div>
            </div>
            <div class="listing__first__contents__section mt-40px">
              <div class="listing__first__contents__section__title">
                <h3>インタビューの流れ</h3>
              </div>
              <div class="listing__first__contents__section__flow justify-content-space-between mt-20px">
                <flow-card>
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>案件内容を入力</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>インタビューの詳細や、希望するインフルエンサーの条件などを入力してください。</h5>
                      <h5 class="mt-10px">条件の記載に不明な点があれば、必須事項のみの記入で問題ございません。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="2">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        INFRECTがインタビューに<br />
                        最適な候補者をリストアップ
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>インタビューの実施に向け弊社と一度オリエンテーションを実施させて頂き、目的や詳細をヒアリングさせて頂きます。</h5>
                      <h5 class="mt-10px">お客様から頂いた情報をもとに、候補者のリストアップを致します。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="3">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        インタビュー交渉
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>候補者の中からインタビューを実施したいインフルエンサーを選択していただければ弊社が交渉、日程調整などを行います。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="4">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>インタビューの実施</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>日程調整した日時になりましたら、インフルエンサーへ指定した方法にてインタビューを実施してください。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="5">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        報酬をお振り込み
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>INFRECTの毎月のご請求に加算させていただき、弊社がインフルエンサーに報酬をお支払い致します。</h5>
                    </div>
                  </template>
                </flow-card>
              </div>
            </div>
            <div class="listing__first__contents__section__footer">
              <h3 class="back" @click="back2First()">◀︎戻る</h3>
              <div class="listing__first__contents__section">
                <div class="listing__first__contents__section__request">
                  <div class="listing__first__contents__section__request__description ml-20px">
                    インタビューの交渉とインフルエンサーへの報酬の振り込みをINFRECTが支援致します。<br />
                    ※インフルエンサーへお支払いする報酬金額は応相談。
                  </div>
                </div>
              </div>
              <h3
                class="next"
                @click="
                  show.second = false;
                  show.third = true;
                "
              >
                次へ▶
              </h3>
            </div>
          </div>
        </div>
        <div class="listing" v-if="taskType?.key === 1">
          <div class="listing__first__contents">
            <div class="listing__first__contents__section">
              <div class="listing__first__contents__section__title">
                <h3>コラボレーションのメリット</h3>
              </div>
              <div class="listing__first__contents__section__merit mt-20px">
                <h4>インフルエンサーとコラボレーションする事で、顧客の意見や思いを反映した商品・サービスの開発ができ、コラボレーションするインフルエンサーのファンも取り込む事が出来ます。</h4>
              </div>
            </div>
            <div class="listing__first__contents__section mt-40px">
              <div class="listing__first__contents__section__title">
                <h3>コラボレーションの流れ</h3>
              </div>
              <div class="listing__first__contents__section__flow justify-content-space-between mt-20px">
                <flow-card>
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>案件内容を入力</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>コラボレーションの詳細や、希望するインフルエンサーの条件などを入力してください。</h5>
                      <h5 class="mt-10px">条件の記載に不明な点があれば、必須事項のみの記入で問題ございません。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="2">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        INFRECTがコラボレーションに<br />
                        最適な候補者をリストアップ
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>コラボレーションの実施に向け弊社と一度オリエンテーションを実施させて頂き、目的や詳細をヒアリングさせて頂きます。</h5>
                      <h5 class="mt-10px">お客様から頂いた情報をもとに、候補者のリストアップを致します。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="3">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへ<br />
                        コラボレーションを交渉
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>候補者の中からコラボレーションを実施したいインフルエンサーを選択していただければ弊社が交渉、ご契約サポートなどを行います。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="4">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>コラボレーションの実施</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>インフルエンサーとのご契約内容に応じて、ご契約の期間・日程にてインフルエンサーとコラボレーションを実施してください。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="5">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        報酬をお振り込み
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>INFRECTの毎月のご請求に加算させていただき、弊社がインフルエンサーに報酬をお支払い致します。</h5>
                    </div>
                  </template>
                </flow-card>
              </div>
            </div>
            <div class="listing__first__contents__section__footer">
              <h3 class="back" @click="back2First()">◀︎戻る</h3>
              <div class="listing__first__contents__section">
                <div class="listing__first__contents__section__request">
                  <div class="listing__first__contents__section__request__description ml-20px">
                    コラボレーションの交渉・契約とインフルエンサーへの報酬の振り込みをINFRECTが支援致します。<br />
                    ※インフルエンサーへお支払いする報酬金額は応相談。
                  </div>
                </div>
              </div>
              <h3
                class="next"
                @click="
                  show.second = false;
                  show.third = true;
                "
              >
                次へ▶
              </h3>
            </div>
          </div>
        </div>
        <div class="listing" v-if="taskType?.key === 2">
          <div class="listing__first__contents">
            <div class="listing__first__contents__section">
              <div class="listing__first__contents__section__title">
                <h3>PR投稿してもらうメリット</h3>
              </div>
              <div class="listing__first__contents__section__merit mt-20px">
                <h4>
                  インフルエンサーにPR投稿していただくことで、興味のあるターゲットユーザーへダイレクトに商品・サービスをアピールする事が出来ます。<br />
                  お客様の商品・サービスに共感する顧客の購買率向上が見込めます。
                </h4>
              </div>
            </div>
            <div class="listing__first__contents__section mt-40px">
              <div class="listing__first__contents__section__title">
                <h3>PR投稿の流れ</h3>
              </div>
              <div class="listing__first__contents__section__flow justify-content-space-between mt-20px">
                <flow-card>
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>案件内容を入力</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>PRの詳細や、希望するインフルエンサーの条件などを入力してください。</h5>
                      <h5 class="mt-10px">条件の記載に不明な点があれば、必須事項のみの記入で問題ございません。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="2">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        INFRECTがPRに<br />
                        最適な候補者をリストアップ
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>PRの実施に向け弊社と一度オリエンテーションを実施させて頂き、目的や詳細をヒアリングさせて頂きます。</h5>
                      <h5 class="mt-10px">お客様から頂いた情報をもとに、候補者のリストアップを致します。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="3">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーに<br />
                        PRを交渉
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>候補者の中からPR投稿をお願いしたいインフルエンサーを選択していただければ弊社が交渉、投稿日程調整などを行います。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="4">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>PR投稿を実施してもらう</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>インフルエンサーに御社の商品・サービスのPRをしていただきます。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="5">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        報酬をお振り込み
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>INFRECTの毎月のご請求に加算させていただき、弊社がインフルエンサーに報酬をお支払い致します。</h5>
                    </div>
                  </template>
                </flow-card>
              </div>
            </div>
            <div class="listing__first__contents__section__footer">
              <h3 class="back" @click="back2First()">◀︎戻る</h3>
              <div class="listing__first__contents__section">
                <div class="listing__first__contents__section__request">
                  <div class="listing__first__contents__section__request__description ml-20px">
                    PRの交渉とインフルエンサーへの報酬の振り込みをINFRECTが支援致します。<br />
                    ※インフルエンサーへお支払いする報酬金額は応相談。
                  </div>
                </div>
              </div>
              <h3
                class="next"
                @click="
                  show.second = false;
                  show.third = true;
                "
              >
                次へ▶
              </h3>
            </div>
          </div>
        </div>
        <div class="listing" v-if="taskType?.key === 3">
          <div class="listing__first__contents">
            <div class="listing__first__contents__section">
              <div class="listing__first__contents__section__title">
                <h3>インフルエンサーとのお仕事の流れ</h3>
              </div>
              <div class="listing__first__contents__section__flow justify-content-space-between mt-20px">
                <flow-card>
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>案件内容を入力</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>お仕事の詳細や、希望するインフルエンサーの条件などを入力してください。</h5>
                      <h5 class="mt-10px">条件の記載に不明な点があれば、必須事項のみの記入で問題ございません。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="2">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        INFRECTがお仕事に<br />
                        最適な候補者をリストアップ
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>お仕事の実施に向け弊社と一度オリエンテーションを実施させて頂き、目的や詳細をヒアリングさせて頂きます。</h5>
                      <h5 class="mt-10px">お客様から頂いた情報をもとに、候補者をリストアップを致します。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="3">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        お仕事の交渉
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>候補者からお仕事を実施したいインフルエンサーを選択していただければ弊社が交渉、日程調整などを行います。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="4">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h4>お仕事の実施</h4>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>インフルエンサーとのお仕事を実施してください。</h5>
                    </div>
                  </template>
                </flow-card>
                <div class="listing__first__contents__section__flow__arrow">▶︎</div>
                <flow-card :flow-number="5">
                  <template v-slot:title>
                    <div class="listing__first__contents__section__flow__title">
                      <h5>
                        インフルエンサーへの<br />
                        報酬をお振り込み
                      </h5>
                    </div>
                  </template>
                  <template v-slot:description>
                    <div class="listing__first__contents__section__flow__description">
                      <h5>INFRECTの毎月のご請求に加算させていただき、弊社がインフルエンサーに報酬をお支払い致します。</h5>
                    </div>
                  </template>
                </flow-card>
              </div>
            </div>
            <div class="listing__first__contents__section__footer">
              <h3 class="back" @click="back2First()">◀︎戻る</h3>
              <div class="listing__first__contents__section">
                <div class="listing__first__contents__section__request">
                  <div class="listing__first__contents__section__request__description ml-20px">
                    お仕事の交渉とインフルエンサーへの報酬の振り込みをINFRECTが支援致します。<br />
                    ※インフルエンサーへお支払いする報酬金額は応相談。
                  </div>
                </div>
              </div>
              <h3
                class="next"
                @click="
                  show.second = false;
                  show.third = true;
                "
              >
                次へ▶
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container__white" v-show="!show.first && !show.second">
      <sidebar :current-tab="2"></sidebar>
      <div class="main" v-show="company">
        <base-header header-title="ビジネスクリエーション案件の作成" display-type="LISTING" :key="createTaskSuccess" enable-border></base-header>
        <div class="dialog__container" v-if="(createTaskSuccess || errorMessage) && show.createTask">
          <div class="dialog" v-if="createTaskSuccess">
            <div class="title">ビジネスクリエーション案件の<br />作成が完了しました</div>
            <div class="description"><check-mark></check-mark></div>
            <div class="description"><base-button size="short" color="dark" @click="createTaskDone"> OK </base-button></div>
          </div>
          <div class="dialog" v-else>
            <div class="title error">ビジネスクリエーション案件の<br />作成に失敗しました</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="show.createTask = false"> 戻る </base-button></div>
          </div>
        </div>
        <div class="dialog__container" v-else-if="formErrorMessage && show.errorDialog">
          <div class="dialog">
            <div class="title error">入力エラー</div>
            <div class="description"><exclamation-mark></exclamation-mark></div>
            <div class="description">
              <h4>{{ formErrorMessage }}</h4>
            </div>
            <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
          </div>
        </div>
        <div class="listing">
          <div v-show="show.third">
            <div class="listing__contents" v-if="taskType?.key === 0">
              <div class="listing__contents__description">
                <h2>どのような内容の<br />インタビューですか？</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-text-field label="案件名" required :width="640" :sm-width="240" v-model="taskTitle" :placeholder="'例： 女性目線でコンビニで買いたいスイーツに関するインタビュー調査'"> </base-text-field>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area :width="640" :height="60" :sm-width="240" label="依頼背景" required v-model="taskBackground" :placeholder="'例： コンビニで販売を行う新作スイーツの開発を担当しており、コンビニでスイーツを買う女性にざっくばらんな意見を伺いつつ、実際に開発中の新作スイーツを食べていただき、意見を頂きたい'"> </base-text-area>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area
                    :width="680"
                    :sm-width="240"
                    :height="120"
                    label="案件詳細"
                    required
                    v-model="taskDetail"
                    :placeholder="'例： 都心のコンビニで発売予定の新作スイーツであり、ターゲットは20歳~34歳程度の女性を想定中。お仕事で疲れた女性が自宅でちょっとした幸せを感じられる様なスイーツの開発を目指している為、実際にテイスティングして頂き、風味や見た目、パッケージデザイン、価格帯といった項目について意見を伺う予定。探して頂きたいインフルエンサーはInstagramを中心に活躍しており、20代‐30代の女性をフォロワーに持つ方。スイーツ関連のトレンドやスイーツが美味しいお店等を投稿・把握しており、食品関連業界だけではなくアパレル業界や美容業界で働く方など多岐の業界にわたる女性の方々をリストアップして頂きたい'"
                  >
                  </base-text-area>
                </div>
                <div class="d-flex justify-content-left">
                  <h3
                    class="back"
                    @click="
                      show.third = false;
                      show.second = true;
                    "
                  >
                    ◀︎戻る
                  </h3>
                </div>
                <div class="d-flex justify-content-right">
                  <h3 class="next" @click="validateThird()">次へ▶</h3>
                </div>
              </div>
            </div>
            <div class="listing__contents" v-else-if="taskType?.key === 1">
              <div class="listing__contents__description">
                <h2>どのような内容で<br />コラボレーション<br />しますか？</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-text-field label="案件名" required :width="640" :sm-width="240" v-model="taskTitle" :placeholder="'例：ランニングに特化したスニーカーの商品開発・デザイン'"> </base-text-field>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area
                    :width="640"
                    :sm-width="240"
                    :height="60"
                    label="依頼背景"
                    required
                    v-model="taskBackground"
                    :placeholder="'例：弊社はスポーツ用品からファッションアイテムを提供しているが、昨今のランニングブームに興隆に伴い、ランニングに特化したスニーカーの開発を考案中。これに際して、ランニングに知見があり、ご自身でもランニングを頻繁に嗜まれているインフルエンサーの方に意見を聞きながら、開発を成功させたい'"
                  >
                  </base-text-area>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area
                    :width="640"
                    :sm-width="240"
                    :height="120"
                    label="案件詳細"
                    required
                    v-model="taskDetail"
                    :placeholder="
                      '例： ターゲット層は20代~30代の女性を想定中。20代~30代のキャンプ初心者の女性が利用しても扱いやすく、尚且つ対象ターゲット層に受けがいい商品デザインを行ってきたい。' +
                      'その為に、20代の女性でスポーツの経験があり、ランニングやトレーニングに関する情報を自ら発信しているオペニオンリーダー的存在のインフルエンサーにPJT参画して頂き、ともに商品開発・広告宣伝を行っていきたい'
                    "
                  >
                  </base-text-area>
                </div>
                <div class="d-flex justify-content-left">
                  <h3
                    class="back"
                    @click="
                      show.third = false;
                      show.second = true;
                    "
                  >
                    ◀︎戻る
                  </h3>
                </div>
                <div class="d-flex justify-content-right">
                  <h3 class="next" @click="validateThird()">次へ▶</h3>
                </div>
              </div>
            </div>
            <div class="listing__contents" v-else-if="taskType?.key === 2">
              <div class="listing__contents__description">
                <h2>どのような内容の<br />PRですか？</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-text-field label="案件名" required :width="640" :sm-width="240" v-model="taskTitle" :placeholder="'例：ソロキャンプブランド立ち上げに伴うPR広告'"> </base-text-field>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area :width="640" :sm-width="240" :height="60" label="依頼背景" required v-model="taskBackground" :placeholder="'例：ソロキャンプブランドの立ち上げを2か月後に予定しており、ローンチと同時に大々的にSNSを使ったマーケティングを行いたい'"> </base-text-area>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area :width="640" :sm-width="240" :height="120" label="案件詳細" required v-model="taskDetail" :placeholder="'例：若年女性向けのソロキャンプブランドの立ち上げをこの度2か月後に控えており、20代の女性でキャンプやアウトドアを中心とした情報発信を行っている方にPR広告をお願いしたい'"> </base-text-area>
                </div>
                <div class="d-flex justify-content-left">
                  <h3
                    class="back"
                    @click="
                      show.third = false;
                      show.second = true;
                    "
                  >
                    ◀︎戻る
                  </h3>
                </div>
                <div class="d-flex justify-content-right">
                  <h3 class="next" @click="validateThird()">次へ▶</h3>
                </div>
              </div>
            </div>
            <div class="listing__contents" v-else-if="taskType?.key === 3">
              <div class="listing__contents__description">
                <h2>どのような内容の<br />案件ですか？</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-text-field label="案件名" required :width="640" :sm-width="240" v-model="taskTitle" placeholder="案件タイトルを入力してください"> </base-text-field>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area :width="640" :sm-width="240" :height="60" label="依頼背景" required v-model="taskBackground" placeholder="経緯など依頼背景を入力してください"> </base-text-area>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-area :width="640" :sm-width="240" :height="120" label="案件詳細" required v-model="taskDetail" placeholder="案件内容を詳細にご記入ください"> </base-text-area>
                </div>
                <div class="d-flex justify-content-left">
                  <h3
                    class="back"
                    @click="
                      show.third = false;
                      show.second = true;
                    "
                  >
                    ◀︎戻る
                  </h3>
                </div>
                <div class="d-flex justify-content-right">
                  <h3 class="next" @click="validateThird()">次へ▶</h3>
                </div>
              </div>
            </div>
          </div>
          <div v-show="show.fourth">
            <div class="listing__contents">
              <div class="listing__contents__description">
                <h2>案件に活用したい<br />インフルエンサーの<br />情報を入力してください</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-select-box label="SNSの種類" class="mr-40px" :width="236" item-value="key" item-text="text" :items="SNSType.getAll()" v-model="snsType" return-object required disable-lookup></base-select-box>
                  <base-text-field label="リストアップ希望人数（名）" type="number" class="mr-40px" :width="136" :sm-width="200" v-model="listingNum" :placeholder="'例： 20'" required></base-text-field>
                </div>
                <div class="d-flex mb-20px">
                  <base-select-box label="性別" class="mr-40px" :width="176" v-model="genderType" item-value="key" item-text="text" :items="GenderType.getAll()" disable-lookup return-object allow-null></base-select-box>
                  <base-select-box label="年齢層" class="mr-40px" :width="296" item-value="key" item-text="text" :items="AgeGroup.getAll()" v-model="ages" multiple chips deletable-chips return-object disable-lookup></base-select-box>
                </div>
                <div class="d-flex mb-20px">
                  <base-text-field label="最小フォロワー数" class="mr-40px" :width="130" v-model="minFollower" ref="minFollowerInput"></base-text-field>
                  <base-text-field label="最大フォロワー数" :width="130" v-model="maxFollower"></base-text-field>
                </div>
                <div class="mb-20px">
                  <base-text-field label="インフルエンサーの属性" :width="480" :sm-width="240" v-model="attributes" :placeholder="'例： 都心部在住、働く女性、一人暮らし、スイーツ好き'"> </base-text-field>
                </div>
                <div class="d-flex justify-content-right">
                  <div
                    @click="
                      show.fourth = false;
                      show.third = true;
                    "
                    class="mr-20px"
                  >
                    <h3 class="back">◀︎戻る</h3>
                  </div>
                  <div @click="validateFourth()"><h3 class="next">次へ▶</h3></div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="show.fifth">
            <div class="listing__contents">
              <div class="listing__contents__description">
                <h2>案件に活用したい<br />インフルエンサーの<br />フォロワーの情報を<br />入力してください</h2>
              </div>
              <div class="listing__contents__container">
                <div class="d-flex mb-20px">
                  <base-select-box label="性別" class="mr-40px" :width="176" item-value="key" item-text="text" :items="GenderType.getAll()" v-model="followerGenderType" disable-lookup return-object allow-null></base-select-box>
                  <base-select-box label="年齢層" class="mr-40px" :width="296" item-value="key" item-text="text" :items="AgeGroup.getAll()" v-model="followerAges" multiple chips deletable-chips return-object disable-lookup></base-select-box>
                </div>
                <div class="mb-20px">
                  <base-text-field label="フォロワーの属性" :width="480" :sm-width="240" v-model="followerAttributes" :placeholder="'例： 都心部在住、働く女性、一人暮らし、スイーツ好き'"> </base-text-field>
                </div>
                <div class="d-flex justify-content-center">
                  <base-button size="large_wide" color="accent" class="mt-40px" @click="openListDialog">INFRECTにお仕事の仲介を依頼する</base-button>
                </div>
                <h4 class="listing__comment d-flex justify-content-center mt-20px">※インフルエンサーのリストアップには数営業日頂きます</h4>
                <div
                  @click="
                    show.fifth = false;
                    show.fourth = true;
                  "
                  class="d-flex justify-content-right margin-left-auto w-fit-content"
                >
                  <h3 class="back">◀︎戻る</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="listing__dialog" v-show="show.dialog">
      <div class="listing__dialog__container">
        <div class="listing__dialog__content">
          <div class="listing__dialog__content__title mb-40px">インフルエンサーのご紹介に関するご確認事項</div>
          <div class="listing__dialog__content__text mb-40px">
            本サービスを利用してご紹介したインフルエンサーは<br />
            お仕事の受託を確約するものではございません。
          </div>
          <div class="listing-error mt-20px" v-show="formErrorMessage">{{ formErrorMessage }}</div>
          <div class="mb-20px">
            <base-button color="blue" size="wide" @click="createTask"> 同意して、お仕事の仲介を依頼する </base-button>
          </div>
          <div class="mb-20px">
            <base-button color="dark" @click="show.dialog = false"> キャンセル </base-button>
          </div>
          <div>
            <base-checkbox v-model="checked">
              <h4>次回から表示しない</h4>
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="base-container">
      <sidebar :current-tab="2"></sidebar>
      <div class="main" v-show="company">
        <base-header header-title="ビジネスクリエーション案件の作成" display-type="LISTING" :key="createTaskSuccess"></base-header>
        <div class="expired">
          <div class="expired__title">トライアルプランの有効期限が切れています。</div>
          <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
          <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
            <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { GenderType } from "@/enum/genderType";
import { SNSType } from "@/enum/snsType";
import { TaskType } from "@/enum/taskType";
import { AgeGroup } from "@/enum/ageGroup";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import { useStore } from "vuex";
import { CreateTaskParams } from "@/types";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";
import FlowCard from "@/components/FlowCard.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";

export default defineComponent({
  name: "Listing",
  components: {
    BaseSelectBox,
    FlowCard,
    BaseTextArea,
    BaseTextField,
    BaseHeader,
    Sidebar,
    BaseButton,
    BaseCheckbox,
    ExclamationMark,
    CheckMark,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    let taskType = route.query.type ? TaskType.getObject(Number(route.query.type)) : null;
    type ItemType = {
      key: string;
      text: string;
    };
    type ItemNumberType = {
      key: number;
      text: string;
    };
    const state = reactive({
      show: {
        first: true,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        dialog: false,
        createTask: false,
        errorDialog: false,
      },
      company: computed(() => store.state.companies.company),
      user: computed(() => store.state.users.user),
      errorMessage: computed(() => store.state.tasks.errorMessage),
      formErrorMessage: null as string | null,
      createTaskSuccess: computed(() => store.state.tasks.createTaskSuccess),
      checked: false,
      hashtags: [] as string[],
      attributes: null as null | string,
      taskType: null as null | TaskType,
      snsType: null as null | ItemNumberType,
      genderType: null as null | ItemNumberType,
      listingNum: null as null | number,
      ages: [] as ItemType[],
      minFollower: null as number | null,
      maxFollower: null as number | null,
      followerGenderType: null as null | ItemNumberType,
      followerAges: [] as ItemType[],
      followerAttributes: null as null | string,
      followerHashtags: [] as string[],
      taskTitle: null as null | string,
      taskBackground: null as null | string,
      taskDetail: null as null | string,
    });
    state.taskType = taskType;
    if (route.query.type) {
      state.show.first = false;
      state.show.second = true;
      state.show.third = false;
      state.show.fourth = false;
      state.show.fifth = false;
    } else {
      state.show.first = true;
      state.show.second = false;
      state.show.third = false;
      state.show.fourth = false;
      state.show.fifth = false;
    }
    const selectPurpose = (taskType: number) => {
      state.taskType = TaskType.getObject(taskType);
      router.push({ name: "Listing", query: { type: taskType } });
    };
    const back2First = () => {
      router.push({ name: "Listing" });
    };
    const validateThird = () => {
      state.formErrorMessage = null;
      if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else {
        state.show.third = false;
        state.show.fourth = true;
      }
    };
    const validateFourth = () => {
      state.formErrorMessage = null;
      if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else if (state.minFollower && (state.minFollower < 0 || !Number.isInteger(Number(state.minFollower)))) {
        state.formErrorMessage = "最小フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && (state.maxFollower < 0 || !Number.isInteger(Number(state.maxFollower)))) {
        state.formErrorMessage = "最大フォロワー数は0以上の整数である必要があります";
        state.show.errorDialog = true;
      } else if (state.maxFollower && state.minFollower && Number(state?.minFollower) > Number(state?.maxFollower)) {
        state.formErrorMessage = "最大フォロワー数は最小フォロワー数より大きい数である必要があります";
        state.show.errorDialog = true;
      } else {
        state.show.fourth = false;
        state.show.fifth = true;
      }
    };
    const createTask = () => {
      state.formErrorMessage = null;
      if (!state.taskType?.key && state.taskType?.key !== 0) {
        state.formErrorMessage = "案件の種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskTitle) {
        state.formErrorMessage = "案件名は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskBackground) {
        state.formErrorMessage = "依頼背景は必須です";
        state.show.errorDialog = true;
      } else if (!state.taskDetail) {
        state.formErrorMessage = "案件詳細は必須です";
        state.show.errorDialog = true;
      } else if (!state.snsType?.key && state.snsType?.key !== 0) {
        state.formErrorMessage = "SNSの種類は必須です";
        state.show.errorDialog = true;
      } else if (!state.listingNum) {
        state.formErrorMessage = "リストアップの希望人数は必須です";
        state.show.errorDialog = true;
      } else {
        store.dispatch("tasks/createTaskInitialize");
        if (state.checked) {
          const params = { isReadListing: state.checked };
          store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
        }
        const params: CreateTaskParams = {
          company: state.company?.id,
          type: state.taskType.key,
          snsType: state.snsType.key,
          title: state.taskTitle,
          backGround: state.taskBackground,
          detail: state.taskDetail,
          attribute: state.attributes,
          followerAttribute: state.followerAttributes,
          listingNum: state.listingNum,
          ages: state.ages.slice().map((x: ItemType) => ({ age: x.text })),
          followerAges: state.followerAges.slice().map((x: ItemType) => ({ age: x.text })),
          hashtags: state.hashtags.slice().map((x: string) => ({ tag: x })),
          followerHashtags: state.followerHashtags.slice().map((x: string) => ({ tag: x })),
        };
        if (state.minFollower) {
          params.minFollowers = state.minFollower;
        } else {
          params.minFollowers = null;
        }
        if (state.maxFollower) {
          params.maxFollowers = state.maxFollower;
        } else {
          params.maxFollowers = null;
        }
        if (state.genderType?.key) params["gender"] = state.genderType?.key;
        if (state.followerGenderType?.key) params["followerGender"] = state.followerGenderType?.key;
        store.dispatch("tasks/createTask", params);
        state.show.dialog = false;
        state.show.createTask = true;
      }
      state.show.dialog = false;
    };
    let createTaskSuccess = computed(() => state.createTaskSuccess);
    watch(createTaskSuccess, (val: boolean) => {
      store.dispatch("users/loadCurrentUser");
    });
    const openListDialog = () => {
      state.formErrorMessage = null;
      if (state.user?.isReadListing) {
        state.show.errorDialog = false;
        createTask();
      } else {
        state.show.dialog = true;
      }
    };
    const createTaskDone = () => {
      router.push({ name: "TaskManage" });
    };
    onBeforeRouteUpdate((to) => {
      const toTaskType = to.query.type ? TaskType.getObject(Number(to.query.type)) : null;
      state.taskType = Object.assign({}, toTaskType);
      if (toTaskType) {
        state.show.first = false;
        state.show.second = true;
        state.show.third = false;
        state.show.fourth = false;
        state.show.fifth = false;
      } else {
        state.show.first = true;
        state.show.second = false;
        state.show.third = false;
        state.show.fourth = false;
        state.show.fifth = false;
      }
    });
    return {
      ...toRefs(state),
      GenderType,
      SNSType,
      TaskType,
      AgeGroup,
      selectPurpose,
      back2First,
      validateThird,
      validateFourth,
      createTask,
      openListDialog,
      createTaskDone,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.no-function-container {
  width: 80%;
  padding: 80px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin-top: 60px;
  & h1 {
    color: map-get($colors, "primary");
    margin-bottom: 40px;
  }
}
.listing {
  width: 100%;
  & a.mail-link {
    color: #0b82d4;
  }
  & .d-flex {
    @include mq(xs) {
      display: block!important;
    }
    @include mq(lg) {
      display: flex!important;
    }
  }
  & .mr-40px {
    @include mq(xs) {
      margin-right: initial!important;
    }
    @include mq(lg) {
      margin-right: 40px!important;
    }
  }
  &__first {
    &__contents {
      margin: 24px 0 0 0;
      &__description {
        padding: 16px 0 40px;
        & h2 {
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
        & .select-area {
          margin: 6px 0;
        }
      }
      &__section {
        &__title {
          position: relative;
          & h3 {
            color: map-get($colors, "blue400");
            padding-left: 16px;
          }
          &:after {
            position: absolute;
            content: "";
            background-color: map-get($colors, "blue400");
            width: 6px;
            height: 24px;
            top: 1px;
            left: 0;
          }
        }
        &__merit {
          padding: 10px 20px;
          background-color: white;
          border-radius: 10px;
          & h4 {
            color: map-get($colors, "default");
          }
        }
        &__flow {
          align-items: center;
          display: flex!important;
          @include mq(xs) {
            overflow-x: scroll;
          }
          @include mq(lg) {
            overflow-x: auto;
          }
          &__title {
            display: table-cell;
            vertical-align: middle;
            & h4 {
              color: map-get($colors, "blue400");
              font-weight: 700;
              text-align: center;
            }
            & h5 {
              color: map-get($colors, "blue400");
              font-weight: 700;
              text-align: center;
            }
          }
          &__negotiation {
            width: fit-content;
            font-size: 12px;
            font-weight: 700;
            color: white;
            text-align: left;
            align-self: start;
            background-color: map-get($colors, "gray300");
            border-radius: 20px;
            padding: 4px 8px;
            margin-bottom: 5px;
          }
          &__request {
            width: fit-content;
            font-size: 12px;
            font-weight: 700;
            color: white;
            text-align: left;
            align-self: start;
            background-color: map-get($colors, "accent");
            border-radius: 20px;
            padding: 4px 8px;
            margin: 10px 0 5px;
          }
          &__description {
            display: flex;
            flex-direction: column;
            & h5 {
              font-size: 12px;
              font-weight: 500;
              color: map-get($colors, "gray300");
              text-align: left;
            }
            & p {
              color: map-get($colors, "accent");
              font-size: 10px;
            }
          }
          &__arrow {
            color: map-get($colors, "blue300");
            font-size: 36px;
            margin: 0 10px;
          }
        }
        &__footer {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
        }
        &__request {
          display: flex;
          &__title {
            width: fit-content;
            padding: 10px 10px;
            background-color: map-get($colors, "accent");
            border-radius: 20px;
            color: white;
            font-weight: 700;
            font-size: 14px;
          }
          &__description {
            color: map-get($colors, "accent");
            font-weight: 700;
            font-size: 14px;
            @include mq(xs) {
              max-width: calc(70vw - 30px);
            }
            @include mq(lg) {
              max-width: initial;
            }
          }
        }
      }
      &__container {
        position: relative;
        .cards {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 40px;
          position: relative;
          @include mq(xs) {
            display: block;
          }
          @include mq(lg) {
            display: flex;
          }
          & .card {
            max-width: 450px;
            min-width: 380px;
            width: calc(50% - 100px);
            padding: 30px 20px 20px 40px;
            height: 120px;
            position: relative;
            border-radius: 10px;
            background-color: white;
            align-self: flex-start;
            justify-self: flex-start;
            @include mq(xs) {
              max-width: 450px;
              min-width: 240px;
              width: calc(90vw - 40px);
              padding: 20px 20px 20px 30px;
              height: fit-content;
              margin-bottom: 20px;
            }
            @include mq(lg) {
              max-width: 450px;
              min-width: 380px;
              width: calc(50% - 100px);
              padding: 30px 20px 20px 40px;
              height: 120px;
              margin-bottom: 0;
            }
            &:hover {
              cursor: pointer;
            }
            &::after {
              content: "";
              width: 20px;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 10px 0 0 10px;
            }
            &.interview {
              & img {
                width: 130px;
                height: 102px;
                margin-top: 10px;
                @include mq(xs) {
                  display: none;
                }
                @include mq(lg) {
                  display: block;
                }
              }
              & h3 {
                color: #8bd5ad;
              }
              &::after {
                background-color: #8bd5ad;
              }
            }
            &.collaboration {
              & img {
                width: 140px;
                @include mq(xs) {
                  display: none;
                }
                @include mq(lg) {
                  display: block;
                }
              }
              & h4 {
                color: map-get($colors, "blue300");
                font-weight: 900;
              }
              &::after {
                background-color: map-get($colors, "blue300");
              }
            }
            &.pr {
              & img {
                width: 150px;
                @include mq(xs) {
                  display: none;
                }
                @include mq(lg) {
                  display: block;
                }
              }
              & h3 {
                color: #ea9090;
              }
              &::after {
                background-color: #ea9090;
              }
            }
            &.others::after {
              background-color: #626262;
            }
            & h3 {
              font-weight: 900;
              font-size: 18px;
              color: #808080;
              @include mq(xs) {
                font-size: 14px;
              }
              @include mq(lg) {
                font-size: 18px;
              }
            }
            & p {
              font-weight: 500;
              font-size: 12px;
              color: #808080;
            }
            &__contents {
              justify-content: space-between;
            }
          }
        }
      }
      & .next {
        color: map-get($colors, "primary");
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
      & .back {
        color: map-get($colors, "gary300");
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  &__contents {
    width: 100%;
    height: calc(100vh - 140px);
    border-radius: 10px;
    margin: 24px 0 0 0;
    background-color: map-get($bg-colors, "default");
    border: solid 2px map-get($colors, "blue400");
    display: flex;
    @include mq(xs) {
      display: block;
      margin: 24px 0 60px 0;
      height: fit-content;
    }
    @include mq(lg) {
      display: flex;
      margin: 24px 0 0 0;
      height: calc(100vh - 140px);
    }
    &__description {
      padding: 24px;
      background: white;
      width: 20%;
      border-radius: 10px 0 0 10px;
      @include mq(xs) {
        width: calc(100% - 24px);
        padding: 12px;
        border-radius: 10px 10px 0 0;
      }
      @include mq(lg) {
        width: 20%;
        padding: 24px;
        border-radius: 10px 0 0 10px;
      }
      & h2 {
        margin-top: 60px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        @include mq(xs) {
          margin-top: 10px;
        }
        @include mq(lg) {
          margin-top: 60px;
        }
        & br {
          @include mq(xs) {
            display: none;
          }
          @include mq(lg) {
            display: block;
          }
        }
      }
      & .select-area {
        margin: 6px 0;
      }
    }
    &__container {
      padding: 40px 60px;
      width: 80%;
      position: relative;
      @include mq(xs) {
        padding: 5px 10px;
      }
      @include mq(lg) {
        padding: 40px 60px;
      }
      .cards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 40px 60px;
        position: relative;
        @include mq(xs) {
          display: block;
        }
        @include mq(lg) {
          display: flex;
        }
        & .card {
          max-width: 440px;
          width: calc(50% - 100px);
          padding: 30px 10px 20px 50px;
          height: 120px;
          position: relative;
          border-radius: 10px;
          background-color: white;
          align-self: flex-start;
          justify-self: flex-start;
          @include mq(xs) {
            width: 90vw;
          }
          @include mq(lg) {
            width: calc(50% - 100px);
          }
          &:hover {
            cursor: pointer;
          }
          &::after {
            content: "";
            width: 20px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 10px 0 0 10px;
          }
          &.interview::after {
            background-color: #0ac794;
          }
          &.collaboration::after {
            background-color: #0a6dc7;
          }
          &.pr::after {
            background-color: #c7530a;
          }
          &.others::after {
            background-color: #626262;
          }
          & h3 {
            font-weight: 900;
            font-size: 18px;
            color: #707070;
          }
          & p {
            font-weight: 500;
            font-size: 12px;
            color: #707070;
          }
        }
      }
      & .next {
        color: map-get($colors, "primary");
        cursor: pointer;
        position: absolute;
        bottom: 40px;
        right: 60px;
        @include mq(xs) {
          bottom: -40px;
          right: 60px;
          position: absolute;
        }
        @include mq(lg) {
          bottom: 40px;
          right: 60px;
          position: absolute;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      & .back {
        color: map-get($colors, "gary300");
        cursor: pointer;
        position: absolute;
        bottom: 40px;
        left: 60px;
        @include mq(xs) {
          bottom: -40px;
          left: 60px;
          position: absolute;
        }
        @include mq(lg) {
          bottom: 40px;
          left: 60px;
          position: absolute;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      & .combo-label {
        text-align: left;
        font-weight: 900;
        font-size: 13px;
        margin: 8px 10px 8px 0;
        color: map-get($colors, "gray300");
      }
      &__box {
        white-space: nowrap;
        border-radius: 5px;
        border: 2px solid map-get($colors, "primary");
        padding: 10px;
        margin-right: 10px;
        width: fit-content;
        height: fit-content;
      }
    }
    & .choice {
      border-radius: 5px;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 8px;
        display: block !important;
      }
      &::-webkit-scrollbar-track {
        background-color: white;
      }
      &::-webkit-scrollbar-thumb {
        background-color: map-get($colors, "gray100");
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
      }
    }
  }
  &__comment {
    color: map-get($colors, "accent");
    font-weight: 700;
  }
  &__dialog {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      position: relative;
    }
    &__content {
      position: absolute;
      width: 800px;
      padding: 70px 70px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 15%);
      -webkit-transform: translate(-50%, 15%);
      -ms-transform: translate(-50%, 15%);
      background-color: white;
      border-radius: 10px;
      text-align: center;
      @include mq(xs) {
        width: 90vw;
        padding: 40px 20px;
      }
      @include mq(lg) {
        width: 800px;
        padding: 70px 70px;
      }
      &__title {
        font-size: 24px;
        font-weight: 700;
        color: map-get($colors, "blue400");
      }
      &__text {
        font-size: 18px;
        font-weight: 500;
        color: map-get($font-colors, "default");
        & span {
          font-size: 24px;
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
      }
    }
  }
  &-error {
    color: map-get($colors, "accent");
    font-size: 12px;
    font-weight: 500;
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: calc(90vw - 20px);
    padding: 20px;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 800px;
    padding: 20px 100px;
    margin: 100px auto;
    border: none;
  }
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.base-container {
  &__white {
    background-color: white;
  }
}
</style>
