
import { computed, defineComponent, reactive, toRefs, PropType } from "vue";

export default defineComponent({
  name: "BaseTextField",
  props: {
    label: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 240,
    },
    height: {
      type: Number,
      default: 42,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      text: props.modelValue,
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--width": props.width,
        "--height": props.height,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
      };
    });
    const handleText = () => {
      context.emit("update:modelValue", state.text);
    };
    return {
      ...toRefs(state),
      styles,
      handleText,
    };
  },
});
