<template>
  <div class="base-container__white">
    <div class="first-setting">
      <header class="d-flex">
        <img src="@/assets/img/logo-white.svg" alt="INFRECT" />
      </header>
      <div class="first-setting__content" v-show="show.base">
        <div class="mt-60px">
          <h1>INFRECTへようこそ！</h1>
        </div>
        <div class="mt-40px">
          <h3>INFRECTを利用するには初回設定が必要です。</h3>
        </div>
        <div class="mt-60px">
          <base-button
            :size="'wide'"
            @click="
              show.first = true;
              show.base = false;
            "
            >初回設定をはじめる</base-button
          >
        </div>
      </div>
      <div class="first-setting__content" v-show="show.first">
        <div class="mt-40px first-setting__content__title">
          <h2>会社情報の登録</h2>
        </div>
        <div class="mt-40px">
          <div class="d-flex align-items-center">
            <div class="icon mr-10px">
              <div>
                <div class="icon__input">
                  <div class="icon__input__btn">
                    <input type="file" ref="uploadThumbnailInputForCompany" accept="image/jpeg,image/png" @change="handleFileForCompany" />
                  </div>
                  <div class="icon__input__image">
                    <img src="@/assets/img/camera-circle.svg" v-show="!imgUrlForCompany" alt="" />
                    <img :src="imgUrlForCompany" v-show="imgUrlForCompany" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="icon__label">
              <h4>会社ロゴ</h4>
              <h5>アイコンをクリックして,アイコンを変更してください。</h5>
            </div>
          </div>
        </div>
        <div class="first-setting__section">
          <base-text-field label="会社名" :width="356" class="mt-40px" v-model="companyName" required></base-text-field>
        </div>
        <div class="first-setting__section">
          <base-text-field label="部署名" :width="356" class="mt-20px" v-model="department"></base-text-field>
        </div>
        <base-select-box label="業種" :width="396" class="mt-20px" item-value="key" v-model="companyType" :items="CompanyType.getAll()" required></base-select-box>
        <div class="first-setting-error mt-20px" v-show="formErrorMessage || errorMessage">{{ formErrorMessage }}{{ errorMessage }}</div>
        <div class="mt-60px">
          <base-button size="short" @click="submitCompanyData">次へ</base-button>
        </div>
      </div>
      <div class="first-setting__content" v-show="show.second">
        <div class="mt-40px first-setting__content__title">
          <h2>管理ユーザー情報の登録</h2>
        </div>
        <div class="mt-40px">
          <div class="d-flex align-items-center">
            <div class="icon mr-10px">
              <div>
                <div class="icon__input">
                  <div class="icon__input__btn">
                    <input type="file" ref="uploadThumbnailInput" accept="image/jpeg,image/png" @change="handleFile" />
                  </div>
                  <div class="icon__input__image">
                    <img src="@/assets/img/camera-circle.svg" v-show="!imgUrl" alt="" />
                    <img :src="imgUrl" v-show="imgUrl" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="icon__label">
              <h4>ユーザーアイコン</h4>
              <h5>アイコンをクリックして、アイコンを変更してください。</h5>
            </div>
          </div>
        </div>
        <div class="first-setting__section">
          <base-text-field label="ユーザー名" :width="360" class="mt-40px" v-model="username" required></base-text-field>
        </div>
        <div class="first-setting-error mt-20px" v-show="formErrorMessage || errorMessage">{{ formErrorMessage }}{{ errorMessage }}</div>
        <div class="mt-60px">
          <base-button size="short" @click="submitUserData">次へ</base-button>
        </div>
      </div>
      <div class="first-setting__content" v-show="show.third">
        <CheckMark></CheckMark>
        <div class="mt-40px">
          <h2>初回設定完了</h2>
        </div>
        <div class="mt-40px">
          <h3>さあINFRECTを使ってみましょう！</h3>
        </div>
        <div class="mt-60px">
          <router-link :to="{ name: 'Home' }">
            <base-button :size="'wide'">INFRECTをはじめる</base-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";
import { CompanyCreateParams, UserUpdateParams } from "@/types";
import { CompanyType } from "@/enum/companyType";

export default defineComponent({
  name: "FirstSetting",
  components: { BaseSelectBox, CheckMark, BaseTextField, BaseButton },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("companies/createCompanyInitialize", false);
    const state = reactive({
      show: {
        base: true,
        first: false,
        second: false,
        third: false,
      },
      companyName: null as string | null,
      department: null as string | null,
      companyType: null as number | null,
      username: null as string | null,
      imgUrl: null as string | null,
      imgUrlForCompany: null as string | null,
      errorMessage: computed(() => store.state.companies.errorMessage),
      formErrorMessage: null as string | null,
      createCompanySuccess: computed(() => store.state.companies.createCompanySuccess),
      updateUserSuccess: computed(() => store.state.users.updateUserSuccess),
      user: computed(() => store.state.users.user),
      CompanyType,
    });
    if (state.user?.isSetCompany) router.push({ name: "Home" });
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const uploadThumbnailInputForCompany = ref<HTMLInputElement>();
    const handleFileForCompany = () => {
      state.imgUrlForCompany = URL.createObjectURL((uploadThumbnailInputForCompany.value?.files || [])[0]);
    };
    const submitCompanyData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.companyName) {
        state.formErrorMessage = "会社名は必須です";
      } else if (!state.companyType && state.companyType !== 0) {
        state.formErrorMessage = "業種は必須です";
      } else {
        const params: CompanyCreateParams = {
          name: state.companyName,
          department: state.department,
          type: state.companyType,
        };
        if ((uploadThumbnailInputForCompany?.value?.files || [])[0]) params.logo = (uploadThumbnailInputForCompany?.value?.files || [])[0];
        store.dispatch("companies/createCompany", params);
        if (!state.user) store.dispatch("users/loadCurrentUser");
      }
    };
    let createCompanySuccess = computed(() => state.createCompanySuccess);
    watch(createCompanySuccess, (val: boolean) => {
      if (val) state.show.second = true;
      state.show.first = false;
    });
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.username) {
        state.formErrorMessage = "名前は必須です";
      } else {
        const params: UserUpdateParams = {
          username: state.username,
        };
        if ((uploadThumbnailInput.value?.files || [])[0]) params.logo = (uploadThumbnailInput.value?.files || [])[0];
        store.dispatch("users/updateUser", { params: params, userId: state.user.id });
      }
    };
    let updateUserSuccess = computed(() => state.updateUserSuccess);
    watch(updateUserSuccess, (val: boolean) => {
      if (val) state.show.third = true;
      state.show.second = false;
    });
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      handleFile,
      uploadThumbnailInputForCompany,
      handleFileForCompany,
      submitCompanyData,
      submitUserData,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";

.first-setting {
  text-align: center;
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    display: inline-block;
    margin-top: 120px;
    &__title {
      & h2 {
        color: map-get($colors, "blue400");
      }
    }
    & h1 {
      color: map-get($colors, "blue400");
    }
    & .icon {
      border: 2px solid map-get($colors, "blue300");
      background: map-get($colors, "blue300");
      border-radius: 50%;
      width: 80px;
      height: 80px;
      cursor: pointer;
      &__input {
        position: relative;
        &__image {
          pointer-events: none;
          & img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &__btn {
          display: inline-block;
          position: relative;
          cursor: pointer;
          border-radius: 5px;
          width: 80px;
          height: 80px;
          & input[type="file"] {
            top: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            width: 80px;
            height: 80px;
            cursor: pointer;
          }
        }
      }
      &__label {
        text-align: left;
        & h4 {
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
        & h3 {
          font-weight: 500;
        }
      }
    }
  }
  &__section {
    width: 100%;
  }
  &-error {
    color: map-get($colors, "accent");
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
