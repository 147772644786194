<template>
  <svg
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 48 48"
  >
    <rect width="48" height="48" rx="24" :fill="rounded ? 'white' : 'none'"/>
    <path d="M19.2,6.789H15.6V4.895A1.843,1.843,0,0,0,13.8,3H10.2A1.843,1.843,0,0,0,8.4,4.895V6.789H4.8A1.835,1.835,0,0,0,3.009,8.684L3,19.105A1.843,1.843,0,0,0,4.8,21H19.2A1.843,1.843,0,0,0,21,19.105V8.684A1.843,1.843,0,0,0,19.2,6.789Zm-5.4,0H10.2V4.895h3.6Z" transform="translate(12 12)" :fill="fillColor" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent ({
  name: "OrderManageIcon",
  props: {
    fillColor: {
      type: String,
      default: "#fff"
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})

</script>





