<template>
  <div class="base-container">
    <sidebar :current-tab="6"></sidebar>
    <div class="main">
      <base-header header-title="アカウント設定" display-type="MEMBER"></base-header>
      <div class="dialog__container" v-if="(updateUserSuccess || updateCompanySuccess || errorMessage || updateMemberSuccess) && show.update">
        <div class="dialog" v-if="updateUserSuccess || updateCompanySuccess || updateMemberSuccess">
          <div class="title">変更が完了しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button size="short" color="dark" @click="show.update = false"> OK </base-button></div>
        </div>
        <div class="dialog" v-else>
          <div class="title error">変更に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.update = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-else-if="formErrorMessage && show.errorDialog">
        <div class="dialog">
          <div class="title error">変更に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ formErrorMessage }}</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-if="(invitationMemberSuccess || errorMessage) && show.invitationSuccess">
        <div class="dialog" v-if="invitationMemberSuccess">
          <div class="title">メンバーの招待が完了しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button size="short" color="dark" @click="show.invitationSuccess = false"> OK </base-button></div>
        </div>
        <div class="dialog" v-else>
          <div class="title error">招待に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ errorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.invitationSuccess = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-else-if="formErrorMessage && show.errorDialog">
        <div class="dialog">
          <div class="title error">招待に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ formErrorMessage }}</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.errorDialog = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-if="(deleteMemberSuccess || InvitationErrorMessage) && show.deleteMemberSuccess">
        <div class="dialog" v-if="deleteMemberSuccess">
          <div class="title">メンバーの削除が完了しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button size="short" color="dark" @click="show.deleteMemberSuccess = false"> OK </base-button></div>
        </div>
        <div class="dialog" v-else>
          <div class="title error">削除に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ InvitationErrorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.deleteMemberSuccess = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="dialog__container" v-if="(deleteInvitationMemberSuccess || InvitationErrorMessage) && show.deleteInvitationMemberSuccess">
        <div class="dialog" v-if="deleteInvitationMemberSuccess">
          <div class="title">メンバーの招待を取り消しました</div>
          <div class="description"><check-mark></check-mark></div>
          <div class="description"><base-button size="short" color="dark" @click="show.deleteInvitationMemberSuccess = false"> OK </base-button></div>
        </div>
        <div class="dialog" v-else>
          <div class="title error">招待取り消しに失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>{{ InvitationErrorMessage }}<br />お手数ですが、最初からやり直してください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="show.deleteInvitationMemberSuccess = false"> 戻る </base-button></div>
        </div>
      </div>
      <div class="account-setting">
        <div class="account-setting__tabs mt-20px d-flex">
          <div :class="['account-setting__tab', tab === 'general' ? 'selected' : '']" @click="selectTabs('general')"><h4>登録情報</h4></div>
          <div :class="['account-setting__tab', tab === 'notifications' ? 'selected' : '']" @click="selectTabs('notifications')"><h4>通知設定</h4></div>
          <div :class="['account-setting__tab', tab === 'members' ? 'selected' : '']" @click="selectTabs('members')"><h4>メンバー管理</h4></div>
          <div :class="['account-setting__tab', tab === 'invoices' ? 'selected' : '']" @click="selectTabs('invoices')"><h4>請求書</h4></div>
          <div :class="['account-setting__tab', tab === 'plans' ? 'selected' : '']" @click="selectTabs('plans')"><h4>プラン</h4></div>
        </div>
        <div class="account-setting__content mt-20px" v-if="tab === 'notifications'">
          <h1>通知設定</h1>
          <div class="account-setting__content__container__full mt-20px">
            <div class="account-setting__content__container__title">
              <h4>メール通知</h4>
            </div>
            <div class="account-setting__content__container__notification">
              <div class="notification__wrapper">
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">新規メンバー登録</div>
                    <div class="notification__description">招待されたメンバーがINFRECTのアカウント作成を完了した際に、メールに通知します。</div>
                  </div>
                  <toggle-button v-model="notification.enableEmailMemberNotification" id="enableEmailMemberNotification" @toggle="checkNotificationChanged" :key="show.notificationPage" />
                </div>
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">ビジネスクリエーション候補者の追加</div>
                    <div class="notification__description">ビジネスクリエーションの候補者をINFRECTからご提案した際に、メールで通知します。</div>
                  </div>
                  <toggle-button v-model="notification.enableEmailListedNotification" id="enableEmailListedNotificationBtn" @toggle="checkNotificationChanged" :key="show.notificationPage" />
                </div>
              </div>
            </div>
            <div class="account-setting__content__container__title">
              <h4>サービス利用時の確認項目</h4>
            </div>
            <div class="account-setting__content__container__notification">
              <div class="notification__wrapper">
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">ビジネスクリエーションの利用</div>
                    <div class="notification__description">ビジネスクリエーションをご利用される場合に、候補者に関する注意事項を表示します。</div>
                  </div>
                  <toggle-button v-model="notification.isReadListing" id="isReadListingBtn" @toggle="checkNotificationChanged" reverse :key="show.notificationPage" />
                </div>
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">インフルエンサーの詳細閲覧</div>
                    <div class="notification__description">インフルエンサー詳細を閲覧される際に、詳細閲覧に関する注意事項を表示します。</div>
                  </div>
                  <toggle-button v-model="notification.isReadDetail" id="isReadDetailBtn" @toggle="checkNotificationChanged" reverse :key="show.notificationPage" />
                </div>
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">インフルエンサーリストへの追加</div>
                    <div class="notification__description">インフルエンサーをインフルエンサーリストに追加される際に、注意事項を表示します。</div>
                  </div>
                  <toggle-button v-model="notification.isReadAddList" id="isReadAddListBtn" @toggle="checkNotificationChanged" reverse :key="show.notificationPage" />
                </div>
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">インフルエンサーリストの削除</div>
                    <div class="notification__description">インフルエンサーリスト内のインフルエンサーやフォルダを削除する際に、注意事項を表示します。</div>
                  </div>
                  <toggle-button v-model="notification.isReadDeleteList" id="isReadDeleteListBtn" @toggle="checkNotificationChanged" reverse :key="show.notificationPage" />
                </div>
                <div class="notification__container">
                  <div class="notification__content">
                    <div class="notification__title">メンバーの削除</div>
                    <div class="notification__description">会社のメンバーを削除する際に、メンバー削除に関する注意事項を表示します。</div>
                  </div>
                  <toggle-button v-model="notification.isReadDeleteMember" id="isReadDeleteMemberBtn" @toggle="checkNotificationChanged" reverse :key="show.notificationPage" />
                </div>
              </div>
            </div>
            <div class="notification__btn">
              <base-button size="wide" color="blue" @click="updateUserNotifications" :disabled="!notificationChanged" :key="notificationChanged">変更を保存</base-button>
            </div>
          </div>
        </div>
        <div class="account-setting__content mt-20px" v-else-if="tab === 'members' && company?.plan?.memberLimitation !== 0">
          <div class="d-flex justify-content-space-between">
            <h1>メンバー管理</h1>
            <base-button color="success" @click="show.invite = true" v-if="user?.isCompanyAdmin && company?.members?.length + invitationMember?.length < company?.plan?.memberLimitation">メンバーを招待する</base-button>
          </div>
          <member-table class="mt-20px" v-show="(members || []).length > 0 ? (show.members = true) : ''" :items="members" v-if="loadCompanySuccess" :has-company-admin-role="user?.isCompanyAdmin" />
          <div class="mt-40px" v-show="(invitationMember || []).length > 0 ? (show.invitationMember = true) : ''">
            <h1>招待中のメンバー</h1>
            <invitation-member-table class="mt-20px mb-20px" :items="invitationMember" v-if="invitationMember" :has-company-admin-role="user?.isCompanyAdmin"></invitation-member-table>
          </div>
        </div>
        <div class="account-setting__content mt-20px" v-else-if="tab === 'members' && company?.plan?.memberLimitation === 0">
          <div class="no-function-container">
            <h1>ご利用中のプランではこの機能はお使いいただけません。</h1>
            <base-button color="success" size="wide" class="mt-20px mr-20px" @click="selectTabs(4)">プランを変更する</base-button>
          </div>
        </div>
        <div class="account-setting__content mt-20px" v-else-if="tab === 'invoices'">
          <h1>請求書</h1>
          <InvoiceTable class="mt-20px" :items="invoices" v-if="invoiceSuccess"></InvoiceTable>
          <div class="loading-container" v-else-if="invoiceLoading">
            <h1>読み込み中です少々お待ちください</h1>
            <loading></loading>
          </div>
          <div class="failure-container" v-else>
            <h1>取得に失敗しました。</h1>
            <exclamation-mark></exclamation-mark>
            <h4>お手数ではございますが、再度読み込んでください。</h4>
          </div>
        </div>
        <div class="account-setting__content mt-20px" v-else-if="tab === 'plans'">
          <h1>プラン</h1>
          <div class="account-setting__content__plan">
            <plan-card :has-company-admin-role="user?.isCompanyAdmin && !company?.isInvoicePayment" enable-subscription-link disable-scroll></plan-card>
          </div>
        </div>
        <div class="account-setting__content mt-20px" v-else>
          <h1>登録情報</h1>
          <div class="d-flex">
            <div class="mr-20px">
              <div class="account-setting__content__container mt-20px">
                <div class="account-setting__content__container__title">
                  <h4>会社情報</h4>
                </div>
                <div class="account-setting__content__container__info d-flex mt-20px align-items-center">
                  <div class="d-flex align-items-center">
                    <company-icon :src="company?.logo"></company-icon>
                    <div class="ml-10px">
                      <h4>{{ company?.name }}</h4>
                      <h5>{{ company?.department }}</h5>
                    </div>
                  </div>
                  <base-button size="short" color="dark" @click="show.company = true" v-if="user?.isCompanyAdmin">編集</base-button>
                </div>
              </div>
              <div class="account-setting__content__container mt-20px">
                <div class="account-setting__content__container__title">
                  <h4>アカウント情報</h4>
                </div>
                <div class="account-setting__content__container__account d-flex mt-20px">
                  <div class="account-setting__content__container__account__img mr-20px">
                    <profile-icon :src="user?.logo" :size="80"></profile-icon>
                  </div>
                  <div>
                    <div class="account-setting__content__container__account__info">
                      <h5>名前</h5>
                      <h4>{{ user?.username }}</h4>
                    </div>
                    <div class="account-setting__content__container__account__info mt-20px">
                      <h5>メールアドレス</h5>
                      <h4>{{ user?.email }}</h4>
                    </div>
                  </div>
                </div>
                <div class="account-setting__content__container__button mt-20px">
                  <base-button size="short" color="dark" @click="show.account = true">編集</base-button>
                </div>
              </div>
            </div>
            <div>
              <div class="account-setting__content__container side mt-20px">
                <div class="account-setting__content__container__title">
                  <h4>退会</h4>
                </div>
                <div class="side__button">
                  <base-button size="short" color="accent" @click="show.resign = true">退会する</base-button>
                </div>
              </div>
              <div class="account-setting__content__container side mt-20px">
                <div class="account-setting__content__container__title">
                  <h4>パスワードの変更</h4>
                </div>
                <div class="side__button">
                  <router-link :to="{ name: 'ChangePassword' }">
                    <base-button size="short" color="dark">変更する</base-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="invite__dialog" v-show="show.invite">
    <div class="invite__dialog__container">
      <div class="invite__dialog__content">
        <div class="title">メンバーを招待する</div>
        <div class="mt-40px">
          <div class="form">
            <base-text-field label="メールアドレス" :key="email" v-model="email"></base-text-field>
            <select-box label="権限" class="mt-20px" v-model="role" item-value="key" :options="MemberRole.getAll()" v-if="`role-${role}`"></select-box>
            <div class="error mt-20px" v-show="formErrorMessage || InvitationErrorMessage">
              <span>{{ formErrorMessage }}</span>
              <span v-if="Array.isArray(InvitationErrorMessage?.email) && InvitationErrorMessage?.email[0]">{{ InvitationErrorMessage?.email[0] }}</span>
              <span v-else-if="InvitationErrorMessage?.message">{{ InvitationErrorMessage?.message }}</span>
              <span v-else>{{ InvitationErrorMessage }}</span>
            </div>
          </div>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button size="short" class="mr-20px" @click="submitInvitationMember">送信</base-button>
          <base-button size="short" color="dark" @click="show.invite = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="account-setting__dialog" v-show="show.company">
    <div class="account-setting__dialog__container">
      <div class="account-setting__dialog__content">
        <div class="title">会社情報を編集する</div>
        <div class="mt-40px">
          <div class="form">
            <div class="d-flex align-items-center">
              <div class="icon mr-10px">
                <div>
                  <div class="icon__input">
                    <div class="icon__input__btn">
                      <input type="file" ref="uploadThumbnailInputForCompany" accept="image/jpeg,image/png" @change="handleFileForCompany" />
                    </div>
                    <div class="icon__input__image">
                      <company-icon :src="company?.logo" v-if="!imgUrlForCompany" :size="60"></company-icon>
                      <img :src="imgUrlForCompany" v-else alt="会社のアイコン" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="icon__label">
                <h4>会社ロゴ</h4>
                <h6>アイコンをクリックしてアイコンを変更</h6>
              </div>
            </div>
            <base-text-field placeholder="入力してください" v-model="companyName" v-if="loadCompanySuccess" label="会社名" class="mt-40px"></base-text-field>
            <base-text-field placeholder="入力してください" v-model="department" v-if="loadCompanySuccess" label="部署名" class="mt-20px"></base-text-field>
          </div>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button size="short" class="mr-20px" @click="submitCompanyData">変更</base-button>
          <base-button size="short" color="dark" @click="show.company = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="account-setting__dialog" v-show="show.account">
    <div class="account-setting__dialog__container">
      <div class="account-setting__dialog__content">
        <div class="title">アカウント情報を編集する</div>
        <div class="mt-40px">
          <div class="form">
            <div class="d-flex align-items-center">
              <div class="icon mr-10px">
                <div>
                  <div class="icon__input">
                    <div class="icon__input__btn">
                      <input type="file" ref="uploadThumbnailInput" accept="image/jpeg,image/png" @change="handleFile" />
                    </div>
                    <div class="icon__input__image">
                      <profile-icon :src="user?.logo" v-if="!imgUrl" :size="54"></profile-icon>
                      <img :src="imgUrl" v-else alt="ユーザーのアイコン" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="icon__label">
                <h4>ユーザーアイコン</h4>
                <h6>アイコンをクリックしてアイコンを変更</h6>
              </div>
            </div>
            <base-text-field placeholder="入力してください" v-model="username" label="ユーザー名" class="mt-40px"></base-text-field>
          </div>
        </div>
        <div class="buttons d-flex justify-content-center mt-40px">
          <base-button size="short" class="mr-20px" @click="submitUserData">変更</base-button>
          <base-button size="short" color="dark" @click="show.account = false">キャンセル</base-button>
        </div>
      </div>
    </div>
  </div>
  <div class="account-setting__dialog resign" v-show="show.resign">
    <div class="account-setting__dialog__container">
      <div class="account-setting__dialog__content">
        <div class="title">退会する</div>
        <div class="mt-40px d-flex justify-content-center">
          <ExclamationMark></ExclamationMark>
        </div>
        <div v-if="company?.plan?.price || company?.plan?.initialPrice">
          <div class="account-setting__dialog__content__text">
            <div>
              <h4 class="mb-10px">退会前にプランを解約してくだい</h4>
              <h5>継続しているプランがあります。<br>
                退会前にプランを解約する必要があります。<br>
                特定メンバーのみ退会したい場合は<a class="link" @click="show.resign = false; tab = 'members';">メンバー管理</a>からメンバーを削除してください</h5>
            </div>
          </div>
          <div class="buttons d-flex justify-content-center mt-40px">
            <base-button size="wide" color="dark" @click="show.resign = false; tab = 'plans';">プランページに移動</base-button>
          </div>
        </div>
        <div v-else>
          <div class="account-setting__dialog__content__text">
            <h4>本当に退会しますか？</h4>
          </div>
          <div class="buttons d-flex justify-content-center mt-40px">
            <base-button size="short" color="accent" class="mr-20px" @click="deleteUserData">退会</base-button>
            <base-button size="short" color="dark" @click="show.resign = false">キャンセル</base-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseButton from "@/components/BaseButton.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import MemberTable from "@/components/MemberTable.vue";
import InvitationMemberTable from "@/components/InvitationMemberTable.vue";
import SelectBox from "@/components/SelectBox.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import InvoiceTable from "@/components/InvoiceTable.vue";
import PlanCard from "@/components/PlanCard.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import Loading from "@/components/Loading.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import CompanyIcon from "@/components/icon/CompanyIcon.vue";
import { MemberRole } from "@/enum/memberRole";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { CompanyMember, CompanyUpdateParams, MemberInvitationParams, UserUpdateInAccountSettingParams } from "@/types";

export default defineComponent({
  name: "AccountSetting",
  components: {
    CompanyIcon,
    ProfileIcon,
    Loading,
    InvitationMemberTable,
    CheckMark,
    ExclamationMark,
    PlanCard,
    InvoiceTable,
    BaseTextField,
    SelectBox,
    MemberTable,
    BaseHeader,
    Sidebar,
    BaseButton,
    ToggleButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("members/loadMember");
    store.dispatch("members/loadInvitationMember");
    store.dispatch("plans/getPlans");
    store.dispatch("invoices/getCompanyInvoices");
    const state = reactive({
      show: {
        invite: false,
        company: false,
        account: false,
        resign: false,
        update: false,
        invitationSuccess: false,
        errorDialog: false,
        deleteMemberSuccess: false,
        deleteInvitationMemberSuccess: false,
      },
      notification: {
        isReadListing: false,
        isReadAddList: false,
        isReadDetail: false,
        isReadRefresh: false,
        isReadDeleteList: false,
        isReadDeleteMember: false,
        enableEmailListedNotification: true,
        enableEmailMemberNotification: true,
      },
      user: computed(() => store.state.users.user),
      member: computed(() => store.state.members.member),
      company: computed(() => store.state.companies.company),
      invitationMember: computed(() => store.state.members.invitationMember),
      invoices: computed(() => store.state.invoices.invoices),
      plans: computed(() => store.state.plans.plans),
      loadPlansSuccess: computed(() => store.state.plans.loadPlansSuccess),
      loadCompanySuccess: computed(() => store.state.companies.loadCompanySuccess),
      invitationMemberSuccess: computed(() => store.state.members.invitationMemberSuccess),
      updateUserSuccess: computed(() => store.state.users.updateUserSuccess),
      updateCompanySuccess: computed(() => store.state.companies.updateCompanySuccess),
      invoiceLoading: computed(() => store.state.invoices.loading),
      invoiceSuccess: computed(() => store.state.invoices.success),
      errorMessage: computed(() => store.state.companies.errorMessage),
      InvitationErrorMessage: computed(() => store.state.members.errorMessage),
      deleteMemberSuccess: computed(() => store.state.members.deleteMemberSuccess),
      deleteInvitationMemberSuccess: computed(() => store.state.members.deleteInvitationMemberSuccess),
      updateMemberSuccess: computed(() => store.state.members.updateMemberSuccess),
      username: null as null | string,
      companyName: null as null | string,
      department: null as null | string,
      imgUrl: null as string | null,
      imgUrlForCompany: null as string | null,
      formErrorMessage: null as string | null,
      role: null as number | null,
      email: null as string | null,
      members: [] as CompanyMember[],
      notificationChanged: false,
      MemberRole,
      tab: "general" as string,
    });
    if (route.query.type) {
      if (["general", "notifications", "members", "invoices", "plans"].includes(String(route.query.type))) {
        state.tab = String(route.query.type);
      }
    }
    const selectTabs = (tab: string) => {
      router.push({ name: "AccountSetting", query: { type: tab } });
      state.tab = tab;
    };
    const loadUserState = (preventComputed = false) => {
      const username = state.user?.username;
      if (!preventComputed) state.username = username;
      const notification = {
        isReadListing: state.user?.isReadListing,
        isReadAddList: state.user?.isReadAddList,
        isReadDetail: state.user?.isReadDetail,
        isReadRefresh: state.user?.isReadRefresh,
        isReadDeleteList: state.user?.isReadDeleteList,
        isReadDeleteMember: state.user?.isReadDeleteMember,
        enableEmailListedNotification: state.user?.enableEmailListedNotification,
        enableEmailMemberNotification: state.user?.enableEmailMemberNotification,
      };
      if (!preventComputed) state.notification = notification;
      return { username: username, notification: notification };
    };
    loadUserState();
    state.companyName = state.company?.name;
    state.department = state.company?.department;
    state.members = state.company?.members;
    const uploadThumbnailInput = ref<HTMLInputElement>();
    const handleFile = () => {
      state.imgUrl = URL.createObjectURL((uploadThumbnailInput.value?.files || [])[0]);
    };
    const uploadThumbnailInputForCompany = ref<HTMLInputElement>();
    const handleFileForCompany = () => {
      state.imgUrlForCompany = URL.createObjectURL((uploadThumbnailInputForCompany.value?.files || [])[0]);
    };
    const submitUserData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.username) {
        state.formErrorMessage = "名前は必須です";
        state.show.errorDialog = true;
      } else {
        const params: UserUpdateInAccountSettingParams = {
          logo: (uploadThumbnailInput.value?.files || [])[0],
          username: state.username,
        };
        store.dispatch("users/updateUserInAccountSetting", { params: params, userId: state.user.id });
      }
    };
    let updateUserSuccess = computed(() => state.updateUserSuccess);
    watch(updateUserSuccess, (val: boolean) => {
      checkNotificationChanged(true);
      if (val) state.show.account = false;
      if (val) state.show.update = true;
    });
    const submitCompanyData = () => {
      state.formErrorMessage = null; // エラーメッセージ初期化
      if (!state.companyName) {
        state.formErrorMessage = "会社名は必須です";
        state.show.errorDialog = true;
      } else {
        const params: CompanyUpdateParams = {
          logo: (uploadThumbnailInputForCompany.value?.files || [])[0],
          name: state.companyName,
          department: state.department,
        };
        store.dispatch("companies/updateCompany", { params: params, companyId: state.company.id });
      }
    };
    let updateCompanySuccess = computed(() => state.updateCompanySuccess);
    watch(updateCompanySuccess, (val: boolean) => {
      if (val) state.show.company = false;
      if (val) state.show.update = true;
    });
    let loadCompanySuccess = computed(() => state.loadCompanySuccess);
    watch(loadCompanySuccess, (val) => {
      if (val) {
        state.companyName = state.company?.name;
        state.department = state.company?.department;
        state.members = state.company?.members;
      }
    });
    const deleteUserData = () => {
      state.formErrorMessage = null;
      store.dispatch("users/deleteUser", { userId: state.user.id });
    };
    const submitInvitationMember = () => {
      store.dispatch("members/invitationMemberInitialize");
      state.formErrorMessage = null;
      if (!state.email) {
        state.formErrorMessage = "メールアドレスは必須です";
        state.show.errorDialog = true;
      } else if (!state.role && state.role !== 0) {
        state.formErrorMessage = "権限は必須です";
        state.show.errorDialog = true;
      } else {
        const params: MemberInvitationParams = {
          email: state.email,
          company: state.user?.companyId,
          role: state.role,
        };
        store.dispatch("members/invitationMember", params);
      }
    };
    let invitationMemberSuccess = computed(() => state.invitationMemberSuccess);
    watch(invitationMemberSuccess, (val: boolean) => {
      if (val) state.show.invite = false;
      if (val) state.show.invitationSuccess = true;
      if (val) state.email = null;
    });
    const checkNotificationChanged = (initialize = false) => {
      const { notification } = loadUserState(!initialize);
      const currObj = JSON.stringify(Object.entries(notification).sort());
      const prevObj = JSON.stringify(Object.entries(state.notification).sort());
      state.notificationChanged = currObj !== prevObj;
    };
    const updateUserNotifications = () => {
      if (!state.notificationChanged) return;
      store.dispatch("users/updateUserReadOption", { params: state.notification, userId: state.user.id });
    };
    let showNotificationSetting = computed(() => state.tab === "notifications");
    watch(showNotificationSetting, (val) => {
      if (val) checkNotificationChanged(true);
    });
    let deleteMemberSuccess = computed(() => state.deleteMemberSuccess);
    watch(deleteMemberSuccess, (val: boolean) => {
      if (val) state.show.deleteMemberSuccess = true;
    });
    let updateMemberSuccess = computed(() => state.updateMemberSuccess);
    watch(updateMemberSuccess, (val: boolean) => {
      if (val) state.show.update = true;
    });
    let deleteInvitationMemberSuccess = computed(() => state.deleteInvitationMemberSuccess);
    watch(deleteInvitationMemberSuccess, (val: boolean) => {
      if (val) state.show.deleteInvitationMemberSuccess = true;
    });
    return {
      ...toRefs(state),
      uploadThumbnailInput,
      handleFile,
      uploadThumbnailInputForCompany,
      handleFileForCompany,
      submitUserData,
      submitCompanyData,
      deleteUserData,
      submitInvitationMember,
      checkNotificationChanged,
      updateUserNotifications,
      selectTabs,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.no-function-container {
  width: 80%;
  padding: 80px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  margin-top: 60px;
  & h1 {
    color: map-get($colors, "primary") !important;
    margin-bottom: 40px;
  }
}
.account-setting {
  &__tabs {
    background: map-get($btn-colors, "disabled");
    width: 600px;
    height: fit-content;
    border-radius: 24px;
  }
  &__tab {
    width: calc(100% / 4);
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    & h4 {
      font-weight: 700;
      color: map-get($colors, "gray200");
    }
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
  & .selected {
    background: map-get($colors, "blue400");
    border-radius: 24px;
    & h4 {
      color: white !important;
    }
  }
  &__content {
    word-break: break-all;
    & h1 {
      color: map-get($colors, "blue400");
    }
    &__container {
      background: white;
      border-radius: 10px;
      width: 500px;
      height: fit-content;
      padding: 20px;
      &__full {
        background: white;
        border-radius: 10px;
        width: calc(100% - 40px);
        height: fit-content;
        padding: 20px;
        & .notification__btn {
          width: 100%;
          margin: 40px auto;
          text-align: center;
        }
      }
      & h4 {
        font-weight: 700;
      }
      &__title {
        & h4 {
          color: map-get($colors, "blue400");
          font-weight: 700;
        }
      }
      &__info {
        justify-content: space-between;
        & .company-logo {
          width: 54px;
          height: 54px;
          border-radius: 50%;
        }
      }
      &__account {
        & img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        &__info {
          & h5 {
            color: map-get($colors, "blue400");
            font-weight: 700;
          }
        }
      }
      &__notification {
        & .notification {
          &__wrapper {
            display: flex;
            gap: 30px 70px;
            box-sizing: border-box;
            width: 100%;
            flex-wrap: wrap;
            margin: 24px 48px;
          }
          &__container {
            margin: 0;
            padding: 0;
            display: flex;
          }
          &__content {
            width: 260px;
            margin-right: 20px;
          }
          &__title {
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 8px;
            color: #4c4c4c;
          }
          &__description {
            font-weight: 500;
            font-size: 11px;
            margin: 0;
            padding: 0;
            color: #707070;
            word-break: break-all;
          }
        }
      }
      &__button {
        text-align: right;
      }
    }
    & .side {
      width: 200px;
      text-decoration: none;
      &__button {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 20px;
        height: 54px;
      }
    }
    &__plan {
      padding-bottom: 80px;
    }
  }
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      width: 360px;
      padding: 60px 100px;
      background-color: white;
      border-radius: 10px;
      & .title {
        font-size: 18px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        text-align: center;
      }
      & .form {
        width: fit-content;
        margin: 0 auto;
      }
      &__text {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 40px;
      }
    }
    & .icon {
      width: 54px;
      height: 54px;
      border: 2px solid map-get($colors, "blue300");
      background: map-get($colors, "blue300");
      border-radius: 50%;
      cursor: pointer;
      &__input {
        position: relative;
        &__image {
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          & img {
            width: 54px !important;
            height: 54px !important;
            border-radius: 50%;
          }
        }
        &__btn {
          display: inline-block;
          position: relative;
          cursor: pointer;
          border-radius: 5px;
          width: 54px;
          height: 54px;
          & input[type="file"] {
            top: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            width: 54px;
            height: 54px;
            cursor: pointer;
          }
        }
      }
      &__label {
        & h4 {
          font-weight: 700;
          color: map-get($colors, "blue400");
        }
        & h3 {
          font-weight: 500;
        }
      }
    }
  }
}
.invite {
  &__dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: #40404047 0 0 no-repeat padding-box;
    opacity: 1;
    width: 100%;
    height: 100%;
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      width: 280px;
      padding: 60px 120px;
      background-color: white;
      border-radius: 10px;
      & .title {
        font-size: 18px;
        font-weight: 700;
        color: map-get($colors, "blue400");
        text-align: center;
      }
      & .form {
        width: fit-content;
        margin: 0 auto;
      }
      & .error {
        color: map-get($colors, "accent");
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
.resign {
  & .title {
    color: map-get($colors, "accent");
  }
  & a.link {
    color: map-get($colors, "primary");
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
.main {
  @include mq(xs) {
    width: 100%;
    overflow-x: scroll;
  }
  @include mq(lg) {
    width: calc(100% - 104px);
    overflow-x: unset;
  }
}
</style>
<style lang="scss">
.account-setting {
  &__content__container__account__img {
    & img {
      width: 80px !important;
      height: 80px !important;
      border-radius: 50%;
    }
  }
  &__dialog {
    & .icon {
      &__input__image {
        & img {
          width: 54px !important;
          height: 54px !important;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
