
import {computed, defineComponent, PropType, reactive, toRefs, watch} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import { useStore } from "vuex";
import {CompanyMember, MemberInvitationParamsForSetting, UserUpdateParams} from "@/types";

export default defineComponent({
  name: "TrashIcon",
  components: { BaseButton, BaseCheckbox },
  props: {
    fillColor: {
      type: String,
      default: "#707070",
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 24,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    member: {
      type: Object as PropType<CompanyMember>,
    },
    invitationMember: {
      type: Object as PropType<MemberInvitationParamsForSetting>,
    },
    id: {
      type: String,
      default: "delete-checkbox",
    },
    trashType: {
      type: String,
      validator: (value: string): boolean => {
        // The value must match one of these strings
        return ["influencer", "member"].includes(value);
      },
      default: "influencer",
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = useStore();
    const state = reactive({
      show: false,
      deleteChecked: false,
      formErrorMessage: null as string | null,
      memberId: props.member?.id,
      invitationMemberId: props.invitationMember?.id,
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
    });
    const checkSkipConfirmDelete = () => {
      if (props.trashType === "influencer") {
        return state.user?.isReadDeleteList;
      } else if (props.trashType === "member") {
        return state.user?.isReadDeleteMember;
      } else return false;
    };
    const handleConfirmDelete = () => {
      if (state.deleteChecked) {
        let params = {} as UserUpdateParams;
        if (props.trashType === "influencer") {
          params = { isReadDeleteList: state.deleteChecked };
        } else if (props.trashType === "member") {
          params = { isReadDeleteMember: state.deleteChecked };
        } else return;
        store.dispatch("users/updateUserReadOption", { params: params, userId: state.user.id });
      }
      state.show = false;
      context.emit("confirmDelete");
    };
    const clickIcon = () => {
      if (!checkSkipConfirmDelete()) {
        state.show = true;
      } else {
        handleConfirmDelete();
      }
    };
    const handleDeleteCheck = () => {
      context.emit("update:modelValue", state.deleteChecked);
    };
    return { ...toRefs(state), handleDeleteCheck, clickIcon, handleConfirmDelete };
  },
});
