
import {computed, reactive, toRefs} from "vue";

export default {
  name: "MultipleToggle",
  props: {
    items: {
      type: Array,
      default: [] as string[],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    fontSize: {
      type: String,
      default: "12px",
    },
    background: {
      type: String,
      default: "#909090",
    },
    selectedBackground: {
      type: String,
      default: "#0a6dc7",
    },
    selectable: {
      type: Boolean,
      default: true,
    }
  },
  setup(props: any, context: any) {
    const state = reactive({
      selectedIndex: props.modelValue,
      styles: computed(() => {
        return { "--width": props.width, "--font-size": props.fontSize, "--background": props.background, "--selected-background": props.selectedBackground };
      }),
    });
    const selectItem = (index: number) => {
      if (!props.selectable) return;
      state.selectedIndex = index;
      context.emit("update:modelValue", state.selectedIndex);
      context.emit("change");
    };
    return {
      ...toRefs(state),
      selectItem
    };
  },
}
