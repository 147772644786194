<template>
  <div class="add-file-button" @click="show = true">
    <div class="add-file-button__button d-flex justify-content-center">
      <h1>＋</h1>
    </div>
  </div>
  <div class="dialog-table" v-show="show">
    <div class="dialog-table__container">
      <div class="dialog-table__content">
        <div class="dialog-table__content__table">
          <div class="dialog-table__content__table__header d-flex justify-content-space-between">
            <base-checkbox v-model="allChecked" :id="id"></base-checkbox>
            <div class="d-flex">
              <h4 class="mr-20px">フォロワー</h4>
              <h4>エンゲージメント率</h4>
            </div>
          </div>
          <div class="dialog-table__content__table__body">
            <div :class="['dialog-table__content__table__body__content', 'd-flex', !item.isPredictable ? 'disabled' : '']" v-for="(item, index) in items" :key="index" :ref="setItemRefs" v-show="item.type === 0">
              <base-checkbox v-model="checked[item.id]" :id="item.id"></base-checkbox>
              <div class="dialog-table__content__table__body__thumbnail">
                <img :src="item.picture" />
              </div>
              <div class="dialog-table__content__table__body__sns">
                <instagram-icon v-if="item.type === 0"></instagram-icon>
                <youtube-icon v-else-if="item.type === 1"></youtube-icon>
                <tiktok-icon v-else-if="item.type === 2"></tiktok-icon>
              </div>
              <div class="dialog-table__content__table__body__influencer-name">
                <h4>{{ item.fullname }}</h4>
                <h6>{{ item.username }}</h6>
              </div>
              <div class="d-flex dialog-table__content__table__right align-items-center">
                <div class="dialog-table__content__table__body__gender">
                  <h4>{{ item.gender }}</h4>
                </div>
                <div class="dialog-table__content__table__body__follower">
                  <h3>{{ item.followers.toLocaleString() }}</h3>
                </div>
                <div class="dialog-table__content__table__body__engagement">
                  <h3>{{ String((item.engagements / item.followers) * 100).slice(0, 4) }}%</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-table__content__button d-flex justify-content-center">
          <base-button color="dark" class="mr-20px" @click="show = false">キャンセル</base-button>
          <base-button @click="addInfluencers">追加する</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch, onBeforeUpdate, onMounted } from "vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "AddCandidateButton",
  components: { BaseCheckbox, InstagramIcon, YoutubeIcon, TiktokIcon, BaseButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "checkbox",
    },
    modelValue: {
      type: Array as () => any[],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: false,
      allChecked: false,
      checked: {} as any,
    });
    let itemRefs = [] as any[];
    const setItemRefs = (el: any) => {
      itemRefs.push(el);
    };
    onMounted(() => {
      props.items.map((item: any, index: number) => {
        if (props.modelValue && props.modelValue.length && item.id) {
          const selectedItems = props.modelValue.map((selectedItem: any) => selectedItem.id);
          state.checked[item.id] = selectedItems.includes(item.id);
          if (state.checked[item.id]) itemRefs[index].children[0].click();
        } else {
          state.checked[item.id] = false;
        }
      });
    });
    onBeforeUpdate(() => {
      itemRefs = [];
    });
    let allChecked = computed(() => state.allChecked);
    watch(allChecked, (val: boolean) => {
      // header のチェックボックスが選択されたら全選択、外れたら全部選択を外す
      props.items.map((item: any, index: number) => {
        if (state.checked[item.id] !== val) itemRefs[index].children[0].click();
      });
    });
    const addInfluencers = () => {
      const selectedItems = [] as any[];
      props.items.map((item: any, index: number) => {
        if (state.checked[item.id]) selectedItems.push(item);
      });
      context.emit("update:modelValue", selectedItems);
      state.show = false;
    };
    return { ...toRefs(state), addInfluencers, itemRefs, setItemRefs };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.add-file-button {
  &__button {
    width: 24px;
    padding: 4px 18px;
    background: white;
    border: 2px solid map-get($colors, "primary");
    border-radius: 5px;
    cursor: pointer;
    & h1 {
      font-size: 36px;
      font-weight: 900;
      color: map-get($colors, "primary");
    }
  }
}
.dialog-table {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: #40404047 0 0 no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 100%;
  &__container {
    position: relative;
  }
  &__content {
    position: absolute;
    width: 800px;
    top: 0;
    left: 50%;
    height: calc(100vh - 200px);
    transform: translate(-50%, 20%);
    -webkit-transform: translate(-50%, 20%);
    -ms-transform: translate(-50%, 20%);
    @include mq(xs) {
      width: 95vw;
    }
    @include mq(lg) {
      width: 800px;
    }
    &__table {
      &__right {
        margin-left: auto;
        margin-right: 0;
        align-items: center;
      }
      &__header {
        padding: 20px 45px 20px 20px;
        border-radius: 10px 10px 0 0;
        background: map-get($colors, "blue300");
        & h4 {
          color: white;
          font-weight: 700;
        }
        &__gender {
          width: 56px;
          text-align: center;
        }
        &__follower {
          width: 80px;
          text-align: right;
          margin: 0 20px;
        }
        &__engagement {
          width: 100px;
          text-align: right;
          margin: 0 20px;
        }
        &__post-date {
          width: 90px;
          margin: 0 20px;
          text-align: left;
        }
      }
      &__body {
        height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: white;
        &::-webkit-scrollbar {
          width: 10px;
          display: block !important;
        }
        &::-webkit-scrollbar-track {
          background-color: white;
        }
        &::-webkit-scrollbar-thumb {
          background-color: map-get($colors, "primary");
          border-radius: 10px;
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
        }
        &__content {
          padding: 20px;
          background-color: white;
          align-items: center;
          position: relative;
          &.disabled::after {
            content: "このユーザーは予測できません。";
            text-align: center;
            font-weight: 700;
            color: white;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
          }
        }
        &__thumbnail {
          & img {
            width: 54px;
            height: 54px;
            border-radius: 50%;
          }
        }
        &__sns {
          margin: 0 10px;
        }
        &__influencer-name {
          width: 280px;
          & h4 {
            font-weight: 900;
          }
        }
        &__gender {
          width: 56px;
          text-align: center;
        }
        &__follower {
          width: 100px;
          text-align: right;
          margin: 0 10px;
        }
        &__engagement {
          width: 110px;
          text-align: right;
          margin: 0 20px;
        }
        &__post-date {
          width: 90px;
          margin: 0 20px;
          & h4 {
            text-align: left;
          }
        }
      }
    }
    &__button {
      width: 760px;
      height: fit-content;
      padding: 20px;
      background: white;
      border-radius: 0 0 10px 10px;
      @include mq(xs) {
        width: calc(95vw - 40px);
      }
      @include mq(lg) {
        width: 760px;
      }
    }
  }
}
</style>
<style lang="scss">
.dialog-table__content__table__body__content.disabled {
  & label,
  input {
    visibility: hidden !important;
  }
}
</style>
