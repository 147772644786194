<template>
  <div class="base-container-white">
    <div class="delete-user">
      <div class="delete-user__content">
        <img src="@/assets/img/logo-wrap.svg">
        <div class="mt-60px">
          <h1>INFRECTのご利用ありがとうございました。</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "DeleteUser",
};
</script>

<style lang="scss" scoped>
.delete-user {
  & header {
    width: calc(100% - 48px);
    height: 36px;
    padding: 12px 24px;
    background: map-get($colors, "blue300");
    border-radius: 10px;
    align-items: center;
  }
  &__content {
    margin-top: 300px;
    text-align: center;
    & h1 {
      color: map-get($colors, "blue400");
    }
  }
}
</style>
