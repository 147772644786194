<template>
  <div :class="['header', enableBorder ? 'enable-border' : null]">
    <div class="header__inner d-flex">
      <div class="header__inner__title align-self-center">
        <h2>{{ headerTitle }}</h2>
      </div>
      <div class="header__inner__icons">
        <plan-banner :display-type="displayType" @click="moveToPlan" />
        <div class="header__inner__icon faq">
          <router-link :to="{ name: 'FAQ' }" target="_blank" rel="noreferrer noopener" class="d-flex">
            <question-icon />
          </router-link>
        </div>
        <div class="header__inner__icon">
          <NotificationIcon @click="toggleNotification" v-click-outside="onClickNotificationOutside" :news-count="notifications.length ? newCount(notifications) : 0" :key="newCount(notifications)" />
        </div>
        <transition>
          <div class="notification-container" v-show="show.notification && notifications" v-if="!notifications.length">
            <div class="d-flex notification-container__title">
              <h4>お知らせはありません</h4>
            </div>
          </div>
          <div class="notification-container" v-show="show.notification && notifications" v-else>
            <div class="d-flex notification-container__title">
              <h4>お知らせがあります</h4>
              <div class="notification-container__new-badge" v-if="newCount(notifications)">{{ newCount(notifications) }} new</div>
            </div>
            <div class="notification-container__contents">
              <div class="notification-container__content" v-for="notification in viewableNotifications()" :key="notification.id">
                <div class="notification-container__content__header">
                  <div :class="['notification-container__content__header__badge', notification.isRead ? 'is-read' : 'new']">
                    {{ notification.isRead ? "Read" : "New" }}
                  </div>
                  <div class="notification-container__content__header__datetime">
                    {{ formatDate(notification.notification.createdAt) }}
                  </div>
                </div>
                <a :class="['notification-container__content__message', notification.isRead ? 'is-read' : 'new']" :href="notification.notification.url ? notification.notification.url : '#'">
                  {{ notification.notification.message }}
                </a>
              </div>
            </div>
            <div class="view-more" @click.prevent="viewMore" v-show="show.viewMore" ref="ViewMore">View more</div>
          </div>
        </transition>
        <div class="header__inner__icon" @click="show.profile = !show.profile" v-click-outside="onClickProfileOutside">
          <ProfileIcon :src="user?.logo" />
        </div>
        <transition>
          <div class="profile-container" v-show="show.profile">
            <div class="profile-container__name">
              <h4>{{ user?.username }}</h4>
              <h5>{{ user?.email }}</h5>
            </div>
            <div class="profile-container__company">
              <h5>{{ company?.name }}</h5>
              <h5>{{ company?.department }}</h5>
            </div>
            <div class="profile-container__item" @click="moveToEditProfile">アカウント設定</div>
            <router-link :to="{ name: 'Contact' }" target="_blank" rel="noreferrer noopener">
              <div class="profile-container__item">お問い合わせ</div>
            </router-link>
            <div class="profile-container__item" @click="logOut">ログアウトする</div>
          </div>
        </transition>
        <transition>
          <hamburger-menu class="hamburger-menu" :links="links" :background-color="'#79B5DB'"></hamburger-menu>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import HamburgerMenu from "@/components/HamburgerMenu.vue";
import QuestionIcon from "@/components/icon/QuestionIcon.vue";
import NotificationIcon from "@/components/icon/Notification.vue";
import ProfileIcon from "@/components/icon/ProfileIcon.vue";
import PlanBanner from "@/components/PlanBanner.vue";
import { useStore } from "vuex";
import { formatDate } from "@/plugins/formatter";
import vClickOutside from "click-outside-vue3";
import {HamburgerMenuItem, UserNotification} from "@/types";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BaseHeader",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { QuestionIcon, NotificationIcon, ProfileIcon, PlanBanner, HamburgerMenu },
  props: {
    headerTitle: {
      type: String,
      default: "インフルエンサーを探す",
    },
    displayType: {
      type: String,
      default: null,
    },
    enableBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    store.dispatch("users/loadCurrentUser");
    store.dispatch("notifications/getNotifications");
    const state = reactive({
      show: {
        notification: false,
        profile: false,
        viewMore: true,
      },
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      notifications: computed(() => store.state.notifications.notifications),
      notificationLoading: computed(() => store.state.notifications.loading),
      updateNotificationSuccess: computed(() => store.state.notifications.updateNotificationSuccess),
      notificationViewables: 3,
    });
    const ViewMore = ref<HTMLImageElement>();
    const links: HamburgerMenuItem[] = [
      {
        name: "ホーム",
        link: { name: "Home" },
      },
      {
        name: "インフルエンサーを探す",
        link: { name: "SearchHome" },
      },
      {
        name: "名前・IDで検索",
        isSubItem: true,
        link: { name: "IDSearch" },
      },
      {
        name: "詳細検索",
        isSubItem: true,
        link: { name: "AdvancedSearch" },
      },
      {
        name: "ハッシュタグ検索",
        isSubItem: true,
        link: { name: "HashtagSearch" },
      },
      {
        name: "AI検索",
        isSubItem: true,
        link: { name: "AISearch" },
      },
      {
        name: "ビジネスクリエーション",
        link: { name: "Listing" },
      },
      {
        name: "インタビュー",
        isSubItem: true,
        link: { name: "Listing", query: { type: 0 } },
      },
      {
        name: "コラボレーション",
        isSubItem: true,
        link: { name: "Listing", query: { type: 1 } },
      },
      {
        name: "PR投稿",
        isSubItem: true,
        link: { name: "Listing", query: { type: 2 } },
      },
      {
        name: "その他のお仕事",
        isSubItem: true,
        link: { name: "Listing", query: { type: 3 } },
      },
      {
        name: "効果を予測する",
        link: { name: "AdPrediction" },
      },
      {
        name: "案件を管理する",
        link: { name: "TaskManage" },
      },
      {
        name: "インフルエンサーリスト",
        link: { name: "InfluencerList" },
      },
      {
        name: "よくある質問",
        link: { name: "Faq" },
      },
    ];
    const logOut = () => {
      store.dispatch("users/logOut");
    };
    const onClickProfileOutside = (event: any) => {
      if (event.target !== ProfileIcon.value) state.show.profile = false;
    };
    const onClickNotificationOutside = (event: any) => {
      if (event.target !== NotificationIcon.value && event.target !== ViewMore.value) {
        beforeCloseNotification();
        state.show.notification = false;
      }
    };
    const beforeCloseNotification = () => {
      if (state.show.notification) {
        // 閉じる前に更新
        const currNotifications = viewableNotifications();
        store.dispatch(
          "notifications/updateNotifications",
          currNotifications.map((x: UserNotification) => x.id)
        );
        store.dispatch("notifications/getNotifications");
        state.notificationViewables = 3;
        state.show.viewMore = true;
      }
    };
    const toggleNotification = () => {
      beforeCloseNotification();
      state.show.notification = !state.show.notification;
      store.dispatch("notifications/getNotifications");
    };
    const viewableNotifications = () => {
      return state.notifications.slice(0, state.notificationViewables);
    };
    const viewMore = () => {
      if (state.notifications.length >= state.notificationViewables + 3) {
        state.notificationViewables += 3;
      } else {
        state.notificationViewables = state.notifications.length;
        state.show.viewMore = false;
      }
    };
    const newCount = () => {
      if (!state.notifications) return 0;
      const newArr = state.notifications.filter((x: UserNotification) => !x.isRead);
      return newArr.length;
    };
    let updateNotificationSuccess = computed(() => state.updateNotificationSuccess);
    watch(updateNotificationSuccess, (val) => {
      if (val) store.dispatch("notifications/getNotifications");
    });
    const moveToPlan = () => {
      window.location.href = "/account-setting?type=plans";
    };
    const moveToEditProfile = () => {
      window.location.href = "/account-setting?type=general";
    };
    const moveToContact = () => {
      router.push({ name: "Contact" });
    };
    return {
      ...toRefs(state),
      links,
      logOut,
      onClickProfileOutside,
      onClickNotificationOutside,
      toggleNotification,
      viewableNotifications,
      formatDate,
      viewMore,
      ViewMore,
      newCount,
      moveToPlan,
      moveToEditProfile,
      moveToContact,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.header {
  width: 100%;
  height: 60px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  &.enable-border {
    border: solid 2px map-get($font-colors, "blue");
  }
  &__inner {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    &__title {
      padding-left: 20px;
      & h2 {
        font-weight: 900;
        color: map-get($font-colors, "blue");
        @include mq(xs) {
          font-size: 14px;
        }
        @include mq(lg) {
          font-size: unset;
        }
      }
    }
    &__icons {
      display: flex;
      align-items: center;
    }
    &__icon {
      display: flex;
      align-items: center;
      margin: 0 20px;
      cursor: pointer;
      @include mq(xs) {
        margin: 0 10px;
      }
      @include mq(lg) {
        margin: 0 20px;
      }
      &.faq {
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: flex;
        }
      }
    }
    & .plan-banner {
      margin-right: 10px;
      transition: 0.3s;
      display: flex;
      @include mq(xs) {
        display: none;
      }
      @include mq(lg) {
        display: flex;
      }
      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
    }
  }
}
.notification-container {
  position: absolute;
  top: 70px;
  right: 0;
  width: 360px;
  height: fit-content;
  max-height: 60vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 10px;
  border: solid 2px map-get($colors, "primary");
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-right: 0;
  margin-left: auto;
  margin-top: 10px;
  z-index: 3;
  cursor: pointer;
  @include mq(xs) {
    padding: 10px;
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
  }
  @include mq(lg) {
    padding: 20px;
    width: 360px;
    margin-right: 0;
    margin-left: auto;
  }
  &__title {
    justify-content: space-between;
    align-items: baseline;
    & h4 {
      color: map-get($font-colors, "blue");
      font-weight: 700;
    }
    & .new-icon {
      width: 48px;
      background: map-get($colors, "blue300");
      border: none;
      border-radius: 24px;
      align-self: center;
      padding: 2px 8px;
      & h6 {
        color: white;
      }
    }
  }
  &__new-badge {
    color: white;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 20px;
    background-color: #f75977;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    align-content: baseline;
  }
  &__contents {
    margin: 20px 0;
    width: 100%;
    padding: 0;
  }
  &__content {
    width: 100%;
    font-weight: 500;
    padding: 10px 0;
    border-bottom: solid 1px #f1f1f1;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: end;
      &__badge {
        width: 40px;
        padding: 4px;
        text-align: center;
        font-size: 12px;
        color: white;
        font-weight: 700;
        &.new {
          background-color: #f75977;
        }
        &.is-read {
          background-color: #707070;
        }
      }
      &__datetime {
        font-size: 10px;
        font-weight: 500;
        color: #707070;
      }
    }
    &__message {
      display: block;
      width: 100%;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      margin: 8px 0;
      color: map-get($font-colors, "blue");
      word-break: break-all;
      &.is-read {
        color: #707070;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  & .view-more {
    text-align: center;
    width: 100%;
    padding: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #707070;
    box-sizing: border-box;
    &:hover {
      text-decoration: underline;
    }
  }
}
.profile-container {
  position: absolute;
  top: 70px;
  right: 0;
  width: 240px;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-right: 0;
  margin-left: auto;
  margin-top: 10px;
  z-index: 3;
  cursor: pointer;
  word-break: break-all;
  border: solid 2px map-get($colors, "primary");
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  @include mq(xs) {
    padding: 10px;
    width: 90vw;
    margin-right: auto;
    margin-left: auto;
  }
  @include mq(lg) {
    padding: 20px;
    width: 360px;
    margin-right: 0;
    margin-left: auto;
  }
  &__name {
    margin-bottom: 6px;
    & h4 {
      color: map-get($font-colors, "blue");
      font-weight: 700;
    }
  }
  &__company {
    & h5 {
      color: map-get($colors, "gray300");
    }
  }
  &__item {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    color: map-get($font-colors, "blue");
    font-size: 14px;
    font-weight: 700;
    border-top: solid 1px #f1f1f1;
    &:hover {
      text-decoration: underline;
    }
  }
  & a {
    color: map-get($font-colors, "blue");
    text-decoration: none;
  }
}
.hamburger-menu {
  display: none;
  @include mq(xs) {
    display: block;
    margin-right: 20px;
  }
  @include mq(lg) {
    display: none;
    margin-right: 0;
  }
}
.v-leave-active,
.v-enter-active {
  transition: opacity 0.1s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
