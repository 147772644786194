<template>
  <div class="base-container__white" v-show="!showSuccess">
    <div class="sign-up">
      <img src="@/assets/img/logo-wrap.svg" alt="INFRECTのロゴ" />
      <base-text-field placeholder="パスワード" :type="'password'" width="360" class="mt-60px" v-model="password"></base-text-field>
      <base-text-field placeholder="パスワード確認用" :type="'password'" width="360" class="mt-20px" v-model="password2" @keydown.enter="judgementCreateMemberData"></base-text-field>
      <div class="sign-up-error mt-20px">
        <span v-if="formErrorMessage">{{ formErrorMessage }}</span>
        <span v-else-if="errorMessage?.password && Array.isArray(errorMessage?.password) && errorMessage?.password[0]">{{ errorMessage?.password[0]?.message }}</span>
        <span v-else>{{ errorMessage?.message }}</span>
      </div>
      <div class="mt-60px">
        <h4>INFRECT<router-link :to="{ name: 'Terms' }" target="_blank" rel="noreferrer noopener">利用規約・プライバシーポリシー</router-link>に同意して新規登録する</h4>
      </div>
      <div class="mt-20px">
        <base-button size="wide" @click="createMemberData">新規登録</base-button>
      </div>
      <div class="mt-60px">
        <h4>すでにアカウントをお持ちの方は、<router-link :to="{ name: 'SignIn' }">ログイン</router-link></h4>
      </div>
    </div>
  </div>
  <div class="base-container__white" v-show="showSuccess">
    <div class="sign-up-success">
      <img src="@/assets/img/logo-wrap.svg" alt="INFRECTのロゴ" />
      <div class="mt-60px">
        <h2>ご登録ありがとうございます。</h2>
      </div>
      <div class="mt-40px">
        <h3>
          ご登録が完了いたしました。<br />
          ログイン後、INFRECTがご利用いただけます。
        </h3>
      </div>
      <div class="mt-40px">
        <router-link :to="{ name: 'SignIn' }">
          <base-button>ログイン</base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MemberCreateParams } from "@/types";

export default defineComponent({
  name: "MemberVerifyEmail",
  components: { BaseTextField, BaseButton },
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      errorMessage: computed(() => store.state.members.errorMessage),
      createMemberSuccess: computed(() => store.state.members.createMemberSuccess),
      password: null as string | null,
      password2: null as string | null,
      formErrorMessage: null as string | null,
      verificationCode: route.params.invitation_code,
      showSuccess: false,
    });
    const createMemberData = () => {
      state.formErrorMessage = null;
      if (!state.password) {
        state.formErrorMessage = "パスワードは必須です";
      } else if (!state.password2) {
        state.formErrorMessage = "確認用パスワードは必須です";
      } else if (state.password !== state.password2) {
        state.formErrorMessage = "パスワードと確認用パスワードが違います";
      } else {
        const params: MemberCreateParams = {
          password: state.password,
          verificationCode: state.verificationCode,
        };
        store.dispatch("members/createMember", params);
      }
    };
    let createMemberSuccess = computed(() => state.createMemberSuccess);
    watch(createMemberSuccess, (val: boolean) => {
      if (val) state.showSuccess = true;
    });
    const judgementCreateMemberData = () => {
      if (state.password && state.password2) {
        createMemberData();
      }
    };
    return { ...toRefs(state), createMemberData, judgementCreateMemberData };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.sign-up {
  text-align: center;
  padding-top: 120px;
  &-error {
    color: map-get($colors, "accent");
    font-size: 12px;
    font-weight: 500;
  }
  &-success {
    text-align: center;
    padding-top: 120px;

    & h2 {
      color: map-get($colors, "blue400");
    }

    & img {
      width: 160px;
      height: auto;
    }
  }
}
</style>
