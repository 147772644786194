
import { defineComponent, reactive, computed, toRefs, ref, watch } from "vue";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { useStore } from "vuex";
import { Plan } from "@/types";

export default defineComponent({
  name: "BaseStripeCheckout",
  components: { StripeCheckout },
  props: {
    planId: {
      type: Number,
      default: 7,
    },
  },
  setup(props, context) {
    const store = useStore();
    store.dispatch("plans/getSessionId", props.planId);
    interface LineItem {
      price: string;
      quantity: number;
    }
    const state = reactive({
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading: false,
      loadSessionIdSuccess: computed(() => store.state.plans.loadSessionIdSuccess),
      sessionId: computed(() => store.state.plans.sessionId),
    });
    const checkoutRef = ref<StripeCheckout>();
    const submit = () => {
      checkoutRef.value?.redirectToCheckout();
    };
    return {
      ...toRefs(state),
      checkoutRef,
      submit,
    };
  },
});
