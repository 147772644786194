import axios from "@/store/axios";
import { Commit } from "vuex";
import { Invoice } from "@/types";

interface State {
  errorMessage: string | null;
  invoices: Invoice[];
  loading: boolean;
  success: boolean;
}

const state: State = {
  errorMessage: null,
  invoices: [],
  loading: false,
  success: false,
};

const invoices = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string) {
      state.errorMessage = errorMessage;
    },
    setInvoices(state: State, invoices: Invoice[]) {
      state.invoices = invoices;
    },
    setSuccess(state: State, flag: boolean) {
      state.success = flag;
    },
    setLoading(state: State, flag: boolean) {
      state.loading = flag;
    },
  },
  actions: {
    async getCompanyInvoices({ commit }: { commit: Commit }) {
      commit("setSuccess", false);
      commit("setLoading", true);
      try {
        const res = await axios.get("api/v1/companies/invoices/");
        commit("setInvoices", res.data);
        commit("setSuccess", true);
        commit("setLoading", false);
      } catch (err) {
        commit("setLoading", false);
      }
    },
  },
};

export default invoices