<template>
  <div class="base-container">
    <sidebar :current-tab="1"></sidebar>
    <div class="main">
      <base-header display-type="SEARCH" :key="searchUsernameSuccess"></base-header>
      <div class="dialog__container" v-if="loading || errorMessage  && !company?.isExpired">
        <div class="dialog" v-show="loading">
          <div class="title">検索中です</div>
          <div class="loading-box"><loading></loading></div>
          <div class="description"><h4>表示されるまでしばらくお待ちください</h4></div>
        </div>
        <div class="dialog" v-show="errorMessage">
          <div class="title">データの取得に失敗しました</div>
          <div class="description"><exclamation-mark></exclamation-mark></div>
          <div class="description">
            <h4>検索回数は消費されません。<br />お手数をおかけしますが、ページをリロードしてください。</h4>
          </div>
          <div class="description"><base-button size="short" color="dark" @click="closeLoadingDialog">戻る</base-button></div>
        </div>
      </div>
      <div v-if="company?.isExpired === false">
        <div class="search">
          <div class="search__contents">
            <h1>インフルエンサーの名前・IDで検索</h1>
            <div class="search__contents__buttons d-flex">
              <base-button color="blue" type="toggle" :selected="currentSearchTab === 'instagram'" @click="setCurrentSearchTab('instagram')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><instagram-icon :size="20" fill-color="white" class="mr-5px" /><span>Instagram</span></div>
              </base-button>
              <base-button color="white" type="toggle" :selected="currentSearchTab === 'youtube'" @click="setCurrentSearchTab('youtube')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><youtube-icon fill-color="white" class="mr-5px" /><span>YouTube</span></div>
              </base-button>
              <base-button color="white" type="toggle" :selected="currentSearchTab === 'tiktok'" @click="setCurrentSearchTab('tiktok')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><tiktok-icon :size="24" fill-color="white" class="mr-5px" /><span>TikTok</span></div>
              </base-button>
              <base-button color="white" type="toggle" :selected="currentSearchTab === 'twitter'" @click="setCurrentSearchTab('twitter')" class="d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center"><twitter-icon :size="24" fill-color="white" class="mr-5px" /><span>Twitter</span></div>
              </base-button>
            </div>
            <div class="search__contents__area d-flex justify-content-center">
              <input type="search" placeholder="名前やIDを入力" v-model="username" @keydown.enter="searchUsername" />
              <div class="search__contents__area__button d-flex align-items-center justify-content-center" @click="searchUsername">
                <search-icon />
              </div>
            </div>
            <div class="search__contents__area__error mt-20px" v-show="formErrorMessage || errorMessage">{{ formErrorMessage }}{{ errorMessage }}</div>
          </div>
        </div>
        <div class="search-result" v-if="total || total === 0">
          <div class="d-flex mt-40px mb-10px align-items-end">
            <h3 class="mr-10px">検索結果</h3>
            <h4>{{ total?.toLocaleString() }} インフルエンサー</h4>
          </div>
          <base-table v-if="total" :items="influencers" />
          <div class="result-none" v-else>
            <h1 class="mb-30px">
              該当するインフルエンサーは<br />
              見つかりませんでした。
            </h1>
            <h3>検索条件を変更し検索をしてください。</h3>
          </div>
          <base-pagination :total="total" :total-pages="totalPages" :currentPage="currentPage" @move="submitPages"></base-pagination>
        </div>
      </div>
      <div class="expired" v-else>
        <div class="expired__title">トライアルプランの有効期限が切れています。</div>
        <div class="expired__description">サービスをご利用いただくにはプランを変更してください。</div>
        <router-link :to="{ name: 'AccountSetting', query: { type: 'plans' } }">
          <base-button color="success" size="wide" class="mt-40px"> プランを変更する </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import BaseHeader from "@/components/BaseHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import SearchIcon from "@/components/icon/SearchIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useStore } from "vuex";
import { SearchUsernameInfluencerParams, ClickEvent } from "@/types";
import BaseTable from "@/components/BaseTable.vue";
import BasePagination from "@/components/BasePagination.vue";
import Loading from "@/components/Loading.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import { set } from "vue-gtag";

export default defineComponent({
  name: "IDSearch",
  components: {
    BaseTable,
    Sidebar,
    BaseHeader,
    SearchIcon,
    BaseButton,
    BasePagination,
    Loading,
    ExclamationMark,
    TiktokIcon,
    TwitterIcon,
    InstagramIcon,
    YoutubeIcon,
  },
  setup: function () {
    const store = useStore();
    store.dispatch("influencers/getCompaniesList");
    store.dispatch("influencers/usernameSearchInitialize");
    const state = reactive({
      currentSearchTab: "instagram",
      user: computed(() => store.state.users.user),
      company: computed(() => store.state.companies.company),
      loading: computed(() => store.state.influencers.loading),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      success: computed(() => store.state.influencers.usernameSearchSuccess),
      total: computed(() => store.state.influencers.total),
      totalPages: computed(() => store.state.influencers.totalPages),
      currentPage: computed(() => store.state.influencers.currentPage),
      formErrorMessage: null as string | null,
      username: null as string | null,
      type: null as number | null,
      influencers: computed(() => store.state.influencers.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      searchUsernameSuccess: computed(() => store.state.influencers.usernameSearchSuccess),
    });
    set({userId: state.user?.id});
    const setCurrentSearchTab = (index: string) => {
      state.currentSearchTab = index;
    };
    const searchUsername = (event: ClickEvent) => {
      state.formErrorMessage = null;
      if (event.keyCode !== 229) {
        submitUsernameSearch(1);
      }
    };
    const submitUsernameSearch = (page: number) => {
      if (!state.username) {
        state.formErrorMessage = "名前やIDの入力は必須です";
      } else {
        store.dispatch("influencers/simpleSearchInitialize");
        const params: SearchUsernameInfluencerParams = {
          username: state.username,
          snsType: state.currentSearchTab,
          page: page,
        };
        store.dispatch("influencers/searchUsername", params);
      }
    };
    const submitPages = (page: number) => {
      return submitUsernameSearch(page);
    };
    let searchUsernameSuccess = computed(() => state.searchUsernameSuccess);
    watch(searchUsernameSuccess, (val: boolean) => {
      store.dispatch("users/loadCurrentUser");
    });
    const closeLoadingDialog = () => {
      window.location.reload();
    };
    return {
      ...toRefs(state),
      setCurrentSearchTab,
      searchUsername,
      closeLoadingDialog,
      submitPages,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/img/id-search-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 24px;
  text-align: center;
  &__contents {
    padding: 50px 0;
    @include mq(xs) {
      padding: 40px 0 60px 0;
    }
    @include mq(lg) {
      padding: 50px 0;
    }
    & h1 {
      color: white;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 40px;
      @include mq(xs) {
        font-size: 18px;
        margin-bottom: 20px;
      }
      @include mq(lg) {
        font-size: 24px;
        margin-bottom: 40px;
      }
    }
    &__buttons {
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;
      & svg {
        margin-top: 2px;
      }
      & .button {
        width: 140px;
        @include mq(xs) {
          width: 60px;
        }
        @include mq(lg) {
          width: 140px;
        }
      }
      & span {
        display: inline-block;
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: inline-block;
        }
      }
    }
    &__area {
      & input {
        width: 580px;
        height: 60px;
        border: 2px solid white;
        border-radius: 5px 0 0 5px;
        font-size: 18px;
        font-weight: 700;
        color: map-get($font-colors, "default");
        padding: 0 20px;
        @include mq(xs) {
          width: 90%;
          font-size: 14px;
        }
        @include mq(lg) {
          width: 580px;
          font-size: 18px;
        }
        &:focus {
          border: 2px solid map-get($colors, "accent");
          outline: none;
        }
        &::placeholder {
          color: map-get($colors, "gray200");
          @include mq(xs) {
            font-size: 14px;
          }
          @include mq(lg) {
            font-size: 18px;
          }
        }
      }
      &__button {
        width: 60px;
        height: 60px;
        background-color: #ee7382;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
        &:hover {
          background-color: #efabb4;
        }
      }
      &__error {
        font-size: 14px;
        font-weight: 700;
        color: white;
      }
    }
  }
}
.result-none {
  width: 100%;
  background: white;
  padding: 120px 0;
  border-radius: 10px;
  height: fit-content;
  text-align: center;
  align-items: center;
  @include mq(xs) {
    padding: 60px 0;
    margin-bottom: 40px;
  }
  @include mq(lg) {
    padding: 120px 0;
    margin-bottom: auto;
  }
  & h1,
  h3 {
    color: map-get($colors, "primary");
  }
}
.dialog {
  width: 400px;
  padding: 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90%;
    padding: 40px 0;
    margin: 20px auto;
    border: solid 2px map-get($colors, "primary");
  }
  @include mq(lg) {
    width: 400px;
    padding: 100px;
    margin: 100px auto;
    border: unset;
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .loading-box {
    width: fit-content;
    margin: 20px auto;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
