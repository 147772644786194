
import { computed, defineComponent, ref, reactive, toRefs } from "vue";

export default defineComponent({
  name: "BaseTextArea",
  props: {
    label: {
      type: String,
      default: "ラベル",
    },
    width: {
      type: Number,
      default: 480,
    },
    height: {
      type: Number,
      default: 80,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Number,
      default: 6,
    },
    cols: {
      type: Number,
      default: 40,
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    modelValue: {
      type: String as () => string | null,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const textArea = ref<HTMLTextAreaElement>();
    const state = reactive({
      text: props.modelValue,
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--width": props.width,
        "--height": props.height,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
      };
    });
    const handleText = () => {
      context.emit("update:modelValue", state.text);
    };
    return {
      ...toRefs(state),
      styles,
      handleText,
      textArea,
    };
  },
});
