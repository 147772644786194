
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfileIcon",
  props: {
    fillColor: {
      type: String,
      default: "#79b5db",
    },
    size: {
      type: Number,
      default: 36,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String as () => string | null,
      default: null,
    },
  },
});
