<template>
  <div class="base-container__gray">
    <header>
      <router-link :to="{ name: 'TOP' }">
        <img src="@/assets/img/logo-blue.svg" alt="INFRECT for marketing" />
      </router-link>
    </header>
    <div class="contact">
      <div class="contact__title__container">
        <div class="contact__title__small">Trial</div>
        <div class="contact__title">無料トライアルお申込み</div>
        <div class="contact__title__description">
          入力頂いたアドレス宛に、弊社より新規登録のURLが記載されたメールを送らせて頂きます。<br />
          7日間の無料トライアルで実際に触ってお試しください。
        </div>
      </div>
      <div class="contact__main__container">
        <div class="contact__main__form" v-if="!success">
          <div class="error large" v-if="formError?.companyName || errorMessage?.companyName || formError?.departmentName || errorMessage?.departmentName || formError?.name || errorMessage?.name || formError?.email || errorMessage?.email || formError?.phone || errorMessage?.phone || formError?.content || errorMessage?.content || formError?.check">
            入力エラーがあります
          </div>
          <BaseTextField label="会社名" placeholder="ゼロスタ株式会社" v-model="form.companyName" required></BaseTextField>
          <div class="error" v-if="formError?.companyName || errorMessage?.companyName">
            {{ formError?.companyName || errorMessage?.companyName?.join(" ") }}
          </div>
          <div class="spacer"></div>
          <BaseTextField label="部署名" placeholder="マーケティング部" v-model="form.departmentName"></BaseTextField>
          <div class="error" v-if="formError?.departmentName || errorMessage?.departmentName">
            {{ formError?.departmentName || errorMessage?.departmentName?.join(" ") }}
          </div>
          <div class="spacer"></div>
          <BaseTextField label="お名前" placeholder="田中太郎" v-model="form.name" required></BaseTextField>
          <div class="error" v-if="formError?.name || errorMessage?.name">
            {{ formError?.name || errorMessage?.name?.join(" ") }}
          </div>
          <div class="spacer"></div>
          <BaseTextField label="メールアドレス" placeholder="example@infrect.com" v-model="form.email" required></BaseTextField>
          <div class="error" v-if="formError?.email || errorMessage?.email">
            {{ formError?.email || errorMessage?.email?.join(" ") }}
          </div>
          <div class="spacer"></div>
          <BaseTextField label="電話番号" placeholder="090-0000-0000" v-model="form.phone"></BaseTextField>
          <div class="error" v-if="formError?.phone || errorMessage?.phone">
            {{ formError?.phone || errorMessage?.phone?.join(" ") }}
          </div>
          <div class="spacer__lg"></div>
          <div class="checkbox__container">
            <BaseCheckbox v-model="form.check"><router-link :to="{ name: 'Terms' }" target="_blank" rel="noreferrer noopener">INFRECT利用規約・プライバシーポリシー</router-link><span>に同意する</span></BaseCheckbox>
            <div class="error" v-if="formError?.check">{{ formError?.check }}</div>
            <BaseButton @click="submit()">この内容で送信する</BaseButton>
          </div>
          <div class="contact__main__form__attention">
            ※フリーメールドメインや悪戯、競合他社様のご登録はお断りしております。<br />
            弊社が上記だと判断した場合は、登録用のメールが届かない場合がありますのでご容赦ください。
          </div>
        </div>
        <div class="contact__main__success" v-else>
          <div class="contact__main__success__title">お申し込みが完了しました</div>
          <check-mark></check-mark>
          <div class="contact__main__success__description">
            お申し込み頂きありがとうございます。<br />
            確認のため、自動返信メールをお送りいたします。
          </div>
          <BaseButton @click="closeWindow">閉じる</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ComputedRef } from "vue";
import { useStore } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseButton from "@/components/BaseButton.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import { Inquiry, InquiryError, ComboBoxItem } from "@/types";

export default defineComponent({
  name: "Trial",
  components: { BaseTextField, BaseCheckbox, BaseButton, CheckMark },
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: false as boolean,
      },
      formError: {
        companyName: null as string | null,
        departmentName: null as string | null,
        name: null as string | null,
        email: null as string | null,
        phone: null as string | number | null,
        title: null as string | null,
        content: null as string | null,
        check: null as string | null,
      },
      errorMessage: computed(() => store.state.inquiries.errorMessage) as ComputedRef<InquiryError>,
      success: computed(() => store.state.inquiries.success) as ComputedRef<boolean>,
    });
    const contactTypes: string[] = ["新規登録およびトライアルについて", "資料請求", "インフルエンサーの追加希望", "サービスに関する相談", "その他"];
    const selectItems: ComboBoxItem[] = contactTypes.map((x: string) => {
      return {
        text: x,
        value: x,
      };
    });
    const getContentPlaceHolder = () => {
      if (!state.form) return "お問い合わせ内容の詳細をこちらにご記入ください";
      if (state.form.title === "インフルエンサーの追加希望") return "対応を希望されるインフルエンサーのURLを入力してください";
      return "お問い合わせ内容の詳細をこちらにご記入ください";
    };
    const submit = () => {
      let err = false;
      state.formError = {
        companyName: null,
        departmentName: null,
        name: null,
        email: null,
        phone: null,
        title: null,
        content: null,
        check: null,
      };
      if (!state.form?.companyName) {
        state.formError.companyName = "会社名は必須です";
        err = true;
      }
      if (!state.form?.name) {
        state.formError.name = "お名前は必須です";
        err = true;
      }
      if (!state.form?.email) {
        state.formError.email = "メールアドレスは必須です";
        err = true;
      }
      if (!state.form?.check) {
        state.formError.check = "お問い合わせには利用規約・プライバシーポリシーに同意する必要があります";
        err = true;
      }
      if (err) {
        window.scrollTo(0, 0);
        return;
      }
      const data: Inquiry = {
        companyName: String(state.form.companyName),
        departmentName: state.form.departmentName,
        name: String(state.form.name),
        email: String(state.form.email),
        phone: state.form.phone ? String(state.form.phone) : null,
        title: "新規登録およびトライアルについて",
        content: state.form.content,
      };
      store.dispatch("inquiries/createInquiry", data);
      window.scrollTo(0, 0);
    };
    const closeWindow = () => {
      window.close();
    };
    return {
      ...toRefs(state),
      selectItems,
      getContentPlaceHolder,
      submit,
      close,
      closeWindow,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
header {
  @include mq(xs) {
    padding: 5px 0 0;
  }
  @include mq(lg) {
    padding: 5px 0 0 36px;
  }
}
.contact {
  max-width: 1000px;
  @include mq(xs) {
    width: 100%;
    margin: 80px auto;
  }
  @include mq(lg) {
    width: 80%;
    margin: 120px auto;
  }
  &__title {
    font-weight: 900;
    margin-bottom: 20px;
    color: map-get($colors, "blue400");
    @include mq(xs) {
      font-size: 24px;
    }
    @include mq(lg) {
      font-size: 36px;
    }
    &__description {
      color: #66b8f0;
      font-weight: 700;
      @include mq(xs) {
        font-size: 14px;
      }
      @include mq(lg) {
        font-size: 18px;
      }
    }
    &__container {
      width: 100%;
      color: #198fd9;
    }
    &__small {
      color: map-get($colors, "blue300");
      @include mq(xs) {
        font-size: 10px;
        margin-bottom: -5px;
      }
      @include mq(lg) {
        font-size: 14px;
        margin-bottom: -10px;
      }
    }
  }
  &__main {
    &__container {
      margin: 60px 0;
      max-width: 1000px;
      background: white;
      border-radius: 20px;
      @include mq(xs) {
        width: calc(100% - 40px);
        padding: 40px 20px;
      }
      @include mq(lg) {
        width: 100%;
        padding: 80px 0;
      }
    }
    &__form {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      &__attention {
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        color: map-get($colors, "gray300");
        margin-top: 48px;
      }
    }
    &__success {
      text-align: center;
      &__title {
        color: map-get($colors, "blue400");
        font-weight: 900;
        margin-bottom: 30px;
        @include mq(xs) {
          font-size: 16px;
        }
        @include mq(lg) {
          font-size: 20px;
        }
      }
      &__description {
        color: map-get($colors, "blue300");
        font-weight: 700;
        margin-top: 30px;
        @include mq(xs) {
          font-size: 13px;
        }
        @include mq(lg) {
          font-size: 14px;
        }
      }
    }
  }
  & .spacer {
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
    &__lg {
      width: 100%;
      max-width: 600px;
      margin-top: 60px;
    }
  }
  & .error {
    font-weight: 500;
    color: map-get($colors, "error");
    font-size: 12px;
    margin: 4px 0;
    &.large {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
.contact {
  & input {
    width: calc(100% - 44px) !important;
    max-width: 600px !important;
    border: solid 1.5px map-get($colors, "primary") !important;
    &:focus {
      border: solid 1.5px map-get($colors, "primary") !important;
      outline: solid 0.5px map-get($colors, "primary") !important;
    }
  }
  & .selectbox__container {
    width: 100% !important;
    max-width: 600px !important;
  }
  & .selectbox__wrapper,
  .selectbox__items {
    width: calc(100% - 3px) !important;
    max-width: 600px !important;
    border-width: 1.5px !important;
  }
  & .focus .selectbox__wrapper {
    width: calc(100% - 4px) !important;
  }
  & textarea {
    width: calc(100% - 44px) !important;
    max-width: 600px !important;
    border: solid 1.5px map-get($colors, "primary") !important;
    &:focus {
      border: solid 1.5px map-get($colors, "primary") !important;
      outline: solid 0.5px map-get($colors, "primary") !important;
    }
  }
  & a.button {
    margin-top: 60px;
    font-size: 20px !important;
    font-weight: 900 !important;
    color: white !important;
    background: transparent linear-gradient(90deg, #198fd9 0%, #93cce2 100%) 0 0 no-repeat padding-box;
    padding: 18px 68px !important;
    width: fit-content !important;
    text-decoration: none;
  }
  & .checkbox__container {
    text-align: center;
    font-weight: 700;
    & label {
      & span {
        color: map-get($colors, "gray300");
        @include mq(xs) {
          font-size: 14px;
        }
        @include mq(lg) {
          font-size: 16px;
        }
      }
      & a {
        color: #198fd9;
        text-decoration: none;
        @include mq(xs) {
          font-size: 14px;
        }
        @include mq(lg) {
          font-size: 16px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
