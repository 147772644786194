<template>
  <div class="influencer-list-table">
    <div class="influencer-list-table__header d-flex">
      <div class="influencer-list-table__header__sort d-flex align-items-center">
        <h4 class="mr-10px">並び替え</h4>
        <select-box class="influencer-list-table__header__sort__selectbox" :width="180" :height="32" v-model="sortingItem" :options="sortItems" return-object></select-box>
      </div>
    </div>
    <div class="influencer-list-table__body d-flex align-items-center justify-content-space-between" v-for="item in sortedItems" :key="item.key">
      <div class="d-flex">
        <div class="influencer-list-table__body__thumbnail">
          <img :src="item.picture" />
        </div>
        <div class="ml-40px">
          <div class="influencer-list-table__body__sns mb-10px">
            <a class="d-flex link" v-if="item?.type === 0" :href="item.url" target="_blank" rel="noopener noreferrer">
              <instagram-icon></instagram-icon>
              <div class="influencer-list-table__body__sns__text link">Instagram</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 1" :href="item.url" target="_blank" rel="noopener noreferrer">
              <youtube-icon></youtube-icon>
              <div class="influencer-list-table__body__sns__text link">YouTube</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 2" :href="item.url" target="_blank" rel="noopener noreferrer">
              <tiktok-icon></tiktok-icon>
              <div class="influencer-list-table__body__sns__text link">TikTok</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 3" :href="item.url" target="_blank" rel="noopener noreferrer">
              <twitter-icon></twitter-icon>
              <div class="influencer-list-table__body__sns__text link">Twitter</div>
            </a>
          </div>
          <div class="influencer-list-table__body__influencer-name">
            <h3>{{ item.fullname }}</h3>
            <h4>{{ item.username }}</h4>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex influencer-list-table__body__description">
          <div class="influencer-list-table__body__value d-flex">
            <img src="@/assets/img/follower-icon.svg" alt="フォロワー"/>
            <div class="ml-10px">
              <h5>フォロワー数</h5>
              <h3>{{ item.followers.toLocaleString() }}</h3>
            </div>
          </div>
          <div class="influencer-list-table__body__value d-flex">
            <img src="@/assets/img/liker-icon.svg" alt="平均いいね"/>
            <div class="ml-10px">
              <h5>平均いいね数</h5>
              <h3 v-if="item.avgLikes">{{ item.avgLikes.toLocaleString() }}</h3>
              <h5 class="no-data-text" v-else>データなし</h5>
            </div>
          </div>
          <div class="influencer-list-table__body__value d-flex">
            <img src="@/assets/img/engagement-icon.svg" alt="エンゲージメント"/>
            <div class="ml-10px">
              <h5>エンゲージメント率</h5>
              <h3>{{ (item.engagementRate * 100).toFixed(1) }}%</h3>
            </div>
          </div>
        </div>
        <div class="d-flex influencer-list-table__body__description mt-20px">
          <div class="influencer-list-table__body__value d-flex audience">
            <div>
              <h5>オーディエンスの男女比</h5>
              <div class="d-flex">
                <div class="d-flex">
                  <img src="@/assets/img/female-solid.svg" alt="女性"/>
                  <h3 class="ml-10px" v-if="item.audienceFemaleRate">{{ (item.audienceFemaleRate * 100).toFixed(1) }}%</h3>
                  <h5 class="ml-10px no-data-text" v-else>データなし</h5>
                </div>
                <div class="d-flex">
                  <img src="@/assets/img/male-solid.svg" class="ml-10px" alt="男性"/>
                  <h3 class="ml-10px" v-if="item.audienceFemaleRate">{{ (item.audienceMaleRate * 100).toFixed(1) }}%</h3>
                  <h5 class="ml-10px no-data-text" v-else>データなし</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="influencer-list-table__body__value audience">
            <h5>オーディエンスのボリュームゾーン</h5>
            <h3 v-if="item.audienceEffectiveAge">{{ item.audienceEffectiveAge }}歳</h3>
            <h5 class="no-data-text" v-else>データなし</h5>
          </div>
        </div>
        <div class="influencer-list-table__body__description mt-20px">
          <div class="influencer-list-table__body__profile d-flex">
            <h5 class="mr-10px ml-10px">プロフィール</h5>
            <h5 class="influencer-list-table__body__profile__text" v-if="item.biography">{{ item.biography }}</h5>
            <h5 v-else class="no-data-text">データなし</h5>
          </div>
        </div>
      </div>
      <div class="influencer-list-table__body__icons">
        <open-detail-icon v-model="detailChecked" class="mb-20px" @confirmView="viewInfluencerDetail(item.id)" disable-icon>
          <base-button>詳細を表示</base-button>
        </open-detail-icon>
        <trash-icon :id="String(item.id)" v-model="deleteChecked" @confirmDelete="deleteInfluencer(item.id)" disable-icon>
          <base-button color="accent">削除する</base-button>
        </trash-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import BaseButton from "@/components/BaseButton.vue";
import { DeleteInfluencerListParams, Influencer } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "InfluencerListTable",
  props: {
    items: {
      type: Array as () => Influencer[],
      default: () => [],
    },
    listId: {
      type: Number,
      required: false,
    },
  },
  components: {
    YoutubeIcon,
    TiktokIcon,
    TwitterIcon,
    TrashIcon,
    InstagramIcon,
    SelectBox,
    OpenDetailIcon,
    BaseButton,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      show: {
        add: true,
        trash: false,
        delete: false,
        errorDialog: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "fullname",
          text: "名前",
        },
        {
          key: "followers",
          text: "フォロワー",
        },
        {
          key: "engagementRate",
          text: "エンゲージメント",
        },
      ],
      sortedItems: props.items.slice(),
      listChecked: false,
      detailChecked: false,
      deleteChecked: false,
      influencers: computed(() => store.state.influencers.influencerList?.influencers),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      deleteInfluencerSuccess: computed(() => store.state.influencers.deleteInfluencerSuccess),
      childInfluencerList: computed(() => store.state.influencers?.childInfluencerList),
      errorMessage: computed(() => store.state.influencers.errorMessage),
      influencerId: null as string | null,
      formErrorMessage: null as string | null,
    });
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as Influencer[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const deleteInfluencer = (influencerId: string) => {
      if (!props.listId) {
        const params: DeleteInfluencerListParams = {
          influencerList: state.influencerList,
          influencers: [{ id: influencerId }],
        };
        store.dispatch("influencers/deleteInfluencer", params);
      } else {
        const params: DeleteInfluencerListParams = {
          influencerList: Number(state.childInfluencerList?.id),
          influencers: [{ id: influencerId }],
        };
        store.dispatch("influencers/deleteInfluencer", params);
      }
    };
    let deleteInfluencerSuccess = computed(() => state.deleteInfluencerSuccess);
    watch(deleteInfluencerSuccess, (val: boolean) => {
      if (val) state.show.trash = false;
      if (val) state.show.delete = true;
      if (val) context.emit("influencerDelete");
    });
    return { ...toRefs(state), sortByKey, deleteInfluencer, viewInfluencerDetail };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.influencer-list-table {
  width: 100%;
  &__right {
    margin-left: auto;
    margin-right: 0;
    align-items: center;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
    &__sort {
      width: 370px;
    }
    &__gender {
      width: 56px;
      text-align: center;
    }
    &__follower {
      width: 100px;
      text-align: right;
      margin: 0 20px;
    }
    &__engagement {
      width: 112px;
      text-align: right;
      margin: 0 20px;
    }
    &__blank {
      width: 92px;
    }
  }
  &__body {
    height: fit-content;
    padding: 30px 20px;
    background-color: white;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: calc(100% - 40px);
      bottom: 0;
      left: 20px;
      height: 2px;
      background-color: #f1f1f1;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      &:after {
        display: none;
      }
    }
    &__thumbnail {
      & img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    &__sns {
      padding: 2px 10px;
      width: fit-content;
      border-radius: 20px;
      background-color: map-get($colors, "gray100");
      &__text {
        font-size: 10px;
        font-weight: 700;
        border-radius: 10px;
      }
      & svg {
        width: 12px;
        margin-right: 5px;
      }
      & .link {
        text-decoration: none;
        cursor: pointer;
        align-items: center;
        color: map-get($colors, "gray400") !important;
        &:visited {
          color: map-get($colors, "gray400") !important;
        }
      }
    }
    &__influencer-name {
      width: 280px;
      & h4 {
        color: map-get($colors, "gray200");
      }
    }
    &__value {
      width: 160px;
      margin: 0 10px;
      & h5 {
        color: map-get($colors, "gray200");
        font-weight: 700;
      }
      & .no-data-text {
        color: map-get($colors, "gray400") !important;
      }
      & img {
        width: 30px;
      }
    }
    & .audience {
      width: 220px !important;
      & h5 {
        margin-bottom: 5px;
      }
      & .no-data-text {
        color: map-get($colors, "gray400") !important;
      }
      & img {
        height: 26px;
      }
    }
    &__profile {
      width: fit-content;
      & h5 {
        color: map-get($colors, "gray200");
        font-weight: 700;
      }
      &__text {
        color: map-get($colors, "gray400") !important;
        text-overflow: ellipsis;
        width: 440px;
        white-space: nowrap;
        overflow: hidden;
      }
      & .no-data-text {
        color: map-get($colors, "gray400") !important;
      }
    }
    &__icons {
    }
    &__blank {
      width: 36px;
    }
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
