
import HomeIcon from "@/components/icon/HomeIcon.vue";
import AccountIcon from "@/components/icon/AccountIcon.vue";
import OrderIcon from "@/components/icon/OrderIcon.vue";
import MeterIcon from "@/components/icon/MeterIcon.vue";
import OrderManageIcon from "@/components/icon/OrderManageIcon.vue";
import AccountListIcon from "@/components/icon/AccountListIcon.vue";
import AccountEditIcon from "@/components/icon/AccountEditIcon.vue";
import { defineComponent, PropType, reactive, toRefs } from "vue";
import { TabItem } from "@/types";

const defaultTabItem: TabItem[] = [
  {
    index: 0,
    text: "ホーム",
    link: "Home",
  },
  {
    index: 1,
    text: "インフルエンサーを探す",
    link: "SearchHome",
    subItems: [
      {
        index: 0,
        link: "IDSearch",
        text: "名前・IDで検索",
      },
      {
        index: 1,
        link: "AdvancedSearch",
        text: "詳細検索",
      },
      {
        index: 2,
        link: "HashtagSearch",
        text: "ハッシュタグ検索",
      },
      {
        index: 3,
        link: "AISearch",
        text: "AI検索",
      },
    ],
  },
  {
    index: 2,
    text: "ビジネスクリエーション",
    link: "Listing",
    subItems: [
      {
        index: 0,
        link: "Listing",
        query: { type: 0 },
        text: "インタビュー",
      },
      {
        index: 1,
        link: "Listing",
        query: { type: 1 },
        text: "コラボレーション",
      },
      {
        index: 2,
        link: "Listing",
        query: { type: 2 },
        text: "アンバサダー・PR投稿",
      },
      {
        index: 3,
        link: "Listing",
        query: { type: 3 },
        text: "その他のお仕事",
      },
    ],
  },
  {
    index: 3,
    text: "効果を予測する",
    link: "AdPrediction",
  },
  {
    index: 4,
    text: "案件を管理する",
    link: "TaskManage",
  },
  {
    index: 5,
    text: "インフルエンサーリスト",
    link: "InfluencerList",
  },
];

export default defineComponent({
  name: "Sidebar",
  components: { HomeIcon, AccountIcon, OrderIcon, MeterIcon, OrderManageIcon, AccountListIcon, AccountEditIcon },
  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array as PropType<TabItem[]>,
      default: defaultTabItem,
    },
  },
  setup() {
    const state = reactive({
      hoverIndex: null as number | null,
    });
    return { ...toRefs(state) };
  },
});
