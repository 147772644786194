<template>
  <div class="base-container__white" v-show="!showSuccess">
    <div class="mail-admit">
      <img src="@/assets/img/logo-wrap.svg">
      <div class="mt-60px">
        <h2>メールアドレスの認証</h2>
      </div>
      <div class="mt-60px">
        <base-button :size="'wide'" @click="submitVerify">メールアドレスを認証する</base-button>
      </div>
    </div>
  </div>
  <div class="base-container__white" v-show="showSuccess">
    <div class="mail-admit">
      <img src="@/assets/img/logo-wrap.svg">
      <div class="mt-60px">
        <h2>メールアドレスの認証完了</h2>
      </div>
      <div class="mt-40px">
        <h3>
          メールアドレス{{ email }}を認証しました。<br>
          早速ログインしてINFRECTを使ってみましょう！
        </h3>
      </div>
      <div class="mt-60px">
        <router-link :to="{ name: 'SignIn' }">
          <base-button :size="'wide'">ログイン</base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs} from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRoute } from "vue-router";
import {useStore} from "vuex";

export default defineComponent({
  name: "VerifyEmail",
  components: { BaseButton },
  setup(props, context){
    const store = useStore();
    const route = useRoute();
    store.dispatch("verificationEmail/verificationEmailInitialize");
    const state = reactive({
      showSuccess: computed(() => store.state.verificationEmail.submitVerificationSuccess),
      verificationCode: route.params.verificationCode,
      email: computed(() => store.state.users.user?.email),
    });
    const submitVerify = () => {
      store.dispatch("verificationEmail/postVerificationEmail", state.verificationCode);
    };
    return { ...toRefs(state), submitVerify };
  }
})
</script>

<style lang="scss" scoped>
@import "../../src/assets/styles/main";
.mail-admit {
  text-align: center;
  padding-top: 120px;
  & h2 {
    color: map-get($colors, 'blue400');
  }
  & img {
    width: 160px;
    height: auto;
  }
}
</style>