
import { defineComponent, reactive, toRefs, computed, watch, onBeforeUpdate, onMounted } from "vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import BaseButton from "@/components/BaseButton.vue";

export default defineComponent({
  name: "AddCandidateButton",
  components: { BaseCheckbox, InstagramIcon, YoutubeIcon, TiktokIcon, BaseButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "checkbox",
    },
    modelValue: {
      type: Array as () => any[],
    },
  },
  setup(props, context) {
    const state = reactive({
      show: false,
      allChecked: false,
      checked: {} as any,
    });
    let itemRefs = [] as any[];
    const setItemRefs = (el: any) => {
      itemRefs.push(el);
    };
    onMounted(() => {
      props.items.map((item: any, index: number) => {
        if (props.modelValue && props.modelValue.length && item.id) {
          const selectedItems = props.modelValue.map((selectedItem: any) => selectedItem.id);
          state.checked[item.id] = selectedItems.includes(item.id);
          if (state.checked[item.id]) itemRefs[index].children[0].click();
        } else {
          state.checked[item.id] = false;
        }
      });
    });
    onBeforeUpdate(() => {
      itemRefs = [];
    });
    let allChecked = computed(() => state.allChecked);
    watch(allChecked, (val: boolean) => {
      // header のチェックボックスが選択されたら全選択、外れたら全部選択を外す
      props.items.map((item: any, index: number) => {
        if (state.checked[item.id] !== val) itemRefs[index].children[0].click();
      });
    });
    const addInfluencers = () => {
      const selectedItems = [] as any[];
      props.items.map((item: any, index: number) => {
        if (state.checked[item.id]) selectedItems.push(item);
      });
      context.emit("update:modelValue", selectedItems);
      state.show = false;
    };
    return { ...toRefs(state), addInfluencers, itemRefs, setItemRefs };
  },
});
