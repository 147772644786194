<template>
  <div class="combobox">
    <h4 :class="[label ? 'combobox__label' : 'd-none']">
      {{ label }}
      <span v-if="required" v-show="required" class="required">必須</span>
      <span v-if="multiple" v-show="multiple" class="multiple">複数入力</span>
    </h4>
    <div class="combobox__container" :style="styles" v-click-outside="setSelectedItems">
      <div class="combobox__wrapper" :style="styles">
        <div :class="['combobox__selected_item', chips ? 'chips' : '', deletableChips ? 'deletable-chips' : '']" v-for="(item, idx) in selectedItems" :key="idx"><span v-show="deletableChips" @click="deleteSelectedItem(idx)">×</span>{{ item }}</div>
        <!-- @keydown.enter でエンターキーを入力後も発火するように。日本語の変換時のエンターは発火しない。 -->
        <input
          :id="id"
          :class="['combobox__input', disabled ? 'disabled' : '', readonly ? 'readonly' : '']"
          :style="styles"
          :placeholder="placeholder"
          :disabled="disabled ? 'disabled' : null"
          :readonly="readonly ? 'readonly' : null"
          v-model="currentInputValue"
          @input="handleEvent"
          @keydown.enter="setSelectedItems"
          @keydown.delete="deleteLastSelectedItem"
          :autofocus="autofocus"
        />
      </div>
      <div class="combobox__items" :style="styles" v-show="!disableLookup && isSearching" :key="storeState.key">
        <div class="combobox__item" v-show="filteredIndexArr.length < 1">{{ noDataText }}</div>
        <div :class="['combobox__item', (selectedItems || [selectedItems]).includes(searchItem.text) ? 'selected' : '']" v-for="(searchItem, sIdx) in storeState.items" :key="searchItem.text" v-show="filteredIndexArr.includes(sIdx)" @click="setSelectedItemsFromFilter(searchItem.text)">{{ searchItem.text }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, reactive, toRefs, computed, watch } from "vue";
import { ComboBoxItem, ComboBoxId } from "@/types";
import vClickOutside from "click-outside-vue3";
import {useStore} from "vuex";

export default defineComponent({
  name: "ComboBox",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    allowDistinct: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    borderWidth: {
      type: Number,
      default: 2,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#79B5DB",
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableLookup: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: "#707070",
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 48,
    },
    id: {
      type: Object as PropType<ComboBoxId>,
      default: null,
    },
    items: {
      type: Array as PropType<Array<ComboBoxItem>>,
      default: () => [],
    },
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
    storeName: {
      type: String,
      default: "",
    },
    storeStateName: {
      type: String,
      default: "",
    },
    itemColor: {
      type: String,
      default: "#707070",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "データがありません",
    },
    placeholder: {
      type: String,
      default: "入力してください",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    smWidth: {
      type: Number,
      default: 0,
    },
    smHeight: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 636,
    },
    modelValue: {
      type: Object as () => string | string[] | null,
      default: null,
    },
  },
  setup(props, context) {
    const store = useStore();
    const storeState = reactive({
      items: computed(() => store.state[props.storeName][props.storeStateName]),
      key: "",
    });
    const state = reactive({
      currentInputValue: props.modelValue ? (Array.isArray(props.modelValue) ? null : props.modelValue) : null,
      selectedItems: props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : []) : [],
      isSearching: false,
      filteredIndexArr: [...Array((storeState.items || []).length).keys()] as number[], //items の range
    });
    const styles = computed(() => {
      const smWidth = Number(props.smWidth) ? Number(props.smWidth) : props.width;
      const smHeight = Number(props.smHeight) ? Number(props.smHeight) : props.height;
      return {
        "--height": props.height,
        "--width": props.width,
        "--sm-width": smWidth,
        "--sm-height": smHeight,
        "--border-radius": props.rounded ? props.height / 2 : props.borderRadius, // roundedの時は強制で高さの半分にする
        "--border-width": props.borderWidth,
        "--color": props.color,
        "--font-color": props.fontColor,
        "--font-size": props.fontSize,
        "--item-color": props.itemColor,
      };
    });
    const handleEvent = () => {
      let resValue: string | string[] | null;
      if (props.multiple) {
        if (state.currentInputValue !== null) {
          resValue = state.selectedItems.length > 0 ? state.selectedItems : [state.currentInputValue];
        } else {
          resValue = state.selectedItems.length > 0 ? state.selectedItems : [];
        }
      } else {
        resValue = state.currentInputValue;
      }
      if (state.currentInputValue) context.emit("update:inputValue", state.currentInputValue);
      if (resValue) context.emit("update:modelValue", resValue); // update:modelValue で全部のイベントを handling
    };
    const setSelectedItems = () => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (state.currentInputValue !== "" && state.currentInputValue !== null) {
        if (props.multiple) {
          // 通常は重複を許容しない
          if (props.allowDistinct || !state.selectedItems.includes(state.currentInputValue)) {
            state.selectedItems.push(state.currentInputValue);
          }
          state.currentInputValue = null;
        } else {
          state.isSearching = false;
        }
        handleEvent();
      }
    };
    const setSelectedItemsFromFilter = (val: string) => {
      // multiple が true なときは selectedItems に入力値を追加する
      if (props.multiple) {
        // 通常は重複を許容しない
        if (props.allowDistinct || !state.selectedItems.includes(val)) {
          state.selectedItems.push(val);
        }
        state.currentInputValue = null;
      } else {
        state.currentInputValue = val;
      }
      handleEvent();
    };
    interface HTMLEvent<T extends EventTarget> extends Event {
      target: T;
    }
    const deleteLastSelectedItem = (e: HTMLEvent<HTMLInputElement>) => {
      if (e?.target?.value === "" || e?.target?.value === null) {
        state.selectedItems.pop();
      }
      handleEvent();
    };
    const deleteSelectedItem = (idx: number) => {
      state.selectedItems.splice(idx, 1);
      handleEvent();
    };
    const indexOfAll = (arr: string[], val: string): number[] => {
      const resArr: number[] = [];
      arr.map((arrVal: string, i: number) => {
        if (arrVal.indexOf(val) > -1) resArr.push(i);
      });
      return resArr;
    };
    // inputに入力している時は検索アイテムを表示する
    let inputVal = computed(() => state.currentInputValue);
    watch(inputVal, () => {
      state.isSearching = inputVal.value !== null && inputVal.value !== "";
      if (inputVal.value !== null && inputVal.value !== "") {
        state.filteredIndexArr = indexOfAll(
          storeState.items.map((val: ComboBoxItem) => val.text),
          inputVal.value
        );
      } else {
        // 初期値に戻す
        state.filteredIndexArr = [...Array((storeState.items || []).length).keys()];
      }
    });
    let comboBoxItems = computed(() => storeState.items);
    watch(comboBoxItems, (val) => {
      if (val && val.length) {
        state.filteredIndexArr = [...Array((storeState.items || []).length).keys()]
      }
    });
    return {
      ...toRefs(state),
      storeState,
      styles,
      setSelectedItems,
      setSelectedItemsFromFilter,
      deleteLastSelectedItem,
      deleteSelectedItem,
      handleEvent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";
.combobox {
  &__input {
    width: calc(100% - 40px);
    min-width: fit-content;
    flex-wrap: nowrap;
    height: 100%;
    border: none;
    padding: 0 20px;
    background: none;
    color: map-get($font-colors, "default");
    font-weight: 500;
    overflow-x: scroll;
    font-size: calc(var(--font-size) * 1px);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: map-get($colors, "blue300");
      font-size: calc(var(--font-size) * 1px);
    }
    &.disabled {
      cursor: not-allowed;
      background-color: #e0e0e0;
      &:focus {
        box-shadow: none;
      }
    }
    &.readonly {
      cursor: not-allowed;
    }
  }
  &__label {
    text-align: left;
    font-weight: 900;
    font-size: 13px;
    margin: 8px 10px 8px 0;
    color: map-get($colors, "gray300");
  }
  &__container {
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    @include mq(xs) {
      width: calc(var(--sm-width) * 1px);
      height: calc(var(--sm-height) * 1px);
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px);
      height: calc(var(--height) * 1px);
    }
    position: relative;
  }
  &__wrapper {
    width: calc(var(--width) * 1px);
    height: calc(var(--height) * 1px);
    @include mq(xs) {
      width: calc(var(--sm-width) * 1px);
      height: calc(var(--sm-height) * 1px);
    }
    @include mq(lg) {
      width: calc(var(--width) * 1px);
      height: calc(var(--height) * 1px);
    }
    border-radius: calc(var(--border-radius) * 1px);
    border: solid calc(var(--border-width) * 1px) var(--color);
    background-color: white;
    overflow-x: scroll;
    display: flex;
    position: relative;
    &:focus-within {
      outline: none;
      box-shadow: 0 0 0 2px var(--color); /* outlineは角丸にできないのでboc-shadowで代用 */
    }
  }
  &__selected_item {
    align-self: center;
    margin-left: 10px;
    font-size: calc(var(--font-size) * 1px);
    color: var(--font-color);
    font-weight: 500;
    min-width: fit-content;
    &.chips {
      display: block;
      border: solid 2px var(--color);
      width: fit-content;
      padding: 4px 12px;
      border-radius: 5px;
      &.deletable-chips {
        padding: 4px 12px 4px 6px;
        & > span {
          margin-right: 6px;
          &:hover {
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__items {
    position: absolute;
    width: calc(100% - 40px);
    max-height: 400px;
    overflow-y: scroll;
    top: calc(var(--height) * 1px + 10px);
    @include mq(xs) {
      top: calc(var(--sm-height) * 1px + 10px);
    }
    @include mq(lg) {
      top: calc(var(--height) * 1px + 10px);
    }
    left: 0;
    border: solid 2px var(--color);
    color: var(--item-color);
    border-radius: 5px;
    background-color: white;
    padding: 8px 20px;
    z-index: 1000;
  }
  &__item {
    margin-bottom: 4px;
    padding: 10px;
    z-index: 1000;
    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
    &.selected {
      background-color: #e0e0e0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.required, .multiple {
  margin-left: 14px;
  padding: 1px 10px;
  background-color: map-get($colors, "accent");
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 700;
}
.multiple {
  background-color: map-get($colors, "gray300");
}
</style>
