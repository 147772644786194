import axios from "@/store/axios";
import { transformResponse } from "@/store/axios";
import { Commit } from "vuex";
import { Language } from "@/types";

interface State {
  errorMessage: string | null;
  languages: Language[];
}

const state: State = {
  errorMessage: null,
  languages: [],
};
const languages = {
  namespaced: true,
  state,
  mutations: {
    setErrorMessage(state: State, errorMessage: string | null) {
      state.errorMessage = errorMessage;
    },
    setLanguage(state: State, languages: Language[]) {
      state.languages = languages;
    },
  },
  actions: {
    async getLanguage({ commit }: { commit: Commit }) {
      try {
        const res = await axios.get("api/v1/languages/");
        let languages = res.data;
        languages = languages.map((x: Language) => {
          return {
            id: x.id,
            code: x.code,
            name: x.name,
            jpName: x.jpName ? x.jpName : x.name,
          };
        });
        commit("setLanguage", languages);
      } catch (err) {
        commit("setErrorMessage", err.response.data);
      }
    },
  },
};

export default languages;
