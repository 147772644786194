<template>
  <div class="base-table">
    <div class="dialog__container" v-if="(addInfluencerSuccess || errorMessage) && show.addInfluencer">
      <div class="dialog" v-if="addInfluencerSuccess">
        <div class="title">リストにインフルエンサーを保存しました</div>
        <div class="description"><check-mark></check-mark></div>
        <div class="description"><base-button size="short" color="dark" @click="show.addInfluencer = false"> OK </base-button></div>
      </div>
      <div class="dialog" v-else>
        <div class="title error">インフルエンサーの保存に失敗しました</div>
        <div class="description"><exclamation-mark></exclamation-mark></div>
        <div class="description">
          <h4>{{ errorMessage }}</h4>
        </div>
        <div class="description"><base-button size="short" color="dark" @click="show.addInfluencer = false"> 戻る </base-button></div>
      </div>
    </div>
    <div class="base-table__header d-flex">
      <div class="base-table__header__sortalign-items-center d-none">
        <h4 class="mr-10px">並び替え</h4>
        <select-box class="base-table__header__sort__selectbox" :width="180" :height="32" v-model="sortingItem" :options="sortItems" return-object></select-box>
      </div>
    </div>
    <div class="base-table__body" v-for="item in sortedItems" :key="item.key">
      <div class="d-flex">
        <div class="base-table__body__thumbnail">
          <img :src="item.picture" />
        </div>
        <div class="base-table__body__sns__container">
          <div class="base-table__body__sns mb-10px">
            <a class="d-flex link" v-if="item?.type === 0" :href="item.url" target="_blank" rel="noopener noreferrer">
              <instagram-icon />
              <div class="base-table__body__sns__text link">Instagram</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 1" :href="item.url" target="_blank" rel="noopener noreferrer">
              <youtube-icon />
              <div class="base-table__body__sns__text link">YouTube</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 2" :href="item.url" target="_blank" rel="noopener noreferrer">
              <tiktok-icon />
              <div class="base-table__body__sns__text link">TikTok</div>
            </a>
            <a class="d-flex link" v-else-if="item?.type === 3" :href="item.url" target="_blank" rel="noopener noreferrer">
              <twitter-icon />
              <div class="base-table__body__sns__text link">Twitter</div>
            </a>
          </div>
          <div class="base-table__body__influencer-name">
            <h3>{{ item.fullname }}</h3>
            <h4>{{ item.username }}</h4>
            <h5 v-if="item.hashtagCount">ハッシュタグの投稿回数: {{ item.hashtagCount }}</h5>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex base-table__body__description">
          <div class="base-table__body__value d-flex account">
            <img src="@/assets/img/follower-icon.svg" alt="フォロワー" />
            <div class="ml-10px">
              <h5>フォロワー数</h5>
              <h3 v-if="item.followers">{{ item.followers.toLocaleString() }}</h3>
              <h5 class="no-data-text" v-else>データなし</h5>
            </div>
          </div>
          <div class="base-table__body__value d-flex account">
            <img src="@/assets/img/liker-icon.svg" alt="平均いいね" />
            <div class="ml-10px">
              <h5>平均いいね数</h5>
              <h3 v-if="item.avgLikes">{{ item.avgLikes.toLocaleString() }}</h3>
              <h5 class="no-data-text" v-else>データなし</h5>
            </div>
          </div>
          <div class="base-table__body__value d-flex account">
            <img src="@/assets/img/engagement-icon.svg" alt="エンゲージメント" />
            <div class="ml-10px">
              <h5>エンゲージメント率</h5>
              <h3>{{ (item.engagementRate * 100).toFixed(1) }}%</h3>
            </div>
          </div>
        </div>
        <div class="d-flex base-table__body__description mt-20px">
          <div class="base-table__body__value audience">
            <div>
              <h5>オーディエンスの男女比</h5>
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <img src="@/assets/img/female-solid.svg" alt="女性" />
                  <h3 class="ml-10px" v-if="item.audienceFemaleRate">{{ (item.audienceFemaleRate * 100).toFixed(1) }}%</h3>
                  <h5 class="ml-10px no-data-text" v-else>データなし</h5>
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/img/male-solid.svg" class="ml-10px" alt="男性" />
                  <h3 class="ml-10px" v-if="item.audienceFemaleRate">{{ (item.audienceMaleRate * 100).toFixed(1) }}%</h3>
                  <h5 class="ml-10px no-data-text" v-else>データなし</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="base-table__body__value volume-zone">
            <h5>オーディエンスのボリュームゾーン</h5>
            <h3 v-if="item.audienceEffectiveAge">{{ item.audienceEffectiveAge }}歳</h3>
            <h5 class="no-data-text" v-else>データなし</h5>
          </div>
        </div>
        <div class="base-table__body__description mt-20px">
          <div class="base-table__body__profile">
            <h5>プロフィール</h5>
            <h5 class="base-table__body__profile__text" v-if="item.biography">{{ item.biography }}</h5>
            <h5 v-else class="no-data-text">データなし</h5>
          </div>
        </div>
      </div>
      <div class="base-table__body__icons">
        <open-detail-icon
            v-model="detailChecked"
            class="mb-20px"
            @confirmView="viewInfluencerDetail(item.id)"
            :id="`detail-${item.id}`"
            disable-icon
        >
          <base-button>詳細を表示</base-button>
        </open-detail-icon>
        <add-list-icon
            v-if="company?.plan?.myListLimitation !== 0"
            v-model="listChecked"
            v-model:selected-folder="selectedFolder"
            @addList="addInfluencerList(item.id)"
            :id="`add-list-${item.id}`"
            :influencer-id="item.id"
            disable-icon
            :key="count"
        >
        </add-list-icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import InstagramIcon from "@/components/icon/InstagramIcon.vue";
import YoutubeIcon from "@/components/icon/YoutubeIcon.vue";
import TiktokIcon from "@/components/icon/TiktokIcon.vue";
import TwitterIcon from "@/components/icon/TwitterIcon.vue";
import SelectBox from "@/components/SelectBox.vue";
import AddListIcon from "@/components/icon/AddListIcon.vue";
import OpenDetailIcon from "@/components/icon/OpenDetailIcon.vue";
import CheckMark from "@/components/icon/CheckMark.vue";
import ExclamationMark from "@/components/icon/ExclamationMark.vue";
import BaseButton from "@/components/BaseButton.vue";
import { AddInfluencerListParams } from "@/types";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BaseTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InstagramIcon,
    YoutubeIcon,
    TiktokIcon,
    TwitterIcon,
    SelectBox,
    AddListIcon,
    OpenDetailIcon,
    ExclamationMark,
    BaseButton,
    CheckMark,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    store.dispatch("influencers/getCompaniesList");
    const state = reactive({
      show: {
        add: true,
        trash: false,
        addInfluencer: false,
      },
      sortingItem: null as any,
      sortItems: [
        {
          key: "influencerName",
          text: "名前",
        },
        {
          key: "genderType",
          text: "性別",
        },
        {
          key: "followerValue",
          text: "フォロワー",
        },
        {
          key: "engagementValue",
          text: "エンゲージメント",
        },
        {
          key: "latestPost",
          text: "最終投稿日",
        },
      ],
      sortedItems: ((props || { items: [] }).items || []).slice(),
      listChecked: false,
      detailChecked: false,
      company: computed(() => store.state.companies.company),
      influencerList: computed(() => store.state.influencers.influencerList?.id),
      addInfluencerSuccess: computed(() => store.state.influencers.addInfluencerListSuccess),
      errorMessage: computed(() => store.state.influencers.influencerListErrorMessage),
      selectedFolder: null as number | null,
      count: 1,
    });
    let key = computed(() => (state.sortingItem || { key: "" }).key);
    watch(key, (key) => {
      state.sortedItems = sortByKey(props.items.slice(), key).reverse();
    });
    let sortByKey = (arr: any[], key: string) => [] as boolean[];
    sortByKey = (arr: any[], key: string) => {
      const res = arr.slice().sort((a: any, b: any) => {
        return a[key] > b[key] ? 1 : -1;
      });
      return res;
    };
    const viewInfluencerDetail = (influencerId: string) => {
      let resolvedRoute = router.resolve({ name: "InfluencerDetail", params: { id: influencerId } });
      window.open(resolvedRoute.href, "_blank", "noreferrer");
    };
    const addInfluencerList = (id: string) => {
      store.dispatch("influencers/initailizeAddInfluencerList");
      if (state.selectedFolder) {
        const params: AddInfluencerListParams = {
          influencerList: state.selectedFolder,
          influencers: [{ id: id }],
        };
        store.dispatch("influencers/addInfluencerList", params);
      }
      state.show.addInfluencer = true;
      store.dispatch("influencers/getCompaniesList");
    };
    let addInfluencerListSuccess = computed(() => state.addInfluencerSuccess);
    watch(addInfluencerListSuccess, (val) => {
      if (val) store.dispatch("influencers/getCompaniesList");
      state.count++;
    });
    return { ...toRefs(state), sortByKey, addInfluencerList, viewInfluencerDetail };
  },
});
</script>

<style lang="scss" scoped>
@import "src/assets/styles/main";

.base-table {
  width: 100%;
  &__right {
    margin-left: auto;
    margin-right: 0;
    align-items: center;
  }
  &__header {
    height: fit-content;
    padding: 20px 20px;
    border-radius: 10px 10px 0 0;
    background: map-get($colors, "blue300");
    & h4 {
      color: white;
      font-weight: 700;
    }
    &__sort {
      width: 370px;
    }
    &__gender {
      width: 56px;
      text-align: center;
    }
    &__follower {
      width: 100px;
      text-align: right;
      margin: 0 20px;
    }
    &__engagement {
      width: 112px;
      text-align: right;
      margin: 0 20px;
    }
    &__blank {
      width: 92px;
    }
  }
  &__body {
    height: fit-content;
    padding: 30px 20px;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(xs) {
      display: inline-block;
      padding: 10px 5px;
      max-width: 100%;
      box-sizing: border-box;
    }
    @include mq(lg) {
      display: flex;
      padding: 30px 20px;
      max-width: unset;
      box-sizing: unset;
    }
    &:after {
      position: absolute;
      content: "";
      width: calc(100% - 40px);
      bottom: 0;
      left: 20px;
      height: 2px;
      background-color: #f1f1f1;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
      &:after {
        display: none;
      }
    }
    &__thumbnail {
      & img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        @include mq(xs) {
          width: 60px;
          height: 60px;
        }
        @include mq(lg) {
          width: 100px;
          height: 100px;
        }
      }
    }
    &__sns {
      padding: 2px 10px;
      width: fit-content;
      border-radius: 20px;
      background-color: map-get($colors, "gray100");
      &__container {
        margin-left: 40px;
        margin-bottom: 0;
        width: 257px;
        box-sizing: border-box;
        @include mq(xs) {
          margin-left: 20px;
          margin-bottom: 20px;
        }
        @include mq(lg) {
          margin-left: 40px;
          margin-bottom: 0;
        }
      }
      &__text {
        font-size: 10px;
        font-weight: 700;
        border-radius: 10px;
      }
      & svg {
        width: 12px;
        margin-right: 5px;
      }
      & .link {
        text-decoration: none;
        cursor: pointer;
        align-items: center;
        color: map-get($colors, "gray400") !important;
        &:visited {
          color: map-get($colors, "gray400") !important;
        }
      }
    }
    &__influencer-name {
      width: 280px;
      @include mq(xs) {
        width: calc(100vw - 70px);
        max-width: 240px;
      }
      @include mq(lg) {
        width: fit-content;
        max-width: 280px;
      }
      & h4 {
        color: map-get($colors, "gray200");
      }
    }
    &__value {
      width: 160px;
      margin: 0 10px;
      @include mq(xs) {
        width: 100px;
        margin: 0;
      }
      @include mq(lg) {
        width: 160px;
        margin: 0 10px;
      }
      & img {
        @include mq(xs) {
          display: none;
        }
        @include mq(lg) {
          display: block;
        }
      }
      & h5 {
        color: map-get($colors, "gray200");
        font-weight: 700;
      }
      & .no-data-text {
        color: map-get($colors, "gray400") !important;
      }
    }
    & .account {
      & h3 {
        margin-top: 5px;
      }
      & img {
        width: 27px;
      }
      & .no-data-text {
        margin-top: 6px;
      }
    }
    & .audience {
      width: 180px !important;
      @include mq(xs) {
        width: 150px !important;
        margin-left: 10px;
      }
      @include mq(lg) {
        width: 180px!important;
        margin-left: 10px;
      }
      & h5 {
        margin-bottom: 5px;
        min-width: 61px;
      }
      & img {
        height: 24px;
        display: block;
      }
    }
    & .volume-zone {
      width: 220px !important;
      @include mq(xs) {
        width: 200px !important;
        margin-left: 10px;
      }
      @include mq(lg) {
        width: 220px;
        margin-left: unset;
      }
      & h3 {
        margin-top: 5px;
      }
      & .no-data-text {
        margin-top: 6px;
      }
    }
    &__profile {
      width: fit-content;
      display: flex;
      margin: 0 10px;
      @include mq(xs) {
        display: block;
        margin: 0 0 0 10px;
      }
      @include mq(lg) {
        display: flex;
        margin: 0 10px;
      }
      & h5 {
        color: map-get($colors, "gray200");
        font-weight: 700;
      }
      &__text {
        color: map-get($colors, "gray400") !important;
        text-overflow: ellipsis;
        width: 440px;
        white-space: nowrap;
        overflow: hidden;
        @include mq(xs) {
          text-overflow: ellipsis;
          width: 80vw;
          white-space: nowrap;
          overflow: hidden;
          margin-top: 10px;
        }
        @include mq(lg) {
          text-overflow: ellipsis;
          width: 440px;
          white-space: nowrap;
          overflow: hidden;
          margin-top: 0;
        }
      }
      & .no-data-text {
        color: map-get($colors, "gray400") !important;
      }
    }
    &__icons {
      display: block;
      @include mq(xs) {
        display: flex;
        margin-top: 20px;
      }
      @include mq(lg) {
        display: block;
        margin: 0;
      }
      & .button {
        @include mq(xs) {
          width: 130px;
          font-size: 12px;
        }
        @include mq(lg) {
          width: 160px;
          font-size: unset;
        }
      }
      & .open-list {
        @include mq(xs) {
          width: 150px;
          margin-left: 20px;
        }
        @include mq(lg) {
          width: unset;
          font-size: unset;
          margin: unset;
        }
      }
    }
    &__blank {
      width: 36px;
    }
  }
}
.dialog {
  width: 400px;
  padding: 20px 100px;
  margin: 100px auto;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  @include mq(xs) {
    width: 90vw;
    padding: 20px;
    margin: 20px auto;
  }
  @include mq(lg) {
    width: 400px;
    padding: 20px 100px;
    margin: 100px auto;
  }
  & .error {
    color: map-get($colors, "accent") !important;
    & h1,
    h4 {
      color: map-get($colors, "accent");
    }
  }
  &__container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  & .title {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
    color: map-get($colors, "blue400");
    text-align: center;
  }
  & .description {
    width: fit-content;
    margin: 20px auto;
  }
}
</style>
